/* eslint-disable no-param-reassign */
/* eslint-disable max-len */

export function hexToRGBArray(hexColor) {
  let color = hexColor.replace('#', '');
  if (color.length === 3) color = color.charAt(0) + color.charAt(0) + color.charAt(1) + color.charAt(1) + color.charAt(2) + color.charAt(2);
  // eslint-disable-next-line no-throw-literal
  else if (color.length !== 6) throw (`Invalid hex color: ${color}`);
  const rgb = [];
  for (let i = 0; i <= 2; i += 1) { rgb[i] = parseInt(color.substr(i * 2, 2), 16); }
  return rgb;
}

function luma(color) {
  const rgb = (typeof color === 'string') ? hexToRGBArray(color) : color;
  return (0.2126 * rgb[0]) + (0.7152 * rgb[1]) + (0.0722 * rgb[2]); // SMPTE C, Rec. 709 weightings
}

function blendColors(colorA, colorB, amount) {
  const [rA, gA, bA] = colorA.match(/\w\w/g).map((c) => parseInt(c, 16));
  const [rB, gB, bB] = colorB.match(/\w\w/g).map((c) => parseInt(c, 16));
  const r = Math.round(rA + (rB - rA) * amount).toString(16).padStart(2, '0');
  const g = Math.round(gA + (gB - gA) * amount).toString(16).padStart(2, '0');
  const b = Math.round(bA + (bB - bA) * amount).toString(16).padStart(2, '0');
  return `#${r}${g}${b}`;
}

function contrastingColor(color, mixValue = 0.8) {
  if (!color) return null;
  const background = color.replace('#', '');
  const contrast = (luma(background) >= 165) ? '3333333' : 'ffffff';

  const foreground = blendColors(background, contrast, mixValue);

  return foreground;
}

export default contrastingColor;
