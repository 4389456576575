import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useCoreApi from '@/hooks/useCoreApi';

const { fetchBranches } = useCoreApi();

export default defineStore('coreBranch', () => {
  const {
    state,
    fetch,
    fetched,
    getById,
  } = useRemoteResource('core.branch', fetchBranches);

  return {
    state,
    fetch,
    fetched,
    getById,
  };
});
