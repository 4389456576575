<template>
  <div class="investments-create-work-type-modal">
    <b-modal
      id="modal-investments-create-work-type"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.workType.create.title')"

      v-model="addWorkTypeModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.workType.create.loading')"
        v-if="isLoading"
      />

      <investment-work-type-form
        v-model="fields"
        :feedback="feedback"
      />

      <template #modal-footer>
        <div class="investment-modal-footer">

          <div class="buttons">
            <vs-button
              variant="outline"
              @click="addWorkTypeModal
              .visible = false"
            >
              {{ $t('investments.modal.workType.create.cancel') }}
            </vs-button>

            <vs-button
              variant="primary"
              @click="addWorkType"
            >
              {{ $t('investments.modal.workType.create.confirm') }}
            </vs-button>
          </div>

        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import showToast from '@/libs/toasts';
import { CreateWorkTypeError, CreateWorkTypeSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import InvestmentWorkTypeForm from '../forms/InvestmentWorkTypeForm.vue';

export default {
  name: 'InvestmentWorkTypeModalAdd',
  props: {},
  setup(_props, { emit }) {
    const { addWorkTypeModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const {
      fields, feedback, validateAllFields, resetFields, validateField,
      nonEmptyFields, assignServerSideErrors,
    } = useValidatedForm({
      name: 'required',
    }, 'investments.form.workType.field');

    const addWorkType = async () => {
      const validFields = await validateAllFields();
      if (!validFields.every((field) => field)) return;

      isLoading.value = true;

      useInvestmentsApi()
        .createWorkType({
          ...nonEmptyFields.value,
        })
        .then(({ data }) => {
          showToast(CreateWorkTypeSuccess, { name: data.data.name });
          emit('success', data.data);

          resetFields();
          addWorkTypeModal.value.visible = false;
          isLoading.value = false;
        })
        .catch(({ response }) => {
          showToast(CreateWorkTypeError);
          assignServerSideErrors(response?.data?.errors);
          isLoading.value = false;
        });
    };

    return {
      fields,
      feedback,
      addWorkTypeModal,
      isLoading,
      addWorkType,
      validateField,
    };
  },
  components: {
    VsLoader,
    VsButton,
    InvestmentWorkTypeForm,
  },
};
</script>

<style lang="sass" scoped>
.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between

  .buttons
    margin-left: auto
</style>
