export default {
  items: [
    {
      id: 0,
      title: 'Rura do żerdzi do DSM',
      number: '0437/03/2020',
      offerNumber: '20-08 / OF / 0347',
      description: `
        Prosimy o potwierdzenie przyjęcia zlecenia do realizacji na email mwitkowski@budokop.pl lub brak odpowiedzi/potwierdzenia wiąże się z akceptacją warunków określonych w zleceniu. Prosimy aby na fakturzy powoływać się na numer zamówienia.

        Fakturę proszę wysłać na nasz adres korespondencyjny:
        BUDOKOP ul. Legionów 2, 83-300 Elbląg
      `,
      status: {
        key: 2,
      },
      accepting: 'Adam Nowak (przełożony)',
      date: {
        created: '19.06.2021',
        accepted: null,
        settled: null,
      },
      provider: 'PILETEST Sp. z o.o.',
      investor: 'BUDIMEX',
      investment: 'Przekop pod NBP $$',
      region: 'GDA',
      subject: {
        priceUnit: 'PLN',
        items: [
          {
            name: 'Rura do żerdzi do DSM',
            price: 2450,
            quantity: 1,
          },
        ],
      },
      terms: [
        {
          type: 'Warunki płatności',
          value: 'przelew w terminie 30 dni od daty wpływu do siedziby firmy na podstawie prawidłowo wystawionej faktury',
        },
        {
          type: 'Termin realizacji',
          value: '1 dzień roboczy',
        },
        {
          type: 'Miejsce dostawy',
          value: '82-300 Elbląg, ul. Pasłęcka 9',
        },
        {
          type: 'Transport',
          value: 'własny',
        },
        {
          type: 'Osoba do kontaktu',
          value: 'Maciej Witkowski 507-073-251',
        },
      ],
      attachments: [
        {
          id: 0,
          name: 'Skan podpisanego zlecenia',
          userHasAccess: true,
        },
      ],
      timeline: [
        {
          id: 0,
          name: 'Utworzenie zlecenia',
          author: 'Monika Stenka',
          date: '14:23 - 22.03.2020 r.',
        },
        {
          id: 1,
          name: 'Akceptacja kierownika',
          author: 'Adam Kowalski - kierownik budowy',
          date: '11:02 - 23.03.2020 r.',
        },
      ],
    },
    {
      id: 1,
      title: 'Palowanie na karwinach',
      number: '0246/03/2021',
      offerNumber: '20-03 / OF / 0246',
      description: `
        Prosimy o potwierdzenie przyjęcia zlecenia do realizacji na email mwitkowski@budokop.pl lub brak odpowiedzi/potwierdzenia wiąże się z akceptacją warunków określonych w zleceniu. Prosimy aby na fakturzy powoływać się na numer zamówienia.

        Fakturę proszę wysłać na nasz adres korespondencyjny:
        BUDOKOP ul. Legionów 2, 83-300 Elbląg
      `,
      status: {
        key: 4,
      },
      accepting: 'Andrzej Paleta (przełożony)',
      date: {
        created: '29.07.2021',
        accepted: '29.07.2021',
        settled: '03.08.2021',
      },
      provider: 'PILETEST Sp. z o.o.',
      investor: 'BUDIMEX',
      investment: 'Palowanie na karwinach',
      region: 'GDA',
      subject: {
        priceUnit: 'PLN',
        items: [
          {
            name: 'Rura do żerdzi do DSM',
            price: 2450,
            quantity: 2,
          },
          {
            name: 'Paleta załadunkowa',
            price: 250,
            quantity: 8,
          },
        ],
      },
      terms: [
        {
          type: 'Warunki płatności',
          value: 'przelew w terminie 30 dni od daty wpływu do siedziby firmy na podstawie prawidłowo wystawionej faktury',
        },
        {
          type: 'Termin realizacji',
          value: '1 dzień roboczy',
        },
        {
          type: 'Miejsce dostawy',
          value: '82-300 Elbląg, ul. Pasłęcka 9',
        },
        {
          type: 'Transport',
          value: 'własny',
        },
        {
          type: 'Osoba do kontaktu',
          value: 'Maciej Witkowski 507-073-251',
        },
      ],
      attachments: [
        {
          id: 0,
          name: 'Skan podpisanego zlecenia',
          userHasAccess: true,
        },
      ],
      timeline: [
        {
          id: 0,
          name: 'Utworzenie zlecenia',
          author: 'Jerzy Farko',
          date: '14:23 - 29.07.2021 r.',
        },
        {
          id: 1,
          name: 'Akceptacja kierownika',
          author: 'Andrzej Paleta - kierownik budowy',
          date: '15:02 - 29.07.2021 r.',
        },
      ],
    },
  ],
};
