<template>
  <b-card>
    <b-tabs
      nav-class="nav-left"
    >

      <b-tab active>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Integracje</span>
        </template>

        <settings-tab-orders-integrations/>
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue';
import SettingsTabOrdersIntegrations from './SettingsTabOrdersIntegrations.vue';

export default {
  name: 'SettingsTabOrders',
  components: {
    SettingsTabOrdersIntegrations,
    BCard,
    BTabs,
    BTab,
  },
};
</script>
