import cars from './cars';
import equipment from './equipment';
import machines from './machines';
import transport from './transport';
import service from './service';
import examination from './examination';

/**
 * Base Tab routes
 */
const baseRoutes = [
  {
    path: '/base',
    redirect: '/base/machines',
    name: 'base',
    meta: {
      moduleRoute: 'Base',
      activeTab: 'machines',
      requiresAuth: true,
      showFiltration: true,
    },
  },
  ...machines,
  ...equipment,
  ...cars,
  ...transport,
  ...service,
  ...examination,
];

export default baseRoutes;
