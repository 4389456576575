<template>
  <div
    :class="{'vs-permission-editor-permission': true, expanded: childrenVisible }"
  >

    <div
      :class="{
        permission: true,
        'first-nested': nestedLevel === 0,
        'second-nested': nestedLevel === 1,
      }"
      :style="{
        '--top-offset': stickTop ? 0 : 80,
      }"
    >
      <permission-switcher
        :inheritAsDeny="noInherit && deniedChildrenCount === 0"
        :noInherit="noInherit"
        :value="value"
        @change="(state) => $emit('state:change', state, id)"
        :disabled="hideSwitchForSettings ? !forSettings : false"
      />

      <div
        class="permission-details"
        @click="childrenVisible = !childrenVisible"
      >
        <div class="name">
          {{ translatedPermissionName }}

          <div class="category-badge" v-if="forSettings">
            <feather-icon
              icon="SettingsIcon"
              size="10"
            />
            Ustawienia
          </div>
        </div>
        <span v-if="developmentMode" style="opacity: 0.5">
          {{ name }}
        </span>

        <div class="description">

          <div
            class="permission-count"
            v-if="nestedLevel !== 2"
          >

            <div class="permission-count-total" v-if="false">
              {{ totalChildrenCount }} uprawnienia łącznie
            </div>

            <div
              class="permission-count-granted"
              v-if="grantedChildrenCount > 0"
            >
              <feather-icon
                icon="CheckIcon"
                size="14"
              />
              <template v-if="grantedChildrenCount === totalChildrenCount">
                Przyznano wszystkie uprawnienia
              </template>
              <template v-else>
                Przyznano
                <span class="number">
                  {{ grantedChildrenCount }}
                </span>
              </template>
            </div>

            <div
              class="permission-count-denied"
              v-if="deniedChildrenCount > 0"
            >
              <feather-icon
                icon="XIcon"
                size="14"
              />
              <template v-if="deniedChildrenCount === totalChildrenCount">
                Odmówiono wszystkich uprawnień
              </template>
              <template v-else>
                Odmówiono
                <span class="number">
                  {{ deniedChildrenCount }}
                </span>
              </template>
            </div>
          </div>

        </div>
      </div>

      <div class="collapse-button" v-if="hasChildren">
        <vs-button
          vs-variant="link"
          @click="childrenVisible = !childrenVisible"
        >
          <feather-icon
            :icon="childrenVisible ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            size="14"
          />
          {{ childrenVisible ? 'Zwiń uprawnienia' : 'Rozwiń uprawnienia' }}
        </vs-button>
      </div>
    </div>

    <b-collapse
      v-model="childrenVisible"
      class="permission-children"
      v-if="hasChildren"
      >
      <div class="permission-children-permissions">
        <vs-permission-editor-permission
          v-for="child in computedChildren"
          :key="child.id"
          :id="child.id"
          :value="relativeState(value, child.state)"
          :name="child.name"
          :children="getChildrenArray(child)"
          :forSettings="child.forSettings"
          :nestedLevel="nestedLevel + 1"
          @state:change="(state, permissionId) => $emit('state:change', state, permissionId)"
          :hideSwitchForSettings="hideSwitchForSettings"
          :noInherit="noInherit"
        />
      </div>
    </b-collapse>

  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';
import { computed, ref, watch } from 'vue';
import i18n from '@/libs/i18n';
import PermissionSwitcher from '../vs-permission-manager/PermissionSwitcher.vue';
import VsButton from '../vs-button/VsButton.vue';

export default {
  name: 'VsPermissionEditorPermission',
  emits: ['state:change'],
  props: {
    id: Number,
    name: String,
    value: {
      type: String,
      validator: (val) => ['deny', 'inherit', 'grant'].includes(val),
    },
    forSettings: {
      type: Boolean,
      default: false,
    },
    children: {
      type: Array,
      default: () => {},
    },
    nestedLevel: {
      type: Number,
      default: 0,
    },
    hideSwitchForSettings: {
      type: Boolean,
      default: false,
    },
    stickTop: {
      type: Boolean,
      default: false,
    },
    noInherit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    developmentMode() {
      return process.env.NODE_ENV === 'development';
    },
  },
  setup(props) {
    const translatedPermissionName = computed(() => {
      let { name } = props;
      if (props.name?.endsWith('.*')) {
        name = name.slice(0, -2);
      }
      const translationPath = name.replaceAll('.', '.children.');

      if (name.split('.').length === 3) {
        if (i18n.te(`permission._genericActions.${name.split('.')[2]}`)) {
          return i18n.t(`permission._genericActions.${name.split('.')[2]}`);
        }
        return i18n.t(`permission.${translationPath}`);
      }

      return i18n.t(`permission.${translationPath}.name`);
    });

    const childrenVisible = ref(props.hideSwitchForSettings ? !props.forSettings : false);
    watch(() => props.hideSwitchForSettings, () => {
      childrenVisible.value = props.hideSwitchForSettings ? !props.forSettings : false;
    });

    const hasChildren = computed(() => props.children && props.children.length > 0);

    const getChildrenArray = (permission) => {
      const { children } = permission;
      if (!children) return [];
      if (Array.isArray(children)) return children;
      return Object.entries(children).map(([, child]) => child);
    };

    const relativeState = (parentState, childState) => {
      if (parentState !== 'inherit') return parentState;
      return childState;
    };

    const totalChildrenCount = computed(() => {
      const count = (children) => getChildrenArray({ children }).reduce((acc, child) => {
        if (!child.children) return acc + 1;
        return acc + count(child.children);
      }, 0);

      return count(props.children);
    });

    const countState = (state, childrenToCount) => {
      const loop = (children, ignoreState = false) => getChildrenArray({ children })
        .reduce((acc, child) => {
          if (ignoreState) return acc + 1;
          if (child.state === state && !child.children) return acc + 1;
          if (child.state === state) return acc + loop(child.children, true);
          if (child.children) return acc + loop(child.children);
          return acc;
        }, 0);

      return loop(childrenToCount);
    };

    const grantedChildrenCount = computed(() => {
      if (props.value === 'grant') return totalChildrenCount.value;
      if (props.value === 'deny') return 0;
      return countState('grant', props.children);
    });

    const deniedChildrenCount = computed(() => {
      if (props.value === 'deny') return totalChildrenCount.value;
      if (props.value === 'grant') return 0;
      return countState('deny', props.children);
    });

    const computedChildren = computed(() => {
      if (!props.hideSwitchForSettings) return props.children;
      return props.children.filter((c) => c.forSettings);
    });

    return {
      translatedPermissionName,
      computedChildren,
      childrenVisible,
      hasChildren,
      getChildrenArray,
      relativeState,
      grantedChildrenCount,
      deniedChildrenCount,
      totalChildrenCount,
    };
  },
  components: {
    BCollapse,
    PermissionSwitcher,
    FeatherIcon,
    VsButton,
  },
};
</script>

<style lang="scss" scoped>
$rail-color: var(--vs-card-bg-l-5);

$deny: #df6767;
$inherit: var(--vs-card-border);
$grant: #50d985;

.vs-permission-editor-permission {
  position: relative;
  padding-left: 20px;

  &:before {
    transition: 0.2s all ease-in-out;
    content: '';
    position: absolute;
    display: block;
    width: 2px;
    background: $rail-color;
    left: 42px;
    top: 37px;
    height: calc(100% - 40px - 18px);
    z-index: 0;
    opacity: 0;
    z-index: 0;
  }

  &.expanded {
    &:before,
    .permission:before {
      opacity: 1;
    }

    .permission-children-permissions {
      opacity: 1;
    }
  }

  .permission {
    --top-offset: 80px;

    gap: 15px;
    display: flex;
    align-items: center;
    padding: 3px 0;
    transition: 0.2s all ease-in-out;

    &.first-nested {
      padding-top: 20px;
      padding-bottom: 10px;
      background: var(--vs-card-bg);
      position: sticky;
      top: calc(var(--top-offset) * 1px);
      z-index: 10;
    }
    &.second-nested {
      padding-top: 20px;
      padding-bottom: 10px;
      background: var(--vs-card-bg);
      position: sticky;
      top: calc(var(--top-offset) * 1px - 12px);
      z-index: 9;

      &:before {
        left: -18px;
      }
    }

    &:before {
      transition: 0.2s all ease-in-out;
      content: '';
      position: absolute;
      display: block;
      width: 13px;
      height: 2px;
      background: $rail-color;
      left: 4px;
      z-index: 0;
      opacity: 0;
    }

    .permission-details {
      cursor: pointer;
      min-height: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .name {
        font-weight: 500;
        display: flex;
        align-items: center;
        gap: 5px;

        &-raw {
          font-weight: 400;
          opacity: 0.3;
        }

        .category-badge {
          font-weight: 500;
          font-size: 8pt;
          background: var(--vs-card-bg-l-5);
          color: var(--vs-card-bg-l-40);
          border-radius: 3px;
          padding: 0 4px;
          display: flex;
          align-items: center;
          gap: 5px;
          text-transform: uppercase;
        }
      }
      .description {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 10pt;
      }
    }
  }

  .permission-children {
    margin-left: 20px;
  }
  .permission-children-permissions {
    opacity: 0;
    transition: 0.1s all ease-in-out;
  }

  .permission-count {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 8pt;
    text-transform: uppercase;

    .number {
      font-weight: 700;
    }

    &-total {
      font-weight: 400;
    }
    &-granted {
      color: $grant;
    }
    &-denied {
      color: $deny;
    }
  }

  .collapse-button {
    margin-left: auto;

    .vs-button {
      font-size: 9pt;

      .vs-button-content {
        display: flex !important;
        align-items: center !important;
        gap: 10px !important;
      }
    }
  }
}
</style>
