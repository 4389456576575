export default {
  created: {
    default: 'Dodano komentarz do zadania',
  },
  updated: {
    default: 'Zaktualizowano komentarz do zadania',
  },
  deleted: {
    default: 'Usunięto komentarz do zadania',
  },
};
