<template>
  <div class="settings-tab-crm-industries-form">
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('crm.industry.field.name')"
            label-cols-md="3"
            :invalid-feedback="errors.name"
            :state="errors.name === null"
            >
            <b-input-group>
              <b-form-input
                :placeholder="$t('crm.industry.field.name')"
                v-model="industry.name"/>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue';
import { inject } from 'vue';

export default {
  name: 'SettingsTabCrmIndustryForm',
  setup() {
    const { industry } = inject('industriesInstance');
    const errors = {};

    return { industry, errors };
  },
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
  },
};
</script>
