export const areArraysEqual = (a, b) => {
  // @ https://stackoverflow.com/a/14853974
  if (!a || !b) return false;

  if (a.length !== b.length) return false;

  for (let i = 0, l = a.length; i < l; i += 1) {
    if (a[i] instanceof Array && b[i] instanceof Array) {
      if (!a[i].equals(b[i])) { return false; }
    } else if (a[i] !== b[i]) {
      return false;
    }
  }
  return true;
};

export default areArraysEqual;
