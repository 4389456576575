var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vs-permission-manager"
  }, [Object.keys(_vm.permissionTree).length === 0 ? _c('vs-loader', {
    attrs: {
      "text": "Przygotowywanie uprawnień"
    }
  }) : _vm._e(), _vm._l(Object.keys(_vm.permissionTree), function (module, idx) {
    return _c('div', {
      key: idx
    }, [_c('permission-entry-module', {
      attrs: {
        "name": module,
        "resources": _vm.permissionTree[module],
        "fetched": _vm.fetchedByModule[module],
        "stickTop": _vm.stickTop,
        "hideCount": _vm.hideCount,
        "noInherit": _vm.noInherit
      },
      on: {
        "update": function (data) {
          return _vm.updateModulePermissions(module, data);
        },
        "ready": _vm.onModuleReady
      }
    })], 1);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }