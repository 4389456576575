var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "feedback-toast"
  }, [_c('div', {
    staticClass: "indicator"
  }), _c('div', {
    staticClass: "feedback-content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._t("title", function () {
    return [_vm._v(" Feedback Title Slot ")];
  })], 2), _c('div', {
    staticClass: "description"
  }, [_vm._t("description", function () {
    return [_vm._v(" Feedback Description Slot ")];
  })], 2), _vm.action ? _c('b-button', {
    staticClass: "action pl-0 pr-0",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return function () {
          return _vm.action();
        }.apply(null, arguments);
      }
    }
  }, [_vm._t("action", function () {
    return [_vm._v(" Feedback Action Name Slot ")];
  })], 2) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }