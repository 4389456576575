import { computed, ref } from 'vue';
import useCoreApi from './useCoreApi';

const permissions = ref([]);

export default function usePermissions() {
  const fetchPermissionList = () => {
    useCoreApi()
      .fetchPermissionList()
      .then(({ data }) => {
        permissions.value = data.data;
      })
      .catch(() => {

      });
  };

  if (permissions.value.length === 0) fetchPermissionList();

  const precisePermissions = computed(() => permissions.value.filter(({ name }) => !name.includes('*')));

  const permissionTree = computed(() => {
    const tree = {};
    // eslint-disable-next-line no-useless-escape
    const regex = /^([^\.]*[^\.]\.){2}[^\.]*[^\.]$/;

    precisePermissions.value.forEach(({ name }) => {
      if (!regex.test(name)) return;

      const [module, resource, action] = name.split('.');

      tree[module] = {
        ...tree[module] ?? {},
      };

      tree[module][resource] = [
        ...tree[module][resource] ?? [],
        action,
      ];
    });

    return tree;
  });

  const getPermissionModule = (perm) => perm.split('.')[0];

  const getModulePermissions = (module) => permissions.value
    .filter((perm) => getPermissionModule(perm.name) === module)
    .map((perm) => ({ ...perm, state: null }));

  const getModuleWildcards = (module) => permissions.value
    .filter((perm) => getPermissionModule(perm.name) === module)
    .filter((perm) => perm.name.endsWith('.*') && perm.name !== `${module}.*`);

  const getPermissionChildren = (perm) => {
    if (!perm.endsWith('.*')) return [];
    const permString = perm.slice(0, -1);

    const children = permissions.value.filter((p) => p.name.startsWith(permString));
    const notWildcards = children.filter((c) => !c.name.endsWith('.*'));

    let compChildren = [...notWildcards];

    children.forEach((c) => {
      if (c.name === perm || c.name.endsWith('.*')) return;
      compChildren = [...compChildren, ...getPermissionChildren(c.name, false)];
    });

    return compChildren;
  };

  return {
    fetchPermissionList,
    permissions,
    permissionTree,

    getModulePermissions,
    getModuleWildcards,
    getPermissionChildren,
  };
}
