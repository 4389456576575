<template>
  <div class="unauthorized-pane">

    <feather-icon
      class="icon"
      icon="EyeOffIcon"
      size="32"
    />

    <div class="title">
      Brak dostępu
    </div>

    <div class="explanation">
      Nie masz uprawnień do tej części aplikacji.
      Jeżeli uważasz, że powinieneś/powinnaś mieć dostęp do tej sekcji,
      skontaktuj się z administratorem strony.
    </div>

    <div class="permission">
      {{ permission }}
    </div>

  </div>
</template>

<script>
export default {
  name: 'UnauthorizedPane',
  props: {
    permission: {
      type: String,
    },
  },
};
</script>

<style lang="sass" scoped>
.unauthorized-pane
  display: flex
  flex-direction: column
  gap: 5px
  width: 100%
  align-items: center
  text-align: center
  border-radius: 5px
  padding: 50px 0

  .icon
    margin-bottom: 10px

  .title
    font-weight: 500
    font-size: 14pt

  .explanation
    max-width: 500px

  .permission
    opacity: 0.7
</style>
