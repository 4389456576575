var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vs-list-item"
  }, [_c('div', {
    staticClass: "vs-list-item-cell title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm._t("cells", null, {
    "row": _vm.item
  }), !_vm.disableActions ? _c('div', {
    staticClass: "vs-list-item-cell button-group"
  }, [_c('b-button', {
    staticClass: "list-button",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('edit');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Edit2Icon",
      "size": "16"
    }
  }), _c('div', {
    staticClass: "list-button-text"
  }, [_vm._v(" Edytuj ")])], 1), _c('b-button', {
    staticClass: "list-button",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('delete');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Trash2Icon",
      "size": "16"
    }
  }), _c('div', {
    staticClass: "list-button-text"
  }, [_vm._v(" Usuń ")])], 1)], 1) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }