var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settings-tab-investments-materials"
  }, [_c('authorization-guard', {
    attrs: {
      "silent": "",
      "permission": "investment.requirement_group.store"
    }
  }, [_c('div', {
    staticClass: "buttons"
  }, [_c('div', {
    staticClass: "group"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.addMaterialModal.visible = true;
      }
    }
  }, [_vm._v(" + Materiał ")])], 1)])]), _c('authorization-guard', {
    attrs: {
      "permission": "investment.requirement_group.index"
    }
  }, [_c('vs-list', {
    attrs: {
      "showHeaders": "",
      "items": _vm.materialResource,
      "headers": ['Nazwa', 'Jednostka']
    },
    on: {
      "edit": _vm.openEditModal,
      "delete": _vm.deleteMaterial
    },
    scopedSlots: _vm._u([{
      key: "cells",
      fn: function (_ref) {
        var row = _ref.row;
        return [_c('div', {
          staticClass: "vs-list-item-cell"
        }, [_vm._v(" " + _vm._s(_vm.getUnit(row.core_unit_id).code) + " ")])];
      }
    }])
  })], 1), _c('investment-material-modal-add', {
    on: {
      "success": _vm.addMaterial
    }
  }), _c('investment-material-modal-edit', {
    on: {
      "success": _vm.updateMaterial
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }