// import store from '@/store/index';
import Echo from 'laravel-echo';
import ApiService from '@/services/api';

window.Pusher = require('pusher-js');

const {
  VUE_APP_USE_HTTPS,
  VUE_APP_ECHO_HOST,
  VUE_APP_ECHO_PORT,
  VUE_APP_ECHO_KEY,
  VUE_APP_ECHO_CLUSTER,
} = process.env;

let echoOptions = {
  broadcaster: 'pusher',
  wsHost: VUE_APP_ECHO_HOST,
  wsPort: VUE_APP_ECHO_PORT,
  key: VUE_APP_ECHO_KEY,
  cluster: VUE_APP_ECHO_CLUSTER,
  authEndpoint: `http://${VUE_APP_ECHO_HOST}/broadcasting/auth`,
  authorizer: (channel) => ({
    authorize: (socketId, callback) => {
      ApiService.post('/broadcasting/auth', {
        socket_id: socketId,
        channel_name: channel.name,
      })
        .then((response) => {
          callback(false, response.data);
        })
        .catch((error) => {
          callback(true, error);
        });
    },
  }),
  forceTLS: false,
};

if (VUE_APP_USE_HTTPS === 'true') {
  echoOptions = {
    ...echoOptions,
    wssHost: echoOptions.wsHost,
    wssPort: echoOptions.wsPort,
    authEndpoint: `https://${VUE_APP_ECHO_HOST}/broadcasting/auth`,
    forceTLS: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
  };
}

const echo = new Echo(echoOptions);

export default echo;
