var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "powertools-dropdown"
  }, [_c('b-dropdown', {
    attrs: {
      "size": "sm",
      "variant": "link",
      "toggle-class": "text-decoration-none",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "powertools-dropdown-button"
        }, [_c('feather-icon', {
          attrs: {
            "size": "21",
            "icon": "ZapIcon"
          }
        }), _c('div', {
          staticClass: "text"
        }, [_vm._v(" Powertools ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('powertools-item', {
    attrs: {
      "enabled": _vm.$store.state.app['disableModuleCheck']
    },
    on: {
      "click": function () {
        return _vm.$store.commit('app/TOGGLE_MODULE_CHECK');
      }
    }
  }, [_vm._v(" Ignoruj status modułów ")]), _c('powertools-item', {
    attrs: {
      "enabled": _vm.$store.state.app['simulateThrottling']
    },
    on: {
      "click": function () {
        return _vm.$store.commit('app/TOGGLE_THROTTLING_SIMULATION');
      }
    }
  }, [_vm._v(" Symuluj słabe łącze ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }