<template>
  <div class="profile-settings">
    <b-tabs pills vertical>
      <b-tab v-for="(tab, index) in tabs" :key="`profile-setting-tab-${index}`">

        <template #title>
          <feather-icon :icon="tab.icon" />
          {{ tab.name }}
        </template>

        <b-card>
          <component :is="tab.component" />
        </b-card>

      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
  BCard,
} from 'bootstrap-vue';
import ProfileSettingsDevices from '@/components/views/profile/settings/ProfileSettingsDevices.vue';

export default {
  name: 'ProfileSettings',
  setup() {
    const tabs = [
      {
        name: 'Urządzenia',
        icon: 'SmartphoneIcon',
        component: ProfileSettingsDevices,
      },
    ];

    return { tabs };
  },
  components: {
    BTabs,
    BTab,
    BCard,
  },
};
</script>
