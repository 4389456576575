import { ref } from 'vue';
import useCRMApi from '@/hooks/crm/useCRMApi';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import showToast from '@/libs/toasts';
import {
  CreateIndustrySuccess,
  CreateIndustryError,
  DeleteIndustryError,
  DeleteIndustrySuccess,
  UpdateIndustryError,
  UpdateIndustrySuccess,
} from '@/libs/toasts/feedback/module/crm';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

const useIndustry = () => {
  const industries = ref([]);
  const industry = ref({});

  const modalAddVisible = ref(false);
  const modalEditVisible = ref(false);

  const isLoading = ref(false);

  const fetch = () => {
    useCRMApi()
      .fetchIndustries()
      .then(({ data }) => {
        industries.value = data.data;
      })
      .catch(() => {
      });
  };

  const createIndustry = () => {
    isLoading.value = true;

    useCRMApi()
      .createIndustry(industry.value)
      .then(({ data }) => {
        isLoading.value = false;
        industries.value.push(data.data);
        modalAddVisible.value = false;

        showToast(CreateIndustrySuccess, { name: data.data.name });
        RemoteResourceManager.getInstance().notify('crm.industry');
        industry.value = {};
      })
      .catch(() => {
        isLoading.value = false;
        showToast(CreateIndustryError, { name: industry.value.name });
      });
  };

  const editIndustry = () => {
    isLoading.value = true;

    useCRMApi()
      .updateIndustry(industry.value)
      .then(({ data }) => {
        isLoading.value = false;
        industries.value = industries.value.map((s) => (s.id === data.data.id ? data.data : s));
        modalEditVisible.value = false;

        showToast(UpdateIndustrySuccess, { name: data.data.name });
        RemoteResourceManager.getInstance().notify('crm.industry');
        industry.value = {};
      })
      .catch(() => {
        isLoading.value = false;
        showToast(UpdateIndustryError, { name: industry.value.name });
      });
  };

  const deleteIndustry = async (requested) => {
    const confirmation = await deleteModal({
      title: i18n.t('crm.industry.modal.delete.title'),
      text: i18n.t('crm.industry.modal.delete.text', {
        name: requested.name,
      }),
    });

    if (!confirmation) return false;

    return useCRMApi()
      .deleteIndustry(requested.id)
      .then(() => {
        industries.value = industries.value.filter((s) => s.id !== requested.id);
        showToast(DeleteIndustrySuccess, { name: requested.name });
        RemoteResourceManager.getInstance().notify('crm.industry');
      })
      .catch(() => showToast(DeleteIndustryError, { name: requested.name }));
  };

  const openAddModal = () => {
    industry.value = {};
    modalAddVisible.value = true;
  };

  const openEditModal = (edited) => {
    industry.value = { ...edited };
    modalEditVisible.value = true;
  };

  return {
    industry,
    industries,
    modalAddVisible,
    modalEditVisible,
    fetch,
    openAddModal,
    openEditModal,
    createIndustry,
    editIndustry,
    deleteIndustry,
    isLoading,
  };
};

export default useIndustry;
