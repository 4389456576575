var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-reinforcement-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.errors,
      "i18nNamespace": "investments.form.reinforcement.field"
    },
    model: {
      value: _vm.reinforcement,
      callback: function ($$v) {
        _vm.reinforcement = $$v;
      },
      expression: "reinforcement"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "name"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.reinforcement.field.name')
    },
    model: {
      value: _vm.reinforcement.name,
      callback: function ($$v) {
        _vm.$set(_vm.reinforcement, "name", $$v);
      },
      expression: "reinforcement.name"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }