var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "permission-entry-module"
  }, [_c('div', {
    class: `header ${_vm.stickTop && 'stick-top'}`,
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) return null;
        _vm.collapsed = !_vm.collapsed;
      }
    }
  }, [_c('permission-switcher', {
    attrs: {
      "value": _vm.getPermissionState(`${_vm.name}.*`),
      "noInherit": _vm.noInherit
    },
    on: {
      "change": function (type) {
        return _vm.togglePermission(`${_vm.name}.*`, type);
      }
    }
  }), _c('div', {
    staticClass: "header-text",
    on: {
      "click": function ($event) {
        _vm.collapsed = !_vm.collapsed;
      }
    }
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.moduleTranslation) + " ")]), _vm._v(" " + _vm._s(`${_vm.name}.*`) + " ")]), !_vm.hideCount ? _c('div', [_vm._v(" Przyznano " + _vm._s(_vm.enabledPermissionsCount) + " z " + _vm._s(_vm.totalPermissionsCount) + " uprawnień ")]) : _vm._e(), _c('div', {
    staticClass: "collapse-indicator",
    on: {
      "click": function ($event) {
        _vm.collapsed = !_vm.collapsed;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": _vm.collapsed ? 'ChevronUpIcon' : 'ChevronDownIcon',
      "size": "22"
    }
  })], 1)], 1), _c('b-collapse', {
    model: {
      value: _vm.collapsed,
      callback: function ($$v) {
        _vm.collapsed = $$v;
      },
      expression: "collapsed"
    }
  }, _vm._l(Object.keys(_vm.resources), function (resource, idx) {
    return _c('permission-entry-resource', {
      key: `resource-${idx}`,
      attrs: {
        "module": _vm.name,
        "name": resource,
        "actions": _vm.resources[resource],
        "state": _vm.newWay,
        "hideCount": _vm.hideCount,
        "noInherit": _vm.noInherit
      },
      on: {
        "change": _vm.handlePermissionStateChange
      }
    });
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }