export default {
  investment: {
    tab: {
      file: {
        name: 'Kartoteka inwestycji',
        section: {
          details: 'Dane inwestycji',
          location: 'Lokalizacja',
          estimateDeadline: 'Terminy - szacowane',
          contractDeadline: 'Terminy - z umowy',
        },
      },
      subject: {
        name: 'Przedmiot zamówienia',
        section: {
          list: 'Lista przedmiotów zamówienia',
        },
        button: {
          add: 'Dodaj przedmiot zamówienia',
        },
        table: {
          diameter: 'średnica',
          length: 'długość',
          mb: 'mb',
          quantity: 'ilość',
          reinforcement: 'zbrojenie',
          technology: 'technologia',
          progress: 'wykonane',
        },
        field: {
          diameter: 'Średnica',
          length: 'Długość',
          quantity: 'Ilość',
          investment_subject_reinforcement_id: 'Zbrojenie',
          base_technology_id: 'Technologia',
        },
      },
      demand: {
        table: {
          class: 'Rodzaj',
          price: 'Cena',
          quantity: 'Ilość',
          value: 'Wartość',

          provider: 'Dostawca',
          attachments: 'Załączniki',
          choosing: 'Wybór oferty',
          valid_date: 'Ważna do',
          guarantee_price_date: 'Gwarancja ceny',
        },
        field: {
          investment_requirement_group_id: 'Materiał',
          name: 'Rodzaj',
          price: 'Cena',
          value: 'Ilość',
          core_unit_id: 'Jednostka',
        },
      },
      offer: {
        field: {
          investment_requirement_group_id: 'Materiał',
          crm_provider_id: 'Dostawca',
          valid_date: 'Termin ważności',
          guarantee_price_date: 'Gwarancja ceny',
          value: 'Wartość',
        },
      },
      resource: {
        field: {
          investment_id: 'Inwestycja',
          base_resource_id: 'Zasób',
          base_technology_id: 'Technologia',
          resource_data: {
            scheduler: {
              start_date: 'Początek pracy',
              end_date: 'Koniec pracy',
            },
            days_number: 'Liczba dni',
          },
        },
        table: {
          name: 'Nazwa',
          type: 'Typ',
          category: 'Kategoria',
          technology: 'Technologia',
          date_from: 'Data od',
          date_to: 'Data do',
          price_day: 'Cena/dzień',
          work_days: 'Ilość',
          total_value: 'Wartość',
        },
      },
      cadre: {
        table: {
          position: 'Stanowisko',
          full_name: 'Imię i nazwisko',
          date_from: 'Data od',
          date_to: 'Data do',
          price_day: 'Cena/dzień',
          work_days: 'Ilość',
          total_value: 'Wartość',
        },
        field: {
          brigade_id: 'Brygada',
          cadre_employee_id: 'Pracownik',
          cadre_position_id: 'Stanowisko',
          start_date: 'Data od',
          end_date: 'Data do',
          days_number: 'Ilość dni',
        },
      },
      brigade: {
        field: {
          name: 'Nazwa',
          investment_base_vehicles: 'Maszyna',
        },
      },
    },
    field: {
      name: 'Nazwa inwestycji',
      shortcut: 'Skrót',
      core_branch_id: 'Region',
      crm_investor_id: 'Inwestor',
      crm_principal_id: 'Zleceniodawca',
      external_contract: 'Nr umowy zewnętrznej',
      serial: 'Identyfikator',
      investment_status_id: 'Status',
      offer: 'Oferta',

      street: 'Adres',
      post_code: 'Kod pocztowy',
      city: 'Miasto',
      plot_number: 'Nr działki',
      latitude: 'Szerokość geograficzna',
      longitude: 'Długość geograficzna',
      address_description: 'Dodatkowy opis lokalizacji',

      contract_scheduler: {
        start_date: 'Rozpoczęcie prac',
        end_date: 'Zakończenie prac',
        documentation_date: 'Przekazanie dokumentacji',
      },
      estimated_scheduler: {
        start_date: 'Rozpoczęcie prac',
        end_date: 'Zakończenie prac',
        documentation_date: 'Przekazanie dokumentacji',
      },
      work_groups: 'Grupa',
      work_types: 'Typ robót',
    },
  },
  report: {
    work: {
      table: {
        technology: 'Technologia',
        diameter: 'Średnica',
        quantity: 'Ilość',
        mb: 'mb',
        project_mb: 'mb projekt.',
        concrete: 'Beton',
        steel: 'Stal',
        aggregate: 'Kruszywo',
      },
    },
    field: {
      investment_id: 'Inwestycja',
      daily_balance_date: 'Data raportu',
      reporter_id: 'Raportujący',
      work_time: 'Praca (od - do)',
      production_time: 'Produkcja (od - do)',
      investment_brigade_id: 'Brygada',
      management: 'Kierownictwo',
    },
  },
  material: {
    field: {
      name: 'Nazwa materiału',
      core_unit_id: 'Jednostka',
    },
  },
  reinforcement: {
    field: {
      name: 'Nazwa zbrojenia',
    },
  },
  workType: {
    field: {
      name: 'Typ robót',
    },
  },
  workGroup: {
    field: {
      name: 'Nazwa grupy',
    },
  },
};
