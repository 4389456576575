var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    class: `abstract-module-label ${_vm.short ? 'abstract-module-label--short' : ''}`,
    style: _vm.labelStylesheet,
    attrs: {
      "to": _vm.to
    }
  }, [_c('feather-icon', {
    staticClass: "abstract-module-label__icon",
    attrs: {
      "icon": _vm.icon,
      "size": _vm.style.iconSize
    }
  }), _c('div', {
    staticClass: "abstract-module-label__content"
  }, [_vm._t("default", function () {
    return [_vm._v(" " + _vm._s(_vm.name) + " ")];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }