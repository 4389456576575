<template>
  <div class="vs-permission-editor">

    <vs-loader
      text="Przygotowywanie uprawnień"
      v-if="Object.entries(categorized).length === 0"
    />

    <b-form-checkbox
      class="toggle-settings"
      switch
      size="md"
      v-model="showSettingsOnly"
      v-else
    >
      Pokaż tylko dotyczące ustawień aplikacji
    </b-form-checkbox>

    <vs-permission-editor-permission
      v-for="module in Object.entries(categorized)
        .map(([, a]) => a)
        .sort((a, b) => {
          const aIdx = moduleOrder.indexOf(b.name);
          const bIdx = moduleOrder.indexOf(a.name);
          if (aIdx < 0) return -1;
          if (bIdx < 0) return 1;
          return aIdx < bIdx;
        })"
      :key="module.id"
      :id="module.id"
      :value="module.state"
      :name="module.name"
      :children="Object
        .entries(module.children)
        .map(([, child]) => child)
      "
      :noInherit="noInherit"
      :stickTop="stickTop"
      :forSettings="module.forSettings"
      @state:change="(state, permissionId) => changeState(state, permissionId, true)"
      :hideSwitchForSettings="showSettingsOnly"
    />
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue';
import { computed, ref, watch } from 'vue';
import useCoreApi from '@/hooks/useCoreApi';
import VsPermissionEditorPermission from './VsPermissionEditorPermission.vue';
import VsLoader from '../vs-loader/VsLoader.vue';

export default {
  name: 'VsPermissionEditor',
  emits: ['update:granted', 'update:denied'],
  props: {
    fetched: {
      type: Array,
      default: () => [],
    },
    stickTop: {
      type: Boolean,
      default: false,
    },
    noInherit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const permState = {
      GRANT: 'grant',
      DENY: 'deny',
      INHERIT: 'inherit',
    };

    const moduleOrder = [
      'core.*',
      'tasks.*',
      'orders.*',
      'cadre.*',
      'crm.*',
      'base.*',
      'investment.*',
    ];

    // FIXME: move to @/libs
    const settingsRelatedPermissions = [
      'core.*',
      'base.group.*',
      'base.integration_employee.*',
      'base.producer.*',
      'base.producer_model.*',
      'cadre.employment_form.*',
      'cadre.group.*',
      'cadre.position.*',
      'cadre.skill.*',
      'crm.company_industry.*',
      'crm.company_region.*',
      'crm.company_status.*',
      'investment.work_group.*',
      'investment.work_type.*',
      'orders.integration_crm.*',
      'orders.integration_task.*',
      'orders.statuses.*',
      'tasks.status.*',
    ];

    const blacklist = [
      'crm.company_industry.un_assign',
      'crm.company_industry.update',
      'crm.company_status.*',
      'investment.subject_balance_progress.*',
      'investment.subject_balance_usage.*',
      'orders.comment.change_active',
      'orders.comment.change_active',
      'orders.comment.show',
      'orders.statuses.*',
      'tasks.status.*',
    ];

    const permissions = ref([]);

    const permissionById = (permissionId) => permissions.value
      .find(({ id }) => id === permissionId);

    const isPermissionBlacklisted = (permissionName) => {
      const [module, resource] = permissionName.split('.');
      return blacklist.includes(`${module}.*`)
        || blacklist.includes(`${module}.${resource}.*`)
        || blacklist.includes(permissionName);
    };

    // returns categorized permissions
    // side-effect: add parent and children to permissions ref
    const catMethod = () => {
      const obj = {};

      const createModuleEntry = (perm) => {
        const [module] = perm.name.split('.');

        if (!module) return;
        if (module in obj) return;

        obj[module] = { ...perm, children: {} };
      };

      const createResourceEntry = (perm) => {
        const [module, resource] = perm.name.split('.');
        createModuleEntry(perm);

        if (!resource) return;
        if (resource === '*') return;
        if (resource in obj[module].children) return;

        obj[module].children[resource] = {
          ...perm,
          forSettings: obj[module].forSettings || perm.forSettings,
          children: {},
        };

        // set children
        permissionById(obj[module].id).children.push(perm.id);
      };

      const createActionEntry = (perm) => {
        const [module, resource, action] = perm.name.split('.');
        createResourceEntry(perm);

        if (!action) return;
        if (action === '*') return;
        if (action in obj[module].children[resource].children) return;

        obj[module].children[resource].children[action] = {
          ...perm,
          forSettings: obj[module].forSettings
            || obj[module].children[resource].forSettings
            || perm.forSettings,
        };

        // set parent and children
        permissionById(obj[module].children[resource].id).parent = obj[module].id;
        permissionById(obj[module].children[resource].id).children.push(perm.id);
        permissionById(perm.id).parent = obj[module].children[resource].id;
      };

      permissions.value.forEach((permission) => {
        if (!permission?.name) return;
        if (isPermissionBlacklisted(permission.name)) return;

        if (permission.children) {
          permissionById(permission.id).children = [];
        }

        createActionEntry(permission);
      });

      return obj;
    };

    const categorized = computed(() => catMethod());

    const changeState = (state, permissionId, invokedManually = false) => {
      const permission = permissionById(permissionId);
      const parentPermission = permissionById(permission?.parent);

      // return if state stays the same
      if (parentPermission
        && state !== permState.INHERIT
        && (
          parentPermission.state === state
          || permissionById(parentPermission.parent)?.state === state)
      ) {
        return;
      }

      const previousPermissionState = permission.state;
      permission.state = state;

      // unset all children of permission
      if (permission.children) {
        permission.children.forEach((id) => changeState(permState.INHERIT, id));
      }

      const syncSiblingsToParent = (syncingParentId, forcedState) => {
        const parentToSync = permissionById(syncingParentId);
        const stateToSync = forcedState ?? parentToSync?.state;

        if (parentToSync && stateToSync !== permState.INHERIT) {
          parentToSync.state = permState.INHERIT;
          parentToSync.children
            .filter((id) => id !== permission.id)
            // .forEach((id) => changeState(previousParentState, id));
            .forEach((id) => {
              if (forcedState) permissionById(id).state = stateToSync;
              else changeState(stateToSync, id);
            });
        }
      };

      if (state === permState.INHERIT) {
        // change permission state to inherit (keeping the structure)
        if (!parentPermission
          || !invokedManually) return;

        const permissionHadState = (searchedState) => (previousPermissionState === searchedState
          || parentPermission?.state === searchedState
          || permissionById(parentPermission?.parent)?.state === searchedState);

        if (permissionHadState(permState.DENY)) {
          changeState(permState.GRANT, permissionId);
        }

        if (permissionHadState(permState.GRANT)) {
          changeState(permState.DENY, permissionId, true);
        }

        const allSiblingsInherit = parentPermission.children
          .every((s) => {
            if (s.id === permissionId) return true;
            return permissionById(s).state === permState.INHERIT;
          });

        changeState(permState.INHERIT, permissionId, allSiblingsInherit);

        return;
      }

      // sync permission state with its children
      if (parentPermission
        && parentPermission.children
          .every((sibling) => permissionById(sibling).state === state)) {
        changeState(state, parentPermission.id);
        return;
      }

      // change state of the module permission on action state change
      if (parentPermission?.parent) {
        const modulePermission = permissionById(parentPermission.parent);

        if (modulePermission.state === permState.INHERIT) {
          syncSiblingsToParent(parentPermission.id);
          return;
        }

        const previousModuleState = modulePermission.state;
        modulePermission.state = permState.INHERIT;

        modulePermission.children.forEach((childId) => {
          if (childId === parentPermission?.id) return;
          changeState(previousModuleState, childId);
        });

        syncSiblingsToParent(parentPermission.id, previousModuleState);
      } else {
        syncSiblingsToParent(parentPermission?.id);
      }
    };

    const permissionIdsByState = (searchedState) => permissions.value
      .filter(({ state }) => state === searchedState)
      .map(({ id }) => id);

    const grantedIds = computed(() => permissionIdsByState(permState.GRANT));
    const deniedIds = computed(() => permissionIdsByState(permState.DENY));

    const updateFetched = () => {
      props.fetched.forEach(({ id, disabled }) => {
        if (!permissionById(id)) return;
        permissionById(id).state = disabled ? 'deny' : 'grant';
      });
    };

    const fetchPermissionList = () => {
      useCoreApi()
        .fetchPermissionList()
        .then(({ data }) => {
          permissions.value = data.data.map((perm) => {
            const permObj = {
              ...perm,
              state: permState.INHERIT,
              forSettings: settingsRelatedPermissions.includes(perm.name),
            };

            if (perm.name?.split('.').length !== 2 || !perm.name.endsWith('*')) {
              permObj.parent = null;
            }

            if (perm.name?.split('.').length !== 3 || perm.name.endsWith('*')) {
              permObj.children = [];
            }

            return permObj;
          });
          updateFetched();
        })
        .catch(() => {

        });
    };
    fetchPermissionList();

    watch(grantedIds, (val) => emit('update:granted', val));
    watch(deniedIds, (val) => emit('update:denied', val));

    watch(props, updateFetched);

    const showSettingsOnly = ref(false);

    return {
      permissions,
      categorized,
      changeState,
      settingsRelatedPermissions,
      grantedIds,
      deniedIds,
      showSettingsOnly,
      moduleOrder,
    };
  },
  components: {
    VsPermissionEditorPermission,
    BFormCheckbox,
    VsLoader,
  },
};
</script>

<style lang="sass" scoped>
.toggle-settings
  margin-left: 20px
</style>
