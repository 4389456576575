import { buildRequest } from '@/services/api';

const prefix = 'api/cadre';

const useCadreApi = () => ({
  /**
   * Medical Examinations
   */
  addMedicalExamination: (formData) => buildRequest('post', `${prefix}/medical-exam`, formData),

  updateMedicalExamination: (examinationId, formData) => buildRequest('post', `${prefix}/medical-exam/${examinationId}`, formData),

  deleteMedicalExamination: (examinationId) => buildRequest('delete', `${prefix}/medical-exam/${examinationId}`),

  /**
   * Employee
   */
  employee: {
    fetch: () => buildRequest('get', `${prefix}/employee/index`),
    fetchPicker: () => buildRequest('get', `${prefix}/employee/get-employees`),
    createAccount: (employeeId) => buildRequest('put', `${prefix}/employee/${employeeId}/create-account`),
  },

  /**
   * Contract (form of employment)
   */
  contract: {
    fetch: () => buildRequest('get', `${prefix}/form/index`),
  },

  /**
   * Group
   */
  group: {
    fetch: () => buildRequest('get', `${prefix}/group/index`),
    create: () => buildRequest('post', `${prefix}/group`),
  },

  /**
   * Examination
   */
  examination: {
    fetchTypes: () => buildRequest('get', `${prefix}/medical-exam/get-medical-exam-types`),
  },

  /**
   * Position
   */
  position: {
    fetch: () => buildRequest('get', `${prefix}/position/index`),
  },

  /**
   * Skill
   */
  skill: {
    fetch: () => buildRequest('get', `${prefix}/skill/index`),
  },

  /**
   * Status
   */
  status: {
    fetch: () => buildRequest('get', `${prefix}/statuses`),
  },

  /**
   * Safety Trainings
   */
  addSafetyTraining: (formData) => buildRequest('post', `${prefix}/safety-training`, formData),

  updateSafetyTraining: (trainingId, formData) => buildRequest('post', `${prefix}/safety-training/${trainingId}`, formData),

  deleteSafetyTraining: (trainingId) => buildRequest('delete', `${prefix}/safety-training/${trainingId}`),

  /**
   * Employees (picker)
   */
  fetchPickerEmployees: () => buildRequest('get', `${prefix}/employee/get-employees`),

  /**
   * Export
   */
  exportData: (fetchParams) => buildRequest('get', `${prefix}/employee/export`, fetchParams),
});

export default useCadreApi;
