<template>
  <div class="feedback-toast">
    <div class="indicator"></div>
    <div class="feedback-content">
      <div class="title">

        <slot name="title">
          Feedback Title Slot
        </slot>

      </div>
      <div class="description">

        <slot name="description">
          Feedback Description Slot
        </slot>

      </div>
      <b-button v-if="action"
        variant="link"
        class="action pl-0 pr-0"
        @click.prevent="() => action()"
        >

        <slot name="action">
          Feedback Action Name Slot
        </slot>

      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';

export default {
  props: {
    action: {
      type: Function,
      default: null,
    },
  },
  components: {
    BButton,
  },
};
</script>

<style lang="sass" scoped>
.feedback-content
  .title
    font-weight: 700
  .description
    font-weight: 400
</style>
