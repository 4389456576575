import { buildRequest } from '@/services/api';

const prefix = 'api/tasks';

const useTasksApi = () => ({
  /**
   * Status
   */
  fetchStatuses: () => buildRequest('get', `${prefix}/statuses`),
  /**
   * Export
   */
  exportData: () => buildRequest('get', `${prefix}/export`),
});

export default useTasksApi;
