var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "orders"
  }, [_c('div', {
    staticClass: "listWrapper",
    class: {
      fullWidth: !_vm.detailsVisible()
    }
  }, [_c('order-list')], 1), _c('div', {
    staticClass: "detailsColumn",
    class: {
      visible: _vm.detailsVisible()
    }
  }, [_c('order-details')], 1), _c('orders-edit-modal'), _c('orders-create-modal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }