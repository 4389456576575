import { reactive, toRefs } from 'vue';
import { vsModal } from './investments/useInvestmentsModals';

const modalState = reactive({
  modalAddAuthorizedDevice: vsModal({
    text: {
      title: 'Dodaj zaufane urządzenie',
      ok: 'Dodaj urządzenie',
    },
  }),
  modalConnectAuthorizedDevice: vsModal({
    noConfirm: true,
    size: 'md',
    text: {
      title: 'Autoryzacja urządzenia mobilnego',
      loader: 'Generowanie kodu autoryzacyjnego',
      cancel: 'Zamknij',
    },
  }),
  modalEditAuthorizedDevice: vsModal({
    text: {
      title: 'Edytuj zaufane urządzenie',
      ok: 'Zapisz urządzenie',
    },
  }),
});

export default function useCoreModals() {
  return { ...toRefs(modalState) };
}
