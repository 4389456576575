<template>
  <div class="order-form">
    <vs-form
      v-model="order"
      i18nNamespace="form.orders.order"
      :errors="feedback"
    >

      <vs-form-group
        field="offer_number"
      >
        <b-form-input
          :placeholder="$t('orders.edit.field.offer_number')"
          v-model="order.offer_number"
        />
      </vs-form-group>

      <vs-form-group
        field="title"
      >
        <b-form-input
          :placeholder="$t('orders.edit.field.title')"
          v-model="order.title"
        />
      </vs-form-group>

      <vs-form-group
        field="priority_id"
      >
        <authorization-guard
          permission="orders.order.change_priority"
        >
          <template #default>
            <core-priority-picker
              v-model="order.priority_id"
            />
          </template>
          <template #unauthorized>
            <div style="margin-top: 6px">
              <priority-label :id="order.priority_id" />
            </div>
          </template>
        </authorization-guard>
      </vs-form-group>

      <vs-form-group
        field="currency_id"
      >
        <core-currency-picker autopick v-model="order.currency_id"/>
      </vs-form-group>

      <vs-form-group
        field="provider"
        v-if="!$integrated('crm_provider_relation')"
      >
        <b-form-textarea
          :placeholder="$t('orders.edit.field.provider')"
          v-model="order.provider"
        />
      </vs-form-group>

      <template v-else>
        <vs-form-group
          field="_providerBranch"
          integration="crm_address_relation"
        >
          <crm-provider-branch-picker
            v-model="order._providerBranch"
            clearable
          />
        </vs-form-group>

        <vs-form-group
          field="provider_id"
          integration="crm_address_relation"
          v-show="!!order._providerBranch || !!order.provider_id"
        >
          <crm-provider-branch-person-picker
            v-model="order.provider_id"
            @pick="onProviderBranchPersonSelect"
            :excluded="(o) => order._providerBranch
              && (o.company_address_id !== order._providerBranch)"
          />
        </vs-form-group>
      </template>

      <vs-form-group
        field="investor"
        v-if="!$integrated('crm_investor_relation')"
      >
        <b-form-input
          :placeholder="$t('orders.edit.field.investor')"
          v-model="order.investor"
        />
      </vs-form-group>

      <vs-form-group
        field="investor_id"
        integration="crm_address_relation"
        v-else
      >
        <crm-client-branch-picker
          :reduce="client => client.branch ? client.branch.id : client.id"
          v-model="order.investor_id"
          clearable
        />
      </vs-form-group>

      <vs-form-group
        field="investment"
        v-if="!$integrated('investment_investment_relation')"
      >
        <b-form-input
          :placeholder="$t('orders.edit.field.investment')"
          v-model="order.investment"
        />
      </vs-form-group>

      <vs-form-group
        field="investment_id"
        integration="investment_investment_relation"
        v-else
      >
        <investments-investment-picker
          v-model="order.investment_id"
          clearable
        />
      </vs-form-group>

      <vs-form-group
        field="branch_id"
      >
        <core-branch-picker autopick v-model="order.branch_id"/>
      </vs-form-group>

      <vs-form-group
        field="ordered_at"
      >
        <vs-datepicker v-model="order.ordered_at" />
      </vs-form-group>

      <vs-form-group
        field="assigned_id"
      >
        <authorization-guard
          permission="orders.order.assign_user"
        >
          <template #default>
            <core-user-picker
              :multiple="false"
              :clearable="false"
              v-model="order.assigned_id"
            />
          </template>
          <template #unauthorized>
            <user-label withAvatar />
          </template>
        </authorization-guard>
      </vs-form-group>

      <vs-form-group
        field="accepting_id"
      >
        <core-user-picker
          :multiple="false"
          :clearable="false"
          v-model="order.accepting_id"
        />
      </vs-form-group>

      <vs-form-group
        field="comment"
      >
        <editor
          v-model="order.comment"
        />
      </vs-form-group>

      <h4 class="card-title">Warunki zlecenia</h4>

      <vs-form-group
        field="payment_terms"
      >
        <editor
          v-model="order.payment_terms"
        />
      </vs-form-group>

      <vs-form-group
        field="realization_at"
      >
        <vs-datepicker v-model="order.realization_at" />
      </vs-form-group>

      <vs-form-group
        field="delivery_place"
      >
        <b-form-input
          :placeholder="$t('orders.edit.field.delivery_place')"
          v-model="order.delivery_place"
        />
      </vs-form-group>

      <vs-form-group
        field="transport"
      >
        <b-form-input
          :placeholder="$t('orders.edit.field.transport')"
          v-model="order.transport"
        />
      </vs-form-group>

      <vs-form-group
        field="contact_person"
      >
        <b-form-input
          :placeholder="$t('orders.edit.field.contact_person')"
          v-model="order.contact_person"
        />
      </vs-form-group>

    </vs-form>

  </div>
</template>

<script>
import {
  BFormTextarea,
  BFormInput,
} from 'bootstrap-vue';

import Editor from '@/components/ui/vs-wysiwyg/Editor.vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import UserLabel from '@/components/ui/UserLabel.vue';
import PriorityLabel from '@/components/ui/priority-label/PriorityLabel.vue';
import CorePriorityPicker from '@/components/module/core/CorePriorityPicker.vue';
import CoreCurrencyPicker from '@/components/module/core/CoreCurrencyPicker.vue';
import CoreUserPicker from '@/components/module/core/CoreUserPicker.vue';
import CoreBranchPicker from '@/components/module/core/CoreBranchPicker.vue';
import InvestmentsInvestmentPicker from '@/components/module/investments/InvestmentsInvestmentPicker.vue';
import CrmProviderBranchPicker from '@/components/module/crm/CrmProviderBranchPicker.vue';
import CrmProviderBranchPersonPicker from '@/components/module/crm/CrmProviderBranchPersonPicker.vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import { computed, ref, watch } from 'vue';
import CrmClientBranchPicker from '@/components/module/crm/CrmClientBranchPicker.vue';

export default {
  name: 'OrdersOrderForm',
  props: {
    value: {
      type: Object,
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    /* eslint-disable no-underscore-dangle */
    const order = computed(() => props.value);

    const selectedProviderBranchPerson = ref(null);

    const onProviderBranchPersonSelect = (person) => {
      selectedProviderBranchPerson.value = person;
      if (order.value._providerBranch !== person.company_address_id) {
        order.value._providerBranch = person.company_address_id;
      }
    };

    watch(() => selectedProviderBranchPerson.value, (newValue) => {
      if (!newValue?.id) return;
      order.value.provider_id = newValue.id;
    });

    watch(() => order.value._providerBranch, (newBranch) => {
      if (newBranch === selectedProviderBranchPerson.value?.company_address_id) return;
      order.value.provider_id = null;
      selectedProviderBranchPerson.value = null;
    });

    return {
      order,
      selectedProviderBranchPerson,
      onProviderBranchPersonSelect,
    };
  },
  components: {
    BFormInput,
    BFormTextarea,

    Editor,
    InvestmentsInvestmentPicker,
    AuthorizationGuard,
    UserLabel,
    PriorityLabel,
    CorePriorityPicker,
    CoreCurrencyPicker,
    CoreUserPicker,
    CoreBranchPicker,
    CrmProviderBranchPicker,
    CrmProviderBranchPersonPicker,
    VsForm,
    VsFormGroup,
    VsDatepicker,
    CrmClientBranchPicker,
  },
};
</script>

<style lang="sass" scoped>
.order-form
  form
    padding: 30px 45px

    @media screen and (max-width: 991px)
      padding: 0px 0px

  .card-title
    margin-top:30px

.order-form::v-deep
  .vs-wysiwyg
    margin-top: 15px
</style>
