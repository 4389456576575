export default {
  pageTitle: 'Kadra',
  list: {
    pageTitle: 'Lista pracowników',
    field: {
      employee: 'Imię i Nazwisko',
      group: 'Grupa',
      position: 'Stanowisko',
      branch: 'Region',
      status: 'Status',
      medicalExams: 'B. lekarskie',
      contractEnd: 'Koniec umowy',
      edit: 'Edycja',
      indefinite: 'Czas nieokreślony',
    },
  },
  skills: {
    title: 'Uprawnienia / kursy',
    field: {
      selected: 'Wybrane uprawnienia',
    },
    button: {
      add: 'Edytuj uprawnienia / kursy',
    },
  },
  medicalExams: {
    modal: {
      add: {
        title: 'Dodaj badanie lekarskie',
      },
      edit: {
        title: 'Edytuj badanie lekarskie',
      },
      delete: {
        title: 'Potwierdź usunięcie badania lekarskiego',
        text: 'Czy na pewno chcesz usunąć badanie z dnia {date}?',
      },
    },
    type: {
      EARLY: 'Wstępne',
      PERIODIC: 'Okresowe',
      CHECKUP: 'Kontrolne',
      FINAL: 'Końcowe',
    },
    title: {
      emptyHistory: 'Historia badań pracownika {name} jest pusta',
    },
    button: {
      backToDetails: 'Powrót do szczegółów użytkownika',
    },
    field: {
      attachment: 'Załącznik',
      examDate: 'Data badania',
      expirationDate: 'Data ważności',
      notApplicable: 'nie dotyczy',
      outdated: 'Nieaktualne',
      upToDate: 'Aktualne',
      expiringIn: 'Wygasa dzisiaj | Wygasa jutro | Wygasa za {n} dni',
    },
    list: {
      button: {
        add: 'Dodaj badanie lekarskie',
      },
    },
  },
  employee: {
    add: {
      title: 'Dodawanie nowego pracownika',
      button: {
        cancel: 'Anuluj',
        save: 'Zapisz pracownika',
      },
    },
    modal: {
      delete: {
        title: 'Potwierdź usunięcie pracownika',
        text: 'Czy na pewno chcesz usunąć pracownika {name}? Tej akcji nie można cofnąć.',
      },
      archive: {
        title: 'Potwierdź archiwizację pracownika',
        text: 'Czy na pewno chcesz zarchiwizować pracownika {name}? Ta akcja jest odwracalna.',
      },
      restore: {
        title: 'Potwierdź przyywrócenie pracownika',
        text: 'Czy na pewno chcesz przywrócić pracownika {name}?',
      },
    },
    edit: {
      title: 'Dodawanie nowego pracownika',
      field: {
        forename: 'Imię',
        surname: 'Nazwisko',
        phone: 'Numer telefonu',
        email: 'Adres e-mail',
        status: 'Status pracownika',
        contract_beginning: 'Początek umowy',
        contract_end: 'Koniec umowy',
        form_of_employment: 'Forma zatrudnienia',
        medical_examinations: 'Badania lekarskie',
        group: 'Grupa',
        position: 'Stanowisko',
        supervisor: 'Przełożony',
        region: 'Region',
        courses: 'Uprawnienia / kursy',
        shortcode: 'Identyfikator',
        createAccount: 'Konto w aplikacji',
      },
      button: {
        cancel: 'Anuluj',
        save: 'Utwórz pracownika',
      },
    },
  },
  details: {
    pageTitle: 'Szczegóły pracownika',
  },
  holidays: {
    pageTitle: 'Historia urlopów',
  },
  examinations: {
    pageTitle: 'Historia badań lekarskich',
  },
  safetyTrainings: {
    pageTitle: 'Historia szkoleń BHP',
    list: {
      button: {
        add: 'Dodaj szkolenie',
      },
    },
    title: {
      emptyHistory: 'Historia szkoleń pracownika {name} jest pusta',
    },
    modal: {
      delete: {
        title: 'Potwierdź usunięcie szkolenia BHP',
        text: 'Czy na pewno chcesz usunąć badanie {name}?',
      },
      edit: {
        title: 'Edytuj szkolenie BHP',
      },
    },
    field: {
      name: 'Nazwa szkolenia',
      trainingDate: 'Data szkolenia',
      expirationDate: 'Data ważności',
      attachment: 'Załącznik',
      expiringIn: 'Wygasa dzisiaj | Wygasa jutro | Wygasa za {n} dni',
    },
    button: {
      backToDetails: 'Powrót do szczegółów użytkownika',
    },
  },
  editEmployee: {
    pageTitle: 'Edycja',
  },
};
