var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vs-file"
  }, [_c('b-form-file', _vm._b({
    model: {
      value: _vm.selectedFile,
      callback: function ($$v) {
        _vm.selectedFile = $$v;
      },
      expression: "selectedFile"
    }
  }, 'b-form-file', _vm.fileInputProps, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }