var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "user-avatar",
    class: {
      'user-avatar--large': _vm.lg
    },
    style: _vm.avatarStyling
  }, [_vm.displayUserIcon ? _c('div', {
    staticClass: "user-avatar-icon"
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "UserIcon",
      "size": "14"
    }
  })], 1) : _vm._e(), _c('div', {
    class: `user-avatar-circle ${_vm.minimal ? 'minimal' : ''}`
  }, [_vm._v(" " + _vm._s(_vm.avatar.shortcode) + " ")]), _vm.avatarDeleteButton ? _c('div', {
    staticClass: "remove-button",
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('delete');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "XIcon",
      "size": "18"
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }