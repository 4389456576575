import { areArraysEqual } from '@/libs/arrays';
import i18n from '@/libs/i18n';
import {
  computed, reactive, ref, toRefs, watch,
} from 'vue';
import { validate } from 'vee-validate';

// null each property in each object recursively
const getEmptiedObject = (filledObject) => {
  if (Object.keys(filledObject).length === 0) return null;

  const emptiedObject = {};

  Object
    .keys(filledObject)
    .forEach((key) => {
      if (typeof filledObject[key] === 'object') {
        emptiedObject[key] = { ...getEmptiedObject(filledObject[key]) };
      } else {
        emptiedObject[key] = null;
      }
    });

  return { ...emptiedObject };
};

const flattenObject = (nestedObject, propertyPrefix = null) => {
  if (Object.keys(nestedObject).length === 0) return null;

  let emptiedObject = {};

  Object
    .entries(nestedObject)
    .forEach(([key, val]) => {
      const keyWithPrefix = propertyPrefix ? `${propertyPrefix}.${key}` : key;
      if (typeof val === 'object' && val !== null) {
        emptiedObject = { ...emptiedObject, ...flattenObject(val, keyWithPrefix) };
      } else {
        emptiedObject[keyWithPrefix] = val;
      }
    });

  return emptiedObject;
};

/**
 * FIXME: some values (e.g. field names) should be cached
 *        to avoid redundant calls to i18n library
 */
export default function useValidatedForm(rules, i18nNamespace = null) {
  const flattenedRules = flattenObject(rules);
  let validateClientSide = true;

  const flattenedEmptiedObject = { ...getEmptiedObject(flattenedRules) };
  const fields = ref(getEmptiedObject(rules));
  const errors = reactive({
    // client-side validation handled by vee-validate
    clientSide: { ...flattenedEmptiedObject },
    serverSide: { ...flattenedEmptiedObject },
  });

  const flattenedFields = computed(() => flattenObject({ ...fields.value }));

  const observers = ref([]);
  const observeFieldError = (fieldName, callbackFn) => {
    observers.value.push({
      observing: fieldName,
      callback: callbackFn,
    });
  };
  const notifyObservers = (fieldName, message) => {
    observers.value
      .filter((observer) => observer.observing === fieldName)
      .forEach((observer) => {
        observer.callback(message);
      });
  };

  const assignMatching = (target, source) => {
    if (!target) return null;
    const matched = { ...target };

    Object.keys(source).forEach((property) => {
      if (!Object.prototype.hasOwnProperty.call(target, property)) return;

      if (typeof source[property] === 'object' && source[property] !== null) {
        matched[property] = assignMatching(matched[property], source[property]) ?? source[property];
      } else if (typeof target[property] === 'object' && target[property] !== null) {
        matched[property] = {};
      } else {
        matched[property] = source[property];
      }
    });

    return matched;
  };

  const assignFields = (assignedObject) => {
    fields.value = assignMatching(fields.value, { ...assignedObject });
  };

  const fieldDefaults = ref(getEmptiedObject(rules));

  const getFieldDisplayName = (fieldName) => {
    const i18nField = `${i18nNamespace ?? 'global.field'}.${fieldName}`;

    if (i18n.te(i18nField)) return i18n.t(i18nField);
    return fieldName;
  };

  const getFieldByName = (fieldName) => {
    let field = fields.value[fieldName];

    if (fieldName.includes('.')) {
      field = fieldName.split('.').reduce((acc, curr) => acc[curr], fields.value);
    }

    return field;
  };

  const validateField = async (fieldName, fieldRules = rules[fieldName]) => {
    let field = fields.value[fieldName];
    let fieldFinalRules = fieldRules;

    if (fieldName.includes('.')) {
      field = fieldName.split('.').reduce((acc, curr) => acc[curr], fields.value);
      fieldFinalRules = fieldName.split('.').reduce((acc, curr) => acc[curr], rules);
    }

    const validation = await validate(field, fieldFinalRules, {
      name: getFieldDisplayName(fieldName),
      values: { ...flattenedFields.value },
    });

    if (!areArraysEqual(errors.clientSide[fieldName], validation.errors)) {
      errors.clientSide[fieldName] = validation.errors;
    }

    if (!validation.valid) {
      notifyObservers(fieldName, validation.errors);
    }

    return validation.valid;
  };

  const registerValidationObserver = (fieldName) => {
    let field = fields.value[fieldName];

    if (fieldName.includes('.')) {
      field = fieldName.split('.').reduce((acc, curr) => acc[curr], fields.value);
    }

    if (field !== null) return;

    const fieldRules = flattenedRules[fieldName];

    watch(() => getFieldByName(fieldName), () => {
      if (!validateClientSide) return;
      validateField(fieldName, fieldRules);
    });
  };

  const registerAllValidationObservers = () => {
    Object
      .keys(flattenedRules)
      .forEach((field) => registerValidationObserver(field));
  };

  registerAllValidationObservers();

  const validateAllFields = async () => Promise.all(
    Object
      .keys(flattenedEmptiedObject)
      .map((field) => validateField(field, rules[field])),
  );

  const isClientSideValid = computed(() => Object
    .keys(errors.clientSide)
    .every((field) => errors.clientSide[field] === null));

  const feedback = ref({ ...getEmptiedObject(flattenedRules) });

  // get server-side errors first
  watch(() => [errors.clientSide, errors.serverSide], () => {
    const parsedErrorList = { ...flattenedRules };

    const renameMe = (toReplace, serverSide, clientSide) => {
      if (!serverSide || !clientSide) {
        feedback.value = null;
      }
      const temporary = {};

      Object
        .keys(toReplace)
        .forEach((field) => {
          let fieldError;

          if (typeof toReplace[field] === 'object' && toReplace[field] !== null) {
            fieldError = renameMe(
              toReplace[field],
              serverSide[field],
              clientSide[field],
            );
          } else {
            fieldError = serverSide[field] ?? clientSide[field] ?? null;
          }

          if (Array.isArray(fieldError)) {
            fieldError = fieldError?.[0] ?? null;
          }

          temporary[field] = fieldError;
        });

      feedback.value = temporary;
    };

    return renameMe(parsedErrorList, errors.serverSide, errors.clientSide);
  }, { deep: true });

  const clearErrors = () => {
    errors.clientSide = { ...flattenedEmptiedObject };
    errors.serverSide = { ...flattenedEmptiedObject };
  };

  const resetFields = (disableValidation = true) => {
    if (disableValidation) {
      validateClientSide = false;
    }

    fields.value = JSON.parse(JSON.stringify(fieldDefaults.value));
    clearErrors();

    setTimeout(() => {
      validateClientSide = true;
    }, 0);
  };

  const setFieldDefaults = (defaults) => {
    fieldDefaults.value = assignMatching(fieldDefaults.value, { ...defaults });
    resetFields();
  };

  // eslint-disable-next-line no-prototype-builtins
  const fieldHasRules = (fieldName) => rules.hasOwnProperty(fieldName);

  const nonEmptyFields = computed(() => {
    const nonEmpty = {};

    Object.keys(fields.value)
      .forEach((field) => {
        const fieldValue = fields.value[field];
        if (fieldValue === null || !fieldHasRules(field)) return;
        if (typeof fieldValue === 'object' && Object.keys(fieldValue).length === 0) return;
        nonEmpty[field] = fields.value[field];
      });

    return nonEmpty;
  });

  // get fields specified in validation rules
  const fieldsToValidate = computed(() => {
    const fieldList = {};

    Object.keys(fields.value)
      .forEach((field) => {
        if (rules[field]) return;
        fieldList.field = fields.value[field];
      });

    return fieldList;
  });

  const assignServerSideErrors = (assignedErrors) => {
    if (!assignedErrors || Array.isArray(assignedErrors)) return;

    const parsedErrorList = {};

    Object
      .keys(assignedErrors)
      .forEach((error) => {
        parsedErrorList[error] = assignedErrors[error];
      });

    errors.serverSide = { ...parsedErrorList };
  };

  const areAllFieldsValid = async () => {
    const fieldsValidation = await validateAllFields();

    return fieldsValidation.every((valid) => valid);
  };

  return {
    fields,
    feedback,
    errors: { ...toRefs(errors) },

    assignFields,
    assignServerSideErrors,
    setFieldDefaults,
    resetFields,
    clearErrors,
    validateField,
    validateAllFields,
    isClientSideValid,
    nonEmptyFields,
    fieldsToValidate,
    areAllFieldsValid,
    observeFieldError,
    flattenedEmptiedObject,
    fieldDefaults,
  };
}
