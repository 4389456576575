<template>
  <component :is="component" :status_id="status_id" :item="item"/>
</template>

<script>
import TaskStatus from './Task.vue';
import OrderStatus from './Order.vue';

/**
 * This component needs some refactoring.
 * Recreate current behaviour without redundancy
 *
 * Basically the problem sits in store referencing:
 * the only thing that really changes is the store
 * inner route (tasks/statuses vs. orders/statuses)
 */

export default {
  props: {
    status_id: Number,
    item: Object,
    orders: Boolean,
  },
  computed: {
    component() {
      if (this.orders) return OrderStatus;

      return TaskStatus;
    },
  },
};
</script>
