var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.component, {
    tag: "component",
    attrs: {
      "status_id": _vm.status_id,
      "item": _vm.item
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }