<template>
  <div class="remote-resource-manager-simulation">

    <input
      type="text"
      placeholder="Nazwa zasobu"
      v-model="resourceName"
    >

    <button
      @click="simulate"
    >
      Wyślij "pseudo-websocket"
    </button>

  </div>
</template>

<script>
import { ref } from 'vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

export default {
  name: 'RemoteResourceManagerSimulation',
  setup() {
    const resourceName = ref('');

    const rrm = RemoteResourceManager.getInstance();
    const simulate = () => {
      rrm.notify(resourceName.value);
      resourceName.value = '';
    };

    return { resourceName, simulate };
  },
};
</script>

<style lang="scss" scoped>
.remote-resource-manager-simulation {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>
