var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order-form"
  }, [_c('vs-form', {
    attrs: {
      "i18nNamespace": "form.orders.order",
      "errors": _vm.feedback
    },
    model: {
      value: _vm.order,
      callback: function ($$v) {
        _vm.order = $$v;
      },
      expression: "order"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "offer_number"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('orders.edit.field.offer_number')
    },
    model: {
      value: _vm.order.offer_number,
      callback: function ($$v) {
        _vm.$set(_vm.order, "offer_number", $$v);
      },
      expression: "order.offer_number"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "title"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('orders.edit.field.title')
    },
    model: {
      value: _vm.order.title,
      callback: function ($$v) {
        _vm.$set(_vm.order, "title", $$v);
      },
      expression: "order.title"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "priority_id"
    }
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "orders.order.change_priority"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('core-priority-picker', {
          model: {
            value: _vm.order.priority_id,
            callback: function ($$v) {
              _vm.$set(_vm.order, "priority_id", $$v);
            },
            expression: "order.priority_id"
          }
        })];
      },
      proxy: true
    }, {
      key: "unauthorized",
      fn: function () {
        return [_c('div', {
          staticStyle: {
            "margin-top": "6px"
          }
        }, [_c('priority-label', {
          attrs: {
            "id": _vm.order.priority_id
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "currency_id"
    }
  }, [_c('core-currency-picker', {
    attrs: {
      "autopick": ""
    },
    model: {
      value: _vm.order.currency_id,
      callback: function ($$v) {
        _vm.$set(_vm.order, "currency_id", $$v);
      },
      expression: "order.currency_id"
    }
  })], 1), !_vm.$integrated('crm_provider_relation') ? _c('vs-form-group', {
    attrs: {
      "field": "provider"
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "placeholder": _vm.$t('orders.edit.field.provider')
    },
    model: {
      value: _vm.order.provider,
      callback: function ($$v) {
        _vm.$set(_vm.order, "provider", $$v);
      },
      expression: "order.provider"
    }
  })], 1) : [_c('vs-form-group', {
    attrs: {
      "field": "_providerBranch",
      "integration": "crm_address_relation"
    }
  }, [_c('crm-provider-branch-picker', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.order._providerBranch,
      callback: function ($$v) {
        _vm.$set(_vm.order, "_providerBranch", $$v);
      },
      expression: "order._providerBranch"
    }
  })], 1), _c('vs-form-group', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !!_vm.order._providerBranch || !!_vm.order.provider_id,
      expression: "!!order._providerBranch || !!order.provider_id"
    }],
    attrs: {
      "field": "provider_id",
      "integration": "crm_address_relation"
    }
  }, [_c('crm-provider-branch-person-picker', {
    attrs: {
      "excluded": function (o) {
        return _vm.order._providerBranch && o.company_address_id !== _vm.order._providerBranch;
      }
    },
    on: {
      "pick": _vm.onProviderBranchPersonSelect
    },
    model: {
      value: _vm.order.provider_id,
      callback: function ($$v) {
        _vm.$set(_vm.order, "provider_id", $$v);
      },
      expression: "order.provider_id"
    }
  })], 1)], !_vm.$integrated('crm_investor_relation') ? _c('vs-form-group', {
    attrs: {
      "field": "investor"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('orders.edit.field.investor')
    },
    model: {
      value: _vm.order.investor,
      callback: function ($$v) {
        _vm.$set(_vm.order, "investor", $$v);
      },
      expression: "order.investor"
    }
  })], 1) : _c('vs-form-group', {
    attrs: {
      "field": "investor_id",
      "integration": "crm_address_relation"
    }
  }, [_c('crm-client-branch-picker', {
    attrs: {
      "reduce": function (client) {
        return client.branch ? client.branch.id : client.id;
      },
      "clearable": ""
    },
    model: {
      value: _vm.order.investor_id,
      callback: function ($$v) {
        _vm.$set(_vm.order, "investor_id", $$v);
      },
      expression: "order.investor_id"
    }
  })], 1), !_vm.$integrated('investment_investment_relation') ? _c('vs-form-group', {
    attrs: {
      "field": "investment"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('orders.edit.field.investment')
    },
    model: {
      value: _vm.order.investment,
      callback: function ($$v) {
        _vm.$set(_vm.order, "investment", $$v);
      },
      expression: "order.investment"
    }
  })], 1) : _c('vs-form-group', {
    attrs: {
      "field": "investment_id",
      "integration": "investment_investment_relation"
    }
  }, [_c('investments-investment-picker', {
    attrs: {
      "clearable": ""
    },
    model: {
      value: _vm.order.investment_id,
      callback: function ($$v) {
        _vm.$set(_vm.order, "investment_id", $$v);
      },
      expression: "order.investment_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "branch_id"
    }
  }, [_c('core-branch-picker', {
    attrs: {
      "autopick": ""
    },
    model: {
      value: _vm.order.branch_id,
      callback: function ($$v) {
        _vm.$set(_vm.order, "branch_id", $$v);
      },
      expression: "order.branch_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "ordered_at"
    }
  }, [_c('vs-datepicker', {
    model: {
      value: _vm.order.ordered_at,
      callback: function ($$v) {
        _vm.$set(_vm.order, "ordered_at", $$v);
      },
      expression: "order.ordered_at"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "assigned_id"
    }
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "orders.order.assign_user"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('core-user-picker', {
          attrs: {
            "multiple": false,
            "clearable": false
          },
          model: {
            value: _vm.order.assigned_id,
            callback: function ($$v) {
              _vm.$set(_vm.order, "assigned_id", $$v);
            },
            expression: "order.assigned_id"
          }
        })];
      },
      proxy: true
    }, {
      key: "unauthorized",
      fn: function () {
        return [_c('user-label', {
          attrs: {
            "withAvatar": ""
          }
        })];
      },
      proxy: true
    }])
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "accepting_id"
    }
  }, [_c('core-user-picker', {
    attrs: {
      "multiple": false,
      "clearable": false
    },
    model: {
      value: _vm.order.accepting_id,
      callback: function ($$v) {
        _vm.$set(_vm.order, "accepting_id", $$v);
      },
      expression: "order.accepting_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "comment"
    }
  }, [_c('editor', {
    model: {
      value: _vm.order.comment,
      callback: function ($$v) {
        _vm.$set(_vm.order, "comment", $$v);
      },
      expression: "order.comment"
    }
  })], 1), _c('h4', {
    staticClass: "card-title"
  }, [_vm._v("Warunki zlecenia")]), _c('vs-form-group', {
    attrs: {
      "field": "payment_terms"
    }
  }, [_c('editor', {
    model: {
      value: _vm.order.payment_terms,
      callback: function ($$v) {
        _vm.$set(_vm.order, "payment_terms", $$v);
      },
      expression: "order.payment_terms"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "realization_at"
    }
  }, [_c('vs-datepicker', {
    model: {
      value: _vm.order.realization_at,
      callback: function ($$v) {
        _vm.$set(_vm.order, "realization_at", $$v);
      },
      expression: "order.realization_at"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "delivery_place"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('orders.edit.field.delivery_place')
    },
    model: {
      value: _vm.order.delivery_place,
      callback: function ($$v) {
        _vm.$set(_vm.order, "delivery_place", $$v);
      },
      expression: "order.delivery_place"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "transport"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('orders.edit.field.transport')
    },
    model: {
      value: _vm.order.transport,
      callback: function ($$v) {
        _vm.$set(_vm.order, "transport", $$v);
      },
      expression: "order.transport"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "contact_person"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('orders.edit.field.contact_person')
    },
    model: {
      value: _vm.order.contact_person,
      callback: function ($$v) {
        _vm.$set(_vm.order, "contact_person", $$v);
      },
      expression: "order.contact_person"
    }
  })], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }