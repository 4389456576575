var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: _vm.contentWidth === 'boxed' ? 'container p-0' : null
  }, [_c('router-view')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }