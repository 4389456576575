/* eslint-disable no-underscore-dangle */
import RemoteResourceManager from '@/libs/RemoteResourceManager';
import { computed, ref } from 'vue';

/**
 * Composable used to fetch resources.
 *
 * TODO: Create ResourceManager and register provided name.
 */
const useRemoteResource = (name, fetchMethod, dataTransformer = (e) => e.data) => {
  const remoteResourceManager = RemoteResourceManager.getInstance();
  const _innerState = ref([]);
  const fetched = ref(false);
  const postFetchMethod = ref(null);

  const setPostFetchMethod = (newPostFetchMethod) => {
    postFetchMethod.value = newPostFetchMethod;
  };

  const fetch = (params, force = false) => {
    if (fetched.value && !force) return;

    fetchMethod(params)
      .then(({ data }) => {
        _innerState.value = dataTransformer(data);
        fetched.value = true;

        if (!postFetchMethod.value) return;

        try {
          postFetchMethod.value(_innerState.value);
        } catch {
          throw new Error(`Could not execute the post-fetch method for ${name}`);
        }
      })
      .catch(() => {});
  };

  const state = computed({
    get: () => {
      fetch();
      return _innerState.value;
    },
    set: (newState) => {
      _innerState.value = newState;
    },
  });

  // FIXME: name is a subject to change
  const markAsFetchable = () => {
    fetched.value = false;
  };

  remoteResourceManager.attach(name, () => markAsFetchable());

  const getById = (id) => state.value.find((item) => item?.id === id);

  return {
    _innerState,
    state,
    fetch,
    fetched,
    getById,
    setPostFetchMethod,
  };
};

export default useRemoteResource;
