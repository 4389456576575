var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'vs-permission-editor-permission': true,
      expanded: _vm.childrenVisible
    }
  }, [_c('div', {
    class: {
      permission: true,
      'first-nested': _vm.nestedLevel === 0,
      'second-nested': _vm.nestedLevel === 1
    },
    style: {
      '--top-offset': _vm.stickTop ? 0 : 80
    }
  }, [_c('permission-switcher', {
    attrs: {
      "inheritAsDeny": _vm.noInherit && _vm.deniedChildrenCount === 0,
      "noInherit": _vm.noInherit,
      "value": _vm.value,
      "disabled": _vm.hideSwitchForSettings ? !_vm.forSettings : false
    },
    on: {
      "change": function (state) {
        return _vm.$emit('state:change', state, _vm.id);
      }
    }
  }), _c('div', {
    staticClass: "permission-details",
    on: {
      "click": function ($event) {
        _vm.childrenVisible = !_vm.childrenVisible;
      }
    }
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.translatedPermissionName) + " "), _vm.forSettings ? _c('div', {
    staticClass: "category-badge"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SettingsIcon",
      "size": "10"
    }
  }), _vm._v(" Ustawienia ")], 1) : _vm._e()]), _vm.developmentMode ? _c('span', {
    staticStyle: {
      "opacity": "0.5"
    }
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")]) : _vm._e(), _c('div', {
    staticClass: "description"
  }, [_vm.nestedLevel !== 2 ? _c('div', {
    staticClass: "permission-count"
  }, [false ? _c('div', {
    staticClass: "permission-count-total"
  }, [_vm._v(" " + _vm._s(_vm.totalChildrenCount) + " uprawnienia łącznie ")]) : _vm._e(), _vm.grantedChildrenCount > 0 ? _c('div', {
    staticClass: "permission-count-granted"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CheckIcon",
      "size": "14"
    }
  }), _vm.grantedChildrenCount === _vm.totalChildrenCount ? [_vm._v(" Przyznano wszystkie uprawnienia ")] : [_vm._v(" Przyznano "), _c('span', {
    staticClass: "number"
  }, [_vm._v(" " + _vm._s(_vm.grantedChildrenCount) + " ")])]], 2) : _vm._e(), _vm.deniedChildrenCount > 0 ? _c('div', {
    staticClass: "permission-count-denied"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "XIcon",
      "size": "14"
    }
  }), _vm.deniedChildrenCount === _vm.totalChildrenCount ? [_vm._v(" Odmówiono wszystkich uprawnień ")] : [_vm._v(" Odmówiono "), _c('span', {
    staticClass: "number"
  }, [_vm._v(" " + _vm._s(_vm.deniedChildrenCount) + " ")])]], 2) : _vm._e()]) : _vm._e()])]), _vm.hasChildren ? _c('div', {
    staticClass: "collapse-button"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.childrenVisible = !_vm.childrenVisible;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": _vm.childrenVisible ? 'ChevronUpIcon' : 'ChevronDownIcon',
      "size": "14"
    }
  }), _vm._v(" " + _vm._s(_vm.childrenVisible ? 'Zwiń uprawnienia' : 'Rozwiń uprawnienia') + " ")], 1)], 1) : _vm._e()], 1), _vm.hasChildren ? _c('b-collapse', {
    staticClass: "permission-children",
    model: {
      value: _vm.childrenVisible,
      callback: function ($$v) {
        _vm.childrenVisible = $$v;
      },
      expression: "childrenVisible"
    }
  }, [_c('div', {
    staticClass: "permission-children-permissions"
  }, _vm._l(_vm.computedChildren, function (child) {
    return _c('vs-permission-editor-permission', {
      key: child.id,
      attrs: {
        "id": child.id,
        "value": _vm.relativeState(_vm.value, child.state),
        "name": child.name,
        "children": _vm.getChildrenArray(child),
        "forSettings": child.forSettings,
        "nestedLevel": _vm.nestedLevel + 1,
        "hideSwitchForSettings": _vm.hideSwitchForSettings,
        "noInherit": _vm.noInherit
      },
      on: {
        "state:change": function (state, permissionId) {
          return _vm.$emit('state:change', state, permissionId);
        }
      }
    });
  }), 1)]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }