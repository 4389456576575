/**
 * TODO: Integrations should be defined in a regular
 * logic file, this is only for providing an example
 */
export default {
  crm_address_relation: {
    name: 'Integracja adresów',
    explanation: 'To pole jest sprzężone z modułem CRM. Aby dodać/zaktualizować wartości widoczne w tym polu, przejdź do zakładki "CRM" przy pomocy lewej belki nawigacyjnej',
    module: 'CRM',
    icon: 'PackageIcon',
  },
  base_resource_relation: {
    name: 'Integracja zasobów',
    explanation: 'To pole jest sprzężone z modułem Baza. Aby dodać/zaktualizować maszyny lub sprzęt widoczne w tym polu, przejdź do zakładki "Baza" przy pomocy lewej belki nawigacyjnej',
    module: 'Base',
    icon: 'TruckIcon',
  },
  base_technology_relation: {
    name: 'Integracja technologii',
    explanation: 'To pole jest sprzężone z modułem Baza. Aby dodać/zaktualizować technologie widoczne w tym polu, przejdź do zakładki "Baza" przy pomocy lewej belki nawigacyjnej',
    module: 'Base',
    icon: 'TruckIcon',
  },
  base_resource_investment_relation: {
    name: 'Przypisany zasób',
    explanation: 'To pole jest sprzężone z modułem Baza. Aby dodać/zaktualizować maszyny lub sprzęt widoczne w tym polu, przypisz je najpierw do inwestycji przy pomocy zakładki "Maszyny i sprzęt"',
    module: 'Base',
    icon: 'TruckIcon',
  },
  cadre_employee_relation: {
    name: 'Integracja zespołu',
    explanation: 'To pole jest sprzężone z modułem Kadra. Aby dodać/zaktualizować pracowników widocznych w tym polu, przejdź do zakładki "Kadra" przy pomocy lewej belki nawigacyjnej',
    module: 'Cadre',
    icon: 'UsersIcon',
  },
  cadre_position_relation: {
    name: 'Integracja stanowisk',
    explanation: 'To pole jest sprzężone z modułem Kadra. Aby dodać/zaktualizować stanowiska widoczne w tym polu, przejdź do zakładki "Kadra" w ustawieniach aplikacji',
    module: 'Cadre',
    icon: 'UsersIcon',
  },
  investment_investment_relation: {
    name: 'Integracja inwestycji',
    explanation: 'To pole jest sprzężone z modułem Inwestycje. Aby dodać/zaktualizować inwestycje widoczne w tym polu, przejdź do zakładki "Inwestycje" przy pomocy lewej belki nawigacyjnej',
    module: 'Orders',
    icon: 'CompassIcon',
  },
  base_transport_investment_relation: {
    name: 'Integracja inwestycji',
    explanation: `
To pole jest sprzężone z modułem Inwestycje. Aby dodać/zaktualizować inwestycje widoczne w tym polu, przejdź do zakładki "Inwestycje" przy pomocy lewej belki nawigacyjnej.
W celu wybrania BAZY, pozostaw pole puste (pole możesz wyczyścić przy użyciu ikony "X" po prawej stronie pola)
`,
    module: 'Base',
    icon: 'CompassIcon',
  },
  base_transport_company_relation: {
    name: 'Integracja adresów',
    explanation: 'To pole jest sprzężone z modułem CRM. Aby dodać/zaktualizować wartości widoczne w tym polu, przejdź do zakładki "CRM" przy pomocy lewej belki nawigacyjnej',
    module: 'CRM',
    icon: 'PackageIcon',
  },
};
