/**
 * Global routes
 */
import i18n from '@/libs/i18n';

import Home from '@/views/dashboard/Home.vue';
import Notifications from '@/views/dashboard/Notifications.vue';
import ProfileSettings from '@/views/dashboard/ProfileSettings.vue';
import Login from '@/views/Login.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import Error404 from '@/views/error/Error404.vue';
import Unauthorized from '@/views/error/Unauthorized.vue';

import store from '@/store';

const homeRoutes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      pageTitle: i18n.t('home.pageTitle'),
      requiresAuth: true,
      breadcrumb: [
        {
          text: i18n.t('home.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/profile/settings',
    name: 'profile.settings',
    component: ProfileSettings,
    meta: {
      pageTitle: i18n.t('profile.settings.pageTitle'),
      requiresAuth: true,
      breadcrumb: [
        {
          text: i18n.t('profile.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('profile.settings.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/profile/notifications',
    name: 'notifications',
    component: Notifications,
    meta: {
      pageTitle: i18n.t('home.notifications.pageTitle'),
      navActiveLink: 'home',
      requiresAuth: true,
      breadcrumb: [
        {
          text: i18n.t('home.pageTitle'),
          to: '/',
          active: false,
        },
        {
          text: i18n.t('home.notifications.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      layout: 'full',
    },
    beforeEnter(_to, _from, next) {
      if (store.getters['auth/authenticated']) {
        next({ name: 'home' });
      } else {
        next();
      }
    },
  },
  {
    path: '/reset-password/:token',
    name: 'resetPassword',
    component: ResetPassword,
    meta: {
      layout: 'full',
    },
    // beforeEnter(_to, _from, next) {
    //   if (store.getters['auth/authenticated']) {
    //     next({ name: 'home' });
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: '/forgot-password',
    name: 'auth.password.forgot',
    component: ForgotPassword,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: Error404,
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: Unauthorized,
    meta: {
      requiresAuth: true,
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
];

export default homeRoutes;
