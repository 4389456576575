import i18n from '@/libs/i18n';

export default [
  {
    path: '/base/machines',
    name: 'base.machines',
    component: () => import('@/views/base/machines/BaseMachines.vue'),
    meta: {
      pageTitle: i18n.t('base.machines.list.pageTitle'),
      moduleRoute: 'Base',
      activeTab: 'machines',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.machines.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.machines.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/machines/details/:id',
    name: 'base.machines.details',
    component: () => import('@/views/base/machines/MachinesDetails.vue'),
    meta: {
      pageTitle: i18n.t('base.machines.details.pageTitle'),
      navActiveLink: 'base.machines',
      moduleRoute: 'Base',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource.show',
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.machines.list.pageTitle'),
          to: { name: 'base.machines' },
          active: false,
        },
        {
          text: i18n.t('base.machines.details.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/machines/details/:id/settings',
    name: 'base.machines.details.settings',
    component: () => import('@/components/views/base/machines/settings/MachineSettings.vue'),
    meta: {
      pageTitle: i18n.t('base.machines.settings.pageTitle'),
      navActiveLink: 'base.machines',
      moduleRoute: 'Base',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource.update',
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.machines.list.pageTitle'),
          to: { name: 'base.machines' },
          active: false,
        },
        {
          text: i18n.t('base.machines.details.pageTitle'),
          to: { name: 'base.machines.details' },
          active: false,
        },
        {
          text: i18n.t('base.machines.settings.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/machines/reports/:resourceId?/:reportType?',
    name: 'base.machines.reports',
    component: () => import('@/views/base/machines/BaseMachines.vue'),
    meta: {
      pageTitle: i18n.t('base.machines.reports.fullname'),
      navActiveLink: 'base.machines',
      moduleRoute: 'Base',
      activeTab: 'reports',
      requiresAuth: true,
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.machines.pageTitle'),
          to: { name: 'base.machines' },
          active: false,
        },
        {
          text: i18n.t('base.machines.reports.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/machines/schedule',
    name: 'base.machines.schedule',
    component: () => import('@/views/base/machines/BaseMachines.vue'),
    meta: {
      pageTitle: i18n.t('base.machines.schedule.fullname'),
      navActiveLink: 'base.machines',
      moduleRoute: 'Base',
      activeTab: 'schedule',
      requiresAuth: true,
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.machines.pageTitle'),
          to: { name: 'base.machines' },
          active: false,
        },
        {
          text: i18n.t('base.machines.schedule.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/machines/service/:resourceId?',
    name: 'base.machines.service',
    component: () => import('@/views/base/machines/BaseMachines.vue'),
    meta: {
      pageTitle: i18n.t('base.service.list.pageTitle'),
      moduleRoute: 'Base',
      navActiveLink: 'base.machines',
      activeTab: 'service',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.machines.pageTitle'),
          to: { name: 'base.machines' },
          active: true,
        },
        {
          text: i18n.t('base.service.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/machines/service/details/:id',
    name: 'base.machines.service.details',
    component: () => import('@/views/base/machines/BaseMachines.vue'),
    meta: {
      pageTitle: i18n.t('base.service.details.pageTitle'),
      navActiveLink: 'base.machines',
      moduleRoute: 'Base',
      activeTab: 'service',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource.show',
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.machines.pageTitle'),
          to: { name: 'base.machines' },
          active: true,
        },
        {
          text: i18n.t('base.service.details.pageTitle'),
          active: true,
        },
      ],
    },
  },
];
