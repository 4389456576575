<template>
  <div class="settings-tab-crm-industries-form">
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('base.producer.field.name')"
            label-cols-md="3"
            :invalid-feedback="errors.name"
            :state="errors.name === null"
            >
            <b-input-group>
              <b-form-input
                :placeholder="$t('base.producer.field.name')"
                v-model="producerModel.name"/>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('base.producer.field.producer')"
            label-cols-md="3"
            :invalid-feedback="errors.producer"
            :state="!errors.hasOwnProperty('producer')"
          >
            <base-producer-picker v-model="producerModel.base_producer_id"/>
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="$t('base.producer.field.type')"
            label-cols-md="3"
            :invalid-feedback="errors.base_resource_type_id"
            :state="!errors.hasOwnProperty('base_resource_type_id')"
          >
            <base-resource-type-picker
              v-model="producerModel.base_resource_type_id"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue';
import { inject } from 'vue';
import BaseResourceTypePicker
  from '@/components/views/settings/content-tabs/base/ui/picker/BaseResourceTypePicker.vue';
import BaseProducerPicker from '@/components/module/base/BaseProducerPicker.vue';

export default {
  name: 'SettingsTabBaseProducerModelForm',
  setup() {
    const { producerModel } = inject('producersModelsInstance');
    const errors = {};

    return { producerModel, errors };
  },
  components: {
    BaseResourceTypePicker,
    BaseProducerPicker,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
  },
};
</script>
