<template>
  <vs-modal
    :modalInstance="modalEditOrder"
    @ok="updateOrder"
  >
    <orders-order-form
      v-model="fields"
      :feedback="feedback"
    />
  </vs-modal>
</template>

<script>
import OrdersOrderForm from '@/components/views/orders/OrdersOrderForm.vue';
import {
  EditSuccess,
  BadInputError,
  ServerError,
} from '@feedback/module/orders';
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useOrdersModals from '@/hooks/orders/useOrdersModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import store from '@/store';
import formatDate from '@/libs/date-formatter';
import { watch } from 'vue';
import { integrationEnabled } from '@/libs/module-telescope';
import showToast from '@/libs/toasts';

export default {
  name: 'OrdersEditModal',
  setup() {
    const { modalEditOrder } = useOrdersModals();

    const {
      fields,
      feedback,
      setFieldDefaults,
      resetFields,
      assignFields,
      nonEmptyFields,
      assignServerSideErrors,
      areAllFieldsValid,
    } = useValidatedForm({
      _providerBranch: '',
      id: 'required',
      status_id: 'required',
      offer_number: 'required',
      title: 'required',
      priority_id: 'required',
      currency_id: 'required',
      provider: '',
      provider_id: 'required_if:_providerBranch',
      investor: '',
      investor_id: '',
      investment: '',
      investment_id: '',
      branch_id: 'required',
      ordered_at: 'required',
      assigned_id: 'required',
      accepting_id: 'required',
      comment: '',
      payment_terms: 'required',
      realization_at: '',
      delivery_place: '',
      transport: '',
      contact_person: 'required',
    });
    setFieldDefaults({
      assigned_id: store.getters['auth/user']?.id,
      priority_id: 2,
      ordered_at: formatDate.standard(Date.now()),
      realization_at: formatDate.standard(Date.now()),
    });

    watch(() => modalEditOrder.value.visible, (visible) => {
      resetFields();
      if (visible) {
        const currentOrder = store.getters['orders/current'];

        assignFields({
          ...currentOrder,
          provider: integrationEnabled('crm_provider_relation')
            ? null
            : currentOrder.crm_provider_address_contact,
          provider_id: currentOrder.crm_provider_address_contact?.id ?? null,
          investor_id: currentOrder.investor?.id ?? null,
          investor: integrationEnabled('crm_investor_relation')
            ? null
            : currentOrder.investor,
          investment_id: currentOrder.investment?.id ?? null,
          investment: integrationEnabled('investment_investment_relation')
            ? null
            : currentOrder.investment,
        });
      }
    });

    const updateOrder = async () => {
      if (!(await areAllFieldsValid())) return;
      modalEditOrder.value.isLoading = true;

      store.dispatch('orders/update', {
        ...nonEmptyFields.value,
        provider_id: fields.value.provider_id ?? null,
        investor_id: fields.value.investor_id ?? null,
        investment_id: fields.value.investment_id ?? null,
      })
        .then(({ data }) => {
          showToast(EditSuccess, {
            label: store.getters['orders/label'](data.data.id),
            name: data.data.title,
          });
          modalEditOrder.value.close();
        })
        .catch(({ response }) => {
          let Feedback = ServerError;

          if (response?.data?.errors) {
            Feedback = BadInputError;
          }

          showToast(Feedback);
          assignServerSideErrors(response.data?.errors);
        })
        .finally(() => {
          modalEditOrder.value.isLoading = false;
        });
    };

    return {
      modalEditOrder,
      fields,
      feedback,
      updateOrder,
    };
  },
  components: {
    OrdersOrderForm,
    VsModal,
  },
};
</script>
