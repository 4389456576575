var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settings-tab-base-technologies"
  }, [_c('div', {
    staticClass: "buttons"
  }, [_c('div', {
    staticClass: "group"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.modalAddVisible = true;
      }
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t('base.technology.button.add')) + " ")])], 1)]), _c('vs-list', {
    attrs: {
      "showHeaders": "",
      "items": _vm.technologies,
      "headers": ['Nazwa', 'Kolor']
    },
    on: {
      "edit": _vm.editTechnology,
      "delete": _vm.deleteTechnology
    },
    scopedSlots: _vm._u([{
      key: "cells",
      fn: function (_ref) {
        var row = _ref.row;
        return [_c('div', {
          staticClass: "vs-list-item-cell"
        }, [_c('div', {
          staticClass: "color-circle",
          style: {
            '--color': row.color
          }
        })])];
      }
    }])
  }), _c('settings-tab-base-technology-modal-add'), _c('settings-tab-base-technology-modal-edit')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }