<template>
  <div
    class="module-icon"
    :style="iconStyle"
    >
    <feather-icon :icon="icon" size="18" />
  </div>
</template>

<script>
export default {
  props: ['icon', 'color'],
  computed: {
    iconStyle() {
      return `--module-color: ${this.color}`;
    },
  },
};
</script>

<style lang="sass" scoped>
.module-icon
  border-radius: 50%
  width: 40px
  height: 40px
  aspect-ratio: 1 / 1
  color: var(--module-color)
  position: relative
  display: flex
  align-items: center
  justify-content: center

  &:before
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    content: ''
    display: block
    background-color: var(--module-color)
    opacity: 0.1
    border-radius: 50%
</style>
