<template>
  <div>
    <b-card :title="$t('home.title.dashboard')">
      <b-card-text>{{ $t('home.greeting.heading', { user: user.forename }) }}</b-card-text>
      <b-card-text>
        {{ $t('home.greeting.body', { appName: $store.getters['themes/data'].shortAppName }) }}
      </b-card-text>
    </b-card>

    <!-- Developent Mode Widgets -->
    <div class="development-widgets" v-if="developmentMode">
      <div class="header">
        <div class="title">
          Widżety trybu deweloperskiego
        </div>

        <b-link
          href="https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/"
          target="_blank"
          v-if="developmentMode"
          class="doc-link"
          >
          <feather-icon icon="LinkIcon" />
          Template Documentation
        </b-link>
      </div>
      <hr>

      <!-- Temporary Integrations Widgets -->
      <b-card title="Lista integracji" class="development-widgets" v-if="developmentMode">

        <remote-resource-manager-simulation />

        <div
          v-for="integration in moduleIntegrations"
          :key="integration.name"
          >
          <state-badge class="mt-1" :state="integration.active" :text="integration.name" />
        </div>
      </b-card>

      <websocket-widget />

    </div>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import WebsocketWidget from '@/components/dev/WebsocketWidget.vue';
import StateBadge from '@/components/ui/StateBadge.vue';
import ApiClient from '@/services/api';
import RemoteResourceManagerSimulation from '@/components/dev/RemoteResourceManagerSimulation.vue';

export default {
  setup() {
    const enableIntegration = () => {
      ApiClient.put('api/orders/settings/relation/crm/switch-relation-order-to-crm-branch');
    };

    return { enableIntegration };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      moduleIntegrations: 'app/moduleIntegrations',
    }),
    developmentMode() {
      return process.env.NODE_ENV === 'development';
    },
  },
  components: {
    BCard,
    BCardText,
    BLink,

    WebsocketWidget,
    StateBadge,
    RemoteResourceManagerSimulation,
  },
};
</script>

<style lang="sass" scoped>
.development-widgets
  .header
    display: flex
    justify-content: space-between

    .doc-link
      display: flex
      align-items: center
      gap: 5px
</style>
