var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('authorization-guard', {
    attrs: {
      "permission": "tasks.task.index",
      "silent": ""
    }
  }, [_vm.filtrationEnabled ? _c('div', {
    staticClass: "card shadow-none bg-transparent border-secondary"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.title.filtering')) + " ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('tasks.edit.field.assignedTo')
    }
  }, [_c('core-user-picker', {
    attrs: {
      "withDeleted": "",
      "multiple": "",
      "filtration": _vm.filteredUsers
    },
    model: {
      value: _vm.userFilter,
      callback: function ($$v) {
        _vm.userFilter = $$v;
      },
      expression: "userFilter"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status"
    }
  }, [_c('tasks-status-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.statusFilter,
      callback: function ($$v) {
        _vm.statusFilter = $$v;
      },
      expression: "statusFilter"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('tasks.edit.field.priority')
    }
  }, [_c('core-priority-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.priorityFilter,
      callback: function ($$v) {
        _vm.priorityFilter = $$v;
      },
      expression: "priorityFilter"
    }
  })], 1)], 1)], 1), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.clearAllFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.clearAllFilters')) + " ")])], 1)]) : _vm._e()]), _c('div', {
    staticClass: "buttons"
  }, [_c('div', {
    staticClass: "group d-flex justify-content-between"
  }, [_c('vs-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'tasks.task',
      expression: "'tasks.task'",
      arg: "store"
    }],
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.openTaskCreateModal
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t('global.button.task')) + " ")]), _c('vs-button', {
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": _vm.handleExport
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "14",
      "icon": "DownloadIcon"
    }
  })], 1), _c('a', {
    ref: "exportAnchorRef",
    staticClass: "d-none"
  })], 1)]), _c('br'), _c('authorization-guard', {
    attrs: {
      "permission": "tasks.task.index"
    }
  }, [_c('vs-table', {
    attrs: {
      "mode": "remote",
      "columns": _vm.displayedColumns,
      "rows": _vm.tasks,
      "totalRecords": _vm.totalRecords,
      "checkSort": _vm.columnSortOption,
      "perPage": _vm.serverParams.perPage,
      "isLoading": _vm.isLoading
    },
    on: {
      "searchTermChange": function (term) {
        return _vm.searchTerm = term;
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange,
      "on-row-click": _vm.openDetails,
      "on-sort-change": _vm.changeSorting
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        return [props.column.field === 'title' ? _c('task-label', {
          attrs: {
            "id": props.row.id,
            "title": props.row.title
          }
        }) : props.column.field === 'status_id' ? _c('status-badge', {
          attrs: {
            "status_id": props.row.status_id
          }
        }) : props.column.field === 'assigned_id' ? _c('span', [_c('user-label', {
          attrs: {
            "data": props.row.assigned
          }
        })], 1) : props.column.field === 'priority_id' ? _c('span', [_c('priority-label', {
          attrs: {
            "id": props.row.priority_id
          }
        })], 1) : props.column.field === 'termination_date' ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate.taskDeadline(props.row.termination_date)) + " ")]) : _vm._e()];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }