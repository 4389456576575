const developmentRoutes = [
  {
    path: '/development/permissions',
    name: 'development.permissions',
    component: () => import('@/views/development/DevelopmentPermissions.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/development/themes',
    name: 'development.themes',
    component: () => import('@/views/development/DevelopmentThemes.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/development/scheduler',
    name: 'development.scheduler',
    component: () => import('@/views/development/DevelopmentScheduler.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const appInDevelopment = process.env.NODE_ENV === 'development';
export default appInDevelopment ? developmentRoutes : [];
