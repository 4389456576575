<template>
  <span class="status-badge" :style="styleVars">
    {{ status.name }}
  </span>
</template>

<script>
import getContrasting from '@/libs/contrast';
import { computed } from 'vue';
import useOrdersStatusStore from '@/stores/module/orders/useOrdersStatusStore';

export default {
  props: {
    status_id: {
      type: Number,
      default: null,
    },
    item: {
      type: Object,
      default: () => ({
        default: true,
        name: 'brak statusu',
        color: '#e0e0e0',
      }),
    },
  },
  setup(props) {
    const statusStore = useOrdersStatusStore();
    const status = computed(() => (props.item.default
      ? statusStore.getById(props.status_id) ?? props.item
      : props.item));

    const styleVars = computed(() => {
      if (!status.value) return null;

      return {
        '--primary-bg': status.value.color,
        '--primary-fg': getContrasting(status.value.color),
      };
    });

    return { status, styleVars };
  },
};
</script>

<style lang="sass" scoped>
.status-badge
  display: inline-block
  background-color: var(--primary-bg)
  color: var(--primary-fg)
  padding: 3px 10px
  border-radius: 6px
  font-weight: 600
  white-space: nowrap
</style>
