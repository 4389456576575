import i18n from '@/libs/i18n';
import Settings from '@/views/Settings.vue';

/**
 * Settings Tab routes
 */
const settingsRoutes = [
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      pageTitle: i18n.t('settings.list.pageTitle'),
      requiresAuth: true,
      activeTab: 'core',
      breadcrumb: [
        {
          text: i18n.t('settings.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/:tabName',
    name: 'settings.tab',
    component: Settings,
    meta: {
      pageTitle: i18n.t('settings.list.pageTitle'),
      requiresAuth: true,
      breadcrumb: [
        {
          text: i18n.t('settings.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
];

export default settingsRoutes;
