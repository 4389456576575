<template>
  <header-section
    :title="title || $t('component.orderSubject.name')"
    action="Dodaj przedmiot"
    @actionClick="openSubjectEditor"
    :canAction="$store.getters['auth/userHasPermission']('store', 'orders.subject')"
    >
    <b-collapse
      id="subject-collapse"
      class="mt-2"
      v-model="subjectCollapseVisible"
      >
      <b-card
        :title="editorTranslations.title"
        class="m-0 p-0 border"
        >
        <b-container>
          <b-row>

            <b-col>
              <b-form-group
                :label="$t('component.orderSubject.field.name')"
                class="mt-1"
                >
                <b-input-group>
                  <b-form-input
                    :placeholder="$t('component.orderSubject.field.name')"
                    v-model="newSubject.name"
                    />
                </b-input-group>
              </b-form-group>
            </b-col>

          </b-row>

          <b-row>
            <b-col>

              <b-form-group
                :label="$t('component.orderSubject.field.quantity')"
                class="mt-1"
                >
                <b-input-group>
                  <b-form-input
                    :placeholder="$t('component.orderSubject.field.quantity')"
                    v-model="newSubject.amount"
                    />
                </b-input-group>
              </b-form-group>

            </b-col>
            <b-col>
              <b-form-group
                :label="$t('component.orderSubject.field.unit')"
                class="mt-1"
                >
                <core-unit-picker autopick v-model="newSubject.unit_id" />
              </b-form-group>
            </b-col>

          </b-row>

          <b-row>
            <b-col>
              <b-form-group
                :label="$t('component.orderSubject.field.price')"
                >
                <b-input-group
                  :append="currency.code"
                  >
                  <b-form-input
                    :placeholder="$t('component.orderSubject.field.price')"
                    v-model="newSubject.unit_price"
                    />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

        </b-container>

        <div class="subject-buttons">

          <vs-button
            vs-variant="light"
            @click="cancelEditing"
            >
            {{ editorTranslations.cancel }}
          </vs-button>

          <vs-button
            vs-variant="primary"
            class="vs-edit-button"
            @click="publishSubject"
            >
            {{ editorTranslations.confirm }}
          </vs-button>

        </div>
      </b-card>
    </b-collapse>

    <table class="table" v-if="subject && subject.length > 0">
      <tr>
        <th>{{ $t('component.orderSubject.field.name') }}</th>
        <th class="quantity">{{ $t('component.orderSubject.field.quantity') }}</th>
        <th>{{ $t('component.orderSubject.field.price') }}</th>
        <th>{{ $t('component.orderSubject.field.value') }}</th>
        <th></th>
      </tr>
      <order-subject-item
        v-for="item in subject"
        :key="item.id"

        :item="item"
        :unit="order.currency_id"

        @edit="edit"
        @remove="remove"
        />
    </table>
    <div class="subject-price-sum" v-if="totalPrice">
      {{ $t('component.orderSubject.field.sum') }}:
      <span class="value">{{ totalPrice.toLocaleString('ru-RU') }}</span> {{ currency.code }}
    </div>
  </header-section>
</template>

<script>
import { mapGetters } from 'vuex';
import { deleteModal } from '@/libs/modals';
import {
  BContainer, BRow, BCol,
  BCollapse, BCard, BFormGroup, BInputGroup, BFormInput,
} from 'bootstrap-vue';
import HeaderSection from '@/components/ui/HeaderSection.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import CoreUnitPicker from '@/components/module/core/CoreUnitPicker.vue';
import OrderSubjectItem from './OrderSubjectItem.vue';

export default {
  name: 'OrderSubject',
  components: {
    BContainer,
    BRow,
    BCol,
    BCollapse,
    BCard,
    BFormGroup,
    BInputGroup,
    BFormInput,
    HeaderSection,
    OrderSubjectItem,
    VsButton,
    CoreUnitPicker,
  },
  props: ['subject', 'title'],
  data() {
    return {
      subjectCollapseVisible: false,
      model: {
        id: null,
        name: null,
        amount: null,
        unit_id: 1,
        unit_price: null,
      },
      newSubject: {},
    };
  },
  computed: {
    ...mapGetters({
      order: 'orders/current',
      currencyById: 'currencies/byId',
      totalPrice: 'orders/subject/totalPrice',
    }),
    editorTranslations() {
      if (this.newSubject.id) {
        return {
          title: this.$t('component.orderSubject.title.edit'),
          confirm: this.$t('component.orderSubject.button.confirmEdit'),
          cancel: this.$t('component.orderSubject.button.cancelEdit'),
        };
      }

      return {
        title: this.$t('component.orderSubject.title.add'),
        confirm: this.$t('component.orderSubject.button.confirmAdd'),
        cancel: this.$t('component.orderSubject.button.cancelAdd'),
      };
    },
    currency() {
      const curr = this.currencyById(this.order.currency_id);

      if (!curr) return {};
      return curr;
    },
  },
  methods: {
    openSubjectEditor() {
      this.subjectCollapseVisible = true;
    },

    edit(item) {
      this.newSubject = { ...this.model, ...item };
      this.openSubjectEditor();
    },

    async remove(subject) {
      const confirmation = await deleteModal({
        title: this.$t('component.orderSubject.modal.delete.title'),
        text: this.$t('component.orderSubject.modal.delete.text', {
          name: subject.name,
          price: subject.unit_price * subject.amount,
          unit: this.currency.code,
        }),
      });

      if (!confirmation) return false;
      return this.$emit('remove', subject);
    },

    cancelEditing() {
      this.newSubject = {};
      this.subjectCollapseVisible = false;
    },
    publishSubject() {
      const event = this.newSubject.id ? 'edit' : 'add';
      const subject = { ...this.model, ...this.newSubject };

      this.$emit(event, subject);
      this.cancelEditing();
    },
  },
};
</script>

<style lang="sass" scoped>
.subject-buttons
  display: flex
  justify-content: end
  margin-top: 15px
  gap: 10px

.table::v-deep
  background: none !important

  .quantity
    text-align: center

  .edit
    text-align: right

  th
    border-top: none
    padding: 15px 0 0 0 !important

  tr
    &:nth-child(2)
      td
        border-top: none

  td
    margin: 0 !important
    padding: 15px 0 !important

.subject-price-sum
  font-size: 14pt
  text-align: right
  padding: 10px 10px

  .value
    font-weight: 500
</style>
