import ApiClient from '@/services/api';

const urls = {
  get: 'api/cadre/safety-training/history/:userId',
};

export default {
  namespaced: true,

  state: {
    trainings: [
      {
        id: 1,
        name: 'Szkolenie BHP z czegos niedobrego',
        training_date: '2021-10-27 00:00:00',
        expiration_date: '2021-10-27 00:00:00',
        active: false,
        attachment: {},
      },
      {
        id: 2,
        name: 'Szkolenie BHP z epoki lodowcowej',
        training_date: '29.09.2021 r.',
        expiration_date: '28.09.2022 r.',
        active: true,
        attachment: {},
      },
      {
        id: 3,
        name: 'Szkolenie BHP z czegos niedobrego',
        training_date: '29.09.2019 r.',
        expiration_date: '28.09.2020 r.',
        active: true,
        attachment: {},
      },
      {
        id: 4,
        name: 'Szkolenie BHP z epoki lodowcowej',
        training_date: '29.09.2021 r.',
        expiration_date: '28.09.2022 r.',
        active: false,
        attachment: {},
      },
      {
        id: 5,
        name: 'Szkolenie BHP z czegos niedobrego',
        training_date: '29.09.2019 r.',
        expiration_date: '28.09.2020 r.',
        active: false,
        attachment: {},
      },
      {
        id: 6,
        name: 'Szkolenie BHP z epoki lodowcowej',
        training_date: '29.09.2021 r.',
        expiration_date: '28.09.2022 r.',
        active: true,
        attachment: {},
      },
      {
        id: 7,
        name: 'Szkolenie BHP z czegos niedobrego',
        training_date: '29.09.2019 r.',
        expiration_date: '28.09.2020 r.',
        active: true,
        attachment: {},
      },
      {
        id: 8,
        name: 'Szkolenie BHP z epoki lodowcowej',
        training_date: '29.09.2021 r.',
        expiration_date: '28.09.2022 r.',
        active: false,
        attachment: {},
      },
    ],
  },

  getters: {
    trainings: (state) => state.trainings,
    byId: (state) => (id) => state.trainings.find((t) => t.id === id),
  },

  mutations: {
    SET_TRAININGS(state, payload) {
      state.trainings = payload;
    },
    UPDATE_TRAINING(state, payload) {
      state.trainings = state.trainings
        .map((training) => {
          if (training.id !== payload.id) return training;
          return payload;
        });
    },
    REMOVE_TRAINING(state, payload) {
      state.trainings = state.trainings
        .filter((training) => training.id !== payload.id);
    },
    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
  },

  actions: {
    fetch({ commit, state }, id, force = false) {
      if (state.fetchPromise && !force) return state.fetchPromise;
      const url = urls.get.replace(':userId', id);

      const promise = ApiClient.get(url);

      promise.then(({ data }) => {
        commit('SET_TRAININGS', data.data);
      });

      commit('SET_PROMISE', promise);

      return promise;
    },
  },
};
