var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "vs-status-badge",
    style: _vm.styleVars
  }, [_vm._v(" " + _vm._s(_vm.status.name) + " ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }