import Vue from 'vue';
import Vuex from 'vuex';

import ApiClient from '@/services/api';

import statuses from './statuses';
import logs from './logs';
import comments from './comments';
import attachments from './attachments';

const endpoint = 'api/tasks';
const tasksList = 'api/tasks/get-tasks';
const taskById = 'api/tasks/:id/edit';
const updateTaskUrl = 'api/tasks/:id';
const updateStatusUrl = 'api/tasks/:id/status';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    tasks: [],
    currentTask: {},
    table: {
      isLoading: false,
    },

    sortableColumns: ['priority_id', 'termination_date'],

    prefix: 'ZD-:id', // for routing and cosmetics

    totalRecords: null,
    // default parameters for goodtable
    fetchParams: {
      search: '',
      columnFilters: {
        task_ids: null,
        assigned_to: null,
        created_by_id: null,
        created_from: null,
        created_to: null,
        status_id: null,
        priority_id: null,
      },
      sort: [
        {
          field: 'termination_date',
          type: 'desc',
        },
      ],
      page: 1,
      perPage: 10,
    },
  },

  getters: {
    all: (state) => state.tasks,
    byId: (state, taskId) => state.tasks.filter(({ id }) => id === taskId)[0],
    current: (state) => state.currentTask
      || state.tasks.filter(({ id }) => id === state.currentTask.id)[0],
    serverParams: (state) => state.fetchParams,
    totalRecords: (state) => state.totalRecords,
    prefix: (state) => state.prefix,
    label: (state) => (id) => state.prefix.replace(':id', id),
    isLoading: (state) => state.table.isLoading,
    columnSort: (state) => (field) => {
      const { sort } = state.fetchParams;
      const option = sort.filter((row) => row.field === field);

      if (option.length === 0) return null;
      return option[0].type;
    },
  },

  mutations: {
    SET_TASKS(state, payload) {
      state.tasks = payload;
    },
    SET_TOTAL_RECORDS(state, payload) {
      state.totalRecords = payload;
    },
    SET_CURRENT_TASK(state, payload) {
      const newTasks = state.tasks.map((task) => {
        if (task.id !== payload.id) return task;

        return payload;
      });
      state.currentTask = payload;
      state.tasks = [...newTasks];
    },
    UPDATE_TASK_STATUS(state, payload) {
      const taskId = state.currentTask.id;

      state.tasks = state.tasks.map((task) => {
        if (task.id !== taskId) return task;

        return {
          ...task,
          status_id: payload,
        };
      });

      state.currentTask.status_id = payload;
    },
    UPDATE_FILTER(state, payload) {
      state.fetchParams.columnFilters = {
        ...state.fetchParams.columnFilters,
        ...payload,
      };
    },
    ADD_TASK(state, payload) {
      state.tasks = [...state.tasks, payload];
    },
    SET_SERVER_PARAM(state, newParams) {
      state.fetchParams = newParams;
    },
    SET_TABLE_LOADING(state, newValue) {
      state.table.isLoading = newValue;
    },
  },

  actions: {
    fetch({ state, commit }, params = state.fetchParams) {
      commit('SET_TABLE_LOADING', true);
      ApiClient.get(tasksList, params).then((payload) => {
        if (payload.status !== 200) return null;
        commit('SET_TOTAL_RECORDS', payload.data.data.totalRecords);
        return commit('SET_TASKS', payload.data.data.tasks);
      })
        .finally(() => {
          commit('SET_TABLE_LOADING', false);
        });
    },

    fetchById({ commit, dispatch }, id) {
      const url = taskById.replace(':id', id);

      const request = ApiClient.get(url);

      request.then((payload) => {
        if (payload.status !== 200) return null;
        dispatch('comments/set', payload.data.data.comments);
        dispatch('attachments/set', payload.data.data.files);

        commit('SET_CURRENT_TASK', payload.data.data);
        return request;
      }).catch(() => {});

      return request;
    },

    create({ dispatch }, data) {
      const request = ApiClient.post(endpoint, data);

      request.then((res) => {
        if (res.status === 200) {
          dispatch('fetch');
        }
      }).catch(() => {});

      return request;
    },

    update({ commit, dispatch }, data) {
      if (!data.id) return false;
      const url = updateTaskUrl.replace(':id', data.id);

      const request = ApiClient.put(url, data);

      request.then((payload) => {
        commit('SET_CURRENT_TASK', payload.data.data);
        dispatch('logs/fetch', data.id);
      });

      return request;
    },

    remove({ dispatch }, id) {
      const url = updateTaskUrl.replace(':id', id);

      const query = ApiClient.delete(url);
      query.then(() => dispatch('fetch'));

      return query;
    },

    updateStatus({ commit }, data) {
      const url = updateStatusUrl.replace(':id', data.task_id);

      const params = {
        status_id: data.status_id,
      };

      const request = ApiClient.patch(url, params);
      request.then((res) => {
        if (res.status !== 200) return;

        commit('UPDATE_TASK_STATUS', data.status_id);
      });

      return request;
    },

    updateColumnFilter({ state, commit, dispatch }, parameter) {
      const newParams = {
        ...state.fetchParams,
        columnFilters: {
          ...state.fetchParams.columnFilters,
          ...parameter,
        },
      };
      commit('SET_SERVER_PARAM', newParams);
      return dispatch('fetch', newParams);
    },

    updateColumnSorting({ state, commit, dispatch }, column) {
      if (!state.sortableColumns.includes(column.field)) return null;
      const newParams = {
        ...state.fetchParams,
        sort: [
          column,
        ],
      };
      commit('SET_SERVER_PARAM', newParams);
      return dispatch('fetch', newParams);
    },

    updatePageSettings({ state, commit }, parameter) {
      const newParams = {
        ...state.fetchParams,
        page: Number(parameter.currentPage),
        perPage: Number(parameter.currentPerPage),
      };

      return commit('SET_SERVER_PARAM', newParams);
    },

    updateSearchTerm({ state, commit }, term) {
      const newParams = {
        ...state.fetchParams,
        search: term,
      };

      return commit('SET_SERVER_PARAM', newParams);
    },

    reset({ commit }) {
      commit('SET_TASKS', []);
    },
  },
  modules: {
    statuses,
    logs,
    comments,
    attachments,
  },
};
