var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('abstract-module-label', {
    attrs: {
      "icon": "CompassIcon",
      "to": _vm.investmentRoute,
      "name": _vm.investmentName,
      "size": _vm.size,
      "target": _vm.target
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }