import showToast from '@/libs/toasts';
import { FetchStatusesError } from '@/libs/toasts/feedback/module/base';
import useBaseApi from '../useBaseApi';
import useBaseStore from '../useBaseStore';

export default function useBaseStatus() {
  const { statuses } = useBaseStore();

  const fetchTypedStatuses = (force = false) => {
    if (statuses.value.length > 0 && !force) return statuses;

    const req = useBaseApi()
      .fetchTypedStatuses()
      .then(({ data }) => {
        statuses.value = data.data;
      })
      .catch(() => showToast(FetchStatusesError));

    return req;
  };

  const fetchStatusesIndex = (force = false) => {
    if (statuses.value.length > 0 && !force) return statuses;

    const req = useBaseApi()
      .fetchStatusesIndex()
      .then(({ data }) => {
        statuses.value = data.data;
      })
      .catch(() => showToast(FetchStatusesError));

    return req;
  };

  return { statuses, fetchTypedStatuses, fetchStatusesIndex };
}
