var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shouldDisplayField ? _c('b-col', {
    staticClass: "vs-form-group",
    attrs: {
      "cols": _vm.cols
    }
  }, [!_vm.hideParentFormGroup ? _c('b-form-group', {
    staticClass: "b-form-group",
    attrs: {
      "label": _vm.label,
      "invalid-feedback": _vm.fieldError,
      "state": _vm.hasErrors,
      "label-cols-md": !_vm.noLabel ? _vm.labelCols : null
    },
    scopedSlots: _vm._u([!_vm.integration ? {
      key: "label",
      fn: function () {
        return [_vm.label ? _c('div', {
          staticClass: "label-content-wrapper"
        }, [_c('span', {
          staticClass: "label-content-wrapper-text"
        }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _vm.info ? _c('span', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.hover.top",
            value: _vm.info,
            expression: "info",
            modifiers: {
              "hover": true,
              "top": true
            }
          }, {
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "label-content-wrapper-info"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1) : _vm._e()]) : _vm._e()];
      },
      proxy: true
    } : {
      key: "label",
      fn: function () {
        return [_c('div', {
          staticClass: "label-content-wrapper"
        }, [_c('integration-form-label', {
          attrs: {
            "integration": _vm.integration,
            "label": _vm.label
          }
        }), _vm.info ? _c('span', {
          directives: [{
            name: "b-popover",
            rawName: "v-b-popover.hover.top",
            value: _vm.info,
            expression: "info",
            modifiers: {
              "hover": true,
              "top": true
            }
          }, {
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "label-content-wrapper-info"
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1) : _vm._e()], 1)];
      },
      proxy: true
    }], null, true)
  }, [_vm._t("default")], 2) : _vm._e(), _vm.$slots.children ? _c('b-col', {
    staticClass: "child-groups",
    attrs: {
      "offset": _vm.labelCols,
      "cols": 12 - _vm.labelCols
    }
  }, [_vm._t("children")], 2) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }