var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    staticClass: "name"
  }, [_vm._v(_vm._s(_vm.item.name))]), _c('td', {
    staticClass: "quantity"
  }, [_vm._v(_vm._s(_vm.item.amount.toLocaleString('ru-RU')) + " " + _vm._s(_vm.unit.code))]), _c('td', [_vm._v(_vm._s(_vm.item.unit_price) + " " + _vm._s(_vm.currency.code))]), _c('td', [_vm._v(_vm._s((_vm.item.unit_price * _vm.item.amount).toLocaleString('ru-RU')) + " " + _vm._s(_vm.currency.code))]), _c('td', {
    staticClass: "edit"
  }, [_c('authorization-guard', {
    attrs: {
      "silent": "",
      "permission": "orders.subject.update"
    }
  }, [_c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "flat-secondary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Edit3Icon",
      "size": "20"
    },
    on: {
      "click": _vm.edit
    }
  })], 1)], 1), _c('authorization-guard', {
    attrs: {
      "silent": "",
      "permission": "orders.subject.destroy"
    }
  }, [_c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "flat-secondary"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Trash2Icon",
      "size": "20"
    },
    on: {
      "click": _vm.remove
    }
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }