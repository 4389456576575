<template>
  <div class="settings-tab-investments-work-type">

    <authorization-guard
      permission="investment.work_type.store"
      silent
    >
      <div class="buttons">
        <div class="group">
          <vs-button
            vs-variant="primary"
            @click="addWorkTypeModal.visible = true"
          >
            + Typ robót
          </vs-button>
        </div>
      </div>
    </authorization-guard>

    <authorization-guard
      permission="investment.work_type.index"
    >
      <vs-list showHeaders
        :items="workTypes"
        @edit="openEditModal"
        @delete="deleteWorkType"
        :headers="['Nazwa']"
      >
      </vs-list>
    </authorization-guard>

    <investment-work-type-modal-add @success="addWorkType" />
    <investment-work-type-modal-edit @success="updateWorkType" />
  </div>
</template>

<script>
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import VsList from '@/components/ui/vs-list/VsList.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import showToast from '@/libs/toasts';
import { DeleteWorkTypeSuccess, DeleteWorkTypeError } from '@/libs/toasts/feedback/module/investments';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';
import InvestmentWorkTypeModalAdd from './modals/InvestmentWorkTypeModalAdd.vue';
import InvestmentWorkTypeModalEdit from './modals/InvestmentWorkTypeModalEdit.vue';

export default {
  name: 'SettingsTabInvestmentWorkType',
  setup() {
    const { workTypes, addWorkTypeModal, editWorkTypeModal } = useInvestmentsStore();

    useInvestmentsApi()
      .fetchPickerWorkTypes()
      .then(({ data }) => {
        workTypes.value = [...data.data];
      });

    const openEditModal = (workType) => {
      editWorkTypeModal.value.visible = true;
      editWorkTypeModal.value.data = { ...workType };
    };

    const addWorkType = (workType) => {
      workTypes.value.push({ ...workType });
      RemoteResourceManager.getInstance().notify('investments.worktype');
    };

    const updateWorkType = (workType) => {
      workTypes.value = workTypes.value.map((m) => {
        if (m.id !== workType.id) return m;
        return workType;
      });
      RemoteResourceManager.getInstance().notify('investments.worktype');
    };

    const deleteWorkType = async (requested) => {
      const confirmation = await deleteModal({
        title: i18n.t('investments.modal.workType.delete.title'),
        text: i18n.t('investments.modal.workType.delete.text', {
          name: requested.name,
        }),
      });

      if (!confirmation) return false;

      return useInvestmentsApi()
        .deleteWorkType(requested.id)
        .then(() => {
          showToast(DeleteWorkTypeSuccess, { name: requested.name });
          RemoteResourceManager.getInstance().notify('investments.worktype');
          workTypes.value = workTypes.value
            .filter((s) => s.id !== requested.id);
        })
        .catch(() => showToast(DeleteWorkTypeError, { name: requested.name }));
    };

    return {
      workTypes,

      addWorkTypeModal,
      openEditModal,

      addWorkType,
      updateWorkType,
      deleteWorkType,
    };
  },
  components: {
    VsButton,
    VsList,
    InvestmentWorkTypeModalAdd,
    InvestmentWorkTypeModalEdit,
    AuthorizationGuard,
  },
};
</script>
