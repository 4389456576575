/**
 * Store Module for managing employees inside Cadre
 * employees are fictional users
 */

import ApiClient from '@/services/api';

import safety from './employees/safetyTrainings';

const urls = {
  get: 'api/cadre/employee/index',
  getById: 'api/cadre/employee/:id/edit',
  create: 'api/cadre/employee',
  delete: 'api/cadre/employee/:id',
  update: 'api/cadre/employee/:id',
  restore: 'api/cadre/employee/restore/:id',
};

export default {
  namespaced: true,

  state: {
    /** Employees list */
    employees: [],
    current: {},

    fetchParams: {
      columnFilters: {
        branches_id: null,
      },
    },

    /** Current promise returned by fetch action */
    fetchPromise: null,
  },

  getters: {
    all: (state) => state.employees,
    current: (state) => state.current,
    currentName: (state) => {
      const { forename, surname } = state.current;
      return `${forename || ''} ${surname || ''}`;
    },
    byId: (state) => (id) => state.employees.find((e) => e.id === id),
  },

  mutations: {
    SET_EMPLOYEES(state, employees) {
      state.employees = employees;
    },
    SET_CURRENT(state, employee) {
      state.current = employee;
    },
    UNSET_CURRENT(state) {
      state.current = {};
    },
    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
    ADD_EMPLOYEE(state, employee) {
      state.employees = [...state.employees, employee];
    },
    DELETE_TRAINING(state, training) {
      if (!state?.current?.safety_training) return;

      state.current.safety_training = state.current.safety_training
        .filter((t) => t.id !== training.id);
    },
    UPDATE_TRAINING(state, training) {
      if (!state?.current?.safety_training) return;

      state.current.safety_training = state.current.safety_training
        .map((t) => {
          if (t.id !== training.id) return t;
          return training;
        });
    },
    UPDATE_EMPLOYEE(state, newEmployee) {
      const newEmployees = state.employees
        .map((employee) => {
          if (employee.id !== newEmployee.id) return employee;
          return newEmployee;
        });

      if (newEmployee.id === state.current.id) {
        state.current = newEmployee;
      }

      state.employees = newEmployees;
    },
    REMOVE_EMPLOYEE(state, employee) {
      const newEmployees = state.employees.filter((current) => current.id !== employee.id);
      state.employees = [...newEmployees];
    },
  },

  actions: {
    /**
     * Fetch employees list
     * @action fetch=employees
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if employees exist
     * @returns {Promise}
     */
    fetch({ state, commit }, params, force = true) {
      if (state.employees.length && !force) return state.employees;
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(urls.get, params);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_EMPLOYEES', res.data.data.employees);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },

    /**
     * Fetch employee by id
     * @action fetch=employees
     * @param {ActionContext} [vuexContext]
     * @param {Number} employeeId id of the employee
     * @param {Boolean} force Forces the fetch even if employees exist
     * @returns {Promise}
     */
    fetchOne({ state, commit }, {
      employeeId = state.current?.id,
      force = false,
      setCurrent = true,
    } = {}) {
      const id = Number(employeeId);

      if (state.current?.id === id && !force) return state.current;
      if (setCurrent && state.current?.id !== id) commit('UNSET_CURRENT');

      const url = urls.getById.replace(':id', id);
      const promise = ApiClient.get(url);

      promise.then((res) => {
        if (res.status !== 200) return;
        if (setCurrent) commit('SET_CURRENT', res.data.data);
      });

      return promise;
    },

    /**
     * Create new employee
     * @action create=employees
     * @param {ActionContext} [vuexContext]
     * @param {Object} employee - Employee object
     * @returns {Promise}
     */
    create({ commit }, employee) {
      const url = urls.create;

      // FIXME change it later
      const changeMeEmployee = {
        ...employee,
      };

      const promise = ApiClient.post(url, changeMeEmployee);

      promise.then(({ data }) => {
        commit('ADD_EMPLOYEE', data.data);
      });
      return promise;
    },

    /**
     * Remove employee
     * @action delete=employees
     * @param {ActionContext} [vuexContext]
     * @param {Object} employee - Employee object
     * @returns {Promise}
     */
    delete({ commit }, { employee, archive = false }) {
      const url = urls.delete.replace(':id', employee.id);
      const promise = ApiClient.delete(url);

      promise.then((res) => {
        if (res.status !== 200) return;

        if (archive) {
          commit('UPDATE_EMPLOYEE', { ...employee, deleted_at: true });
        } else {
          commit('REMOVE_EMPLOYEE', employee);
        }
      });
      return promise;
    },

    /**
     * restore employee
     * @action delete=employees
     * @param {ActionContext} [vuexContext]
     * @param {Object} employee - Employee object
     * @returns {Promise}
     */
    restore({ commit }, employee) {
      const url = urls.restore.replace(':id', employee.id);
      const promise = ApiClient.put(url, { should_create_user: false });

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('UPDATE_EMPLOYEE', { ...employee, deleted_at: null });
      });
      return promise;
    },

    /**
     * Update employee
     * @action update=employees
     * @param {ActionContext} [vuexContext]
     * @param {Object} employee - Employee object
     * @returns {Promise}
     */
    update({ commit }, employee) {
      const url = urls.update.replace(':id', employee.id);

      // FIXME change it later
      const changeMeEmployee = {
        ...employee,
      };

      const promise = ApiClient.put(url, changeMeEmployee);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('UPDATE_EMPLOYEE', res?.data?.data);
      });
      return promise;
    },
  },

  modules: {
    safety,
  },
};
