<template>
  <b-tabs
    @activate-tab="(to, _from, bvEvent) => changeTab(to, bvEvent)"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <b-tab :active="isActiveTab('core')">
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Aplikacja</span>
      </template>

      <core-content v-if="isActiveTab('core')"/>
    </b-tab>

    <b-tab :active="isActiveTab('tasks')">
      <template #title>
        <feather-icon
          icon="CheckSquareIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Zadania</span>
      </template>

      <core-content v-if="isActiveTab('tasks')"/>
    </b-tab>

    <b-tab :active="isActiveTab('orders')">
      <template #title>
        <feather-icon
          icon="ArrowRightCircleIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Zlecenia</span>
      </template>

      <settings-tab-orders v-if="isActiveTab('orders')"/>
    </b-tab>

    <b-tab
      :active="isActiveTab('cadre')">
      <template #title>
        <feather-icon
          icon="UsersIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Kadra</span>
      </template>

      <cadre-content v-if="isActiveTab('cadre')"/>
    </b-tab>

    <b-tab
      :active="isActiveTab('crm')">
      <template #title>
        <feather-icon
          icon="PackageIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">CRM</span>
      </template>

      <settings-tab-crm v-if="isActiveTab('crm')"/>
    </b-tab>

    <b-tab
      :active="isActiveTab('base')">
      <template #title>
        <feather-icon
          icon="TruckIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Baza</span>
      </template>

      <settings-tab-base v-if="isActiveTab('base')"/>
    </b-tab>

    <b-tab
      :active="isActiveTab('investment')">
      <template #title>
        <feather-icon
          icon="CompassIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Inwestycje</span>
      </template>

      <settings-tab-investment v-if="isActiveTab('investment')"/>
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue';
import { mapGetters } from 'vuex';
import CoreContent from '@/components/views/settings/content-tabs/core/Index.vue';
import CadreContent from '@/components/views/settings/content-tabs/cadre/Index.vue';
import SettingsTabCrm from '@/components/views/settings/content-tabs/crm/SettingsTabCrm.vue';
import SettingsTabBase from '@/components/views/settings/content-tabs/base/SettingsTabBase.vue';
import SettingsTabOrders from '@/components/views/settings/content-tabs/orders/SettingsTabOrders.vue';
import SettingsTabInvestment from '@/components/views/settings/content-tabs/investment/SettingsTabInvestment.vue';

export default {
  components: {
    BTabs,
    BTab,
    CadreContent,
    CoreContent,

    SettingsTabCrm,
    SettingsTabOrders,
    SettingsTabBase,
    SettingsTabInvestment,
  },
  computed: {
    ...mapGetters({
      isModuleEnabled: 'app/isModuleEnabled',
    }),
    activeTab() {
      const routerTab = this.$route.params.tabName || 'core';
      return routerTab;
    },
  },
  methods: {
    isActiveTab(tab) {
      return tab === this.activeTab;
    },
    changeTab(tab) {
      const tabName = this.tabs[tab];

      if (this.activeTab !== tabName) {
        this.$router.push({
          name: 'settings.tab',
          params: {
            tabName,
          },
        });
      }
    },
  },
  data() {
    return {
      options: {},
      tabs: {
        0: 'core',
        1: 'tasks',
        2: 'orders',
        3: 'cadre',
        4: 'crm',
        5: 'base',
        6: 'investment',
      },
    };
  },
};
</script>
