<template>
  <div class="task-form">
    <vs-form
      v-model="task"
      :errors="feedback"
      i18nNamespace="form.tasks.task"
    >

      <vs-form-group field="title">
        <b-form-input v-model="task.title" />
      </vs-form-group>

      <vs-form-group field="priority_id">
        <core-priority-picker v-model="task.priority_id" />
      </vs-form-group>

      <vs-form-group field="assigned_id">
        <core-user-picker v-model="task.assigned_id" />
      </vs-form-group>

      <vs-form-group field="termination_date">
        <vs-datepicker v-model="task.termination_date" />
      </vs-form-group>

      <vs-form-group field="description">
        <editor v-model="task.description" />
      </vs-form-group>

    </vs-form>
  </div>
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue';

import Editor from '@/components/ui/vs-wysiwyg/Editor.vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import { computed } from 'vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import CorePriorityPicker from '@/components/module/core/CorePriorityPicker.vue';
import CoreUserPicker from '@/components/module/core/CoreUserPicker.vue';

export default {
  name: 'TasksTaskForm',
  props: {
    model: {
      type: Object,
      default: () => null,
    },
    value: {
      type: Object,
    },
    feedback: {
      type: Object,
    },
  },
  setup(props) {
    const task = computed(() => props.value);

    return { task };
  },
  components: {
    BFormInput,

    Editor,
    VsForm,
    VsFormGroup,
    VsDatepicker,
    CorePriorityPicker,
    CoreUserPicker,
  },
};
</script>

<style lang="sass" scoped>
.task-form::v-deep
  .vs-wysiwyg
    margin-top: 15px
</style>
