<template>
  <div class="settings-tab-investments-materials">

    <authorization-guard
      silent
      permission="investment.requirement_group.store"
    >
      <div class="buttons">
        <div class="group">
          <vs-button
            vs-variant="primary"
            @click="addMaterialModal.visible = true"
          >
            + Materiał
          </vs-button>
        </div>
      </div>
    </authorization-guard>

    <authorization-guard
      permission="investment.requirement_group.index"
    >
      <vs-list showHeaders
        :items="materialResource"
        @edit="openEditModal"
        @delete="deleteMaterial"
        :headers="['Nazwa', 'Jednostka']"
      >
        <template #cells="{ row }">
          <div class="vs-list-item-cell">
            {{ getUnit(row.core_unit_id).code }}
          </div>
        </template>
      </vs-list>
    </authorization-guard>

    <investment-material-modal-add @success="addMaterial" />
    <investment-material-modal-edit @success="updateMaterial" />
  </div>
</template>

<script>
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import VsList from '@/components/ui/vs-list/VsList.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import showToast from '@/libs/toasts';
import { DeleteMaterialSuccess, DeleteMaterialError } from '@/libs/toasts/feedback/module/investments';
import store from '@/store';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';
import InvestmentMaterialModalEdit from './modals/InvestmentMaterialModalEdit.vue';
import InvestmentMaterialModalAdd from './modals/InvestmentMaterialModalAdd.vue';

export default {
  name: 'SettingsTabInvestmentMaterials',
  setup() {
    const { materialResource, addMaterialModal, editMaterialModal } = useInvestmentsStore();

    useInvestmentsApi()
      .fetchPickerMaterials()
      .then(({ data }) => {
        materialResource.value = [...data.data];
      });

    const openEditModal = (material) => {
      editMaterialModal.value.visible = true;
      editMaterialModal.value.data = { ...material };
    };

    const addMaterial = (material) => {
      materialResource.value.push({ ...material });
    };

    const updateMaterial = (material) => {
      materialResource.value = materialResource.value.map((m) => {
        if (m.id !== material.id) return m;
        return material;
      });
    };

    const deleteMaterial = async (requested) => {
      const confirmation = await deleteModal({
        title: i18n.t('investments.modal.material.delete.title'),
        text: i18n.t('investments.modal.material.delete.text', {
          name: requested.name,
        }),
      });

      if (!confirmation) return false;

      return useInvestmentsApi()
        .deleteMaterial(requested.id)
        .then(() => {
          showToast(DeleteMaterialSuccess, { name: requested.name });
          materialResource.value = materialResource.value.filter((s) => s.id !== requested.id);
          RemoteResourceManager.getInstance().notify('investments.material');
        })
        .catch(() => showToast(DeleteMaterialError, { name: requested.name }));
    };

    store.dispatch('units/fetch');
    const getUnit = (id) => store.getters['units/byId'](id) ?? {};

    return {
      materialResource,

      addMaterialModal,
      openEditModal,

      addMaterial,
      updateMaterial,
      deleteMaterial,

      getUnit,
    };
  },
  components: {
    VsButton,
    VsList,
    InvestmentMaterialModalAdd,
    InvestmentMaterialModalEdit,
    AuthorizationGuard,
  },
};
</script>
