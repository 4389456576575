import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useCoreApi from '@/hooks/useCoreApi';

const { currency } = useCoreApi();

export default defineStore('coreCurrency', () => {
  const { state, fetch, fetched } = useRemoteResource('core.currency', currency.fetch);

  return {
    state,
    fetch,
    fetched,
  };
});
