<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useCoreUserStore from '@/stores/module/core/useCoreUserStore';
import CoreUserPickerOption from './CoreUserPickerOption.vue';

export default Vue.component('CoreUnitPicker', {
  emits,
  setup(_, ctx) {
    const store = useCoreUserStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz użytkownika z listy',
      reduce: (option) => option.id,
      label: 'forename',
      options: store.state,
      filter: ['forename', 'surname', 'full_name', 'short_code', 'email'],
      ...ctx.attrs,
    }, {
      option: ({ option }) => h(CoreUserPickerOption, { props: { user: option } }),
      'selected-option': ({ option, props }) => h(CoreUserPickerOption, {
        props: {
          user: option,
          noAvatar: props.multiple,
          noSubtitle: props.multiple,
        },
      }),
    }));
  },
  components: { VsPickerVue },
});
</script>
