var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', [_vm._m(0), _c('tbody', _vm._l(_vm.companyStatuses, function (status) {
    return _c('tr', {
      key: status.id
    }, [_c('td', [_c('status-badge', {
      attrs: {
        "status_id": status.id
      }
    })], 1), _c('td', [_c('activity-badge', {
      attrs: {
        "state": "upToDate",
        "hide-text": true
      }
    })], 1)]);
  }), 0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('th', [_vm._v("Nazwa")]), _c('th', [_vm._v("Aktywny")])]);

}]

export { render, staticRenderFns }