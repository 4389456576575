<template>
  <div
    :class="`
      vs-loader
      ${inline ? 'vs-loader--inline' : ''}
      ${full ? 'vs-loader--full' : ''}
      ${sticky ? 'vs-loader--sticky' : ''}
      ${opaque ? 'vs-loader--opaque' : ''}
      ${compact ? 'vs-loader--compact' : ''}
    `">
    <div class="loader-wrapper">
      <div class="contents">
        <div class="spinner"></div>
        <div class="text" v-if="text">
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Simple loader, a subject to change in the future.
 */
export default {
  props: {
    text: {
      type: String,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    compact: {
      type: Boolean,
      default: false,
    },
    sticky: {
      type: Boolean,
      default: false,
    },
    opaque: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="sass" scoped>
.vs-loader.vs-loader--compact
  height: 100% !important
  min-height: 100%
  top: 0

  .loader-wrapper

    .contents
      display: flex
      align-items: center
      justify-content: center
      gap: 20px

      .text
        margin: 0

.vs-loader.vs-loader--sticky
  .contents
    position: fixed !important
    top: 50% !important

.vs-loader.vs-loader--full
  width: 100%
  height: 100%
  left: 0
  position: absolute
  background: rgba(255, 255, 255, 0.7)
  z-index: 10

.vs-loader--inline
  height: auto
  min-height: auto !important
  padding-bottom: 10px

  .loader-wrapper
    position: relative !important
    justify-content: left !important

    .contents
      display: flex
      align-items: center

      .spinner
        width: 20px
        height: 20px
        border: 2px solid

        &:after
          width: 10px
          height: 10px
          border: 2px solid

      .text
        margin-top: 0
        margin-left: 12px

.vs-loader
  width: 100%
  height: 100%
  min-height: 100px
  position: relative

  .loader-wrapper
    width: 100%
    height: 100%
    position: absolute
    left: 0
    top: 0
    z-index: 3
    display: flex
    align-items: center
    justify-content: center

    .text
      margin-top: 20px
      font-weight: 500

    .spinner
      display: flex
      margin: 0 auto
      align-items: center
      justify-content: center
      width: 40px
      height: 40px
      border-radius: 30%
      border: 3px solid
      animation: spin 2s ease infinite

      &:after
        content: ''
        display: block
        width: 20px
        height: 20px
        border: 3px solid
        border-radius: 4px
        animation: spin 2s ease infinite

@keyframes spin
  0%
    transform: rotateZ(0deg)
    opacity: 1
  50%
    transform: rotateZ(180deg)
    opacity: 0.5
  100%
    transform: rotateZ(360deg)
    opacity: 1

</style>
