export default {
  pageTitle: 'Ustawienia',
  list: {
    pageTitle: 'Ustawienia',
  },
  cadre: {
    pageTitle: 'Ustawienia modułu Kadra',
    position: {
      field: {
        group: 'Grupa',
        cost: 'Koszt dzienny',
      },
    },
    employment: {
      add: {
        title: 'Dodaj formę zatrudnienia',
      },
      button: {
        add: 'Forma zatrudnienia',
        cancel: 'Anuluj',
        save: 'Zapisz formę zatrudnienia',
      },
      edit: {
        title: 'Edytuj formę zatrudnienia',
      },
      field: {
        name: 'Nazwa',
      },
      modal: {
        delete: {
          title: 'Potwierdź usunięcie formy zatrudnienia',
          text: 'Czy na pewno chcesz usunąć formę zatrudnienia {name}? Tej akcji nie można cofnąć.',
        },
      },
    },
    groups: {
      add: {
        title: 'Dodaj grupę',
      },
      button: {
        add: 'Grupa',
        cancel: 'Anuluj',
        save: 'Zapisz grupę',
      },
      edit: {
        title: 'Edytuj grupę',
      },
      field: {
        name: 'Nazwa',
      },
      modal: {
        delete: {
          title: 'Potwierdź usunięcie grupy',
          text: 'Czy na pewno chcesz usunąć grupę {name}? Tej akcji nie można cofnąć.',
        },
      },
    },
    positions: {
      add: {
        title: 'Dodaj stanowisko',
      },
      button: {
        add: 'Stanowisko',
        cancel: 'Anuluj',
        save: 'Zapisz stanowisko',
      },
      edit: {
        title: 'Edytuj stanowisko',
      },
      field: {
        name: 'Nazwa',
      },
      modal: {
        delete: {
          title: 'Potwierdź usunięcie stanowiska',
          text: 'Czy na pewno chcesz usunąć stanowisko {name}? Tej akcji nie można cofnąć.',
        },
      },
    },
    skills: {
      add: {
        title: 'Dodaj kompetencje',
      },
      button: {
        add: 'Kompetencje',
        cancel: 'Anuluj',
        save: 'Zapisz kompetencje',
      },
      edit: {
        title: 'Edytuj kompetencje',
      },
      field: {
        name: 'Nazwa',
      },
      modal: {
        delete: {
          title: 'Potwierdź usunięcie kompetencji',
          text: 'Czy na pewno chcesz usunąć kompotencje {name}? Tej akcji nie można cofnąć.',
        },
      },
    },
  },
};
