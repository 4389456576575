import store from '@/store';
import axios from 'axios';
import Qs from 'qs';

// function simulates throttling if the corresponding
// setting in store is set to true
const simulateThrottling = () => {
  const enabled = store.state.app.simulateThrottling;
  if (!enabled) return new Promise((resolve) => resolve());

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, 2000);
  });
};

const observers = [];
const notifyObservers = (code, msg = null) => {
  observers
    .filter((o) => o.code === code)
    .forEach((o) => o.callback(msg));
};
export const registerApiObserver = (code, callback) => {
  observers.push({ code, callback });
};

/**
 * ApiClient class for communicating with back-end.
 */
class ApiClient {
  static axiosApi() {
    const apiClient = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: true,
      xsrfCookieName: 'XSRF-TOKEN',
      xsrfHeaderName: 'X-XSRF-TOKEN',
    });

    return apiClient;
  }

  static async get(route, data = null) {
    if (!route) {
      return new Promise(
        (_resolve, reject) => reject(new Error('No API route provided')),
      );
    }

    await simulateThrottling();

    const request = this.axiosApi().get(route, {
      params: { ...data },
      paramsSerializer: function paramsSerializer(params) {
        return Qs.stringify(params, {
          arrayFormat: 'indices',
          encode: false,
          filter: (_, v) => (typeof v === 'boolean' ? (+v) : v),
        });
      },
    });

    request.catch(({ response }) => {
      notifyObservers(response.status);
    });

    return request;
  }

  static async post(route, data) {
    return this.axiosApi().post(route, data);
  }

  static async put(route, data) {
    return this.axiosApi().put(route, data);
  }

  static async patch(route, data) {
    return this.axiosApi().patch(route, data);
  }

  static async delete(route, data) {
    return this.axiosApi().delete(route, data);
  }
}

export const buildRequest = (method, url, data = null) => ApiClient?.[method](url, data);

export default ApiClient;
