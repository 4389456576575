import cars from './cars';
import transport from './transport';
import service from './service';

export default {
  pageTitle: 'Baza',
  global: {
    field: {
      date: 'Data',
      dateFrom: 'Data od',
      dateTo: 'Data do',
      group: 'Grupa',
      investment: 'Inwestycja',
      liters: 'Litry',
      machineName: 'Nazwa maszyny',
      producer: 'Producent',
      region: 'Region',
      reporter: 'Zgłaszający',
      status: 'Status',
      technology: 'Technologie',
      type: 'Typ',
      category: 'Kategoria',
      reportType: 'Typ raportów',
      brand: 'Marka',
      model: 'Model',
      user: 'Użytkownik',
      branch: 'Region',
    },
    button: {
      addMTH: 'Przebieg',
      addRefuel: 'Tankowanie',
      reports: 'Raporty',
    },
    title: {
      VEHICLE_TYPE: 'Maszyna',
      CAR_TYPE: 'Samochód',
      TOOL_TYPE: 'Sprzęt',
    },
    resourceType: {
      vehicle: 'Maszyna',
      car: 'Samochód',
      tool: 'Sprzęt',
    },
  },
  technology: {
    button: {
      add: 'Technologia',
    },
    modal: {
      add: {
        title: 'Dodawanie nowej technologii',
        button: {
          cancel: 'Anuluj',
          ok: 'Zapisz technologię',
        },
      },
      edit: {
        title: 'Edytuj technologię',
        button: {
          cancel: 'Anuluj',
          ok: 'Zapisz technologię',
        },
      },
      delete: {
        title: 'Potwierdź usunięcie technologii',
        text: 'Technologia "{name}" zostanie trwale usunięta. Czy na pewno chcesz kontynuować?',
      },
    },
    field: {
      name: 'Nazwa',
    },
  },
  category: {
    button: {
      add: 'Kategoria',
    },
    field: {
      unit: 'Jednostka stanu licznika',
      type: 'Typ zasobu',
      name: 'Nazwa',
    },
    modal: {
      add: {
        title: 'Dodaj kategorię',
        button: {
          cancel: 'Anuluj',
          ok: 'Zapisz kategorię',
        },
      },
      edit: {
        title: 'Edytuj kategorię',
        button: {
          cancel: 'Anuluj',
          ok: 'Zapisz kategorię',
        },
      },
      delete: {
        title: 'Potwierdź usunięcie kategorii',
        text: 'Kategoria "{name}" zostanie trwale usunięta. Czy na pewno chcesz kontynuować?',
      },
    },
  },
  producer: {
    button: {
      add: 'Producent',
    },
    field: {
      name: 'Nazwa',
      producer: 'Producent',
      type: 'Typ',
    },
    modal: {
      add: {
        title: 'Dodaj producenta',
        button: {
          cancel: 'Anuluj',
          ok: 'Zapisz producenta',
        },
      },
      edit: {
        title: 'Edytuj producenta',
        button: {
          cancel: 'Anuluj',
          ok: 'Zapisz producenta',
        },
      },
      delete: {
        title: 'Potwierdź usunięcie producenta',
        text: 'Producent "{name}" zostanie trwale usunięty. Czy na pewno chcesz kontynuować?',
      },
    },
    model: {
      button: {
        add: 'Model',
      },
      modal: {
        add: {
          title: 'Dodaj model producenta',
          button: {
            cancel: 'Anuluj',
            ok: 'Zapisz model',
          },
        },
        edit: {
          title: 'Edytuj model producenta',
          button: {
            cancel: 'Anuluj',
            ok: 'Zapisz model',
          },
        },
        delete: {
          title: 'Potwierdź usunięcie modelu',
          text: 'Model "{name}" zostanie trwale usunięty. Czy na pewno chcesz kontynuować?',
        },
      },
    },
  },
  machines: {
    pageTitle: 'Maszyny',
    modal: {
      delete: {
        title: 'Potwierdź usunięcie zasobu',
        text: 'Zasób "{name}" zostanie trwale usunięty. Czy na pewno chcesz kontynuować?',
      },
      unassignTool: {
        title: 'Potwierdź usunięcie przypisania sprzętu',
        text: 'Sprzęt "{tool}" zostanie odłączony od maszyny "{machine}". Czy na pewno chcesz kontynuować?',
      },
      deleteReport: {
        title: 'Potwierdź usunięcie raportu',
        text: 'Raport z dnia {date} zostanie trwale usunięty. Czy na pewno chcesz kontynuować?',
      },
      add: {
        title: 'Dodaj maszynę',
        button: {
          cancel: 'Anuluj',
          save: 'Zapisz maszynę',
        },
      },
      reportMth: {
        title: 'Dodaj raport przebiegu',
        button: {
          cancel: 'Anuluj',
          save: 'Zapisz raport',
        },
      },
      reportFuel: {
        title: 'Dodaj raport tankowania',
        button: {
          cancel: 'Anuluj',
          save: 'Zapisz raport',
        },
      },
    },
    mth: {
      field: {
        machine: 'Maszyna',
        equipment: 'Sprzęt',
        unit: 'Jednostka',
        meterStatus: 'Stan licznika',
        reportDate: 'Data raportu',
        reporter: 'Raportujący',
        operator: 'Operator',
        mth: 'Stan licznika',
        fuel: 'Paliwo',
      },
    },
    button: {
      addMachine: 'Maszyna',
    },
    list: {
      pageTitle: 'Lista maszyn',
    },
    details: {
      pageTitle: 'Szczegóły maszyny',
    },
    reports: {
      pageTitle: 'Raporty',
      fullname: 'Raporty maszyn',
    },
    schedule: {
      pageTitle: 'Harmonogram',
      fullname: 'Harmonogram maszyn',
    },
    field: {
      location: 'Lokalizacja',
      mth: 'Stan licznika',
      name: 'Nazwa',
      cost: 'Koszt dzienny',
      producer: 'Producent',
      productionYear: 'Rok produkcji',
      serialNumber: 'Nr ewidencyjny',
      serial_no: 'Nr ewidencyjny',
      status: 'Status',
      type: 'Typ',
      worktype: 'Typ pracy',
    },
    settings: {
      pageTitle: 'Ustawienia maszyny',
    },
  },
  refueling: {
    pageTitle: 'Tankowania',
    button: {
      reportMth: 'Raport przebiegu',
      reportFuel: 'Raport tankowania',
    },
  },
  equipment: {
    pageTitle: 'Sprzęt',
    add: {
      title: 'Dodaj sprzęt',
      button: {
        cancel: 'Anuluj',
        save: 'Zapisz sprzęt',
      },
    },
    modal: {
      delete: {
        title: 'Potwierdź usunięcie sprzętu',
        text: 'Sprzęt "{name}" zostanie trwale usunięty. Czy na pewno chcesz kontynuować?',
      },
    },
    button: {
      addEquipment: 'Sprzęt',
    },
    field: {
      name: 'Nazwa',
      evidenceNumber: 'Nr. ewidencyjny',
      type: 'Typ',
      status: 'Status',
      endOFTheRentalPeriod: 'Do końca wynajmu',
      location: 'Lokalizacja',
      group: 'Grupa',
    },
    list: {
      pageTitle: 'Lista sprzętów',
    },
    details: {
      pageTitle: 'Szczegóły sprzętu',
    },
    settings: {
      pageTitle: 'Ustawienia sprzętu',
    },
    reports: {
      pageTitle: 'Raporty',
      fullname: 'Raporty sprzętu',
    },
    schedule: {
      pageTitle: 'Harmonogram',
      fullname: 'Harmonogram sprzętu',
    },
  },
  cars,
  transport,
  service,
};
