<template>
  <div class="settings-tab-crm-industries-form">
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('base.category.field.name')"
            label-cols-md="3"
            :invalid-feedback="errors.name"
            :state="errors.name === null"
            >
            <b-input-group>
              <b-form-input
                :placeholder="$t('base.category.field.name')"
                v-model="category.name"/>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('base.category.field.type')"
            label-cols-md="3"
            :invalid-feedback="errors.base_resource_type_id"
            :state="errors.base_resource_type_id !== null"
          >
            <base-resource-type-picker v-model="category.base_resource_type_id"/>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Raporty"
            label-cols-md="3"
            :invalid-feedback="errors.type"
            :state="errors.type !== null"
          >
            <b-form-checkbox
              v-model="category.be_reported"
              class="reportable-checkbox"
            >
              Posiada raporty
            </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="category.be_reported">
        <b-col cols="12">
          <b-form-group
            :label="$t('base.category.field.unit')"
            label-cols-md="3"
            :invalid-feedback="errors.type"
            :state="errors.type !== null"
          >
            <unit-picker v-model="category.unit_id" />
          </b-form-group>
        </b-col>
      </b-row>

    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormCheckbox,
} from 'bootstrap-vue';
import { inject } from 'vue';
import BaseResourceTypePicker
  from '@/components/views/settings/content-tabs/base/ui/picker/BaseResourceTypePicker.vue';
import UnitPicker from '@/components/ui/pickers/Unit.vue';

export default {
  name: 'SettingsTabBaseCategoryForm',
  setup() {
    const { category } = inject('categoriesInstance');
    const errors = {};

    return { category, errors };
  },
  components: {
    UnitPicker,
    BaseResourceTypePicker,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormCheckbox,
    BFormInput,
  },
};
</script>

<style lang="sass" scoped>
.reportable-checkbox
  margin: 7px 0
</style>
