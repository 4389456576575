<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useCrmCompanyStore from '@/stores/module/crm/useCrmCompanyStore';
import CrmProviderBranchPersonPickerOption from './CrmProviderBranchPersonPickerOption.vue';

export default Vue.component('CrmProviderBranchPicker', {
  emits,
  setup(_, ctx) {
    const { providersContactPersons } = useCrmCompanyStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz osobę kontaktową z listy',
      reduce: (option) => option.id,
      label: 'forename',
      options: providersContactPersons,
      ...ctx.attrs,
    }, {
      option: ({ option }) => h(CrmProviderBranchPersonPickerOption, {
        props: {
          provider: option,
        },
      }),
      'selected-option': ({ option }) => h(CrmProviderBranchPersonPickerOption, {
        props: {
          provider: option,
        },
      }),
      'no-options': () => 'Brak osób kontaktowych w wybranym oddziale',
    }));
  },
  components: { VsPickerVue },
});
</script>
