<template>
  <div>
    <!--
      TODO:
      Refactor is needed here, this component is redundant
      and tabs are using provide/inject without an explicit reason
      -->
    <tabs />
  </div>
</template>

<script>
import Tabs from '@/components/views/settings/Tabs.vue';

export default {
  components: {
    Tabs,
  },
  data() {
    return {
      options: {},
    };
  },
};
</script>
