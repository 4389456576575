<template>
  <div class="permission-entry-module">

    <div
      class="header"
    >
      <permission-switcher
        :value="state"
        @change="changeState"
        :noInherit="noInherit"
      />

      <div class="header-text">
        <div class="name">
          {{ moduleTranslation }}
        </div>

        {{ `${module}.${resource}.${name}` }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import i18n from '@/libs/i18n';
import PermissionSwitcher from './PermissionSwitcher.vue';

export default {
  name: 'PermissionEntryModule',
  emits: ['change'],
  props: {
    module: String,
    resource: String,
    name: String,
    state: String,
    noInherit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const moduleTranslation = computed(() => {
      const specific = `permission.${props.module}.resource.${props.resource}.action.${props.name}`;
      const generic = `permission._genericActions.${props.name}`;

      if (i18n.te(specific)) return i18n.t(specific);
      if (i18n.te(generic)) return i18n.t(generic);

      return `${props.module}.${props.resource}.${props.name}`;
    });

    const changeState = (val) => emit('change', val);

    return { moduleTranslation, changeState };
  },
  components: {
    PermissionSwitcher,
  },
};
</script>

<style lang="sass" scoped>
.header
  display: flex
  align-items: center
  gap: 20px
  font-size: 9pt
  line-height: 1.2
  padding: 5px 10px
  padding-left: 90px

  .name
    font-size: 12pt
    font-weight: 500
</style>
