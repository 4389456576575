<template>
  <div class="vs-datepicker">
    <b-form-datepicker
      class="date"
      :locale="$i18n.locale"
      :placeholder="placeholder"
      v-model="datepickerValue"
      :reset-button="resetButton"
      :label-reset-button="labelResetButton"
      start-weekday="1"
      v-bind="$attrs"
    />

    <b-form-timepicker
      v-if="withTime"
      class="time"
      :hour12="false"
      v-model="time"
      placeholder=""
    />
  </div>
</template>

<script>
import { BFormDatepicker, BFormTimepicker } from 'bootstrap-vue';
import i18n from '@/libs/i18n';
import { computed, reactive, watch } from 'vue';
import formatDate from '@/libs/date-formatter';

export default {
  name: 'VsDatepicker',
  inheritAttrs: false,
  props: {
    value: {
      type: String,
    },
    resetButton: {
      type: Boolean,
      default: false,
    },
    withTime: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: i18n.t('global.calendar.pickDate'),
    },
    labelResetButton: {
      type: String,
      default: 'Wyczyść datę',
    },
  },
  setup(props, { emit }) {
    const getValueTime = () => formatDate.getTime(props.value);
    const localValue = reactive({
      date: formatDate.standard(props.value),
      time: getValueTime(),
    });

    watch(() => localValue, () => {
      const { date, time } = localValue;
      let emitted = date;

      if (props.withTime) {
        emitted = `${emitted} ${time}`;
      }

      emit('input', emitted);
    }, { deep: true });

    const datepickerValue = computed({
      get: () => formatDate.standard(props.value),
      set: (val) => {
        localValue.date = val;
      },
    });

    const time = computed({
      get: () => getValueTime(),
      set: (val) => {
        localValue.time = val;
      },
    });

    return {
      datepickerValue,
      time,
    };
  },
  components: {
    BFormDatepicker,
    BFormTimepicker,
  },
};
</script>

<style lang="sass" scoped>
.vs-datepicker
  display: flex
  align-items: center
  gap: 10px

  &::v-deep
    label.form-control
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap !important

  .time
    max-width: 110px
</style>
