export default {
  tasks: {
    store: {
      title: 'Utworzono zadanie {id}',
      description: 'Utworzono przez {name}',
    },
    update: {
      title: 'Zaktualizowano zadanie {id}',
      description: 'Zmodyfikowane przez {name}',
    },
    changeStatus: {
      title: 'Zaktualizowano status zadania {id}',
      description: 'Zmieniono status przez {name}',
    },
    commentAdded: {
      title: 'Dodano nowy komentarz do zadania {id}',
      description: 'Dodano przez {name}',
    },
    destroy: {
      title: 'Usunięto zadanie {id}',
      description: 'Usunięto przez {name}',
    },
  },
};
