<template>
  <vs-modal
    class="profile-settings-devices-edit-modal"
    :modalInstance="modalEditAuthorizedDevice"
    @ok="updateAuthorizedDevice"
    @show="setData"
    @hide="resetFields"
  >

    <profile-settings-device-form
      v-model="fields"
      :feedback="feedback"
    />

  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useCoreModals from '@/hooks/useCoreModals';
import useCoreApi from '@/hooks/useCoreApi';
import useValidatedForm from '@/hooks/useValidatedForm';
import { ref } from 'vue';
import { AuthorizedDevicesUpdateError, AuthorizedDevicesUpdateSuccess } from '@/libs/toasts/feedback/module/core';
import showToast from '@/libs/toasts';
import ProfileSettingsDeviceForm from '../form/ProfileSettingsDeviceForm.vue';

export default {
  name: 'ProfileSettingsDevicesEditModal',
  emits: ['success'],
  setup(_, { emit }) {
    const { modalEditAuthorizedDevice } = useCoreModals();
    const editedId = ref(null);

    const {
      fields, assignFields, feedback, resetFields,
    } = useValidatedForm({
      name: 'required',
    });

    const setData = (data) => {
      editedId.value = data?.id;
      assignFields(data);
    };

    const updateAuthorizedDevice = () => {
      modalEditAuthorizedDevice.value.isLoading = true;

      useCoreApi()
        .updateAuthorizedDevice(editedId.value, fields.value)
        .then(({ data }) => {
          modalEditAuthorizedDevice.value.isLoading = false;
          modalEditAuthorizedDevice.value.visible = false;
          showToast(AuthorizedDevicesUpdateSuccess, { name: fields.value.name });
          emit('success', data.data);
        })
        .catch(() => {
          modalEditAuthorizedDevice.value.isLoading = false;
          showToast(AuthorizedDevicesUpdateError);
        });
    };

    return {
      modalEditAuthorizedDevice,
      updateAuthorizedDevice,
      fields,
      feedback,
      setData,
      resetFields,
    };
  },
  components: { VsModal, ProfileSettingsDeviceForm },
};
</script>
