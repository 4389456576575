<template>
  <div class="settings-tab-investments-work-group">

    <authorization-guard
      silent
      permission="investment.work_group.store"
    >
      <div class="buttons">
        <div class="group">
          <vs-button
            vs-variant="primary"
            @click="addWorkGroupModal.visible = true"
          >
            + Grupa
          </vs-button>
        </div>
      </div>
    </authorization-guard>

    <authorization-guard
      permission="investment.work_group.index"
    >
      <vs-list showHeaders
        :items="workGroups"
        @edit="openEditModal"
        @delete="deleteWorkGroup"
        :headers="['Nazwa']"
      />
    </authorization-guard>

    <investment-work-group-modal-add @success="addWorkGroup" />
    <investment-work-group-modal-edit @success="updateWorkGroup" />
  </div>
</template>

<script>
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import VsList from '@/components/ui/vs-list/VsList.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import showToast from '@/libs/toasts';
import { DeleteWorkGroupSuccess, DeleteWorkGroupError } from '@/libs/toasts/feedback/module/investments';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';
import InvestmentWorkGroupModalAdd from './modals/InvestmentWorkGroupModalAdd.vue';
import InvestmentWorkGroupModalEdit from './modals/InvestmentWorkGroupModalEdit.vue';

export default {
  name: 'SettingsTabInvestmentWorkGroup',
  setup() {
    const { workGroups, addWorkGroupModal, editWorkGroupModal } = useInvestmentsStore();

    useInvestmentsApi()
      .fetchPickerWorkGroups()
      .then(({ data }) => {
        workGroups.value = [...data.data];
      });

    const openEditModal = (workGroup) => {
      editWorkGroupModal.value.visible = true;
      editWorkGroupModal.value.data = { ...workGroup };
    };

    const addWorkGroup = (workGroup) => {
      workGroups.value.push({ ...workGroup });
      RemoteResourceManager.getInstance().notify('investments.work-group');
    };

    const updateWorkGroup = (workGroup) => {
      workGroups.value = workGroups.value.map((m) => {
        if (m.id !== workGroup.id) return m;
        return workGroup;
      });
      RemoteResourceManager.getInstance().notify('investments.work-group');
    };

    const deleteWorkGroup = async (requested) => {
      const confirmation = await deleteModal({
        title: i18n.t('investments.modal.workGroup.delete.title'),
        text: i18n.t('investments.modal.workGroup.delete.text', {
          name: requested.name,
        }),
      });

      if (!confirmation) return false;

      return useInvestmentsApi()
        .deleteWorkGroup(requested.id)
        .then(() => {
          showToast(DeleteWorkGroupSuccess, { name: requested.name });
          RemoteResourceManager.getInstance().notify('investments.work-group');
          workGroups.value = workGroups.value
            .filter((s) => s.id !== requested.id);
        })
        .catch(() => showToast(DeleteWorkGroupError, { name: requested.name }));
    };

    return {
      workGroups,

      addWorkGroupModal,
      openEditModal,

      addWorkGroup,
      updateWorkGroup,
      deleteWorkGroup,
    };
  },
  components: {
    VsButton,
    VsList,
    InvestmentWorkGroupModalAdd,
    InvestmentWorkGroupModalEdit,
    AuthorizationGuard,
  },
};
</script>
