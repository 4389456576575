import Vue from 'vue';
import Vuex from 'vuex';

import ApiClient from '@/services/api';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    currencies: [],
  },

  getters: {
    all: (state) => state.currencies,
    // eslint-disable-next-line arrow-body-style
    byId: (state) => (searchedId) => state.currencies.filter((currency) => {
      return currency.id === searchedId;
    })[0] || {},
  },

  mutations: {
    SET_CURRENCIES(state, payload) {
      state.currencies = payload;
    },
    ADD_CURRENCY(state, payload) {
      state.currencies = [...state.currencies, ...payload];
    },
  },

  actions: {
    fetch({ commit }) {
      const request = ApiClient.get('api/core/currency/get-list');
      request.then((res) => {
        if (res.status !== 200) return null;
        return commit('SET_CURRENCIES', res.data.data);
      });

      return request;
    },
  },
};
