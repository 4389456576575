var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-tabs', {
    attrs: {
      "nav-class": "nav-left"
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "UserIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Statusy")])];
      },
      proxy: true
    }])
  }, [_c('status-management')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UserIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Formy zatrudnienia")])];
      },
      proxy: true
    }])
  }, [_c('employment-forms')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UserIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Grupy")])];
      },
      proxy: true
    }])
  }, [_c('groups')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UserIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Stanowiska")])];
      },
      proxy: true
    }])
  }, [_c('positions')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UserIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Kompetencje")])];
      },
      proxy: true
    }])
  }, [_c('skills')], 1)], 1), _c('modal-add-group'), _c('modal-add-position')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }