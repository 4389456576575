var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "task-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.feedback,
      "i18nNamespace": "form.tasks.task"
    },
    model: {
      value: _vm.task,
      callback: function ($$v) {
        _vm.task = $$v;
      },
      expression: "task"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "title"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.task.title,
      callback: function ($$v) {
        _vm.$set(_vm.task, "title", $$v);
      },
      expression: "task.title"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "priority_id"
    }
  }, [_c('core-priority-picker', {
    model: {
      value: _vm.task.priority_id,
      callback: function ($$v) {
        _vm.$set(_vm.task, "priority_id", $$v);
      },
      expression: "task.priority_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "assigned_id"
    }
  }, [_c('core-user-picker', {
    model: {
      value: _vm.task.assigned_id,
      callback: function ($$v) {
        _vm.$set(_vm.task, "assigned_id", $$v);
      },
      expression: "task.assigned_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "termination_date"
    }
  }, [_c('vs-datepicker', {
    model: {
      value: _vm.task.termination_date,
      callback: function ($$v) {
        _vm.$set(_vm.task, "termination_date", $$v);
      },
      expression: "task.termination_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "description"
    }
  }, [_c('editor', {
    model: {
      value: _vm.task.description,
      callback: function ($$v) {
        _vm.$set(_vm.task, "description", $$v);
      },
      expression: "task.description"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }