var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._t("header", function () {
    return [_c('ul', {
      staticClass: "nav navbar-nav flex-row"
    }, [_c('li', {
      staticClass: "nav-item mr-auto"
    }, [_c('b-link', {
      staticClass: "navbar-brand",
      attrs: {
        "to": "/"
      }
    }, [_c('span', {
      staticClass: "brand-logo"
    }, [_c('b-img', {
      attrs: {
        "src": _vm.$store.getters['themes/branding'].logo,
        "alt": "logo"
      }
    })], 1), _c('h1', {
      staticClass: "brand-logo-brand"
    }, [_c('b-img', {
      attrs: {
        "src": _vm.$store.getters['themes/branding'].logoBrand,
        "alt": _vm.appName
      }
    })], 1)])], 1), _c('li', {
      staticClass: "nav-item nav-toggle"
    }, [_c('b-link', {
      staticClass: "nav-link modern-nav-toggle"
    }, [_c('feather-icon', {
      staticClass: "d-block d-xl-none",
      attrs: {
        "icon": "XIcon",
        "size": "20"
      },
      on: {
        "click": _vm.toggleVerticalMenuActive
      }
    }), _c('feather-icon', {
      staticClass: "d-none d-xl-block collapse-toggle-icon",
      attrs: {
        "icon": _vm.collapseTogglerIconFeather,
        "size": "20"
      },
      on: {
        "click": _vm.toggleCollapsed
      }
    })], 1)], 1)])];
  }, {
    "toggleVerticalMenuActive": _vm.toggleVerticalMenuActive,
    "toggleCollapsed": _vm.toggleCollapsed,
    "collapseTogglerIcon": _vm.collapseTogglerIcon
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }