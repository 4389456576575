import core from './core';

import tasks from './tasks';
import orders from './orders';
import cadre from './cadre';
import crm from './crm';
import base from './base';
import investments from './investments';

import component from './component';

export default {
  core,

  tasks,
  orders,
  cadre,
  crm,
  base,
  investments,

  component,
};
