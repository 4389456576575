<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useCrmCompanyStore from '@/stores/module/crm/useCrmCompanyStore';
import CrmProviderBranchPickerOption from './CrmProviderBranchPickerOption.vue';

export default Vue.component('CrmClientBranchPicker', {
  emits,
  setup(_, ctx) {
    const { clientsBranches } = useCrmCompanyStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz oddział klienta z listy',
      reduce: (option) => option.id,
      selectable: (option) => option.branch,
      filter: ['name', 'nip'],
      options: clientsBranches,
      ...ctx.attrs,
    }, {
      option: ({ option }) => h(CrmProviderBranchPickerOption, {
        props: {
          provider: option,
        },
      }),
      'selected-option': ({ option }) => h(CrmProviderBranchPickerOption, {
        props: {
          provider: option,
        },
      }),
    }));
  },
  components: { VsPickerVue },
});
</script>
