export default {
  company: {
    name: 'Nazwa firmy',
    website: 'Strona internetowa',
    company_status_id: 'Status',
    nip: 'NIP',
    regon: 'REGON',
    krs: 'KRS',
    branch_ids: 'Region',
    industry_ids: 'Branża',
    protected_workplace: 'Miejsce pracy chronionej',
  },
};
