import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const echoStore = {
  namespaced: true,
  state: {
    publicMessages: [],
  },
  getters: {
    newestPublicMessage(state) {
      const message = state.publicMessages[state.publicMessages.length - 1] || null;
      return message;
    },
    publicMessages(state) {
      return state.publicMessages;
    },
  },
  mutations: {
    PUSH_PUBLIC_MESSAGE(state, message) {
      const date = new Date();

      state.publicMessages.push({
        ...message,
        channel: 'public-channel-message',
        timestamp: date.toLocaleTimeString(),
      });
    },
  },
  actions: {
    newPublicMessage({ commit }, payload) {
      return commit('PUSH_PUBLIC_MESSAGE', payload);
    },
    newNotification({ dispatch }, payload) {
      return dispatch('auth/notifications/addNotification', payload, { root: true });
    },
  },
};

export default echoStore;
