import { ref } from 'vue';
import useCRMApi from '@/hooks/crm/useCRMApi';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import showToast from '@/libs/toasts';
import {
  CreateServiceError,
  CreateServiceSuccess,
  DeleteServiceError,
  DeleteServiceSuccess,
  UpdateServiceError,
  UpdateServiceSuccess,
} from '@/libs/toasts/feedback/module/crm';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

const useServices = () => {
  const services = ref([]);
  const service = ref({});
  const assigned = ref([]);

  const modalAddVisible = ref(false);
  const modalEditVisible = ref(false);

  const isLoading = ref(false);

  const fetch = () => {
    useCRMApi()
      .fetchServices()
      .then(({ data }) => {
        services.value = data.data;
      })
      .catch(() => {});
  };

  const createService = () => {
    isLoading.value = true;

    useCRMApi()
      .createService(service.value)
      .then(({ data }) => {
        isLoading.value = false;
        services.value.push(data.data);
        modalAddVisible.value = false;

        showToast(CreateServiceSuccess, { name: data.data.name });
        service.value = {};
        RemoteResourceManager.getInstance().notify('crm.service');
      })
      .catch(() => {
        isLoading.value = false;
        showToast(CreateServiceError, { name: service.value.name });
      });
  };

  const editService = () => {
    isLoading.value = true;

    useCRMApi()
      .updateService(service.value)
      .then(({ data }) => {
        isLoading.value = false;
        services.value = services.value.map((s) => (s.id === data.data.id ? data.data : s));
        modalEditVisible.value = false;

        showToast(UpdateServiceSuccess, { name: data.data.name });
        service.value = {};
        RemoteResourceManager.getInstance().notify('crm.service');
      })
      .catch(() => {
        isLoading.value = false;
        showToast(UpdateServiceError, { name: service.value.name });
      });
  };

  const deleteService = async (requested) => {
    const confirmation = await deleteModal({
      title: i18n.t('crm.service.modal.delete.title'),
      text: i18n.t('crm.service.modal.delete.text', {
        name: requested.name,
      }),
    });

    if (!confirmation) return false;

    return useCRMApi()
      .deleteService(requested.id)
      .then(() => {
        services.value = services.value.filter((s) => s.id !== requested.id);
        showToast(DeleteServiceSuccess, { name: requested.name });
        RemoteResourceManager.getInstance().notify('crm.service');
      })
      .catch(() => showToast(DeleteServiceError, { name: requested.name }));
  };

  // maybe move to company hook
  const assignServices = (providerId) => {
    useCRMApi()
      .assignServices(providerId, { service_ids: assigned.value })
      .then(({ data }) => {
        showToast(CreateServiceSuccess, { name: data.data.name });
      })
      .catch(() => showToast(CreateServiceError, { name: service.value.name }));
  };

  const openAddModal = () => {
    service.value = {};
    modalAddVisible.value = true;
  };

  const openEditModal = (edited) => {
    service.value = { ...edited };
    modalEditVisible.value = true;
  };

  return {
    services,
    service,
    assigned,

    isLoading,
    modalAddVisible,
    modalEditVisible,

    fetch,
    openEditModal,
    openAddModal,
    createService,
    editService,
    deleteService,
    assignServices,
  };
};

export default useServices;
