import StatusBadge from '@/components/ui/status-badge/StatusBadge.vue';
import DefaultEventComponent from './default';

const OrderCreated = {
  props: ['translation', 'event'],
  template: '<span>{{ $t(translation, { order }) }}</span>',
  computed: { order() { return this.event.new_values.order_number; } },
};

const OrderUpdatedStatus = {
  props: ['translation', 'event'],
  template: `
    <span>
      {{ $t(translation) }}
      <status-badge orders :status_id="event.new_values.status_id"/>
    </span>
  `,
  components: {
    StatusBadge,
  },
};

const OrderUpdatedOfferNumber = {
  props: ['translation', 'event'],
  template: `
    <span>
      {{ $t(translation, {
        old: event.old_values.offer_number,
        new: event.new_values.offer_number,
      }) }}
    </span>
  `,
};

export default {
  created: {
    default: OrderCreated,
  },
  updated: {
    default: DefaultEventComponent,
    offer_number: OrderUpdatedOfferNumber,
    status_id: OrderUpdatedStatus,
  },
};
