<template>
  <div class="settings-tab-investments-status">

    <div class="buttons">
      <div class="group">
        <vs-button
          vs-variant="primary"
          @click="() => {}"
          disabled
        >
          + Status
        </vs-button>
      </div>
    </div>

    <div
      class="status-wrapper"
      v-for="status in statuses"
      :key="status.id"
    >
      <vs-status-badge-investment
        :id="status.id"
      />
    </div>
  </div>
</template>

<script>
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import VsStatusBadgeInvestment from '@/components/ui/vs-status-badge/investment/VsStatusBadgeInvestment.vue';

export default {
  name: 'SettingsTabInvestmentStatus',
  setup() {
    const { statuses } = useInvestmentsStore();

    return {
      statuses,
    };
  },
  components: {
    VsButton,
    VsStatusBadgeInvestment,
  },
};
</script>

<style lang="sass" scoped>
.status-wrapper
  margin: 15px 0
</style>
