export default {
  priorityLabel: [{
    1: 'Niski', 2: 'Normalny', 3: 'Wysoki', 4: 'Krytyczny',
  },
  {
    1: 'ArrowDownCircleIcon', 2: 'ArrowRightCircleIcon', 3: 'AlertTriangleIcon', 4: 'AlertOctagonIcon',
  },
  {
    1: '#09b3da', 2: '#1ea75b', 3: '#F39314', 4: '#ff0002',
  },
  ],
};
