import { ref, watch } from 'vue';

export default function useLocalStorage(name, initialValue) {
  const storageRef = ref(localStorage.getItem(name) ?? initialValue);

  watch(storageRef, () => {
    localStorage.setItem(name, storageRef.value);
  });

  return storageRef;
}
