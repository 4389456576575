<template>
  <div class="settings-tab-base-statuses">
    <table>
      <thead>
        <th>Nazwa</th>
        <th>Dotyczy</th>
        <th>Aktywny</th>
      </thead>
      <tbody>
        <tr v-for="status in statuses" :key="status.id">
          <td>
            <status-machine-badge :status_id="status.id"/>
          </td>

          <td>
            <div
              class="resource-type"
              v-for="type in status.types"
              :key="type"
            >
              {{ $t(`base.global.resourceType.${type.slug}`) }}
            </div>
          </td>

          <td>
            <activity-badge state="upToDate" :hide-text="true"/>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import useBaseStatus from '@/hooks/base/assignables/useBaseStatus';
import { provide } from 'vue';
import ActivityBadge from '@/components/views/cadre/ui/ExaminationBadge.vue';
import StatusMachineBadge from '@/components/ui/status-badge/Machine.vue';

export default {
  name: 'SettingsTabBaseStatuses',
  setup() {
    const statusesInstance = useBaseStatus();
    const { statuses, fetchStatusesIndex } = statusesInstance;

    provide('statusesInstance', statusesInstance);

    fetchStatusesIndex();

    return {
      statuses,
    };
  },
  components: {
    ActivityBadge,
    StatusMachineBadge,
  },
};
</script>

<style lang="sass" scoped>
.resource-type
  display: inline-block

  &:not(:first-child):before
    content: ','

table
  width: 100%
  margin-top: 25px

  td
    padding: 10px 0

    &.expiry-date
      display: flex
      align-items: center
      color: #65b259

      &.expired
        color: #b2595a

      .icon
        margin-right: 10px

  tr:not(:last-child)
    td
      border-bottom: 1px solid rgba(58, 59, 61, 0.3)

.action-buttons
  position: absolute
  bottom: 15px

.action-button
  margin: 10px 0

.box-color
  height: 30px
  width: 100px
</style>
