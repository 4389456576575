<template>
  <div class="vs-list">

    <div class="headers" v-if="showHeaders">
      <div
        class="header"
        v-for="header in [...headers, '']"
        :key="header"
        >
        {{ header }}
      </div>
    </div>

    <vs-list-item
      v-for="item in items"
      :key="item.id"
      :title="item[title]"
      :item="item"

      @edit="$emit('edit', item)"
      @delete="$emit('delete', item)"
      :disableActions="disableActions"
    >
      <template v-slot:cells="{ row }" >
        <slot name="cells" :row="row" />
      </template>
    </vs-list-item>

  </div>
</template>

<script>
import VsListItem from './VsListItem.vue';

export default {
  props: {
    items: {
      type: Array,
    },
    showHeaders: {
      type: Boolean,
      default: false,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      default: () => ['Nazwa'],
    },
    title: {
      // which key to display as a title
      type: String,
      default: 'name',
    },
  },
  components: {
    VsListItem,
  },
};
</script>

<style lang="sass" scoped>
.vs-list
  margin-top: 15px
  display: table
  width: 100%

  .headers
    display: table-row
    font-weight: 500

    .header
      display: table-cell
      padding-bottom: 19px
      border-bottom: 1px solid rgba(black, 0.1)
</style>
