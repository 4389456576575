<template>
  <div class="tasks">

    <div class="listWrapper" :class="{ fullWidth: !detailsVisible() }">
      <tasks-list />
      <tasks-edit-modal />
      <tasks-create-modal />
    </div>

    <div class="detailsColumn" :class="{ visible: detailsVisible() }">
      <tasks-details />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import TasksList from '@/components/views/tasks/List.vue';
import TasksDetails from '@/components/views/tasks/Details.vue';
import TasksEditModal from '@/components/views/tasks/modals/TasksEditModal.vue';
import TasksCreateModal from '@/components/views/tasks/modals/TasksCreateModal.vue';

export default {
  computed: {
    ...mapGetters({
      user: 'auth/user',
      statuses: 'tasks/statuses/all',
      priorities: 'priorities/all',
    }),
  },
  methods: {
    detailsVisible() {
      return this.$route.name === 'tasks-details';
    },
  },
  components: {
    TasksList,
    TasksDetails,
    TasksEditModal,
    TasksCreateModal,
  },
};
</script>

<style lang="sass" scoped>

$details-width: 60%

.tasks
  display: flex

  .listWrapper
    width: calc(100% - #{$details-width})
    transition: 0.5s width ease

    &.fullWidth
      width: 100%

  .detailsColumn
    width: $details-width
    transition: 0.5s all ease-in-out
    position: absolute
    pointer-events: none
    opacity: 0
    padding-left: 20px
    height: 0
    overflow: hidden

    &.visible
      opacity: 1
      animation: relativeDelay .1s ease forwards
      pointer-events: auto
      height: auto

      @keyframes relativeDelay
        from
          position: absolute
        to
          position: relative

@media screen and (max-width: 1000px)
  .tasks
    display: block

    .listWrapper
      display: none

      &.fullWidth
        display: block

    .detailsColumn
      width: 100%
      position: relative
      display: block
      padding-left: 0
</style>
