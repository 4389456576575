<template>
  <div class="vs-list-item">

    <div class="vs-list-item-cell title">
      {{ title }}
    </div>

    <slot name="cells" :row="item" />

    <div
      v-if="!disableActions"
      class="vs-list-item-cell button-group"
    >
      <b-button
        variant="link"
        class="list-button"
        @click.prevent="$emit('edit')"
      >
        <feather-icon
          icon="Edit2Icon"
          size="16"
        />
        <div class="list-button-text">
          Edytuj
        </div>
      </b-button>

      <b-button
        variant="link"
        class="list-button"
        @click.prevent="$emit('delete')"
      >
        <feather-icon
          icon="Trash2Icon"
          size="16"
        />
        <div class="list-button-text">
          Usuń
        </div>
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue';

export default {
  props: {
    title: {
      type: String,
    },
    item: {
      type: Object,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BButton,
  },
};
</script>

<style lang="sass" scoped>
.vs-list-item
  display: table-row
  border: 1px solid rgba(0, 0, 0, 0.1)
  padding: 10px 20px
  border-radius: 5px
  margin: 5px 0
  position: relative
  width: 100%

  &-cell
    display: table-cell
    padding: 5px 0

  .title
    max-width: 60%

  &:hover .button-group
    opacity: 1

  .button-group
    display: flex
    align-items: center
    margin-left: auto
    opacity: 0
    transition: 0.2s opacity ease-in-out

    .list-button
      display: flex
      align-items: center

      &-text
        margin-left: 6px
</style>
