export default {
  items: [
    {
      id: 0,
      title: 'Przygotuj dokumentację powykonawczą - Stadion PGE',
      description: 'Amet eum quisquam dicta nostrum modi Velit ratione rerum exercitationem recusandae reprehenderit earum Itaque minima enim nobis eligendi nesciunt. Voluptate unde quia blanditiis omnis quaerat? Ipsam inventore sed dolor repudiandae!',
      priority: 1,
      status: {
        key: 4,
      },
      reporter: 'Jan Kowalski',
      assignee: 'Jerzy Nowak',
      date: '23.03.2021',
      attachments: [
        {
          id: 0,
          name: 'faktura_03_2021.pdf',
          userHasAccess: false,
          date: '24.04.2021',
        },
        {
          id: 1,
          name: 'Dokumenty od podwykonawcy',
          userHasAccess: true,
          date: '26.04.2021',
        },
      ],
      comments: [
        {
          id: 0,
          title: 'Pozyskiwanie surowców',
          content: 'Elit tempora perspiciatis adipisicing magni sit Accusamus dignissimos autem ducimus provident accusantium. Facere impedit culpa nam cum beatae quia Veritatis adipisci debitis mollitia saepe ratione iusto? Voluptas exercitationem et quisquam',
          author: 'Jerzy Nowak',
          date: '02.08.2021',
        },
        {
          id: 1,
          title: 'Kto pyta nie błądzi',
          content: 'Elit tempora perspiciatis adipisicing magni sit Accusamus dignissimos autem ducimus provident accusantium. Facere impedit culpa nam cum beatae quia Veritatis adipisci debitis mollitia saepe ratione iusto? Voluptas exercitationem et quisquam',
          author: 'Janusz Przecinek',
          date: '02.08.2021',
        },
      ],
      timeline: [
        {
          name: 'Utworzenie zadania',
          author: 'Jerzy Nowak',
          date: '02.08.2021',
        },
        {
          name: 'Modyfikacja terminu realizacji zadania',
          author: 'Jerzy Nowak',
          date: '03.08.2021',
        },
        {
          name: 'Zadanie w toku',
          author: 'Jan Kowalski',
          date: '05.08.2021',
        },
      ],
    },
    {
      id: 1,
      title: 'Kup mi kawę w Starbucks',
      description: 'Amet eum quisquam dicta nostrum modi Velit ratione rerum exercitationem recusandae reprehenderit earum Itaque minima enim nobis eligendi nesciunt. Voluptate unde quia blanditiis omnis quaerat? Ipsam inventore sed dolor repudiandae!',
      priority: 2,
      status: {
        key: 2,
      },
      reporter: 'Andrzej Paleta',
      assignee: 'Martyna Butla',
      date: '23.03.2021',
      attachments: [
        {
          id: 0,
          name: 'Instrukcja kupowania kawy',
          userHasAccess: true,
          date: '24.03.2021',
        },
      ],
      comments: [
        {
          id: 1,
          title: 'Moja ulubiona kawa',
          content: 'Elit tempora perspiciatis adipisicing magni sit Accusamus dignissimos autem ducimus provident accusantium. Facere impedit culpa nam cum beatae quia Veritatis adipisci debitis mollitia saepe ratione iusto? Voluptas exercitationem et quisquam',
          author: 'Andrzej Paleta',
          date: '02.08.2021',
        },
      ],
      timeline: [
        {
          name: 'Utworzenie zadania',
          author: 'Andrzej Paleta',
          date: '02.08.2021',
        },
        {
          name: 'Zadanie wykonane',
          author: 'Martyna Butla',
          date: '02.08.2021',
        },
      ],
    },
    {
      id: 2,
      title: 'Kup mi kawę w Starbucks',
      description: 'Amet eum quisquam dicta nostrum modi Velit ratione rerum exercitationem recusandae reprehenderit earum Itaque minima enim nobis eligendi nesciunt. Voluptate unde quia blanditiis omnis quaerat? Ipsam inventore sed dolor repudiandae!',
      priority: 3,
      status: {
        key: 3,
      },
      reporter: 'Andrzej Paleta',
      assignee: 'Martyna Butla',
      date: '23.03.2021',
      attachments: [
        {
          id: 0,
          name: 'Instrukcja kupowania kawy',
          userHasAccess: true,
          date: '24.03.2021',
        },
      ],
      comments: [
        {
          id: 1,
          title: 'Moja ulubiona kawa',
          content: 'Elit tempora perspiciatis adipisicing magni sit Accusamus dignissimos autem ducimus provident accusantium. Facere impedit culpa nam cum beatae quia Veritatis adipisci debitis mollitia saepe ratione iusto? Voluptas exercitationem et quisquam',
          author: 'Andrzej Paleta',
          date: '02.08.2021',
        },
      ],
      timeline: [
        {
          name: 'Utworzenie zadania',
          author: 'Andrzej Paleta',
          date: '02.08.2021',
        },
        {
          name: 'Zadanie wykonane',
          author: 'Martyna Butla',
          date: '02.08.2021',
        },
      ],
    },
    {
      id: 3,
      title: 'Kup mi kawę w Starbucks 2',
      description: 'Amet eum quisquam dicta nostrum modi Velit ratione rerum exercitationem recusandae reprehenderit earum Itaque minima enim nobis eligendi nesciunt. Voluptate unde quia blanditiis omnis quaerat? Ipsam inventore sed dolor repudiandae!',
      priority: 4,
      status: {
        key: 5,
      },
      reporter: 'Andrzej Paleta',
      assignee: 'Martyna Butla',
      date: '23.03.2021',
      attachments: [
        {
          id: 0,
          name: 'Instrukcja kupowania kawy',
          userHasAccess: true,
          date: '24.03.2021',
        },
      ],
      comments: [
        {
          id: 1,
          title: 'Moja ulubiona kawa',
          content: 'Elit tempora perspiciatis adipisicing magni sit Accusamus dignissimos autem ducimus provident accusantium. Facere impedit culpa nam cum beatae quia Veritatis adipisci debitis mollitia saepe ratione iusto? Voluptas exercitationem et quisquam',
          author: 'Andrzej Paleta',
          date: '02.08.2021',
        },
      ],
      timeline: [
        {
          name: 'Utworzenie zadania',
          author: 'Andrzej Paleta',
          date: '02.08.2021',
        },
        {
          name: 'Zadanie wykonane',
          author: 'Martyna Butla',
          date: '02.08.2021',
        },
      ],
    },
    {
      id: 4,
      title: 'Kup mi kawę w Starbucks',
      description: 'Amet eum quisquam dicta nostrum modi Velit ratione rerum exercitationem recusandae reprehenderit earum Itaque minima enim nobis eligendi nesciunt. Voluptate unde quia blanditiis omnis quaerat? Ipsam inventore sed dolor repudiandae!',
      priority: 2,
      status: {
        key: 1,
      },
      reporter: 'Andrzej Paleta',
      assignee: 'Martyna Butla',
      date: '23.03.2021',
      attachments: [
        {
          id: 0,
          name: 'Instrukcja kupowania kawy',
          userHasAccess: true,
          date: '24.03.2021',
        },
      ],
      comments: [
        {
          id: 1,
          title: 'Moja ulubiona kawa',
          content: 'Elit tempora perspiciatis adipisicing magni sit Accusamus dignissimos autem ducimus provident accusantium. Facere impedit culpa nam cum beatae quia Veritatis adipisci debitis mollitia saepe ratione iusto? Voluptas exercitationem et quisquam',
          author: 'Andrzej Paleta',
          date: '02.08.2021',
        },
      ],
      timeline: [
        {
          name: 'Utworzenie zadania',
          author: 'Andrzej Paleta',
          date: '02.08.2021',
        },
        {
          name: 'Zadanie wykonane',
          author: 'Martyna Butla',
          date: '02.08.2021',
        },
      ],
    },
  ],
};
