import ApiClient from '@/services/api';
import reports from './reports';
import brigades from './brigades';
import picker from './picker';
import fueling from './fueling';

const urls = {
  get: 'api/investment/investment',
};

export default {
  namespaced: true,
  state: {
    investments: [],
  },

  getters: {
  },

  mutations: {
    SET_INVESTMENTS(state, investments) {
      state.investments = investments;
    },

    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
  },

  actions: {
    /**
     * Fetch investments list
     * @action fetch=investments
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if vehicles exist
     * @returns {Promise}
     */
    fetch({ state, commit }, params, force = true) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(urls.get, {
        ...params,
      });

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_INVESTMENTS', res.data.data.investments);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },
  },
  modules: {
    reports, brigades, picker, fueling,
  },
};
