var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-img', {
    attrs: {
      "src": _vm.$store.getters['themes/branding'].logoFull,
      "alt": _vm.$store.getters['themes/data'].appName
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }