import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '@/store';

import routes from './routes';

Vue.use(VueRouter);

const authCheck = function beforeRouteAuthCheck(to, _from, next) {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (!requiresAuth) return next();
  if (!store.getters['auth/authenticated']) {
    return next({
      name: 'login',
      params: { requested: to.name },
    });
  }

  if (!to?.meta?.requiresAnyPermission) return next();
  const hasPermission = store.getters['auth/userHasAnyPermission'](to.meta.requiresAnyPermission);
  if (hasPermission) return next();

  return next({ name: 'unauthorized', params: { permission: to.meta.requiresAnyPermission } });
};

const moduleEnabledCheck = async function beforeRouteModuleCheck(to, _from, next) {
  if (to.matched.some((record) => record.meta.moduleRoute)) {
    const module = to.meta.moduleRoute;

    await store.dispatch('app/fetchModulesStatus');

    if (store.getters['app/isModuleEnabled'](module)) {
      next();
    } else {
      next({ name: 'error-404' });
    }
  } else {
    next();
  }
};

const splashScreen = function afterRouteSplashLoad() {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
};

const replaceTitle = function replaceTitle(to) {
  const { title, titlePrefix } = store.getters['themes/data'];

  const getRouteTitle = () => {
    if (!to.meta.pageTitle || !titlePrefix) return title;
    return titlePrefix.replace('{route}', to.meta.pageTitle);
  };

  Vue.nextTick(() => {
    document.title = getRouteTitle();
  });
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach(moduleEnabledCheck);
router.beforeEach(authCheck);
router.afterEach(splashScreen);
router.afterEach(replaceTitle);

export default router;
