import Vue from 'vue';
import Vuex from 'vuex';

import ApiClient from '@/services/api';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    units: [],
  },

  getters: {
    all: (state) => state.units,
    // eslint-disable-next-line arrow-body-style
    byId: (state) => (searchedId) => state.units.filter((unit) => {
      return unit.id === searchedId;
    })[0] || null,
  },

  mutations: {
    SET_UNITS(state, payload) {
      state.units = payload;
    },
    ADD_UNIT(state, payload) {
      state.units = [...state.units, ...payload];
    },
    SET_PROMISE(state, payload) {
      state.fetchPromise = payload;
    },
  },

  actions: {
    fetch({ commit, state }, force = false) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get('api/core/unit/get-list');

      promise.then((res) => {
        if (res.status !== 200) return null;
        return commit('SET_UNITS', res.data.data);
      });

      commit('SET_PROMISE', promise);

      return promise;
    },
  },
};
