export default {
  pageTitle: 'Zlecenia',
  list: {
    pageTitle: 'Lista zleceń',
    field: {
      order_number: 'Nr zlecenia',
      date: 'Data',
      title: 'Nazwa zlecenia',
      provider: 'Dostawca',
      investor: 'Inwestor',
      investment: 'Inwestycja',
      status: 'Status',
      subject: 'Przedmiot zlecenia',
      price: 'Wartość',
      edit: 'Edycja',
      region: 'Region',
      priority: 'Priorytet',
      totalPrice: 'Suma zlecenia',
    },
    filter: {
      region: 'Region',
      provider: 'Dostawca',
      investor: 'Inwestor',
      investment: 'Inwestycja',
      status: 'Status',
    },
  },
  details: {
    pageTitle: 'Szczegóły zlecenia',
    status: {
      accepted: 'Zaakceptowane',
      waiting: 'Oczekuje na akceptację',
      settled: 'Rozliczone',
    },
    field: {
      offerNumber: 'nr oferty',
      status: 'status',
      priority: 'priorytet',
      author: 'utworzył',
      accepting: 'akceptujący',
      assigned: 'przypisany',
      date: {
        created: 'data zlecenia',
        accepted: 'data akceptacji',
        settled: 'data rozliczenia',
      },
      provider: 'dostawca',
      investor: 'inwestor',
      investment: 'inwestycja',
      region: 'region',

      description: 'Uwagi do zlecenia',
      paymentTerms: 'Warunki zlecenia',
    },
    title: {
      number: 'Zlecenie nr {number}',
    },
    message: {
      noDescription: 'Brak uwag do zlecenia',
      noAttachmentAccess: 'Do tego pliku dostęp mają jedynie: przypisany do zlecenia oraz twórca zlecenia',
      loading: 'Wczytywanie szczegółów zlecenia',
    },
    button: {
      accept: 'Zatwierdź',
      decline: 'Odrzuć',
      return: 'Do korekty',
      settle: 'Rozlicz',
      back: 'Powrót do listy zleceń',
    },
    modal: {
      delete: {
        title: 'Potwierdzanie usuwania zlecenia',
        text: 'Czy na pewno chcesz usunąć zlecenie {order}?',
      },
    },
  },
  edit: {
    title: 'Edycja zlecenia',
    button: {
      cancel: 'Anuluj',
      save: 'Zapisz zmiany',
    },
    field: {
      title: 'Tytuł',
      priority: 'Priorytet',
      number: 'Numer zlecenia',
      offer_number: 'Numer oferty',
      accepting: 'Osoba akceptująca',
      assigned: 'Osoba przypisana',
      contact_person: 'Osoba kontaktowa',
      date: {
        ordered_at: 'Data zlecenia',
        realization_at: 'Termin realizacji',
        acceptance_at: 'Data akceptacji',
        settlement_at: 'Data rozliczenia',
      },
      providerBranch: 'Dostawca - oddział',
      providerContactPerson: 'Dostawca - osoba kontaktowa',
      provider: 'Dostawca',
      investor: 'Inwestor',
      investment: 'Inwestycja',
      region: 'Region',
      subject: {
        name: 'Przedmiot zlecenia',
        quantity: 'Ilość',
        price: 'Cena jednostkowa',
      },
      description: 'Uwagi do zlecenia',
      payment_terms: 'Warunki płatności',
      transport: 'Transport',
      delivery_place: 'Miejsce dostawy',
    },
  },
  add: {
    title: 'Dodaj zlecenie',
    button: {
      cancel: 'Anuluj',
      save: 'Zapisz zmiany',
    },
  },
  error: {
    orderNotFound: 'Nie znaleziono takiego zlecenia.',
  },
  timeline: {
    event: {
      created: {
        default: 'Utworzono nowe zlecenie',
        comment: 'Dodano nowy komentarz do zlecenia',
      },
      updated: {
        default: 'Zaktualizowano treść zlecenia',
        status_change: 'Zmieniono status zlecenia na {status}',
        assignee_change: 'Zmieniono przypisanego użytkownika z {oldUser} na {newUser}',
        deadline_change: 'Zaktualizowano termin wykonania zlecenia',
      },
    },
  },
};
