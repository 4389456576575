var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "employee-form"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.cadre.skills.field.name'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.name,
      "state": _vm.errors.name === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('settings.cadre.skills.field.name')
    },
    model: {
      value: _vm.objectModel.name,
      callback: function ($$v) {
        _vm.$set(_vm.objectModel, "name", $$v);
      },
      expression: "objectModel.name"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }