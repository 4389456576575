export default {
  pageTitle: 'Transport',
  list: {
    pageTitle: 'Lista zleceń transportu',
    button: {
      addTransport: 'Dodaj zlecenie transportu',
    },
  },
  modal: {
    delete: {
      title: 'Potwierdź usunięcie transportu',
      text: 'Czy na pewno chcesz usunąć transport {name}?',
    },
  },
  details: {
    pageTitle: 'Szczegóły zlecenia transportu',
    field: {
      status: 'Status',
      transportDate: 'Data transportu',
      deliveryDate: 'Planowana data odbioru',
      author: 'Zlecający',
      dispatcher: 'Wydający',
      receiver: 'Odbierający',
      company: 'Przewoźnik',
      type: 'Rodzaj transportu',
      branch: 'Region',

      transportIn: 'dzisiaj | jutro | za {n} dni',
    },
  },
  field: {
    name: 'Nazwa',
    date: 'Data transportu',
    user: 'Użytkownik',
    status: 'Status',
    dispatcher: 'Osoba wydająca',
    receiver: 'Osoba odbierająca',
  },
};
