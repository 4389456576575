var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    attrs: {
      "vertical": "",
      "content-class": "col-12 col-md-9 mt-1 mt-md-0",
      "pills": "",
      "nav-wrapper-class": "col-md-3 col-12",
      "nav-class": "nav-left"
    },
    on: {
      "activate-tab": function (to, _from, bvEvent) {
        return _vm.changeTab(to, bvEvent);
      }
    }
  }, [_c('b-tab', {
    attrs: {
      "active": _vm.isActiveTab('core')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UserIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Aplikacja")])];
      },
      proxy: true
    }])
  }, [_vm.isActiveTab('core') ? _c('core-content') : _vm._e()], 1), _c('b-tab', {
    attrs: {
      "active": _vm.isActiveTab('tasks')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CheckSquareIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Zadania")])];
      },
      proxy: true
    }])
  }, [_vm.isActiveTab('tasks') ? _c('core-content') : _vm._e()], 1), _c('b-tab', {
    attrs: {
      "active": _vm.isActiveTab('orders')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "ArrowRightCircleIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Zlecenia")])];
      },
      proxy: true
    }])
  }, [_vm.isActiveTab('orders') ? _c('settings-tab-orders') : _vm._e()], 1), _c('b-tab', {
    attrs: {
      "active": _vm.isActiveTab('cadre')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UsersIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Kadra")])];
      },
      proxy: true
    }])
  }, [_vm.isActiveTab('cadre') ? _c('cadre-content') : _vm._e()], 1), _c('b-tab', {
    attrs: {
      "active": _vm.isActiveTab('crm')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "PackageIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("CRM")])];
      },
      proxy: true
    }])
  }, [_vm.isActiveTab('crm') ? _c('settings-tab-crm') : _vm._e()], 1), _c('b-tab', {
    attrs: {
      "active": _vm.isActiveTab('base')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TruckIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Baza")])];
      },
      proxy: true
    }])
  }, [_vm.isActiveTab('base') ? _c('settings-tab-base') : _vm._e()], 1), _c('b-tab', {
    attrs: {
      "active": _vm.isActiveTab('investment')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CompassIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Inwestycje")])];
      },
      proxy: true
    }])
  }, [_vm.isActiveTab('investment') ? _c('settings-tab-investment') : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }