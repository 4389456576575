import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useBaseApi from '@/hooks/base/useBaseApi';

const { fetchProducers } = useBaseApi();

export default defineStore('baseProducer', () => {
  const {
    state,
    fetch,
    fetched,
    getById,
  } = useRemoteResource('base.producer', fetchProducers);

  return {
    state,
    fetch,
    fetched,
    getById,
  };
});
