<template>
  <div class="priority-label">
    <div class="icon" :style="`color: ${priority.color}`">
      <feather-icon v-if="priority.icon" :icon="priority.icon" size="18"/>
    </div>
    <div class="details" :style="`color: ${priority.color}`">
      {{ priority.name }}
    </div>
  </div>
</template>

<script>
import useCorePriorityStore from '@/stores/module/core/useCorePriorityStore';
import { computed } from 'vue';

export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
    item: {
      type: Object,
      default: () => ({
        default: true,
        icon: null,
        name: 'priority',
        color: 'lightgray',
      }),
    },
  },
  setup(props) {
    const priorityStore = useCorePriorityStore();
    const priority = computed(() => {
      if (!props.id) return props.item;
      return priorityStore.getById(props.id) ?? props.item;
    });

    return { priority };
  },
};
</script>

<style lang="sass" scoped>
.priority-label
  display: flex
  align-items: center
  .icon
    display: flex
    align-items: flex-start
  .details
    margin-left: 6px
</style>
