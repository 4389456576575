<template>
  <div>

    <authorization-guard
      silent
      permission="cadre.skill.store"
    >
      <div>
        <vs-button
          v-b-modal.modal-cadre-skill-add
          vs-variant="primary"
        >
          + {{ $t('settings.cadre.skills.button.add') }}
        </vs-button>
      </div>
    </authorization-guard>

    <authorization-guard
      permission="cadre.skill.index"
    >
      <table>
        <thead>
          <th>Nazwa</th>
          <th>Edycja</th>
        </thead>
        <tbody>
          <tr v-for="skill in skills" :key="skill.id">
            <td>{{ skill.name }}</td>
            <td class="edit">
              <edit-button :items="editOptions" :target="skill"/>
            </td>
          </tr>
        </tbody>
      </table>
    </authorization-guard>

    <modal-add/>
    <modal-edit/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import ModalAdd from '@/components/views/settings/content-tabs/cadre/modals/skill/Add.vue';
import ModalEdit from '@/components/views/settings/content-tabs/cadre/modals/skill/Edit.vue';
import EditButton from '@/components/ui/vs-table/TableActionButton.vue';
import { deleteModal } from '@/libs/modals';
import useModal from '@/hooks/useModal';
import {
  DeleteSkillSuccess,
  ServerError,
  SkillObjectNotFound,
} from '@feedback/module/cadre';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

export default {
  name: 'SkillsManagement',
  components: {
    VsButton,
    ModalAdd,
    ModalEdit,
    EditButton,
    AuthorizationGuard,
  },
  setup(_props, context) {
    const { openModal } = useModal();

    const openEditModal = (skill) => {
      openModal('modal-cadre-skill-edit', context, {
        ...skill,
      });
    };

    return { openEditModal };
  },
  computed: {
    ...mapGetters({
      skills: 'cadre/skills/all',
      current: 'cadre/skills/byId',
    }),
    skillEditModel: {
      get() {
        if (!this.currentSkill.provider) {
          return {
            ...this.currentSkill,
          };
        }

        return {
          ...this.currentSkill,
        };
      },
    },
  },
  methods: {
    async deleteSkill(skill = this.skill) {
      const confirmation = await deleteModal({
        title: this.$i18n.t('settings.cadre.skills.modal.delete.title'),
        text: this.$i18n.t('settings.cadre.skills.modal.delete.text', { name: skill.name }),
      });
      if (!confirmation) return;

      this.$store.dispatch('cadre/skills/delete', {
        id: skill.id,
      })
        .then(() => {
          this.$showFeedback(DeleteSkillSuccess);
          this.loading = false;
          RemoteResourceManager.getInstance().notify('cadre.skill');
        })
        .catch((err) => {
          let Feedback = ServerError;
          if (err?.response?.data?.errors) {
            Feedback = SkillObjectNotFound;
          }

          this.$showFeedback(Feedback);
          this.loading = false;
          this.errors = err?.response?.data?.errors;
        });
    },
    editSkill(skill) {
      this.openEditModal({ ...skill });
    },
    currentSkill(id) {
      return this.current(id);
    },
  },
  data() {
    return {
      loading: false,
      skill: {},
      editOptions: [
        {
          title: this.$t('global.button.edit'),
          icon: 'CheckCircle',
          callback: this.editSkill,
        },
        {
          title: this.$t('global.button.delete'),
          icon: 'Trash2',
          callback: this.deleteSkill,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch('cadre/skills/fetch');
  },
};
</script>

<style lang="sass" scoped>
table
  width: 100%
  margin-top: 25px

  td
    padding: 10px 0

    &.expiry-date
      display: flex
      align-items: center
      color: #65b259

      &.expired
        color: #b2595a

      .icon
        margin-right: 10px

  tr:not(:last-child)
    td
      border-bottom: 1px solid rgba(58, 59, 61, 0.3)

  thead
    border-bottom: 2px solid rgba(1, 4, 9, 0.3)

.action-buttons
  position: absolute
  bottom: 15px

.action-button
  margin: 10px 0

.box-color
  height: 30px
  width: 100px
</style>
