<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useInvestmentsInvestmentStore from '@/stores/module/investments/useInvestmentsInvestmentStore';

export default Vue.component('CrmProviderPicker', {
  emits,
  setup(_, ctx) {
    const { pickerList } = useInvestmentsInvestmentStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz inwestycję z listy',
      reduce: (option) => option.id,
      options: pickerList.state,
      ...ctx.attrs,
    }, {
      option: ({ option }) => option.name,
      'selected-option': ({ option }) => option.name,
    }));
  },
  components: { VsPickerVue },
});
</script>
