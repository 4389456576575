<template>
  <div class="settings-tab-base-technologies">

    <div class="buttons">
      <div class="group">
        <vs-button
          vs-variant="primary"
          @click="modalAddVisible = true"
        >
          + {{ $t('base.technology.button.add') }}
        </vs-button>
      </div>
    </div>

    <vs-list showHeaders
      :items="technologies"
      @edit="editTechnology"
      @delete="deleteTechnology"
      :headers="['Nazwa', 'Kolor']"
    >
      <template v-slot:cells="{ row }" >
        <div class="vs-list-item-cell">
          <div class="color-circle" :style="{ '--color': row.color }" />
        </div>
      </template>
    </vs-list>

    <settings-tab-base-technology-modal-add />
    <settings-tab-base-technology-modal-edit />
  </div>
</template>

<script>
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import VsList from '@/components/ui/vs-list/VsList.vue';
import { mapGetters } from 'vuex';
import useBaseTechnology from '@/hooks/base/assignables/useBaseTechnology';
import { provide } from 'vue';
import SettingsTabBaseTechnologyModalAdd from './modals/technology/SettingsTabBaseTechnologyModalAdd.vue';
import SettingsTabBaseTechnologyModalEdit from './modals/technology/SettingsTabBaseTechnologyModalEdit.vue';

export default {
  name: 'SettingsTabBaseCategories',
  setup() {
    const technologyInstance = useBaseTechnology();
    const {
      fetchTechnologies, technologies, modalAddVisible,
      modalEditVisible, form, deleteTechnology,
    } = technologyInstance;

    const editTechnology = (technology) => {
      form.value = { ...technology };
      modalEditVisible.value = true;
    };

    provide('technologyInstance', technologyInstance);

    fetchTechnologies();

    return {
      technologies,
      modalAddVisible,
      modalEditVisible,
      editTechnology,
      deleteTechnology,
    };
  },
  computed: {
    ...mapGetters({
      units: 'units/all',
      unitById: 'units/byId',
    }),
  },
  created() {
    if (this.units.length === 0) this.$store.dispatch('units/fetch');
  },
  components: {
    VsButton,
    VsList,
    SettingsTabBaseTechnologyModalAdd,
    SettingsTabBaseTechnologyModalEdit,
  },
};
</script>

<style lang="sass" scoped>
.color-circle
  height: 16px
  width: 50px
  background: var(--color)
  border-radius: 4px

.resource-type
  display: inline-block

  &:not(:first-child):before
    content: ','
</style>
