/**
 * Store Module for managing trainings inside Cadre
 * trainings are fictional users
 */

import ApiClient from '@/services/api';

const urls = {
  get: 'api/cadre/safety-training',
  getById: 'api/cadre/employee/:id/edit',
  create: 'api/cadre/employee',
  delete: 'api/cadre/employee/:id',
  update: 'api/cadre/employee/:id',
};

export default {
  namespaced: true,

  state: {
    /** Examinations list */
    trainings: [],

    fetchParams: {
      columnFilters: {
        branches_id: null,
      },
    },

    /** Current promise returned by fetch action */
    fetchPromise: null,
  },

  getters: {
    all: (state) => state.trainings,
    byId: (state) => (id) => state.trainings.find((e) => e.id === id),
  },

  mutations: {
    SET_TRAININGS(state, trainings) {
      state.trainings = trainings;
    },
    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
    UPDATE_TRAINING(state, newTraining) {
      const newTrainings = state.trainings
        .map((training) => {
          if (training.id !== newTraining.id) return training;
          return newTraining;
        });

      if (newTraining.id === state.current.id) {
        state.current = newTraining;
      }

      state.trainings = newTrainings;
    },
    REMOVE_TRAINING(state, training) {
      const newTrainings = state.trainings.filter((current) => current.id !== training.id);
      state.trainings = [...newTrainings];
    },
  },

  actions: {
    /**
     * Fetch trainings list
     * @action fetch=trainings
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if trainings exist
     * @returns {Promise}
     */
    fetch({ state, commit }, params, force = true) {
      if (state.trainings.length && !force) return state.trainings;
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(urls.get, params);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_TRAININGS', res.data.data.safetyTrainings);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },

    /**
     * Fetch training by id
     * @action fetch=trainings
     * @param {ActionContext} [vuexContext]
     * @param {Number} employeeId id of the training
     * @param {Boolean} force Forces the fetch even if trainings exist
     * @returns {Promise}
     */
    fetchOne({ state, commit }, { employeeId = state.current?.id, force = false } = {}) {
      const id = Number(employeeId);

      if (state.current?.id === id && !force) return state.current;
      if (state.current?.id !== id) commit('UNSET_CURRENT');

      const url = urls.getById.replace(':id', id);
      const promise = ApiClient.get(url);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_CURRENT', res.data.data);
      });

      return promise;
    },

    /**
     * Create new training
     * @action create=trainings
     * @param {ActionContext} [vuexContext]
     * @param {Object} training - Examination object
     * @returns {Promise}
     */
    create({ commit }, training) {
      const url = urls.create;

      // FIXME change it later
      const changeMeExamination = {
        ...training,
      };

      const promise = ApiClient.post(url, changeMeExamination);

      promise.then(({ data }) => {
        commit('ADD_TRAINING', data.data);
      });
      return promise;
    },

    /**
     * Remove training
     * @action delete=trainings
     * @param {ActionContext} [vuexContext]
     * @param {Object} training - Examination object
     * @returns {Promise}
     */
    delete({ commit }, training) {
      const url = urls.delete.replace(':id', training.id);
      const promise = ApiClient.delete(url);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('REMOVE_TRAINING', training);
      });
      return promise;
    },

    /**
     * Update training
     * @action update=trainings
     * @param {ActionContext} [vuexContext]
     * @param {Object} training - Examination object
     * @returns {Promise}
     */
    update({ commit }, training) {
      const url = urls.update.replace(':id', training.id);

      // FIXME change it later
      const changeMeExamination = {
        ...training,
      };

      const promise = ApiClient.put(url, changeMeExamination);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('UPDATE_TRAINING', res?.data?.data);
      });
      return promise;
    },
  },
};
