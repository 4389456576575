import { reactive, toRefs } from 'vue';

const initializeModal = (overwrites = {}) => ({
  visible: false,
  isLoading: false,
  data: {},
  ...overwrites,
});

const modals = reactive({
  investmentCreateModal: false,
  investmentAssignSubjectModal: false,

  // TODO structure below should be applied to each modal
  investmentEditSubjectModal: initializeModal(),

  investmentCreateDemandModal: initializeModal(),
  investmentEditDemandModal: initializeModal(),

  investmentCreateOfferModal: initializeModal(),
  investmentEditOfferModal: initializeModal(),

  investmentAssignResourceModal: initializeModal(),
  investmentEditResourceModal: initializeModal(),

  investmentAssignEmployeeModal: initializeModal(),
  investmentEditEmployeeModal: initializeModal(),

  investmentCreateBrigadeModal: initializeModal(),
  investmentEditBrigadeModal: initializeModal(),

  investmentAddReportModal: initializeModal(),
  investmentEditReportModal: initializeModal(),

  addMaterialModal: initializeModal(),
  editMaterialModal: initializeModal(),

  addReinforcementModal: initializeModal(),
  editReinforcementModal: initializeModal(),

  addWorkTypeModal: initializeModal(),
  editWorkTypeModal: initializeModal(),

  addWorkGroupModal: initializeModal(),
  editWorkGroupModal: initializeModal(),
});

const resources = reactive({
  materialResource: [],
  reinforcements: [],
  workGroups: [],
  workTypes: [],
  statuses: [
    { id: 1, name: 'Planowana', color: '#2a63ea' },
    { id: 2, name: 'W toku', color: '#ffbf27' },
    { id: 3, name: 'Zakończona', color: '#33cd85' },
  ],
});

export default function useInvestmentsStore() {
  return {
    ...toRefs(modals),
    ...toRefs(resources),
  };
}
