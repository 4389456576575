<template>
  <div class="settings-tab-crm-industry">

    <div class="buttons">
      <div class="group">
        <vs-button
          vs-variant="primary"
          @click="openAddModal"
        >
          + {{ $t('base.category.button.add') }}
        </vs-button>
      </div>
    </div>

    <vs-list showHeaders
      :items="categories"
      @edit="openEditModal"
      @delete="deleteCategory"
      :headers="['Nazwa', 'Raporty', 'Jednostka', 'Typ']"
    >
      <template v-slot:cells="{ row }" >
        <div class="vs-list-item-cell">
          <examination-badge
            hideText
            :expired="!row.be_reported"
          />
        </div>

        <div class="vs-list-item-cell">
          <template v-if="unitById(row.unit_id)">
            {{ unitById(row.unit_id).name }} ({{ unitById(row.unit_id).code}})
          </template>
          <template v-else>-</template>
        </div>

        <div class="vs-list-item-cell">
          <div
            class="resource-type"
          >
            {{ $t(`base.global.resourceType.${row.type.slug}`)}}
          </div>
        </div>
      </template>
    </vs-list>

    <settings-tab-base-category-modal-add
      @ok="fetch"
    />
    <settings-tab-base-category-modal-edit
      @ok="fetch"
    />
  </div>
</template>

<script>
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useCategory from '@/hooks/settings/base/useCategory';
import { provide } from 'vue';
import VsList from '@/components/ui/vs-list/VsList.vue';
import SettingsTabBaseCategoryModalAdd
  from '@/components/views/settings/content-tabs/base/modals/category/SettingsTabBaseCategoryModalAdd.vue';
import SettingsTabBaseCategoryModalEdit
  from '@/components/views/settings/content-tabs/base/modals/category/SettingsTabBaseCategoryModalEdit.vue';
import { mapGetters } from 'vuex';
import ExaminationBadge from '@/components/views/cadre/ui/ExaminationBadge.vue';

export default {
  name: 'SettingsTabBaseCategories',
  setup() {
    const categoriesInstance = useCategory();
    const {
      categories, fetch, openAddModal, openEditModal, deleteCategory,
    } = categoriesInstance;

    provide('categoriesInstance', categoriesInstance);

    fetch();

    return {
      fetch,
      categories,
      openAddModal,
      openEditModal,
      deleteCategory,
    };
  },
  computed: {
    ...mapGetters({
      units: 'units/all',
      unitById: 'units/byId',
    }),
  },
  created() {
    if (this.units.length === 0) this.$store.dispatch('units/fetch');
  },
  components: {
    SettingsTabBaseCategoryModalAdd,
    SettingsTabBaseCategoryModalEdit,
    VsButton,
    VsList,
    ExaminationBadge,
  },
};
</script>

<style lang="sass" scoped>
.resource-type
  display: inline-block

  &:not(:first-child):before
    content: ','
</style>
