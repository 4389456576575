var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [this.showLoader ? _c('vs-loader', {
    attrs: {
      "text": _vm.$t('tasks.details.message.loading')
    }
  }) : !_vm.task ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('tasks.error.taskNotFound')) + " "), _c('br'), _c('br'), _c('router-link', {
    staticClass: "close-details",
    attrs: {
      "to": {
        name: 'tasks'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('tasks.details.button.back')) + " ")])], 1) : _vm.task ? _c('div', {
    staticClass: "task-details"
  }, [_c('router-link', {
    staticClass: "back-link",
    attrs: {
      "to": {
        name: 'tasks'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('tasks.details.button.back')) + " ")]), _c('h3', {
    staticClass: "title"
  }, [_c('task-label', {
    attrs: {
      "id": _vm.task.id,
      "title": _vm.task.title
    }
  }), _c('router-link', {
    staticClass: "close-details",
    attrs: {
      "to": {
        name: 'tasks'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "XIcon",
      "size": "24"
    }
  })], 1)], 1), _c('div', {
    staticClass: "buttons"
  }, [_vm.task.created_by_id === _vm.user.id ? _c('div', {
    staticClass: "group"
  }, [_c('widget-card-controls', {
    attrs: {
      "editPermission": "tasks.task.update",
      "deletePermission": "tasks.task.destroy"
    },
    on: {
      "edit": _vm.openEditModal,
      "delete": _vm.deleteTask
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "group"
  }, [_c('status-dropdown', {
    directives: [{
      name: "can",
      rawName: "v-can:change_status",
      value: 'tasks.task',
      expression: "'tasks.task'",
      arg: "change_status"
    }],
    attrs: {
      "currentStatusId": _vm.task.status_id
    },
    on: {
      "pick": _vm.changeStatus
    }
  })], 1)]), _c('div', {
    staticClass: "details"
  }, [_c('table', [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('tasks.details.field.priority')))]), _c('td', [_c('priority-label', {
    attrs: {
      "id": _vm.task.priority_id
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('tasks.details.field.status')))]), _c('td', [_c('div', {
    staticClass: "status"
  }, [_vm.task.status_id ? _c('status-badge', {
    attrs: {
      "status_id": _vm.task.status_id
    }
  }) : _vm._e()], 1)])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('tasks.details.field.assignedTo')))]), _c('td', [_c('user-label', {
    attrs: {
      "withAvatar": "",
      "id": _vm.task.assigned_id
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('tasks.details.field.reporter')))]), _c('td', [_c('user-label', {
    attrs: {
      "withAvatar": "",
      "id": _vm.task.created_by_id
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('tasks.details.field.deadline')))]), _c('td', [_vm._v(_vm._s(_vm.formatDate.taskDeadline(_vm.task.termination_date)))])])])]), _c('header-section', {
    attrs: {
      "title": _vm.$t('tasks.details.field.description')
    }
  }, [_vm.task.description ? _c('p', {
    staticClass: "description",
    domProps: {
      "innerHTML": _vm._s(_vm.task.description)
    }
  }) : _c('span', {
    staticClass: "noDescriptionInfo"
  }, [_vm._v(_vm._s(_vm.$t('tasks.edit.noDescription')))])]), _c('hr'), _c('attachments', {
    attrs: {
      "items": _vm.task.files,
      "uploading": _vm.uploading,
      "noAccessText": _vm.$t('tasks.details.message.noAttachmentAccess'),
      "canUpload": _vm.$store.getters['auth/userHasPermission']('upload', 'tasks.media'),
      "canDelete": _vm.$store.getters['auth/userHasPermission']('delete', 'tasks.media')
    },
    on: {
      "add": _vm.createAttachment,
      "remove": _vm.removeAttachment
    }
  }), _c('hr'), _c('comments', {
    attrs: {
      "items": _vm.comments,
      "canAdd": _vm.$store.getters['auth/userHasPermission']('store', 'tasks.comment'),
      "canDelete": _vm.$store.getters['auth/userHasPermission']('destroy', 'tasks.comment'),
      "canUpdate": _vm.$store.getters['auth/userHasPermission']('update', 'tasks.comment')
    },
    on: {
      "add": _vm.createComment,
      "edit": _vm.editComment,
      "remove": _vm.removeComment
    }
  }), _c('hr'), _vm.timeline ? _c('timeline', {
    attrs: {
      "items": _vm.timeline
    }
  }) : _vm._e()], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }