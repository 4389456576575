<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <router-link class="settings-label" :to="{ name: 'settings' }">
        <feather-icon icon="SettingsIcon" size="18" />
        <div class="text">
          {{ $t('global.button.settings') }}
        </div>
      </router-link>
      <powertools v-if="developmentMode" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <notification-dropdown />
      <notification-emitter />
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ fullname }}
            </p>
            <span class="user-status">{{ user.email }}</span>
          </div>

          <avatar displayUserIcon
            :user="user"
          />
        </template>

        <b-dropdown-item @click="openProfileSettings" link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="SlidersIcon"
            class="mr-50"
          />
            <span>{{ $t('global.action.panel') }}</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item @click="logOut" link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
            <span>{{ $t('global.action.logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue';
import Avatar from '@/components/ui/Avatar.vue';
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue';
import { mapGetters } from 'vuex';
import Powertools from '@/components/dev/powertools/Powertools.vue';
import NotificationDropdown from './NotificationDropdown.vue';
import NotificationEmitter from './NotificationEmitter.vue';

export default {
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      fullname: 'auth/fullname',
    }),
    developmentMode() {
      return process.env.NODE_ENV === 'development';
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logOut').then(() => {
        this.$router.replace({ name: 'login' });
        this.$forceUpdate();
      });
    },
    openProfileSettings() {
      this.$router.push({ name: 'profile.settings' });
    },
  },
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    Avatar,

    // Navbar Components
    DarkToggler,
    NotificationDropdown,
    NotificationEmitter,
    Powertools,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="sass" scoped>
.settings-label
  display: flex
  align-items: center
  padding: 10px 5px

  .text
    margin-left: 10px
</style>
