var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.company && _vm.companyRoute ? _c('abstract-module-label', {
    attrs: {
      "icon": "PackageIcon",
      "to": {
        name: _vm.companyRoute,
        params: {
          id: _vm.company.id
        }
      },
      "name": _vm.company.name,
      "short": _vm.short
    }
  }) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }