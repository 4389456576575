import ApiClient from '@/services/api';

const urls = {
  get: 'api/cadre/group/index',
  create: 'api/cadre/group',
  delete: 'api/cadre/group/:id',
  update: 'api/cadre/group/:id',
};

export default {
  namespaced: true,

  state: {
    groups: [],
    fetchPromise: null,
  },

  getters: {
    all: (state) => state.groups,
    byId: (state) => (id) => state.groups.find((g) => g.id === id),
  },

  mutations: {
    SET_GROUPS(state, groups) {
      state.groups = groups;
    },
    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
    ADD_GROUP(state, group) {
      state.groups = [...state.groups, group];
    },
    UPDATE_GROUP(state, group) {
      const newGroups = state.groups
        .map((current) => {
          if (current.id !== group.id) return current;
          return group;
        });

      state.groups = newGroups;
    },
    REMOVE_GROUP(state, group) {
      const newGroups = state.groups.filter((current) => current.id !== group.id);
      state.groups = [...newGroups];
    },
  },

  actions: {
    /**
     * Fetch groups list
     * @action fetch=groups
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if groups exist
     * @returns {Promise}
     */
    fetch({ state, commit }, force = false) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(urls.get);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_GROUPS', res.data.data);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },

    create({ commit }, data) {
      const request = ApiClient.post(urls.create, data);

      request.then((res) => {
        if (res.status !== 200) return;
        commit('ADD_GROUP', res.data.data);
      });

      return request;
    },

    /**
     * Remove group
     * @action delete=groups
     * @param {ActionContext} [vuexContext]
     * @param {Object} group - Group object
     * @returns {Promise}
     */
    delete({ commit }, group) {
      const url = urls.delete.replace(':id', group.id);
      const promise = ApiClient.delete(url);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('REMOVE_GROUP', group);
      });
      return promise;
    },

    /**
     * Update group
     * @action update=groups
     * @param {ActionContext} [vuexContext]
     * @param {Object} group - Group object
     * @returns {Promise}
     */
    update({ commit }, group) {
      const url = urls.update.replace(':id', group.id);
      const promise = ApiClient.put(url, group);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('UPDATE_GROUP', group);
      });
      return promise;
    },
  },
};
