import { reactive, toRefs } from 'vue';
import { vsModal } from '../investments/useInvestmentsModals';

const modals = reactive({
  modalCreateTask: vsModal({
    text: {
      title: 'Tworzenie zadania',
    },
  }),
  modalEditTask: vsModal({
    text: {
      title: 'Edycja zadania',
    },
  }),
});

export default function useTasksModals() {
  return { ...toRefs(modals) };
}
