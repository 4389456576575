var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-nav-item-dropdown', {
    ref: "notificationBell",
    staticClass: "dropdown-notification mr-25",
    attrs: {
      "menu-class": "dropdown-menu-media",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "text-body",
          attrs: {
            "badge": _vm.totalUnreadCount,
            "badge-classes": "bg-danger",
            "icon": "BellIcon",
            "size": "21"
          }
        })];
      },
      proxy: true
    }])
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": ""
    }
  }) : _vm._e(), _c('li', {
    staticClass: "dropdown-menu-header"
  }, [_c('div', {
    staticClass: "dropdown-header d-flex"
  }, [_c('h4', {
    staticClass: "notification-title mb-0 mr-auto"
  }, [_vm._v(" " + _vm._s(_vm.$t('component.notificationBell.title.header')) + " ")]), _c('span', {
    staticClass: "mark-read"
  }, [_vm.notifications.length > 0 ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    },
    on: {
      "click": _vm.markBellAsRead
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('component.notificationBell.button.markAllAsRead')) + " ")]) : _vm._e()], 1)])]), _c('vue-perfect-scrollbar', {
    staticClass: "scrollable-container media-list scroll-area",
    attrs: {
      "settings": _vm.perfectScrollbarSettings,
      "tagname": "li"
    }
  }, [_vm.notifications.length === 0 ? _c('div', {
    staticClass: "no-unread-notifications"
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "CheckCircleIcon",
      "size": "42"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('component.notificationBell.title.noUnreadNotifications')) + " ")], 1) : _vm._e(), _vm._l(_vm.notifications, function (notification, index) {
    return _c('notification', {
      key: index,
      attrs: {
        "notification": notification
      },
      on: {
        "action": function () {
          return _vm.notificationClicked(notification);
        }
      }
    });
  })], 2), _c('li', {
    staticClass: "dropdown-menu-footer"
  }, [_vm.notifications.length < _vm.totalUnreadCount ? _c('p', [_vm._v(" " + _vm._s(_vm.$t('component.notificationBell.title.itemCount', {
    count: _vm.notifications.length,
    maxCount: _vm.totalUnreadCount
  })) + " ")]) : _vm._e(), _c('vs-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "read-history-button",
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.openNotificationHistory
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('component.notificationBell.button.readHistory')) + " ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }