/**
 * Pseudo API class to communicate with
 * objects instead of real api.
 */
import tasks from './tasks';
import jobs from './jobs';
import statusJobsBadge from './statusJobsBadge';
import statusBadge from './statusBadge';
import priorityLabel from './priorityLabel';

const pseudoRoutes = {
  tasks,
  jobs,
  statusBadge,
  priorityLabel,
  statusJobsBadge,
};

class PseudoApi {
  static get(resource) {
    return pseudoRoutes[resource];
  }
}

export default PseudoApi;
