import providers from './providers';
import clients from './clients';
import statuses from './company/statuses';
import industries from './company/industries';

export default {
  namespaced: true,
  modules: {
    providers,
    clients,
    statuses,
    industries,
  },
};
