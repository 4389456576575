import i18n from '@/libs/i18n';

const moduleRoute = 'Investment';

/**
 * Investments Tab routes
 */
const baseRoutes = [
  {
    path: '/investments',
    name: 'investments',
    component: () => import('@/views/investments/InvestmentsList.vue'),
    meta: {
      pageTitle: i18n.t('investments.list.pageTitle'),
      moduleRoute,
      activeTab: 'investments',
      requiresAuth: true,
      requiresAnyPermission: 'investment.investment',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('investments.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/investments/reports/:investmentId?',
    name: 'investments.reports',
    component: () => import('@/views/investments/InvestmentsList.vue'),
    meta: {
      pageTitle: i18n.t('investments.list.pageTitle'),
      moduleRoute,
      requiresAuth: true,
      requiresAnyPermission: 'investment.investment.show',
      activeTab: 'reports',
      navActiveLink: 'investments',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('investments.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/investments/processing/:investmentId?',
    name: 'investments.processing',
    component: () => import('@/views/investments/InvestmentsList.vue'),
    meta: {
      pageTitle: i18n.t('investments.list.pageTitle'),
      moduleRoute,
      requiresAuth: true,
      requiresAnyPermission: 'investment.investment.show',
      activeTab: 'processing',
      navActiveLink: 'investments',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('investments.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/investments/fueling/:investmentId?',
    name: 'investments.fueling',
    component: () => import('@/views/investments/InvestmentsList.vue'),
    meta: {
      pageTitle: i18n.t('investments.list.pageTitle'),
      moduleRoute,
      requiresAuth: true,
      requiresAnyPermission: 'investment.investment.show',
      activeTab: 'fueling',
      navActiveLink: 'investments',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('investments.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/investments/:id',
    name: 'investments.details',
    component: () => import('@/views/investments/InvestmentsDetails.vue'),
    meta: {
      pageTitle: i18n.t('investments.details.pageTitle'),
      navActiveLink: 'investments',
      moduleRoute,
      requiresAuth: true,
      requiresAnyPermission: 'investment.investment.show',
      breadcrumb: [
        {
          text: i18n.t('investments.pageTitle'),
          to: { name: 'investments' },
          active: false,
        },
        {
          text: i18n.t('investments.details.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/investments/:id/settings',
    name: 'investments.details.settings',
    component: () => import('@/views/investments/InvestmentsSettings.vue'),
    meta: {
      pageTitle: i18n.t('investments.settings.pageTitle'),
      navActiveLink: 'investments',
      moduleRoute,
      requiresAuth: true,
      requiresAnyPermission: 'investment.investment.update',
      breadcrumb: [
        {
          text: i18n.t('investments.pageTitle'),
          to: { name: 'investments' },
          active: false,
        },
        {
          text: i18n.t('investments.details.pageTitle'),
          to: { name: 'investments.details' },
          active: false,
        },
        {
          text: i18n.t('investments.settings.pageTitle'),
          active: true,
        },
      ],
    },
  },
];

export default baseRoutes;
