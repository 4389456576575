import CoreFeedback from '@feedback/module/core/CoreFeedback';

/**
 * Defined Feedback objects.
 *
 * Currently it looks messy, but the whole point of
 * defining each error separately (instead of using plain i18n)
 * is to allow further changes to the Feedback structure, e.g.
 * types, actions, custom templates and more settings.
 *
 * TODO: break this down into separate files whenever the Feedback
 *       logic will be ready
 *
 * After adding a feedback instance below, remember to add the proper
 * key to i18n
 */

/** Negative Feedbacks */
// eslint-disable-next-line import/prefer-default-export
export const ModuleFetchError = new CoreFeedback('ModuleFetchError', false);
export const PasswordResetTokenError = new CoreFeedback('PasswordResetTokenError', false);
export const PasswordForgotError = new CoreFeedback('PasswordForgotError', false);

export const AuthorizedDevicesFetchError = new CoreFeedback('AuthorizedDevicesFetchError', false);
export const AuthorizedDevicesReauthError = new CoreFeedback('AuthorizedDevicesReauthError', false);
export const AuthorizedDevicesCreateError = new CoreFeedback('AuthorizedDevicesCreateError', false);
export const AuthorizedDevicesUpdateError = new CoreFeedback('AuthorizedDevicesUpdateError', false);
export const AuthorizedDevicesDeleteError = new CoreFeedback('AuthorizedDevicesDeleteError', false);

export const FetchUserPermissionsError = new CoreFeedback('FetchUserPermissionsError', false);
export const SaveUserPermissionsError = new CoreFeedback('SaveUserPermissionsError', false);
export const CreateUserAccountError = new CoreFeedback('CreateUserAccountError', false);

export const UnauthenticatedError = new CoreFeedback('UnauthenticatedError', false);

/** Positive Feedbacks */
export const AuthorizedDevicesCreateSuccess = new CoreFeedback('AuthorizedDevicesCreateSuccess', true);
export const AuthorizedDevicesUpdateSuccess = new CoreFeedback('AuthorizedDevicesUpdateSuccess', true);
export const AuthorizedDevicesDeleteSuccess = new CoreFeedback('AuthorizedDevicesDeleteSuccess', true);

export const SaveUserPermissionsSuccess = new CoreFeedback('SaveUserPermissionsSuccess', true);
export const CreateUserAccountSuccess = new CoreFeedback('CreateUserAccountSuccess', true);
