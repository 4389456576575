var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order-label"
  }, [_c('router-link', {
    staticClass: "prefix",
    attrs: {
      "to": {
        name: 'orders-details',
        params: {
          id: _vm.id
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.orderPrefix) + " ")]), _vm._v(" " + _vm._s(_vm.title) + " ")], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }