import ApiClient from '@/services/api';

const urls = {
  get: 'api/base/resource-service',
};

export default {
  namespaced: true,

  state: {
    service: [],
  },

  getters: {
  },

  mutations: {
    SET_SERVICE(state, service) {
      state.service = service;
    },

    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
  },

  actions: {
    /**
     * Fetch service list
     * @action fetch=service
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if service exist
     * @returns {Promise}
     */
    fetch({ state, commit }, params, force = true) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(urls.get, {
        ...params,
        columnFilters: {
          ...params.columnFilters ?? {},
          service_types: ['registration'],
        },
      });

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_SERVICE', res.data.data.services);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },
  },
};
