import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';

const { fetchPickerInvestments } = useInvestmentsApi();

export default {
  namespaced: true,
  state: {
    investments: [],
  },

  getters: {
    byInvestmentId: (state) => (investmentId) => state.investments
      .filter((investment) => investment.id === investmentId),
  },

  mutations: {
    SET_INVESTMENTS(state, investments) {
      state.investments = investments;
    },

    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
  },

  actions: {
    /**
     * Fetch investments list
     * @action fetch=investments
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if vehicles exist
     * @returns {Promise}
     */
    fetch({ state, commit }, force = true) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = fetchPickerInvestments();

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_INVESTMENTS', res.data.data);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },
  },
};
