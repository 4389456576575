export default {
  order: {
    offer_number: 'Numer oferty',
    title: 'Tytuł',
    priority_id: 'Priorytet',
    currency_id: 'Waluta',
    provider: 'Dostawca',
    _providerBranch: 'Dostawca - oddział',
    provider_id: 'Dostawca - osoba kontaktowa',
    investor: 'Inwestor',
    investor_id: 'Inwestor - oddział',
    investment: 'Inwestycja',
    investment_id: 'Inwestycja',
    branch_id: 'Region',
    ordered_at: 'Data zlecenia',
    assigned_id: 'Osoba przypisana',
    accepting_id: 'Osoba akceptująca',
    comment: 'Uwagi do zlecenia',
    payment_terms: 'Warunki płatności',
    realization_at: 'Termin realizacji',
    delivery_place: 'Miejsce dostawy',
    transport: 'Transport',
    contact_person: 'Osoba kontaktowa',
  },
};
