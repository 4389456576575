import Vue from 'vue';
import Vuex from 'vuex';

import ApiClient from '@/services/api';

const createUrl = 'api/orders/media';
const removeUrl = 'api/orders/media/:id';

Vue.use(Vuex);

export default {
  namespaced: true,

  state: {
    currentAttachments: [],
    newAttachment: {
      content: null,
    },
    parameters: {
      auditableIds: [12],
      orderBy: 'ASC',
      select: ['event', 'created_at', 'user_id'],
    },
  },

  getters: {
    current: (state) => state.currentAttachments,
    byId: (state) => (id) => state.currentAttachments
      .filter((attachment) => attachment.id === id)[0] || null,
  },

  mutations: {
    SET_CURRENT_ATTACHMENTS(state, payload) {
      state.currentAttachments = payload;
    },
    ADD_ATTACHMENT(state, payload) {
      state.currentAttachments.push(payload);
    },
    REMOVE_ATTACHMENT(state, id) {
      state.currentAttachments = state.currentAttachments
        .filter((attachment) => attachment.id !== id);
    },
    UPDATE_ATTACHMENT(state, newAttachment) {
      const index = state.currentAttachments
        .findIndex((attachment) => attachment.id === newAttachment.id);

      // replace a attachment inside array
      const newArray = [...state.currentAttachments];
      newArray[index] = { ...newArray[index], ...newAttachment };

      state.currentAttachments = [...newArray];
    },
  },

  actions: {
    fetch({ state, commit }, id) {
      const params = {
        ...state.parameters,
        auditableIds: [id],
      };
      ApiClient.get('/', params).then((payload) => {
        if (payload.status !== 200) return null;
        return commit('SET_CURRENT_ATTACHMENTS', payload.data.data);
      });
    },

    create({ commit }, attachment) {
      const formData = new FormData();

      formData.append('file', attachment.file);
      formData.append('title', attachment.title);
      formData.append('mediable_id', attachment.order_id);

      const request = ApiClient.post(createUrl, formData);

      request.then((payload) => {
        if (payload.status !== 200) return null;
        return commit('ADD_ATTACHMENT', payload.data.data.file);
      });

      return request;
    },

    edit({ commit }, attachment) {
      const url = removeUrl.replace(':id', attachment.id);
      const request = ApiClient.put(url, attachment);

      request.then(({ status }) => {
        if (status !== 200) return;

        commit('UPDATE_ATTACHMENT', attachment);
      }).catch(() => {});
    },

    set({ commit }, data) {
      return commit('SET_CURRENT_ATTACHMENTS', data);
    },

    remove({ commit }, id) {
      const url = removeUrl.replace(':id', id);

      const request = ApiClient.delete(url);

      request.then((payload) => {
        if (payload.status !== 200) return null;

        return commit('REMOVE_ATTACHMENT', id);
      });

      return request;
    },
  },
};
