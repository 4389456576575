var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-modal"
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-cadre-position-edit",
      "title": _vm.$t('settings.cadre.positions.edit.title'),
      "cancel-variant": "outline-secondary",
      "cancel-title": _vm.$t('settings.cadre.positions.button.cancel'),
      "ok-title": _vm.$t('settings.cadre.positions.button.save'),
      "size": "xl",
      "no-close-on-backdrop": ""
    },
    on: {
      "show": _vm.bindModel,
      "ok": function ($event) {
        $event.preventDefault();
        return _vm.updatePosition.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref) {
        var ok = _ref.ok,
          cancel = _ref.cancel;
        return [_c('vs-modal-footer', {
          attrs: {
            "disable-on-loading": "",
            "is-loading": _vm.loading,
            "text-cancel": _vm.$t('settings.cadre.positions.button.cancel'),
            "text-ok": _vm.$t('settings.cadre.positions.button.save')
          },
          on: {
            "cancel": cancel,
            "ok": ok
          }
        })];
      }
    }])
  }, [_vm.loading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": "Aktualizowanie stanowiska..."
    }
  }) : _vm._e(), _c('b-card-text', [_c('position-form', {
    attrs: {
      "model": _vm.positionModel,
      "feedback": _vm.errors,
      "permissions": _vm.permissionIds,
      "preparingPermissions": _vm.preparingPermissions
    },
    on: {
      "update:granted": function (val) {
        return _vm.granted = val;
      }
    },
    model: {
      value: _vm.position,
      callback: function ($$v) {
        _vm.position = $$v;
      },
      expression: "position"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }