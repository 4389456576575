var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "table-wrapper"
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "orders.order.index",
      "silent": ""
    }
  }, [_vm.filtrationEnabled ? _c('div', {
    staticClass: "card shadow-none bg-transparent border-secondary"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.title.filtering')) + " ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('orders.list.filter.region')
    }
  }, [_c('core-branch-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.branchFilter,
      callback: function ($$v) {
        _vm.branchFilter = $$v;
      },
      expression: "branchFilter"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('orders.list.filter.provider')
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('integration-form-label', {
          attrs: {
            "integration": "crm_address_relation",
            "label": _vm.$t('orders.list.filter.provider')
          }
        })];
      },
      proxy: true
    }], null, false, 712392431)
  }, [_c('crm-provider-picker', {
    attrs: {
      "multiple": "",
      "reduce": function (company) {
        return company.id;
      },
      "option": _vm.filterParams.providers
    },
    model: {
      value: _vm.providerFilter,
      callback: function ($$v) {
        _vm.providerFilter = $$v;
      },
      expression: "providerFilter"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('orders.list.filter.investor')
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('integration-form-label', {
          attrs: {
            "integration": "crm_address_relation",
            "label": _vm.$t('orders.list.filter.investor')
          }
        })];
      },
      proxy: true
    }], null, false, 4253798176)
  }, [_c('crm-client-picker', {
    attrs: {
      "multiple": "",
      "reduce": function (company) {
        return company.id;
      }
    },
    model: {
      value: _vm.investorFilter,
      callback: function ($$v) {
        _vm.investorFilter = $$v;
      },
      expression: "investorFilter"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('orders.list.filter.investment')
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function () {
        return [_c('integration-form-label', {
          attrs: {
            "integration": "investment_investment_relation",
            "label": _vm.$t('orders.list.filter.investment')
          }
        })];
      },
      proxy: true
    }], null, false, 2597940421)
  }, [_c('investments-investment-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.investmentFilter,
      callback: function ($$v) {
        _vm.investmentFilter = $$v;
      },
      expression: "investmentFilter"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('orders.list.filter.status')
    }
  }, [_c('orders-status-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.statusFilter,
      callback: function ($$v) {
        _vm.statusFilter = $$v;
      },
      expression: "statusFilter"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('orders.edit.field.priority')
    }
  }, [_c('core-priority-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.priorityFilter,
      callback: function ($$v) {
        _vm.priorityFilter = $$v;
      },
      expression: "priorityFilter"
    }
  })], 1)], 1)], 1), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.clearAllFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.clearAllFilters')) + " ")])], 1)]) : _vm._e()]), _c('div', {
    staticClass: "buttons"
  }, [_c('div', {
    staticClass: "group"
  }, [_c('vs-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'orders.order',
      expression: "'orders.order'",
      arg: "store"
    }],
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.openCreateModal
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t('global.button.order')) + " ")])], 1)]), _c('br'), _c('authorization-guard', {
    attrs: {
      "permission": "orders.order.index"
    }
  }, [_c('vs-table', {
    attrs: {
      "mode": "remote",
      "columns": _vm.displayedColumns,
      "rows": _vm.orders,
      "totalRecords": _vm.totalRecords,
      "checkSort": _vm.columnSortOption,
      "perPage": _vm.serverParams.perPage,
      "isLoading": _vm.isLoading
    },
    on: {
      "on-row-click": _vm.openDetails,
      "searchTermChange": function (term) {
        return _vm.searchTerm = term;
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange,
      "on-sort-change": _vm.changeSorting
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (props) {
        var _vm$branchById;
        return [props.column.field === 'order_number' ? _c('order-label', {
          attrs: {
            "id": props.row.id,
            "title": props.row.order_number
          }
        }) : props.column.field === 'status_id' ? _c('status-badge', {
          attrs: {
            "orders": "",
            "status_id": props.row.status_id
          }
        }) : props.column.field === 'priority_id' ? _c('span', [_c('priority-label', {
          attrs: {
            "id": props.row.priority_id
          }
        })], 1) : props.column.field === 'ordered_at' ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate.taskDeadline(props.row.ordered_at)) + " ")]) : props.column.field === 'objects' ? _c('span', [_vm._v(" " + _vm._s(_vm.totalPrice(props.row.subjects, props.row.id)) + " " + _vm._s(_vm.currencyById(props.row.currency_id).code) + " ")]) : props.column.field === 'branch_id' ? _c('span', [_vm._v(" " + _vm._s((_vm$branchById = _vm.branchById(props.row.branch_id)) === null || _vm$branchById === void 0 ? void 0 : _vm$branchById.shortcut) + " ")]) : props.column.field === 'investment' ? _c('span', [props.row.investment.id ? _c('investments-investment-label', {
          attrs: {
            "nowrap": "",
            "investment": props.row.investment
          }
        }) : props.row.investment.name ? _c('span', [_vm._v(_vm._s(props.row.investment.name))]) : _vm._e()], 1) : props.column.field === 'provider' ? _c('span', [props.row.crm_provider_address_contact && props.row.crm_provider_address_contact.company_address ? _c('crm-company-label', {
          attrs: {
            "integrated": props.row.crm_provider_address_contact.company_address.company,
            "short": ""
          }
        }) : _vm._e()], 1) : _vm._e()];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }