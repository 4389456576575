import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useCoreApi from '@/hooks/useCoreApi';

const { fetchPriorities } = useCoreApi();

export default defineStore('corePriority', () => {
  const {
    state,
    fetch,
    fetched,
    getById,
  } = useRemoteResource('core.priority', fetchPriorities);

  return {
    state,
    fetch,
    fetched,
    getById,
  };
});
