import Feedback from '@feedback/Feedback';

/**
 * Feedback extension for Cores
 */

class CoreFeedback extends Feedback {
  constructor(namespace, isPositiveFeedback) {
    super(isPositiveFeedback);

    this.extendI18nNamespace('core');
    this.extendI18nNamespace(namespace);
  }
}

export default CoreFeedback;
