var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "misc-wrapper"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('vs-logo')], 1), _c('div', {
    staticClass: "misc-inner p-2 p-sm-3"
  }, [_c('div', {
    staticClass: "w-100 text-center"
  }, [_c('h2', {
    staticClass: "mb-1"
  }, [_vm._v(" " + _vm._s(_vm.$t('error.unauthorized.header')) + " ")]), _c('p', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('error.unauthorized.description')) + " ")]), _c('div', {
    staticClass: "lacking-info"
  }, [_vm._v(" " + _vm._s(_vm.$t('error.unauthorized.lacking', {
    permission: _vm.$route.params.permission
  })) + " ")]), _c('vs-button', {
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push({
          path: '/'
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('error.unauthorized.button.backToHome')) + " ")]), _c('b-img', {
    attrs: {
      "fluid": "",
      "src": _vm.imgUrl,
      "alt": "Error page"
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }