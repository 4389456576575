import i18n from '@/libs/i18n';

export default [
  {
    path: '/base/transport',
    name: 'base.transport',
    component: () => import('@/views/base/transport/BaseTransport.vue'),
    meta: {
      pageTitle: i18n.t('base.transport.list.pageTitle'),
      moduleRoute: 'Base',
      activeTab: 'transport',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.transport.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.transport.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/transport/details/:id',
    name: 'base.transport.details',
    component: () => import('@/views/base/transport/BaseTransportDetails.vue'),
    meta: {
      pageTitle: i18n.t('base.transport.details.pageTitle'),
      navActiveLink: 'base.transport',
      moduleRoute: 'Base',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource.show',
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.transport.list.pageTitle'),
          to: { name: 'base.transport' },
          active: false,
        },
        {
          text: i18n.t('base.transport.details.pageTitle'),
          active: true,
        },
      ],
    },
  },
];
