import i18n from '@/libs/i18n';

/**
 * Cadre Tab routes
 */
const cadreRoutes = [
  {
    path: '/cadre/employees',
    name: 'cadre',
    component: () => import('@/views/cadre/employees/CadreEmployees.vue'),
    meta: {
      pageTitle: i18n.t('cadre.list.pageTitle'),
      moduleRoute: 'Cadre',
      requiresAuth: true,
      requiresAnyPermission: 'cadre.employee',
      activeTab: 'employees',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('cadre.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/cadre/employees/examinations/:employeeId?',
    name: 'cadre.examinations',
    component: () => import('@/views/cadre/employees/CadreEmployees.vue'),
    meta: {
      pageTitle: i18n.t('cadre.list.pageTitle'),
      moduleRoute: 'Cadre',
      requiresAuth: true,
      requiresAnyPermission: 'cadre.medical_exam',
      activeTab: 'examinations',
      showFiltration: true,
      navActiveLink: 'cadre',
      breadcrumb: [
        {
          text: i18n.t('cadre.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/cadre/employees/safety-trainings/:employeeId?',
    name: 'cadre.safety-trainings',
    component: () => import('@/views/cadre/employees/CadreEmployees.vue'),
    meta: {
      pageTitle: i18n.t('cadre.list.pageTitle'),
      moduleRoute: 'Cadre',
      requiresAuth: true,
      requiresAnyPermission: 'cadre.safety_training',
      activeTab: 'safety-trainings',
      showFiltration: true,
      navActiveLink: 'cadre',
      breadcrumb: [
        {
          text: i18n.t('cadre.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/cadre/employees/details/:id',
    name: 'cadre.employee.details',
    component: () => import('@/views/cadre/Details.vue'),
    showFiltration: false,
    meta: {
      pageTitle: i18n.t('cadre.details.pageTitle'),
      navActiveLink: 'cadre',
      requiresAuth: true,
      requiresAnyPermission: 'cadre.employee.show',
      breadcrumb: [
        {
          text: i18n.t('cadre.pageTitle'),
          to: { name: 'cadre' },
          active: false,
        },
        {
          text: i18n.t('cadre.details.pageTitle'),
          getter: 'cadre/employees/currentName',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cadre/employees/details/:id/edit',
    name: 'cadre.employee.edit',
    component: () => import('@/views/cadre/EditEmployee.vue'),
    meta: {
      pageTitle: i18n.t('cadre.editEmployee.pageTitle'),
      navActiveLink: 'cadre',
      requiresAuth: true,
      requiresAnyPermission: 'cadre.employee.update',
      breadcrumb: [
        {
          text: i18n.t('cadre.pageTitle'),
          to: { name: 'cadre' },
          active: false,
        },
        {
          text: i18n.t('cadre.details.pageTitle'),
          getter: 'cadre/employees/currentName',
          to: { name: 'cadre.employee.details' },
          active: false,
        },
        {
          text: i18n.t('cadre.editEmployee.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/cadre/employees/details/:id/holidays',
    name: 'cadre.employee.holidays',
    component: () => import('@cadre/HolidayHistory.vue'),
    showFiltration: false,
    meta: {
      pageTitle: i18n.t('cadre.holidays.pageTitle'),
      navActiveLink: 'cadre',
      requiresAuth: true,
      breadcrumb: [
        {
          text: i18n.t('cadre.pageTitle'),
          to: { name: 'cadre' },
          active: false,
        },
        {
          text: i18n.t('cadre.details.pageTitle'),
          getter: 'cadre/employees/currentName',
          to: { name: 'cadre.employee.details' },
          active: false,
        },
        {
          text: i18n.t('cadre.holidays.pageTitle'),
          active: true,
        },
      ],
    },
  },
];

export default cadreRoutes;
