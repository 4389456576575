<template>
  <div class="investments-edit-work-group-modal">
    <b-modal
      id="modal-investments-edit-work-group"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.workGroup.edit.title')"

      v-model="editWorkGroupModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.workGroup.edit.loading')"
        v-if="isLoading"
      />

      <investment-work-group-form
        v-model="fields"
        :feedback="feedback"
      />

      <template #modal-footer>
        <div class="investment-modal-footer">

          <div class="buttons">
            <vs-button
              variant="outline"
              @click="editWorkGroupModal.visible = false"
            >
              {{ $t('investments.modal.workGroup.edit.cancel') }}
            </vs-button>

            <vs-button
              variant="primary"
              @click="editWorkGroup"
            >
              {{ $t('investments.modal.workGroup.edit.confirm') }}
            </vs-button>
          </div>

        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import showToast from '@/libs/toasts';
import {
  EditWorkGroupError, EditWorkGroupSuccess,
} from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import InvestmentWorkGroupForm from '../forms/InvestmentWorkGroupForm.vue';

export default {
  name: 'InvestmentWorkGroupModalEdit',
  props: {},
  setup(_props, { emit }) {
    const { editWorkGroupModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const editingId = ref(null);

    const {
      fields, feedback, validateAllFields, resetFields, validateField,
      assignFields, nonEmptyFields, assignServerSideErrors,
    } = useValidatedForm({
      name: 'required',
    }, 'investments.form.workGroup.field');

    watch(() => editWorkGroupModal.value.visible, () => {
      const { data } = editWorkGroupModal.value;
      if (data && Object.keys(data).length === 0) return;

      assignFields({
        ...editWorkGroupModal.value.data,
      });

      editingId.value = editWorkGroupModal.value.data?.id;
      editWorkGroupModal.value.data = {};
    });

    const editWorkGroup = async () => {
      const validFields = await validateAllFields();
      if (!validFields.every((field) => field)) return;
      if (!editingId.value) return;

      isLoading.value = true;

      useInvestmentsApi()
        .updateWorkGroup(editingId.value, {
          ...nonEmptyFields.value,
        })
        .then(({ data }) => {
          showToast(EditWorkGroupSuccess, { name: data.data.name });
          emit('success', data.data);

          resetFields();
          editWorkGroupModal.value.visible = false;
          isLoading.value = false;
        })
        .catch(({ response }) => {
          showToast(EditWorkGroupError);
          assignServerSideErrors(response?.data?.errors);
          isLoading.value = false;
        });
    };

    return {
      fields,
      feedback,
      editWorkGroupModal,
      isLoading,
      editWorkGroup,
      validateField,
    };
  },
  components: {
    VsLoader,
    VsButton,
    InvestmentWorkGroupForm,
  },
};
</script>

<style lang="sass" scoped>
.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between

  .buttons
    margin-left: auto
</style>
