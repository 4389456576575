export default {
  _genericActions: {
    index: 'Listowanie',
    show: 'Wyświetlanie',
    store: 'Tworzenie',
    update: 'Edycja',
    upload: 'Wgrywanie',
    destroy: 'Usuwanie',
    delete: 'Usuwanie',
    switch: 'Przełączanie',
    change_active: 'Zmiana aktywności',
    assign: 'Przypisz',
    unassign: 'Cofnij przypisanie',
  },
  base: {
    name: 'Moduł Baza',
    children: {
      group: {
        name: 'Grupy',
        children: {},
      },
      insurance: {
        name: 'Ubezpieczenia',
        children: {},
      },
      category: {
        name: 'Kategorie',
        children: {},
      },
      notes: {
        name: 'Notatki',
        children: {},
      },
      statuses: {
        name: 'Statusy',
        children: {},
      },
      technology: {
        name: 'Technologie',
        children: {},
      },
      work_type: {
        name: 'Typy pracy',
        children: {},
      },
      media: {
        name: 'Załączniki',
        children: {},
      },
      resource_brand: {
        name: 'Marka',
        children: {},
      },
      resource_service: {
        name: 'Serwisy i przeglądy',
        children: {
          assign_technologies: 'Przypisywanie technologii',
          assign_tools: 'Przypisywanie sprzętu',
        },
      },
      resource: {
        name: 'Zasoby (maszyny, sprzęty i samochody)',
        children: {
          assign_technologies: 'Przypisywanie technologii',
          assign_tools: 'Przypisywanie sprzętu',
        },
      },
      transport: {
        name: 'Transport',
        children: {
          logs: 'Wyświetlanie osi czasu',
        },
      },
      transport_type: {
        name: 'Rodzaje transportu',
        children: {},
      },
      integration_employee: {
        name: 'Integracja z modułem Kadry',
        children: {
          switch: 'base.integration_employee.switch',
        },
      },
      producer_model: {
        name: 'Modele maszyn/sprzętu',
        children: {},
      },
      producer: {
        name: 'Producenci maszyn/sprzętu',
        children: {},
      },
      resource_report: {
        name: 'Raporty maszyn/sprzętu',
        children: {},
      },
      tool: {
        name: 'Sprzęt',
        children: {
          assign_technologies: 'Przypisanie do technologii',
          assign_tools: 'Przypisanie do maszyn',
        },
      },
      vehicle: {
        name: 'Maszyny',
        children: {
          assign_technologies: 'Przypisanie do technologii',
          assign_tools: 'Przypisanie sprzętu',
        },
      },
    },
  },
  cadre: {
    name: 'Moduł Kadra',
    children: {
      employee: {
        name: 'Kadra',
        children: {
          change_active: 'Aktywność',
          restore: 'Przywracanie',
        },
      },
      employment_form: {
        name: 'Formy zatrudnienia',
        children: {},
      },
      form: {
        name: 'Formy zatrudnienia',
        children: {
          assign: 'cadre.cadre_form.assign',
          change_active: 'cadre.cadre_form.change_active',
        },
      },
      group: {
        name: 'Grupy',
        children: {},
      },
      position: {
        name: 'Stanowiska',
        children: {},
      },
      skill: {
        name: 'Uprawnienia / kursy',
        children: {
          assign: 'Przypisywanie',
        },
      },
      status: {
        name: 'Statusy',
        children: {
          restore: 'Przywracanie',
        },
      },
      medical_exam: {
        name: 'Badania lekarskie',
        children: {
          can_pick_up_expiration_notification: 'Otrzymywanie powiadomień o wygasających',
          get_all_by_employee_id: 'Wyświetlenie wszystkich badań dla danego pracownika',
          get_last_by_employee_id: 'Wyświetlenie najnowszego badania dla danego pracownika',
        },
      },
      safety_training: {
        name: 'Szkolenia BHP',
        children: {
          can_pick_up_expiration_notification: 'Otrzymywanie powiadomień o wygasających',
          employee_history: 'Wyświetlenie listy',
        },
      },
    },
  },
  core: {
    name: 'Ogólne uprawnienia',
    children: {
      priority: {
        name: 'Priorytety',
        children: {},
      },
      company_branch: {
        name: 'Oddziały firmy',
        children: {
          change_active: 'core.company_branch.change_active',
        },
      },
      currency: {
        name: 'Waluty',
        children: {
          change_active: 'core.currency.change_active',
        },
      },
      media: {
        name: 'Załączniki',
        children: {
          change_active: 'core.media.change_active',
        },
      },
      permission: {
        name: 'Uprawnienia',
        children: {
          change_active: 'core.permission.change_active',
        },
      },
      role: {
        name: 'Grupy uprawnień (role)',
        children: {
          change_active: 'core.role.change_active',
        },
      },
      unit: {
        name: 'Jednostki',
        children: {
          change_active: 'core.unit.change_active',
        },
      },
      user: {
        name: 'Użytkownicy',
        children: {
          change_active: 'core.user.change_active',
          permission: 'Zmiana uprawnień',
        },
      },
    },
  },
  crm: {
    name: 'Moduł CRM',
    children: {
      company_address_contact: {
        name: 'Osoby kontaktowe',
        children: {},
      },
      company_address: {
        name: 'Oddziały',
        children: {},
      },
      company_industry: {
        name: 'Branże',
        children: {
          assign: 'Przypisywanie',
          change_active: 'crm.company_industry.change_active',
          un_assign: 'crm.company_industry.un_assign',
        },
      },
      company_media: {
        name: 'Załączniki',
        children: {},
      },
      company_region: {
        name: 'Regiony',
        children: {
          assign: 'Przypisanie',
          un_assign: 'Cofnięcie przypisania',
        },
      },
      company_status: {
        name: 'Statusy',
        children: {
          change_active: 'crm.company_status.change_active',
        },
      },
      company: {
        name: 'Klienci',
        children: {
          change_active: 'crm.company.change_active',
          change_status: 'Zmiana statusu',
        },
      },
      provider_service: {
        name: 'Usługi dostawców',
        children: {
          assign: 'Przypisanie',
          change_active: 'crm.provider_service.change_active',
          un_assign: 'Cofnięcie przypisania',
        },
      },
      provider: {
        name: 'Dostawcy',
        children: {
          change_active: 'crm.provider.change_active',
        },
      },
    },
  },
  investment: {
    name: 'Moduł Inwestycje',
    children: {
      base_resource: {
        name: 'Przypisywane zasoby',
        children: {},
      },
      brigade: {
        name: 'Brygady',
        children: {
          assign_employee: 'Przypisywanie pracowników do brygad',
          detach_employee: 'Usuwanie pracowników z brygad',
          edit_employee: 'Edycja',
        },
      },
      investment: {
        name: 'Inwestycje',
        children: {
          index_picker: 'investment.investment.index_picker',
          resources: 'Zasoby',
          subjects: 'Przedmioty zamówienia',
          work_summary: 'Podsumowanie przerobów',
          work_report: 'Raport pracy',
        },
      },
      note: {
        name: 'Notatki',
        children: {},
      },
      requirement_group: {
        name: 'Materiały zapotrzebowania',
        children: {},
      },
      requirement_offer: {
        name: 'Oferty do zapotrzebowania',
        children: {
          change_accept: 'Akceptowanie ofert',
        },
      },
      requirement: {
        name: 'Zapotrzebowanie',
        children: {},
      },
      subject_balance_progress: {
        name: 'investment.subject_balance_progress',
        children: {},
      },
      subject_balance_usage: {
        name: 'investment.subject_balance_usage',
        children: {},
      },
      subject_balance: {
        name: 'Raporty dzienne',
        children: {},
      },
      subject_reinforcement: {
        name: 'Zbrojenie',
        children: {},
      },
      subject: {
        name: 'Przedmioty zamówienia',
        children: {},
      },
      work_group: {
        name: 'Grupy robót',
        children: {},
      },
      work_type: {
        name: 'Typy robót',
        children: {},
      },
      fuel: {
        name: 'Dostawy paliwa',
        children: {},
      },
    },
  },
  orders: {
    name: 'Moduł Zlecenia',
    children: {
      comment: {
        name: 'Komentarze',
        children: {
          change_active: 'orders.comment.change_active',
        },
      },
      integration_crm: {
        name: 'Integracja z modułem CRM',
        children: {
          switch: 'włączenie/wyłączenie',
        },
      },
      integration_task: {
        name: 'Integracja z modułem Zadań',
        children: {
          switch: 'orders.integration_task.switch',
        },
      },
      order: {
        name: 'Zlecenia',
        children: {
          assign_user: 'Przypisanie do osoby',
          change_active: 'orders.order.change_active',
          change_priority: 'Zmiana priorytetu',
          change_status: 'Zmiana statusu',
          get_logs: 'Wyświetlanie osi czasu',
          get_orders: 'Wyświetlenie listy',
        },
      },
      statuses: {
        name: 'Statusy',
        children: {},
      },
      subject: {
        name: 'Przedmioty zleceń',
        children: {
          change_active: 'orders.subject.change_active',
        },
      },
    },
  },
  tasks: {
    name: 'Moduł Zadania',
    children: {
      comment: {
        name: 'Komentarze',
        children: {},
      },
      media: {
        name: 'Załączniki',
        children: {},
      },
      status: {
        name: 'Statusy',
        children: {},
      },
      task: {
        name: 'Zadania',
        children: {
          assign_user: 'Przypisanie do osoby',
          change_active: 'Zmiana aktywności',
          change_priority: 'Zmiana priorytetu',
          change_status: 'Zmiana statusu',
          get_logs_task: 'Wyświetlanie osi czasu',
          get_params_for_filtrating: 'Wyświetlenie filtracji listy',
          get_tasks: 'Wyświetlenie listy',
          update_column: 'Aktualizacja kolumn',
        },
      },
    },
  },
};
