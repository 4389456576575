export default {
  created: {
    default: 'Utworzono zlecenie transportu',
  },
  updated: {
    default: 'Zaktualizowano zlecenie transportu',
    status: 'Zmieniono status zlecenia na ',
    to_investment_id: 'Zmieniono inwestycję docelową na ',
    from_investment_id: 'Zmieniono inwestycję początkową na ',
    receiver_id: 'Zmieniono osobę odbierającą z ',
    release_id: 'Zmieniono osobę wydającą z ',
  },
  deleted: {
    default: 'Usunięto zlecenie transportu',
  },
};
