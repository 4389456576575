var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "h-100",
    class: [_vm.skinClasses],
    attrs: {
      "id": "app"
    },
    on: {
      "mousemove": _vm.mouseMove,
      "mouseup": _vm.mouseUp
    }
  }, [_vm.permissionsOverriden ? _c('div', {
    staticClass: "sample-notification"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Przeglądasz aplikację ze zmodyfikowanymi uprawnieniami ")]), _c('vs-button', {
    on: {
      "click": function ($event) {
        return _vm.$store.commit('auth/SET_OVERRIDEN_PERMISSIONS', []);
      }
    }
  }, [_vm._v(" Przywróć domyślne ")])], 1) : _vm._e(), _c(_vm.layout, {
    tag: "component"
  }, [_c('router-view')], 1), _vm.developmentMode ? _c('vs-permission-manager-float-editor') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }