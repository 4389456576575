import Feedback from '@feedback/Feedback';

/**
 * Feedback extension for Investments Module
 */

class InvestmentsFeedback extends Feedback {
  constructor(namespace, isPositiveFeedback) {
    super(isPositiveFeedback);

    this.extendI18nNamespace('investments');
    this.extendI18nNamespace(namespace);
  }
}

export default InvestmentsFeedback;
