import Vue from 'vue';
import Vuex from 'vuex';

import ApiClient from '@/services/api';
import { createShortcode } from '@/hooks/useAvatarGenerator';

const endpoint = 'api/core/user/list';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    users: [],
  },

  getters: {
    all: (state) => state.users,
    byId: (state) => (userId) => state.users.filter((user) => user.id === userId)[0],
  },

  mutations: {
    SET_USERS(state, payload) {
      state.users = payload;
    },
    SET_PROMISE(state, payload) {
      state.fetchPromise = payload;
    },
    ADD_USER(state, payload) {
      state.users = [...state.users, payload];
    },
  },

  actions: {
    fetch({ commit, state }, force = false) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(endpoint);

      promise.then((payload) => {
        if (payload.status !== 200) return null;
        return commit('SET_USERS', payload.data.data.map((user) => ({
          ...user,
          // TODO: backend doesn't return short_codes yet
          short_code: user.short_code ?? createShortcode(user.forename, user.surname),
        })));
      });

      commit('SET_PROMISE', promise);

      return promise;
    },
    create({ dispatch }, data) {
      ApiClient.post(endpoint, data).then(() => dispatch('fetch'));
    },
    remove({ dispatch }, id) {
      ApiClient.delete(endpoint, id).then(() => dispatch('fetch'));
    },
    reset({ commit }) {
      commit('SET_USERS', []);
      commit('SET_PROMISE', null);
    },
  },
};
