<template>
  <!-- Bell Style -->
  <b-link
    @click="makeAction"
    v-if="appearance === 'bell'"
    class="notification bell"
    >
    <b-media>
      <template #aside>
        <component
          :is="moduleIcon(notification).component"
          v-bind="moduleIcon(notification).props"
          />
      </template>
      <p class="media-heading">
      <span class="font-weight-bolder">
        {{ formatNotification(notification).title }}
      </span>
      </p>
      <small class="notification-text">
        {{ formatNotification(notification).description }}
      </small>
    </b-media>
  </b-link>

  <!-- History Style -->
  <div
    v-else-if="appearance === 'history'"
    class="notification history"
    :class="notification.read ? 'read' : 'unread'"
    >
    <span>
    </span>
    <div class="module-icon">
      <component
        :is="moduleIcon(notification).component"
        v-bind="moduleIcon(notification).props"
        />
    </div>
    <div class="details">
      <div
        class="title"
        @click="makeAction"
        >
        <b-badge
          pill
          variant="light-primary"
          v-if="!notification.read"
          >
          {{ $t('home.notifications.badge.unread') }}
        </b-badge>
        {{ formatNotification(notification).title }}
      </div>
      <div class="description">
        {{ formatNotification(notification).description }}
      </div>
    </div>
    <div class="right-side">
      <div class="action-buttons">
        <b-button
          class="action"
          v-if="!notification.read"
          @click="markAsRead"
          variant="link"
          >
          <feather-icon
            icon="CheckIcon"
            :size="actionSize"
            />
          {{ i18n.t('home.notifications.button.markAsRead') }}
        </b-button>
      </div>
      <div class="date">
        {{ formatDate.notification(notification.created_at) }}
      </div>
    </div>
  </div>

  <!-- Toast Style -->
  <div
    v-else-if="appearance === 'toast'"
    class="notification history toast"
    :class="notification.read ? 'read' : 'unread'"
    @click="makeAction"
    >
    <span>
    </span>
    <div class="module-icon">
      <component
        :is="moduleIcon(notification).component"
        v-bind="moduleIcon(notification).props"
        />
    </div>
    <div class="details">
      <div
        class="title"
        >
        {{ formatNotification(notification).title }}
      </div>
      <div class="description">
        {{ formatNotification(notification).description }}
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/libs/i18n';
import formatDate from '@/libs/date-formatter';
import { formatNotification, moduleIcon } from '@/libs/notifications';
import {
  BLink, BMedia, BAvatar, BBadge, BButton,
} from 'bootstrap-vue';

export default {
  props: {
    notification: {
      type: Object,
    },
    actionButtonSize: {
      type: Number,
      default: 24,
    },
    appearance: {
      type: String,
      default: 'bell',
    },
  },
  data: () => ({
    formatDate,
    i18n,
  }),
  computed: {
    actionSize() {
      return this.actionButtonSize.toString();
    },
  },
  methods: {
    formatNotification,
    moduleIcon,
    makeAction() {
      this.$emit('action');
    },
    dismiss() {
      this.$emit('dismiss');
    },
    markAsRead() {
      this.$emit('read');
    },
  },
  components: {
    BLink, BMedia, BAvatar, BBadge, BButton,
  },
};
</script>

<style lang="sass" scoped>
.notification.bell
  .notification-text
    color: #aaaaaa !important

.notification.toast
  background: none
  box-shadow: none
  opacity: 1 !important
  margin-bottom: inherit !important
  cursor: pointer

.notification.history
  display: flex
  align-items: center
  padding: 10px 10px
  border-radius: 5px
  transition: .2s all ease-in-out

  &:hover
    background-color: rgba(lightgray, 0.20)

    .action-buttons
      opacity: 1
      pointer-events: auto

  .module-icon
    margin-right: 8px

  .details
    .title
      &:hover
        cursor: pointer

    .description
      font-size: 9pt
      opacity: 1

  .right-side
    display: flex
    align-items: center
    margin-left: auto

  .action-buttons
    transition: .2s opacity ease-in-out
    opacity: 0
    pointer-events: none

    .action
      opacity: 0.7
      border: none
      background: none
      transition: .2s opacity ease-in-out
      display: flex
      align-items: center

      &:hover
        opacity: 0.4

  &.unread
    .details
      .title
        font-weight: 600
</style>
