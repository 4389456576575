var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "base-settings-groups-list"
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "base.group.show"
    }
  }, [_c('vs-list', {
    attrs: {
      "showHeaders": "",
      "items": _vm.groups,
      "headers": ['Nazwa'],
      "disable-actions": ""
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }