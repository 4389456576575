<template>
  <div class="add-modal">
    <b-modal
      id="modal-settings-base-producer-model-add"
      :title="$t('base.producer.model.modal.add.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('base.producer.model.modal.add.button.cancel')"
      :ok-title="$t('base.producer.model.modal.add.button.ok')"
      size="lg"

      @ok.prevent="createProducerModel"
      v-model="modalAddVisible"

      no-close-on-backdrop
      >
      <vs-loader full compact
        text="Dodawanie modelu..."
        v-if="isLoading"
      />

      <b-card-text>
        <settings-tab-base-producer-form />
      </b-card-text>

      <template #modal-footer="{ ok, cancel }">
        <vs-modal-footer
          disable-on-loading
          :is-loading="isLoading"

          :text-cancel="$t('base.producer.model.modal.add.button.cancel')"
          :text-ok="$t('base.producer.model.modal.add.button.ok')"

          @cancel="cancel"
          @ok="ok"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCardText } from 'bootstrap-vue';
import { inject } from 'vue';
import SettingsTabBaseProducerForm
  from '@/components/views/settings/content-tabs/base/forms/producerModel/SettingsTabBaseProducerModelForm.vue';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';

export default {
  name: 'SettingsTabBaseProducerModelModalAdd',
  setup() {
    const { createProducerModel, isLoading, modalAddVisible } = inject('producersModelsInstance');

    return { createProducerModel, isLoading, modalAddVisible };
  },
  components: {
    SettingsTabBaseProducerForm,
    BModal,
    BCardText,
    VsModalFooter,
    VsLoader,
  },
};
</script>
