<template>
  <div>
    <slot
      name="header"
      :toggleVerticalMenuActive="toggleVerticalMenuActive"
      :toggleCollapsed="toggleCollapsed"
      :collapseTogglerIcon="collapseTogglerIcon"
    >
      <ul class="nav navbar-nav flex-row">

        <!-- Logo & Text -->
        <li class="nav-item mr-auto">
          <b-link
            class="navbar-brand"
            to="/"
          >
            <span class="brand-logo">
              <b-img
                :src="$store.getters['themes/branding'].logo"
                alt="logo"
              />
            </span>
            <h1 class="brand-logo-brand">
                <b-img
                :src="$store.getters['themes/branding'].logoBrand"
                :alt="appName"
              />
            </h1>
          </b-link>
        </li>

        <!-- Toggler Button -->
        <li class="nav-item nav-toggle">
          <b-link class="nav-link modern-nav-toggle">
            <feather-icon
              icon="XIcon"
              size="20"
              class="d-block d-xl-none"
              @click="toggleVerticalMenuActive"
            />
            <feather-icon
              :icon="collapseTogglerIconFeather"
              size="20"
              class="d-none d-xl-block collapse-toggle-icon"
              @click="toggleCollapsed"
            />
          </b-link>
        </li>
      </ul>
    </slot>
  </div>
</template>

<script>
import navMenuItems from '@/navigation/vertical';
import { BLink, BImg } from 'bootstrap-vue';
import { provide, computed, ref } from 'vue';
import useAppConfig from '@core/app-config/useAppConfig';
import { $themeConfig } from '@themeConfig';
import useVerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu';

export default {
  components: {
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  computed: {
    isDark() {
      const { skin } = useAppConfig();
      if (skin.value === 'dark') {
        return this.appLogoImageBrandDark;
      }
      return this.appLogoImageBrand;
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide('isMouseHovered', isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'));

    // App Name
    const {
      appName,
      appLogoImageBrand,
      appLogoImageBrandDark,
      appLogoImage,
    } = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // App Name
      appName,
      appLogoImage,
      appLogoImageBrand,
      appLogoImageBrandDark,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
