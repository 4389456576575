var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `
    vs-loader
    ${_vm.inline ? 'vs-loader--inline' : ''}
    ${_vm.full ? 'vs-loader--full' : ''}
    ${_vm.sticky ? 'vs-loader--sticky' : ''}
    ${_vm.opaque ? 'vs-loader--opaque' : ''}
    ${_vm.compact ? 'vs-loader--compact' : ''}
  `
  }, [_c('div', {
    staticClass: "loader-wrapper"
  }, [_c('div', {
    staticClass: "contents"
  }, [_c('div', {
    staticClass: "spinner"
  }), _vm.text ? _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.text) + " ")]) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }