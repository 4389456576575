import modal from './modal';
import form from './form';
import settings from './settings';

export default {
  pageTitle: 'Inwestycje',
  modal,
  form,
  settings,
  global: {
    field: {
      accepted: 'akceptacja',
      attachment: 'załącznik',
      branch: 'Region',
      count: 'ilość',
      date_end: 'Koniec',
      date_start: 'Start',
      depth: 'głębokość',
      diameter: 'średnica',
      done: 'wykonane',
      work_type: 'Typ robót',
      percentage: 'Wykonane',
      investor: 'Inwestor',
      name: 'Nazwa',
      price: 'cena',
      principal: 'Zleceniodawca',
      provider: 'dostawca',
      producer: 'Producent',
      serial_number: 'Nr. Seryjny',
      mth: 'Stan licznika',
      region: 'Region',
      reinforcement: 'zbrojenie',
      sort: 'rodzaj',
      status: 'Status',
      technology: 'technologia',
      type: 'typ',
    },
  },
  reports: {
    field: {
      report_date: 'Data raportu',
      daily_balance_date: 'Data raportu',
      work_hours: 'Godziny pracy',
      brigade: 'Brygada',
      investment: 'Inwestycja',
      reporter: 'Raportujący',
    },
  },
  fueling: {
    field: {
      amount: 'Ilość',
      date: 'Data dostawy',
      description: 'Uwagi',
      investment: 'Inwestycja',
      provider: 'Dostawca',
      reporter: 'Raportujący',
    },
  },
  button: {
    addInvestment: 'Inwestycja',
  },
  list: {
    pageTitle: 'Lista inwestycji',
  },
  details: {
    pageTitle: 'Szczegóły inwestycji',
  },
};
