import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useTasksApi from '@/hooks/tasks/useTasksApi';

const { fetchStatuses } = useTasksApi();

export default defineStore('taskStatus', () => {
  const {
    state,
    fetch,
    fetched,
    getById,
  } = useRemoteResource('task.status', fetchStatuses);

  return {
    state,
    fetch,
    fetched,
    getById,
  };
});
