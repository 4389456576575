<template>
  <b-dropdown dropright
    class="table-action-button"
    size="sm"
    :variant="dropdownVariant"
    toggle-class="text-decoration-none"
    no-caret
    v-if="securedItems.length > 0"
  >
    <template #button-content>
      <feather-icon
        size="20"
        icon="SettingsIcon"
      />
    </template>

    <b-dropdown-item
      v-for="({ title, icon, callback }, idx) in securedItems" :key="idx"
      class="action-item"
      @click.stop="() => callback(target)"
    >
      <div class="action-item-content">

        <div class="icon">
          <feather-icon
            size="20"
            :icon="`${typeof icon === 'function' ? icon(target) : icon}Icon`"
          />
        </div>

        <div class="text">
          {{ typeof title === 'function' ? title(target) : title }}
        </div>

      </div>

    </b-dropdown-item>

  </b-dropdown>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue';
import store from '@/store';
import { computed } from 'vue';

export default {
  props: {
    target: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      default: () => ([{
        title: 'Edycja',
        icon: 'Edit2',
        callback: () => { },
      }]),
    },
    dropdownVariant: {
      type: String,
      default: 'outline-secondary',
    },
  },
  setup(props) {
    const securedItems = computed(() => props.items
      .filter((item) => {
        if (!item.requiresPermission) return true;
        const perm = item.requiresPermission.split('.');

        return store.getters['auth/userHasPermission'](perm[2], `${perm[0]}.${perm[1]}`);
      }));
    return { securedItems };
  },
  components: {
    BDropdown,
    BDropdownItem,
  },
};
</script>

<style lang="sass" scoped>
.action-item-content
  display: flex
  align-items: center

  .icon
    margin-right: 7px
</style>
