var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('vs-button', {
    attrs: {
      "variant": "outline"
    },
    on: {
      "click": _vm.toggleIntegration
    }
  }, [_c('state-badge', {
    attrs: {
      "state": _vm.isIntegrationEnabled,
      "text": "Integracja z modułem CRM"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }