export default {
  header: 'Zresetuj hasło',
  description: 'Podaj swój email i wybierz mocne hasło, które będzie chronić twoje konto w aplikacji',
  success: {
    header: 'Hasło zmienione pomyślnie',
    description: 'Możesz teraz przejść do strony logowania klikając poniższy przycisk:',
    button: {
      redirectLogin: 'Przejdź do logowania',
    },
  },
  field: {
    email: {
      placeholder: 'Podaj swój adres e-mail',
    },
    password: {
      placeholder: 'Nowe hasło',
    },
    passwordRepeat: {
      placeholder: 'Powtórz hasło',
    },
  },
  button: {
    reset: 'Ustaw hasło',
  },
};
