<template>
  <b-col
    class="vs-form-group"
    :cols="cols"
    v-if="shouldDisplayField"
    >
    <b-form-group
      :label="label"
      :invalid-feedback="fieldError"
      :state="hasErrors"
      :label-cols-md="!noLabel ? labelCols : null"
      class="b-form-group"
      v-if="!hideParentFormGroup"
    >

      <template #label v-if="!integration">
        <div class="label-content-wrapper" v-if="label">
          <span class="label-content-wrapper-text">
            {{ label }}
          </span>

          <span
            class="label-content-wrapper-info"
            v-if="info"
            v-b-popover.hover.top="info"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          >
            <feather-icon
              icon="InfoIcon"
            />
          </span>
        </div>
      </template>

      <template #label v-else>
        <div class="label-content-wrapper">
          <integration-form-label
            :integration="integration"
            :label="label"
          />

          <span
            class="label-content-wrapper-info"
            v-if="info"
            v-b-popover.hover.top="info"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          >
            <feather-icon
              icon="InfoIcon"
            />
          </span>
        </div>
      </template>

      <slot name="default" />

    </b-form-group>

    <b-col
      class="child-groups"
      :offset="labelCols"
      :cols="12 - labelCols"
      v-if="$slots.children"
    >
      <slot name="children" />
    </b-col>
  </b-col>
</template>

<script>
import {
  BCol,
  BFormGroup,
  VBPopover,
} from 'bootstrap-vue';
import {
  computed, inject, ref, watch,
} from 'vue';
import i18n from '@/libs/i18n';
import Ripple from 'vue-ripple-directive';
import IntegrationFormLabel from '../IntegrationFormLabel.vue';

export default {
  name: 'VsFormGroup',
  props: {
    field: String,
    noLabel: Boolean,
    integration: String,
    cols: {
      type: String,
      default: '12',
    },
    labelCols: {
      type: String,
      default: '3',
    },
    forcedLabel: {
      type: String,
    },
    info: {
      type: String,
    },
    catches: {
      type: Array,
      default: () => ([]),
    },
    i18nArgs: {
      type: Object,
      default: () => ({}),
    },
    hideParentFormGroup: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const vsForm = inject('vs-form');

    const shouldDisplayField = computed(() => {
      const { enabledFields } = vsForm.value;
      if (!enabledFields) return true;
      if (props.hideParentFormGroup) return true;
      return (enabledFields.length) && (enabledFields.includes(props.field));
    });

    const fieldTranslation = computed(() => {
      const preferredTranslation = `${vsForm.value.i18nNamespace}.${props.field}`;
      const fallbackTranslation = `${vsForm.value.fallbackI18nNamespace}.${props.field}`;

      if (i18n.te(preferredTranslation)) {
        return i18n.t(preferredTranslation, props.i18nArgs);
      }

      if (i18n.te(fallbackTranslation)) {
        return i18n.t(fallbackTranslation, props.i18nArgs);
      }

      return preferredTranslation;
    });

    const label = computed(() => {
      if (props.forcedLabel) return props.forcedLabel;
      if (props.noLabel) return undefined;

      return fieldTranslation.value;
    });

    const fieldError = ref(null);

    watch(() => vsForm.value.errors?.[props.field], () => {
      if (props.catches.length) {
        const catchedErrors = props.catches.map((c) => vsForm.value.errors?.[c]);

        fieldError.value = catchedErrors?.[0];
      }
      fieldError.value = vsForm.value.errors?.[props.field]
        ?.replace(props.field, fieldTranslation.value);
    });

    const hasErrors = computed(() => typeof fieldError.value !== 'string');

    return {
      fieldError,
      hasErrors,
      label,
      shouldDisplayField,
    };
  },
  components: {
    BCol,
    BFormGroup,
    IntegrationFormLabel,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
};
</script>

<style lang="sass" scoped>
.integration-form-label
  position: relative

.vs-form-group
  padding: 3px initial

  .child-groups
    padding: 0 3px !important
    margin-bottom: 15px

    .child-row
      display: flex
      align-items: flex-start
      gap: 5px

      &::v-deep
        .b-form-group
          width: calc(100% - 5px)

    .vs-form-group
      padding: initial

  .label-content-wrapper
    display: flex
    align-items: center

    &-info
      margin-left: 5px
      cursor: pointer
      transition: 0.2s opacity ease-in-out
      opacity: 0.5
      display: flex
      align-items: center

      &:hover
        opacity: 0.2
</style>
