<template>
  <div>
    <b-tabs
      nav-class="nav-left"
    >
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
          />
          <span class="font-weight-bold">Statusy</span>
        </template>

        <status-management />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Formy zatrudnienia</span>
        </template>

        <employment-forms />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Grupy</span>
        </template>

        <groups />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Stanowiska</span>
        </template>

        <positions />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Kompetencje</span>
        </template>

        <skills />
      </b-tab>
    </b-tabs>

    <modal-add-group/>
    <modal-add-position/>
  </div>
</template>

<script>

import { BTabs, BTab } from 'bootstrap-vue';
import StatusManagement from '@/components/views/settings/content-tabs/cadre/tabs/StatusManagement.vue';
import EmploymentForms from '@/components/views/settings/content-tabs/cadre/tabs/EmploymentFormsManagement.vue';
import Groups from '@/components/views/settings/content-tabs/cadre/tabs/GroupsManagement.vue';
import Positions from '@/components/views/settings/content-tabs/cadre/tabs/PositionsManagement.vue';
import Skills from '@/components/views/settings/content-tabs/cadre/tabs/SkillsManagement.vue';
import ModalAddGroup from '@/components/views/settings/content-tabs/cadre/modals/group/Add.vue';
import ModalAddPosition from '@/components/views/settings/content-tabs/cadre/modals/position/Add.vue';

export default {
  name: 'Tabs',
  components: {
    BTabs,
    BTab,
    StatusManagement,
    EmploymentForms,
    Groups,
    Positions,
    Skills,
    ModalAddGroup,
    ModalAddPosition,
  },
};
</script>
