import CRMFeedback from '@feedback/module/crm/CRMFeedback';

/**
 * Defined Feedback objects.
 *
 * Currently it looks messy, but the whole point of
 * defining each error separately (instead of using plain i18n)
 * is to allow further changes to the Feedback structure, e.g.
 * types, actions, custom templates and more settings.
 *
 * TODO: break this down into separate files whenever the Feedback
 *       logic will be ready
 *
 * After adding a feedback instance below, remember to add the proper
 * key to i18n
 */

/** Negative Feedbacks */
export const CreateCompanyError = new CRMFeedback('CreateCompanyError', false);
export const UpdateCompanyError = new CRMFeedback('UpdateCompanyError', false);
export const DeleteCompanyError = new CRMFeedback('DeleteCompanyError', false);
export const FetchCompanyError = new CRMFeedback('FetchCompanyError', false);

export const CreateServiceError = new CRMFeedback('CreateServiceError', false);
export const UpdateServiceError = new CRMFeedback('UpdateServiceError', false);
export const DeleteServiceError = new CRMFeedback('DeleteServiceError', false);

export const UpdateFinancingError = new CRMFeedback('UpdateFinancingError', false);

export const CreateIndustryError = new CRMFeedback('CreateIndustryError', false);
export const UpdateIndustryError = new CRMFeedback('UpdateIndustryError', false);
export const DeleteIndustryError = new CRMFeedback('DeleteIndustryError', false);

export const CreateContactError = new CRMFeedback('CreateContactError', false);
export const UpdateContactError = new CRMFeedback('UpdateContactError', false);
export const DeleteContactError = new CRMFeedback('DeleteContactError', false);

export const SetPrimaryContactError = new CRMFeedback('SetPrimaryContactError', false);

export const CreateContactPersonError = new CRMFeedback('CreateContactPersonError', false);
export const UpdateContactPersonError = new CRMFeedback('UpdateContactPersonError', false);
export const DeleteContactPersonError = new CRMFeedback('DeleteContactPersonError', false);

export const FetchPickerProvidersError = new CRMFeedback('FetchPickerProvidersError', false);
export const FetchPickerClientsError = new CRMFeedback('FetchPickerClientsError', false);

/** Positive Feedbacks */
export const CreateCompanySuccess = new CRMFeedback('CreateCompanySuccess', true);
export const UpdateCompanySuccess = new CRMFeedback('UpdateCompanySuccess', true);
export const DeleteCompanySuccess = new CRMFeedback('DeleteCompanySuccess', true);

export const CreateServiceSuccess = new CRMFeedback('CreateServiceSuccess', true);
export const UpdateServiceSuccess = new CRMFeedback('UpdateServiceSuccess', true);
export const DeleteServiceSuccess = new CRMFeedback('DeleteServiceSuccess', true);

export const UpdateFinancingSuccess = new CRMFeedback('UpdateFinancingSuccess', true);

export const CreateContactSuccess = new CRMFeedback('CreateContactSuccess', true);
export const UpdateContactSuccess = new CRMFeedback('UpdateContactSuccess', true);
export const DeleteContactSuccess = new CRMFeedback('DeleteContactSuccess', true);

export const CreateIndustrySuccess = new CRMFeedback('CreateIndustrySuccess', true);
export const UpdateIndustrySuccess = new CRMFeedback('UpdateIndustrySuccess', true);
export const DeleteIndustrySuccess = new CRMFeedback('DeleteIndustrySuccess', true);

export const SetPrimaryContactSuccess = new CRMFeedback('SetPrimaryContactSuccess', true);

export const CreateContactPersonSuccess = new CRMFeedback('CreateContactPersonSuccess', true);
export const UpdateContactPersonSuccess = new CRMFeedback('UpdateContactPersonSuccess', true);
export const DeleteContactPersonSuccess = new CRMFeedback('DeleteContactPersonSuccess', true);
