var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('authorization-guard', {
    attrs: {
      "silent": "",
      "permission": "cadre.group.store"
    }
  }, [_c('div', [_c('vs-button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal-cadre-group-add",
      modifiers: {
        "modal-cadre-group-add": true
      }
    }],
    attrs: {
      "vs-variant": "primary"
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t('settings.cadre.groups.button.add')) + " ")])], 1)]), _c('authorization-guard', {
    attrs: {
      "permission": "cadre.group.index"
    }
  }, [_c('table', [_c('thead', [_c('th', [_vm._v("Nazwa")]), _c('th', [_vm._v("Edycja")])]), _c('tbody', _vm._l(_vm.groups, function (group) {
    return _c('tr', {
      key: group.id
    }, [_c('td', [_vm._v(_vm._s(group.name))]), _c('td', {
      staticClass: "edit"
    }, [_c('edit-button', {
      attrs: {
        "items": _vm.editOptions,
        "target": group
      }
    })], 1)]);
  }), 0)])]), _c('modal-edit')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }