<template>
  <div
    :class="`permission-switcher ${disabled ? 'disabled' : '' }`"
  >
    <b-button-group>

      <vs-button
        size="sm"
        vs-variant="light"
        class="permission-switcher-switch permission-switcher-switch-deny"
        :class="`${(state === 'deny') || (inheritAsDeny && state === 'inherit') ? 'active' : ''}`"
        @click="state = 'deny'"
      >
        <feather-icon size="16" icon="XIcon" />
      </vs-button>

      <vs-button
        size="sm"
        vs-variant="light"
        class="permission-switcher-switch permission-switcher-switch-inherit"
        :class="`${state === 'inherit' ? 'active' : ''}`"
        @click="state = 'inherit'"
        v-if="!noInherit"
      >
        <feather-icon size="16" icon="ArrowDownIcon" />
      </vs-button>

      <vs-button
        size="sm"
        vs-variant="light"
        class="permission-switcher-switch permission-switcher-switch-grant"
        :class="`${state === 'grant' ? 'active' : ''}`"
        @click="state = 'grant'"
      >
        <feather-icon size="16" icon="CheckIcon" />
      </vs-button>

    </b-button-group>
  </div>
</template>

<script>
import { VBPopover, BButtonGroup } from 'bootstrap-vue';

import { computed } from 'vue';
import VsButton from '../vs-button/VsButton.vue';

export default {
  name: 'PermissionSwitcher',
  emits: ['change'],
  props: {
    value: {
      type: String,
      default: () => 'inherit',
      validator: (val) => ['deny', 'inherit', 'grant'].includes(val),
    },
    noInherit: {
      type: Boolean,
      default: false,
    },
    inheritAsDeny: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
  },
  setup(props, { emit }) {
    const state = computed({
      get: () => props.value,
      set: (val) => emit('change', val),
    });

    return { state };
  },
  components: {
    BButtonGroup,
    VsButton,
  },
  directives: {
    'b-popover': VBPopover,
  },
};
</script>

<style lang="sass" scoped>
$deny: #df6767
$inherit: var(--vs-card-border)
$grant: #50d985

.permission-switcher
  position: relative
  z-index: 1

  &-switch
    &-deny.active
      background: $deny
      color: darken($deny, 30%) !important

    &-inherit.active
      background: var(--vs-card-bg-l-2)
      color: var(--vs-blue) !important

    &-grant.active
      background: $grant
      color: darken($grant, 30%) !important

  &.disabled
    background: var(--vs-card-bg)

    .permission-switcher-switch
      pointer-events: none
      opacity: 0.3
</style>
