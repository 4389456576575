<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vs-logo />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          {{ $t('error.unauthorized.header') }}
        </h2>
        <p class="mb-2">
          {{ $t('error.unauthorized.description') }}
        </p>

        <div class="lacking-info">
          {{ $t('error.unauthorized.lacking', { permission: $route.params.permission }) }}
        </div>

        <vs-button
          vs-variant="primary"
          @click="$router.push({path:'/'})"
        >
          {{ $t('error.unauthorized.button.backToHome') }}
        </vs-button>

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="Error page"
        />
      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg } from 'bootstrap-vue';
import VsLogo from '@/components/layout/Logo.vue';
import store from '@/store/index';
import VsButton from '@/components/ui/vs-button/VsButton.vue';

export default {
  components: {
    VsLogo,
    BLink,
    BImg,
    VsButton,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg');
        return this.downImg;
      }
      return this.downImg;
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.lacking-info {
  padding-bottom: 20px;
  opacity: 0.6;
}
</style>
