import ApiClient from '@/services/api';

const urls = {
  get: 'api/crm/company/index',
};

export default {
  namespaced: true,

  state: {
    clients: [],
  },

  getters: {
  },

  mutations: {
    SET_CLIENTS(state, clients) {
      state.clients = clients;
    },

    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
  },

  actions: {
    /**
     * Fetch clients list
     * @action fetch=clients
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if clients exist
     * @returns {Promise}
     */
    fetch({ state, commit }, params, force = true) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(urls.get, {
        ...params,
        company_type: 'clients',
      });

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_CLIENTS', res.data.data.companies);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },
  },
};
