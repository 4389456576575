import Vue from 'vue';
import VueI18n from 'vue-i18n';

import pl from './locales/pl';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'pl',
  fallbackLocale: 'pl',
  messages: {
    pl,
  },
});
