<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useCoreUnitStore from '@/stores/module/core/useCoreUnitStore';

export default Vue.component('CoreUnitPicker', {
  emits,
  setup(_, ctx) {
    const store = useCoreUnitStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz jednostkę z listy',
      reduce: (option) => option.id,
      options: store.state,
      ...ctx.attrs,
    }, {
      option: ({ option }) => option.code,
      'selected-option': ({ option }) => option.code,
    }));
  },
  components: { VsPickerVue },
});
</script>
