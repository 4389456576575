<template>
  <abstract-module-label
    v-if="company && companyRoute"
    icon="PackageIcon"
    :to="{ name: companyRoute, params: { id: company.id } }"
    :name="company.name"
    :short="short"
  />
</template>

<script>
import { computed } from 'vue';
import AbstractModuleLabel from '@/components/abstract/AbstractModuleLabel.vue';

export default {
  name: 'CrmCompanyLabel',
  props: {
    // provider | client
    companyType: {
      type: String,
      default: 'client',
    },

    // object taken from integrated field
    integrated: {
      type: Object,
      default: () => ({}),
    },
    short: Boolean,
  },
  setup(props) {
    const company = computed(() => {
      const { id, name } = props.integrated;
      return { id, name };
    });

    const companyRoute = computed(() => {
      let namespace = null;

      switch (props.companyType) {
        case 'provider':
          namespace = 'providers';
          break;
        case 'client':
          namespace = 'clients';
          break;
        default:
          break;
      }

      if (!namespace) return null;
      return `crm.${namespace}.details`;
    });

    return { company, companyRoute };
  },
  components: {
    AbstractModuleLabel,
  },
};
</script>
