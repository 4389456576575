var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "priority-label"
  }, [_c('div', {
    staticClass: "icon",
    style: `color: ${_vm.priority.color}`
  }, [_vm.priority.icon ? _c('feather-icon', {
    attrs: {
      "icon": _vm.priority.icon,
      "size": "18"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "details",
    style: `color: ${_vm.priority.color}`
  }, [_vm._v(" " + _vm._s(_vm.priority.name) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }