import EventComponents from '@/libs/events/components';

/**
 * Global Library for composing timeline events and notifications
 *
 * TODO: Library is currently used only for timeline.
 *       Lib should be adapted and used for notifications
 */

// syntax for translation string used in i18n
const translationString = 'events.{resource}.{event}.{_changed}';

// get specific component if exists
const getValuableChange = function getValuableChange(resource, event, values) {
  if (!EventComponents[resource]) return null;
  if (!(event in EventComponents[resource])) return null;

  const supportedTypes = Object.keys(EventComponents[resource][event]);
  return supportedTypes.filter((change) => change in values);
};

// compose translation based on existing components
const composeTranslation = function composeTranslationString(event, changedValue = null) {
  const translation = translationString
    .replace('{resource}', event.auditable_type)
    .replace('{event}', event.event)
    .replace('{_changed}', changedValue || 'default');

  // return translation without trailing dot
  return translation.replace(/\.$/, '');
};

// create component and populate with props
const createComponent = function createComponent(BaseComponent, event, change = null) {
  const translation = composeTranslation(event, change);

  const component = {
    template: `<base-component translation="${translation}" :event="event" />`,
    components: { BaseComponent },
    data: () => ({ event }),
  };

  return component;
};

// get a whole component for the event
const getEventComponent = function parseEvent(item) {
  // eslint-disable-next-line camelcase
  const { auditable_type, event, new_values } = item;

  const valuable = getValuableChange(auditable_type, event, new_values);
  let component = EventComponents.default;
  let change = null;

  // TODO refactor this
  if (valuable && valuable.length > 0) {
    [change] = valuable; // first matching from priorityTable
    component = EventComponents[auditable_type][event][change];
  } else if (EventComponents[auditable_type] && EventComponents[auditable_type][event]) {
    component = EventComponents[auditable_type][event].default;
  }

  return createComponent(component, item, change);
};

export default getEventComponent;
