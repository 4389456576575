var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "permission-entry-module"
  }, [_c('div', {
    staticClass: "header",
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) return null;
        _vm.collapsed = !_vm.collapsed;
      }
    }
  }, [_c('permission-switcher', {
    attrs: {
      "value": _vm.permissionState,
      "noInherit": _vm.noInherit
    },
    on: {
      "change": _vm.handleResourceStateChange
    }
  }), _c('div', {
    staticClass: "header-text",
    on: {
      "click": function ($event) {
        _vm.collapsed = !_vm.collapsed;
      }
    }
  }, [_c('div', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.moduleTranslation) + " ")]), _vm._v(" " + _vm._s(`${_vm.module}.${_vm.name}.*`) + " ")]), !_vm.hideCount ? _c('div', [_vm._v(" Przyznano " + _vm._s(_vm.enabledActionCount) + " z " + _vm._s(_vm.actions.length) + " uprawnień ")]) : _vm._e(), _c('div', {
    staticClass: "collapse-indicator",
    on: {
      "click": function ($event) {
        _vm.collapsed = !_vm.collapsed;
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": _vm.collapsed ? 'ChevronUpIcon' : 'ChevronDownIcon',
      "size": "22"
    }
  })], 1)], 1), _c('hr'), _c('b-collapse', {
    model: {
      value: _vm.collapsed,
      callback: function ($$v) {
        _vm.collapsed = $$v;
      },
      expression: "collapsed"
    }
  }, [_vm._l(_vm.actions, function (action) {
    return _c('permission-entry-action', {
      key: `${_vm.module}.${_vm.name}.${action}`,
      attrs: {
        "module": _vm.module,
        "resource": _vm.name,
        "name": action,
        "state": _vm.getActionState(action),
        "noInherit": _vm.noInherit
      },
      on: {
        "change": function (state) {
          return _vm.handleActionStateChange(action, state);
        }
      }
    });
  }), _c('hr')], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }