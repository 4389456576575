<template>
  <b-card>
    <b-tabs
      nav-class="nav-left"
    >
      <b-tab active>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Materiały</span>
        </template>

        <settings-tab-investment-materials />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Zbrojenia</span>
        </template>

        <settings-tab-investment-reinforcement />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Typy robót</span>
        </template>

        <settings-tab-investment-work-type />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Grupy</span>
        </template>

        <settings-tab-investment-work-group />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Statusy</span>
        </template>

        <settings-tab-investment-status />
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue';
import SettingsTabInvestmentMaterials from './SettingsTabInvestmentMaterials.vue';
import SettingsTabInvestmentReinforcement from './SettingsTabInvestmentReinforcement.vue';
import SettingsTabInvestmentWorkType from './SettingsTabInvestmentWorkType.vue';
import SettingsTabInvestmentWorkGroup from './SettingsTabInvestmentWorkGroup.vue';
import SettingsTabInvestmentStatus from './SettingsTabInvestmentStatus.vue';

export default {
  name: 'SettingsTabInvestment',
  components: {
    SettingsTabInvestmentMaterials,

    BCard,
    BTabs,
    BTab,
    SettingsTabInvestmentReinforcement,
    SettingsTabInvestmentWorkType,
    SettingsTabInvestmentWorkGroup,
    SettingsTabInvestmentStatus,
  },
};
</script>
