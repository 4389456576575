export default {
  pageTitle: 'Powiadomienia',
  title: {
    header: 'Dziennik powiadomień',
  },
  badge: {
    unread: 'Nowe',
  },
  button: {
    markAsRead: 'Oznacz jako przeczytane',
  },
};
