import Vue from 'vue';
import Vuex from 'vuex';

import ApiClient from '@/services/api';

const endpoint = 'api/orders/get-order-logs';

Vue.use(Vuex);

export default {
  namespaced: true,

  state: {
    currentLogs: [],
    parameters: {
      auditableId: null,
      orderBy: 'DESC',
      select: ['event', 'auditable_type', 'created_at', 'user_id', 'old_values', 'new_values'],
    },
  },

  getters: {
    current: (state) => state.currentLogs,
  },

  mutations: {
    SET_CURRENT_LOGS(state, payload) {
      state.currentLogs = payload;
    },
  },

  actions: {
    fetch({ state, commit }, id) {
      const params = {
        ...state.parameters,
        auditableId: id,
      };
      ApiClient.get(endpoint, params).then((payload) => {
        if (payload.status !== 200) return null;
        return commit('SET_CURRENT_LOGS', payload.data.data);
      });
    },
  },
};
