import ApiClient from '@/services/api';

const urls = {
  get: 'api/cadre/form/index',
  create: 'api/cadre/form/',
  delete: '',
  update: '',
};

export default {
  namespaced: true,

  state: {
    employment_forms: [],
    fetchPromise: null,
  },

  getters: {
    all: (state) => state.employment_forms,
    byId: (state) => (id) => state.employment_forms.find((g) => g.id === id),
  },

  mutations: {
    SET_EMPLOYMENT_FORMS(state, employmentForms) {
      state.employment_forms = employmentForms;
    },
    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
    ADD_EMPLOYMENT_FORM(state, employmentForms) {
      state.employment_forms = [...state.employment_forms, ...employmentForms];
    },
    UPDATE_EMPLOYMENT_FORM(state, employmentForms) {
      const newEmploymentForms = state.employment_forms
        .map((current) => {
          if (current.id !== employmentForms.id) return current;
          return employmentForms;
        });

      state.employment_forms = newEmploymentForms;
    },
    REMOVE_EMPLOYMENT_FORM(state, employmentForm) {
      const newEmploymentForms = state.employment_forms
        .filter((current) => current.id !== employmentForm.id);
      state.employment_forms = [...newEmploymentForms];
    },
  },

  actions: {
    /**
     * Fetch employment_forms list
     * @action fetch=employment_forms
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if employment_forms exist
     * @returns {Promise}
     */
    fetch({ state, commit }, force = false) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(urls.get);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_EMPLOYMENT_FORMS', res.data.data);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },

    create({ commit }, data) {
      const request = ApiClient.post(urls.create, data);

      request.then((res) => {
        if (res.status !== 200) return;
        commit('ADD_EMPLOYMENT_FORM', res.data.data);
      });

      return request;
    },

    /**
     * Remove employment_form
     * @action delete=employment_forms
     * @param {ActionContext} [vuexContext]
     * @returns {Promise}
     * @param employmentForm
     */
    delete({ commit }, employmentForm) {
      const url = urls.delete.replace(':id', employmentForm.id);
      const promise = ApiClient.delete(url);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('REMOVE_EMPLOYMENT_FORM', employmentForm);
      });
      return promise;
    },

    /**
     * Update employment_form
     * @action update=employment_forms
     * @param {ActionContext} [vuexContext]
     * @returns {Promise}
     * @param employmentForm
     */
    update({ commit }, employmentForm) {
      const url = urls.update.replace(':id', employmentForm.id);
      const promise = ApiClient.put(url, employmentForm);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('UPDATE_EMPLOYMENT_FORM', employmentForm);
      });
      return promise;
    },
  },
};
