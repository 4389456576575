var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-horizontal', {
    scopedSlots: _vm._u([{
      key: "navbar",
      fn: function (_ref) {
        var toggleVerticalMenuActive = _ref.toggleVerticalMenuActive;
        return [_c('app-navbar-horizontal-layout-brand'), _c('navbar', {
          attrs: {
            "toggle-vertical-menu-active": toggleVerticalMenuActive
          }
        })];
      }
    }])
  }, [_c('router-view')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }