var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settings-tab-crm-industry"
  }, [_c('div', {
    staticClass: "buttons"
  }, [_c('div', {
    staticClass: "group"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.openAddModal
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t('base.category.button.add')) + " ")])], 1)]), _c('vs-list', {
    attrs: {
      "showHeaders": "",
      "items": _vm.categories,
      "headers": ['Nazwa', 'Raporty', 'Jednostka', 'Typ']
    },
    on: {
      "edit": _vm.openEditModal,
      "delete": _vm.deleteCategory
    },
    scopedSlots: _vm._u([{
      key: "cells",
      fn: function (_ref) {
        var row = _ref.row;
        return [_c('div', {
          staticClass: "vs-list-item-cell"
        }, [_c('examination-badge', {
          attrs: {
            "hideText": "",
            "expired": !row.be_reported
          }
        })], 1), _c('div', {
          staticClass: "vs-list-item-cell"
        }, [_vm.unitById(row.unit_id) ? [_vm._v(" " + _vm._s(_vm.unitById(row.unit_id).name) + " (" + _vm._s(_vm.unitById(row.unit_id).code) + ") ")] : [_vm._v("-")]], 2), _c('div', {
          staticClass: "vs-list-item-cell"
        }, [_c('div', {
          staticClass: "resource-type"
        }, [_vm._v(" " + _vm._s(_vm.$t(`base.global.resourceType.${row.type.slug}`)) + " ")])])];
      }
    }])
  }), _c('settings-tab-base-category-modal-add', {
    on: {
      "ok": _vm.fetch
    }
  }), _c('settings-tab-base-category-modal-edit', {
    on: {
      "ok": _vm.fetch
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }