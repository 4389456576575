var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('layout-vertical', {
    scopedSlots: _vm._u([{
      key: "navbar",
      fn: function (_ref) {
        var toggleVerticalMenuActive = _ref.toggleVerticalMenuActive;
        return [_c('navbar', {
          attrs: {
            "toggle-vertical-menu-active": toggleVerticalMenuActive
          }
        })];
      }
    }, {
      key: "vertical-menu-header",
      fn: function () {
        return [_c('vertical-menu-header', {
          attrs: {
            "is-vertical-menu-active": _vm.isVerticalMenuActive,
            "toggle-vertical-menu-active": _vm.toggleVerticalMenuActive
          }
        })];
      },
      proxy: true
    }, {
      key: "breadcrumb",
      fn: function () {
        return [_c('app-breadcrumb')];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.$t('global.app.footer')) + " "), _c('a', {
          attrs: {
            "href": "https://vs.com.pl"
          }
        }, [_vm._v("Virtual Services")])];
      },
      proxy: true
    }])
  }, [_c('router-view')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }