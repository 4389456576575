<template>
  <div class="settings-tab-base-producers">

    <authorization-guard
      silent
      permission="base.producer.store"
    >
      <div class="buttons">
        <div class="group">
          <vs-button
            vs-variant="primary"
            @click="openAddModal"
          >
            + {{ $t('base.producer.button.add') }}
          </vs-button>
        </div>
      </div>
    </authorization-guard>

    <authorization-guard
      permission="base.producer.index"
    >
      <vs-list showHeaders
        :items="producers"
        @edit="openEditModal"
        @delete="deleteProducer"
        :headers="['Nazwa']"
      />
    </authorization-guard>

    <settings-tab-base-producer-modal-add/>
    <settings-tab-base-producer-modal-edit/>
  </div>
</template>

<script>
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useBaseProducer from '@/hooks/base/assignables/useBaseProducer';
import VsList from '@/components/ui/vs-list/VsList.vue';
import { provide } from 'vue';
import SettingsTabBaseProducerModalAdd
  from '@/components/views/settings/content-tabs/base/modals/producer/SettingsTabBaseProducerModalAdd.vue';
import SettingsTabBaseProducerModalEdit
  from '@/components/views/settings/content-tabs/base/modals/producer/SettingsTabBaseProducerModalEdit.vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';

export default {
  name: 'SettingsTabBaseProducers',
  setup() {
    const producersInstance = useBaseProducer();
    const {
      producers, fetchProducers, openAddModal, openEditModal, deleteProducer,
    } = producersInstance;

    provide('producersInstance', producersInstance);

    fetchProducers();

    return {
      producers,
      openAddModal,
      openEditModal,
      deleteProducer,
    };
  },
  components: {
    SettingsTabBaseProducerModalAdd,
    SettingsTabBaseProducerModalEdit,
    VsList,
    VsButton,
    AuthorizationGuard,
  },
};
</script>
