var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-dropdown-item', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('div', {
    staticClass: "tool"
  }, [_c('div', {
    staticClass: "tool-status"
  }, [_vm.enabled ? _c('feather-icon', {
    attrs: {
      "size": "21",
      "icon": "CheckIcon"
    }
  }) : _c('feather-icon', {
    attrs: {
      "size": "21",
      "icon": "XIcon"
    }
  })], 1), _c('div', {
    staticClass: "tool-name"
  }, [_vm._t("default")], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }