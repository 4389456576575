<template>
  <span class="status-badge" :style="styleVars">
    {{ status.name }}
  </span>
</template>

<script>
import getContrasting from '@/libs/contrast';
import { mapGetters } from 'vuex';

export default {
  name: 'Machine',
  props: {
    status_id: {
      type: Number,
      default: null,
    },
    item: {
      type: Object,
      default: () => ({
        default: true,
        name: 'brak statusu',
        color: '#e0e0e0',
      }),
    },
  },
  computed: {
    ...mapGetters({
      statuses: 'base/statuses/all',
      statusById: 'base/statuses/byId',
    }),
    status() {
      if (!this.status_id) return this.item;

      return this.statusById(this.status_id) || {};
    },
    styleVars() {
      return {
        '--primary-bg': this?.status?.color,
        '--primary-fg': getContrasting(this?.status?.color),
      };
    },
  },
  created() {
    if (!this.status_id && !this.item.default) return;
    this.$store.dispatch('base/statuses/fetch');
  },
};
</script>

<style lang="sass" scoped>
.status-badge
  display: inline-block
  background-color: var(--primary-bg)
  color: var(--primary-fg)
  padding: 3px 10px
  border-radius: 6px
  font-weight: 600
  white-space: nowrap
</style>
