import order from './order';
import ordercomment from './ordercomment';
import task from './task';
import taskcomment from './taskcomment';
import basetransport from './basetransport';

export default {
  order,
  ordercomment,
  task,
  taskcomment,
  basetransport,
};
