<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useCorePriorityStore from '@/stores/module/core/useCorePriorityStore';
import PriorityLabelVue from '@/components/ui/priority-label/PriorityLabel.vue';

export default Vue.component('CorePriorityPicker', {
  emits,
  setup(_, ctx) {
    const store = useCorePriorityStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz priorytet z listy',
      reduce: (option) => option.id,
      options: store.state,
      ...ctx.attrs,
    }, {
      option: ({ option }) => h(PriorityLabelVue, { props: { item: option } }),
      'selected-option': ({ option }) => h(PriorityLabelVue, { props: { item: option } }),
    }));
  },
  components: { VsPickerVue },
});
</script>
