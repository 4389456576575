<template>

  <div
    class="examination-badge"
    :class="`examination-badge--${modifier} ${expired || !expiring ? 'expiring' : ''}`"
    >

    <div class="icon">
      <feather-icon size="21" :icon="`${icon}Icon`" />
    </div>

    <template v-if="!hideText">
      <div class="text-wrapper">
        <div class="front" v-if="alwaysShowDate">
          <div class="text">{{ formatDate.medicalExpiration(date) }}</div>
        </div>
        <div class="front" v-else>
          <div class="text" v-if="expiring && !expired">
            {{ $tc('cadre.medicalExams.field.expiringIn', daysRemaining) }}
          </div>
          <div class="text" v-else-if="!date">{{ $t(translation) }}</div>
          <div class="text" v-else>{{ formatDate.medicalExpiration(date) }}</div>
        </div>

        <div class="bottom">
          <div class="text">{{ formatDate.medicalExpiration(date) }}</div>
        </div>

      </div>
    </template>
  </div>

</template>

<script>
import formatDate, { daysFromToday } from '@/libs/date-formatter';
import { computed } from 'vue';

export default {
  name: 'ExaminationBadge',
  props: {
    state: {
      type: String,
      default: 'upToDate',
    },
    hideText: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: null,
    },
    expired: {
      type: Boolean,
      default: false,
    },
    aboutToExpire: {
      type: Boolean,
      default: false,
    },
    alwaysShowDate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const states = {
      upToDate: {
        icon: 'CheckCircle',
        class: 'up-to-date',
        translation: 'cadre.medicalExams.field.upToDate',
      },
      expiringSoon: {
        icon: 'AlertTriangle',
        class: 'expiring-soon',
        translation: 'cadre.medicalExams.field.expiringSoon',
      },
      outdated: {
        icon: 'XCircle',
        class: 'outdated',
        translation: 'cadre.medicalExams.field.outdated',
      },
    };

    const daysRemaining = computed(() => Math.abs(daysFromToday(props?.date)));

    const state = computed(() => {
      if (props.expired) return 'outdated';
      if (props.aboutToExpire) return 'expiringSoon';
      return 'upToDate';
    });

    const expiring = computed(() => state.value === 'expiringSoon');

    const icon = computed(() => states[state.value].icon);
    const translation = computed(() => states[state.value].translation);
    const modifier = computed(() => states[state.value].class);

    return {
      expiring,
      icon,
      translation,
      modifier,
      daysRemaining,

      formatDate,
    };
  },
};
</script>

<style lang="sass" scoped>

.examination-badge
  display: flex
  align-items: center
  line-height: 1

  &--up-to-date
    color: #31bf5e

  &--outdated
    color: #ed3131

  &--expiring-soon
    color: #edb936

  .icon
    margin-right: 7px

  .text-wrapper
    display: flex
    flex-direction: column
    position: relative
    height: 30px
    width: 100%
    overflow: hidden
    text-overflow: ellipsis

    .bottom,
    .front
      position: absolute
      display: flex
      align-items: center
      top: 0
      height: 30px
      white-space: nowrap
      transition: 0.2s all ease-in-out

      .text
        width: 100%

    .front
      transform: translateY(0)
      opacity: 1
      transition-delay: 0.1s

    .bottom
      transform: translateY(-20%)
      opacity: 0

  &:not(.expiring):hover
    .text-wrapper
      .front
        transform: translateY(20%)
        opacity: 0
        transition-delay: 0s
      .bottom
        transform: translateY(0)
        opacity: 1
        transition-delay: 0.1s
</style>
