<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vs-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <transition name="zoom-fade">
          <password-forgot-successful
            v-if="resetSuccessful"
            @redirect="$router.push({ name: 'login', params: { email: fields.email.value }})"
          />

          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
            v-else
          >

            <router-link :to="{ name: 'login' }" class="d-flex align-items-center mb-1">
              <feather-icon icon="ArrowLeftIcon" size="16" style="margin-right: 5px" />
              {{ $t('passwordForgot.button.back')}}
            </router-link>

            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              {{ $t('passwordForgot.header')}}
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t('passwordForgot.description')}}
            </b-card-text>

            <!-- form -->
            <validation-observer ref="resetPassForm">
              <b-form class="auth-register-form mt-2" id="resetPassForm">
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="register-email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="fields.email.value"
                    name="email"
                    @focusout="validateField('email')"
                    :state="stateField('email')"
                    :placeholder="$t('passwordForgot.field.email.placeholder')"
                  />
                  <small class="text-danger" v-if="hasErrors('email')">{{ errors.email[0] }}</small>
                </b-form-group>

                <input type="hidden" name="token" :value="token">
                <vs-button
                  vs-variant="primary"
                  block
                  type="submit"
                  @click.prevent="submitForgotForm"
                >
                  {{ $t('passwordForgot.button.reset') }}
                </vs-button>
              </b-form>
            </validation-observer>

          </b-col>
        </transition>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver } from 'vee-validate';
import VsLogo from '@/components/layout/Logo.vue';
import {
  BRow,
  BCol,
  BLink,
  BForm,
  // BFormCheckbox,
  BFormGroup,
  BFormInput,
  BImg,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import ApiClient from '@/services/api';
import usePasswordForgot from '@/hooks/auth/usePasswordForgot';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import PasswordForgotSuccessful from '@/components/views/password-reset/PasswordForgotSuccessful.vue';
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@/@core/utils/utils';

export default {
  setup() {
    store.dispatch('auth/logOut');

    const { route } = useRouter();
    const paramEmail = route.value.params?.email;

    const {
      fields,
      errors,
      resetSuccessful,

      hasErrors,
      submitForgotForm,
      stateField,
      validateField,
    } = usePasswordForgot();

    fields.email.value = paramEmail ?? '';

    return {
      fields,
      errors,
      resetSuccessful,

      hasErrors,
      submitForgotForm,
      stateField,
      validateField,
    };
  },
  components: {
    VsLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    // BFormCheckbox,
    BFormGroup,
    BFormInput,
    // validations
    ValidationObserver,
    VsButton,
    PasswordForgotSuccessful,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      token: this.$route.params.token,
      status: '',
      userEmail: '',
      password: '',
      passwordConfirmation: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.resetPassForm.validate().then((success) => {
        if (success) {
          const formData = new FormData(document.getElementById('resetPassForm'));
          ApiClient.post('/reset-password', formData);
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<!--<style lang="scss">-->
<!--@import '~@core/scss/vue/pages/page-auth.scss';-->
<!--</style>-->
