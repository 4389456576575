var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-provider-picker-option"
  }, [_vm.withBranch && _vm.branch ? [_c('feather-icon', {
    staticClass: "crm-provider-picker-option__icon",
    attrs: {
      "icon": "MapPinIcon",
      "size": "14"
    }
  }), _vm._v(" " + _vm._s(_vm.name) + ", " + _vm._s(_vm.branch.name) + " ")] : [_c('feather-icon', {
    staticClass: "crm-provider-picker-option__icon",
    attrs: {
      "icon": "MapPinIcon",
      "size": "14"
    }
  }), _vm._v(" " + _vm._s(_vm.name) + " ")]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }