var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "integration-form-label"
  }, [_c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.left",
      value: _vm.$t(`integration.${_vm.integration}.explanation`),
      expression: "$t(`integration.${integration}.explanation`)",
      modifiers: {
        "hover": true,
        "left": true
      }
    }, {
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "integration-form-label-indicator",
    attrs: {
      "title": _vm.$t(`integration.${_vm.integration}.name`)
    }
  }, [_c('feather-icon', {
    staticClass: "integration-form-label-icon",
    attrs: {
      "icon": _vm.$t(`integration.${_vm.integration}.icon`),
      "size": "14"
    }
  }), _c('span', {
    staticClass: "integration-form-label-indicator-module"
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }