import i18n from '@/libs/i18n';
import Tasks from '@/views/Tasks.vue';
import store from '@/store';

const { prefix } = store.state.tasks;
/**
 * Tasks Tab routes
 */
const tasksRoutes = [
  {
    path: '/tasks',
    name: 'tasks',
    component: Tasks,
    meta: {
      pageTitle: i18n.t('tasks.list.pageTitle'),
      moduleRoute: 'Tasks',
      requiresAuth: true,
      requiresAnyPermission: 'tasks.task',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('tasks.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: `/tasks/${prefix}`,
    name: 'tasks-details',
    component: Tasks,
    meta: {
      pageTitle: i18n.t('tasks.details.pageTitle'),
      navActiveLink: 'tasks',
      showFiltration: false,
      requiresAuth: true,
      requiresAnyPermission: 'tasks.task.show',
      breadcrumb: [
        {
          text: i18n.t('tasks.pageTitle'),
          to: '/tasks',
          active: false,
        },
        {
          text: i18n.t('tasks.details.pageTitle'),
          active: true,
        },
      ],
    },
  },
];

export default tasksRoutes;
