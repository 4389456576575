<template>
  <div class="edit-modal">
    <b-modal
      id="modal-cadre-skill-edit"
      :title="$t('settings.cadre.skills.edit.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('settings.cadre.skills.button.cancel')"
      :ok-title="$t('settings.cadre.skills.button.save')"
      size="lg"
      @show="bindModel"
      @ok.prevent="updateSkill"
      no-close-on-backdrop
      >
      <vs-loader full
        text="Aktualizowanie grupy zatrudnienia..."
        v-if="loading"
      />

      <b-card-text>
        <skill-form v-model="skill" :model="skillModel" :feedback="errors"/>
      </b-card-text>

      <template #modal-footer="{ ok, cancel }">
        <vs-modal-footer
          disable-on-loading
          :is-loading="loading"

          :text-cancel="$t('settings.cadre.skills.button.cancel')"
          :text-ok="$t('settings.cadre.skills.button.save')"

          @cancel="cancel"
          @ok="ok"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BModal, BCardText } from 'bootstrap-vue';
import SkillForm from '@/components/views/settings/content-tabs/cadre/forms/skill/Form.vue';
import useModal from '@/hooks/useModal';
import {
  EditSkillSuccess,
  BadSkillInputError,
  ServerError,
} from '@feedback/module/cadre';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

export default {
  data() {
    return {
      skill: {},
      skillModel: {},
      errors: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      current: 'cadre/skills/byId',
    }),
  },
  methods: {
    bindModel() {
      this.skillModel = useModal().retrieveValues();
    },
    updateSkill(event) {
      this.loading = true;
      const skill = { ...this.skill };

      this.$store.dispatch('cadre/skills/update', {
        ...skill,
      })
        .then(() => {
          this.$showFeedback(EditSkillSuccess);

          this.$bvModal.hide(event.componentId);
          this.loading = false;
          RemoteResourceManager.getInstance().notify('cadre.skill');
        })
        .catch((err) => {
          let Feedback = ServerError;

          if (err?.response?.data?.errors) {
            Feedback = BadSkillInputError;
          }

          this.$showFeedback(Feedback);
          this.loading = false;
          this.errors = err?.response?.data?.errors || {};
        });
    },
  },
  components: {
    BModal,
    BCardText,

    SkillForm,
    VsModalFooter,
    VsLoader,
  },
};
</script>
