<template>
  <div class="investments-create-reinforcement-modal">
    <b-modal
      id="modal-investments-create-reinforcement"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.reinforcement.create.title')"

      v-model="addReinforcementModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.reinforcement.create.loading')"
        v-if="isLoading"
      />

      <investment-reinforcement-form
        v-model="fields"
        :feedback="feedback"
      />

      <template #modal-footer>
        <div class="investment-modal-footer">

          <div class="buttons">
            <vs-button
              variant="outline"
              @click="addReinforcementModal.visible = false"
            >
              {{ $t('investments.modal.reinforcement.create.cancel') }}
            </vs-button>

            <vs-button
              variant="primary"
              @click="addReinforcement"
            >
              {{ $t('investments.modal.reinforcement.create.confirm') }}
            </vs-button>
          </div>

        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import showToast from '@/libs/toasts';
import { CreateReinforcementError, CreateReinforcementSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import InvestmentReinforcementForm from '../forms/InvestmentReinforcementForm.vue';

export default {
  name: 'InvestmentReinforcementModalAdd',
  props: {},
  setup(_props, { emit }) {
    const { addReinforcementModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const {
      fields, feedback, validateAllFields, resetFields, validateField,
      nonEmptyFields, assignServerSideErrors,
    } = useValidatedForm({
      name: 'required',
    }, 'investments.form.reinforcement.field');

    const addReinforcement = async () => {
      const validFields = await validateAllFields();
      if (!validFields.every((field) => field)) return;

      isLoading.value = true;

      useInvestmentsApi()
        .createReinforcement({
          ...nonEmptyFields.value,
        })
        .then(({ data }) => {
          showToast(CreateReinforcementSuccess, { name: data.data.name });
          emit('success', data.data);

          resetFields();
          addReinforcementModal.value.visible = false;
          isLoading.value = false;
        })
        .catch(({ response }) => {
          showToast(CreateReinforcementError);
          assignServerSideErrors(response?.data?.errors);
          isLoading.value = false;
        });
    };

    return {
      fields,
      feedback,
      addReinforcementModal,
      isLoading,
      addReinforcement,
      validateField,
    };
  },
  components: {
    VsLoader,
    VsButton,
    InvestmentReinforcementForm,
  },
};
</script>

<style lang="sass" scoped>
.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between

  .buttons
    margin-left: auto
</style>
