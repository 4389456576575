export default {
  pageTitle: 'Samochody',
  list: {
    pageTitle: 'Lista samochodów',
  },
  settings: {
    pageTitle: 'Ustawienia samochodu',
  },
  details: {
    pageTitle: 'Szczegóły samochodu',
    field: {
      status: 'Status',
      group: 'Grupa',
      user: 'Użytkownik',
      vin: 'VIN',
      year: 'Rok produkcji',
      company: 'Wynajmujący',
      end: 'Koniec leasingu',
      card: 'Nr karty paliwowej',
      gps: 'Panel GPS',
    },
  },
  examination: {
    pageTitle: 'Badania techniczne',
    details: {
      pageTitle: 'Szczegóły badania',
    },
    list: {
      pageTitle: 'Lista badań technicznych',
    },
  },
  insurance: {
    pageTitle: 'Ubezpieczenia',
    fullname: 'Ubezpieczenia samochodów',
    field: {
      phone: 'Nr tel. Assistance',
      name: 'Towarzystwo ubezpieczeniowe',
      police: 'Nr polisy',
      range: 'Zakres ubezpieczenia',
      damages: 'Szkody',
      expiringIn: 'Wygasa dzisiaj | Wygasa jutro | przez {n} dni',
      expiredSince: 'Wygasło wczoraj | Wygasło {n} dni temu',
      file: 'Załącznik',

      date_from: 'Data ubezpieczenia',
      date_to: 'Data ważności',
      car: 'Samochód',
      company: 'Towarzystwo ubezpieczeniowe',
    },
    modal: {
      delete: {
        title: 'Potwierdź usunięcie ubezpieczenia',
        text: 'Czy na pewno chcesz usunąć ubezpieczenie {policy}?',
      },
    },
  },
  field: {
    name: 'Samochód',
    user: 'Użytkownik',
    status: 'Status',
    mileage: 'Przebieg',
    inspection_date: 'Bad. tech.',
    service_date: 'Serwis',
    location: 'Lokalizacja',
  },
  button: {
    addCar: 'Dodaj samochód',
  },
  service: {
    fullname: 'Serwis samochodów',
    pageTitle: 'Serwis',
    field: {
      date: 'Data serwisu',
      dateExam: 'Data badania',
      type: 'Typ',
      status: 'Status',
      reporter: 'Osoba zgłaszająca',
      acceptor: 'Osoba akceptująca',
      resource: 'Zasób',
    },
  },
  mileage: {
    fullname: 'Kilometrówka samochodów',
    pageTitle: 'Kilometrówka',
    field: {
      initialMileage: 'Km w momencie zakupu',
      filedMileage: 'Km w momencie utworzenia kartoteki',

      date: 'Data wyjazdu',
      type: 'Typ',
      purpose: 'Cel wyjazdu',
      mileage: 'Liczba kilometrów',
      car: 'Samochód',
      driver: 'Kierowca',
    },
  },
};
