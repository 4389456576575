<template>
  <div class="task-list">
    <full-list />
  </div>
</template>

<script>
import FullList from './lists/FullList.vue';

export default {
  components: {
    FullList,
  },
};
</script>
