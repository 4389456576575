<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vs-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <transition name="zoom-fade">
          <password-reset-successful
            v-if="resetSuccessful"
            @redirect="$router.push({ name: 'login', params: { email: fields.email.value }})"
          />

          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto"
            v-else
          >
            <b-card-title
              title-tag="h2"
              class="font-weight-bold mb-1"
            >
              {{ $t('passwordReset.header')}}
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t('passwordReset.description')}}
            </b-card-text>

            <!-- form -->
            <validation-observer ref="resetPassForm">
              <b-form class="auth-register-form mt-2" id="resetPassForm">
                <!-- email -->
                <b-form-group
                  label="Email"
                  label-for="register-email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="fields.email.value"
                    name="email"
                    @focusout="validateField('email')"
                    :state="stateField('email')"
                    :placeholder="$t('passwordReset.field.email.placeholder')"
                  />
                  <small class="text-danger" v-if="hasErrors('email')">{{ errors.email[0] }}</small>
                </b-form-group>

                <!-- password -->
                <b-form-group
                  label-for="register-password"
                  label="Hasło"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="fields.password.value"
                      @focusout="validateField('password')"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="stateField('password')"
                      name="password"
                      :placeholder="$t('passwordReset.field.password.placeholder')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger" v-if="hasErrors('password')">
                    {{ errors.password[0] }}
                  </small>
                </b-form-group>

                <!-- password confirm-->
                <b-form-group
                    label-for="register-password"
                    label="Powtórz hasło"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="fields.passwordConfirmation.value"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      @focusout="validateField('passwordConfirmation')"
                      :state="stateField('passwordConfirmation')"
                      name="passwordConfirmation"
                      :placeholder="$t('passwordReset.field.passwordRepeat.placeholder')"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger" v-if="hasErrors('passwordConfirmation')">
                    {{ errors.passwordConfirmation[0] }}
                  </small>
                </b-form-group>

  <!--              <b-form-group>-->
  <!--                <b-form-checkbox-->
  <!--                    id="register-privacy-policy"-->
  <!--                    v-model="status"-->
  <!--                    name="checkbox-1"-->
  <!--                >-->
  <!--                  I agree to-->
  <!--                  <b-link>privacy policy & terms</b-link>-->
  <!--                </b-form-checkbox>-->
  <!--              </b-form-group>-->
                <input type="hidden" name="token" :value="token">
                <vs-button
                  variant="primary"
                  block
                  type="submit"
                  @click.prevent="submitResetForm"
                >
                  {{ $t('passwordReset.button.reset') }}
                </vs-button>
              </b-form>
            </validation-observer>

  <!--          <p class="text-center mt-2">-->
  <!--            <span>Already have an account?</span>-->
  <!--            <b-link :to="{name:'auth-login-v2'}">-->
  <!--              <span>&nbsp;Sign in instead</span>-->
  <!--            </b-link>-->
  <!--          </p>-->

            <!-- divider -->
  <!--          <div class="divider my-2">-->
  <!--            <div class="divider-text">-->
  <!--              or-->
  <!--            </div>-->
  <!--          </div>-->
          </b-col>
        </transition>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver } from 'vee-validate';
import VsLogo from '@/components/layout/Logo.vue';
import {
  BRow,
  BCol,
  BLink,
  BForm,
  // BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import ApiClient from '@/services/api';
import usePasswordReset from '@/hooks/auth/usePasswordReset';
import PasswordResetSuccessful from '@/components/views/password-reset/PasswordResetSuccessful.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils';

export default {
  setup() {
    store.dispatch('auth/logOut');

    const { route } = useRouter();
    const token = route.value.params?.token;

    const {
      isTokenValid,
      fields,
      errors,
      resetSuccessful,

      hasErrors,
      submitResetForm,
      stateField,
      validateField,
    } = usePasswordReset(token);

    return {
      isTokenValid,
      fields,
      errors,
      resetSuccessful,

      hasErrors,
      submitResetForm,
      stateField,
      validateField,
    };
  },
  components: {
    VsLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BForm,
    BCardText,
    BCardTitle,
    // BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationObserver,
    PasswordResetSuccessful,
    VsButton,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      token: this.$route.params.token,
      status: '',
      userEmail: '',
      password: '',
      passwordConfirmation: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.resetPassForm.validate().then((success) => {
        if (success) {
          const formData = new FormData(document.getElementById('resetPassForm'));
          ApiClient.post('/reset-password', formData);
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<!--<style lang="scss">-->
<!--@import '~@core/scss/vue/pages/page-auth.scss';-->
<!--</style>-->
