export default {
  created: {
    default: 'Utworzono zadanie',
  },
  updated: {
    default: 'Zaktualizowano treść zadania',
    status_id: 'Zmieniono status zadania na',
    assigned_id: 'Zmieniono przypisanego użytkownika z ',
    termination_date: 'Zaktualizowano termin wykonania zadania',
  },
};
