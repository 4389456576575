<template>
  <div class="add-modal">
    <b-modal
      id="modal-cadre-position-add"
      :title="$t('settings.cadre.positions.add.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('settings.cadre.positions.button.cancel')"
      :ok-title="$t('settings.cadre.positions.button.save')"
      size="xl"
      v-model="modalVisible"
      @ok.prevent="createPosition"
      no-close-on-backdrop
      >
      <vs-loader full
        text="Tworzenie stanowiska..."
        v-if="loading"
      />

      <b-card-text>
        <position-form
          v-model="position"
          :feedback="errors"
          @update:granted="updateGranted"
        />
      </b-card-text>

      <template #modal-footer="{ ok, cancel }">
        <vs-modal-footer
          :is-loading="loading"

          :text-cancel="$t('settings.cadre.positions.button.cancel')"
          :text-ok="$t('settings.cadre.positions.button.save')"

          @cancel="cancel"
          @ok="ok"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCardText } from 'bootstrap-vue';
import PositionForm from '@/components/views/settings/content-tabs/cadre/forms/PositionForm.vue';
import {
  AddPositionSuccess,
  ServerError,
  BadInputError,
} from '@feedback/module/cadre';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import { ref } from 'vue';
import showToast from '@/libs/toasts';
import store from '@/store';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

export default {
  setup() {
    const position = ref({});
    const errors = ref({});
    const loading = ref(false);
    const denied = ref([]);
    const modalVisible = ref(false);

    const granted = ref([]);
    const updateGranted = (val) => {
      granted.value = val;
    };

    const createPosition = () => {
      loading.value = true;

      store.dispatch('cadre/positions/create', {
        ...position.value,
        permissions: granted.value,
        slug: 'position',
        guard_name: 'web',
      })
        .then(() => {
          showToast(AddPositionSuccess);
          loading.value = false;
          modalVisible.value = false;
          RemoteResourceManager.getInstance().notify('cadre.position');
        })
        .catch((err) => {
          let Feedback = ServerError;
          if (err?.response?.data?.errors) {
            Feedback = BadInputError;
          }

          showToast(Feedback, { id: null });
          loading.value = false;
          errors.value = err?.response?.data?.errors || {};
        });
    };

    return {
      position,
      errors,
      loading,
      granted,
      denied,
      updateGranted,
      createPosition,
      modalVisible,
    };
  },
  components: {
    BModal,
    BCardText,

    PositionForm,
    VsModalFooter,
    VsLoader,
  },
};
</script>
