var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('td', {
    staticClass: "name"
  }, [_c('a', {
    attrs: {
      "href": _vm.attachment.file_link,
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.attachment.original_name) + " ")]), _vm.attachment.isOfferCurrent ? _c('span', [_vm._v("(aktualna oferta)")]) : _vm._e()]), !_vm.hideAccessInfo ? _c('td', {
    staticClass: "access"
  }, [_c('b-button', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: _vm.noAccessText,
      expression: "noAccessText",
      modifiers: {
        "hover": true,
        "top": true
      }
    }, {
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(40, 199, 111, 0.15)',
      expression: "'rgba(40, 199, 111, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "btn-icon rounded-circle",
    attrs: {
      "title": _vm.$t('component.attachments.button.protected.header'),
      "variant": "flat"
    }
  }, [!_vm.attachment.userHasAccess ? _c('feather-icon', {
    attrs: {
      "size": "18",
      "icon": "LockIcon"
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), !_vm.hideDate ? _c('td', {
    class: !_vm.canDelete && 'last-column'
  }, [_vm._v(" " + _vm._s(_vm.formatDate.taskDeadline(_vm.attachment.created_at)) + " ")]) : _vm._e(), _c('td', {
    staticClass: "edit"
  }, [_vm.canDelete ? _c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "flat-secondary"
    },
    on: {
      "click": _vm.removeAttachment
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Trash2Icon",
      "size": "20"
    }
  })], 1) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }