import notifications from './notifications';

export default {
  pageTitle: 'Pulpit',
  title: {
    dashboard: 'Kokpit',
  },
  greeting: {
    heading: 'Witaj, {user}! 🚀',
    body: 'Znajdujesz się w aplikacji {appName}.',
  },

  notifications,
};
