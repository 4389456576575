<template>
  <div class="notifications">
    <b-card :title="$t('home.notifications.title.header')">
      <ul class="statistics">
        <li>Nieprzeczytanych: <span class="value">{{ unreadCount }}</span></li>
        <li>Wszystkich: <span class="value">{{ allCount }}</span></li>
        <li>
          <b-button
            variant="link"
            @click="markAllAsRead"
            v-if="unreadCount > 0"
            >
            Oznacz wszystkie jako przeczytane
          </b-button>
        </li>
      </ul>
      <hr>
      <notification
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
        appearance="history"

        @read="() => markAsRead(notification)"
        @action="() => makeAction(notification)"
        />
    </b-card>
  </div>
</template>

<script>
import { formatNotification } from '@/libs/notifications';
import { mapGetters } from 'vuex';
import { BButton, BCard } from 'bootstrap-vue';
import Notification from '@/components/ui/notification/Notification.vue';

export default {
  components: {
    BButton,
    BCard,

    Notification,
  },
  computed: {
    ...mapGetters({
      notifications: 'auth/notifications/all',
      allCount: 'auth/notifications/allCount',
      unreadCount: 'auth/notifications/unreadCount',
    }),
  },
  methods: {
    formatNotification,
    markAllAsRead() {
      this.$store.dispatch('auth/notifications/markAllAsRead');
    },
    markAsRead(notification) {
      this.$store.dispatch('auth/notifications/markAsRead', notification);
    },
    makeAction(notification) {
      if (this.formatNotification(notification).action) {
        this.formatNotification(notification).action();
      }

      this.markAsRead(notification);
    },
  },
  created() {
    this.$store.dispatch('auth/notifications/fetchAll');
  },
};
</script>

<style lang="sass" scoped>
.statistics
  list-style-type: none
  margin: 0
  padding: 0

  display: flex
  align-items: center

  li
    &:not(:last-child)
      margin-right: 50px

    .value
      display: block
      font-weight: 600
      font-size: 14pt

    &:last-child
      margin-left: auto
</style>
