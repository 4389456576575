<template>
  <div
    :class="{
      'core-user-picker-option': true,
      'core-user-picker-option--deleted': !!user.deleted_at,
    }"
  >
    <avatar
      displayUserIcon
      :user="user"
      v-if="!noAvatar"
      :deleted="!!user.deleted_at"
    />
    <div>
      <div class="core-user-picker-option__name">
        {{ user.forename || '' }} {{ user.surname || ''}}
      </div>
      <div class="core-user-picker-option__proffession" v-if="!noSubtitle">
        <slot name="selectedProfession" :id="user.id">
          {{ user.email }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/ui/Avatar.vue';

export default {
  components: { Avatar },
  name: 'CoreUserPickerOption',
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    noAvatar: {
      type: Boolean,
      default: false,
    },
    noSubtitle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.core-user-picker-option {
  display: flex;
  align-items: center;
  gap: 10px;

  &__proffession {
    opacity: 0.5;
  }

  &__name {
    font-weight: 500;
  }

  &--deleted {
    .core-user-picker-option__name {
      color: #fa4141;
      transition: 0.2s color ease-in-out;
    }
  }
}
</style>
