export default {
  title: 'Modal Title',
  text: 'Modal Text',
  icon: 'info',
  showCancelButton: true,
  confirmButtonText: 'Confirm',
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-outline-danger ml-1',
  },
  buttonsStyling: false,
};
