/**
 * Store Module for managing industries inside Crm
 */

import ApiClient from '@/services/api';

const urls = {
  get: 'api/crm/company/industry/index',
};

export default {
  namespaced: true,

  state: {
    industries: [],
    fetchPromise: null,
  },

  getters: {
    all: (state) => state.industries,
    byId: (state) => (id) => state.industries.find((s) => s.id === id),
  },

  mutations: {
    SET_INDUSTRIES(state, payload) {
      state.industries = payload;
    },
    SET_PROMISE(state, payload) {
      state.fetchPromise = payload;
    },
    UPDATE_STATUS(state, payload) {
      const newIndustries = state.industries.map((s) => {
        if (s.id !== payload.id) return s;
        return payload;
      });

      state.industries = [...newIndustries];
    },
  },

  actions: {
    fetch({ state, commit }) {
      if (state.industries.length) return state.industries;
      if (state.fetchPromise) return state.fetchPromise;

      const request = ApiClient.get(urls.get);

      request.then((res) => {
        if (res.status !== 200) return;
        commit('SET_INDUSTRIES', res.data.data);
      });

      commit('SET_PROMISE', request);

      return request;
    },
  },
};
