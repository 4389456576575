export default {
  /* Errors */
  ModuleFetchError: {
    title: 'Serwer nie odpowiada',
    description: 'Nie udało się otrzymać informacji o stanie aplikacji. Spróbuj ponownie lub skontaktuj się z administratorem',
  },

  PasswordResetTokenError: {
    title: 'Wystąpił błąd podczas resetu hasła',
    description: 'Link resetu hasła wygasł. Skontaktuj się z administratorem',
  },

  PasswordForgotError: {
    title: 'Wystąpił błąd podczas przypominania hasła',
    description: 'Nie udało się wysłać wiadomości e-mail z linkiem. Skontaktuj się z administratorem',
  },

  AuthorizedDevicesFetchError: {
    title: 'Błąd pobierania danych',
    description: 'Nie udało się pobrać listy autoryzowanych urządzeń twojego konta',
  },

  AuthorizedDevicesReauthError: {
    title: 'Błąd generowania kodu uwierzytelniającego',
    description: 'Nie udało się wygenerować kodu autoryzacyjnego dla tego urządzenia.',
  },

  AuthorizedDevicesCreateError: {
    title: 'Wystąpił błąd podczas dodawania urządzenia zaufanego',
    description: 'Nie udało się dodać urządzenia',
  },

  AuthorizedDevicesUpdateError: {
    title: 'Błąd aktualizacji danych urządzenia',
    description: 'Nie udało się zaktualizować autoryzowanego urządzenia',
  },

  AuthorizedDevicesDeleteError: {
    title: 'Nie udało się usunąć zaufanego urządzenia',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  FetchUserPermissionsError: {
    title: 'Nie udało się pobrać informacji o uprawnieniach użytkownika',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  SaveUserPermissionsError: {
    title: 'Nie udało się zapisać uprawnień użytkownika',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateUserAccountError: {
    title: 'Nie udało się utworzyć konta dla pracownika',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  UnauthenticatedError: {
    title: 'Dane logowania wygasły',
    description: 'Zaloguj się ponownie',
  },

  /* Success */
  AuthorizedDevicesCreateSuccess: {
    title: 'Pomyślnie dodano zaufane urządzenie',
    description: '{name}',
  },

  AuthorizedDevicesUpdateSuccess: {
    title: 'Pomyślnie zaktualizowano dane urządzenia',
    description: '{name}',
  },

  AuthorizedDevicesDeleteSuccess: {
    title: 'Pomyślnie usunięto zaufane urządzenie',
    description: '{name}',
  },

  SaveUserPermissionsSuccess: {
    title: 'Pomyślnie zapisano uprawnienia użytkownika',
  },

  CreateUserAccountSuccess: {
    title: 'Pomyślnie utworzono konto dla pracownika',
  },
};
