<template>
  <div>
    <authorization-guard
      silent
      permission="cadre.group.store"
    >
      <div>
        <vs-button
          v-b-modal.modal-cadre-group-add
          vs-variant="primary"
        >
          + {{ $t('settings.cadre.groups.button.add') }}
        </vs-button>
      </div>
    </authorization-guard>

    <authorization-guard
      permission="cadre.group.index"
    >
      <table>
        <thead>
          <th>Nazwa</th>
          <th>Edycja</th>
        </thead>
        <tbody>
          <tr v-for="group in groups" :key="group.id">
            <td>{{ group.name }}</td>
            <td class="edit">
              <edit-button :items="editOptions" :target="group"/>
            </td>
          </tr>
        </tbody>
      </table>
    </authorization-guard>

    <modal-edit />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import EditButton from '@/components/ui/vs-table/TableActionButton.vue';
import ModalEdit from '@/components/views/settings/content-tabs/cadre/modals/group/Edit.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import { deleteModal } from '@/libs/modals';
import useModal from '@/hooks/useModal';
import {
  DeleteGroupSuccess,
  ServerError,
  GroupObjectNotFound,
} from '@feedback/module/cadre';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

export default {
  name: 'StatusManagement',
  components: {
    EditButton,
    ModalEdit,
    VsButton,
    AuthorizationGuard,
  },
  methods: {
    async deleteGroup(group = this.group) {
      const confirmation = await deleteModal({
        title: this.$i18n.t('settings.cadre.groups.modal.delete.title'),
        text: this.$i18n.t('settings.cadre.groups.modal.delete.text', { name: group.name }),
      });
      if (!confirmation) return;

      this.$store.dispatch('cadre/groups/delete', {
        id: group.id,
      })
        .then(() => {
          this.$showFeedback(DeleteGroupSuccess);
          this.loading = false;
          RemoteResourceManager.getInstance().notify('cadre.group');
        })
        .catch((err) => {
          let Feedback = ServerError;
          if (err?.response?.data?.errors) {
            Feedback = GroupObjectNotFound;
          }

          this.$showFeedback(Feedback);
          this.loading = false;
          this.errors = err?.response?.data?.errors;
        });
    },
    editGroup(group) {
      this.openEditModal({ ...group });
    },
    currentGroup(id) {
      return this.current(id);
    },
  },
  computed: {
    ...mapGetters({
      groups: 'cadre/groups/all',
      current: 'cadre/groups/byId',
    }),
    groupEditModel: {
      get() {
        if (!this.currentGroup.provider) {
          return {
            ...this.currentGroup,
          };
        }

        return {
          ...this.currentGroup,
        };
      },
    },
  },
  setup(_props, context) {
    const { openModal } = useModal();

    const openEditModal = (position) => {
      openModal('modal-cadre-group-edit', context, {
        ...position,
      });
    };

    return { openEditModal };
  },
  data() {
    return {
      group: {},
      editOptions: [
        {
          title: this.$t('global.button.edit'),
          icon: 'CheckCircle',
          callback: this.editGroup,
        },
        {
          title: this.$t('global.button.delete'),
          icon: 'Trash2',
          callback: this.deleteGroup,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch('cadre/groups/fetch');
  },
};
</script>

<style lang="sass" scoped>
table
  width: 100%
  margin-top: 25px

  td
    padding: 10px 0

    &.expiry-date
      display: flex
      align-items: center
      color: #65b259

      &.expired
        color: #b2595a

      .icon
        margin-right: 10px

  tr:not(:last-child)
    td
      border-bottom: 1px solid rgba(58, 59, 61, 0.3)
  thead
    border-bottom: 2px solid rgba(1, 4, 9, 0.3)
.action-buttons
  position: absolute
  bottom: 15px

.action-button
  margin: 10px 0

.box-color
  height: 30px
  width: 100px
</style>
