var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-provider-branch-person-picker-option"
  }, [_c('feather-icon', {
    staticClass: "crm-provider-branch-person-picker-option__icon",
    attrs: {
      "icon": "UserIcon",
      "size": "14"
    }
  }), _vm._v(" " + _vm._s(_vm.provider.forename) + " " + _vm._s(_vm.provider.surname) + " "), _c('div', {
    staticClass: "crm-provider-branch-person-picker-option__position"
  }, [_vm._v(" (" + _vm._s(_vm.provider.position) + ") ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }