<template>
  <b-card class="table-wrapper">
    <authorization-guard
      permission="orders.order.index"
      silent
    >
      <div
        class="card shadow-none bg-transparent border-secondary"
        v-if="filtrationEnabled"
        >
        <div class="card-body">
          <h4 class="card-title">
            {{ $t('global.title.filtering') }}
          </h4>
          <b-row>
            <b-col cols="4">
              <b-form-group
                :label="$t('orders.list.filter.region')"
                >
                <core-branch-picker multiple
                  v-model="branchFilter"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                :label="$t('orders.list.filter.provider')"
                >
                <template #label>
                  <integration-form-label
                    integration="crm_address_relation"
                    :label="$t('orders.list.filter.provider')"
                  />
                </template>
                <crm-provider-picker multiple
                  v-model="providerFilter"
                  :reduce="(company) => company.id"
                  :option='filterParams.providers'
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                :label="$t('orders.list.filter.investor')"
                >
                <template #label>
                  <integration-form-label
                    integration="crm_address_relation"
                    :label="$t('orders.list.filter.investor')"
                  />
                </template>
                <crm-client-picker
                  multiple
                  v-model="investorFilter"
                  :reduce="(company) => company.id"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                :label="$t('orders.list.filter.investment')"
                >
                <template #label>
                  <integration-form-label
                    integration="investment_investment_relation"
                    :label="$t('orders.list.filter.investment')"
                  />
                </template>
                <investments-investment-picker
                  v-model="investmentFilter"
                  multiple
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                :label="$t('orders.list.filter.status')"
                >
                <orders-status-picker multiple
                  v-model="statusFilter"
                  />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group
                :label="$t('orders.edit.field.priority')"
                >
                <core-priority-picker multiple
                  v-model="priorityFilter"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <a href="#" @click="clearAllFilters">
            {{ $t('global.table.clearAllFilters') }}
          </a>
        </div>
      </div>
    </authorization-guard>

    <div class="buttons">
      <div class="group">
        <vs-button
          @click="openCreateModal"
          vs-variant="primary"
          v-can:store="'orders.order'"
        >
          + {{ $t('global.button.order') }}
        </vs-button>
      </div>
    </div>
    <br>

    <authorization-guard
      permission="orders.order.index"
    >
      <vs-table
        mode="remote"
        :columns="displayedColumns"
        :rows="orders"
        :totalRecords="totalRecords"
        :checkSort="columnSortOption"
        :perPage="serverParams.perPage"
        :isLoading="isLoading"
        @on-row-click="openDetails"
        @searchTermChange="(term) => searchTerm = term"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange"
        @on-sort-change="changeSorting"
        >
        <template v-slot:row="props" >
          <order-label
            :id="props.row.id"
            :title="props.row.order_number"
            v-if="props.column.field === 'order_number'"
            />
          <status-badge orders
            v-else-if="props.column.field === 'status_id'"
            :status_id="props.row.status_id"
            />
          <span v-else-if="props.column.field === 'priority_id'">
            <priority-label :id="props.row.priority_id" />
          </span>
          <span v-else-if="props.column.field === 'ordered_at'">
            {{ formatDate.taskDeadline(props.row.ordered_at) }}
          </span>
          <span v-else-if="props.column.field === 'objects'">
            {{ totalPrice(props.row.subjects, props.row.id) }}
            {{ currencyById(props.row.currency_id).code }}
          </span>
          <span v-else-if="props.column.field === 'branch_id'">
            {{ branchById(props.row.branch_id)?.shortcut }}
          </span>

          <span v-else-if="props.column.field === 'investment'">
            <investments-investment-label
              nowrap
              v-if="props.row.investment.id"
              :investment="props.row.investment"
            />
            <span v-else-if="props.row.investment.name">{{ props.row.investment.name }}</span>
          </span>

          <span v-else-if="props.column.field === 'provider'">
            <crm-company-label
              :integrated="props.row.crm_provider_address_contact.company_address.company"
              v-if="props.row.crm_provider_address_contact
                && props.row.crm_provider_address_contact.company_address"
              short
            />
          </span>

        </template>
      </vs-table>
    </authorization-guard>

  </b-card>
</template>

<script>
import formatDate from '@/libs/date-formatter';
import { mapGetters } from 'vuex';
import {
  BCol,
  BRow,
  BCard,
  BFormGroup,
} from 'bootstrap-vue';
import ApiClient from '@/services/api';
import OrderLabel from '@/components/views/orders/Label.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import StatusBadge from '@/components/ui/status-badge/StatusBadge.vue';
import PriorityLabel from '@/components/ui/priority-label/PriorityLabel.vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import OrdersStatusPicker from '@/components/module/orders/OrdersStatusPicker.vue';
import CoreBranchPicker from '@/components/module/core/CoreBranchPicker.vue';
import CorePriorityPicker from '@/components/module/core/CorePriorityPicker.vue';
import InvestmentsInvestmentPicker from '@/components/module/investments/InvestmentsInvestmentPicker.vue';
import IntegrationFormLabel from '@/components/ui/IntegrationFormLabel.vue';
import CrmProviderPicker from '@/components/module/crm/CrmProviderPicker.vue';
import useOrdersModals from '@/hooks/orders/useOrdersModals';
import useCoreBranchStore from '@/stores/module/core/useCoreBranchStore';
import CrmClientPicker from '@/components/module/crm/CrmClientPicker.vue';
import CrmCompanyLabel from '@/components/module/crm/CrmCompanyLabel.vue';
import InvestmentsInvestmentLabel from '../../investments/InvestmentsInvestmentLabel.vue';

export default {
  setup() {
    const { modalCreateOrder } = useOrdersModals();
    const openCreateModal = () => {
      modalCreateOrder.value.open();
    };

    const branchStore = useCoreBranchStore();

    return {
      openCreateModal,
      branchById: (id) => branchStore.getById(id),
    };
  },
  data() {
    return {
      formatDate,
      searchTerm: '',
      searchTermTimeout: null,
      tableOptions: {
        columns: [
          {
            label: this.$t('orders.list.field.order_number'),
            field: 'order_number',
            showInDetails: true,
          },
          {
            label: this.$t('orders.list.field.title'),
            field: 'title',
            showInDetails: true,
          },
          {
            label: this.$t('orders.list.field.date'),
            field: 'ordered_at',
            showInDetails: true,
          },
          {
            label: this.$t('orders.list.field.status'),
            field: 'status_id',
            showInDetails: true,
          },
          {
            label: this.$t('orders.list.field.priority'),
            field: 'priority_id',
          },
          {
            label: this.$t('orders.list.field.totalPrice'),
            field: 'objects',
          },
          {
            label: this.$t('orders.list.field.provider'),
            field: 'provider',
          },
          /**
          {
            label: this.$t('orders.list.field.investor'),
            field: 'investor.name',
          },
          */
          {
            label: this.$t('orders.list.field.investment'),
            field: 'investment',
          },
          {
            label: this.$t('orders.list.field.region'),
            field: 'branch_id',
          },
        ],
      },
      filterParams: [],
      filteredUsers: [],
    };
  },
  created() {
    this.$store.dispatch('orders/fetch');
    const self = this;
    ApiClient.get('api/orders/get-params-for-filtrating').then((({ data }) => {
      self.filterParams = data.data;
    }));
  },
  computed: {
    ...mapGetters({
      orders: 'orders/all',
      isLoading: 'orders/isLoading',
      currencyById: 'currencies/byId',
      serverParams: 'orders/serverParams',
      totalRecords: 'orders/totalRecords',
      columnSortOption: 'orders/columnSort',
      totalPriceOfProvided: 'orders/subject/totalPriceOfProvided',
    }),
    displayedColumns() {
      if (this.$route.name !== 'orders-details') return this.tableOptions.columns;

      return this.tableOptions.columns.filter((column) => column.showInDetails);
    },
    filtrationEnabled() {
      return this.$route.meta.showFiltration;
    },
    statusFilter: {
      get() {
        return this.$store.state.orders.fetchParams.columnFilters.statuses_id;
      },

      set(value) {
        this.$store.dispatch('orders/updateColumnFilter', { statuses_id: value });
      },
    },
    priorityFilter: {
      get() {
        return this.$store.state.orders.fetchParams.columnFilters.priorities_id;
      },

      set(value) {
        this.$store.dispatch('orders/updateColumnFilter', { priorities_id: value });
      },
    },

    investmentFilter: {
      get() {
        return this.$store.state.orders.fetchParams.columnFilters.investments_id;
      },

      set(value) {
        this.$store.dispatch('orders/updateColumnFilter', { investments_id: value });
      },
    },

    investorFilter: {
      get() {
        return this.$store.state.orders.fetchParams.columnFilters.investors_id ?? [];
      },

      set(value) {
        this.$store.dispatch('orders/updateColumnFilter', { investors_id: value });
      },
    },

    providerFilter: {
      get() {
        return this.$store.state.orders.fetchParams.columnFilters.providers_id;
      },

      set(value) {
        this.$store.dispatch('orders/updateColumnFilter', { providers_id: value });
      },
    },

    branchFilter: {
      get() {
        return this.$store.state.orders.fetchParams.columnFilters.branches_id || [];
      },

      set(value) {
        this.$store.dispatch('orders/updateColumnFilter', { branches_id: value });
      },
    },

  },
  methods: {
    totalPrice(subject, id) {
      const total = this.totalPriceOfProvided(subject, id);

      if (!total) return 0;
      return total.toLocaleString('ru-RU');
    },
    calcSum(item) {
      return item.price * item.quantity;
    },
    changeSorting(data) {
      const { field, type } = data[0];
      this.$store.dispatch('orders/updateColumnSorting', { field, type });
    },
    clearAllFilters(e) {
      e.preventDefault();
      this.updateFilter({
        priorities_id: null,
        statuses_id: null,
        branches_id: [],
        providers_id: [],
        investors_id: [],
        investments_id: [],
      });
    },
    onPageChange(param) {
      this.$store.dispatch('orders/updatePageSettings', param);
      this.$store.dispatch('orders/fetch');
    },
    openDetails(data) {
      this.$router.push({ name: 'orders-details', params: { id: data.row.id } });
    },
    updateFilter(param) {
      this.$store.dispatch('orders/updateColumnFilter', param);
    },
    updateSearchTerm(param) {
      const self = this;
      clearTimeout(this.searchTermTimeout);
      this.searchTermTimeout = setTimeout(() => {
        self.$store.dispatch('orders/updateSearchTerm', param);
        self.$store.dispatch('orders/fetch');
      }, 500);
    },
  },
  watch: {
    searchTerm() {
      this.updateSearchTerm(this.searchTerm);
    },
  },
  components: {
    BCol,
    BRow,
    BCard,
    BFormGroup,

    VsButton,
    VsTable,
    StatusBadge,
    OrderLabel,
    PriorityLabel,
    AuthorizationGuard,
    InvestmentsInvestmentLabel,
    CrmCompanyLabel,
    OrdersStatusPicker,
    CoreBranchPicker,
    CorePriorityPicker,
    InvestmentsInvestmentPicker,
    IntegrationFormLabel,
    CrmProviderPicker,
    CrmClientPicker,
  },
};
</script>
<style lang="sass" scoped>
.table-wrapper
  overflow-x: auto
.border-secondary
  border: 1px solid #ebe9f1 !important
</style>
