<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
    @mousemove="mouseMove"
    @mouseup="mouseUp"
  >

    <!-- FIXME: development purposes, move to own generic component -->
    <div class="sample-notification" v-if="permissionsOverriden">
      <div class="title">
        Przeglądasz aplikację ze zmodyfikowanymi uprawnieniami
      </div>

      <vs-button @click="$store.commit('auth/SET_OVERRIDEN_PERMISSIONS', [])">
        Przywróć domyślne
      </vs-button>
    </div>

    <component :is="layout">
      <router-view />
    </component>

    <vs-permission-manager-float-editor
      v-if="developmentMode"
    />
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig';
import { computed, watch } from 'vue';
import useAppConfig from '@core/app-config/useAppConfig';

import { useWindowSize, useCssVar } from '@vueuse/core';

import store from '@/store';

import LayoutVertical from '@/layouts/vertical/LayoutVertical.vue';
import LayoutHorizontal from '@/layouts/horizontal/LayoutHorizontal.vue';
import LayoutFull from '@/layouts/full/LayoutFull.vue';
import useMousePosition from '@/hooks/useMousePosition';
import VsPermissionManagerFloatEditor from './components/ui/vs-permission-manager/VsPermissionManagerFloatEditor.vue';
import VsButton from './components/ui/vs-button/VsButton.vue';
import { registerApiObserver } from './services/api';
import router from './router';
import showToast from './libs/toasts';
import { UnauthenticatedError } from './libs/toasts/feedback/module/core';

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
    VsPermissionManagerFloatEditor,
    VsButton,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full';
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
    developmentMode() {
      return process.env.NODE_ENV === 'development';
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2));
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout');

    registerApiObserver(401, () => {
      const currentRoute = router.currentRoute.name;
      store.dispatch('auth/logOut').then(() => {
        showToast(UnauthenticatedError);
        router.push({
          name: 'login',
          params: { requested: currentRoute },
        });
      });
    });

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val);
    });

    const permissionsOverriden = computed(() => store.getters['auth/permissionsOverriden']);

    const { x, y, notify } = useMousePosition();
    const mouseMove = ({ clientX, clientY }) => {
      x.value = clientX;
      y.value = clientY;
    };

    const mouseUp = (e) => {
      notify('mouseup', e);
    };

    return {
      skinClasses,
      permissionsOverriden,
      mouseMove,
      mouseUp,
    };
  },
};
</script>

<style lang="sass">
.sample-notification
  background: var(--vs-blue)
  color: rgba(255, 255, 255, 0.9)
  font-weight: 500
  position: sticky
  top: 0
  z-index: 100
  display: flex
  align-items: center
  padding: 0 20px

.swal2-title
  font-size: 14pt !important
  font-weight: 600 !important

.Vue-Toastification__toast.top-right
  top: 80px !important
  right: 10px !important
</style>
