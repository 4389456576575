var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header-section', {
    attrs: {
      "title": `${_vm.hideHeader ? '' : 'Oś czasu'}`
    }
  }, [_c('div', {
    staticClass: "timeline-wrapper"
  }, [_c('app-timeline', _vm._l(_vm.items, function (item) {
    return _c('app-timeline-item', {
      key: item.id,
      attrs: {
        "user_id": item.user_id,
        "title": item.event,
        "time": item.created_at
      }
    }, [_c('div', {
      staticClass: "timeline-item-header d-flex flex-row justify-content-between mb-1 mb-sm-0"
    }, [_c('h6', [_c(_vm.translation(item), {
      tag: "component"
    })], 1), _c('small', {
      staticClass: "timeline-item-time text-nowrap text-muted",
      domProps: {
        "textContent": _vm._s(_vm.date(item.created_at))
      }
    })]), _c('user-label', {
      attrs: {
        "id": item.user_id
      }
    })], 1);
  }), 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }