var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "examination-badge",
    class: `examination-badge--${_vm.modifier} ${_vm.expired || !_vm.expiring ? 'expiring' : ''}`
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('feather-icon', {
    attrs: {
      "size": "21",
      "icon": `${_vm.icon}Icon`
    }
  })], 1), !_vm.hideText ? [_c('div', {
    staticClass: "text-wrapper"
  }, [_vm.alwaysShowDate ? _c('div', {
    staticClass: "front"
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.formatDate.medicalExpiration(_vm.date)))])]) : _c('div', {
    staticClass: "front"
  }, [_vm.expiring && !_vm.expired ? _c('div', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.$tc('cadre.medicalExams.field.expiringIn', _vm.daysRemaining)) + " ")]) : !_vm.date ? _c('div', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.$t(_vm.translation)))]) : _c('div', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.formatDate.medicalExpiration(_vm.date)))])]), _c('div', {
    staticClass: "bottom"
  }, [_c('div', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.formatDate.medicalExpiration(_vm.date)))])])])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }