import { buildRequest } from '@/services/api';

const prefix = 'api/orders';

const useOrdersApi = () => ({
  /* Status */
  status: {
    fetch: () => buildRequest('get',
      `${prefix}/statuses`),
  },
});

export default useOrdersApi;
