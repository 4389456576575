<template>
  <vs-modal
    :modalInstance="modalEditTask"
    @ok="updateTask"
  >
    <tasks-form
      v-model="fields"
      :feedback="feedback"
    />
  </vs-modal>
</template>

<script>
import TasksForm from '@/components/views/tasks/Form.vue';
import { EditSuccess, ServerError, BadInputError } from '@feedback/module/tasks';
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import useTasksModals from '@/hooks/tasks/useTasksModals';
import { watch } from 'vue';
import store from '@/store';
import formatDate from '@/libs/date-formatter';
import showToast from '@/libs/toasts';

export default {
  setup() {
    const { modalEditTask } = useTasksModals();
    const {
      fields, feedback, setFieldDefaults, areAllFieldsValid,
      resetFields, assignServerSideErrors, assignFields,
    } = useValidatedForm({
      id: 'required',
      title: 'required',
      priority_id: 'required',
      assigned_id: 'required',
      termination_date: 'required',
      description: 'required',
      status_id: 'required',
      created_by_id: 'required',
    });

    setFieldDefaults({
      priority_id: 2,
      status_id: 1,
      assigned_id: store.getters['auth/user'].id,
      termination_date: formatDate.standard(Date()),
    });

    watch(() => modalEditTask.value.visible, () => {
      resetFields();
      assignFields(modalEditTask.value.data);
    });

    const updateTask = async () => {
      if (!await areAllFieldsValid()) return;
      modalEditTask.value.isLoading = true;

      store.dispatch('tasks/update', {
        ...fields.value,
      })
        .then(({ data }) => {
          showToast(EditSuccess, {
            label: store.getters['tasks/label'](data.data.id),
            name: data.data.title,
          });

          modalEditTask.value.close();
        })
        .catch((err) => {
          const { response } = err;
          assignServerSideErrors(response?.data?.errors);

          let Feedback = ServerError;
          if (response.data.errors) {
            Feedback = BadInputError;
          }
          showToast(Feedback);
        })
        .finally(() => {
          modalEditTask.value.isLoading = false;
        });
    };

    return {
      modalEditTask, updateTask, fields, feedback,
    };
  },
  components: {
    TasksForm,
    VsModal,
  },
};
</script>
