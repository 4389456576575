var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `permission-switcher ${_vm.disabled ? 'disabled' : ''}`
  }, [_c('b-button-group', [_c('vs-button', {
    staticClass: "permission-switcher-switch permission-switcher-switch-deny",
    class: `${_vm.state === 'deny' || _vm.inheritAsDeny && _vm.state === 'inherit' ? 'active' : ''}`,
    attrs: {
      "size": "sm",
      "vs-variant": "light"
    },
    on: {
      "click": function ($event) {
        _vm.state = 'deny';
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "16",
      "icon": "XIcon"
    }
  })], 1), !_vm.noInherit ? _c('vs-button', {
    staticClass: "permission-switcher-switch permission-switcher-switch-inherit",
    class: `${_vm.state === 'inherit' ? 'active' : ''}`,
    attrs: {
      "size": "sm",
      "vs-variant": "light"
    },
    on: {
      "click": function ($event) {
        _vm.state = 'inherit';
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "16",
      "icon": "ArrowDownIcon"
    }
  })], 1) : _vm._e(), _c('vs-button', {
    staticClass: "permission-switcher-switch permission-switcher-switch-grant",
    class: `${_vm.state === 'grant' ? 'active' : ''}`,
    attrs: {
      "size": "sm",
      "vs-variant": "light"
    },
    on: {
      "click": function ($event) {
        _vm.state = 'grant';
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "size": "16",
      "icon": "CheckIcon"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }