import Theme from '@/themes';

export default {
  namespaced: true,
  state: {
    theme: new Theme(process.env.VUE_APP_THEME ?? 'default'),
  },
  getters: {
    data: (state) => state.theme?.data,
    company: (state) => state.theme?.data?.company,
    modules: (state) => state.theme?.data?.modules,
    css: (state) => state.theme?.css,

    branding: (state, _, rootState) => {
      const mode = rootState.appConfig.layout.skin === 'dark' ? 'dark' : 'default';
      return state.theme.branding[mode];
    },
  },
  mutations: {

  },
  actions: {

  },
};
