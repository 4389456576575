var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vs-permission-manager-float-editor"
  }, [_c('div', {
    class: `editor ${_vm.shown && 'editor--visible'}`
  }, [_c('div', {
    staticClass: "header"
  }, [_c('h3', [_vm._v("Edytor uprawnień")]), _c('vs-button', {
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": function ($event) {
        _vm.shown = false;
      }
    }
  }, [_vm._v(" Zamknij ")])], 1), _c('div', {
    staticClass: "show-all-nav"
  }, [_c('div', {
    staticClass: "checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.ignoreNavPermissions,
      expression: "ignoreNavPermissions"
    }],
    attrs: {
      "id": "show-all-cb",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.ignoreNavPermissions) ? _vm._i(_vm.ignoreNavPermissions, null) > -1 : _vm.ignoreNavPermissions
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.ignoreNavPermissions,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && (_vm.ignoreNavPermissions = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.ignoreNavPermissions = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.ignoreNavPermissions = $$c;
        }
      }
    }
  })]), _c('label', {
    staticClass: "text",
    attrs: {
      "for": "show-all-cb"
    }
  }, [_vm._v(" Pokaż wszystkie działy w nawigacji (bez względu na posiadane permisje) ")])]), !_vm.showEditor ? _c('vs-loader') : _c('vs-permission-manager', {
    attrs: {
      "fetched": _vm.userPermissions,
      "no-inherit": "",
      "stickTop": "",
      "hideCount": ""
    },
    model: {
      value: _vm.permissions,
      callback: function ($$v) {
        _vm.permissions = $$v;
      },
      expression: "permissions"
    }
  })], 1), _c('vs-button', {
    staticClass: "show-editor-button",
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": function () {
        _vm.shown = true;
        _vm.showEditor = true;
      }
    }
  }, [_vm._v(" Pokaż edytor uprawnień ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }