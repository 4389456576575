<template>
  <div class="add-modal">
    <b-modal
      id="modal-cadre-skill-add"
      :title="$t('settings.cadre.skills.add.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('settings.cadre.skills.button.cancel')"
      :ok-title="$t('settings.cadre.skills.button.save')"
      size="lg"
      @ok="createSkill"
      no-close-on-backdrop
      >
      <vs-loader full
        text="Tworzenie grupy zatrudnienia..."
        v-if="loading"
      />

      <b-card-text>
        <skill-form v-model="skill" :feedback="errors"/>
      </b-card-text>

      <template #modal-footer="{ ok, cancel }">
        <vs-modal-footer
          disable-on-loading
          :is-loading="loading"

          :text-cancel="$t('settings.cadre.skills.button.cancel')"
          :text-ok="$t('settings.cadre.skills.button.save')"

          @cancel="cancel"
          @ok="ok"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCardText } from 'bootstrap-vue';
import SkillForm from '@/components/views/settings/content-tabs/cadre/forms/skill/Form.vue';
import {
  AddSkillSuccess,
  ServerError,
  BadSkillInputError,
} from '@feedback/module/cadre';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

export default {
  components: {
    BModal,
    BCardText,

    SkillForm,
    VsLoader,
    VsModalFooter,
  },
  data() {
    return {
      skill: {},
      errors: {},
      loading: false,
    };
  },
  methods: {
    createSkill(event) {
      event.preventDefault();
      this.loading = true;

      this.$store.dispatch('cadre/skills/create', {
        ...this.skill,
      })
        .then(() => {
          this.$showFeedback(AddSkillSuccess);
          this.$bvModal.hide(event.componentId);
          this.loading = false;
          RemoteResourceManager.getInstance().notify('cadre.skill');
        })
        .catch((err) => {
          let Feedback = ServerError;
          if (err?.response?.data?.errors) {
            Feedback = BadSkillInputError;
          }

          this.$showFeedback(Feedback, { id: null });
          this.loading = false;
          this.errors = err?.response?.data?.errors || {};
        });
    },
  },
};
</script>
