var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "widget-card-controls"
  }, [_c('b-button-group', [_vm.can(_vm.editPermission) && !_vm.hideEdit ? _c('vs-button', {
    staticClass: "edit-button",
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('edit');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "Edit2Icon"
    }
  }), _vm._v(" Edytuj ")], 1) : _vm._e(), _vm.can(_vm.deletePermission) ? _c('vs-button', {
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.$emit('delete');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Trash2Icon"
    }
  })], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }