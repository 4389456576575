import Vue from 'vue';
import Vuex from 'vuex';

import ApiClient from '@/services/api';

const endpoint = 'api/tasks/statuses';

Vue.use(Vuex);

export default {
  namespaced: true,

  state: {
    statuses: [],
    fetchPromise: null,
  },

  getters: {
    all: (state) => state.statuses,
    byId: (state) => (id) => state.statuses.filter((status) => status.id === Number(id))[0],
    title: (state) => (id) => state.statuses.filter((status) => status.id === Number(id))[0].name,
  },

  mutations: {
    SET_STATUSES(state, payload) {
      state.statuses = payload;
    },
    SET_PROMISE(state, payload) {
      state.fetchPromise = payload;
    },
  },

  actions: {
    fetch({ state, commit }) {
      if (state.statuses.length) return state.statuses;
      if (state.fetchPromise) return state.fetchPromise;

      const promise = ApiClient.get(endpoint).then((payload) => {
        if (payload.status !== 200) return null;
        return commit('SET_STATUSES', payload.data.data);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },
  },
};
