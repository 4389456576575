import i18n from '@/libs/i18n';
import Orders from '@/views/Orders.vue';
import store from '@/store';

const { prefix } = store.state.orders;
/**
 * Orders Tab routes
 */
const ordersRoutes = [
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
    meta: {
      pageTitle: i18n.t('orders.list.pageTitle'),
      moduleRoute: 'Orders',
      requiresAuth: true,
      requiresAnyPermission: 'orders.order',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('orders.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: `/orders/${prefix}`,
    name: 'orders-details',
    component: Orders,
    meta: {
      pageTitle: i18n.t('orders.details.pageTitle'),
      navActiveLink: 'orders',
      requiresAuth: true,
      requiresAnyPermission: 'orders.order.show',
      breadcrumb: [
        {
          text: i18n.t('orders.pageTitle'),
          to: '/orders',
          active: false,
        },
        {
          text: i18n.t('orders.details.pageTitle'),
          active: true,
        },
      ],
    },
  },
];

export default ordersRoutes;
