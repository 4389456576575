export default {
  pageTitle: 'CRM',
  list: {
    pageTitle: 'Lista',
  },
  address: {
    modal: {
      deletePerson: {
        title: 'Potwierdź usunięcie osoby kontaktowej',
        text: 'Osoba kontaktowa "{name}" zostanie trwale usunięta. Czy na pewno chcesz kontynuować?',
      },
      deleteAddress: {
        title: 'Potwierdź usunięcie adresu firmy',
        text: 'Adres firmy "{name}" zostanie trwale usunięty. Czy na pewno chcesz kontynuować?',
      },
    },
  },
  industry: {
    button: {
      add: 'Nowa branża',
    },
    field: {
      name: 'Nazwa branży',
    },
    modal: {
      delete: {
        title: 'Potwierdź usunięcie branży',
        text: 'Branża "{name}" zostanie trwale usunięta. Czy na pewno chcesz kontynuować?',
      },
      add: {
        title: 'Dodawanie branży',
        button: {
          ok: 'Dodaj nową branżę',
          cancel: 'Anuluj',
        },
      },
      edit: {
        title: 'Aktualizowanie branży',
        button: {
          cancel: 'Anuluj',
          ok: 'Aktualizuj branżę',
        },
      },
    },
  },
  service: {
    field: {
      name: 'Nazwa usługi',
    },
    button: {
      add: 'Nowa usługa',
    },
    modal: {
      delete: {
        title: 'Potwierdź usunięcie usługi',
        text: 'Usługa "{name}" zostanie trwale usunięta. Czy na pewno chcesz kontynuować?',
      },
      add: {
        title: 'Dodawanie usługi',
        button: {
          cancel: 'Anuluj dodawanie',
          ok: 'Dodaj nową usługę',
        },
      },
      edit: {
        title: 'Aktualizowanie usługi',
        button: {
          cancel: 'Anuluj',
          ok: 'Aktualizuj usługę',
        },
      },
    },
  },
  company: {
    edit: {
      title: 'Edycja firmy',
      button: {
        save: 'Edytuj firmę',
        cancel: 'Anuluj',
      },
    },
    modal: {
      delete: {
        title: 'Potwierdź usunięcie firmy',
        text: 'Firma "{name}" zostanie trwale usunięta. Czy na pewno chcesz kontynuować?',
      },
    },
    add: {
      title: 'Tworzenie firmy',
      button: {
        save: 'Utwórz firmę',
        cancel: 'Anuluj',
      },
    },
    field: {
      name: 'Nazwa firmy',
      region: 'Region',
      status: 'Status',
      industry: 'Branża',
      industries: 'Branża',
      regions: 'Region',
      nip: 'NIP',
      regon: 'REGON',
      krs: 'KRS',
      website: 'Strona internetowa',
      protected_workplace: 'Miejsce pracy chronionej',
      financing: {
        dateOfPayment: 'Termin płatności',
        creditLimit: 'Limit kredytowy',
        usedCreditLimit: 'Wykorzystany limit kredytowy',
      },
    },
    address: {
      field: {
        branch: 'Oddział',
        street: 'Ulica',
        postalCode: 'Kod pocztowy',
        city: 'Miasto',
        phone: 'Numer telefonu',
        email: 'Adres e-mail',
        streetNumber: 'Numer ulicy',
      },
      person: {
        field: {
          email: 'Adres e-mail',
          gdpr: 'RODO',
          forename: 'Imię',
          surname: 'Nazwisko',
          phone: 'Numer telefonu',
          position: 'Stanowisko',
        },
      },
    },
  },
  companies: {
    field: {
      name: 'Nazwa firmy',
      region: 'Region',
      status: 'Status',
      industry: 'Branża',
      services: 'Usługi',
    },
  },
  providers: {
    pageTitle: 'Dostawcy',
    details: {
      pageTitle: 'Szczegóły dostawcy',
    },
    list: {
      pageTitle: 'Lista dostawców',
    },
    settings: {
      pageTitle: 'Ustawienia',
    },
    button: {
      add: 'Dostawca',
    },
  },
  clients: {
    pageTitle: 'Klienci',
    details: {
      pageTitle: 'Szczegóły klienta',
    },
    list: {
      pageTitle: 'Lista klientów',
    },
    settings: {
      pageTitle: 'Ustawienia',
    },
    button: {
      add: 'Klient',
    },
  },
};
