import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useCadreApi from '@/hooks/cadre/useCadreApi';

const { group } = useCadreApi();

export default defineStore('cadreGroup', () => {
  const {
    state, fetch, fetched, getById,
  } = useRemoteResource('cadre.group', group.fetch);

  const create = (newGroup) => {
    const request = group.create(newGroup);

    request.then(({ data }) => {
      state.value.push(data);
    });

    return request;
  };

  return {
    state,
    fetch,
    fetched,
    getById,

    create,
  };
});
