var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "toastification"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('notification', {
    attrs: {
      "notification": _vm.notification,
      "appearance": "toast"
    },
    on: {
      "action": _vm.makeAction
    }
  }), _c('b-button', {
    staticClass: "close-notification",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close-toast');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "XIcon",
      "size": "24"
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }