<template>
  <div class="employee-form">
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('settings.cadre.skills.field.name')"
            label-cols-md="3"
            :invalid-feedback="errors.name"
            :state="errors.name === null"
            >
            <b-input-group>
              <b-form-input
                :placeholder="$t('settings.cadre.skills.field.name')"
                v-model="objectModel.name"/>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BCol,
  BRow,

  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
} from 'bootstrap-vue';

export default {
  components: {
    BCol,
    BRow,

    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
  },
  watch: {
    feedback() {
      const errors = this.feedback;
      if (!errors) return;

      Object.keys(errors).forEach((key) => {
        if (errors[key].length > 0) {
          [errors[key]] = errors[key];
        }
      });

      this.errors = errors;
    },
    objectModel: {
      handler() {
        this.$emit('input', this.objectModel);
      },
      deep: true,
    },
  },
  props: {
    model: {
      type: Object,
      default: () => ({
        name: null,
      }),
    },
    value: {
      type: Object,
    },
    feedback: {
      type: Object,
    },
  },
  data() {
    return {
      objectModel: { ...this.model },
      errors: {},
    };
  },
};
</script>

<style lang="sass" scoped>
.employee-form
  form
    padding: 30px 45px

    @media screen and (max-width: 991px)
      padding: 0px 0px

.employee-form::v-deep
  .vs-wysiwyg
    margin-top: 15px
</style>
