var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "unauthorized-pane"
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "EyeOffIcon",
      "size": "32"
    }
  }), _c('div', {
    staticClass: "title"
  }, [_vm._v(" Brak dostępu ")]), _c('div', {
    staticClass: "explanation"
  }, [_vm._v(" Nie masz uprawnień do tej części aplikacji. Jeżeli uważasz, że powinieneś/powinnaś mieć dostęp do tej sekcji, skontaktuj się z administratorem strony. ")]), _c('div', {
    staticClass: "permission"
  }, [_vm._v(" " + _vm._s(_vm.permission) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }