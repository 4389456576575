export default {
  pageTitle: 'Serwis',
  list: {
    pageTitle: 'Lista serwisów',
  },
  details: {
    pageTitle: 'Szczegóły serwisu',
    field: {
      date: 'Data serwisu',
      nextDate: 'Data następnego serwisu',
      resource: 'Zasób',
      status: 'Status',
      reporter: 'Raportujący',
      accepting: 'Akceptujący',
      meter_status: 'Przebieg',
      next_meter_status: 'Następny przebieg',
    },
    daysSince: 'dzisiaj | wczoraj | {n} dni temu',
    daysTill: 'dzisiaj | jutro | za {n} dni',
    expired: 'termin minął {n} dzień temu | termin minął {n} dni temu',

    meterStatusLeft: 'lub za {value} {unit}',
  },
  type: {
    technical: 'Awaria',
    registration: 'Badanie techniczne',
    inspection: 'Przegląd okresowy',
  },
  modal: {
    delete: {
      title: 'Potwierdź usunięcie serwisu',
      text: 'Czy na pewno chcesz usunąć ten serwis? Tej akcji nie można cofnąć.',
    },
  },
};
