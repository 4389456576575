var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "auth-wrapper auth-v2"
  }, [_c('b-row', {
    staticClass: "auth-inner m-0"
  }, [_c('b-link', {
    staticClass: "brand-logo"
  }, [_c('vs-logo')], 1), _c('b-col', {
    staticClass: "d-none d-lg-flex align-items-center p-5",
    attrs: {
      "lg": "8"
    }
  }, [_c('div', {
    staticClass: "w-100 d-lg-flex align-items-center justify-content-center px-5"
  }, [_c('b-img', {
    attrs: {
      "fluid": "",
      "src": _vm.imgUrl,
      "alt": "Register V2"
    }
  })], 1)]), _c('b-col', {
    staticClass: "d-flex align-items-center auth-bg px-2 p-lg-5",
    attrs: {
      "lg": "4"
    }
  }, [_c('transition', {
    attrs: {
      "name": "zoom-fade"
    }
  }, [_vm.resetSuccessful ? _c('password-forgot-successful', {
    on: {
      "redirect": function ($event) {
        return _vm.$router.push({
          name: 'login',
          params: {
            email: _vm.fields.email.value
          }
        });
      }
    }
  }) : _c('b-col', {
    staticClass: "px-xl-2 mx-auto",
    attrs: {
      "sm": "8",
      "md": "6",
      "lg": "12"
    }
  }, [_c('router-link', {
    staticClass: "d-flex align-items-center mb-1",
    attrs: {
      "to": {
        name: 'login'
      }
    }
  }, [_c('feather-icon', {
    staticStyle: {
      "margin-right": "5px"
    },
    attrs: {
      "icon": "ArrowLeftIcon",
      "size": "16"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('passwordForgot.button.back')) + " ")], 1), _c('b-card-title', {
    staticClass: "font-weight-bold mb-1",
    attrs: {
      "title-tag": "h2"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('passwordForgot.header')) + " ")]), _c('b-card-text', {
    staticClass: "mb-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('passwordForgot.description')) + " ")]), _c('validation-observer', {
    ref: "resetPassForm"
  }, [_c('b-form', {
    staticClass: "auth-register-form mt-2",
    attrs: {
      "id": "resetPassForm"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "register-email"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "register-email",
      "name": "email",
      "state": _vm.stateField('email'),
      "placeholder": _vm.$t('passwordForgot.field.email.placeholder')
    },
    on: {
      "focusout": function ($event) {
        return _vm.validateField('email');
      }
    },
    model: {
      value: _vm.fields.email.value,
      callback: function ($$v) {
        _vm.$set(_vm.fields.email, "value", $$v);
      },
      expression: "fields.email.value"
    }
  }), _vm.hasErrors('email') ? _c('small', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(_vm.errors.email[0]))]) : _vm._e()], 1), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "token"
    },
    domProps: {
      "value": _vm.token
    }
  }), _c('vs-button', {
    attrs: {
      "vs-variant": "primary",
      "block": "",
      "type": "submit"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.submitForgotForm.apply(null, arguments);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('passwordForgot.button.reset')) + " ")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }