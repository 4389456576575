<template>
  <div class="crm-provider-branch-picker-option">
    <template v-if="provider.branch">

      <feather-icon
        icon="MapPinIcon"
        class="crm-provider-branch-picker-option__icon"
        size="14"
      />
      {{ name }}

      <feather-icon
        icon="MailIcon"
        class="crm-provider-branch-picker-option__icon
        crm-provider-branch-picker-option__icon--branch"
        size="14"
      />
      {{ provider.branch.name }}

    </template>

    <template v-else>
      <feather-icon
        icon="MapPinIcon"
        class="crm-provider-branch-picker-option__icon"
        size="14"
      />
      <div class="crm-provider-branch-picker-option__unselectable">
        {{ name }} (uzupełnij oddział)
      </div>
    </template>

  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'CrmProviderBranchPickerOption',
  props: {
    provider: Object,
  },
  setup(props) {
    const name = computed(() => props.provider.name);

    return { name };
  },
};
</script>

<style lang="scss" scoped>
.crm-provider-branch-picker-option {
  display: flex;
  align-items: center;
  gap: 5px;

  &__unselectable {
    opacity: 0.4;
  }

  &__icon {
    opacity: 0.4;

    &--branch {
      margin-left: 5px;
    }
  }
}
</style>
