var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile-settings-devices"
  }, [_c('vs-form-header', {
    attrs: {
      "withButton": "",
      "text": "Powiązane urządzenia",
      "button-text": "+ Dodaj powiązane urządzenie"
    },
    on: {
      "action": function ($event) {
        _vm.modalAddAuthorizedDevice.visible = true;
      }
    }
  }), _c('div', {
    staticClass: "devices-list"
  }, _vm._l(_vm.devices, function (device) {
    return _c('div', {
      key: device.id,
      staticClass: "device"
    }, [_c('div', {
      staticClass: "device-category"
    }, [_c('feather-icon', {
      attrs: {
        "icon": "SmartphoneIcon"
      }
    }), _vm._v(" Urządzenie mobilne ")], 1), _c('div', {
      staticClass: "device-name"
    }, [_vm._v(" " + _vm._s(device.name) + " ")]), _c('div', {
      staticClass: "device-description"
    }, [_c('div', {
      staticClass: "device-description-parameter"
    }, [_c('div', {
      staticClass: "device-description-parameter-header"
    }, [_vm._v(" Utworzono ")]), _c('div', {
      staticClass: "device-description-parameter-content"
    }, [_vm._v(" " + _vm._s(_vm.formatDate.deviceDate(device.created_at)) + " ")])]), device.updated_at !== device.created_at ? _c('div', {
      staticClass: "device-description-parameter"
    }, [_c('div', {
      staticClass: "device-description-parameter-header"
    }, [_vm._v(" Zmieniono ")]), _c('div', {
      staticClass: "device-description-parameter-content"
    }, [_vm._v(" " + _vm._s(_vm.formatDate.deviceDate(device.updated_at)) + " ")])]) : _vm._e()]), _c('div', {
      staticClass: "device-buttons"
    }, [_c('vs-button', {
      attrs: {
        "vs-variant": "link"
      },
      on: {
        "click": function () {
          return _vm.openConnectModal(device);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "SidebarIcon"
      }
    }), _vm._v(" Kod autoryzacyjny ")], 1), _c('vs-button', {
      attrs: {
        "vs-variant": "link"
      },
      on: {
        "click": function () {
          return _vm.openEditModal(device);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "Edit2Icon"
      }
    }), _vm._v(" Zmień nazwę ")], 1), _c('vs-button', {
      attrs: {
        "vs-variant": "link"
      },
      on: {
        "click": function () {
          return _vm.deleteDevice(device);
        }
      }
    }, [_c('feather-icon', {
      attrs: {
        "icon": "Trash2Icon"
      }
    }), _vm._v(" Usuń urządzenie ")], 1)], 1)]);
  }), 0), _c('profile-settings-devices-add-modal', {
    on: {
      "success": _vm.fetchDevices
    }
  }), _c('profile-settings-devices-edit-modal', {
    on: {
      "success": _vm.updateDevice
    }
  }), _c('profile-settings-devices-connect-modal', {
    on: {
      "success": _vm.updateDevice
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }