<template>
  <div class="permission-entry-module">

    <div
      :class="`header ${stickTop && 'stick-top'}`"
      @click.self="collapsed = !collapsed"
    >
      <permission-switcher
        :value="getPermissionState(`${name}.*`)"
        @change="(type) => togglePermission(`${name}.*`, type)"
        :noInherit="noInherit"
      />

      <div class="header-text" @click="collapsed = !collapsed">
        <div class="name">
          {{ moduleTranslation }}
        </div>

        {{ `${name}.*` }}
      </div>

      <div v-if="!hideCount">
        Przyznano {{ enabledPermissionsCount }} z {{ totalPermissionsCount }} uprawnień
      </div>

      <div class="collapse-indicator" @click="collapsed = !collapsed">
        <feather-icon
          :icon="collapsed ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          size="22"
        />
      </div>
    </div>

    <b-collapse v-model="collapsed">
      <permission-entry-resource
        v-for="(resource, idx) in Object.keys(resources)"
        :key="`resource-${idx}`"
        :module="name"
        :name="resource"
        :actions="resources[resource]"

        :state="newWay"

        @change="handlePermissionStateChange"
        :hideCount="hideCount"
        :noInherit="noInherit"
      />
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue';
import { computed, ref } from 'vue';
import i18n from '@/libs/i18n';
import usePermissions from '@/hooks/usePermissions';
import PermissionSwitcher from './PermissionSwitcher.vue';
import PermissionEntryResource from './PermissionEntryResource.vue';

export default {
  name: 'PermissionEntryModule',
  emits: ['update', 'ready'],
  props: {
    name: String,
    resources: Object,
    fetched: {
      // FIXME temporary workaround
      type: Array,
      default: () => [],
    },
    stickTop: {
      type: Boolean,
      default: false,
    },
    hideCount: {
      type: Boolean,
      default: false,
    },
    noInherit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { getModulePermissions, getModuleWildcards, getPermissionChildren } = usePermissions();
    const moduleWildcards = getModuleWildcards(props.name);

    const collapsed = ref(false);
    const moduleTranslation = computed(() => i18n.t(`permission.${props.name}.name`));

    const newWay = ref(getModulePermissions(props.name));

    const emitUpdate = () => {
      const enabled = newWay.value.filter((n) => n.state === true);
      const disabled = newWay.value.filter((n) => n.state === false);

      emit('update', { enabled, disabled });
    };

    const getPermByKey = (key) => newWay.value.find((p) => p.name === key);

    const togglePermission = (perm, type) => {
      const permission = getPermByKey(perm);

      if (type === 'inherit') {
        permission.state = null;
        emitUpdate();
        return;
      }

      newWay.value = newWay.value.map((p) => {
        if (p.name === perm) return p;
        emitUpdate();
        return { ...p, state: null };
      });

      permission.state = type === 'grant';
      emitUpdate();
    };

    const getPermissionState = (perm) => {
      const { state } = getPermByKey(perm);
      if (state === null) return 'inherit';
      return state ? 'grant' : 'deny';
    };

    const stateOfWildcards = () => {
      const { state } = getPermByKey(`${props.name}.*`);
      // eslint-disable-next-line max-len
      const enabled = moduleWildcards
        .every((wildcard) => getPermByKey(wildcard.name).state === true);
      if (enabled || state === true) return 'grant';

      const disabled = moduleWildcards
        .every((wildcard) => getPermByKey(wildcard.name).state === false);
      if (disabled || state === false) return 'deny';
      return 'inherit';
    };

    const handlePermissionStateChange = (perm, state) => {
      const modulePermission = getPermByKey(`${props.name}.*`);

      const maps = { inherit: null, grant: true, deny: false };
      const permission = getPermByKey(perm);
      const permissionStateCache = modulePermission.state;

      permission.state = maps[state];

      emitUpdate();

      if (state !== modulePermission.state) {
        const wildcardsState = stateOfWildcards();

        if (permission.state !== modulePermission.state && wildcardsState !== 'inherit') {
          moduleWildcards.forEach((w) => {
            const p = getPermByKey(w.name);
            if (w.name === perm) return;
            if (p.state === permission.state) return;
            p.state = permissionStateCache;
          });
        }
        modulePermission.state = null;
      }
      const wildcardsState = stateOfWildcards();

      emitUpdate();
      if (wildcardsState === 'inherit') return;
      togglePermission(`${props.name}.*`, wildcardsState);
    };

    const totalPermissionsCount = computed(() => newWay.value.length - moduleWildcards.length - 1);

    const enabledPermissionsCount = computed(() => {
      if (getPermByKey(`${props.name}.*`).state === true) {
        return totalPermissionsCount.value;
      }

      return moduleWildcards.reduce((total, wildcard) => {
        const properWildcard = getPermByKey(wildcard.name);

        return total + getPermissionChildren(wildcard.name)?.reduce((acc, c) => {
          if (properWildcard.state === true) return acc + 1;
          const child = getPermByKey(c.name);
          if (child.state === true) return acc + 1;
          return acc;
        }, 0) ?? 0;
      }, 0);
    });

    props.fetched.forEach((f) => {
      const toggleType = f.disabled ? 'deny' : 'grant';
      if (f.name === `${props.name}.*`) {
        togglePermission(f.name, toggleType);
        return;
      }
      handlePermissionStateChange(f.name, toggleType);
    });

    return {
      moduleTranslation,
      collapsed,
      togglePermission,
      getPermissionState,
      handlePermissionStateChange,
      enabledPermissionsCount,
      totalPermissionsCount,
      newWay,
    };
  },
  components: {
    BCollapse,
    PermissionSwitcher,
    PermissionEntryResource,
  },
  mounted() {
    this.$emit('ready');
  },
};
</script>

<style lang="sass" scoped>
.header
  display: flex
  align-items: center
  gap: 20px
  font-size: 9pt
  line-height: 1.2
  padding: 20px 10px
  position: sticky
  top: 80px
  z-index: 10
  background: var(--vs-card-bg)
  border-bottom: 1px solid var(--vs-card-border)
  margin-bottom: 15px

  &.stick-top
    top: 0px

  .name
    font-size: 12pt
    font-weight: 500

  .collapse-indicator
    margin-left: auto
</style>
