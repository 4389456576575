import Vue from 'vue';

import defaultTemplate from './default';
import deleteTemplate from './delete';

const templates = {
  default: defaultTemplate,
  delete: deleteTemplate,
};

export const showModal = function showModal(template = templates.default) {
  return Vue.swal(template);
};

export const deleteModal = function deleteModal(params) {
  const config = {
    ...templates.delete,
    ...params,
  };

  return showModal(config).then(({ isConfirmed }) => isConfirmed);
};

export default showModal;
