export default {
  namespaced: true,
  state: {
    statuses: [
      { name: 'Gotowy do wysłania', slug: 'readyToSend', color: '#25b2e6' },
      { name: 'Wysłany', slug: 'sent', color: '#ff8d29' },
      { name: 'Niewysłany', slug: 'notSent', color: '#e63225' },
      { name: 'Dostarczony', slug: 'delivered', color: '#31c458' },
      { name: 'Niedostarczony', slug: 'notDelivered', color: '#992d25' },
    ],
  },

  getters: {
    all: (state) => state.statuses,
    getStatusBySlug: (state) => (slug) => state.statuses
      .find((status) => status.slug === slug),
  },
};
