var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settings-tab-base-producers"
  }, [_c('authorization-guard', {
    attrs: {
      "silent": "",
      "permission": "base.producer.store"
    }
  }, [_c('div', {
    staticClass: "buttons"
  }, [_c('div', {
    staticClass: "group"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.openAddModal
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t('base.producer.button.add')) + " ")])], 1)])]), _c('authorization-guard', {
    attrs: {
      "permission": "base.producer.index"
    }
  }, [_c('vs-list', {
    attrs: {
      "showHeaders": "",
      "items": _vm.producers,
      "headers": ['Nazwa']
    },
    on: {
      "edit": _vm.openEditModal,
      "delete": _vm.deleteProducer
    }
  })], 1), _c('settings-tab-base-producer-modal-add'), _c('settings-tab-base-producer-modal-edit')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }