<template>
  <div class="cadre-group-label">
    {{ label || '-' }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['id'],
  computed: {
    ...mapGetters({
      group: 'cadre/groups/byId',
    }),
    label() {
      return this.group(this.id)?.name;
    },
  },

  created() {
    if (!this.id) return;
    this.$store.dispatch('cadre/groups/fetch');
  },
};
</script>

<style lang="sass" scoped>
.prefix
  font-weight: 600
</style>
