var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vs-modal"
  }, [_c('b-modal', {
    attrs: {
      "size": _vm.size,
      "title": _vm.text.title,
      "hide-footer": !_vm.footerVisible,
      "no-close-on-backdrop": _vm.noCloseOnBackdrop
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('vs-modal-footer', {
          attrs: {
            "is-loading": _vm.isLoading,
            "text-cancel": _vm.text.cancel,
            "text-ok": _vm.text.ok,
            "noConfirm": _vm.noConfirm
          },
          on: {
            "ok": function ($event) {
              return _vm.$emit('ok');
            },
            "cancel": function ($event) {
              _vm.computedVisibility = false;
            }
          },
          scopedSlots: _vm._u([{
            key: "left",
            fn: function () {
              return [_vm._t("modal-footer-left")];
            },
            proxy: true
          }], null, true)
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.computedVisibility,
      callback: function ($$v) {
        _vm.computedVisibility = $$v;
      },
      expression: "computedVisibility"
    }
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": _vm.text.loader
    }
  }) : _vm._e(), _vm._t("default", null, {
    "data": _vm.data
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }