import useLocalStorage from '@/hooks/useLocalStorage';
import { $themeConfig } from '@themeConfig';

const isCollapsed = useLocalStorage('menuCollapsed', $themeConfig.layout.menu.isCollapsed);

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: isCollapsed.value === 'true',
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      isCollapsed.value = val;
      state.isVerticalMenuCollapsed = val;
    },
  },
  actions: {},
};
