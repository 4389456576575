import i18n from '@/libs/i18n';

/**
 * Cadre Tab routes
 */
const cadreRoutes = [
  {
    path: '/crm',
    redirect: '/crm/providers',
    meta: {
      pageTitle: i18n.t('crm.list.pageTitle'),
      moduleRoute: 'Crm',
      resource: 'providers',
      requiresAuth: true,
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('crm.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/crm/providers',
    name: 'crm.providers',
    component: () => import('@/views/crm/providers/ProviderList.vue'),
    meta: {
      pageTitle: i18n.t('crm.providers.list.pageTitle'),
      moduleRoute: 'Crm',
      resource: 'providers',
      requiresAuth: true,
      requiresAnyPermission: 'crm.company',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('crm.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('crm.providers.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/crm/providers/:id',
    name: 'crm.providers.details',
    component: () => import('@/views/crm/providers/ProviderDetails.vue'),
    meta: {
      pageTitle: i18n.t('crm.providers.details.pageTitle'),
      navActiveLink: 'crm.providers',
      moduleRoute: 'Crm',
      resource: 'providers',
      requiresAnyPermission: 'crm.company.show',
      requiresAuth: true,
      breadcrumb: [
        {
          text: i18n.t('crm.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('crm.providers.list.pageTitle'),
          to: { name: 'crm.providers' },
          active: false,
        },
        {
          text: i18n.t('crm.providers.details.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/crm/providers/:id/settings',
    name: 'crm.providers.settings',
    component: () => import('@/components/views/crm/company/settings/CompanySettings.vue'),
    meta: {
      pageTitle: i18n.t('crm.providers.details.pageTitle'),
      navActiveLink: 'crm.providers',
      moduleRoute: 'Crm',
      resource: 'providers',
      requiresAnyPermission: 'crm.company.update',
      requiresAuth: true,
      breadcrumb: [
        {
          text: i18n.t('crm.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('crm.providers.list.pageTitle'),
          to: { name: 'crm.providers' },
          active: false,
        },
        {
          text: i18n.t('crm.providers.details.pageTitle'),
          to: { name: 'crm.providers.details' },
          active: false,
        },
        {
          text: i18n.t('crm.providers.settings.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/crm/clients',
    name: 'crm.clients',
    component: () => import('@/views/crm/clients/ClientList.vue'),
    meta: {
      pageTitle: i18n.t('crm.clients.list.pageTitle'),
      moduleRoute: 'Crm',
      resource: 'clients',
      requiresAuth: true,
      showFiltration: true,
      requiresAnyPermission: 'crm.company',
      breadcrumb: [
        {
          text: i18n.t('crm.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('crm.clients.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/crm/clients/:id',
    name: 'crm.clients.details',
    component: () => import('@/views/crm/clients/ClientDetails.vue'),
    meta: {
      pageTitle: i18n.t('crm.clients.details.pageTitle'),
      navActiveLink: 'crm.clients',
      moduleRoute: 'Crm',
      resource: 'clients',
      requiresAuth: true,
      requiresAnyPermission: 'crm.company.show',
      breadcrumb: [
        {
          text: i18n.t('crm.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('crm.clients.list.pageTitle'),
          to: { name: 'crm.clients' },
          active: false,
        },
        {
          text: i18n.t('crm.clients.details.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/crm/clients/:id/settings',
    name: 'crm.clients.settings',
    component: () => import('@/components/views/crm/company/settings/CompanySettings.vue'),
    meta: {
      pageTitle: i18n.t('crm.clients.details.pageTitle'),
      navActiveLink: 'crm.clients',
      moduleRoute: 'Crm',
      resource: 'clients',
      requiresAuth: true,
      requiresAnyPermission: 'crm.company.update',
      breadcrumb: [
        {
          text: i18n.t('crm.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('crm.clients.list.pageTitle'),
          to: { name: 'crm.clients' },
          active: false,
        },
        {
          text: i18n.t('crm.clients.details.pageTitle'),
          to: { name: 'crm.clients.details' },
          active: false,
        },
        {
          text: i18n.t('crm.clients.settings.pageTitle'),
          active: true,
        },
      ],
    },
  },
];

export default cadreRoutes;
