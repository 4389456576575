import DefaultEventComponent from '@/libs/events/components/default';
import task from '@/libs/events/components/task';
import order from '@/libs/events/components/order';
import basetransport from '@/libs/events/components/basetransport';

export default {
  default: DefaultEventComponent,
  task,
  order,
  basetransport,
};
