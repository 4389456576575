<template>
  <div class="add-modal">
    <b-modal
      id="modal-settings-crm-industry-add"
      :title="$t('crm.industry.modal.add.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('crm.industry.modal.add.button.cancel')"
      :ok-title="$t('crm.industry.modal.add.button.ok')"
      size="lg"

      @ok.prevent="createIndustry"
      v-model="modalAddVisible"
      no-close-on-backdrop
      >
      <vs-loader full compact
        text="Tworzenie branży..."
        v-if="isLoading"
      />

      <b-card-text>
        <settings-tab-crm-services-form />
      </b-card-text>

      <template #modal-footer="{ ok, cancel }">
        <vs-modal-footer
          disable-on-loading
          :is-loading="isLoading"

          :text-cancel="$t('crm.industry.modal.add.button.cancel')"
          :text-ok="$t('crm.industry.modal.add.button.ok')"

          @cancel="cancel"
          @ok="ok"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCardText } from 'bootstrap-vue';
import { inject } from 'vue';
import SettingsTabCrmServicesForm from '@/components/views/settings/content-tabs/crm/forms/industry/SettingsTabCrmIndustryForm.vue';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';

export default {
  setup() {
    const { createIndustry, isLoading, modalAddVisible } = inject('industriesInstance');

    return { createIndustry, isLoading, modalAddVisible };
  },
  components: {
    BModal,
    VsLoader,
    BCardText,

    SettingsTabCrmServicesForm,
    VsModalFooter,
  },
};
</script>
