var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._b({
    staticClass: "vs-picker",
    class: {
      'vs-picker--loading': _vm.isLoading
    }
  }, 'div', _vm.$attrs, false), [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "inline": "",
      "text": "Wczytywanie listy..."
    }
  }) : _vm._e(), _c('v-select', {
    style: _vm.isLoading ? {} : _vm.currentStyle,
    attrs: {
      "multiple": _vm.multiple,
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.filteredOptions,
      "clearable": _vm.clearable,
      "disabled": _vm.disabled || _vm.isLoading,
      "placeholder": !_vm.isLoading ? _vm.placeholder : '',
      "label": _vm.label,
      "reduce": _vm.reduce,
      "selectable": _vm.selectable,
      "filter": _vm.fuseSearch,
      "closeOnSelect": _vm.closeOnSelect,
      "deselectFromDropdown": true
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (item) {
        var _vm$value;
        return [_c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading"
          }],
          staticClass: "item",
          staticStyle: {
            "display": "flex",
            "align-items": "center",
            "gap": "10px"
          }
        }, [_vm.multiple && (_vm$value = _vm.value) !== null && _vm$value !== void 0 && _vm$value.includes(_vm.reduce(item)) ? _c('feather-icon', {
          attrs: {
            "icon": "CheckIcon"
          }
        }) : _vm._e(), _vm._t("option", function () {
          return [_vm.statusPicker ? _c('vs-status-badge', {
            attrs: {
              "data": item
            }
          }) : _c('span', [_vm._v(" " + _vm._s(item.name) + " ")])];
        }, null, {
          option: item,
          multiple: _vm.multiple,
          props: _vm.$props
        })], 2)];
      }
    }, !_vm.multiple || !_vm.statusPicker ? {
      key: "selected-option",
      fn: function (item) {
        return [_c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading"
          }],
          staticClass: "selected-option",
          class: `${_vm.statusPicker ? 'selected-option-status' : ''}`
        }, [_vm._t("selected-option", function () {
          return [_vm.statusPicker ? [_c('vs-status-badge', {
            attrs: {
              "data": item
            }
          })] : [_vm._v(" " + _vm._s(item.name) + " ")]];
        }, null, {
          option: item,
          props: _vm.$props
        })], 2)];
      }
    } : {
      key: "selected-option-container",
      fn: function (_ref) {
        var option = _ref.option,
          deselect = _ref.deselect;
        return [_c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoading,
            expression: "!isLoading"
          }],
          staticClass: "vs__selected selected-status-container",
          style: _vm.getStyleForStatus(option)
        }, [_c('vs-status-badge', {
          attrs: {
            "data": option
          }
        }), _c('feather-icon', {
          staticClass: "close-icon",
          attrs: {
            "icon": "XIcon"
          },
          on: {
            "mousedown": function ($event) {
              $event.stopPropagation();
              return function () {
                return deselect(option);
              }.apply(null, arguments);
            }
          }
        })], 1)];
      }
    }, {
      key: "no-options",
      fn: function () {
        return [_vm._t("no-options", function () {
          return [_vm._v(" " + _vm._s(_vm.$t('component.picker.default.noOptions')) + " ")];
        })];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }