<template>
  <div :class="`vs-form ${noPadding ? '' : 'padded'}`">
    <b-form @submit.prevent>
      <b-row>
        <slot></slot>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BRow,
} from 'bootstrap-vue';
import { computed, provide } from 'vue';

export default {
  name: 'VsForm',
  props: {
    value: Object,
    errors: Object,
    i18nNamespace: String,
    fallbackI18nNamespace: String,
    enabledFields: Array,
    noPadding: Boolean,
  },
  setup(props) {
    const vsForm = computed(() => ({
      form: props.value,
      errors: props.errors,
      i18nNamespace: props.i18nNamespace ?? 'global.field',
      fallbackI18nNamespace: props.fallbackI18nNamespace ?? null,
      enabledFields: props.enabledFields ?? null,
    }));

    provide('vs-form', vsForm);
  },
  components: {
    BForm,
    BRow,
  },
};
</script>

<style lang="sass" scoped>
.vs-form.padded
  padding: 15px 35px
</style>
