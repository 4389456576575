export default {
  statusBadge: [{
    1: 'Do zrobienia', 2: 'Zrobione', 3: 'Anulowane', 4: 'W toku', 5: 'Czeka na klienta',
  },
  {
    1: 'primary', 2: 'success', 3: 'danger', 4: 'warning', 5: 'info',
  },
  {
    1: '#7F7F7F', 2: '#33cd85', 3: '#fe3480', 4: '#ffbf27', 5: '#7f5cfc',
  }],
};
