<template>
  <b-card>
    <b-tabs
      nav-class="nav-left"
    >
      <b-tab active>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Usługi</span>
        </template>

        <settings-tab-crm-services />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Branże</span>
        </template>

        <settings-tab-crm-industries />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Statusy</span>
        </template>

        <settings-tab-crm-statuses />
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue';
import SettingsTabCrmServices from './SettingsTabCrmServices.vue';
import SettingsTabCrmStatuses from './SettingsTabCrmStatuses.vue';
import SettingsTabCrmIndustries from './SettingsTabCrmIndustries.vue';

export default {
  name: 'SettingsTabCrm',
  components: {
    BCard,
    BTabs,
    BTab,

    SettingsTabCrmServices,
    SettingsTabCrmStatuses,
    SettingsTabCrmIndustries,
  },
};
</script>
