import Vue from 'vue';
import FeedbackToast from '@/components/ui/FeedbackToast.vue';

/**
 * Helper function that formats the body of the toast.
 *
 * TODO: Add support for extending the template for specific Feedbacks
 */

const baseTemplate = Vue.component('feedback-toast-template', {
  template: `
    <FeedbackToast :action="action">
      <template #title>{{ i18n.title }}</template>
      <template #description>{{ i18n.description }}</template>
      <template #action>{{ i18n.action}}</template>
    </FeedbackToast>
  `,
  components: { FeedbackToast },
  data: () => ({
    i18n: {
      title: null, description: null, action: null,
    },
    action: null,
  }),
});

const getComponent = function getFeedbackToastComponent(
  Feedback,
  i18nParams,
  callback = null,
  textOverrides = {},
) {
  const component = Vue.component('feedback-toast', {
    extends: baseTemplate,
    data: () => ({
      i18n: {
        ...Feedback.getI18nTranslations(i18nParams),
        ...textOverrides,
      },
      action: callback,
    }),
  });

  return component;
};

export default getComponent;
