class RemoteResourceManager {
  constructor() {
    this.fulfillers = [];
  }

  attach(identifier, callback) {
    // if (this.getFulfillersByIdentifier(identifier)) return;

    this.fulfillers.push({ identifier, callback });
  }

  detach(identifier) {
    this.fulfillers = this.fulfillers.filter((ff) => ff.identifier !== identifier);
  }

  notify(identifier, payload = null) {
    const fulfillers = this.getFulfillersByIdentifier(identifier);
    if (!fulfillers.length) {
      return;
    }
    fulfillers.forEach((fulfiller) => {
      fulfiller.callback(payload);
    });
  }

  getFulfillersByIdentifier(identifier) {
    return this.fulfillers.filter((ff) => ff.identifier === identifier);
  }

  static getInstance() {
    if (!RemoteResourceManager.instance) {
      RemoteResourceManager.instance = new RemoteResourceManager();
    }

    return RemoteResourceManager.instance;
  }
}

export default RemoteResourceManager;
