<template>
  <b-card>
    <vs-loader
      v-if="this.showLoader"
      :text="$t('orders.details.message.loading')"
    />

    <span v-else-if="!order">
      {{ $t('orders.error.orderNotFound') }}
      <br><br>
      <router-link :to="{ name: 'orders' }" class="close-details">
        {{ $t('orders.details.button.back') }}
      </router-link>
    </span>

    <div class="order-details" v-else-if="order">

      <div class="order-subject">
        {{ order.title }}
      </div>

      <h3 class="title">
        <order-label :id="order.id" :title="order.order_number" />
        <router-link :to="{ name: 'orders' }" class="close-details">
          <feather-icon icon="XIcon" size="24"/>
        </router-link>
      </h3>

      <div class="total-price" v-if="totalPrice">
          {{ totalPrice.toLocaleString('ru-RU') }}
          {{ currencyById(order.currency_id).code }}
      </div>

      <div class="buttons">
        <div class="group">
          <widget-card-controls
            editPermission="orders.order.update"
            deletePermission="orders.order.destroy"
            @edit="openEditModal"
            @delete="deleteOrder"
          />
        </div>

        <div class="group">
          <status-dropdown
            source="orders"
            :currentStatusId="order.status_id"
            v-can:change_status="'orders.order'"
            @pick="changeStatus"
            />
        </div>
        <div class="group">
          <vs-button
            variant="outline"
            color="#aaaaaa"
            class="vs-pdf-button"
            v-if="order.crm_provider_address_contact
              && order.crm_provider_address_contact.company_address"
            @click="downloadPDF"
          >
            {{ $t('global.button.pdf') }}
          </vs-button>
        </div>
      </div>

      <div class="details">
        <table>
          <tr>
            <td>{{ $t('orders.details.field.priority') }}</td>
            <td>
              <priority-label :id="order.priority_id" />
            </td>
          </tr>
          <tr>
            <td>{{ $t('orders.details.field.offerNumber') }}</td>
            <td>{{ order.offer_number }}</td>
          </tr>
          <tr>
            <td>{{ $t('orders.details.field.status') }}</td>
            <td>
              <div class="status">
                <status-badge orders :status_id="order.status_id"/>
              </div>
            </td>
          </tr>
          <tr>
            <td>{{ $t('orders.details.field.author') }}</td>
            <td>
              <user-label withAvatar :data="order.author" />
            </td>
          </tr>
          <tr>
            <td>{{ $t('orders.details.field.assigned') }}</td>
            <td>
              <user-label withAvatar :data="order.assigned" />
            </td>
          </tr>
          <tr>
            <td>{{ $t('orders.details.field.accepting') }}</td>
            <td>
              <user-label withAvatar :data="order.accepting" />
            </td>
          </tr>
          <tr>
            <td>{{ $t('orders.details.field.date.created') }}</td>
            <td>{{ formatDate.taskDeadline(order.ordered_at) }}</td>
          </tr>
          <tr>
            <td>{{ $t('orders.details.field.date.accepted') }}</td>
            <td>{{ formatDate.taskDeadline(order.acceptance_at) || '-' }}</td>
          </tr>
          <tr>
            <td>{{ $t('orders.details.field.date.settled') }}</td>
            <td>{{ formatDate.taskDeadline(order.settlement_at) || '-' }}</td>
          </tr>
          <tr>
            <td>{{ $t('orders.details.field.provider') }}</td>
            <td>
              <crm-provider-label
                :integrated="order.crm_provider_address_contact"
                short
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('orders.details.field.investor') }}</td>
            <td>
              <crm-investor-label
                :integrated="order.investor"
              />
            </td>
          </tr>
          <tr>
            <td>{{ $t('orders.details.field.investment') }}</td>
            <td v-if="order.investment && order.investment.id">
              <investments-investment-label :investment="order.investment" />
            </td>
            <td v-else>{{ order.investment ? order.investment.name : '' }}</td>
          </tr>
          <tr>
            <td>{{ $t('orders.details.field.region') }}</td>
            <td>
              {{ branchById(order.branch_id)?.shortcut }}
            </td>
          </tr>
        </table>
      </div>

      <div
        v-can:show="'orders.subject'"
      >
        <hr v-if="subject">

        <order-subject
          :subject="subject"
          @add="createSubject"
          @edit="editSubject"
          @remove="removeSubject"
        />
      </div>

      <hr>

      <header-section :title="$t('orders.details.field.paymentTerms')">
        <div class="details">
          <table class="payment-terms">
            <tr>
              <td>Warunki płatności</td>
              <td class="payment-terms-test" v-html="order.payment_terms"></td>
            </tr>
            <tr>
              <td>Termin realizacji</td>
              <td>{{ formatDate.taskDeadline(order.realization_at) || '-' }}</td>
            </tr>
            <tr>
              <td>Miejsce dostawy</td>
              <td>{{ order.delivery_place }}</td>
            </tr>
            <tr>
              <td>Transport</td>
              <td>{{ order.transport }}</td>
            </tr>
            <tr>
              <td>Osoba do kontaktu</td>
              <td>{{ order.contact_person }}</td>
            </tr>
          </table>
        </div>
      </header-section>

      <header-section :title="$t('orders.details.field.description')">
        <p class="description" v-if="order.comment" v-html="order.comment"></p>
        <span class="noDescriptionInfo" v-else>
          {{ $t('orders.details.message.noDescription') }}
        </span>
      </header-section>

      <hr>

      <attachments orders
        :items="order.files"
        :uploading="uploading"
        @add="createAttachment"
        @remove="removeAttachment"
        :noAccessText="$t('orders.details.message.noAttachmentAccess')"
        />

      <div
        v-can:index="'orders.comment'"
      >
        <hr>

        <comments
          :items="comments"
          @add="createComment"
          @edit="editComment"
          @remove="removeComment"

          :canAdd="$store.getters['auth/userHasPermission']('store', 'orders.comment')"
          :canDelete="$store.getters['auth/userHasPermission']('destroy', 'orders.comment')"
          :canUpdate="$store.getters['auth/userHasPermission']('update', 'orders.comment')"
        />
      </div>

      <authorization-guard silent
        permission="orders.order.get_logs"
      >
        <hr>
        <timeline
          source="orders"
          v-if="timeline"
          :items="timeline"
        />
      </authorization-guard>
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { deleteModal } from '@/libs/modals';
import { BCard } from 'bootstrap-vue';
import Timeline from '@/components/ui/timeline/Timeline.vue';
import Attachments from '@/components/ui/attachments/Attachments.vue';
import OrderSubject from '@/components/views/orders/order-subject/OrderSubject.vue';
import HeaderSection from '@/components/ui/HeaderSection.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import StatusBadge from '@/components/ui/status-badge/StatusBadge.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import OrderLabel from '@/components/views/orders/Label.vue';
import Comments from '@/components/ui/comments/Comments.vue';
import PriorityLabel from '@/components/ui/priority-label/PriorityLabel.vue';
import UserLabel from '@/components/ui/UserLabel.vue';
import formatDate from '@/libs/date-formatter';
import StatusDropdown from '@/components/ui/StatusDropdown.vue';
import Composer from '@/components/dev/invoice/Composer';

import {
  AttachmentCreateSuccess,
  AttachmentCreateError,
  AttachmentSizeError,
  AttachmentDeleteSuccess,
  AttachmentDeleteError,
  CommentCreateSuccess,
  CommentEditSuccess,
  CommentCreateError,
  CommentEditError,
  CommentDeleteSuccess,
  CommentDeleteError,
  StatusChangeSuccess,
  StatusChangeError,
} from '@feedback/module/tasks';

import {
  SubjectError,
  SubjectSuccess,
  SubjectEditError,
  SubjectEditSuccess,
  SubjectDeleteError,
  SubjectDeleteSuccess,
} from '@feedback/module/orders';
import CrmProviderLabel from '@/components/module/crm/CrmProviderLabel.vue';
import CrmInvestorLabel from '@/components/module/crm/CrmInvestorLabel.vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import store from '@/store';
import WidgetCardControls from '@/components/ui/WidgetCardControls.vue';
import useOrdersModals from '@/hooks/orders/useOrdersModals';
import useCoreBranchStore from '@/stores/module/core/useCoreBranchStore';
import InvestmentsInvestmentLabel from '../investments/InvestmentsInvestmentLabel.vue';

export default {
  data() {
    return {
      formatDate,
      showLoader: true,
      uploading: false,
    };
  },
  setup() {
    const { modalEditOrder } = useOrdersModals();
    const branchStore = useCoreBranchStore();

    const openEditModal = () => {
      modalEditOrder.value.open();
    };

    return {
      openEditModal,
      branchById: (id) => branchStore.getById(id),
    };
  },
  computed: {
    ...mapGetters({
      currentOrder: 'orders/current',
      orders: 'orders/all',
      comments: 'orders/comments/current',
      subject: 'orders/subject/current',
      user: 'auth/user',
      userById: 'users/byId',
      timeline: 'orders/logs/current',
      totalPrice: 'orders/subject/totalPrice',
      currencyById: 'currencies/byId',
      unitById: 'units/byId',
    }),
    order() {
      if (!this.currentOrder?.title) return null;
      return this.currentOrder;
    },
  },
  mounted() {
    this.updateOrder();
    this.$store.dispatch('users/fetch');
    this.$store.dispatch('units/fetch');
  },
  methods: {
    async downloadPDF() {
      const { unitById, currencyById, userById } = this;

      const currency = currencyById(this.order.currency_id).code;

      const objects = this.subject.map((object) => {
        const unit = unitById(object.unit_id)?.code ?? '-';
        return {
          ...object,
          unit,
          unit_price: `${object.unit_price.toLocaleString('ru-RU')} ${currency}`,
          total: `${(object.unit_price * object.amount).toLocaleString('ru-RU')} ${currency}`,
          amount: object.amount.toLocaleString('ru-RU'),
        };
      });

      const totalPrice = this.subject
        .reduce((prev, curr) => prev + curr.amount * curr.unit_price, 0);

      const provider = this.order.crm_provider_address_contact;
      const postalCode = provider.company_address.postal_code;
      const formattedPostalCode = `${postalCode.slice(0, 2)}-${postalCode.slice(2)}`;

      /**
       * FIXME: Ordering subject details should be pulled from server!!!
       *        Ideally it should be pulled right when the app launches
       *        together with module health checks and basic app info
       *
       * FIXME: Some params should be assigned using external methods,
       *        defining everything in constructor is a pathetic mess
       *
       * FIXME: Clearly this method is painful on it's own, after moving
       *        to CAPI we should delegate all logic to a hook (composable)
       *
       * TODO: Rewrite as Composition API hook
       * TODO: Invoice should use i18n, even if the client doesn't use
       *       invoices in languages other than Polish
       * TODO: Find a way to parse some of the present data, e.g.
       *       postal code should be displayed in '00-000' format
       */
      const invoiceDetails = store.getters['themes/data'].invoice;
      const comp = new Composer({}, {
        ...this.order,
        provider: this.order.crm_provider_address_contact,
        realization_at: formatDate.taskDeadline(this.order.realization_at),
        objects,
        total: `${totalPrice.toLocaleString('ru-RU')} ${currency}`,
        ordered_at: formatDate.taskDeadline(this.order.ordered_at),
        accepting: userById(this.order.accepting_id),
        footer: invoiceDetails.footer ?? [],
        city: invoiceDetails.city ?? '',
        details: {
          ordering: {
            subject: invoiceDetails.company,
            street: `ul. ${invoiceDetails.street}`,
            city: `${invoiceDetails.postal} ${invoiceDetails.city}`,
            nip: `NIP: ${invoiceDetails.nip}`,
            regon: `REGON: ${invoiceDetails.regon}`,
            contactEmail: invoiceDetails.contactEmail,
            phone: invoiceDetails.phone,
            email: userById(this.order.assigned_id)?.email ?? invoiceDetails.email,
          },
          provider: {
            subject: provider?.company_address?.company?.name,
            street: `ul. ${provider?.company_address?.street} ${provider?.company_address?.street_number}`,
            city: `${formattedPostalCode} ${provider?.company_address?.city}`,
            nip: provider?.company_address?.company?.nip
              ? `NIP: ${provider?.company_address?.company?.nip}`
              : '',
            regon: provider?.company_address?.company?.regon
              ? `REGON: ${provider?.company_address?.company?.regon}`
              : '',
            person: `${provider?.forename} ${provider?.surname}`,
            phone: `Tel. ${provider?.phone_number}`,
            email: provider?.email,
          },
        },
      });

      const invoice = await comp.createInvoice();

      invoice.setProperties({
        title: `Zlecenie numer ${this.order.order_number}`,
      });
      // invoice.output('dataurlnewwindow');
      window.open(invoice.output('bloburl'));
      // invoice.save(`zlecenie-${this.order.order_number}`);
    },
    changeStatus(id) {
      this.$store.dispatch('orders/updateStatus', {
        status_id: id,
        order_id: this.order.id,
      }).then(() => {
        this.$showFeedback(StatusChangeSuccess);
        this.$store.dispatch('orders/logs/fetch', this.order.id);
        this.order.status_id = id;
      }).catch(() => {
        this.$showFeedback(StatusChangeError);
      });
    },
    async updateOrder() {
      if (!this.$route.params.id) return;

      this.showLoader = true;
      let currentOrder = null;

      await this.$store
        .dispatch('orders/fetchById', this.$route.params.id)
        .then(({ data }) => {
          currentOrder = data.data;
        });

      this.currentOrder = currentOrder;
      this.showLoader = false;
    },
    createAttachment(attachment) {
      this.uploading = true;

      this.$store.dispatch('orders/attachments/create', {
        ...attachment,
        order_id: this.order.id,
      }).then(() => {
        this.$showFeedback(AttachmentCreateSuccess);
        this.uploading = false;
      }).catch(({ response }) => {
        if (response.status === 413 || response.status === 422) {
          this.$showFeedback(AttachmentSizeError);
        } else {
          this.$showFeedback(AttachmentCreateError);
        }
        this.uploading = false;
      });
    },
    removeAttachment(id) {
      return this.$store.dispatch('orders/attachments/remove', id)
        .then(() => {
          this.$showFeedback(AttachmentDeleteSuccess);
        })
        .catch(() => {
          this.$showFeedback(AttachmentDeleteError);
        });
    },
    createComment(comment) {
      this.$store.dispatch('orders/comments/create', {
        ...comment,
        order_id: this.order.id,
      }).then(() => {
        this.$showFeedback(CommentCreateSuccess);
        this.$store.dispatch('orders/logs/fetch', this.order.id);
      }).catch(() => {
        this.$showFeedback(CommentCreateError);
      });
    },
    editComment(comment) {
      return this.$store.dispatch('orders/comments/edit', {
        ...comment,
        order_id: this.order.id,
      })
        .then(() => {
          this.$showFeedback(CommentEditSuccess);
        })
        .catch(() => {
          this.$showFeedback(CommentEditError);
        });
    },
    removeComment(id) {
      this.$store.dispatch('orders/comments/remove', id)
        .then(() => {
          this.$showFeedback(CommentDeleteSuccess);
        })
        .catch(() => {
          this.$showFeedback(CommentDeleteError);
        });
    },
    createSubject(subject) {
      return this.$store.dispatch('orders/subject/create', {
        ...subject,
        order_id: this.order.id,
      })
        .then(({ data }) => {
          this.$showFeedback(SubjectSuccess);
          this.$store.commit('orders/UPDATE_IN_ORDERS', {
            id: this.order.id,
            subjects: [data.data],
          });
        })
        .catch(() => {
          this.$showFeedback(SubjectError);
        });
    },
    editSubject(subject) {
      return this.$store.dispatch('orders/subject/edit', {
        ...subject,
        order_id: this.order.id,
      })
        .then(() => {
          this.$showFeedback(SubjectEditSuccess);
        })
        .catch(() => {
          this.$showFeedback(SubjectEditError);
        });
    },
    removeSubject(subject) {
      this.$store.dispatch('orders/subject/delete', subject.id)
        .then(() => {
          this.$showFeedback(SubjectDeleteSuccess);
        })
        .catch(() => {
          this.$showFeedback(SubjectDeleteError);
        });
    },
    async deleteOrder() {
      const confirmation = await deleteModal({
        title: this.$t('orders.details.modal.delete.title'),
        text: this.$t('orders.details.modal.delete.text',
          { order: this.order.title }),
      });

      if (!confirmation) return false;

      const response = this.$store.dispatch('orders/remove', this.$route.params.id);

      response.then((res) => {
        if (!res.status === 200) return;

        this.$router.replace({ name: 'orders' });
      });

      return true;
    },
  },
  watch: {
    currentOrder(oldOrder, newOrder) {
      if (!this.order || newOrder?.id === oldOrder.id) return;
      this.$store.dispatch('orders/logs/fetch', this.order.id);
    },
    $route() {
      this.updateOrder();
    },
  },
  components: {
    BCard,
    Attachments,
    HeaderSection,
    Timeline,
    VsLoader,
    OrderSubject,
    StatusBadge,
    VsButton,
    OrderLabel,
    Comments,
    PriorityLabel,
    UserLabel,
    StatusDropdown,
    CrmProviderLabel,
    CrmInvestorLabel,
    AuthorizationGuard,
    InvestmentsInvestmentLabel,
    WidgetCardControls,
  },
};
</script>

<style lang="sass" scoped>
table.payment-terms
  td
    vertical-align: text-top

    ::v-deep p
      margin-bottom: 0

.title
  font-weight: 600
  display: flex
  align-items: center

  .close-details
    margin-left: auto

.buttons
  margin-top: 15px

  .group
    display: inline-block

    &:not(:first-child):not(:empty):before
      content: '|'
      margin: 0 10px
      opacity: 0.2

    .btn
      font-size: 10pt
      &:not(:last-child)
        margin-right: 5px

.details
  margin-top: 15px

  table
    tr
      height: 25px

      td
        &:not(:first-child)
          padding-left: 20px
          font-weight: 500

.description
  margin-top: 15px

.header-section
  margin-top: 15px

.noDescriptionInfo
  font-style: italic
</style>
