<template>
  <div class="notification"></div>
</template>

<script>
import { mapGetters } from 'vuex';
import NotificationBody from './notification/Body.vue';

export default {
  computed: {
    ...mapGetters({
      new: 'auth/notifications/new',
    }),
  },

  watch: {
    new(value) {
      if (!value) return;

      this.showToast(value);
    },
  },

  methods: {
    showToast(notification) {
      this.$toast({
        component: NotificationBody,
        props: {
          notification,
          style: {
            top: '200px',
          },
        },
      },
      {
        position: 'top-right',
        timeout: 3000,
      });
    },
  },
};
</script>
