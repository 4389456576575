var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "module-icon",
    style: _vm.iconStyle
  }, [_c('feather-icon', {
    attrs: {
      "icon": _vm.icon,
      "size": "18"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }