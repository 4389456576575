export default {
  /* Errors */
  BadInputError: {
    title: 'Nie udało się dodać zlecenia',
    description: 'Popraw oznakowane pola w formularzu',
  },

  StatusFetchError: {
    title: 'Błąd pobierania statusów zleceń',
    description: 'Spróbuj ponownie później lub odśwież stronę',
  },

  ServerError: {
    title: 'Błąd połączenia z serwerem',
    description: 'Spróbuj ponownie lub odśwież stronę',
  },

  SubjectError: {
    title: 'Nie udało się dodać przedmiotu zlecenia',
    description: 'Podane dane są błędne lub nie masz wymaganych uprawnień',
  },

  SubjectEditError: {
    title: 'Nie udało się zaktualizować przedmiotu zlecenia',
    description: 'Podane dane są błędne lub nie masz wymaganych uprawnień',
  },

  SubjectDeleteError: {
    title: 'Nie udało się usunąć przedmiotu zlecenia',
    description: 'Zasób nie istnieje lub nie masz wymaganych uprawnień',
  },

  /* Success */
  AddSuccess: {
    title: 'Pomyślnie utworzono zlecenie {label}',
    action: 'Przejdź do zlecenia',
  },

  EditSuccess: {
    title: 'Pomyślnie zaktualizowano zlecenie {label}',
    description: '{name}',
  },

  SubjectSuccess: {
    title: 'Pomyślnie dodano przedmiot zlecenia',
  },

  SubjectEditSuccess: {
    title: 'Pomyślnie zaktualizowano przedmiot zlecenia',
  },

  SubjectDeleteSuccess: {
    title: 'Pomyślnie usunięto przedmiot zlecenia',
  },
};
