<template>
  <div class="investments-work-type-form">
    <vs-form
      v-model="workType"
      :errors="errors"
      i18nNamespace="investments.form.workType.field"
    >
      <vs-form-group
        field="name"
        >
        <b-input-group>
          <b-form-input
            :placeholder="$t('investments.form.workType.field.name')"
            v-model="workType.name"
          />
        </b-input-group>
      </vs-form-group>
    </vs-form>
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue';
import {
  computed, ref, watch,
} from 'vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';

export default {
  name: 'InvestmentWorkTypeForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const workType = computed(() => props.value);
    const errors = ref({});

    watch(() => props.feedback, () => { errors.value = props.feedback; });

    return {
      workType,
      errors,
    };
  },
  components: {
    VsFormGroup,
    VsForm,
    BInputGroup,
    BFormInput,
  },
};
</script>
