<template>
  <button :title="tooltip" class="pane-button" @click="$emit('click')">
    <slot></slot>
  </button>
</template>

<script>
export default {
  components: {
  },
  props: ['tooltip'],
};
</script>

<style lang="sass" scoped>
  .pane-button
    display: flex
    align-items: center
    justify-content: center
    border-radius: 5px
    background: none
    border: none
    width: 30px
    height: 30px
    transition: 0.1s background ease-in-out

    &:not(last-child)
      margin-left: 2px

    &:hover
      background: rgba(0, 0, 0, 10%)

    &.is-active
      background: orange

      &:hover
        background: rgba(orange, 70%)
</style>
