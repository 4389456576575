export default function useQrGeneration() {
  const drawImage = (canvas, image, x, y, width, height, radius = 2) => {
    const context = canvas.getContext('2d');

    context.shadowOffsetX = 0;
    context.shadowOffsetY = 2;
    context.shadowBlur = 4;
    context.shadowColor = '#00000040';
    context.lineWidth = 8;
    context.beginPath();
    context.moveTo(x + radius, y);
    context.arcTo(x + width, y, x + width, y + height, radius);
    context.arcTo(x + width, y + height, x, y + height, radius);
    context.arcTo(x, y + height, x, y, radius);
    context.arcTo(x, y, x + width, y, radius);
    context.closePath();
    context.strokeStyle = '#F39314';
    context.stroke();
    context.clip();
    context.fillStyle = '#fff';
    context.fillRect(x, x, width, height);
    context.drawImage(image, x + 10, x + 10, width / 1.2, height / 1.2);
  };

  // TODO: dont pass logo in fn parameters
  const generate = (canvas) => {
    const image = new Image();

    // eslint-disable-next-line global-require
    image.src = require('@/assets/images/logo/logo.svg');
    image.crossorigin = 'anonymous';
    image.onload = () => {
      const coverage = 0.17;
      const width = Math.round(canvas.width * coverage);
      const x = (canvas.width - width) / 2;

      drawImage(canvas, image, x, x, width, width);
    };
  };

  return {
    generate,
    drawImage,
  };
}
