import Vue from 'vue';
import Vuex from 'vuex';
import ApiClient from '@/services/api';

const notificationUrl = 'api/core/notification/user-notifications';
const activityUrl = 'api/core/notification/change-activity';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    all: [],
    unread: [],
    maxBellCount: 5,

    // watched by toast emitter
    newNotification: {},

    unreadCount: null,
    totalCount: null,
  },

  getters: {
    all: (state) => state.all,
    bell: (state) => state.unread.slice(0, state.maxBellCount),
    new: (state) => state.newNotification,
    allCount: (state) => state.all.length,
    unreadCount: (state) => state.unreadCount,
  },

  mutations: {
    SET_NOTIFICATIONS(state, payload) {
      state.all = payload;

      // also update unread notifications
      const unread = payload.filter((notification) => notification.read === 0);
      state.unread = [...unread];
      state.unreadCount = unread.length;
    },
    ADD_NOTIFICATION(state, payload) {
      state.all = [payload, ...state.all];
      state.newNotification = payload;

      // also update unread notifications
      state.unread = [payload, ...state.unread];
      state.unreadCount += 1;
    },
    SET_UNREAD_NOTIFICATIONS(state, payload) {
      state.unread = payload;
    },
    SET_UNREAD_COUNT(state, payload) {
      state.unreadCount = payload;
    },
    SET_TOTAL_COUNT(state, payload) {
      state.totalCount = payload;
    },
    // TODO refactor MULTIPLE and SINGLE (join them)
    MARK_MULTIPLE_AS_READ(state, payload) {
      const unread = state.unread
        .filter((notification) => !payload.includes(notification.id));

      const all = state.all
        .map((notification) => {
          if (!payload.includes(notification.id)) return notification;

          return {
            ...notification,
            read: 1,
          };
        });

      state.unreadCount -= state.unread.length - unread.length;
      state.unread = [...unread];
      state.all = [...all];
    },
    MARK_AS_READ(state, payload) {
      const unread = state.unread
        .filter((notification) => notification.id !== payload);

      const all = state.all
        .map((notification) => {
          if (notification.id !== payload) return notification;

          return {
            ...notification,
            read: 1,
          };
        });

      state.unreadCount -= state.unread.length - unread.length;
      state.unreadCount = Math.max(0, state.unreadCount);
      state.unread = [...unread];
      state.all = [...all];
    },
  },

  actions: {
    fetchAll({ commit }) {
      const request = ApiClient.get(notificationUrl, { max: null }).then(({ data }) => {
        commit('SET_NOTIFICATIONS', data.data.notifications);
      }).catch(() => {});

      return request;
    },
    fetchUnread({ commit }) {
      const request = ApiClient.get(notificationUrl, {
        read: 'false',
      }).then(({ data }) => {
        commit('SET_UNREAD_NOTIFICATIONS', data.data.notifications);
        commit('SET_UNREAD_COUNT', data.data.recordsTotalUnread);
        commit('SET_TOTAL_COUNT', data.data.recordsTotal);
      }).catch(() => {});

      return request;
    },
    markAsRead({ commit }, { read, id }) {
      if (read) return true;

      const request = ApiClient.put(activityUrl, { notification_ids: [id] })
        .then(() => {
          commit('MARK_AS_READ', id);
        }).catch(() => {});

      return request;
    },
    markBellAsRead({ commit, getters }) {
      const ids = getters.bell.map((notification) => notification.id);

      const request = ApiClient.put(activityUrl, { notification_ids: ids })
        .then(() => {
          commit('MARK_MULTIPLE_AS_READ', ids);
        }).catch(() => {});

      return request;
    },
    markAllAsRead({ commit, getters }) {
      const ids = getters.all
        .filter((notification) => notification.read === 0)
        .map((notification) => notification.id);

      const request = ApiClient.put(activityUrl, { notification_ids: ids })
        .then(() => {
          commit('MARK_MULTIPLE_AS_READ', ids);
        }).catch(() => {});

      return request;
    },
    addNotification({ commit }, notification) {
      commit('ADD_NOTIFICATION', notification);
    },
    reset({ commit }) {
      commit('SET_UNREAD_NOTIFICATIONS', []);
      commit('SET_NOTIFICATIONS', []);
      commit('SET_UNREAD_COUNT', 0);
      commit('SET_TOTAL_COUNT', 0);
    },
  },
};
