<template>
  <div class="vs-file">
    <b-form-file
      v-model="selectedFile"
      v-bind="fileInputProps"
    />
  </div>
</template>

<script>
import { BFormFile } from 'bootstrap-vue';
import { computed } from 'vue';
import i18n from '@/libs/i18n';

export default {
  name: 'VsFile',
  props: {
    value: {
      type: File,
    },
  },
  setup(props, { emit, attrs }) {
    const selectedFile = computed({
      get() {
        return props.value;
      },
      set(file) {
        emit('input', file);
      },
    });

    const fileInputProps = computed(() => ({
      placeholder: i18n.t('component.attachments.field.uploadFile'),
      'drop-placeholder': i18n.t('component.attachments.field.dropFile'),
      'browse-text': i18n.t('component.attachments.button.browse'),
      ...attrs,
    }));

    return { selectedFile, fileInputProps };
  },
  components: {
    BFormFile,
  },
};
</script>
