import ApiClient from '@/services/api';

const urls = {
  get: 'api/investment/fuel',
};

export default {
  namespaced: true,
  state: {
    fueling: [],
  },

  getters: {
  },

  mutations: {
    SET_FUELING(state, fueling) {
      state.fueling = fueling;
    },

    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
  },

  actions: {
    /**
     * Fetch fueling list
     * @action fetch=fueling
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if vehicles exist
     * @returns {Promise}
     */
    fetch({ state, commit }, params, force = true) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(urls.get, {
        ...params,
      });

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_FUELING', res.data.data.fuel_order);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },
  },
};
