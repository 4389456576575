import ApiClient from '@/services/api';
import insurance from './insurance';
import reports from './reports';

const urls = {
  get: 'api/base/resource',
};

export default {
  namespaced: true,

  state: {
    cars: [],
  },

  getters: {
  },

  mutations: {
    SET_CARS(state, cars) {
      state.cars = cars;
    },

    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
  },

  actions: {
    /**
     * Fetch cars list
     * @action fetch=cars
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if cars exist
     * @returns {Promise}
     */
    fetch({ state, commit }, params, force = true) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(urls.get, {
        ...params,
        columnFilters: {
          ...params.columnFilters ?? {},
          type_ids: ['car'],
        },
      });

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_CARS', res.data.data.resources);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },
  },

  modules: {
    insurance,
    reports,
  },
};
