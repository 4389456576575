import { getCurrentInstance } from 'vue';

let modalValues = {};

const useModal = () => {
  // sets the values and opens the modal
  const app = getCurrentInstance();
  const openModal = (id, _context, params = {}) => {
    modalValues = params;
    app.proxy.$bvModal.show(id);
  };

  // used by modal to retrieve passed values
  const retrieveValues = () => modalValues;

  // clear values
  const clearValues = () => {
    modalValues = {};
  };

  return {
    openModal, retrieveValues, modalValues, clearValues,
  };
};

export default useModal;
