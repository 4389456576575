<template>
  <div
    :class="`vs-form-header ${preserveMargin ? 'with-margin' : ''}`"
  >

    <div class="vs-form-header-title">
      <div :class="`vs-form-header-title-text ${large ? 'large' : ''}`">
        <slot name="title">
          {{ text }}
        </slot>
      </div>

      <div class="vs-form-header-title-buttons">

        <div class="vs-form-header-title-button" v-if="withButton && isAuthorized">
          <b-button
            variant="link"
            @click="$emit('action')"
          >
            {{ buttonText }}
          </b-button>
        </div>

        <div class="vs-form-header-title-edit-button">
          <slot name="edit-button" />
        </div>

      </div>
    </div>

    <div class="vs-form-header-subtitle">
      <slot name="subtitle"></slot>
    </div>

  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue';
import { computed } from 'vue';
import store from '@/store';

export default {
  name: 'VsFormHeader',
  emits: ['action'],
  props: {
    text: String,
    buttonText: String,
    preserveMargin: Boolean,
    withButton: Boolean,
    large: Boolean,
    buttonPermission: String,
  },
  setup(props) {
    const isAuthorized = computed(() => {
      if (!props.buttonPermission) return true;
      const split = props.buttonPermission?.split('.');
      const parent = `${split[0]}.${split[1]}`;
      const action = split[2];

      return store
        .getters['auth/userHasPermission'](action, parent);
    });

    return { isAuthorized };
  },
  components: {
    BButton,
  },
};
</script>

<style lang="sass" scoped>
.vs-form-header
  border-bottom: 1px solid rgba(128, 128, 128, 0.1)
  margin: 0 0 20px 0
  padding: 0 0 10px 14px
  width: 100%

  &-title
    font-weight: 500
    display: flex
    align-items: center
    justify-content: space-between

    @media screen and (max-width: 768px)
      margin: 0
      padding: 0
      padding-bottom: 3px
      margin-bottom: 10px

    &-button
      button
        padding-right: 0

    &-buttons
      display: flex
      align-items: center
      gap: 12px

    &-text
      &.large
        font-size: 13pt

.vs-form-header.with-margin
  margin-top: 30px !important
</style>
