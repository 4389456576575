var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('authorization-guard', {
    attrs: {
      "permission": "cadre.employment_form.index"
    }
  }, [_c('table', [_c('thead', [_c('th', [_vm._v("Forma zatrudnienia")])]), _c('tbody', _vm._l(_vm.employments, function (employment) {
    return _c('tr', {
      key: employment.id
    }, [_c('td', [_vm._v(_vm._s(employment.name))])]);
  }), 0)])]), _c('modal-add'), _c('modal-edit')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }