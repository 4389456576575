import { ref } from 'vue';

const x = ref(null);
const y = ref(null);
const observers = ref([]);

export default function useMousePosition() {
  const subscribe = (observingFunction) => {
    observers.value.push(observingFunction);
  };

  const notify = (type) => {
    observers.value.forEach((observingFunction) => observingFunction(type));
  };

  return {
    x, y, subscribe, notify,
  };
}
