<template>
  <div class="integration-form-label">
    <div class="integration-form-label-indicator"
      v-b-popover.hover.left="$t(`integration.${integration}.explanation`)"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      :title="$t(`integration.${integration}.name`)"
      >
      <feather-icon
        :icon="$t(`integration.${integration}.icon`)"
        class="integration-form-label-icon"
        size="14"
      />
      <span class="integration-form-label-indicator-module">
        {{ label }}
      </span>
    </div>

  </div>
</template>

<script>
import { VBPopover } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  name: 'IntegrationFormLabel',
  props: {
    label: {
      type: String,
      default: '',
    },
    integration: {
      type: String,
      default: '',
    },
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/scss/variables/_variables'

.integration-form-label
  position: relative

  &-icon
    margin-top: 3px
    flex-shrink: 0

  &-indicator
    cursor: pointer
    color: $primary
    display: flex
    align-items: flex-start
    width: auto
    gap: 7px
</style>
