<template>
  <b-card>
    Ustawienia aplikacji...
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';

export default {
  name: 'Index',
  components: {
    BCard,
  },
};
</script>

<style scoped>

</style>
