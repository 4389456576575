var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `vs-form-header ${_vm.preserveMargin ? 'with-margin' : ''}`
  }, [_c('div', {
    staticClass: "vs-form-header-title"
  }, [_c('div', {
    class: `vs-form-header-title-text ${_vm.large ? 'large' : ''}`
  }, [_vm._t("title", function () {
    return [_vm._v(" " + _vm._s(_vm.text) + " ")];
  })], 2), _c('div', {
    staticClass: "vs-form-header-title-buttons"
  }, [_vm.withButton && _vm.isAuthorized ? _c('div', {
    staticClass: "vs-form-header-title-button"
  }, [_c('b-button', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('action');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")])], 1) : _vm._e(), _c('div', {
    staticClass: "vs-form-header-title-edit-button"
  }, [_vm._t("edit-button")], 2)])]), _c('div', {
    staticClass: "vs-form-header-subtitle"
  }, [_vm._t("subtitle")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }