import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useCRMApi from '@/hooks/crm/useCRMApi';
import { ref } from 'vue';

const { fetchPickerProviders, fetchPickerClients } = useCRMApi();

export default defineStore('crmCompany', () => {
  const providersBranches = ref([]);
  const providers = useRemoteResource('crm.provider', fetchPickerProviders);
  const clients = useRemoteResource('crm.provider', fetchPickerClients);

  /**
   * Provider's branches contact people
   */
  const providersContactPersons = ref([]);
  const updateProvidersContactPersons = () => {
    const list = [];

    providersBranches.value.forEach(({ branch }) => {
      if (!branch) return;
      branch.contacts.forEach((contact) => list.push(contact));
    });

    providersContactPersons.value = list;
  };

  /**
   * Provider's branches
   */
  const updateProvidersBranches = () => {
    const list = [];
    if (!providers.state) return;

    providers.state.forEach((provider) => {
      if (!provider.company_addresses?.length) {
        list.push({
          ...provider,
          id: null,
          branch: null,
          providerId: provider.id,
        });
        return;
      }

      provider.company_addresses.forEach((branch) => {
        list.push({
          ...provider,
          branch,
          id: branch.id,
          providerId: provider.id,
        });
      });
    });
    providersBranches.value = list;
    updateProvidersContactPersons();
  };

  /**
   * Client's branches
   */
  const clientsBranches = ref([]);
  const updateClientsBranches = () => {
    const list = [];
    if (!clients.state) return;

    clients.state.forEach((client) => {
      if (!client.company_addresses?.length) {
        list.push({
          ...client,
          id: null,
          branch: null,
          providerId: client.id,
        });
        return;
      }

      client.company_addresses.forEach((branch) => {
        list.push({
          ...client,
          branch,
          id: branch.id,
          providerId: client.id,
        });
      });
    });
    clientsBranches.value = list;
  };

  providers.setPostFetchMethod(updateProvidersBranches);
  clients.setPostFetchMethod(updateClientsBranches);

  return {
    providers,
    clients,

    providersBranches,
    providersContactPersons,

    clientsBranches,
  };
});
