export default {
  /* Errors */
  CreateCompanyError: {
    title: 'Nie udało się dodać firmy',
    description: 'Popraw dane w formularzu',
  },

  UpdateCompanyError: {
    title: 'Nie udało się zaktualizować firmy',
    description: 'Popraw dane w formularzu',
  },

  DeleteCompanyError: {
    title: 'Nie udało się usunąć firmy',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  FetchCompanyError: {
    title: 'Nie udało się odnaleźć szukanej firmy',
    description: 'Firma nie istnieje lub nie masz odpowiednich uprawnień',
  },

  CreateServiceError: {
    title: 'Nie udało się dodać usługi',
    description: 'Popraw dane w formularzu',
  },

  UpdateServiceError: {
    title: 'Nie udało się zaktualizować usługi',
    description: 'Popraw dane w formularzu',
  },

  DeleteServiceError: {
    title: 'Nie udało się usunąć usługi',
    description: 'Wystąpił nieoczekiwany błąd. Spróbuj odświeżyć stronę',
  },

  CreateIndustryError: {
    title: 'Nie udało się dodać branży',
    description: 'Popraw dane w formularzu',
  },

  UpdateIndustryError: {
    title: 'Nie udało się zaktualizować branży',
    description: 'Popraw dane w formularzu',
  },

  DeleteIndustryError: {
    title: 'Nie udało się usunąć branży',
    description: 'Wystąpił nieoczekiwany błąd. Spróbuj odświeżyć stronę',
  },

  UpdateFinancingError: {
    title: 'Nie udało się zaktualizować finansów',
    description: 'Popraw dane w formularzu',
  },

  CreateContactError: {
    title: 'Nie udało się utworzyć adresu firmy',
  },

  UpdateContactError: {
    title: 'Nie udało się zaktualizować adresu firmy',
  },

  DeleteContactError: {
    title: 'Nie udało się usunąć adresu firmy',
  },

  CreateContactPersonError: {
    title: 'Nie udało się utworzyć osoby kontaktowej',
  },

  UpdateContactPersonError: {
    title: 'Nie udało się zaktualizować osób kontaktowych',
  },

  DeleteContactPersonError: {
    title: 'Nie udało się usunąć osoby kontaktowej',
  },

  SetPrimaryContactError: {
    title: 'Nie udało się ustanowić siedziby głównej firmy',
  },

  FetchPickerProvidersError: {
    title: 'Nie udało się pobrać listy dostawców',
  },

  FetchPickerClientsError: {
    title: 'Nie udało się pobrać listy klientów',
  },

  /* Success */
  CreateCompanySuccess: {
    title: 'Pomyślnie dodano firmę',
    description: '{name}',
  },

  UpdateCompanySuccess: {
    title: 'Pomyślnie zaktualizowano firmę',
    description: '{name}',
  },

  DeleteCompanySuccess: {
    title: 'Pomyślnie usunięto firmę',
    description: '{name}',
  },

  CreateServiceSuccess: {
    title: 'Pomyślnie dodano usługę',
    description: '{name}',
  },

  UpdateServiceSuccess: {
    title: 'Pomyślnie zaktualizowano usługę',
    description: '{name}',
  },

  DeleteServiceSuccess: {
    title: 'Pomyślnie usunięto usługę',
    description: '{name}',
  },

  CreateIndustrySuccess: {
    title: 'Pomyślnie dodano branżę',
    description: '{name}',
  },

  UpdateIndustrySuccess: {
    title: 'Pomyślnie zaktualizowano branżę',
    description: '{name}',
  },

  DeleteIndustrySuccess: {
    title: 'Pomyślnie usunięto branżę',
    description: '{name}',
  },

  UpdateFinancingSuccess: {
    title: 'Pomyślnie zaktualizowano finanse',
    description: '{company}',
  },

  CreateContactSuccess: {
    title: 'Pomyślnie utworzono adres firmy',
  },

  UpdateContactSuccess: {
    title: 'Pomyślnie zaktualizowano adres firmy',
  },

  DeleteContactSuccess: {
    title: 'Pomyślnie usunięto adres firmy',
  },

  CreateContactPersonSuccess: {
    title: 'Pomyślnie utworzono osobę kontaktową',
  },

  UpdateContactPersonSuccess: {
    title: 'Pomyślnie zaktualizowano osoby kontaktowe',
  },

  DeleteContactPersonSuccess: {
    title: 'Pomyślnie usunięto osobę kontaktową',
  },

  SetPrimaryContactSuccess: {
    title: 'Pomyślnie ustawiono siedzibę główną firmy',
  },
};
