<!--

  Temporary file for websocket testing purposes

-->
<template>
  <div class="websocket-widget">
    <b-card title="Websocket log">
      <b-button @click="() => $store.dispatch('auth/leaveEchoChannels')">Disconnect</b-button>
      <ul>
        <li v-for="msg in publicMessages.slice().reverse()" :key="msg.timestamp">
          <span class="time">{{ msg.timestamp }}</span>
          <code>{{ msg.channel }}</code>
          <span class="message">{{ msg.message }}</span>
        </li>
      </ul>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BCard, BButton } from 'bootstrap-vue';

export default {
  components: {
    BCard,
    BButton,
  },
  computed: {
    ...mapGetters({
      newestPublicMessage: 'echo/newestPublicMessage',
      publicMessages: 'echo/publicMessages',
    }),
  },
  watch: {
    newestPublicMessage(payload) {
      this.showMessageNotification(payload.message);
    },
  },
  methods: {
    showMessageNotification(message) {
      this.$bvToast.toast(message, {
        title: 'Nowa wiadomość z Echo',
        variant: 'primary',
        solid: true,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.websocket-widget
  ul
    list-style-type: none
    margin: 0
    padding: 0

    li
      margin: 15px 0

    .time
      display: inline-block
      opacity: 0.5
      width: 90px

    .time,
    code
      margin-right: 10px
</style>
