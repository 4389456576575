<template>
  <div class="investments-create-work-group-modal">
    <b-modal
      id="modal-investments-create-work-group"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.workGroup.create.title')"

      v-model="addWorkGroupModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.workGroup.create.loading')"
        v-if="isLoading"
      />

      <investment-work-group-form
        v-model="fields"
        :feedback="feedback"
      />

      <template #modal-footer>
        <div class="investment-modal-footer">

          <div class="buttons">
            <vs-button
              variant="outline"
              @click="addWorkGroupModal
              .visible = false"
            >
              {{ $t('investments.modal.workGroup.create.cancel') }}
            </vs-button>

            <vs-button
              variant="primary"
              @click="addWorkGroup"
            >
              {{ $t('investments.modal.workGroup.create.confirm') }}
            </vs-button>
          </div>

        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import showToast from '@/libs/toasts';
import { CreateWorkGroupError, CreateWorkGroupSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import InvestmentWorkGroupForm from '../forms/InvestmentWorkGroupForm.vue';

export default {
  name: 'InvestmentWorkGroupModalAdd',
  props: {},
  setup(_props, { emit }) {
    const { addWorkGroupModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const {
      fields, feedback, validateAllFields, resetFields, validateField,
      nonEmptyFields, assignServerSideErrors,
    } = useValidatedForm({
      name: 'required',
    }, 'investments.form.workGroup.field');

    const addWorkGroup = async () => {
      const validFields = await validateAllFields();
      if (!validFields.every((field) => field)) return;

      isLoading.value = true;

      useInvestmentsApi()
        .createWorkGroup({
          ...nonEmptyFields.value,
        })
        .then(({ data }) => {
          showToast(CreateWorkGroupSuccess, { name: data.data.name });
          emit('success', data.data);

          resetFields();
          addWorkGroupModal.value.visible = false;
          isLoading.value = false;
        })
        .catch(({ response }) => {
          showToast(CreateWorkGroupError);
          assignServerSideErrors(response?.data?.errors);
          isLoading.value = false;
        });
    };

    return {
      fields,
      feedback,
      addWorkGroupModal,
      isLoading,
      addWorkGroup,
      validateField,
    };
  },
  components: {
    VsLoader,
    VsButton,
    InvestmentWorkGroupForm,
  },
};
</script>

<style lang="sass" scoped>
.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between

  .buttons
    margin-left: auto
</style>
