import OrderFeedback from '@feedback/module/orders/OrderFeedback';

/**
 * Defined Feedback objects.
 *
 * Currently it looks messy, but the whole point of
 * defining each error separately (instead of using plain i18n)
 * is to allow further changes to the Feedback structure, e.g.
 * types, actions, custom templates and more settings.
 *
 * TODO: break this down into separate files whenever the Feedback
 *       logic will be ready
 *
 * After adding a feedback instance below, remember to add the proper
 * key to i18n
 */

/** Negative Feedbacks */
export const BadInputError = new OrderFeedback('BadInputError', false);
export const StatusFetchError = new OrderFeedback('StatusFetchError', false);
export const ServerError = new OrderFeedback('ServerError', false);
export const SubjectError = new OrderFeedback('SubjectError', false);
export const SubjectEditError = new OrderFeedback('SubjectEditError', false);
export const SubjectDeleteError = new OrderFeedback('SubjectDeleteError', false);

/** Positive Feedbacks */
export const AddSuccess = new OrderFeedback('AddSuccess', true);
export const EditSuccess = new OrderFeedback('EditSuccess', true);
export const SubjectSuccess = new OrderFeedback('SubjectSuccess', true);
export const SubjectEditSuccess = new OrderFeedback('SubjectEditSuccess', true);
export const SubjectDeleteSuccess = new OrderFeedback('SubjectDeleteSuccess', true);
