import TaskFeedback from '@feedback/module/tasks/TaskFeedback';

/**
 * Defined Feedback objects.
 *
 * Currently it looks messy, but the whole point of
 * defining each error separately (instead of using plain i18n)
 * is to allow further changes to the Feedback structure, e.g.
 * types, actions, custom templates and more settings.
 *
 * TODO: break this down into separate files whenever the Feedback
 *       logic will be ready
 *
 * After adding a feedback instance below, remember to add the proper
 * key to i18n
 */

/** Negative Feedbacks */
export const BadInputError = new TaskFeedback('BadInputError', false);
export const ServerError = new TaskFeedback('ServerError', false);
export const FetchTaskError = new TaskFeedback('FetchTaskError', false);
export const AttachmentCreateError = new TaskFeedback(
  'AttachmentCreateError',
  false,
);
export const AttachmentSizeError = new TaskFeedback(
  'AttachmentSizeError',
  false,
);
export const AttachmentDeleteError = new TaskFeedback(
  'AttachmentDeleteError',
  false,
);
export const CommentCreateError = new TaskFeedback('CommentCreateError', false);
export const CommentEditError = new TaskFeedback('CommentEditError', false);
export const CommentDeleteError = new TaskFeedback('CommentDeleteError', false);
export const StatusChangeError = new TaskFeedback('StatusChangeError', false);
export const ExportDataError = new TaskFeedback('ExportDataError', false);

/** Positive Feedbacks */
export const AddSuccess = new TaskFeedback('AddSuccess', true);
export const EditSuccess = new TaskFeedback('EditSuccess', true);
export const AttachmentCreateSuccess = new TaskFeedback(
  'AttachmentCreateSuccess',
  true,
);
export const AttachmentDeleteSuccess = new TaskFeedback(
  'AttachmentDeleteSuccess',
  true,
);
export const CommentCreateSuccess = new TaskFeedback(
  'CommentCreateSuccess',
  true,
);
export const CommentEditSuccess = new TaskFeedback('CommentEditSuccess', true);
export const CommentDeleteSuccess = new TaskFeedback(
  'CommentDeleteSuccess',
  true,
);
export const StatusChangeSuccess = new TaskFeedback(
  'StatusChangeSuccess',
  true,
);
export const ExportDataSuccess = new TaskFeedback('ExportDataSuccess', true);
