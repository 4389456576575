var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-modal', {
    staticClass: "profile-settings-devices-edit-modal",
    attrs: {
      "modalInstance": _vm.modalEditAuthorizedDevice
    },
    on: {
      "ok": _vm.updateAuthorizedDevice,
      "show": _vm.setData,
      "hide": _vm.resetFields
    }
  }, [_c('profile-settings-device-form', {
    attrs: {
      "feedback": _vm.feedback
    },
    model: {
      value: _vm.fields,
      callback: function ($$v) {
        _vm.fields = $$v;
      },
      expression: "fields"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }