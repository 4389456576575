var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header-section', {
    attrs: {
      "title": _vm.title || _vm.$t('component.orderSubject.name'),
      "action": "Dodaj przedmiot",
      "canAction": _vm.$store.getters['auth/userHasPermission']('store', 'orders.subject')
    },
    on: {
      "actionClick": _vm.openSubjectEditor
    }
  }, [_c('b-collapse', {
    staticClass: "mt-2",
    attrs: {
      "id": "subject-collapse"
    },
    model: {
      value: _vm.subjectCollapseVisible,
      callback: function ($$v) {
        _vm.subjectCollapseVisible = $$v;
      },
      expression: "subjectCollapseVisible"
    }
  }, [_c('b-card', {
    staticClass: "m-0 p-0 border",
    attrs: {
      "title": _vm.editorTranslations.title
    }
  }, [_c('b-container', [_c('b-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "mt-1",
    attrs: {
      "label": _vm.$t('component.orderSubject.field.name')
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('component.orderSubject.field.name')
    },
    model: {
      value: _vm.newSubject.name,
      callback: function ($$v) {
        _vm.$set(_vm.newSubject, "name", $$v);
      },
      expression: "newSubject.name"
    }
  })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "mt-1",
    attrs: {
      "label": _vm.$t('component.orderSubject.field.quantity')
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('component.orderSubject.field.quantity')
    },
    model: {
      value: _vm.newSubject.amount,
      callback: function ($$v) {
        _vm.$set(_vm.newSubject, "amount", $$v);
      },
      expression: "newSubject.amount"
    }
  })], 1)], 1)], 1), _c('b-col', [_c('b-form-group', {
    staticClass: "mt-1",
    attrs: {
      "label": _vm.$t('component.orderSubject.field.unit')
    }
  }, [_c('core-unit-picker', {
    attrs: {
      "autopick": ""
    },
    model: {
      value: _vm.newSubject.unit_id,
      callback: function ($$v) {
        _vm.$set(_vm.newSubject, "unit_id", $$v);
      },
      expression: "newSubject.unit_id"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('component.orderSubject.field.price')
    }
  }, [_c('b-input-group', {
    attrs: {
      "append": _vm.currency.code
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('component.orderSubject.field.price')
    },
    model: {
      value: _vm.newSubject.unit_price,
      callback: function ($$v) {
        _vm.$set(_vm.newSubject, "unit_price", $$v);
      },
      expression: "newSubject.unit_price"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "subject-buttons"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": _vm.cancelEditing
    }
  }, [_vm._v(" " + _vm._s(_vm.editorTranslations.cancel) + " ")]), _c('vs-button', {
    staticClass: "vs-edit-button",
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.publishSubject
    }
  }, [_vm._v(" " + _vm._s(_vm.editorTranslations.confirm) + " ")])], 1)], 1)], 1), _vm.subject && _vm.subject.length > 0 ? _c('table', {
    staticClass: "table"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t('component.orderSubject.field.name')))]), _c('th', {
    staticClass: "quantity"
  }, [_vm._v(_vm._s(_vm.$t('component.orderSubject.field.quantity')))]), _c('th', [_vm._v(_vm._s(_vm.$t('component.orderSubject.field.price')))]), _c('th', [_vm._v(_vm._s(_vm.$t('component.orderSubject.field.value')))]), _c('th')]), _vm._l(_vm.subject, function (item) {
    return _c('order-subject-item', {
      key: item.id,
      attrs: {
        "item": item,
        "unit": _vm.order.currency_id
      },
      on: {
        "edit": _vm.edit,
        "remove": _vm.remove
      }
    });
  })], 2) : _vm._e(), _vm.totalPrice ? _c('div', {
    staticClass: "subject-price-sum"
  }, [_vm._v(" " + _vm._s(_vm.$t('component.orderSubject.field.sum')) + ": "), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.totalPrice.toLocaleString('ru-RU')))]), _vm._v(" " + _vm._s(_vm.currency.code) + " ")]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }