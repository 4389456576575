<template>
  <div class="settings-tab-crm-industry">

    <authorization-guard
      silent
      permission="crm.company_industry.store"
    >
      <div class="buttons">
        <div class="group">
          <vs-button
            vs-variant="primary"
            @click="openAddModal"
          >
            + {{ $t('crm.industry.button.add') }}
          </vs-button>
        </div>
      </div>
    </authorization-guard>

    <authorization-guard
      permission="crm.company_industry.index"
    >
      <vs-list
        :items="industries"
        @edit="openEditModal"
        @delete="deleteIndustry"
      />
    </authorization-guard>

    <settings-tab-crm-industry-modal-add />
    <settings-tab-crm-industry-modal-edit />

  </div>
</template>

<script>
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useIndustry from '@/hooks/settings/crm/useIndustry';
import { provide } from 'vue';
import VsList from '@/components/ui/vs-list/VsList.vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import SettingsTabCrmIndustryModalAdd from './modals/industry/SettingsTabCrmIndustryModalAdd.vue';
import SettingsTabCrmIndustryModalEdit from './modals/industry/SettingsTabCrmIndustryModalEdit.vue';

export default {
  name: 'SettingsTabCrmIndustries',
  setup() {
    const industriesInstance = useIndustry();
    const {
      industries, fetch, openAddModal, openEditModal, deleteIndustry,
    } = industriesInstance;

    provide('industriesInstance', industriesInstance);

    fetch();

    return {
      industries,
      openAddModal,
      openEditModal,
      deleteIndustry,
    };
  },

  components: {
    SettingsTabCrmIndustryModalAdd,
    SettingsTabCrmIndustryModalEdit,
    VsButton,
    VsList,
    AuthorizationGuard,
  },
};
</script>
