var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navbar-header d-xl-block d-none"
  }, [_c('ul', {
    staticClass: "nav navbar-nav"
  }, [_c('li', {
    staticClass: "nav-item"
  }, [_c('b-link', {
    staticClass: "navbar-brand",
    attrs: {
      "to": "/"
    }
  }, [_c('span', {
    staticClass: "brand-logo"
  }, [_c('b-img', {
    attrs: {
      "src": _vm.appLogoImage,
      "alt": "logo"
    }
  })], 1), _c('h2', {
    staticClass: "brand-text mb-0"
  }, [_vm._v(" " + _vm._s(_vm.appName) + " ")])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }