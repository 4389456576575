<template>
  <div class="attachments">
    <vs-loader full v-if="uploading"/>
    <header-section
      :title="$t('component.attachments.name')"
      :action="$t('component.attachments.button.add')"
      @actionClick="toggleAttachment"
      :canAction="canUpload"
      >
      <template #title>
        <div class="attachments-header">
          <h4>Załączniki</h4>
          <div class="additional-info" v-if="additionalInfo">
            {{ additionalInfo }}
          </div>
        </div>
      </template>
      <b-collapse
        id="attachment-collapse"
        class="mt-2"
        v-model="attachmentCollapseVisible"
      >
        <b-card
          :title="$t('component.attachments.title.add')"
          class="m-0 p-0 border"
        >
          <vs-file
            v-model="newAttachment.file"
            ref="attachmentInput"
          />

          <div class="attachment-buttons">

            <vs-button
              vs-variant="light"
              @click="cancelEditing"
            >
              {{ $t('component.attachments.button.cancel') }}
            </vs-button>

            <vs-button
              vs-variant="primary"
              class="vs-edit-button"
              @click="saveAttachment"
            >
              {{ $t('component.attachments.button.addNew') }}
            </vs-button>

          </div>

        </b-card>
      </b-collapse>

      <b-tabs v-if="useTabs" v-model="currentTab">
        <b-tab title="Bez kategorii" active />
        <b-tab title="Oferta" />
      </b-tabs>

      <table v-if="attachments.length > 0">
        <tr>
          <th class="name">
            {{ $t('component.attachments.field.name') }}
          </th>
          <th class="access" v-if="!hideAccessInfo">
            {{ $t('component.attachments.field.access') }}
          </th>
          <th :class="!canDelete && 'last-column'" v-if="!hideDate">
            {{ $t('component.attachments.field.date') }}
          </th>
          <th class="edit" v-if="canDelete">
            {{ $t('component.attachments.field.edit') }}
          </th>
        </tr>
        <attachment-item
          v-for="item in attachments"
          :key="item.id"
          @remove="removeAttachment"
          :attachment="item"
          :noAccessText="noAccessText"
          :canDelete="canDelete"
          :hideDate="hideDate"
          :hideAccessInfo="hideAccessInfo"
        />
      </table>
    </header-section>
  </div>
</template>

<script>
import { deleteModal } from '@/libs/modals';
import { mapState } from 'vuex';
import {
  BCollapse, BCard, BTabs, BTab,
} from 'bootstrap-vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import HeaderSection from '@/components/ui/HeaderSection.vue';
import store from '@/store';
import { computed, ref } from 'vue';
import AttachmentItem from './AttachmentItem.vue';
import VsLoader from '../vs-loader/VsLoader.vue';
import VsFile from '../vs-file/VsFile.vue';

export default {
  emits: ['add', 'remove'],
  props: {
    uploading: {
      type: Boolean,
      default: false,
    },
    orders: {
      type: Boolean,
      default: false,
    },
    noAccessText: {
      type: String,
      default: '',
    },
    additionalInfo: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    useProps: {
      type: Boolean,
      default: false,
    },
    useTabs: {
      type: Boolean,
      default: false,
    },
    canUpload: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    hideAccessInfo: {
      type: Boolean,
      default: false,
    },
    hideDate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const resource = props.orders ? 'orders' : 'tasks';
    const current = computed(() => store.getters[`${resource}/attachments/current`]);

    const currentTab = ref(0);

    const attachments = computed(() => {
      const type = currentTab.value === 0 ? 'other' : 'offer';

      if (props.useTabs) {
        const filesForType = [...props.items.filter((i) => i.type === type)].reverse();

        // FIXME: temporary solution, check should be performed
        //        in parent
        if (type === 'offer' && filesForType.length > 0) {
          filesForType[0].isOfferCurrent = true;
        }

        return filesForType;
      }

      return props.useProps ? props.items : current.value;
    });

    return { attachments, currentTab };
  },
  computed: {
    ...mapState({
      byId(_state, getters) {
        const resource = this.orders ? 'orders' : 'tasks';
        return getters[`${resource}/attachments/byId`];
      },
    }),
  },
  methods: {
    toggleAttachment() {
      this.attachmentCollapseVisible = true;
      this.$refs.attachmentInput.$children[0].$refs.input.click();
    },
    cancelEditing() {
      this.attachmentCollapseVisible = false;
      this.$refs.attachmentInput.$children[0].reset();
      this.newAttachment.name = null;
    },

    async removeAttachment(id) {
      const attachment = this.byId(id) || this.items.find((i) => i.id === id);

      const confirmation = await deleteModal({
        title: this.$t('component.attachments.modal.delete.title'),
        text: this.$t('component.attachments.modal.delete.text',
          { filename: attachment.original_name }),
      });

      if (!confirmation) return false;

      return this.$emit('remove', id);
    },
    saveAttachment() {
      const attachment = { ...this.newAttachment, original_name: this.newAttachment?.file?.name };

      this.$emit('add', attachment);
      this.cancelEditing();
    },
  },
  data() {
    return {
      newAttachment: {
        file: null,
      },
      attachmentCollapseVisible: false,
    };
  },
  components: {
    BCollapse,
    BCard,
    BTabs,
    BTab,
    VsButton,
    AttachmentItem,
    HeaderSection,
    VsLoader,
    VsFile,
  },
};
</script>

<style lang="sass" scoped>
.attachments
  .additional-info
    opacity: 0.4
  .vs-loader
    top: 0
.attachment-buttons
  padding-top: 15px
  display: flex
  justify-content: end
  gap: 10px

table
  margin-top: 10px
  width: 100%

  tr
    th
      opacity: 0.7
      padding-bottom: 10px

    .name
      width: 50%

    .edit
      text-align: center
      width: 8%

    .last-column
      text-align: right

    .access
      text-align: center
</style>
