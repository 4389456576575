import { parse } from 'node-html-parser';
import Section from '../Section';

class Notes extends Section {
  constructor(composer) {
    super('Invoice Notes', composer);
    this.rows = [];
  }

  interpretHtml(obj) {
    const { layout } = this.composer;

    // eslint-disable-next-line no-underscore-dangle
    if (obj._rawText) {
      // eslint-disable-next-line no-underscore-dangle
      let text = obj._rawText;
      // eslint-disable-next-line no-unused-vars
      let marginLeft = layout.gutters.x + 3;

      if (obj.parentNode && obj.parentNode.rawTagName && obj.parentNode.rawTagName === 'li') {
        marginLeft += 5;
        text = `- ${text}`;
      }

      this.rows.push([text]);
      // this.appendText(text,
      //   marginLeft,
      //   this.composer.layout.currentHeight + 1);
    }

    if (obj.childNodes && obj.childNodes.length > 0) {
      obj.childNodes.map((node) => this.interpretHtml(node));
    }
  }

  generate() {
    const { layout, content } = this.composer;
    layout.currentHeight += 5;

    this.appendSectionHeader(
      'Uwagi',
      layout.gutters.x,
      layout.currentHeight,
    );

    if (content.comment) {
      const html = parse(content.comment);

      this.interpretHtml(html);
      const { rows } = this;

      this.rows.push([' ']);

      this.composer.doc.autoTable({
        body: rows,
        headStyles: {
          font: 'OpenSans-Medium',
        },
        styles: {
          font: 'OpenSans-Light',
          fontSize: layout.fontSize.order.table,
        },
        theme: 'plain',
        margin: layout.gutters.x - 2,
        startY: layout.currentHeight,
        didDrawCell(HookData) {
          layout.currentHeight = HookData.cursor.y;
        },
      });
    } else {
      this.appendText(
        'Brak uwag do zlecenia',
        layout.gutters.x,
        layout.currentHeight,
      );
    }
    layout.currentHeight += 5;
  }
}

export default Notes;
