/**
 * Application routes
 */
import home from './home';
import settings from './settings';

import tasks from './tasks';
import orders from './orders';
import cadre from './cadre';
import crm from './crm';
import base from './base';
import investments from './investments';

import development from './development';

const routes = [
  ...home,
  ...settings,

  ...tasks,
  ...orders,
  ...cadre,
  ...crm,
  ...base,
  ...investments,
  ...development,
];

export default routes;
