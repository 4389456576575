export default {
  created: {
    default: 'Utworzono zlecenie {order}',
  },
  updated: {
    default: 'Zaktualizowano treść zlecenia',
    status_id: 'Zaktualizowano status zlecenia na',
    offer_number: 'Zmieniono numer oferty {old} na {new}',
  },
};
