/* eslint-disable camelcase */
import { extend } from 'vee-validate';
import {
  double, integer, min, required_if,
} from 'vee-validate/dist/rules';
import i18n from './i18n';

extend('longitude', {
  message: (field) => `Wartość ${field} nie jest poprawną długością geograficzną.`,
  validate: (value) => Number.isFinite(Number(value)) && Math.abs(value) <= 180,
});

extend('latitude', {
  message: (field) => `Wartość ${field} nie jest poprawną szerokością geograficzną.`,
  validate: (value) => Number.isFinite(Number(value)) && Math.abs(value) <= 90,
});

extend('positive', {
  message: (field) => `Wartość ${field} musi być większa niż zero.`,
  validate: (value) => parseFloat(value) > 0,
});

extend('double', {
  ...double,
  message: (field) => `Pole ${field} musi być liczbą.`,
});

extend('integer', {
  ...integer,
  message: (field) => `Pole ${field} musi być liczbą.`,
});

extend('required', {
  message: (field) => i18n.t('global.validation.required', { field }),
});

extend('length', {
  message: (field, { length }) => i18n.t('global.validation.length', { field, length }),
});

extend('integer_or_double', {
  message: (field) => `Pole ${field} musi być liczbą`,
  validate: (value) => !Number.isNaN(parseFloat(value)),
});

extend('nip', {
  message: () => 'Wprowadź prawidłowy NIP',
  validate: (value) => {
    if (value.length !== 10) return false;

    const formula = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    const checksum = formula.reduce((prev, curr, idx) => prev + value[idx] * curr, 0) % 11;

    return Number(value[9]) === checksum;
  },
});

extend('regon', {
  message: () => 'Wprowadź prawidłowy REGON',
  validate: (value) => {
    if (value.length !== 9) return false;

    const formula = [8, 9, 2, 3, 4, 5, 6, 7];
    const checksum = formula.reduce((prev, curr, idx) => prev + value[idx] * curr, 0) % 11 % 10;

    return Number(value[8]) === checksum;
  },
});

extend('required_or', {
  message: (field) => i18n.t('global.validation.required', { field }),
  validate: (value, [other]) => !!value || !!other,
  computesRequired: true,
});

extend('required_if', {
  ...required_if,
  message: (field) => i18n.t('global.validation.required', { field }),
});

extend('min', {
  ...min,
  message: (field) => i18n.t('global.validation.minLength', { field }),
});

extend('min_value', {
  message: (field, p) => i18n.t('global.validation.min_value', { field, length: p[0] }),
  validate: (value, [other]) => {
    const numberValue = Number(value);
    const numberOther = Number(other);

    return numberValue >= numberOther;
  },
});

extend('bigger_than', {
  message: (field, p) => i18n.t('global.validation.bigger_than', { field, length: p[0] }),
  validate: (value, [other]) => {
    const numberValue = Number(value);
    const numberOther = Number(other);

    return numberValue > numberOther;
  },
});

extend('date_after', {
  message: (field, p) => i18n.t('global.validation.dateAfter', { field, before: p[0] }),
  validate: (value, [other]) => {
    const valDate = new Date(value);
    const othDate = new Date(other);

    return (valDate.getTime() - othDate.getTime()) >= 0;
  },
});
