<template>
  <div class="crm-provider-picker-option">
    <template v-if="withBranch && branch">
      <feather-icon
        icon="MapPinIcon"
        class="crm-provider-picker-option__icon"
        size="14"
      />
      {{ name }}, {{ branch.name }}
    </template>
    <template v-else>
      <feather-icon
        icon="MapPinIcon"
        class="crm-provider-picker-option__icon"
        size="14"
      />
      {{ name }}
    </template>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'CrmProviderPickerOption',
  props: {
    provider: Object,
    withBranch: Boolean,
  },
  setup(props) {
    const name = computed(() => props.provider.name);
    const branch = computed(() => props.provider.company_addresses
      .find((address) => address.is_primary));

    return { name, branch };
  },
};
</script>

<style lang="scss" scoped>
.crm-provider-picker-option {
  display: flex;
  align-items: center;
  gap: 5px;

  &__icon {
    opacity: 0.4;
  }
}
</style>
