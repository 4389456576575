var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "employee-form"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.cadre.position.field.group'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.group_id,
      "state": _vm.errors.group_id === null || !!_vm.objectModel.group_id
    }
  }, [_c('cadre-group-picker', {
    model: {
      value: _vm.objectModel.group_id,
      callback: function ($$v) {
        _vm.$set(_vm.objectModel, "group_id", $$v);
      },
      expression: "objectModel.group_id"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.cadre.position.field.cost'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.cadre_position_daily_cost,
      "state": _vm.errors.cadre_position_daily_cost === null
    }
  }, [_c('b-input-group', {
    attrs: {
      "append": "PLN"
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('settings.cadre.position.field.cost')
    },
    model: {
      value: _vm.objectModel.cadre_position_daily_cost,
      callback: function ($$v) {
        _vm.$set(_vm.objectModel, "cadre_position_daily_cost", $$v);
      },
      expression: "objectModel.cadre_position_daily_cost"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": _vm.$t('settings.cadre.skills.field.name'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.name,
      "state": _vm.errors.name === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('settings.cadre.skills.field.name')
    },
    model: {
      value: _vm.objectModel.name,
      callback: function ($$v) {
        _vm.$set(_vm.objectModel, "name", $$v);
      },
      expression: "objectModel.name"
    }
  })], 1)], 1), _c('vs-form-header', {
    attrs: {
      "withButton": "",
      "text": "Uprawnienia stanowiska",
      "buttonText": _vm.permissionsVisible ? 'Zwiń' : 'Rozwiń',
      "preserveMargin": ""
    },
    on: {
      "action": function ($event) {
        _vm.permissionsVisible = !_vm.permissionsVisible;
      }
    }
  }), _c('b-collapse', {
    model: {
      value: _vm.permissionsVisible,
      callback: function ($$v) {
        _vm.permissionsVisible = $$v;
      },
      expression: "permissionsVisible"
    }
  }, [_c('div', {
    staticClass: "permission-explanation"
  }, [_vm._v(" Ustawienie poniższych uprawnień pozwoli na automatyczne nadawanie przywilejów osobom, które są przypisane do tego stanowiska. W celu nadania/odebrania uprawnień konkretnemu pracownikowi, skorzystaj z Ustawień konta danego pracownika ")]), _c('div', {
    staticClass: "permission-settings"
  }, [!_vm.preparingPermissions ? _c('vs-permission-editor', {
    attrs: {
      "stickTop": "",
      "noInherit": "",
      "fetched": _vm.permissions
    },
    on: {
      "update:granted": function (granted) {
        return _vm.$emit('update:granted', granted);
      },
      "update:denied": function (denied) {
        return _vm.$emit('update:denied', denied);
      }
    }
  }) : _vm._e()], 1)])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }