<template>
  <div class="vs-modal">
    <b-modal
      :size="size"

      :title="text.title"

      v-model="computedVisibility"
      :hide-footer="!footerVisible"
      :no-close-on-backdrop="noCloseOnBackdrop"
    >
      <vs-loader full
        :text="text.loader"
        v-if="isLoading"
      />

      <slot :data="data" />

      <template #modal-footer
        :ok="$emit('ok')"
        :cancel="computedVisibility = false"
        :isLoading="isLoading"
      >
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="text.cancel"
          :text-ok="text.ok"

          @ok="$emit('ok')"
          @cancel="computedVisibility = false"
          :noConfirm="noConfirm"
        >
          <template #left>
            <slot name="modal-footer-left" />
          </template>
        </vs-modal-footer>
      </template>

    </b-modal>
  </div>
</template>

<script>
import { computed, toRefs, watch } from 'vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import VsModalFooter from './VsModalFooter.vue';

export default {
  name: 'VsModal',
  emits: ['ok', 'show', 'hide'],
  props: {
    modalInstance: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    watch(() => props.modalInstance.visible, () => {
      if (props.modalInstance.visible) emit('show', props.modalInstance.data);
      else emit('hide');
    });

    const computedVisibility = computed({
      get: () => props.modalInstance.visible,
      set: (val) => {
        if (val) {
          props.modalInstance.open();
          return;
        }
        props.modalInstance.close();
      },
    });

    return { ...toRefs(props.modalInstance), computedVisibility };
  },
  components: {
    VsModalFooter,
    VsLoader,
  },
};
</script>
