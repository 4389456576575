export default {
  notFound: {
    header: 'Nie znaleziono strony 🕵🏻‍♀️',
    description: 'Ups! 😖 Żądany adres URL nie został znaleziony na tym serwerze. ',
    button: {
      backToHome: 'Powrót do dashboardu',
    },
  },
  unauthorized: {
    header: 'Brak wystarczających uprawnień',
    description: 'Ups! 😖 Nie masz odpowiednich uprawnień do wyświetlenia tej strony. Jeżeli uważasz, że to błąd - skontaktuj się z administratorem strony',
    lacking: 'Użytkownik potrzebuje uprawnienia związanego z {permission}',
    button: {
      backToHome: 'Powrót do dashboardu',
    },
  },
};
