<template>
  <div class="vs-permission-manager-float-editor">

    <div :class="`editor ${shown && 'editor--visible'}`">
      <div class="header">
        <h3>Edytor uprawnień</h3>

        <vs-button
          vs-variant="light"
          @click="shown = false"
        >
          Zamknij
        </vs-button>
      </div>

      <div class="show-all-nav">
        <div class="checkbox">
          <input id="show-all-cb" type="checkbox" v-model="ignoreNavPermissions" />
        </div>
        <label class="text" for="show-all-cb">
          Pokaż wszystkie działy w nawigacji (bez względu na posiadane permisje)
        </label>
      </div>

      <vs-loader v-if="!showEditor" />

      <vs-permission-manager
        v-else
        v-model="permissions"
        :fetched="userPermissions"
        no-inherit
        stickTop
        hideCount
      />
    </div>

    <vs-button
      class="show-editor-button"
      vs-variant="light"
      @click="() => { shown = true; showEditor = true }"
    >
      Pokaż edytor uprawnień
    </vs-button>

  </div>
</template>

<script>
import { computed, ref, watch } from 'vue';
import store from '@/store';
import VsButton from '../vs-button/VsButton.vue';
import VsPermissionManager from './VsPermissionManager.vue';
import VsLoader from '../vs-loader/VsLoader.vue';

export default {
  setup() {
    const shown = ref(false);
    const local = ref({});
    const showEditor = ref(false);

    const overridenPermissions = computed(() => store.state.auth.overridenPermissions);
    const userPermissions = computed(() => [
      ...store.getters['auth/permissions'],
      ...overridenPermissions.value].map((p) => ({ name: p, disabled: false })));

    const permissions = computed({
      get() {
        return local.value;
      },
      set(val) {
        store.commit('auth/SET_OVERRIDEN_PERMISSIONS', val.enabled.map((v) => v.name));
      },
    });

    watch(() => overridenPermissions.value, (val) => {
      if (val?.length > 0) return;

      showEditor.value = false;

      setTimeout(() => {
        showEditor.value = true;
      });
    });

    const ignoreNavPermissions = computed({
      get: () => store.state.app.disableNavPermissionCheck,
      set: (val) => store.commit('app/SET_DISABLE_NAV_PERMISSION_CHECK', val),
    });

    return {
      permissions,
      shown,
      userPermissions,
      overridenPermissions,
      showEditor,
      ignoreNavPermissions,
    };
  },
  components: {
    VsPermissionManager,
    VsButton,
    VsLoader,
  },

};
</script>

<style lang="sass" scoped>
.show-all-nav
  display: flex
  align-items: center
  gap: 10px
  padding: 0 20px 20px 20px

.vs-permission-manager-float-editor
  $editor-width: 500px

  .show-editor-button
    position: fixed
    bottom: 15px
    right: 29px
    z-index: 100

  .editor
    position: fixed
    right: 0
    top: 0
    height: 100vh
    z-index: 999
    width: $editor-width
    background: var(--vs-card-bg)
    overflow-y: auto
    transition: 0.2s all ease-in-out
    opacity: 0
    transform: translateX($editor-width)

    &--visible
      opacity: 1
      transform: translateX(0)

    .header
      display: flex
      align-items: center
      justify-content: space-between
      padding: 30px
</style>
