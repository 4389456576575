import i18n from '@/libs/i18n';

export default [
  {
    path: '/base/equipment',
    name: 'base.equipment',
    component: () => import('@/views/base/equipment/BaseEquipment.vue'),
    meta: {
      pageTitle: i18n.t('base.equipment.list.pageTitle'),
      moduleRoute: 'Base',
      activeTab: 'machines',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.equipment.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.equipment.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/equipment/details/:id',
    name: 'base.equipment.details',
    component: () => import('@/views/base/equipment/EquipmentDetails.vue'),
    meta: {
      pageTitle: i18n.t('base.equipment.details.pageTitle'),
      navActiveLink: 'base.equipment',
      moduleRoute: 'Base',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource.show',
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.equipment.list.pageTitle'),
          to: { name: 'base.equipment' },
          active: false,
        },
        {
          text: i18n.t('base.equipment.details.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/equipment/details/:id/settings',
    name: 'base.equipment.details.settings',
    component: () => import('@/components/views/base/equipment/settings/EquipmentSettings.vue'),
    meta: {
      pageTitle: i18n.t('base.equipment.settings.pageTitle'),
      navActiveLink: 'base.equipment',
      moduleRoute: 'Base',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource.update',
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.equipment.list.pageTitle'),
          to: { name: 'base.equipment' },
          active: false,
        },
        {
          text: i18n.t('base.equipment.details.pageTitle'),
          to: { name: 'base.equipment.details' },
          active: false,
        },
        {
          text: i18n.t('base.equipment.settings.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/equipment/reports/:resourceId?/:reportType?',
    name: 'base.equipment.reports',
    component: () => import('@/views/base/equipment/BaseEquipment.vue'),
    meta: {
      pageTitle: i18n.t('base.equipment.reports.fullname'),
      navActiveLink: 'base.equipment',
      moduleRoute: 'Base',
      activeTab: 'reports',
      requiresAuth: true,
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.equipment.pageTitle'),
          to: { name: 'base.equipment' },
          active: false,
        },
        {
          text: i18n.t('base.equipment.reports.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/equipment/schedule',
    name: 'base.equipment.schedule',
    component: () => import('@/views/base/equipment/BaseEquipment.vue'),
    meta: {
      pageTitle: i18n.t('base.equipment.schedule.fullname'),
      navActiveLink: 'base.equipment',
      moduleRoute: 'Base',
      activeTab: 'schedule',
      requiresAuth: true,
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.equipment.pageTitle'),
          to: { name: 'base.equipment' },
          active: false,
        },
        {
          text: i18n.t('base.equipment.schedule.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/equipment/service/:resourceId?',
    name: 'base.equipment.service',
    component: () => import('@/views/base/equipment/BaseEquipment.vue'),
    meta: {
      pageTitle: i18n.t('base.service.list.pageTitle'),
      moduleRoute: 'Base',
      navActiveLink: 'base.equipment',
      activeTab: 'service',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.equipment.pageTitle'),
          to: { name: 'base.equipment' },
          active: true,
        },
        {
          text: i18n.t('base.service.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/equipment/service/details/:id',
    name: 'base.equipment.service.details',
    component: () => import('@/views/base/equipment/BaseEquipment.vue'),
    meta: {
      pageTitle: i18n.t('base.service.details.pageTitle'),
      navActiveLink: 'base.equipment',
      moduleRoute: 'Base',
      activeTab: 'service',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource.show',
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.equipment.pageTitle'),
          to: { name: 'base.equipment' },
          active: true,
        },
        {
          text: i18n.t('base.service.details.pageTitle'),
          active: true,
        },
      ],
    },
  },
];
