var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "profile-settings"
  }, [_c('b-tabs', {
    attrs: {
      "pills": "",
      "vertical": ""
    }
  }, _vm._l(_vm.tabs, function (tab, index) {
    return _c('b-tab', {
      key: `profile-setting-tab-${index}`,
      scopedSlots: _vm._u([{
        key: "title",
        fn: function () {
          return [_c('feather-icon', {
            attrs: {
              "icon": tab.icon
            }
          }), _vm._v(" " + _vm._s(tab.name) + " ")];
        },
        proxy: true
      }], null, true)
    }, [_c('b-card', [_c(tab.component, {
      tag: "component"
    })], 1)], 1);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }