<template>
  <div class="base-settings-groups-list">
    <authorization-guard
      permission="base.group.show"
    >
      <vs-list showHeaders
        :items="groups"
        :headers="['Nazwa']"
        disable-actions
      />
    </authorization-guard>
  </div>
</template>

<script>
import useBaseGroup from '@/hooks/base/assignables/useBaseGroup';
import { provide } from 'vue';
import VsList from '@/components/ui/vs-list/VsList.vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';

export default {
  name: 'SettingsTabBaseGroups',
  setup() {
    const groupsInstance = useBaseGroup();
    const { groups, fetchGroups } = groupsInstance;

    provide('statusesInstance', groupsInstance);

    fetchGroups();

    return {
      groups,
    };
  },
  components: {
    VsList,
    AuthorizationGuard,
  },
};
</script>

<style lang="sass" scoped>
table
  width: 100%
  margin-top: 25px

  td
    padding: 10px 0

    &.expiry-date
      display: flex
      align-items: center
      color: #65b259

      &.expired
        color: #b2595a

      .icon
        margin-right: 10px

  tr:not(:last-child)
    td
      border-bottom: 1px solid rgba(58, 59, 61, 0.3)

.action-buttons
  position: absolute
  bottom: 15px

.action-button
  margin: 10px 0

.box-color
  height: 30px
  width: 100px
</style>
