import Vue from 'vue';
import Vuex from 'vuex';

import ApiClient from '@/services/api';

const createUrl = 'api/orders/comment';
const removeUrl = 'api/orders/comment/:id';

Vue.use(Vuex);

export default {
  namespaced: true,

  state: {
    currentComments: [],
    newComment: {
      content: null,
    },
    parameters: {
      auditableIds: [12],
      orderBy: 'ASC',
      select: ['event', 'created_at', 'user_id'],
    },
  },

  getters: {
    current: (state) => state.currentComments,
    byId: (state) => (id) => state.currentComments
      .filter((comment) => comment.id === id)[0] || null,
  },

  mutations: {
    SET_CURRENT_COMMENTS(state, payload) {
      state.currentComments = payload;
    },
    ADD_COMMENT(state, payload) {
      state.currentComments.push(payload);
    },
    REMOVE_COMMENT(state, id) {
      state.currentComments = state.currentComments
        .filter((comment) => comment.id !== id);
    },
    UPDATE_COMMENT(state, newComment) {
      const index = state.currentComments
        .findIndex((comment) => comment.id === newComment.id);

      // replace a comment inside array
      const newArray = [...state.currentComments];
      newArray[index] = { ...newArray[index], ...newComment };

      state.currentComments = [...newArray];
    },
  },

  actions: {
    fetch({ state, commit }, id) {
      const params = {
        ...state.parameters,
        auditableIds: [id],
      };
      ApiClient.get('/', params).then((payload) => {
        if (payload.status !== 200) return null;
        return commit('SET_CURRENT_COMMENTS', payload.data.data);
      });
    },
    create({ commit }, comment) {
      const request = ApiClient.post(createUrl, comment);

      request.then((payload) => {
        if (payload.status !== 200) return null;
        return commit('ADD_COMMENT', payload.data.data);
      });

      return request;
    },
    edit({ commit }, comment) {
      const url = removeUrl.replace(':id', comment.id);
      const request = ApiClient.put(url, comment);

      request.then(({ status }) => {
        if (status !== 200) return;

        commit('UPDATE_COMMENT', comment);
      }).catch(() => {});

      return request;
    },
    set({ commit }, data) {
      return commit('SET_CURRENT_COMMENTS', data);
    },
    remove({ commit }, id) {
      const url = removeUrl.replace(':id', id);

      const request = ApiClient.delete(url);

      request.then((payload) => {
        if (payload.status !== 200) return null;

        return commit('REMOVE_COMMENT', id);
      });

      return request;
    },
  },
};
