var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "comment"
  }, [_c('div', {
    staticClass: "comment-content",
    domProps: {
      "innerHTML": _vm._s(_vm.comment.content)
    }
  }), _c('div', {
    staticClass: "comment-footer"
  }, [_c('user-label', {
    attrs: {
      "id": _vm.comment.author_id,
      "showLink": ""
    }
  }), _c('div', {
    staticClass: "date"
  }, [_vm._v("(" + _vm._s(_vm.date) + ")")]), _vm.comment.author_id === _vm.user.id ? _c('div', {
    staticClass: "buttons"
  }, [_vm.canUpdate ? _c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "flat-secondary"
    },
    on: {
      "click": _vm.edit
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Edit2Icon",
      "size": "18"
    }
  })], 1) : _vm._e(), _vm.canDelete ? _c('b-button', {
    staticClass: "btn-icon",
    attrs: {
      "variant": "flat-secondary"
    },
    on: {
      "click": _vm.remove
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Trash2Icon",
      "size": "18"
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }