var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-form', {
    staticClass: "profile-settings-device-form",
    attrs: {
      "i18nNamespace": "profile.settings.device.field",
      "errors": _vm.feedback
    },
    model: {
      value: _vm.device,
      callback: function ($$v) {
        _vm.device = $$v;
      },
      expression: "device"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "name"
    }
  }, [_c('b-form-input', {
    model: {
      value: _vm.device.name,
      callback: function ($$v) {
        _vm.$set(_vm.device, "name", $$v);
      },
      expression: "device.name"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }