var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notifications"
  }, [_c('b-card', {
    attrs: {
      "title": _vm.$t('home.notifications.title.header')
    }
  }, [_c('ul', {
    staticClass: "statistics"
  }, [_c('li', [_vm._v("Nieprzeczytanych: "), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.unreadCount))])]), _c('li', [_vm._v("Wszystkich: "), _c('span', {
    staticClass: "value"
  }, [_vm._v(_vm._s(_vm.allCount))])]), _c('li', [_vm.unreadCount > 0 ? _c('b-button', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": _vm.markAllAsRead
    }
  }, [_vm._v(" Oznacz wszystkie jako przeczytane ")]) : _vm._e()], 1)]), _c('hr'), _vm._l(_vm.notifications, function (notification) {
    return _c('notification', {
      key: notification.id,
      attrs: {
        "notification": notification,
        "appearance": "history"
      },
      on: {
        "read": function () {
          return _vm.markAsRead(notification);
        },
        "action": function () {
          return _vm.makeAction(notification);
        }
      }
    });
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }