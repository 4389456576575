/**
 * Hook that generates unique avatar colors for user
 */

export const createShortcode = (forename, surname) => {
  if (typeof forename !== 'string') return 'TTT';
  if (forename && surname) return `${forename?.substring(0, 1)}${surname?.substring(0, 2)}`;
  if (forename) return `${forename?.substring(0, 3)}`;
  return surname?.substring(0, 3);
};

const shortcodeColor = (str) => {
  if (!str) return null;
  // glorious formula
  // at some point it shouldn't be changed
  const shortcodeValue = [
    str.charCodeAt(0) * str.charCodeAt(2),
    str.charCodeAt(1) * 20,
    str.charCodeAt(2) * str.charCodeAt(1) * str.charCodeAt(2),
  ];
  const hue = shortcodeValue
    .reduce((prev, curr) => prev + curr, 0)
    % 360;

  const saturation = '70%';
  const lightness = '80%';
  const randomColor = `hsl(${hue}, ${saturation}, ${lightness})`;
  const randomColorFg = `hsl(${hue}, ${saturation}, 20%)`;

  return {
    background: randomColor,
    foreground: randomColorFg,
  };
};

const useAvatarGenerator = () => {
  const createAvatar = (user, deleted = false) => {
    const findShortcode = () => {
      const { forename, surname } = user?.value;
      const shortcode = user?.value?.short_code;
      if (shortcode) return shortcode;

      return createShortcode(forename, surname);
    };

    const shortcode = findShortcode()?.toUpperCase();
    const colors = !deleted ? shortcodeColor(shortcode) : {
      borderColor: '#fa4141',
      foreground: '#fa4141',
    };

    return {
      shortcode,
      ...colors,
    };
  };

  return { createAvatar };
};

export default useAvatarGenerator;
