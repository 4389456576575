<template>
  <div class="unit-picker vs-picker">
    <v-select
      v-model="selected"
      :multiple="multiple"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="units"
      :clearable="clearable"
      label="code"
      >
      <template #option="{ code }">
        {{ code }}
      </template>

      <template #selected-option="{ code }">
        {{ code }}
      </template>

      <template #no-options>
        {{ $t('component.picker.unit.noOptions') }}
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select';

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 1,
    },
  },
  created() {
    if (this.units.length === 0) this.$store.dispatch('units/fetch');
    this.$emit('input', this.value);
  },
  computed: {
    ...mapGetters({
      units: 'units/all',
      unitById: 'units/byId',
    }),
    selected: {
      get() {
        return this.unitById(this.value);
      },
      set(value) {
        this.$emit('input', value.id);
      },
    },
  },
  components: {
    vSelect,
  },
};
</script>
