export default {
  /* Errors */
  BadInputError: {
    title: 'Nie udało się dodać zadania',
    description: 'Popraw oznakowane pola w formularzu',
  },

  ServerError: {
    title: 'Błąd połączenia z serwerem',
    description: 'Spróbuj ponownie lub odśwież stronę',
  },

  FetchTaskError: {
    title: 'Błąd pobierania danych zadania',
    description: 'Żądane zadanie nie istnieje lub nie masz do niego dostępu',
  },

  AttachmentCreateError: {
    title: 'Wystąpił błąd podczas dodawania załącznika',
    description: 'Spróbuj ponownie później',
  },

  AttachmentSizeError: {
    title: 'Załącznik jest zbyt duży',
    description: 'Wielkość załącznika nie może przekraczać 60 MB',
  },

  AttachmentDeleteError: {
    title: 'Nie udało się usunąć załącznika',
    description: 'Zasób nie istnieje lub nie masz wymaganych uprawnień',
  },

  CommentCreateError: {
    title: 'Nie udało się dodać komentarza',
    description: 'Przesłane dane są błędne lub nie masz wymaganych uprawnień',
  },

  CommentEditError: {
    title: 'Nie udało się edytować komentarza',
    description: 'Przesłane dane są błędne lub nie masz wymaganych uprawnień',
  },

  CommentDeleteError: {
    title: 'Nie udało się usunąć komentarza',
    description: 'Zasób nie istnieje lub nie masz wymaganych uprawnień',
  },

  StatusChangeError: {
    title: 'Nie udało się zmienić statusu zadania',
    description: 'Zasób nie istnieje lub nie masz wymaganych uprawnień',
  },

  ExportDataError: {
    title: 'Wystąpił błąd podczas eksportu danych',
    description: 'Spróbuj ponownie później',
  },

  /* Success */
  AddSuccess: {
    title: 'Pomyślnie utworzono zadanie {label}',
    description: '{name}',
    action: 'Przejdź do zadania',
  },

  EditSuccess: {
    title: 'Pomyślnie zmodyfikowano treść zadania {label}',
    description: '{name}',
  },

  AttachmentCreateSuccess: {
    title: 'Pomyślnie dodano załącznik do zadania',
  },

  AttachmentDeleteSuccess: {
    title: 'Pomyślnie usunięto załącznik',
  },

  CommentCreateSuccess: {
    title: 'Pomyślnie opublikowano komentarz',
  },

  CommentEditSuccess: {
    title: 'Zaktualizowano treść komentarza',
  },

  CommentDeleteSuccess: {
    title: 'Pomyślnie usunięto komentarz',
  },

  StatusChangeSuccess: {
    title: 'Pomyślnie zmieniono status zadania',
  },

  ExportDataSuccess: {
    title: 'Pomyślnie wyeksportowano dane',
  },
};
