export default {
  fueling: {
    order_date: 'Data dostawy',
    amount_fuel: 'Ilość paliwa',
    crm_provider_id: 'Dostawca',
    reporting_id: 'Raportujący',
    investment_id: 'Inwestycja',
    comment: 'Uwagi',
  },
};
