import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useOrdersApi from '@/hooks/orders/useOrdersApi';

const { status } = useOrdersApi();

export default defineStore('orderStatus', () => {
  const {
    state,
    fetch,
    fetched,
    getById,
  } = useRemoteResource('order.status', status.fetch);

  return {
    state,
    fetch,
    fetched,
    getById,
  };
});
