import showToast from '@/libs/toasts';
import { ref } from 'vue';
import i18n from '@/libs/i18n';
import { deleteModal } from '@/libs/modals';
import {
  CreateProducerError,
  CreateProducerSuccess, DeleteProducerError, DeleteProducerSuccess,
  FetchProducersError, UpdateProducerError,
  UpdateProducerSuccess,
} from '@/libs/toasts/feedback/module/base';
import RemoteResourceManager from '@/libs/RemoteResourceManager';
import useBaseApi from '../useBaseApi';
import useBaseStore from '../useBaseStore';

export default function useBaseProducer() {
  const { producers } = useBaseStore();
  const producer = ref({});
  const modalAddVisible = ref(false);
  const modalEditVisible = ref(false);

  const isLoading = ref(false);

  const fetchProducers = (force = false) => {
    if (producers.value.length > 0 && !force) return producers;

    const req = useBaseApi()
      .fetchProducers()
      .then(({ data }) => {
        producers.value = data.data;
      })
      .catch(() => showToast(FetchProducersError));

    return req;
  };

  const createProducer = () => {
    isLoading.value = true;

    useBaseApi()
      .createProducer(producer.value)
      .then(({ data }) => {
        isLoading.value = false;
        producers.value.push(data.data);
        modalAddVisible.value = false;

        showToast(CreateProducerSuccess, { name: data.data.name });
        RemoteResourceManager.getInstance().notify('base.producer');
        producer.value = {};
      })
      .catch(() => {
        isLoading.value = false;
        showToast(CreateProducerError, { name: producer.value.name });
      });
  };

  const editProducer = () => {
    isLoading.value = true;

    useBaseApi()
      .updateProducer(producer.value)
      .then(({ data }) => {
        isLoading.value = false;
        producers.value = producers.value.map((s) => (s.id === data.data.id ? data.data : s));
        modalEditVisible.value = false;

        showToast(UpdateProducerSuccess, { name: data.data.name });
        RemoteResourceManager.getInstance().notify('base.producer');
        producer.value = {};
      })
      .catch(() => {
        isLoading.value = false;
        showToast(UpdateProducerError, { name: producer.value.name });
      });
  };

  const deleteProducer = async (requested) => {
    const confirmation = await deleteModal({
      title: i18n.t('base.producer.modal.delete.title'),
      text: i18n.t('base.producer.modal.delete.text', {
        name: requested.name,
      }),
    });

    if (!confirmation) return false;

    return useBaseApi()
      .deleteProducer(requested.id)
      .then(() => {
        producers.value = producers.value.filter((s) => s.id !== requested.id);
        showToast(DeleteProducerSuccess, { name: requested.name });
        RemoteResourceManager.getInstance().notify('base.producer');
        RemoteResourceManager.getInstance().notify('base.producer-model');
      })
      .catch(() => showToast(DeleteProducerError, { name: requested.name }));
  };

  const openAddModal = () => {
    producer.value = {};
    modalAddVisible.value = true;
  };

  const openEditModal = (edited) => {
    producer.value = { ...edited };
    modalEditVisible.value = true;
  };

  return {
    isLoading,
    producer,
    producers,
    modalAddVisible,
    modalEditVisible,
    createProducer,
    editProducer,
    deleteProducer,
    fetchProducers,
    openEditModal,
    openAddModal,
  };
}
