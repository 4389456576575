<template>
  <div class="settings-tab-crm-services">

    <authorization-guard
      silent
      permission="crm.provider_service.store"
    >
      <div class="buttons">
        <div class="group">
          <vs-button
            vs-variant="primary"
            @click="openAddModal"
          >
            + {{ $t('crm.service.button.add') }}
          </vs-button>
        </div>
      </div>
    </authorization-guard>

    <authorization-guard
      permission="crm.provider_service.index"
    >
      <vs-list
        :items="services"
        @edit="openEditModal"
        @delete="deleteService"
      />
    </authorization-guard>

    <settings-tab-crm-services-modal-add />
    <settings-tab-crm-services-modal-edit />

  </div>
</template>

<script>
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useServices from '@/hooks/settings/crm/useServices';
import { provide } from 'vue';
import VsList from '@/components/ui/vs-list/VsList.vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import SettingsTabCrmServicesModalAdd from './SettingsTabCrmServicesModalAdd.vue';
import SettingsTabCrmServicesModalEdit from './SettingsTabCrmServicesModalEdit.vue';

export default {
  setup() {
    const servicesInstance = useServices();
    const {
      services, fetch, openEditModal, deleteService, openAddModal,
    } = servicesInstance;

    provide('servicesInstance', servicesInstance);

    fetch();

    return {
      services, openEditModal, deleteService, openAddModal,
    };
  },

  components: {
    SettingsTabCrmServicesModalAdd,
    SettingsTabCrmServicesModalEdit,
    VsButton,
    VsList,
    AuthorizationGuard,
  },
};
</script>
