<template>
  <div class="investments-edit-material-modal">
    <b-modal
      id="modal-investments-edit-material"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.material.edit.title')"

      v-model="editMaterialModal.visible"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.material.edit.loading')"
        v-if="isLoading"
      />

      <investment-material-form
        v-model="fields"
        :feedback="feedback"
      />

      <template #modal-footer>
        <div class="investment-modal-footer">

          <div class="buttons">
            <vs-button
              variant="outline"
              @click="editMaterialModal.visible = false"
            >
              {{ $t('investments.modal.material.edit.cancel') }}
            </vs-button>

            <vs-button
              variant="primary"
              @click="editMaterial"
            >
              {{ $t('investments.modal.material.edit.confirm') }}
            </vs-button>
          </div>

        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import showToast from '@/libs/toasts';
import { EditMaterialError, EditMaterialSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import formatDate from '@/libs/date-formatter';
import RemoteResourceManager from '@/libs/RemoteResourceManager';
import InvestmentMaterialForm from '../forms/InvestmentMaterialForm.vue';

export default {
  name: 'InvestmentsEditOfferModal',
  props: {},
  setup(_props, { emit }) {
    const { editMaterialModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const {
      fields, feedback, validateAllFields, resetFields, validateField,
      assignFields, nonEmptyFields, assignServerSideErrors,
    } = useValidatedForm({
      id: 'integer',
      name: 'required',
      core_unit_id: 'required|integer',
    }, 'investments.form.material.field');

    watch(() => editMaterialModal.value.visible, () => {
      const { data } = editMaterialModal.value;
      if (data && Object.keys(data).length === 0) return;

      assignFields({
        ...editMaterialModal.value.data,
      });

      editMaterialModal.value.data = {};
    });

    const editMaterial = async () => {
      const validFields = await validateAllFields();
      if (!validFields.every((field) => field)) return;
      if (!fields.value.id) return;

      isLoading.value = true;

      if (!fields.value.whole_period) {
        fields.value.guarantee_price_date = formatDate.standard(fields.value.guarantee_price_date);
      }

      useInvestmentsApi()
        .updateMaterial(fields.value.id, {
          ...nonEmptyFields.value,
        })
        .then(({ data }) => {
          showToast(EditMaterialSuccess, { name: data.data.name });
          emit('success', data.data);

          resetFields();
          RemoteResourceManager.getInstance().notify('investments.material');
          editMaterialModal.value.visible = false;
          isLoading.value = false;
        })
        .catch(({ response }) => {
          showToast(EditMaterialError);
          assignServerSideErrors(response?.data?.errors);
          isLoading.value = false;
        });
    };

    return {
      fields,
      feedback,
      editMaterialModal,
      isLoading,
      editMaterial,
      validateField,
    };
  },
  components: {
    VsLoader,
    VsButton,
    InvestmentMaterialForm,
  },
};
</script>

<style lang="sass" scoped>
.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between

  .buttons
    margin-left: auto
</style>
