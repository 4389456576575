export default {
  statusDropdown: {
    button: {
      pick: 'Wybierz status',
    },
  },
  notes: {
    name: 'Notatki',
    button: {
      add: 'Dodaj notatkę',
      update: 'Aktualizuj notatkę',
    },
    modal: {
      title: 'Dodaj nową notatkę',
      field: {
        title: 'Tytuł notatki',
        description: 'Treść notatki',
      },
      delete: {
        title: 'Potwierdź usunięcie notatki',
        text: 'Czy na pewno chcesz usunąć notatkę {name}?',
      },
    },
  },
  notificationBell: {
    title: {
      header: 'Powiadomienia',
      itemCount: 'Wyświetlono {count} z {maxCount} nieodczytanych powiadomień.',
      noUnreadNotifications: 'Nie masz żadnych nieodczytanych powiadomień',
    },
    badge: {
      unread: 'Nowe',
    },
    button: {
      readHistory: 'Wyświetl pełną historię',
      markAllAsRead: 'Oznacz jako przeczytane',
    },
  },
  attachments: {
    name: 'Załączniki',
    title: {
      add: 'Dodawanie nowego załącznika',
    },
    field: {
      name: 'Nazwa',
      access: 'Dostęp',
      date: 'Data dodania',
      edit: 'Edycja',
      uploadFile: 'Kliknij, aby załączyć plik...',
      dropFile: 'Upuść załącznik tutaj',
      filename: 'Nazwa załącznika',
    },
    modal: {
      delete: {
        title: 'Potwierdź usuwanie załącznika',
        text: 'Czy na pewno chcesz usunąć załącznik {filename}?',
      },
    },
    button: {
      add: 'Załącz',
      addNew: 'Dodaj załącznik',
      cancel: 'Anuluj dodawanie',
      browse: 'Przeglądaj',
      protected: {
        header: 'Plik zastrzeżony',
        description: 'Nie masz dostępu do tego załącznika',
      },
    },
  },
  comments: {
    name: 'Komentarze',
    title: {
      add: 'Dodawanie nowego komentarza',
      edit: 'Edytowanie komentarza',
    },
    field: {
      title: 'Tytuł',
      content: 'Treść',
    },
    modal: {
      delete: {
        title: 'Potwierdź usuwanie komentarza',
        text: 'Czy na pewno chcesz usunąć ten komentarz?',
      },
    },
    button: {
      add: 'Dodaj',
      confirmAdd: 'Opublikuj komentarz',
      confirmEdit: 'Zaktualizuj treść',
      cancelAdd: 'Anuluj dodawanie',
      cancelEdit: 'Anuluj edycję',
    },
  },
  picker: {
    default: {
      noOptions: 'Brak dostępnych opcji',
    },
    machine: {
      noOptions: 'Brak maszyn do wyświetlenia',
    },
    investment: {
      noOptions: 'Brak inwestycji do wyświetlenia',
    },
    resource: {
      noOptions: 'Brak maszyn / sprzętów do wyświetlenia',
    },
    brigade: {
      noOptions: 'Brak brygad do wyświetlenia',
    },
    user: {
      noOptions: 'Nie ma takiego użytkownika',
    },
    priority: {
      noOptions: 'Nie ma takiego priorytetu',
    },
    currency: {
      noOptions: 'Nie ma takiej waluty',
    },
    unit: {
      noOptions: 'Nie ma takiej jednostki',
    },
    branch: {
      noOptions: 'Nie ma takiego regionu',
    },
    status: {
      noOptions: 'Brak statusów do wyświetlenia',
    },
    standard: {
      noOptions: 'Brak danych',
    },
    industry: {
      noOptions: 'Brak branż do wyświetlenia',
    },
    category: {
      noOptions: 'Brak kategorii do wyświetlenia',
    },
    producer: {
      noOptions: 'Brak producentów do wyświetlenia',
    },
    group: {
      noOptions: 'Brak grup do wyświetlenia',
    },
    tool: {
      noOptions: 'Brak sprzętu do wyświetlenia',
    },
  },
  editor: {
    button: {
      bold: 'Pogrubienie',
      italic: 'Kursywa',
      strike: 'Przekreślenie',
      code: 'Kod',
      paragraph: 'Akapit',
      heading1: 'Nagłówek 1',
      heading2: 'Nagłówek 2',
      bulletList: 'Lista',
      orderedList: 'Lista numerowana',
      blockquote: 'Cytat',
      horizontalRule: 'Podział',
      hardBreak: 'Twarda spacja',
      undo: 'Cofnij zmianę',
      redo: 'Przywróć zmianę',
    },
  },
  orderSubject: {
    name: 'Przedmiot zlecenia',
    title: {
      add: 'Dodawanie przedmiotu zlecenia',
      edit: 'Edytowanie przedmiotu zlecenia',
    },
    field: {
      name: 'Nazwa',
      price: 'Cena jednostkowa',
      quantity: 'Ilość',
      value: 'Wartość',
      sum: 'Suma',
      unit: 'Jednostka',
    },
    modal: {
      delete: {
        title: 'Potwierdź usuwanie przedmiotu zlecenia',
        text: 'Czy na pewno chcesz usunąć przedmiot "{name}" o łącznej wartości {price} {unit}?',
      },
    },
    button: {
      confirmAdd: 'Dodaj przedmiot zlecenia',
      confirmEdit: 'Zaktualizuj  przedmiot zlecenia',
      cancelAdd: 'Anuluj dodawanie',
      cancelEdit: 'Anuluj edycję',
    },
  },
  table: {
    field: {
      search: 'Wyszukaj',
      entryNumber: 'rekordów ({number} rekordów łącznie)',
      displaying: 'Wyświetlaj',
    },
  },
  userLabel: {
    you: 'ty',
  },
};
