var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header-section', {
    attrs: {
      "title": _vm.$t('component.comments.name'),
      "action": _vm.$t('component.comments.button.add'),
      "canAction": _vm.canAdd
    },
    on: {
      "actionClick": _vm.openCommentEditor
    }
  }, [_c('b-collapse', {
    staticClass: "mt-2",
    attrs: {
      "id": "comment-collapse"
    },
    model: {
      value: _vm.commentCollapseVisible,
      callback: function ($$v) {
        _vm.commentCollapseVisible = $$v;
      },
      expression: "commentCollapseVisible"
    }
  }, [_c('b-card', {
    staticClass: "m-0 p-0 border",
    attrs: {
      "title": _vm.editorTranslations.title
    }
  }, [_c('b-form-group', {
    staticClass: "mt-1",
    attrs: {
      "label": _vm.$t('component.comments.field.content')
    }
  }, [_c('editor', {
    model: {
      value: _vm.newComment.content,
      callback: function ($$v) {
        _vm.$set(_vm.newComment, "content", $$v);
      },
      expression: "newComment.content"
    }
  })], 1), _c('div', {
    staticClass: "comment-buttons"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": _vm.cancelEditing
    }
  }, [_vm._v(" " + _vm._s(_vm.editorTranslations.cancel) + " ")]), _c('vs-button', {
    staticClass: "vs-edit-button",
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.publishComment
    }
  }, [_vm._v(" " + _vm._s(_vm.editorTranslations.confirm) + " ")])], 1)], 1)], 1), _c('app-collapse', _vm._l(_vm.items, function (item) {
    return _c('comment-item', {
      key: item.id,
      attrs: {
        "comment": item,
        "canUpdate": _vm.canUpdate,
        "canDelete": _vm.canDelete
      },
      on: {
        "remove": _vm.removeComment,
        "edit": _vm.editComment
      }
    });
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }