import showToast from '@/libs/toasts';
import {
  CreateProducerModelError,
  CreateProducerModelSuccess, DeleteProducerModelError, DeleteProducerModelSuccess,
  FetchProducersModelsError, UpdateProducerModelError, UpdateProducerModelSuccess,
} from '@/libs/toasts/feedback/module/base';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import { ref } from 'vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';
import useBaseStore from '../useBaseStore';
import useBaseApi from '../useBaseApi';

export default function useBaseProducerModels() {
  const { producersModels } = useBaseStore();
  const producerModel = ref({});
  const modalAddVisible = ref(false);
  const modalEditVisible = ref(false);

  const isLoading = ref(false);

  const fetchProducersModels = (force = false) => {
    if (producersModels.value.length > 0 && !force) return producersModels;

    const req = useBaseApi()
      .fetchProducersModels()
      .then(({ data }) => {
        producersModels.value = data.data;
      })
      .catch(() => showToast(FetchProducersModelsError));

    return req;
  };

  const createProducerModel = () => {
    isLoading.value = true;

    useBaseApi()
      .createProducersModels(producerModel.value)
      .then(({ data }) => {
        isLoading.value = false;
        producersModels.value.push(data.data);
        modalAddVisible.value = false;

        showToast(CreateProducerModelSuccess, { name: data.data.name });
        RemoteResourceManager.getInstance().notify('base.producer-model');
        producerModel.value = {};
      })
      .catch(() => {
        isLoading.value = false;
        showToast(CreateProducerModelError, { name: producerModel.value.name });
      });
  };

  const editProducerModel = () => {
    isLoading.value = true;

    useBaseApi()
      .updateProducersModels(producerModel.value)
      .then(({ data }) => {
        isLoading.value = false;
        producersModels.value = producersModels.value.map((s) => (
          s.id === data.data.id ? { ...s, type: data.data.type } : s
        ));
        modalEditVisible.value = false;

        showToast(UpdateProducerModelSuccess, { name: data.data.name });
        RemoteResourceManager.getInstance().notify('base.producer-model');
        producerModel.value = {};
        fetchProducersModels(true);
      })
      .catch(() => {
        isLoading.value = false;
        showToast(UpdateProducerModelError, { name: producerModel.value.name });
      });
  };

  const deleteProducerModel = async (requested) => {
    const confirmation = await deleteModal({
      title: i18n.t('base.producer.modal.delete.title'),
      text: i18n.t('base.producer.modal.delete.text', {
        name: requested.name,
      }),
    });

    if (!confirmation) return false;

    return useBaseApi()
      .deleteProducersModels(requested.id)
      .then(() => {
        producersModels.value = producersModels.value.filter((s) => s.id !== requested.id);
        showToast(DeleteProducerModelSuccess, { name: requested.name });
        RemoteResourceManager.getInstance().notify('base.producer-model');
      })
      .catch(() => showToast(DeleteProducerModelError, { name: requested.name }));
  };

  const openAddModal = () => {
    producerModel.value = {};
    modalAddVisible.value = true;
  };

  const openEditModal = (edited) => {
    producerModel.value = { ...edited, base_resource_type_id: edited?.type?.slug };
    modalEditVisible.value = true;
  };

  return {
    isLoading,
    modalAddVisible,
    modalEditVisible,
    producerModel,
    producersModels,
    createProducerModel,
    editProducerModel,
    fetchProducersModels,
    deleteProducerModel,
    openEditModal,
    openAddModal,
  };
}
