var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "remote-resource-manager-simulation"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.resourceName,
      expression: "resourceName"
    }],
    attrs: {
      "type": "text",
      "placeholder": "Nazwa zasobu"
    },
    domProps: {
      "value": _vm.resourceName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.resourceName = $event.target.value;
      }
    }
  }), _c('button', {
    on: {
      "click": _vm.simulate
    }
  }, [_vm._v(" Wyślij \"pseudo-websocket\" ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }