/* eslint-disable */
import store from '@/store';
import Vue from 'vue';

/**
 * Create comment node
 *
 * @private
 * @author https://stackoverflow.com/questions/43003976/a-custom-directive-similar-to-v-if-in-vuejs#43543814
 */
function commentNode(el, vnode) {
  const comment = document.createComment(' ')

  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined
  })

  vnode.text = ' '
  vnode.elm = comment
  vnode.isComment = true
  vnode.context = undefined
  vnode.tag = undefined
  vnode.data.directives = undefined

  if (vnode.componentInstance) {
    vnode.componentInstance.$el = comment
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el)
  }
}

Vue.directive('can', (el, bindings, vnode) => {
  const invert = bindings.modifiers.invert ?? false;
  const behaviour = bindings.modifiers.disable ? 'disable' : 'hide';
  const ok = store.getters['auth/userHasPermission'](bindings.arg, bindings.value);

  if ((!ok && !invert) || (ok && invert)) {
    if (behaviour === 'hide') {
      commentNode(el, vnode)
    } else if (behaviour === 'disable') {
      el.setAttribute('disabled', true);
    }
  }
});
