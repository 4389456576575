<template>
  <div>

    <authorization-guard
      silent
      permission="cadre.position.store"
    >
      <div>
        <vs-button
          v-b-modal.modal-cadre-position-add
          vs-variant="primary"
        >
          + {{ $t('settings.cadre.positions.button.add') }}
        </vs-button>
      </div>
    </authorization-guard>

    <authorization-guard
      permission="cadre.position.index"
    >
      <table>
        <thead>
          <th>Nazwa</th>
          <th>Grupa</th>
          <th>Stawka dzienna</th>
          <th>Edycja</th>
        </thead>
        <tbody>
          <tr v-for="position in positions" :key="position.id">
            <td>{{ position.name }}</td>
            <td><group-label :id="position.group_id"/></td>
            <td>{{ (position.actual_daily_cost || 0).toLocaleString() }} PLN</td>
            <td class="edit">
              <edit-button :items="editOptions" :target="position"/>
            </td>
          </tr>
        </tbody>
      </table>
    </authorization-guard>

    <modal-edit/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EditButton from '@/components/ui/vs-table/TableActionButton.vue';
import ModalEdit from '@/components/views/settings/content-tabs/cadre/modals/position/Edit.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import { deleteModal } from '@/libs/modals';
import useModal from '@/hooks/useModal';
import {
  DeletePositionSuccess,
  ServerError,
  PositionObjectNotFound,
} from '@feedback/module/cadre';
import GroupLabel from '@/components/views/cadre/ui/GroupLabel.vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

export default {
  name: 'PositionsManagement',
  components: {
    EditButton,
    ModalEdit,
    VsButton,
    GroupLabel,
    AuthorizationGuard,
  },
  computed: {
    ...mapGetters({
      positions: 'cadre/positions/all',
      current: 'cadre/positions/byId',
    }),
    skillEditModel: {
      get() {
        if (!this.currentPosition.provider) {
          return {
            ...this.currentPosition,
          };
        }

        return {
          ...this.currentPosition,
        };
      },
    },
  },
  setup(_props, context) {
    const { openModal } = useModal();

    const openEditModal = (position) => {
      openModal('modal-cadre-position-edit', context, {
        ...position,
      });
    };

    return { openEditModal };
  },
  methods: {
    async deletePosition(position = this.position) {
      const confirmation = await deleteModal({
        title: this.$i18n.t('settings.cadre.positions.modal.delete.title'),
        text: this.$i18n.t('settings.cadre.positions.modal.delete.text', { name: position.name }),
      });
      if (!confirmation) return;

      this.$store.dispatch('cadre/positions/delete', {
        id: position.id,
      })
        .then(() => {
          this.$showFeedback(DeletePositionSuccess);
          this.loading = false;
          RemoteResourceManager.getInstance().notify('cadre.position');
        })
        .catch((err) => {
          let Feedback = ServerError;
          if (err?.response?.data?.errors) {
            Feedback = PositionObjectNotFound;
          }

          this.$showFeedback(Feedback);
          this.loading = false;
          this.errors = err?.response?.data?.errors;
        });
    },
    editPosition(position) {
      this.openEditModal({ ...position, cadre_position_daily_cost: position.actual_daily_cost });
    },
    currentPosition(id) {
      return this.current(id);
    },
  },
  data() {
    return {
      position: {},
      editOptions: [
        {
          title: this.$t('global.button.edit'),
          icon: 'CheckCircle',
          callback: this.editPosition,
        },
        {
          title: this.$t('global.button.delete'),
          icon: 'Trash2',
          callback: this.deletePosition,
        },
      ],
    };
  },
  created() {
    this.$store.dispatch('cadre/positions/fetch');
  },
};
</script>

<style lang="sass" scoped>
table
  width: 100%
  margin-top: 25px

  td
    padding: 10px 0

    &.expiry-date
      display: flex
      align-items: center
      color: #65b259

      &.expired
        color: #b2595a

      .icon
        margin-right: 10px

  tr:not(:last-child)
    td
      border-bottom: 1px solid rgba(58, 59, 61, 0.3)

  thead
    border-bottom: 2px solid rgba(1, 4, 9, 0.3)

.action-buttons
  position: absolute
  bottom: 15px

.action-button
  margin: 10px 0

.box-color
  height: 30px
  width: 100px
</style>
