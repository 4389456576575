var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settings-tab-investments-work-group"
  }, [_c('authorization-guard', {
    attrs: {
      "silent": "",
      "permission": "investment.work_group.store"
    }
  }, [_c('div', {
    staticClass: "buttons"
  }, [_c('div', {
    staticClass: "group"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.addWorkGroupModal.visible = true;
      }
    }
  }, [_vm._v(" + Grupa ")])], 1)])]), _c('authorization-guard', {
    attrs: {
      "permission": "investment.work_group.index"
    }
  }, [_c('vs-list', {
    attrs: {
      "showHeaders": "",
      "items": _vm.workGroups,
      "headers": ['Nazwa']
    },
    on: {
      "edit": _vm.openEditModal,
      "delete": _vm.deleteWorkGroup
    }
  })], 1), _c('investment-work-group-modal-add', {
    on: {
      "success": _vm.addWorkGroup
    }
  }), _c('investment-work-group-modal-edit', {
    on: {
      "success": _vm.updateWorkGroup
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }