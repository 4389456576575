import InvestmentsFeedback from '@feedback/module/investments/InvestmentsFeedback';

/**
 * Defined Feedback objects.
 *
 * Currently it looks messy, but the whole point of
 * defining each error separately (instead of using plain i18n)
 * is to allow further changes to the Feedback structure, e.g.
 * types, actions, custom templates and more settings.
 *
 * TODO: break this down into separate files whenever the Feedback
 *       logic will be ready
 *
 * After adding a feedback instance below, remember to add the proper
 * key to i18n
 */

/** Negative Feedbacks */
export const CreateInvestmentError = new InvestmentsFeedback('CreateInvestmentError', false);
export const UpdateInvestmentError = new InvestmentsFeedback('UpdateInvestmentError', false);
export const DeleteInvestmentError = new InvestmentsFeedback('DeleteInvestmentError', false);
export const FetchInvestmentError = new InvestmentsFeedback('FetchInvestmentError', false);

export const UploadInvestmentOfferError = new InvestmentsFeedback('UploadInvestmentOfferError', false);
export const FetchWorkSummaryError = new InvestmentsFeedback('FetchWorkSummaryError', false);

export const CreateFuelingError = new InvestmentsFeedback('CreateFuelingError', false);
export const UpdateFuelingError = new InvestmentsFeedback('UpdateFuelingError', false);
export const DeleteFuelingError = new InvestmentsFeedback('DeleteFuelingError', false);

export const AssignSubjectError = new InvestmentsFeedback('AssignSubjectError', false);
export const EditSubjectError = new InvestmentsFeedback('EditSubjectError', false);
export const DeleteSubjectError = new InvestmentsFeedback('DeleteSubjectError', false);

export const CreateDemandError = new InvestmentsFeedback('CreateDemandError', false);
export const EditDemandError = new InvestmentsFeedback('EditDemandError', false);
export const DeleteDemandError = new InvestmentsFeedback('DeleteDemandError', false);

export const CreateReinforcementError = new InvestmentsFeedback('CreateReinforcementError', false);
export const EditReinforcementError = new InvestmentsFeedback('EditReinforcementError', false);
export const DeleteReinforcementError = new InvestmentsFeedback('DeleteReinforcementError', false);

export const CreateWorkTypeError = new InvestmentsFeedback('CreateWorkTypeError', false);
export const EditWorkTypeError = new InvestmentsFeedback('EditWorkTypeError', false);
export const DeleteWorkTypeError = new InvestmentsFeedback('DeleteWorkTypeError', false);

export const CreateWorkGroupError = new InvestmentsFeedback('CreateWorkGroupError', false);
export const EditWorkGroupError = new InvestmentsFeedback('EditWorkGroupError', false);
export const DeleteWorkGroupError = new InvestmentsFeedback('DeleteWorkGroupError', false);

export const CreateMaterialError = new InvestmentsFeedback('CreateMaterialError', false);
export const EditMaterialError = new InvestmentsFeedback('EditMaterialError', false);
export const DeleteMaterialError = new InvestmentsFeedback('DeleteMaterialError', false);

export const CreateOfferError = new InvestmentsFeedback('CreateOfferError', false);
export const EditOfferError = new InvestmentsFeedback('EditOfferError', false);
export const DeleteOfferError = new InvestmentsFeedback('DeleteOfferError', false);
export const AcceptOfferError = new InvestmentsFeedback('AcceptOfferError', false);

export const AssignResourceError = new InvestmentsFeedback('AssignResourceError', false);
export const EditResourceError = new InvestmentsFeedback('EditResourceError', false);
export const UpdateManyResourceError = new InvestmentsFeedback('UpdateManyResourceError', false);
export const DeleteResourceError = new InvestmentsFeedback('DeleteResourceError', false);

export const AssignEmployeeError = new InvestmentsFeedback('AssignEmployeeError', false);
export const EditEmployeeError = new InvestmentsFeedback('EditEmployeeError', false);
export const DeleteEmployeeError = new InvestmentsFeedback('DeleteEmployeeError', false);

export const CreateBrigadeError = new InvestmentsFeedback('CreateBrigadeError', false);
export const EditBrigadeError = new InvestmentsFeedback('EditBrigadeError', false);
export const DeleteBrigadeError = new InvestmentsFeedback('DeleteBrigadeError', false);

export const CreateInvestmentReportError = new InvestmentsFeedback('CreateInvestmentReportError', false);
export const UpdateInvestmentReportError = new InvestmentsFeedback('UpdateInvestmentReportError', false);
export const DeleteInvestmentReportError = new InvestmentsFeedback('DeleteInvestmentReportError', false);
export const CreateInvestmentReportInvalid = new InvestmentsFeedback('CreateInvestmentReportInvalid', false);

export const AttachmentSizeError = new InvestmentsFeedback('AttachmentSizeError', false);

/** Positive Feedbacks */
export const CreateInvestmentSuccess = new InvestmentsFeedback('CreateInvestmentSuccess', true);
export const UpdateInvestmentSuccess = new InvestmentsFeedback('UpdateInvestmentSuccess', true);
export const DeleteInvestmentSuccess = new InvestmentsFeedback('DeleteInvestmentSuccess', true);

export const CreateFuelingSuccess = new InvestmentsFeedback('CreateFuelingSuccess', true);
export const UpdateFuelingSuccess = new InvestmentsFeedback('UpdateFuelingSuccess', true);
export const DeleteFuelingSuccess = new InvestmentsFeedback('DeleteFuelingSuccess', true);

export const AssignSubjectSuccess = new InvestmentsFeedback('AssignSubjectSuccess', true);
export const EditSubjectSuccess = new InvestmentsFeedback('EditSubjectSuccess', true);
export const DeleteSubjectSuccess = new InvestmentsFeedback('DeleteSubjectSuccess', true);

export const CreateDemandSuccess = new InvestmentsFeedback('CreateDemandSuccess', true);
export const EditDemandSuccess = new InvestmentsFeedback('EditDemandSuccess', true);
export const DeleteDemandSuccess = new InvestmentsFeedback('DeleteDemandSuccess', true);

export const CreateReinforcementSuccess = new InvestmentsFeedback('CreateReinforcementSuccess', true);
export const EditReinforcementSuccess = new InvestmentsFeedback('EditReinforcementSuccess', true);
export const DeleteReinforcementSuccess = new InvestmentsFeedback('DeleteReinforcementSuccess', true);

export const CreateWorkTypeSuccess = new InvestmentsFeedback('CreateWorkTypeSuccess', true);
export const EditWorkTypeSuccess = new InvestmentsFeedback('EditWorkTypeSuccess', true);
export const DeleteWorkTypeSuccess = new InvestmentsFeedback('DeleteWorkTypeSuccess', true);

export const CreateWorkGroupSuccess = new InvestmentsFeedback('CreateWorkGroupSuccess', true);
export const EditWorkGroupSuccess = new InvestmentsFeedback('EditWorkGroupSuccess', true);
export const DeleteWorkGroupSuccess = new InvestmentsFeedback('DeleteWorkGroupSuccess', true);

export const CreateMaterialSuccess = new InvestmentsFeedback('CreateMaterialSuccess', true);
export const EditMaterialSuccess = new InvestmentsFeedback('EditMaterialSuccess', true);
export const DeleteMaterialSuccess = new InvestmentsFeedback('DeleteMaterialSuccess', true);

export const CreateOfferSuccess = new InvestmentsFeedback('CreateOfferSuccess', true);
export const EditOfferSuccess = new InvestmentsFeedback('EditOfferSuccess', true);
export const DeleteOfferSuccess = new InvestmentsFeedback('DeleteOfferSuccess', true);
export const AcceptOfferSuccess = new InvestmentsFeedback('AcceptOfferSuccess', true);

export const AssignResourceSuccess = new InvestmentsFeedback('AssignResourceSuccess', true);
export const EditResourceSuccess = new InvestmentsFeedback('EditResourceSuccess', true);
export const DeleteResourceSuccess = new InvestmentsFeedback('DeleteResourceSuccess', true);
export const UpdateManyResourceSuccess = new InvestmentsFeedback('UpdateManyResourceSuccess', true);

export const AssignEmployeeSuccess = new InvestmentsFeedback('AssignEmployeeSuccess', true);
export const EditEmployeeSuccess = new InvestmentsFeedback('EditEmployeeSuccess', true);
export const DeleteEmployeeSuccess = new InvestmentsFeedback('DeleteEmployeeSuccess', true);

export const CreateBrigadeSuccess = new InvestmentsFeedback('CreateBrigadeSuccess', true);
export const EditBrigadeSuccess = new InvestmentsFeedback('EditBrigadeSuccess', true);
export const DeleteBrigadeSuccess = new InvestmentsFeedback('DeleteBrigadeSuccess', true);

export const CreateInvestmentReportSuccess = new InvestmentsFeedback('CreateInvestmentReportSuccess', true);
export const UpdateInvestmentReportSuccess = new InvestmentsFeedback('UpdateInvestmentReportSuccess', true);
export const DeleteInvestmentReportSuccess = new InvestmentsFeedback('DeleteInvestmentReportSuccess', true);
