<template>
  <div>
    <authorization-guard
      permission="cadre.employment_form.index"
    >
      <table>
        <thead>
          <th>Forma zatrudnienia</th>
        </thead>
        <tbody>
          <tr v-for="employment in employments" :key="employment.id">
            <td>{{ employment.name }}</td>
          </tr>
        </tbody>
      </table>
    </authorization-guard>

    <modal-add/>
    <modal-edit/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalAdd from '@/components/views/settings/content-tabs/cadre/modals/employment-forms/Add.vue';
import ModalEdit from '@/components/views/settings/content-tabs/cadre/modals/employment-forms/Edit.vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';

export default {
  name: 'EmploymentFormsManagement',
  components: {
    ModalAdd,
    ModalEdit,
    AuthorizationGuard,
  },
  computed: {
    ...mapGetters({
      employments: 'cadre/employmentForms/all',
    }),
  },
  created() {
    this.$store.dispatch('cadre/employmentForms/fetch');
  },
};
</script>

<style lang="sass" scoped>
table
  width: 100%
  margin-top: 25px

  td
    padding: 10px 0

    &.expiry-date
      display: flex
      align-items: center
      color: #65b259

      &.expired
        color: #b2595a

      .icon
        margin-right: 10px

  tr:not(:last-child)
    td
      border-bottom: 1px solid rgba(58, 59, 61, 0.3)
  thead
    border-bottom: 2px solid rgba(1, 4, 9, 0.3)

.action-buttons
  position: absolute
  bottom: 15px

.action-button
  margin: 10px 0

.box-color
  height: 30px
  width: 100px
</style>
