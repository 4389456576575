export default {
  pageTitle: 'Moje konto',
  settings: {
    pageTitle: 'Ustawienia konta',
    device: {
      field: {
        name: 'Nazwa urządzenia',
      },
      modal: {
        delete: {
          title: 'Potwierdź usunięcie zaufanego urządzenia',
          text: 'Urządzenie "{name}" zostanie usunięte. Ponowne logowanie na powiązanym urządzeniu będzie możliwe po wygenerowaniu nowego kodu. Czy na pewno chcesz kontynuować?',
        },
      },
    },
  },
};
