<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useCoreCurrencyStore from '@/stores/module/core/useCoreCurrencyStore';

export default Vue.component('CoreCurrencyPicker', {
  emits,
  setup(_, ctx) {
    const store = useCoreCurrencyStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz walutę z listy',
      reduce: (option) => option.id,
      options: store.state,
      ...ctx.attrs,
    }, {
      option: ({ option }) => `${option.name} - ${option.code}`,
      'selected-option': ({ option }) => `${option.name} - ${option.code}`,
    }));
  },
  components: { VsPickerVue },
});
</script>
