<template>
  <div class="settings-tab-investments-reinforcement">

    <authorization-guard
      silent
      permission="investment.subject_reinforcement.store"
    >
      <div class="buttons">
        <div class="group">
          <vs-button
            vs-variant="primary"
            @click="addReinforcementModal.visible = true"
          >
            + Zbrojenie
          </vs-button>
        </div>
      </div>
    </authorization-guard>

    <authorization-guard
      permission="investment.subject_reinforcement.index"
    >
      <vs-list showHeaders
        :items="reinforcements"
        @edit="openEditModal"
        @delete="deleteReinforcement"
        :headers="['Nazwa']"
      >
      </vs-list>
    </authorization-guard>

    <investment-reinforcement-modal-add @success="addReinforcement" />
    <investment-reinforcement-modal-edit @success="updateReinforcement" />
  </div>
</template>

<script>
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import VsList from '@/components/ui/vs-list/VsList.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import showToast from '@/libs/toasts';
import { DeleteReinforcementSuccess, DeleteReinforcementError } from '@/libs/toasts/feedback/module/investments';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';
import InvestmentReinforcementModalEdit from './modals/InvestmentReinforcementModalEdit.vue';
import InvestmentReinforcementModalAdd from './modals/InvestmentReinforcementModalAdd.vue';

export default {
  name: 'SettingsTabInvestmentReinforcement',
  setup() {
    const { reinforcements, addReinforcementModal, editReinforcementModal } = useInvestmentsStore();

    useInvestmentsApi()
      .fetchPickerReinforcements()
      .then(({ data }) => {
        reinforcements.value = [...data.data];
      });

    const openEditModal = (reinforcement) => {
      editReinforcementModal.value.visible = true;
      editReinforcementModal.value.data = { ...reinforcement };
    };

    const addReinforcement = (reinforcement) => {
      reinforcements.value.push({ ...reinforcement });
      RemoteResourceManager.getInstance().notify('investments.reinforcement');
    };

    const updateReinforcement = (reinforcement) => {
      reinforcements.value = reinforcements.value.map((m) => {
        if (m.id !== reinforcement.id) return m;
        return reinforcement;
      });
      RemoteResourceManager.getInstance().notify('investments.reinforcement');
    };

    const deleteReinforcement = async (requested) => {
      const confirmation = await deleteModal({
        title: i18n.t('investments.modal.reinforcement.delete.title'),
        text: i18n.t('investments.modal.reinforcement.delete.text', {
          name: requested.name,
        }),
      });

      if (!confirmation) return false;

      return useInvestmentsApi()
        .deleteReinforcement(requested.id)
        .then(() => {
          showToast(DeleteReinforcementSuccess, { name: requested.name });
          RemoteResourceManager.getInstance().notify('investments.reinforcement');
          reinforcements.value = reinforcements.value
            .filter((s) => s.id !== requested.id);
        })
        .catch(() => showToast(DeleteReinforcementError, { name: requested.name }));
    };

    return {
      reinforcements,

      addReinforcementModal,
      openEditModal,

      addReinforcement,
      updateReinforcement,
      deleteReinforcement,
    };
  },
  components: {
    VsButton,
    VsList,
    InvestmentReinforcementModalAdd,
    InvestmentReinforcementModalEdit,
    AuthorizationGuard,
  },
};
</script>
