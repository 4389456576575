import base from './base';
import cadre from './cadre';
import investments from './investments';
import orders from './orders';
import tasks from './tasks';
import crm from './crm';

export default {
  base,
  crm,
  cadre,
  investments,
  orders,
  tasks,
};
