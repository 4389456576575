<template>
  <div class="vs-table">

    <b-form-group v-if="showSearch">
      <b-input-group class="input-group-merge">

        <b-input-group-prepend is-text v-if="search" @click="() => search = ''" class="clear-input">
          <feather-icon class="icon" icon="XIcon" />
        </b-input-group-prepend>

        <b-input-group-prepend is-text v-else @click="() => $refs.searchTerm.focus()">
          <feather-icon class="icon" icon="SearchIcon" />
        </b-input-group-prepend>

        <b-form-input
          ref="searchTerm"
          :placeholder="$t('component.table.field.search')"
          v-model="search"
        />

      </b-input-group>
    </b-form-group>

    <vue-good-table
      :columns="gtColumns"
      :rows="rows"
      :mode="mode"
      :search-options="{ enabled: true, externalQuery: search }"
      :select-options="gtOptions.select"
      :pagination-options="gtOptions.pagination"
      :totalRecords="totalRecords"
      :isLoading="isLoading"
      :sort-options="sortOptions"
      styleClass="vs-table bordered"
      @on-row-click.exact="(data) => $emit('on-row-click', data)"
      @on-page-change="(data) => $emit('on-page-change', data)"
      @on-per-page-change="(data) => $emit('on-per-page-change', data)"
      @on-sort-change="(data) => $emit('on-sort-change', data)"
    >

      <template #emptystate>
        Brak pozycji do wyświetlenia
      </template>

      <template v-slot:table-column="props" >
        <span>
          {{props.column.label}}
          <feather-icon v-if="checkSort(props.column.field) === 'desc'" icon="ChevronDownIcon"/>
          <feather-icon v-if="checkSort(props.column.field) === 'asc'" icon="ChevronUpIcon"/>
        </span>
      </template>

      <template #loadingContent>
        <div class="loading-spinner" v-if="!rows.length">
          <vs-loader full opaque />
        </div>

        <!-- Show small indicator if table has rows -->
        <div class="small-loading-indicator" v-else>
          <div class="small-loading-indicator-icon">
            <vs-loader inline text="Aktualizowanie danych..." />
          </div>
        </div>
      </template>

      <template
        v-slot:table-row="props"

        >

        <!-- Edit Button -->
        <span
          v-if="showActionButton && props.column.field === 'edit' && !props.row.hideActionButton"
          style="display: block; width: 50px; margin-left: auto"
          >

          <table-action-button :items="actionItems" :target="props.row" />

        </span>

        <!--
          Row Definitions
        -->
        <slot
          name="row"
          :column="props.column"
          :row="props.row"
          :index="props.index"
          >

          <!-- Default (Text) -->
          <span>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </slot>
      </template>

      <template
        v-slot:pagination-bottom="props"

        >
        <div
          class="d-flex justify-content-between flex-wrap"
          >
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              {{ $t('component.table.field.displaying', { number: props.total }) }}
            </span>
            <b-form-select
              v-model="gtOptions.pagination.perPage"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
            <span class="text-nowrap">
              {{ $t('component.table.field.entryNumber', { number: totalRecords }) }}
            </span>
          </div>
          <div>
            <b-pagination
              :value="currentPage"
              :total-rows="totalRecords"
              :per-page="gtOptions.pagination.perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
              >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                  />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                  />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BFormSelect,
  BPagination,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import TableActionButton from './TableActionButton.vue';

export default {
  components: {
    BFormSelect,
    BPagination,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    VueGoodTable,
    TableActionButton,
    VsLoader,
  },
  props: {
    mode: String,
    columns: Array,
    rows: Array,
    perPage: Number,
    showPagination: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    checkSort: {
      type: Function,
      default: () => {},
    },
    totalRecords: {
      type: Number,
      default: 9,
    },
    searchTerm: {
      type: String,
      default: null,
    },
    rowClicked: {
      type: Function,
      default: () => {},
    },
    showActionButton: {
      type: Boolean,
      default: false,
    },
    actionItems: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    sortOptions: {
      type: Object,
    },
  },
  computed: {
    gtColumns() {
      const cols = this.columns;
      const hasEditAlready = cols.filter((col) => col.field === 'edit').length;

      if (this.showActionButton && !hasEditAlready) {
        cols.push({
          label: '',
          field: 'edit',
        });
      }

      return cols;
    },
    search: {
      get() {
        return this.searchTerm || '';
      },
      set(val) {
        this.$emit('searchTermChange', val);
      },
    },
  },
  data() {
    return {
      gtOptions: {
        sort: {
          enabled: true,
        },
        select: {
          enabled: false,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        },
        pagination: {
          enabled: this.showPagination,
          perPage: this.perPage,
        },
      },
    };
  },
};
</script>

<style lang="sass">
.small-loading-indicator
  padding: 10px 0

.loading-spinner
  position: absolute
  top: 0
  z-index: 100
  width: 100%
  height: 100%

.clear-input
  .icon
    transition: 0.3s opacity ease-in-out

    &:hover
      cursor: pointer
      opacity: 0.5

.vgt-input
  padding: 10px 10px
  border-radius: 5px
  border: 1px solid rgba(black, 0.1)
  margin-bottom: 15px

.vs-table
  padding-bottom: 6px
  position: relative
  max-width: 100%
  overflow-x: auto

  .vgt-responsive
    width: 100%

    table
      width: 100%

    &::-webkit-scrollbar
      -webkit-appearance: none
      width: 10px
      height: 10px

    &::-webkit-scrollbar-thumb
      border-radius: 2px
      background-color: rgba(0, 0, 0, .3)
      box-shadow: 0 0 1px rgba(255, 255, 255, .5)

  th, td
    padding: 10px

  td
    padding: 10px 10px

  tr
    &:hover
      background: rgba(0, 0, 0, 0.03)
      cursor: pointer

  thead
    padding: 0 10px

    tr:hover
      background: none

    th
      background-color: rgba(16, 16, 16, 0.05)
      border-bottom: 1px solid rgba(0, 0, 0, 0.1)
</style>
