const dateFormat = require('date-format');

const patterns = {
  // date format required by backend and date pickers
  // do not change!
  standard: 'yyyy-MM-dd',
  standardTimed: 'yyyy-MM-dd hh:mm:ss',

  deviceDate: 'dd.MM.yyyy hh:mm:ss',
  employeeContract: 'dd-MM-yyyy',
  rent: 'dd.MM.yyyy',
  insurance: 'dd.MM.yyyy',
  transport: 'dd.MM.yyyy',
  training: 'dd.MM.yyyy',
  medicalExpiration: 'dd.MM.yyyy',
  noteCreated: 'yyyy-MM-dd',
  taskDeadline: 'dd.MM.yyyy',
  notification: 'hh:mm:ss dd.MM.yyyy',
  commentCreated: 'yyyy-MM-dd',
  timelineDate: 'yyyy-MM-dd',
  baseReport: 'dd.MM.yyyy - hh:mm',
  investmentFuel: 'dd.MM.yyyy - hh:mm',
  getTime: 'hh:mm',
  reportChart: 'dd/MM',
  dmy: 'dd/MM/yyyy',
  filename: 'dd.MM',
  scheduleWeek: 'dd/MM',
};

const formatDate = {};

Object.keys(patterns).forEach((key) => {
  formatDate[key] = (value) => {
    if (!value) return null;
    const date = value || Date.now();
    return dateFormat(patterns[key], new Date(date));
  };
});

export const daysFromToday = (targetDate, future = false) => {
  if (!targetDate) return null;

  const today = Date.now();
  const dateB = new Date(targetDate);

  const timeDiff = today - dateB.getTime();

  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  return future ? -days : days;
};

export const addDays = (date, days) => {
  const result = new Date(date);
  const newDate = result.getDate() + Math.round(days);
  result.setDate(newDate);
  return formatDate.standard(result);
};

export const daysCountBetweenDates = (dateA, dateB, excludedDays = []) => {
  let workDays = 0;
  const dateFrom = new Date(dateA);
  const dateTo = new Date(dateB);
  const dateIter = new Date(dateFrom.getTime());

  while (dateIter <= dateTo) {
    const dayOfWeek = dateIter.getDay();

    if (!excludedDays.includes(dayOfWeek)) {
      workDays += 1;
    }

    dateIter.setDate(dateIter.getDate() + 1);
  }
  return workDays;
};

export const getWeekday = (date) => {
  const d = new Date(date);
  return d.toLocaleString('pl-PL', { weekday: 'short' });
};

export default formatDate;
