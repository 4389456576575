import Feedback from '@feedback/Feedback';

/**
 * Feedback extension for Orders
 */

class OrderFeedback extends Feedback {
  constructor(namespace, isPositiveFeedback) {
    super(isPositiveFeedback);

    this.extendI18nNamespace('orders');
    this.extendI18nNamespace(namespace);
  }
}

export default OrderFeedback;
