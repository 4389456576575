<template>
  <b-dropdown
    id="dropdown-1"
    :text="selectedStatus?.name"
    :style="currentStyle"
    no-caret
    >

    <template #button-content>
      {{ selectedStatus?.name }}
      <feather-icon
        icon="ChevronDownIcon"
        size="18"
      />
    </template>

    <b-dropdown-item
      v-for="status in statuses"
      :key="status.id"
      :style="getStyling(status)"
      @click="$emit('pick', status.id)"
      >
      {{ status.name }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import getContrasting, { hexToRGBArray } from '@/libs/contrast';
import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import useTaskStatusStore from '@/stores/module/tasks/useTaskStatusStore';
import { computed } from 'vue';
import useOrdersStatusStore from '@/stores/module/orders/useOrdersStatusStore';
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  name: 'StatusDropdown',
  props: {
    currentStatusId: Number,
    source: {
      type: String,
      default: 'tasks',
    },
  },
  setup(props) {
    const taskStatusStore = useTaskStatusStore();
    const orderStatusStore = useOrdersStatusStore();

    const statusStore = computed(() => (props.source === 'tasks'
      ? taskStatusStore
      : orderStatusStore
    ));

    const statuses = computed(() => statusStore.value.state);

    const selectedStatus = computed(() => {
      if (!props.currentStatusId) return null;
      return statusStore.value.getById(props.currentStatusId);
    });

    const getStyling = ({ color }) => ({
      '--status-color': color,
      '--status-hover': getContrasting(color),
    });

    const selectedRgbString = computed(() => hexToRGBArray(selectedStatus.value.color).join(', '));

    const currentStyle = computed(() => {
      if (!selectedStatus.value) return null;
      return {
        '--current-status-color': selectedStatus.value.color,
        '--current-status-fg': getContrasting(selectedStatus.value.color),
        '--current-status-shadow': `rgba(${selectedRgbString.value}, 0.4)`,
      };
    });

    return {
      statuses,
      selectedStatus,
      getStyling,
      currentStyle,
    };
  },
  components: {
    BDropdown,
    BDropdownItem,
    FeatherIcon,
  },
};
</script>

<style lang="scss" scoped>
#dropdown-1::v-deep {
  button.dropdown-toggle {
    background-color: var(--current-status-color) !important;
    border-color: var(--current-status-color) !important;
    color: var(--current-status-fg) !important;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 9px 15px;

    &:hover {
      box-shadow: 0 5px 10px var(--current-status-shadow);
    }

    &::after {
      color: var(--current-status-fg) !important;
    }
  }

  a.dropdown-item {
    color: var(--status-color);
    font-weight: 500;

    &:hover {
      background-color: var(--status-color) !important;
      color: var(--status-hover) !important;
    }
  }
}
</style>
