import vehicles from './vehicles';
import reports from './reports';
import equipment from './equipment';
import cars from './cars';
import transport from './transport';
import statuses from './statuses';
import service from './service';
import examination from './examination';

export default {
  namespaced: true,
  modules: {
    vehicles,
    reports,
    equipment,
    cars,
    transport,
    statuses,
    service,
    examination,
  },
};
