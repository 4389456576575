import CadreFeedback from '@feedback/module/cadre/CadreFeedback';

/**
 * Defined Feedback objects.
 *
 * Currently it looks messy, but the whole point of
 * defining each error separately (instead of using plain i18n)
 * is to allow further changes to the Feedback structure, e.g.
 * types, actions, custom templates and more settings.
 *
 * TODO: break this down into separate files whenever the Feedback
 *       logic will be ready
 *
 * After adding a feedback instance below, remember to add the proper
 * key to i18n
 */

/** Negative Feedbacks */
export const DeleteError = new CadreFeedback('DeleteError', false);
export const ArchiveError = new CadreFeedback('ArchiveError', false);
export const RestoreError = new CadreFeedback('RestoreError', false);
export const ServerError = new CadreFeedback('ServerError', false);
export const BadInputError = new CadreFeedback('BadInputError', false);
export const EditError = new CadreFeedback('EditError', false);
export const FetchEmployeeError = new CadreFeedback(
  'FetchEmployeeError',
  false,
);
export const BadSkillInputError = new CadreFeedback(
  'BadSkillInputError',
  false,
);
export const GroupObjectNotFound = new CadreFeedback(
  'GroupObjectNotFound',
  false,
);
export const PositionObjectNotFound = new CadreFeedback(
  'PositionObjectNotFound',
  false,
);
export const SkillObjectNotFound = new CadreFeedback(
  'SkillObjectNotFound',
  false,
);
export const AddExaminationError = new CadreFeedback(
  'AddExaminationError',
  false,
);
export const UpdateExaminationError = new CadreFeedback(
  'UpdateExaminationError',
  false,
);
export const FetchExaminationHistoryError = new CadreFeedback(
  'FetchExaminationHistoryError',
  false,
);
export const EditEmployeeSkillsError = new CadreFeedback(
  'EditEmployeeSkillsError',
  false,
);
export const AddTrainingError = new CadreFeedback('AddTrainingError', false);
export const UpdateTrainingError = new CadreFeedback(
  'UpdateTrainingError',
  false,
);
export const FetchTrainingHistoryError = new CadreFeedback(
  'FetchTrainingHistoryError',
  false,
);
export const DeleteExaminationError = new CadreFeedback(
  'DeleteExaminationError',
  false,
);
export const DeleteTrainingError = new CadreFeedback(
  'DeleteTrainingError',
  false,
);
export const FetchPickerEmployeesError = new CadreFeedback(
  'FetchPickerEmployeesError',
  false,
);
export const AttachmentSizeError = new CadreFeedback(
  'AttachmentSizeError',
  false,
);
export const ExportDataError = new CadreFeedback('ExportDataError', false);

/** Positive Feedbacks */
export const AddSuccess = new CadreFeedback('AddSuccess', true);
export const EditSuccess = new CadreFeedback('EditSuccess', true);
export const DeleteSuccess = new CadreFeedback('DeleteSuccess', true);
export const ArchiveSuccess = new CadreFeedback('ArchiveSuccess', true);
export const RestoreSuccess = new CadreFeedback('RestoreSuccess', true);
export const AddSkillSuccess = new CadreFeedback('AddSkillSuccess', true);
export const EditSkillSuccess = new CadreFeedback('EditSkillSuccess', true);
export const DeleteSkillSuccess = new CadreFeedback('DeleteSkillSuccess', true);
export const AddPositionSuccess = new CadreFeedback('AddPositionSuccess', true);
export const DeletePositionSuccess = new CadreFeedback(
  'DeletePositionSuccess',
  true,
);
export const EditPositionSuccess = new CadreFeedback(
  'EditPositionSuccess',
  true,
);
export const AddGroupSuccess = new CadreFeedback('AddGroupSuccess', true);
export const EditGroupSuccess = new CadreFeedback('EditGroupSuccess', true);
export const DeleteGroupSuccess = new CadreFeedback('DeleteGroupSuccess', true);
export const AddEmploymentFormSuccess = new CadreFeedback(
  'AddEmploymentFormSuccess',
  true,
);
export const AddExaminationSuccess = new CadreFeedback(
  'AddExaminationSuccess',
  true,
);
export const UpdateExaminationSuccess = new CadreFeedback(
  'UpdateExaminationSuccess',
  true,
);
export const EditEmployeeSkillsSuccess = new CadreFeedback(
  'EditEmployeeSkillsSuccess',
  true,
);
export const AddTrainingSuccess = new CadreFeedback('AddTrainingSuccess', true);
export const UpdateTrainingSuccess = new CadreFeedback(
  'UpdateTrainingSuccess',
  true,
);
export const DeleteExaminationSuccess = new CadreFeedback(
  'DeleteExaminationSuccess',
  true,
);
export const DeleteTrainingSuccess = new CadreFeedback(
  'DeleteTrainingSuccess',
  true,
);
export const ExportDataSuccess = new CadreFeedback('ExportDataSuccess', true);
