var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-modal', {
    staticClass: "profile-settings-devices-edit-modal",
    attrs: {
      "modalInstance": _vm.modalConnectAuthorizedDevice
    },
    on: {
      "show": _vm.setData,
      "hide": _vm.clearData
    }
  }, [!_vm.token ? _c('div', {
    staticClass: "confirmation"
  }, [_c('h3', [_vm._v(_vm._s(_vm.device.name))]), _c('p', [_vm._v(" Ponowne wyświetlenie kodu uwierzytelniającego spowoduje "), _c('b', [_vm._v("wylogowanie przypisanego urządzenia")]), _vm._v(". Czy na pewno chcesz kontynuować? ")]), _c('vs-button', {
    attrs: {
      "vs-variant": "primary",
      "loading": _vm.modalConnectAuthorizedDevice.isLoading
    },
    on: {
      "click": _vm.reauthToken
    }
  }, [_vm._v(" Tak, pokaż kod ")]), _c('vs-button', {
    attrs: {
      "vs-variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.modalConnectAuthorizedDevice.visible = false;
      }
    }
  }, [_vm._v(" Zamknij okno ")])], 1) : _vm._e(), _c('div', {
    staticClass: "qr-code-wrapper",
    class: _vm.token && 'visible'
  }, [_c('vue-qrcode', {
    ref: "qrCode",
    staticClass: "qr-code",
    attrs: {
      "value": _vm.qrLink,
      "options": _vm.qrOptions
    }
  }), _c('div', {
    staticClass: "qr-info"
  }, [_vm._v(" Nikomu nie udostępniaj powyższego kodu. ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }