import { buildRequest } from '@/services/api';

const prefix = 'api/investment';

const useInvestmentsApi = () => ({
  /**
   * Investment
   */
  createInvestment: (investment) => buildRequest('post',
    `${prefix}/investment`, investment),

  temporaryEmployee: (employee) => buildRequest('post',
    `${prefix}/cadre-employee`, employee),

  fetchInvestment: (investmentId) => buildRequest('get',
    `${prefix}/investment/${investmentId}/show`),

  fetchPickerInvestments: () => buildRequest('get',
    `${prefix}/investment/index-picker`),

  updateInvestment: (investmentId, investment) => buildRequest('put',
    `${prefix}/investment/${investmentId}`, investment),

  deleteInvestment: (investmentId) => buildRequest('delete',
    `${prefix}/investment/${investmentId}`),

  uploadAttachment: (investmentId, data) => buildRequest('post',
    `${prefix}/investment/${investmentId}/media`, data),

  deleteAttachment: (attachmentId) => buildRequest('delete',
    `${prefix}/investment/media/${attachmentId}`),

  fetchWorkSummary: (investmentId, dateSpan) => buildRequest('get',
    `${prefix}/investment/${investmentId}/work-summary`, dateSpan),

  fetchWorkReport: (investmentId) => buildRequest('get',
    `${prefix}/investment/${investmentId}/work-report`),

  fetchFuelingReport: (investmentId) => buildRequest('get',
    `${prefix}/investment/${investmentId}/fuel-report`),

  /**
   * Investment Notes
   */
  fetchInvestmentNotes: (investmentId) => buildRequest('get',
    `${prefix}/notes/get-for-investment/${investmentId}`),

  /**
   * Investment Fueling
   */
  createFueling: (fueling) => buildRequest('post',
    `${prefix}/fuel`, fueling),

  updateFueling: (fuelingId, fueling) => buildRequest('put',
    `${prefix}/fuel/${fuelingId}`, fueling),

  deleteFueling: (fuelingId) => buildRequest('delete',
    `${prefix}/fuel/${fuelingId}`),

  /**
   * Investment Report
   */
  createReport: (report) => buildRequest('post',
    `${prefix}/subject-balance`, report),

  updateReport: (reportId, report) => buildRequest('put',
    `${prefix}/subject-balance/${reportId}`, report),

  addReportAdditionalCosts: (reportId, costs) => buildRequest('put',
    `${prefix}/subject-balance/${reportId}/sync-additional-costs`, costs),

  deleteReport: (reportId) => buildRequest('delete',
    `${prefix}/subject-balance/${reportId}`),

  fetchReport: (reportId) => buildRequest('get',
    `${prefix}/subject-balance/${reportId}/show`),

  /**
   * Demand
   */
  createRequirement: (requirement) => buildRequest('post',
    `${prefix}/requirement`, requirement),

  updateRequirement: (requirementId, requirement) => buildRequest('put',
    `${prefix}/requirement/${requirementId}`, requirement),

  deleteRequirement: (requirementId) => buildRequest('delete',
    `${prefix}/requirement/${requirementId}`),

  createOffer: (offer) => buildRequest('post',
    `${prefix}/requirement-offer`, offer),

  toggleOfferAcceptance: (id) => buildRequest('patch',
    `${prefix}/requirement-offer/${id}/change-accept`),

  updateOffer: (offerId, offer) => buildRequest('put',
    `${prefix}/requirement-offer/${offerId}`, offer),

  deleteOffer: (offerId) => buildRequest('delete',
    `${prefix}/requirement-offer/${offerId}`),

  addOfferAttachment: (attachment) => buildRequest('post',
    `${prefix}/requirement-offer/media/`, attachment),

  deleteOfferAttachment: (attachmentId) => buildRequest('delete',
    `${prefix}/requirement-offer/media/${attachmentId}`),

  /**
   * Investment Resource
   */
  assignResource: (resource) => buildRequest('post',
    `${prefix}/base-resource`, resource),

  updateAssignedResource: (resourceId, resource) => buildRequest('put',
    `${prefix}/base-resource/${resourceId}`, resource),

  assignVehicle: (vehicle) => buildRequest('post',
    `${prefix}/base-vehicle`, vehicle),

  assignEquipment: (equipment) => buildRequest('post',
    `${prefix}/base-tool`, equipment),

  editVehicle: (vehicleId, vehicle) => buildRequest('put',
    `${prefix}/base-vehicle/${vehicleId}`, vehicle),

  updateManyResources: (data) => buildRequest('put',
    `${prefix}/base-resource/update-many`, data),

  editEquipment: (equipmentId, equipment) => buildRequest('put',
    `${prefix}/base-tool/${equipmentId}`, equipment),

  deleteResource: (resourceId) => buildRequest('delete',
    `${prefix}/base-resource/${resourceId}`),

  deleteVehicle: (vehicleId) => buildRequest('delete',
    `${prefix}/base-vehicle/${vehicleId}`),

  deleteEquipment: (equipmentId) => buildRequest('delete',
    `${prefix}/base-tool/${equipmentId}`),

  getAllLocations: (resourceId) => buildRequest('get',
    `${prefix}/base-resource/${resourceId}/locations`),

  /**
   * Investment Subject
   */
  assignSubject: (subject) => buildRequest('post',
    `${prefix}/subject`, subject),

  updateSubject: (subjectId, subject) => buildRequest('put',
    `${prefix}/subject/${subjectId}`, subject),

  deleteSubject: (subjectId) => buildRequest('delete',
    `${prefix}/subject/${subjectId}`),

  /**
   * Demand Material
   */
  fetchPickerMaterials: () => buildRequest('get',
    `${prefix}/requirement-group`),

  createMaterial: (material) => buildRequest('post',
    `${prefix}/requirement-group`, material),

  updateMaterial: (materialId, material) => buildRequest('put',
    `${prefix}/requirement-group/${materialId}`, material),

  deleteMaterial: (materialId) => buildRequest('delete',
    `${prefix}/requirement-group/${materialId}`),

  /**
   * Reinforcement
   */
  fetchPickerReinforcements: () => buildRequest('get',
    `${prefix}/subject-reinforcement`),

  createReinforcement: (reinforcement) => buildRequest('post',
    `${prefix}/subject-reinforcement`, reinforcement),

  updateReinforcement: (reinforcementId, reinforcement) => buildRequest('put',
    `${prefix}/subject-reinforcement/${reinforcementId}`, reinforcement),

  deleteReinforcement: (reinforcementId) => buildRequest('delete',
    `${prefix}/subject-reinforcement/${reinforcementId}`),

  /**
   * Work Types
   */
  fetchPickerWorkTypes: () => buildRequest('get',
    `${prefix}/work-type`),

  createWorkType: (workType) => buildRequest('post',
    `${prefix}/work-type`, workType),

  updateWorkType: (workTypeId, workType) => buildRequest('put',
    `${prefix}/work-type/${workTypeId}`, workType),

  deleteWorkType: (workTypeId) => buildRequest('delete',
    `${prefix}/work-type/${workTypeId}`),

  /**
   * Work Groups
   */
  fetchPickerWorkGroups: () => buildRequest('get',
    `${prefix}/work-group`),

  createWorkGroup: (workGroup) => buildRequest('post',
    `${prefix}/work-group`, workGroup),

  updateWorkGroup: (workGroupId, workGroup) => buildRequest('put',
    `${prefix}/work-group/${workGroupId}`, workGroup),

  deleteWorkGroup: (workGroupId) => buildRequest('delete',
    `${prefix}/work-group/${workGroupId}`),

  /**
   * Employee
   */
  fetchPickerBrigades: () => buildRequest('get',
    `${prefix}/brigade`),

  createBrigade: (brigade) => buildRequest('post',
    `${prefix}/brigade`, brigade),

  editBrigade: (brigadeId, brigade) => buildRequest('put',
    `${prefix}/brigade/${brigadeId}`, brigade),

  deleteBrigade: (brigadeId) => buildRequest('delete',
    `${prefix}/brigade/${brigadeId}`),

  assignEmployee: (brigadeId, employee) => buildRequest('patch',
    `${prefix}/brigade/${brigadeId}/assign-employee`, employee),

  editEmployee: (brigadeId, employeeId, employee) => buildRequest('patch',
    `${prefix}/brigade/${brigadeId}/edit-employee/${employeeId}`, employee),

  detachEmployee: (brigadeId, employeeId) => buildRequest('patch',
    `${prefix}/brigade/${brigadeId}/detach-employee/${employeeId}`),
});

export default useInvestmentsApi;
