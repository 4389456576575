import showToast from '@/libs/toasts';
import { FetchGroupsError } from '@/libs/toasts/feedback/module/base';
import useBaseApi from '../useBaseApi';
import useBaseStore from '../useBaseStore';

export default function useBaseGroup() {
  const { groups } = useBaseStore();

  const fetchGroups = (force = false) => {
    if (groups.value.length > 0 && !force) return groups;

    const req = useBaseApi()
      .fetchGroups()
      .then(({ data }) => {
        groups.value = data.data;
      })
      .catch(() => showToast(FetchGroupsError));

    return req;
  };

  return { groups, fetchGroups };
}
