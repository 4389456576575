import Vue from 'vue';
import Vuex from 'vuex';

import ApiClient from '@/services/api';

Vue.use(Vuex);

export default {
  namespaced: true,

  state: {
    subject: [],
  },

  getters: {
    current: (state) => state.subject,
    totalPrice: (state) => {
      if (state.subject.length === 0) return null;

      const sum = state.subject.reduce((total, curr) => (
        curr.unit_price * curr.amount
        + total
      ), 0);
      return sum;
    },
    totalPriceOfProvided: (state, getters, rootState, rootGetters) => (subject, orderId) => {
      // return current subject total price if requested order is a current order
      if (rootGetters['orders/current'].id === orderId) return getters.totalPrice;
      if (!subject || subject.length === 0) return 0;

      const sum = subject.reduce((total, curr) => (
        curr.unit_price * curr.amount
        + total
      ), 0);

      return sum;
    },
  },

  mutations: {
    SET_SUBJECT(state, payload) {
      state.subject = payload;
    },
    DELETE_SUBJECT_ITEM(state, id) {
      const newArray = [...state.subject].filter((item) => item.id !== id);
      state.subject = [...newArray];
    },
    ADD_SUBJECT_ITEM(state, subjectItem) {
      state.subject = [
        ...state.subject,
        subjectItem,
      ];
    },
    UPDATE_SUBJECT_ITEM(state, newSubject) {
      const index = state.subject
        .findIndex((subject) => subject.id === newSubject.id);

      // replace a subject inside array
      const newArray = [...state.subject];
      newArray[index] = { ...newArray[index], ...newSubject };

      state.subject = [...newArray];
    },
  },

  actions: {
    create({ commit }, data) {
      const url = 'api/orders/subject';
      const request = ApiClient.post(url, data);

      request.then((res) => {
        if (res.status !== 200) return;
        commit('ADD_SUBJECT_ITEM', res.data.data);
      });

      return request;
    },
    edit({ commit }, data) {
      const url = 'api/orders/subject/:id'.replace(':id', data.id);
      const request = ApiClient.put(url, data);

      request.then((res) => {
        if (res.status !== 200) return;
        commit('UPDATE_SUBJECT_ITEM', res.data.data);
      });

      return request;
    },
    delete({ commit }, id) {
      const url = 'api/orders/subject/:id'.replace(':id', id);
      const request = ApiClient.delete(url);

      request.then((res) => {
        if (res.status !== 200) return;
        commit('DELETE_SUBJECT_ITEM', id);
      });

      return request;
    },
    set({ commit }, subject) {
      commit('SET_SUBJECT', subject);
    },
  },
};
