<template>
  <div class="edit-modal">
    <b-modal
      id="modal-cadre-position-edit"
      :title="$t('settings.cadre.positions.edit.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('settings.cadre.positions.button.cancel')"
      :ok-title="$t('settings.cadre.positions.button.save')"
      size="xl"
      @show="bindModel"
      @ok.prevent="updatePosition"
      no-close-on-backdrop
      >
      <vs-loader full
        text="Aktualizowanie stanowiska..."
        v-if="loading"
      />

      <b-card-text>
        <position-form
          v-model="position"
          :model="positionModel"
          :feedback="errors"
          :permissions="permissionIds"
          :preparingPermissions="preparingPermissions"
          @update:granted="(val) => granted = val"
        />
      </b-card-text>

      <template #modal-footer="{ ok, cancel }">
        <vs-modal-footer
          disable-on-loading
          :is-loading="loading"

          :text-cancel="$t('settings.cadre.positions.button.cancel')"
          :text-ok="$t('settings.cadre.positions.button.save')"

          @cancel="cancel"
          @ok="ok"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BModal, BCardText } from 'bootstrap-vue';
import PositionForm from '@/components/views/settings/content-tabs/cadre/forms/PositionForm.vue';
import useModal from '@/hooks/useModal';
import {
  EditPositionSuccess,
  BadInputError,
  ServerError,
} from '@feedback/module/cadre';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

export default {
  components: {
    BModal,
    BCardText,

    PositionForm,
    VsLoader,
    VsModalFooter,
  },
  data() {
    return {
      position: {},
      positionModel: {},
      errors: {},
      loading: false,
      preparingPermissions: true,
      permissionIds: [],
      granted: [],
    };
  },
  computed: {
    ...mapGetters({
      current: 'cadre/positions/byId',
    }),
  },
  methods: {
    bindModel() {
      this.positionModel = useModal().retrieveValues();
      this.permissionIds = useModal().retrieveValues()?.role?.permissions
        ?.map(({ id }) => ({ id, disabled: false }));
      this.preparingPermissions = false;
    },
    updatePosition(event) {
      this.loading = true;
      const position = { ...this.position };
      delete position.actual_daily_cost;
      delete position.role;

      this.$store.dispatch('cadre/positions/update', {
        ...position,
        permissions: this.granted,
      })
        .then(() => {
          this.$showFeedback(EditPositionSuccess);

          this.$bvModal.hide(event.componentId);
          this.loading = false;
          RemoteResourceManager.getInstance().notify('cadre.position');
        })
        .catch((err) => {
          let Feedback = ServerError;

          if (err.response && err.response.data && err.response.data.errors) {
            Feedback = BadInputError;
          }

          this.$showFeedback(Feedback);
          this.loading = false;
          this.errors = err?.response?.data?.errors || {};
        });
    },
  },
};
</script>
