var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle ? _c('b-row', {
    staticClass: "content-header"
  }, [_c('b-col', {
    staticClass: "content-header-left mb-2",
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('b-row', {
    staticClass: "breadcrumbs-top"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h2', {
    staticClass: "content-header-title float-left pr-1 mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$route.meta.pageTitle) + " ")]), _c('div', {
    staticClass: "breadcrumb-wrapper"
  }, [_c('b-breadcrumb', [_c('b-breadcrumb-item', {
    attrs: {
      "to": "/"
    }
  }, [_c('feather-icon', {
    staticClass: "align-text-top",
    attrs: {
      "icon": "HomeIcon",
      "size": "16"
    }
  })], 1), _vm._l(_vm.$route.meta.breadcrumb, function (item) {
    return _c('b-breadcrumb-item', {
      key: item.text,
      attrs: {
        "active": item.active,
        "to": item.to
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")]);
  })], 2)], 1)])], 1)], 1), _c('b-col', {
    staticClass: "content-header-right text-md-right d-md-block d-none mb-1",
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-dropdown', {
    attrs: {
      "variant": "link",
      "no-caret": "",
      "toggle-class": "p-0",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-icon",
          attrs: {
            "variant": "primary"
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "SettingsIcon"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 1621059081)
  }, [_c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'apps-todo'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CheckSquareIcon",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "align-middle ml-50"
  }, [_vm._v("Todo")])], 1), _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'apps-chat'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MessageSquareIcon",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "align-middle ml-50"
  }, [_vm._v("Chat")])], 1), _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'apps-email'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MailIcon",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "align-middle ml-50"
  }, [_vm._v("Email")])], 1), _c('b-dropdown-item', {
    attrs: {
      "to": {
        name: 'apps-calendar'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CalendarIcon",
      "size": "16"
    }
  }), _c('span', {
    staticClass: "align-middle ml-50"
  }, [_vm._v("Calendar")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }