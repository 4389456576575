<template>
  <div class="demo-inline-spacing">
    <vs-button
      variant="outline"
      @click="toggleIntegration"
    >
      <state-badge
        :state="isIntegrationEnabled"
        text="Integracja z modułem CRM"
      />
    </vs-button>
  </div>
</template>

<script>
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useIntegrations from '@/hooks/useIntegrations';
import StateBadge from '@/components/ui/StateBadge.vue';

export default {
  name: 'SettingsTabOrdersIntegrations',
  setup() {
    const { isIntegrationEnabled, toggleIntegration, enabledIntegration } = useIntegrations('crm_provider_relation');

    return {
      toggleIntegration: () => toggleIntegration('asd'),
      isIntegrationEnabled,
      enabledIntegration,
    };
  },
  components: {
    VsButton,
    StateBadge,
  },
};
</script>

<style lang="sass" scoped>
.vs-button
  padding-left: 0
</style>
