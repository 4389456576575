<template>
  <span v-if="isAuthorized">
    <slot />
  </span>

  <span v-else-if="!silent">
    <slot name="unauthorized">
      <unauthorized-pane
        :permission="permission"
      />
    </slot>
  </span>

</template>

<script>
import { computed } from 'vue';
import store from '@/store';
import UnauthorizedPane from './UnauthorizedPane.vue';

export default {
  name: 'AuthorizationGuard',
  props: {
    permission: {
      type: String,
      validator: (val) => val.split('.').length === 3,
    },
    permissions: {
      type: Array,
      validator: (val) => val.every((perm) => perm.split('.').length === 3),
    },
    mode: {
      type: String,
      validator: (val) => ['AND', 'OR'].includes(val),
      default: 'AND',
    },
    silent: {
      // don't show the unauthorized pane
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const hasSinglePermission = (permission) => {
      if (!permission) return true;
      const split = permission.split('.');
      const parent = `${split[0]}.${split[1]}`;
      const action = split[2];

      return store
        .getters['auth/userHasPermission'](action, parent);
    };

    const isAuthorized = computed(() => {
      if (!props.permission && !props.permissions) return true;
      if (props.permission) return hasSinglePermission(props.permission);
      if (props.mode === 'AND') return props.permissions.every(hasSinglePermission);
      return props.permissions.some(hasSinglePermission);
    });

    return { isAuthorized };
  },
  components: {
    UnauthorizedPane,
  },
};
</script>
