<template>
  <div class="edit-modal">
    <b-modal
      id="modal-cadre-group-edit"
      :title="$t('settings.cadre.groups.edit.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('settings.cadre.groups.button.cancel')"
      :ok-title="$t('settings.cadre.groups.button.save')"
      size="lg"
      @show="bindModel"
      @ok.prevent="updateGroup"
      no-close-on-backdrop
      >
      <vs-loader full
        text="Aktualizowanie grupy..."
        v-if="loading"
      />

      <b-card-text>
        <name-form v-model="group" :model="groupModel" :feedback="errors"/>
      </b-card-text>

      <template #modal-footer="{ ok, cancel }">
        <vs-modal-footer
          disable-on-loading
          :is-loading="loading"

          :text-cancel="$t('settings.cadre.groups.button.cancel')"
          :text-ok="$t('settings.cadre.groups.button.save')"

          @cancel="cancel"
          @ok="ok"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BModal, BCardText } from 'bootstrap-vue';
import NameForm from '@/components/views/settings/content-tabs/cadre/forms/NameForm.vue';
import useModal from '@/hooks/useModal';
import {
  EditGroupSuccess,
  BadInputError,
  ServerError,
} from '@feedback/module/cadre';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

export default {
  components: {
    BModal,
    BCardText,

    NameForm,
    VsModalFooter,
  },
  data() {
    return {
      group: {},
      groupModel: {},
      errors: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      current: 'cadre/groups/byId',
    }),
  },
  methods: {
    bindModel() {
      this.groupModel = useModal().retrieveValues();
    },
    updateGroup(event) {
      const group = { ...this.group };
      this.loading = true;

      this.$store.dispatch('cadre/groups/update', {
        ...group,
      })
        .then(() => {
          this.$showFeedback(EditGroupSuccess);

          this.$bvModal.hide(event.componentId);
          this.loading = false;
          RemoteResourceManager.getInstance().notify('cadre.group');
        })
        .catch((err) => {
          let Feedback = ServerError;

          if (err.response && err.response.data && err.response.data.errors) {
            Feedback = BadInputError;
          }

          this.$showFeedback(Feedback);
          this.loading = false;
          this.errors = err?.response?.data?.errors || {};
        });
    },
  },
};
</script>
