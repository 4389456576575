import ApiClient from '@/services/api';
import insurance from './insurance';

const urls = {
  get: 'api/base/resource-report/index',
};

export default {
  namespaced: true,

  state: {
    reports: [],
  },

  getters: {
  },

  mutations: {
    SET_REPORTS(state, reports) {
      state.reports = reports;
    },

    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
  },

  actions: {
    /**
     * Fetch reports list
     * @action fetch=reports
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if reports exist
     * @returns {Promise}
     */
    fetch({ state, commit }, params, force = true) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(urls.get, {
        ...params,
        columnFilters: {
          ...params.columnFilters ?? {},
          resource_type: ['car'],
        },
      });

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_REPORTS', res.data.data.reports);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },
  },

  modules: {
    insurance,
  },
};
