var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.securedItems.length > 0 ? _c('b-dropdown', {
    staticClass: "table-action-button",
    attrs: {
      "dropright": "",
      "size": "sm",
      "variant": _vm.dropdownVariant,
      "toggle-class": "text-decoration-none",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "size": "20",
            "icon": "SettingsIcon"
          }
        })];
      },
      proxy: true
    }], null, false, 3105021997)
  }, _vm._l(_vm.securedItems, function (_ref, idx) {
    var title = _ref.title,
      icon = _ref.icon,
      callback = _ref.callback;
    return _c('b-dropdown-item', {
      key: idx,
      staticClass: "action-item",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return function () {
            return callback(_vm.target);
          }.apply(null, arguments);
        }
      }
    }, [_c('div', {
      staticClass: "action-item-content"
    }, [_c('div', {
      staticClass: "icon"
    }, [_c('feather-icon', {
      attrs: {
        "size": "20",
        "icon": `${typeof icon === 'function' ? icon(_vm.target) : icon}Icon`
      }
    })], 1), _c('div', {
      staticClass: "text"
    }, [_vm._v(" " + _vm._s(typeof title === 'function' ? title(_vm.target) : title) + " ")])])]);
  }), 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }