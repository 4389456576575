/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
class Theme {
  constructor(name) {
    this.name = '';
    this.data = {};
    this.css = '';

    this.branding = {
      default: {
        logo: '',
        logoBrand: '',
        logoFull: '',
        logoInvoice: '',
      },
      dark: {
        logo: '',
        logoBrand: '',
        logoFull: '',
        logoInvoice: '',
      },
    };

    this.useTheme(name);
  }

  async loadBranding(name = this.name) {
    try {
      Object.entries(this.branding).forEach(([mode, images]) => {
        Object.keys(images).reduce(async (promise, imageName) => {
          await promise;
          const relativePath = this.data?.branding?.[mode]?.[imageName]
            ?? this.data?.branding?.default?.[imageName];

          if (!relativePath) return;
          this.branding[mode][imageName] = await require(`@/themes/${name}/${relativePath}`);
        }, Promise.resolve());
      });
    } catch {
      console.error('No logo found in theme directory');
    }
  }

  async loadStylesheets(name = this.name) {
    const { stylesheet } = this.data;
    if (!stylesheet || !stylesheet.length) return;

    try {
      this.css = await import(/* webpackChunkName: "theme" */ `@/themes/${name}/${stylesheet}`);
    } catch (err) {
      console.error('Could not load stylesheet specified in theme');
      console.error(err);
    }
  }

  async useTheme(name) {
    try {
      const themeFile = await import(`@/themes/${name}/theme.json`);
      this.data = themeFile.default;
      this.name = name;
    } catch (err) {
      console.error(err);
      console.error('Invalid theme file provided, falling back to defaults...');
      this.useTheme('default');
      return;
    }

    this.loadStylesheets();
    this.loadBranding();
  }
}

export default Theme;
