<template>
  <div
    class="user-avatar"
    :style="avatarStyling"
    :class="{'user-avatar--large': lg}"
  >
    <div class="user-avatar-icon" v-if="displayUserIcon">
      <feather-icon icon="UserIcon" class="icon" size="14" />
    </div>
    <div
      :class="`user-avatar-circle ${minimal ? 'minimal' : ''}`"
      >
      {{ avatar.shortcode }}
    </div>

    <div
      class="remove-button"
      @click.prevent="$emit('delete')"
      v-if="avatarDeleteButton"
    >
      <feather-icon class="icon" icon="XIcon" size="18" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import useAvatarGenerator from '@/hooks/useAvatarGenerator';

export default {
  emits: ['delete'],
  props: {
    user: {
      type: Object,
      default: null,
    },
    lg: {
      type: Boolean,
      default: false,
    },
    minimal: {
      type: Boolean,
      default: false,
    },
    /**
     * FIXME: this should be discussed later on
     *        as the component currently serves as a
     *        avatar for users and employees
     */
    displayUserIcon: {
      type: Boolean,
      default: false,
    },
    avatarDeleteButton: {
      type: Boolean,
      default: false,
    },
    deleted: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { createAvatar } = useAvatarGenerator();

    const user = computed(() => props.user);
    const avatar = computed(() => createAvatar(user, props.deleted));

    const avatarStyling = computed(() => {
      const { foreground, background, borderColor } = avatar.value;

      const primaryForeground = props.minimal ? background : foreground;
      const size = props.lg ? '90px' : '40px';

      return {
        '--primary-bg': background,
        '--primary-fg': primaryForeground,
        '--border-color': borderColor,
        '--size': size,
        '--font-size': props.lg ? '14pt' : '8pt',
      };
    });

    return {
      avatar,
      avatarStyling,
    };
  },
};
</script>

<style lang="sass" scoped>
.user-avatar
  position: relative
  width: var(--size)
  height: var(--size)
  background: none !important

  &--large
    .user-avatar-icon
      width: 30px
      height: 30px

  &:hover
    .remove-button
      opacity: 1
    .remove-button .icon
      opacity: 0.5
      transform: translateY(0) rotateY(0deg)

  .remove-button
    opacity: 0
    transition: 0.2s all ease-in-out
    position: absolute
    color: var(--vs-color)
    background: var(--vs-card-bg-l-10)
    top: -1px
    left: -1px
    height: 42px
    border-radius: 50%
    aspect-ratio: 1/1
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer

    .icon
      opacity: 0
      transition: 0.2s 0.1s all ease-in-out
      transform: translateY(5px) rotateX(40deg)

  &-icon
    position: absolute
    right: 0px
    bottom: -1px
    background: white
    border: 2px solid var(--primary-bg)
    color: var(--primary-fg)
    border-radius: 50%
    padding: 2px
    width: 17px
    height: 17px
    display: flex
    align-items: center
    justify-content: center

  &-circle
    display: flex
    align-items: center
    justify-content: center
    width: var(--size)
    border-radius: 50%
    aspect-ratio: 1/1
    background: var(--primary-bg, none)
    border: 1px solid var(--border-color)
    color: var(--primary-fg)
    font-size: var(--font-size)
    font-weight: 700

    &.minimal
      aspect-ratio: initial
      background: none
      padding: 0
      display: block
      text-align: center
      border-radius: 0
</style>
