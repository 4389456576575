<template>
  <div class="orders">
    <div class="listWrapper" :class="{ fullWidth: !detailsVisible() }">
      <order-list />
    </div>

    <div class="detailsColumn" :class="{ visible: detailsVisible() }">
      <order-details />
    </div>

    <orders-edit-modal />
    <orders-create-modal />
  </div>
</template>

<script>
import OrderList from '@/components/views/orders/List.vue';
import OrderDetails from '@/components/views/orders/Details.vue';
import OrdersEditModal from '@/components/views/orders/modals/OrdersEditModal.vue';
import OrdersCreateModal from '@/components/views/orders/modals/OrdersCreateModal.vue';

export default {
  components: {
    OrderList,
    OrderDetails,
    OrdersEditModal,
    OrdersCreateModal,
  },
  methods: {
    detailsVisible() {
      return this.$route.name === 'orders-details';
    },
  },
  created() {
    this.$store.dispatch('currencies/fetch');
  },
};
</script>
<style lang="sass" scoped>
$details-width: 60%

.orders
  display: flex

  .listWrapper
    width: calc(100% - #{$details-width})
    transition: 0.5s width ease
    position: relative
    z-index: 1

    &.fullWidth
      width: 100%

  .detailsColumn
    width: $details-width
    transition: 0.5s all ease-in-out
    position: absolute
    opacity: 0
    padding-left: 20px
    height: 0
    overflow: hidden
    z-index: 0

    &.visible
      opacity: 1
      animation: relativeDelay .1s ease forwards
      height: auto

      @keyframes relativeDelay
        from
          position: absolute
        to
          position: relative

@media screen and (max-width: 1000px)
  .orders
    display: block

    .listWrapper
      display: none

      &.fullWidth
        display: block

    .detailsColumn
      width: 100%
      position: relative
      display: block
      padding-left: 0
</style>
