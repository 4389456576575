var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-modal"
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-settings-crm-services-add",
      "title": _vm.$t('crm.service.modal.add.title'),
      "cancel-variant": "outline-secondary",
      "cancel-title": _vm.$t('crm.service.modal.add.button.cancel'),
      "ok-title": _vm.$t('crm.service.modal.add.button.ok'),
      "size": "lg",
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": function ($event) {
        $event.preventDefault();
        return _vm.createService.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function (_ref) {
        var ok = _ref.ok,
          cancel = _ref.cancel;
        return [_c('vs-modal-footer', {
          attrs: {
            "disable-on-loading": "",
            "is-loading": _vm.isLoading,
            "text-cancel": _vm.$t('crm.service.modal.add.button.cancel'),
            "text-ok": _vm.$t('crm.service.modal.add.button.ok')
          },
          on: {
            "cancel": cancel,
            "ok": ok
          }
        })];
      }
    }]),
    model: {
      value: _vm.modalAddVisible,
      callback: function ($$v) {
        _vm.modalAddVisible = $$v;
      },
      expression: "modalAddVisible"
    }
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "compact": "",
      "text": "Tworzenie usługi..."
    }
  }) : _vm._e(), _c('b-card-text', [_c('settings-tab-crm-services-form')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }