<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useCrmCompanyStore from '@/stores/module/crm/useCrmCompanyStore';
import CrmProviderPickerOption from './CrmProviderPickerOption.vue';

export default Vue.component('CrmProviderPicker', {
  emits,
  setup(_, ctx) {
    const { providers } = useCrmCompanyStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz dostawcę z listy',
      reduce: (option) => option.provider.id,
      filter: ['name', 'nip'],
      options: providers.state,
      ...ctx.attrs,
    }, {
      option: ({ option }) => h(CrmProviderPickerOption, {
        props: {
          provider: option,
        },
      }),
      'selected-option': ({ option }) => h(CrmProviderPickerOption, {
        props: {
          provider: option,
        },
      }),
    }));
  },
  components: { VsPickerVue },
});
</script>
