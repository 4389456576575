export default {
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Tak, usuń',
  cancelButtonText: 'Nie',
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-secondary ml-1',
  },
  buttonsStyling: false,
};
