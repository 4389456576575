<template>
  <abstract-module-label
    icon="CompassIcon"
    :to="investmentRoute"
    :name="investmentName"
    :size="size"
    :target="target"
  />
</template>

<script>
import { computed, ref } from 'vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import AbstractModuleLabel from '@/components/abstract/AbstractModuleLabel.vue';

export default {
  name: 'InvestmentsInvestmentLabel',
  props: {
    investment: {
      type: Object,
    },
    id: {
      type: Number,
    },
    target: {
      type: String,
    },
    size: {
      type: String,
      validator: (val) => ['sm', 'md', 'lg'].includes(val),
      default: 'md',
    },
  },
  setup(props) {
    const fetchedInvestment = ref(null);
    const properInvestment = computed(() => props.investment ?? fetchedInvestment.value ?? {});

    const investmentId = computed(() => props.id ?? properInvestment.value.id ?? null);
    const investmentName = computed(() => properInvestment.value.name);

    if (!props.investment && props.id) {
      useInvestmentsApi()
        .fetchInvestment(props.id)
        .then(({ data }) => {
          fetchedInvestment.value = data.data;
        });
    }

    const investmentRoute = computed(() => ({
      name: 'investments.details',
      params: {
        id: investmentId.value,
      },
    }));

    return {
      investmentRoute,
      investmentName,
      investmentId,
      properInvestment,
    };
  },
  components: {
    AbstractModuleLabel,
  },
};
</script>
