import statuses from './statuses';
import groups from './groups';
import positions from './positions';
import employees from './employees';
import employmentForms from './employment-forms';
import skills from './skills';
import examinations from './examinations';
import trainings from './trainings';

export default {
  namespaced: true,

  modules: {
    statuses,
    groups,
    positions,
    employees,
    employmentForms,
    skills,
    examinations,
    trainings,
  },
};
