<template>
  <div class="settings-tab-base-models">

    <authorization-guard
      silent
      permission="base.producer_model.store"
    >
      <div class="buttons">
        <div class="group">
          <vs-button
            vs-variant="primary"
            @click="openAddModal"
          >
            + {{ $t('base.producer.model.button.add') }}
          </vs-button>
        </div>
      </div>
    </authorization-guard>

    <br>

    <authorization-guard
      permission="base.producer_model.index"
    >
      <div class="model-list-wrapper">
        <vs-list showHeaders
          class="model-list"
          v-for="producer in producers"
          :key="producer"

          :items="producersModels.filter((model) => model.producer.name === producer)"
          :headers="[producer, 'Typ']"
          @edit="openEditModal"
          @delete="deleteProducerModel"
        >
          <template v-slot:cells="{ row }" >
            <div class="vs-list-item-cell">
              {{ $t(`base.global.resourceType.${row?.type?.slug}`) }}
            </div>
          </template>
        </vs-list>
      </div>
    </authorization-guard>

    <settings-tab-base-producer-model-modal-edit />
    <settings-tab-base-producer-model-modal-add />
  </div>
</template>

<script>
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useBaseProducerModels from '@/hooks/base/assignables/useBaseProducerModels';
import { computed, provide } from 'vue';
import VsList from '@/components/ui/vs-list/VsList.vue';
import SettingsTabBaseProducerModelModalEdit
  from '@/components/views/settings/content-tabs/base/modals/producerModels/SettingsTabBaseProducerModelModalEdit.vue';
import SettingsTabBaseProducerModelModalAdd
  from '@/components/views/settings/content-tabs/base/modals/producerModels/SettingsTabBaseProducerModelModalAdd.vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';

export default {
  name: 'SettingsTabBaseModels',
  setup() {
    const producersModelsInstance = useBaseProducerModels();
    const {
      producersModels, fetchProducersModels, openAddModal,
      openEditModal, deleteProducerModel, modalAddVisible,
    } = producersModelsInstance;

    const producers = computed(() => producersModels.value
      .map((model) => model.producer.name)
      .filter((producerName, idx, self) => self.indexOf(producerName) === idx));

    provide('producersModelsInstance', producersModelsInstance);

    fetchProducersModels();

    return {
      producersModels,
      openAddModal,
      openEditModal,
      deleteProducerModel,
      modalAddVisible,
      producers,
    };
  },
  components: {
    SettingsTabBaseProducerModelModalAdd,
    SettingsTabBaseProducerModelModalEdit,
    VsList,
    VsButton,
    AuthorizationGuard,
  },
};
</script>

<style lang="sass" scoped>
.model-list
  &:not(:last-child)
    margin-bottom: 50px
</style>
