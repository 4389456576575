import Section from '../Section';

class Details extends Section {
  constructor(composer) {
    super('Invoice Details', composer);
  }

  addContactDetails(header, details, leftOffset = 0) {
    const { layout } = this.composer;

    this.composer.doc
      .setFontSize(layout.fontSize.details.contact)
      .setFont('OpenSans-Bold', 'normal');

    this.appendText(
      header,
      layout.gutters.x + leftOffset,
      layout.currentHeight,
    );
    this.composer.layout.currentHeight += 2;

    this.composer.restoreDefaultFont();

    this.composer.doc
      .setFont('OpenSans-Light', 'normal');

    const rows = Object.keys(details)
      .filter((row) => details[row])
      .map((row) => details[row]);

    const self = this;

    rows.map((row) => self.appendText(
      row,
      layout.gutters.x + leftOffset,
      layout.currentHeight + 2,
    ));
  }

  generate() {
    this.composer.layout.currentHeight += 10;

    const heightRollback = this.composer.layout.currentHeight;
    this.addContactDetails('Zamawiający', this.composer.content.details.ordering);
    const heightRollback2 = this.composer.layout.currentHeight; // xD

    this.composer.layout.currentHeight = heightRollback;
    this.addContactDetails('Dostawca', this.composer.content.details.provider, 100);
    this.composer.layout.currentHeight = heightRollback2;
  }
}

export default Details;
