import Feedback from '@feedback/Feedback';

/**
 * Feedback extension for Base Module
 */

class BaseFeedback extends Feedback {
  constructor(namespace, isPositiveFeedback) {
    super(isPositiveFeedback);

    this.extendI18nNamespace('base');
    this.extendI18nNamespace(namespace);
  }
}

export default BaseFeedback;
