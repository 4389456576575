var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vs-table"
  }, [_vm.showSearch ? _c('b-form-group', [_c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_vm.search ? _c('b-input-group-prepend', {
    staticClass: "clear-input",
    attrs: {
      "is-text": ""
    },
    on: {
      "click": function () {
        return _vm.search = '';
      }
    }
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "XIcon"
    }
  })], 1) : _c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    },
    on: {
      "click": function () {
        return _vm.$refs.searchTerm.focus();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "icon",
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    ref: "searchTerm",
    attrs: {
      "placeholder": _vm.$t('component.table.field.search')
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1) : _vm._e(), _c('vue-good-table', {
    attrs: {
      "columns": _vm.gtColumns,
      "rows": _vm.rows,
      "mode": _vm.mode,
      "search-options": {
        enabled: true,
        externalQuery: _vm.search
      },
      "select-options": _vm.gtOptions.select,
      "pagination-options": _vm.gtOptions.pagination,
      "totalRecords": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "sort-options": _vm.sortOptions,
      "styleClass": "vs-table bordered"
    },
    on: {
      "on-row-click": function ($event) {
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
        return function (data) {
          return _vm.$emit('on-row-click', data);
        }.apply(null, arguments);
      },
      "on-page-change": function (data) {
        return _vm.$emit('on-page-change', data);
      },
      "on-per-page-change": function (data) {
        return _vm.$emit('on-per-page-change', data);
      },
      "on-sort-change": function (data) {
        return _vm.$emit('on-sort-change', data);
      }
    },
    scopedSlots: _vm._u([{
      key: "emptystate",
      fn: function () {
        return [_vm._v(" Brak pozycji do wyświetlenia ")];
      },
      proxy: true
    }, {
      key: "table-column",
      fn: function (props) {
        return [_c('span', [_vm._v(" " + _vm._s(props.column.label) + " "), _vm.checkSort(props.column.field) === 'desc' ? _c('feather-icon', {
          attrs: {
            "icon": "ChevronDownIcon"
          }
        }) : _vm._e(), _vm.checkSort(props.column.field) === 'asc' ? _c('feather-icon', {
          attrs: {
            "icon": "ChevronUpIcon"
          }
        }) : _vm._e()], 1)];
      }
    }, {
      key: "loadingContent",
      fn: function () {
        return [!_vm.rows.length ? _c('div', {
          staticClass: "loading-spinner"
        }, [_c('vs-loader', {
          attrs: {
            "full": "",
            "opaque": ""
          }
        })], 1) : _c('div', {
          staticClass: "small-loading-indicator"
        }, [_c('div', {
          staticClass: "small-loading-indicator-icon"
        }, [_c('vs-loader', {
          attrs: {
            "inline": "",
            "text": "Aktualizowanie danych..."
          }
        })], 1)])];
      },
      proxy: true
    }, {
      key: "table-row",
      fn: function (props) {
        return [_vm.showActionButton && props.column.field === 'edit' && !props.row.hideActionButton ? _c('span', {
          staticStyle: {
            "display": "block",
            "width": "50px",
            "margin-left": "auto"
          }
        }, [_c('table-action-button', {
          attrs: {
            "items": _vm.actionItems,
            "target": props.row
          }
        })], 1) : _vm._e(), _vm._t("row", function () {
          return [_c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
        }, {
          "column": props.column,
          "row": props.row,
          "index": props.index
        })];
      }
    }, {
      key: "pagination-bottom",
      fn: function (props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.$t('component.table.field.displaying', {
          number: props.total
        })) + " ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['3', '5', '10']
          },
          on: {
            "input": function (value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.gtOptions.pagination.perPage,
            callback: function ($$v) {
              _vm.$set(_vm.gtOptions.pagination, "perPage", $$v);
            },
            expression: "gtOptions.pagination.perPage"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.$t('component.table.field.entryNumber', {
          number: _vm.totalRecords
        })) + " ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": _vm.currentPage,
            "total-rows": _vm.totalRecords,
            "per-page": _vm.gtOptions.pagination.perPage,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function (value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function () {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function () {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }], null, true)
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }