export default {
  app: {
    name: 'VS-ERP',
    description: 'Przykładowy opis aplikacji',
    footer: 'Projekt i implementacja:',
  },
  form: {
    unsaved: 'Zmieniono',
  },
  navigation: {
    shortcuts: 'Skróty',
    modules: 'Działy',
    development: 'Deweloperskie',
  },
  views: {
    home: 'Pulpit',
    tasks: 'Zadania',
  },
  action: {
    logout: 'Wyloguj',
    panel: 'Moje konto',
  },
  field: {
    email: 'Adres e-mail',
    phone: 'Numer telefonu',
    password: 'Hasło',
    passwordConfirmation: 'Powtórz hasło',
    name: 'Nazwa',
    nip: 'NIP',
    regon: 'REGON',
  },
  title: {
    filtering: 'Filtracja',
  },
  button: {
    cancel: 'Anuluj',
    delete: 'Usuń',
    edit: 'Edytuj',
    employee: 'Pracownik',
    order: 'Zlecenie',
    pdf: 'Pobierz PDF',
    save: 'Zapisz',
    task: 'Zadanie',
    settings: 'Ustawienia aplikacji',
  },
  validation: {
    required: 'Pole {field} jest wymagane.',
    badEmailFormat: 'Wpisz poprawny adres e-mail.',
    wrongRepeat: 'Pole powinno być takie samo jak {target}',
    minLength: 'Pole {field} jest zbyt krótkie',
    dateAfter: 'Data {field} nie może być wcześniejsza niż {before}',
    length: 'Pole {field} musi zawierać dokładnie {length} znaki',
    min_value: 'Pole {field} musi być większe lub równe {length}',
    bigger_than: 'Pole {field} musi być większe niż {length}',
  },
  text: {
    yes: 'Tak',
    no: 'Nie',
  },
  table: {
    perPage: 'Na stronę',
    asc: 'rosnąco',
    desc: 'malejąco',
    clear: 'Wyczyść',
    sort: 'Sortuj',
    filter: 'Filtruj',
    typeToSearch: 'Wpisz, aby wyszukać',
    none: '-- brak --',
    clearAllFilters: 'Wyczyść wszystkie filtry',
  },
  calendar: {
    pickDate: 'Wybierz datę',
    labelPrevDecade: 'Poprzednia dekada',
    labelPrevYear: 'Poprzedni rok',
    labelPrevMonth: 'Poprzedni miesiąc',
    labelCurrentMonth: 'Aktualny miesiąc',
    labelNextMonth: 'Następny miesiąc',
    labelNextYear: 'Następny rok',
    labelNextDecade: 'Następna dekada',
    labelToday: 'Dzisiaj',
    labelSelected: 'Wybrana data',
    labelNoDateSelected: 'Nie wybrano daty',
    labelCalendar: 'Kalendarz',
    labelNav: 'Nawigacja',
    labelHelp: 'Użyj strzałek, aby poruszać się po kalendarzu',
  },
};
