import Vue from 'vue';
import Vuex from 'vuex';

import ApiClient from '@/services/api';

import statuses from './statuses';
import comments from './comments';
import subject from './subject';
import attachments from './attachments';
import logs from './logs';

const endpoint = 'api/orders';
const ordersList = 'api/orders/get-orders';
const orderById = 'api/orders/:id/edit';
const updateStatusUrl = 'api/orders/:id/status';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    currentOrder: {},
    prefix: 'ZL-:id',
    orders: [],
    sortableColumns: ['priority_id', 'ordered_at', 'order_number'],
    totalRecords: null,
    table: {
      isLoading: false,
    },
    fetchParams: {
      search: '',
      columnFilters: {
        status_id: null,
        priority_id: null,
      },
      sort: [
        {
          field: 'ordered_at',
          type: 'desc',
        },
      ],
      page: 1,
      perPage: 10,
    },
  },

  getters: {
    all: (state) => state.orders,
    byId: (state, orderId) => state.orders.filter(({ id }) => id === orderId)[0],
    prefix: (state) => state.prefix,
    label: (state) => (id) => state.prefix.replace(':id', id),
    isLoading: (state) => state.table.isLoading,
    current: (state) => state.currentOrder
      || state.orders.filter(({ id }) => id === state.currentOrder.id)[0],
    serverParams: (state) => state.fetchParams,
    totalRecords: (state) => state.totalRecords,
    columnSort: (state) => (field) => {
      const { sort } = state.fetchParams;
      const option = sort.filter((row) => row.field === field);

      if (option.length === 0) return null;
      return option[0].type;
    },
  },

  mutations: {
    SET_JOBS(state, payload) {
      state.orders = payload;
    },
    SET_CURRENT_JOB(state, payload) {
      const newOrders = state.orders.map((order) => {
        if (order.id !== payload.id) return order;

        return payload;
      });
      state.currentOrder = payload;
      state.orders = [...newOrders];
    },
    ADD_JOB(state, payload) {
      state.orders = [...state.orders, payload];
    },
    SET_TOTAL_RECORDS(state, payload) {
      state.totalRecords = payload;
    },
    UPDATE_IN_ORDERS(state, payload) {
      state.orders = state.orders.map((record) => {
        if (record.id !== payload.id) return record;
        return {
          ...record,
          ...payload,
          subjects: [...record.subjects, ...payload.subjects],
        };
      });
    },
    UPDATE_FILTER(state, payload) {
      state.fetchParams.columnFilters = {
        ...state.fetchParams.columnFilters,
        ...payload,
      };
    },
    SET_SERVER_PARAM(state, newParams) {
      state.fetchParams = newParams;
    },
    SET_TABLE_LOADING(state, newValue) {
      state.table.isLoading = newValue;
    },
    UPDATE_STATUS(state, payload) {
      const orderId = state.currentOrder.id;

      state.orders = state.orders.map((order) => {
        if (order.id !== orderId) return order;

        return {
          ...order,
          status_id: payload,
        };
      });

      state.currentOrder.status_id = payload;
    },
  },

  actions: {
    fetch({ commit, state }, params = state.fetchParams) {
      commit('SET_TABLE_LOADING', true);
      ApiClient.get(ordersList, params).then((payload) => {
        if (payload.status !== 200) return null;
        commit('SET_TOTAL_RECORDS', payload.data.data.totalRecords);
        return commit('SET_JOBS', payload.data.data.orders);
      }).finally(() => {
        commit('SET_TABLE_LOADING', false);
      });
    },

    fetchById({ commit, dispatch }, id) {
      const url = orderById.replace(':id', id);

      const request = ApiClient.get(url);

      request.then((payload) => {
        if (payload.status !== 200) return null;

        dispatch('comments/set', payload.data.data.comments);
        dispatch('subject/set', payload.data.data.subjects);
        dispatch('attachments/set', payload.data.data.files);

        commit('SET_CURRENT_JOB', payload.data.data);
        return request;
      }).catch(() => {});

      return request;
    },

    create({ dispatch }, data) {
      const request = ApiClient.post(endpoint, data);

      request.then((res) => {
        if (res.status === 200) {
          dispatch('fetch');
        }
      }).catch(() => {});

      return request;
    },

    remove({ dispatch }, id) {
      const url = 'api/orders/:id'.replace(':id', id);

      const request = ApiClient.delete(url);
      request.then(() => dispatch('fetch'));

      return request;
    },

    update({ commit, dispatch }, data) {
      if (!data.id) return false;
      const url = 'api/orders/:id'.replace(':id', data.id);

      const request = ApiClient.put(url, data);

      request.then((payload) => {
        commit('SET_CURRENT_JOB', payload.data.data);
        dispatch('logs/fetch', data.id);
      }).catch(() => {});

      return request;
    },

    updateColumnFilter({ state, commit, dispatch }, parameter) {
      const newParams = {
        ...state.fetchParams,
        columnFilters: {
          ...state.fetchParams.columnFilters,
          ...parameter,
        },
      };
      commit('SET_SERVER_PARAM', newParams);
      return dispatch('fetch', newParams);
    },

    updatePageSettings({ state, commit }, parameter) {
      const newParams = {
        ...state.fetchParams,
        page: Number(parameter.currentPage),
        perPage: Number(parameter.currentPerPage),
      };

      return commit('SET_SERVER_PARAM', newParams);
    },

    updateColumnSorting({ state, commit, dispatch }, column) {
      if (!state.sortableColumns.includes(column.field)) return null;
      const orderNumberParams = column.field === 'order_number' ? { field: 'ordered_at', type: column.type } : null;
      const orderedAtParams = column.field === 'ordered_at' ? { field: 'created_at', type: column.type } : null;

      const newSort = [column];

      if (orderNumberParams) {
        newSort.unshift(orderNumberParams);
      }
      if (orderedAtParams) {
        newSort.push(orderedAtParams);
      }

      const newParams = {
        ...state.fetchParams,
        sort: newSort,
      };
      commit('SET_SERVER_PARAM', newParams);
      return dispatch('fetch', newParams);
    },

    updateSearchTerm({ state, commit }, term) {
      const newParams = {
        ...state.fetchParams,
        search: term,
      };

      return commit('SET_SERVER_PARAM', newParams);
    },

    updateStatus({ commit }, data) {
      const url = updateStatusUrl.replace(':id', data.order_id);

      const params = {
        status_id: data.status_id,
      };

      const request = ApiClient.patch(url, params);
      request.then((res) => {
        if (res.status !== 200) return;

        commit('SET_CURRENT_JOB', res.data.data);
        commit('UPDATE_STATUS', data.status_id);
      });

      return request;
    },

    reset({ commit }) {
      commit('SET_JOBS', []);
    },
  },

  modules: {
    statuses,
    comments,
    subject,
    attachments,
    logs,
  },
};
