class Section {
  constructor(name, composer) {
    this.name = name;
    this.composer = composer;
  }

  appendText(...params) {
    const { layout } = this.composer;
    const breakPage = params[3] ? params[3] : true;

    const { w, h } = this.composer.doc.getTextDimensions(params[0]);

    const newParams = [...params];

    // increase current height by text height and it's position
    this.composer.layout.currentHeight = params[2] + h;

    if (breakPage && layout.currentHeight + h + 30 > layout.pageSize.height - layout.gutters.y) {
      this.composer.doc.addPage();

      if (layout.currentHeight >= params[2]) {
        newParams[2] = params[2] - layout.currentHeight + h + layout.gutters.y;
      }

      this.composer.layout.currentHeight = layout.gutters.y + h;
    }

    this.composer.doc.text(...newParams, {
      maxWidth: layout.pageSize.width - params[1] - layout.gutters.x,
    });

    return {
      width: w,
      height: h,
    };
  }

  appendSectionHeader(...params) {
    const sectionParams = [...params];
    sectionParams[2] = params[2] + 5;
    this.composer.doc.setFont('OpenSans-Bold', 'normal');
    this.appendText(...sectionParams);
    this.composer.layout.currentHeight += 2;
    this.composer.restoreDefaultFont();
  }

  getScaledImageProperties(image, desiredWidth = null) {
    const properties = this.composer.doc.getImageProperties(image);
    const { width, height } = properties;

    const aspectRatio = width / height;

    return {
      width: desiredWidth,
      height: desiredWidth / aspectRatio,
      aspectRatio,
    };
  }
}

export default Section;
