import { validate } from 'vee-validate';
import { reactive, ref, watch } from 'vue';

const useFieldValidation = (rules) => {
  const errors = reactive({ ...rules });
  const fields = reactive({});

  const emptyErrors = () => {
    Object.keys(errors).forEach((error) => {
      errors[error] = null;
    });
  };

  const setErrors = (newErrors) => {
    Object.keys(errors).forEach((error) => {
      errors[error] = newErrors[error] ?? null;
    });
  };

  const isValid = async (field) => {
    const mappedValues = {};

    Object.keys(fields).forEach((mappedField) => {
      mappedValues[mappedField] = fields[mappedField]?.value;
    });

    const validated = await validate(fields[field]?.value, rules[field], {
      name: field,
      values: mappedValues,
    });

    return validated;
  };

  const validateField = (field) => {
    const valid = isValid(field).then((result) => {
      errors[field] = result.errors;
    });

    return valid;
  };

  const createField = (field) => {
    fields[field] = ref('');

    watch(fields[field], () => validateField(field));

    return fields[field];
  };

  const initFields = () => {
    Object.keys(rules)
      .forEach((rule) => createField(rule));
  };

  const validateAll = () => Object.keys(fields)
    .map((field) => validateField(field));

  const areFieldsValid = async () => {
    validateAll();

    const areValid = Object.keys(fields)
      .map((field) => {
        const valid = isValid(field);

        return valid;
      });

    const values = await Promise.all(areValid);

    return values.every((val) => val.valid === true);
  };

  const reassignFields = (values) => {
    Object.keys(fields)
      .forEach((field) => {
        if (field in fields && field in values) {
          fields[field].value = values[field];
        } else {
          fields[field].value = null;
        }
      });
  };

  const hasErrors = (field) => errors?.[field]?.length > 0;
  const stateField = (field) => (errors?.[field] === null ? null : !hasErrors(field));

  emptyErrors();
  initFields();

  return {
    fields,
    errors,

    resetFields: initFields,

    validateField,
    validateAll,
    hasErrors,
    stateField,
    areFieldsValid,
    reassignFields,
    setErrors,
    emptyErrors,
  };
};

export default useFieldValidation;
