<template>
  <div class="vs-modal-footer">

    <div class="vs-modal-footer-left">
      <slot name="left" />
    </div>

    <div class="vs-modal-footer-buttons">
      <vs-button
        vs-variant="light"
        @click="$emit('cancel')"
        :disabled="disableOnLoading ? isLoading : false"
      >
        <slot name="text-cancel">
          {{ textCancel }}
        </slot>
      </vs-button>

      <vs-button
        vs-variant="primary"
        v-if="!noConfirm"
        :loading="isLoading"
        :disabled="disableOnLoading ? isLoading : false"
        @click="$emit('ok')"
      >
        <slot name="text-ok">
          {{ textOk }}
        </slot>
      </vs-button>
    </div>

  </div>
</template>

<script>
import VsButton from '@/components/ui/vs-button/VsButton.vue';

export default {
  name: 'VsModalFooter',
  emits: ['cancel', 'ok'],
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    disableOnLoading: {
      type: Boolean,
      default: true,
    },
    textCancel: {
      type: String,
      default: 'Cancel',
    },
    textOk: {
      type: String,
      default: 'OK',
    },
    noConfirm: {
      type: Boolean,
      default: false,
    },
  },
  components: { VsButton },
};
</script>

<style lang="sass" scoped>
.vs-modal-footer
  width: 100%
  display: flex
  align-items: center
  justify-content: space-between

  &-buttons
    display: flex
    align-items: center
    gap: 10px
</style>
