import { reactive, toRefs } from 'vue';

// TODO move this export to a global file
export const vsModal = (overwrites = {}) => ({
  visible: false,
  isLoading: false,
  data: {},
  cleanData() { this.data = {}; },
  footerVisible: true,
  noCloseOnBackdrop: true,
  noConfirm: false,
  size: 'lg',

  ...overwrites,

  text: {
    title: 'Vs Modal',
    ok: 'Zapisz',
    cancel: 'Anuluj',
    loader: 'Zapisywanie...',
    ...overwrites.text,
  },

  visibilityObservers: [],
  notifyObservers(visibility, data = this.data) {
    this.visibilityObservers.forEach((o) => {
      if (o.notifyOn === visibility) o.callback(data);
    });
  },
  unregisterObserver(identifier) {
    if (!identifier) return;
    this.visibilityObservers = this.visibilityObservers
      .filter((o) => o.identifier !== identifier);
  },

  open(data = {}) {
    this.data = data;
    this.visible = true;
    this.notifyObservers('open', data);
  },
  close() {
    this.visible = false;
    this.notifyObservers('close');
  },

  on({ close, open, success }, identifier = null) {
    if (close) this.visibilityObservers.push({ identifier, notifyOn: 'close', callback: close });
    if (open) this.visibilityObservers.push({ identifier, notifyOn: 'open', callback: (data) => open(data) });
    if (success) this.visibilityObservers.push({ identifier, notifyOn: 'success', callback: success });
  },

});

const modals = reactive({
  modalCreateInvestment: vsModal({
    text: {
      title: 'Tworzenie inwestycji',
    },
  }),
  modalReportDetails: vsModal({
    noCloseOnBackdrop: false,
    footerVisible: false,
    text: {
      title: 'Szczegóły raportu',
    },
  }),
  modalCreateFueling: vsModal({
    text: {
      title: 'Dodawanie dostawy paliwa',
    },
  }),
  modalEditFueling: vsModal({
    text: {
      title: 'Aktualizowanie dostawy paliwa',
    },
  }),
  modalFuelingDetails: vsModal({
    noCloseOnBackdrop: false,
    footerVisible: false,
    text: {
      title: 'Szczegóły dostawy paliwa',
    },
  }),
});

export default function useInvestmentsModals() {
  return { ...toRefs(modals) };
}
