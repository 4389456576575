export default {
  investment: {
    create: {
      title: 'Tworzenie nowej inwestycji',
      loading: 'Tworzenie inwestycji...',

      confirm: 'Utwórz inwestycję',
      cancel: 'Anuluj',
    },
    delete: {
      title: 'Potwierdź usunięcie inwestycji',
      text: 'Czy na pewno chcesz usunąć inwestycję {name}? Tej akcji nie można cofnąć.',
    },
  },
  fueling: {
    delete: {
      title: 'Potwierdź usunięcie dostawy paliwa',
      text: 'Czy na pewno chcesz usunąć dostawę z dnia {date}? Tej akcji nie można cofnąć.',
    },
  },
  subject: {
    create: {
      title: 'Dodawanie przedmiotu zamówienia',
      loading: 'Dodawanie przedmiotu...',

      confirm: 'Dodaj przedmiot',
      cancel: 'Anuluj',
    },
    edit: {
      title: 'Aktualizowanie przedmiotu zamówienia',
      loading: 'Aktualizowanie przedmiotu...',

      confirm: 'Aktualizuj przedmiot',
      cancel: 'Anuluj',
    },
    delete: {
      title: 'Potwierdź usunięcie przedmiotu zlecenia',
      text: 'Czy na pewno chcesz usunąć przedmiot w technologii {technology}? Tej akcji nie można cofnąć.',
    },
  },
  demand: {
    create: {
      title: 'Dodawanie zapotrzebowania',
      loading: 'Dodawanie zapotrzebowania...',

      confirm: 'Dodaj zapotrzebowanie',
      cancel: 'Anuluj',
    },
    edit: {
      title: 'Edycja zapotrzebowania',
      loading: 'Aktualizowanie zapotrzebowania...',

      confirm: 'Zapisz zapotrzebowanie',
      cancel: 'Anuluj',
    },
    delete: {
      title: 'Potwierdź usunięcie przedmiotu zapotrzebowania',
      text: 'Czy na pewno chcesz usunąć zapotrzebowanie {name}? Tej akcji nie można cofnąć.',
    },
  },
  offer: {
    create: {
      title: 'Dodawanie oferty',
      loading: 'Dodawanie oferty...',

      confirm: 'Dodaj ofertę',
      cancel: 'Anuluj',
    },
    edit: {
      title: 'Edycja oferty',
      loading: 'Aktualizowanie oferty...',

      confirm: 'Zapisz ofertę',
      cancel: 'Anuluj',
    },
    delete: {
      title: 'Potwierdź usunięcie oferty',
      text: 'Czy na pewno chcesz usunąć ofertę dostawcy {provider}? Tej akcji nie można cofnąć.',
    },
  },
  resource: {
    create: {
      title: 'Przypisywanie zasobu',
      loading: 'Przypisywanie zasobu...',

      confirm: 'Dodaj zasób',
      cancel: 'Anuluj',
    },
    edit: {
      title: 'Edycja zasobu',
      loading: 'Aktualizowanie zasobu...',

      confirm: 'Zapisz zasób',
      cancel: 'Anuluj',
    },
    delete: {
      title: 'Potwierdź usunięcie zasobu',
      text: 'Czy na pewno chcesz usunąć zasób {name}? Tej akcji nie można cofnąć.',
    },
  },
  employee: {
    create: {
      title: 'Przypisywanie pracownika',
      loading: 'Przypisywanie pracownika...',

      confirm: 'Dodaj pracownika',
      cancel: 'Anuluj',
    },
    edit: {
      title: 'Edycja pracownika',
      loading: 'Aktualizowanie pracownika...',

      confirm: 'Zapisz pracownika',
      cancel: 'Anuluj',
    },
    delete: {
      title: 'Potwierdź usunięcie pracownika',
      text: 'Czy na pewno chcesz usunąć pracownika {name}? Tej akcji nie można cofnąć.',
    },
  },
  brigade: {
    create: {
      title: 'Tworzenie brygady',
      loading: 'Tworzenie brygady...',

      confirm: 'Utwórz brygadę',
      cancel: 'Anuluj',
    },
    edit: {
      title: 'Edycja brygady',
      loading: 'Aktualizowanie brygady...',

      confirm: 'Zapisz brygadę',
      cancel: 'Anuluj',
    },
    delete: {
      title: 'Potwierdź usunięcie brygady',
      text: 'Czy na pewno chcesz usunąć brygadę {name}? Tej akcji nie można cofnąć.',
    },
  },
  report: {
    create: {
      title: 'Dodawanie raportu',
      loading: 'Dodawanie raportu...',

      confirm: 'Utwórz raport',
      cancel: 'Anuluj',
    },
    edit: {
      title: 'Edycja raportu',
      loading: 'Aktualizowanie raportu...',

      confirm: 'Zapisz raport',
      cancel: 'Anuluj',
    },
    delete: {
      title: 'Potwierdź usunięcie raportu',
      text: 'Czy na pewno chcesz usunąć raport z dnia {date}? Zaraportowane paliwo i motogodziny nie zostaną usunięte.',
    },
  },
  material: {
    create: {
      title: 'Dodawanie materiału',
      loading: 'Dodawanie materiału...',

      confirm: 'Utwórz materiał',
      cancel: 'Anuluj',
    },
    edit: {
      title: 'Edycja materiału',
      loading: 'Aktualizowanie materiału...',

      confirm: 'Zapisz materiał',
      cancel: 'Anuluj',
    },
    delete: {
      title: 'Potwierdź usunięcie materiału',
      text: 'Czy na pewno chcesz usunąć materiał "{name}"? Tej akcji nie można cofnąć.',
    },
  },
  reinforcement: {
    create: {
      title: 'Dodawanie zbrojenia',
      loading: 'Dodawanie zbrojenia...',

      confirm: 'Utwórz zbrojenie',
      cancel: 'Anuluj',
    },
    edit: {
      title: 'Edycja zbrojenia',
      loading: 'Aktualizowanie zbrojenia...',

      confirm: 'Zapisz zbrojenie',
      cancel: 'Anuluj',
    },
    delete: {
      title: 'Potwierdź usunięcie zbrojenia',
      text: 'Czy na pewno chcesz usunąć zbrojenie "{name}"? Tej akcji nie można cofnąć.',
    },
  },
  workType: {
    create: {
      title: 'Dodawanie typu robót',
      loading: 'Dodawanie typu robót...',

      confirm: 'Utwórz typ robót',
      cancel: 'Anuluj',
    },
    edit: {
      title: 'Edycja typu robót',
      loading: 'Aktualizowanie typu robót...',

      confirm: 'Zapisz typ robót',
      cancel: 'Anuluj',
    },
    delete: {
      title: 'Potwierdź usunięcie typu robót',
      text: 'Czy na pewno chcesz usunąć typ robót "{name}"? Tej akcji nie można cofnąć.',
    },
  },
  workGroup: {
    create: {
      title: 'Dodawanie grupy',
      loading: 'Dodawanie grupy...',

      confirm: 'Utwórz grupę',
      cancel: 'Anuluj',
    },
    edit: {
      title: 'Edycja grupy',
      loading: 'Aktualizowanie grupy...',

      confirm: 'Zapisz grupę',
      cancel: 'Anuluj',
    },
    delete: {
      title: 'Potwierdź usunięcie grupy',
      text: 'Czy na pewno chcesz usunąć grupę "{name}"? Tej akcji nie można cofnąć.',
    },
  },
};
