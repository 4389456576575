import Vue from 'vue';
import Vuex from 'vuex';
import ApiClient from '@/services/api';

import Echo from '@/services/echo';

import notifications from './notifications';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    // determine whether we should attempt the authentication or not
    attemptAuth: JSON.parse(localStorage.getItem('attemptAuth')) || 'false',

    authenticated: false,
    overridenPermissions: [],
    user: null,
  },

  getters: {
    attemptAuth(state) {
      return state.attemptAuth;
    },
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
    permissions(state) {
      return state.user.permissions.map((p) => p.name);
    },
    finalPermissions(state) {
      return state.user.final_permissions.map((p) => p.name);
    },
    permissionsOverriden(state, getters) {
      if (state.overridenPermissions.length === 0) return false;
      if (getters.permissions.length === state.overridenPermissions.length) {
        return !getters.permissions
          .every((perm) => state.overridenPermissions.find((o) => o === perm));
      }

      return true;
    },
    userHasPermission: ({ overridenPermissions }, getters) => (action, parent) => {
      let { finalPermissions } = getters;

      if (getters.permissionsOverriden) {
        finalPermissions = overridenPermissions;
      }

      if (!action || !parent) return false;

      const granted = finalPermissions.includes(`${parent}.${action}`)
        || finalPermissions.includes(`${parent}.*`)
        || finalPermissions.includes(`${parent.split('.')[0]}.*`)
        || (getters.user.forename === 'Master' && !getters.permissionsOverriden);

      return granted;
    },

    userHasAnyPermission: ({ overridenPermissions }, getters) => (requested) => {
      if (requested.split('.').length === 3) {
        const [module, resource, action] = requested.split('.');
        return getters.userHasPermission(action, `${module}.${resource}`);
      }
      let { finalPermissions } = getters;
      if (getters.permissionsOverriden) {
        finalPermissions = overridenPermissions;
      }

      const granted = (getters.user.forename === 'Master' && !getters.permissionsOverriden)
        || finalPermissions.find((p) => p.startsWith(requested))
        || requested.split('.')?.some((r) => finalPermissions.find((p) => `${r}.*` === p));

      return granted;
    },

    fullname: (state) => `${state.user?.forename || ''} ${state.user?.surname || ''}`,
    forename: (state) => state.user?.forename,
    surname: (state) => state.user?.surname,
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_AUTHENTICATED(state, authenticated) {
      state.authenticated = authenticated;
    },
    SET_ATTEMPT_AUTH(state, loggedIn) {
      localStorage.setItem('attemptAuth', loggedIn);
      state.attemptAuth = loggedIn;
    },
    SET_OVERRIDEN_PERMISSIONS(state, value = []) {
      state.overridenPermissions = value;
    },
  },

  actions: {
    async signIn({ dispatch, commit }, credentials) {
      await ApiClient.post('/login', credentials);

      commit('SET_ATTEMPT_AUTH', true);
      return dispatch('attempt');
    },

    async logOut({ commit, dispatch }) {
      await ApiClient.post('/logout');
      commit('SET_ATTEMPT_AUTH', false);

      // reset store to initial state
      dispatch('reset', null, { root: true });
      return commit('SET_AUTHENTICATED', false);
    },

    async attempt({ commit, getters, dispatch }) {
      try {
        if (getters.attemptAuth !== true) throw new Error('No saved login information');
        const res = await ApiClient.get('/api/core/user');
        commit('SET_AUTHENTICATED', true);
        commit('SET_USER', res.data);
        commit('SET_ATTEMPT_AUTH', true);
        dispatch('bindEchoChannels');
        dispatch('app/fetchModulesStatus', null, { root: true });
      } catch (err) {
        commit('SET_AUTHENTICATED', false);
        commit('SET_USER', null);
        commit('SET_ATTEMPT_AUTH', false);
        return err;
      }
      return false;
    },

    bindEchoChannels({ dispatch, getters }) {
      dispatch('leaveEchoChannels');
      Echo.channel('public-channel-message').listen('PublicChannelMessage', (e) => {
        dispatch('echo/newPublicMessage', e, { root: true });
      });

      const hash = getters?.user?.websocket_hash;
      if (!hash) return;

      Echo.channel(`channel-message.${hash}`)
        .listen('CreateNotificationEvent', (e) => {
          dispatch('echo/newNotification', e, { root: true });
        });
    },

    leaveEchoChannels({ getters }) {
      const hash = getters?.user?.websocket_hash;
      if (!hash) return;

      const publicChannel = 'public-channel-message';
      const privateChannel = `channel-message.${hash}`;

      Echo.leave(publicChannel);
      Echo.leave(privateChannel);
    },
  },

  modules: {
    notifications,
  },
};
