var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vs-modal-footer"
  }, [_c('div', {
    staticClass: "vs-modal-footer-left"
  }, [_vm._t("left")], 2), _c('div', {
    staticClass: "vs-modal-footer-buttons"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "light",
      "disabled": _vm.disableOnLoading ? _vm.isLoading : false
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_vm._t("text-cancel", function () {
    return [_vm._v(" " + _vm._s(_vm.textCancel) + " ")];
  })], 2), !_vm.noConfirm ? _c('vs-button', {
    attrs: {
      "vs-variant": "primary",
      "loading": _vm.isLoading,
      "disabled": _vm.disableOnLoading ? _vm.isLoading : false
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('ok');
      }
    }
  }, [_vm._t("text-ok", function () {
    return [_vm._v(" " + _vm._s(_vm.textOk) + " ")];
  })], 2) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }