import { ref } from 'vue';
import ApiClient from '@/services/api';
import showToast from '@/libs/toasts';
import {
  PasswordResetTokenError,
} from '@feedback/module/core';

import useFieldValidation from '../useFieldValidation';

const usePasswordReset = (token) => {
  const isTokenValid = !!token;
  const resetSuccessful = ref(false);

  const {
    fields, errors, hasErrors, stateField,
    areFieldsValid, validateField,
  } = useFieldValidation({
    email: 'required|email',
    password: 'required|min:8',
    passwordConfirmation: 'required|confirmed:password',
  });

  const submitResetForm = async () => {
    if (!isTokenValid) return;

    const formValid = await areFieldsValid();

    if (!formValid) return;

    ApiClient
      .post('/reset-password', {
        email: fields.email.value,
        password: fields.password.value,
        password_confirmation: fields.passwordConfirmation.value,
        token,
      })
      .then(() => {
        resetSuccessful.value = true;
      })
      .catch(({ response }) => {
        if (response?.data?.errors?.email) {
          showToast(PasswordResetTokenError);
        }
      });
  };

  return {
    isTokenValid,
    fields,
    errors,
    resetSuccessful,

    hasErrors,
    stateField,
    submitResetForm,
    validateField,
  };
};

export default usePasswordReset;
