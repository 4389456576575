<template>
  <div class="task-label">
    <router-link :to="{ name: 'tasks-details', params: { id } }" class="prefix">
      {{ taskPrefix }}
    </router-link>
    {{ title }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['id', 'title'],
  computed: {
    ...mapGetters({
      taskLabel: 'tasks/label',
    }),
    taskPrefix() {
      return this.taskLabel(this.id);
    },
  },
};
</script>

<style lang="sass" scoped>
.prefix
  font-weight: 600
</style>
