<template>
  <tr>
    <td class="name">{{ item.name }}</td>
    <td class="quantity">{{ item.amount.toLocaleString('ru-RU') }} {{ unit.code }}</td>
    <td>{{ item.unit_price }} {{ currency.code }}</td>
    <td>{{ (item.unit_price * item.amount).toLocaleString('ru-RU') }} {{ currency.code }}</td>
    <td class="edit">

      <authorization-guard silent
        permission="orders.subject.update"
      >
        <b-button class="btn-icon" variant="flat-secondary">
          <feather-icon
            icon="Edit3Icon"
            size="20"
            @click="edit"
            ></feather-icon>
        </b-button>
      </authorization-guard>

      <authorization-guard silent
        permission="orders.subject.destroy"
      >
        <b-button class="btn-icon" variant="flat-secondary">
          <feather-icon
            icon="Trash2Icon"
            size="20"
            @click="remove"
            ></feather-icon>
        </b-button>
      </authorization-guard>

    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import { BButton } from 'bootstrap-vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';

export default {
  props: ['item'],
  components: {
    BButton,
    AuthorizationGuard,
  },
  computed: {
    ...mapGetters({
      currentOrder: 'orders/current',
      currencyById: 'currencies/byId',
      unitById: 'units/byId',
    }),
    currency() {
      return this.currencyById(this.currentOrder.currency_id);
    },
    unit() {
      return this.unitById(this.item.unit_id) || {};
    },
  },
  methods: {
    remove() {
      this.$emit('remove', this.item);
    },
    edit() {
      this.$emit('edit', this.item);
    },
  },
};
</script>
