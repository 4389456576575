<template>
  <div class="header-section">
    <div class="header">

      <slot name="title">
        <h4 class="title">
          {{ title }}
        </h4>
      </slot>

      <div class="action" v-if="action && canAction">
        <vs-button
          @click="$emit('actionClick')"
          vs-variant="light"
          >
          {{ action }}
        </vs-button>
      </div>

    </div>

    <div class="section-content">
      <slot />
    </div>
  </div>
</template>

<script>
import VsButton from '@/components/ui/vs-button/VsButton.vue';

export default {
  components: {
    VsButton,
  },
  props: {
    title: String,
    action: String,
    canAction: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="sass" scoped>
.header
  display: flex
  align-items: center

  h4
    padding: 0
    margin: 0

  .action
    margin-left: auto

.section-content
  margin-top: 15px
</style>
