var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settings-tab-base-models"
  }, [_c('authorization-guard', {
    attrs: {
      "silent": "",
      "permission": "base.producer_model.store"
    }
  }, [_c('div', {
    staticClass: "buttons"
  }, [_c('div', {
    staticClass: "group"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.openAddModal
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t('base.producer.model.button.add')) + " ")])], 1)])]), _c('br'), _c('authorization-guard', {
    attrs: {
      "permission": "base.producer_model.index"
    }
  }, [_c('div', {
    staticClass: "model-list-wrapper"
  }, _vm._l(_vm.producers, function (producer) {
    return _c('vs-list', {
      key: producer,
      staticClass: "model-list",
      attrs: {
        "showHeaders": "",
        "items": _vm.producersModels.filter(function (model) {
          return model.producer.name === producer;
        }),
        "headers": [producer, 'Typ']
      },
      on: {
        "edit": _vm.openEditModal,
        "delete": _vm.deleteProducerModel
      },
      scopedSlots: _vm._u([{
        key: "cells",
        fn: function (_ref) {
          var _row$type;
          var row = _ref.row;
          return [_c('div', {
            staticClass: "vs-list-item-cell"
          }, [_vm._v(" " + _vm._s(_vm.$t(`base.global.resourceType.${row === null || row === void 0 ? void 0 : (_row$type = row.type) === null || _row$type === void 0 ? void 0 : _row$type.slug}`)) + " ")])];
        }
      }], null, true)
    });
  }), 1)]), _c('settings-tab-base-producer-model-modal-edit'), _c('settings-tab-base-producer-model-modal-add')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }