<template>
  <b-dropdown-item href="#" @click="$emit('click')">
    <div class="tool">

      <div class="tool-status">
        <feather-icon v-if="enabled"
          size="21"
          icon="CheckIcon"/>
        <feather-icon v-else
          size="21"
          icon="XIcon"/>
      </div>

      <div class="tool-name">
        <slot />
      </div>

    </div>
  </b-dropdown-item>
</template>

<script>
import { BDropdownItem } from 'bootstrap-vue';

export default {
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BDropdownItem,
  },
};
</script>

<style lang="sass" scoped>
.tool
  display: flex
  align-items: center

  &-name
    margin-left: 10px
</style>
