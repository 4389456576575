import { h, ref, watch } from 'vue';
import Fuse from 'fuse.js';
import VsPicker from './VsPicker.vue';

export default function initializePickerComponent(name, pickerParams) {
  const { options, fetchFunction, scopedSlots } = pickerParams;

  return {
    name,
    props: {
      value: [Number, Array, String, Object],
      reduce: {
        type: Function,
        default: pickerParams.props.reduce,
      },
      multiple: Boolean,
      autopick: Boolean,
      optionsKey: {
        type: String,
        default: pickerParams.props?.optionsKey,
      },
      selectable: Function,
      excluded: Function,
      clearable: Boolean,
      disabled: Boolean,
      placeholder: String,
      labelProps: Object,
      withDeleted: Boolean,
    },
    setup(props, { emit }) {
      const isLoading = ref(true);

      const fetchPickerOptions = async () => {
        if (!fetchFunction) return;
        isLoading.value = true;

        await fetchFunction();

        if (props.autopick && !props.value && options.value.length) {
          emit('input', props.reduce(options.value[0]));
        }

        isLoading.value = false;
      };

      watch(() => props.value, (val) => {
        if (val || !props.autopick) return;
        emit('input', props.reduce(options.value[0]));
      });

      fetchPickerOptions();

      return { isLoading };
    },
    render() {
      const pickerOptions = this.optionsKey
        ? options.value?.[this.optionsKey] ?? []
        : options.value;

      const excludeFn = (o) => !this.excluded?.(o);

      const fuseSearch = (fuseOptions, search) => {
        const fuse = new Fuse(fuseOptions, {
          keys: pickerParams.filter ?? [pickerParams.props.label ?? 'name'],
          threshold: 0.2,
        });
        return search.length
          ? fuse.search(search).map(({ item }) => item)
          : fuse.list;
      };

      return h(VsPicker, {
        on: this.$listeners,
        props: {
          options: pickerOptions.filter(excludeFn),
          value: this.value,
          isLoading: this.isLoading,
          multiple: this.multiple,
          clearable: this.clearable,
          ...pickerParams.props,
          reduce: this.reduce,
          placeholder: this.placeholder ?? pickerParams.props.placeholder,
          disabled: this.disabled ?? pickerParams.props.disabled,
          filter: fuseSearch,
          labelProps: this.labelProps ?? pickerParams.props.labelProps,
          withDeleted: this.withDeleted ?? pickerParams.props.withDeleted,
        },
        scopedSlots,
      });
    },
  };
}

export const emits = ['input', 'pick'];
export const createPickerAttributes = (
  context = {},
  defaultPickerProps = {},
  scopedSlots = {},
) => ({
  props: {
    ...defaultPickerProps,
  },
  on: {
    input: (v) => context.emit('input', v),
    pick: (v) => context.emit('pick', v),
  },
  scopedSlots,
});
