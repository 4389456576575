<script>
import Vue, { h } from 'vue';
import VsPickerVue from '@/components/ui/vs-picker/VsPicker.vue';
import { createPickerAttributes, emits } from '@/components/ui/vs-picker';
import useCoreBranchStore from '@/stores/module/core/useCoreBranchStore';

export default Vue.component('CorePriorityPicker', {
  emits,
  setup(_, ctx) {
    const store = useCoreBranchStore();

    return () => h(VsPickerVue, createPickerAttributes(ctx, {
      placeholder: 'Wybierz region z listy',
      reduce: (option) => option.id,
      options: store.state,
      ...ctx.attrs,
    }, {
      option: ({ option }) => option.shortcut,
      'selected-option': ({ option }) => option.shortcut,
    }));
  },
  components: { VsPickerVue },
});
</script>
