export default [
  {
    header: 'global.navigation.shortcuts',
  },
  {
    title: 'global.views.home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    header: 'global.navigation.modules',
  },
  {
    title: 'tasks.pageTitle',
    route: 'tasks',
    module: 'Tasks',
    icon: 'CheckSquareIcon',
    permission: 'tasks.task',
  },
  {
    title: 'orders.pageTitle',
    route: 'orders',
    module: 'Orders',
    icon: 'ArrowRightCircleIcon',
    permission: 'orders.order',
  },
  {
    title: 'cadre.pageTitle',
    route: 'cadre',
    module: 'Cadre',
    icon: 'UsersIcon',
    permission: 'cadre.employee',
  },
  {
    title: 'crm.pageTitle',
    icon: 'PackageIcon',
    module: 'Crm',
    permission: 'crm.company',
    children: [
      {
        title: 'crm.providers.pageTitle',
        route: 'crm.providers',
        permission: 'crm.company',
      },
      {
        title: 'crm.clients.pageTitle',
        route: 'crm.clients',
        permission: 'crm.company',
      },
    ],
  },
  {
    title: 'base.pageTitle',
    icon: 'TruckIcon',
    module: 'Base',
    permission: 'base',
    children: [
      {
        title: 'base.machines.pageTitle',
        route: 'base.machines',
        permission: 'base.resource',
      },
      {
        title: 'base.equipment.pageTitle',
        route: 'base.equipment',
        permission: 'base.resource',
      },
      {
        title: 'base.cars.pageTitle',
        route: 'base.cars',
        permission: 'base.resource',
      },
      {
        title: 'base.transport.pageTitle',
        route: 'base.transport',
        permission: 'base.transport',
      },
    ],
  },
  {
    title: 'investments.pageTitle',
    route: 'investments',
    icon: 'CompassIcon',
    module: 'Investment',
    permission: 'investment.investment',
  },
  {
    header: 'global.navigation.development',
    development: true,
  },
  {
    title: 'permission.core.children.permission.name',
    route: 'development.permissions',
    icon: 'CodeIcon',
    development: true,
  },
];
