var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navbar-container main-menu-content"
  }, [_c('horizontal-nav-menu-items', {
    attrs: {
      "items": _vm.navMenuItems
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }