export default {
  header: 'Przypominanie hasła',
  description: 'Podaj swój email, którego używasz do logowania się do aplikacji',
  success: {
    header: 'Sukces',
    description: 'Na podany adres e-mail został wysłany link, którego możesz użyć do ustanowienia nowego hasła do aplikacji',
    button: {
      redirectLogin: 'Przejdź do logowania',
    },
  },
  field: {
    email: {
      placeholder: 'Podaj swój adres e-mail',
    },
  },
  button: {
    reset: 'Wyślij link do zresetowania hasła',
    back: 'Powrót do logowania',
  },
};
