export default {
  /* Errors */
  DeleteError: {
    title: 'Nie udało się usunąć pracownika',
    description: 'Zasób nie istnieje lub nie masz stosownych uprawnień',
  },
  ArchiveError: {
    title: 'Nie udało się zarchiwizować pracownika',
    description: 'Zasób nie istnieje lub nie masz stosownych uprawnień',
  },
  RestoreError: {
    title: 'Nie udało się przywrócić pracownika',
    description: 'Zasób nie istnieje lub nie masz stosownych uprawnień',
  },
  BadInputError: {
    title: 'Błędnie wypełniony formularz',
    description: 'Popraw oznakowane pola w formularzu',
  },
  ServerError: {
    title: 'Błąd połączenia z serwerem',
    description: 'Spróbuj ponownie lub odśwież stronę',
  },
  EditError: {
    title: 'Wystąpił błąd przy aktualizacji danych',
    description: 'Popraw formularz lub odśwież stronę',
  },
  FetchEmployeeError: {
    title: 'Wystąpił błąd przy pobieraniu danych pracownika',
    description: 'Żądany pracownik nie istnieje',
  },

  BadSkillInputError: {
    title: 'Nie udało się dodać kompetencji',
    description: 'Popraw oznakowane pola w formularzu',
  },

  AddExaminationError: {
    title: 'Nie udało się dodać badania lekarskiego',
    description: 'Popraw oznakowane pola w formularzu',
  },

  UpdateExaminationError: {
    title: 'Nie udało się zaktualizować badania lekarskiego',
    description: 'Popraw oznakowane pola w formularzu',
  },

  FetchExaminationHistoryError: {
    title: 'Wystąpił błąd podczas pobierania historii badań',
  },

  EditEmployeeSkillsError: {
    title: 'Wystąpił błąd podczas edycji uprawnień / kursów',
  },

  AddTrainingError: {
    title: 'Wystąpił błąd podczas dodawania szkolenia',
  },

  UpdateTrainingError: {
    title: 'Wystąpił błąd podczas edycji szkolenia',
  },

  FetchTrainingHistoryError: {
    title: 'Wystąpił błąd podczas pobierania listy szkoleń',
  },

  DeleteExaminationError: {
    title: 'Wystąpił błąd podczas usuwania badania',
  },

  DeleteTrainingError: {
    title: 'Wystąpił błąd podczas usuwania szkolenia BHP',
  },

  FetchPickerEmployeesError: {
    title: 'Wystąpił błąd podczas pobierania listy pracowników',
  },

  AttachmentSizeError: {
    title: 'Załącznik jest zbyt duży',
    description: 'Wielkość załącznika nie może przekraczać 60 MB',
  },

  ExportDataError: {
    title: 'Wystąpił błąd podczas eksportu danych',
    description: 'Spróbuj ponownie później',
  },

  /* Success */

  AddSuccess: {
    title: 'Pomyślnie utworzono pracownika',
    description: '{name}',
    action: 'Przejdź do profilu',
  },

  EditSuccess: {
    title: 'Pomyślnie zaktualizowano dane pracownika',
    description: '{name}',
    action: 'Przejdź do profilu',
  },

  DeleteSuccess: {
    title: 'Pomyślnie usunięto pracownika',
    description: '{name}',
  },

  ArchiveSuccess: {
    title: 'Pomyślnie zarchiwizowano pracownika',
    description: '{name}',
  },

  RestoreSuccess: {
    title: 'Pomyślnie przywrócono pracownika',
    description: '{name}',
  },

  AddSkillSuccess: {
    title: 'Pomyślnie utworzono kompetencję',
  },

  EditSkillSuccess: {
    title: 'Pomyślnie zaktualizowano kompetencję',
  },

  DeleteSkillSuccess: {
    title: 'Pomyślnie usunięto kompetencję',
  },

  AddPositionSuccess: {
    title: 'Pomyślnie utworzono stanowisko',
  },

  EditPositionSuccess: {
    title: 'Pomyślnie zaktualizowano stanowisko',
  },

  DeletePositionSuccess: {
    title: 'Pomyślnie usunięto stanowisko',
  },

  AddGroupSuccess: {
    title: 'Pomyślnie utworzono grupę',
  },

  EditGroupSuccess: {
    title: 'Pomyślnie zaktualizowano grupę',
  },

  DeleteGroupSuccess: {
    title: 'Pomyślnie usunięto grupę',
  },

  AddEmploymentFormSuccess: {
    title: 'Pomyślnie utworzono grupę zatrudnienia',
  },

  AddExaminationSuccess: {
    title: 'Pomyślnie dodano badanie lekarskie',
  },

  UpdateExaminationSuccess: {
    title: 'Pomyślnie zaktualizowano badanie lekarskie',
  },

  EditEmployeeSkillsSuccess: {
    title: 'Pomyślnie zaktualizowano uprawnienia / kursy użytkownika',
  },

  AddTrainingSuccess: {
    title: 'Pomyślnie przypisano szkolenie do pracownika',
  },
  UpdateTrainingSuccess: {
    title: 'Pomyślnie zaktualizowano szkolenie pracownika',
  },
  DeleteExaminationSuccess: {
    title: 'Pomyślnie usunięto badanie',
  },
  DeleteTrainingSuccess: {
    title: 'Pomyślnie usunięto szkolenie BHP',
  },
  ExportDataSuccess: {
    title: 'Pomyślnie wyeksportowano dane',
  },
};
