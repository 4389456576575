import i18n from '@/libs/i18n';

/**
 * A basic extendable Feedback object
 *
 * TODO: Split this further to action-based (create, delete)
 *       and type-based (success, error, info, etc.)
 */

class Feedback {
  constructor(isPositiveFeedback = true) {
    this.i18nNamespace = 'feedback';
    this.action = null;
    this.isPositiveFeedback = isPositiveFeedback;
  }

  getI18nKeys() {
    return {
      title: `${this.i18nNamespace}.title`,
      description: `${this.i18nNamespace}.description`,
      action: `${this.i18nNamespace}.action`,
    };
  }

  static translate(key, i18nParams = null) {
    return (i18n.te(key, i18nParams) ? i18n.t(key, i18nParams) : null);
  }

  getI18nTranslations(i18nParams = null) {
    const { title, description, action } = this.getI18nKeys();
    const translate = (key) => Feedback.translate(key, i18nParams);

    return {
      title: translate(title),
      description: translate(description),
      action: translate(action),
    };
  }

  extendI18nNamespace(param) {
    this.i18nNamespace = `${this.i18nNamespace}.${param}`;
  }
}

export default Feedback;
