<template>
  <div class="password-reset-successful">
    <div class="icon">
      <feather-icon
        icon="CheckCircleIcon"
        size="72"
      />
    </div>
    <h3 class="headline">
      {{ $t('passwordReset.success.header') }}
    </h3>
    <p class="description">
      {{ $t('passwordReset.success.description') }}
    </p>

    <b-button
      variant="primary"
      class="redirect-button"
      @click="$emit('redirect')"
    >
      <feather-icon
        icon="LockIcon"
        size="14"
        class="button-icon"
      />
      {{ $t('passwordReset.success.button.redirectLogin') }}
    </b-button>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';

export default {
  components: {
    BButton,
  },
};
</script>

<style lang="sass" scoped>
.password-reset-successful
  width: 300px
  text-align: center
  margin: 0 auto
  position: absolute
  left: 0
  right: 0

  .icon
    margin: 0 auto
    color: #6ce25f

  .headline
    margin: 50px 0 20px 0

  .redirect-button
    margin-top: 30px

    .button-icon
      margin-right: 10px
</style>
