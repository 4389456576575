var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', _vm._g(_vm._b({
    staticClass: "app-timeline"
  }, 'ul', _vm.$attrs, false), _vm.$listeners), [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }