import { ref } from 'vue';
import useCRMApi from '@/hooks/crm/useCRMApi';

const useStatuses = () => {
  const companyStatuses = ref([]);

  const fetch = () => {
    useCRMApi()
      .fetchStatuses()
      .then(({ data }) => {
        companyStatuses.value = data.data;
      })
      .catch(() => {
      });
  };

  return {
    companyStatuses,
    fetch,
  };
};

export default useStatuses;
