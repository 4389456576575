import showToast from '@/libs/toasts';
import ApiClient from '@/services/api';
import { $themeBreakpoints } from '@themeConfig';
import { ModuleFetchError } from '@/libs/toasts/feedback/module/core';

const urls = {
  moduleStatus: 'api/core/modules/get-active-modules',
};

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    fetchPromise: null,
    disableModuleCheck: (process.env?.VUE_APP_DISABLE_MODULE_CHECK === 'true'),
    simulateThrottling: false,
    modules: [],
    integrations: [],

    disableNavPermissionCheck: false,

    tablePerPage: 10,
  },
  getters: {
    currentBreakPoint: (state) => {
      const { windowWidth } = state;
      if (windowWidth >= $themeBreakpoints.xl) return 'xl';
      if (windowWidth >= $themeBreakpoints.lg) return 'lg';
      if (windowWidth >= $themeBreakpoints.md) return 'md';
      if (windowWidth >= $themeBreakpoints.sm) return 'sm';
      return 'xs';
    },
    isModuleEnabled: (state) => (module) => {
      if (!module || state.disableModuleCheck) return true;
      return state.modules?.[module];
    },
    moduleIntegrations: (state) => state.integrations,

    enabledModuleIntegrations: (_state, getters) => getters.moduleIntegrations
      .filter((integration) => integration.active),

    isModuleIntegrationActive: (_state, getters) => (name) => getters
      .enabledModuleIntegrations
      .some((integration) => integration.name === name),
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val;
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
    },
    SET_PROMISE(state, payload) {
      state.fetchPromise = payload;
    },
    SET_MODULES(state, payload) {
      state.modules = payload;
    },
    SET_DISABLE_NAV_PERMISSION_CHECK(state, payload) {
      state.disableNavPermissionCheck = payload;
    },
    SET_INTEGRATIONS(state, payload) {
      const integrations = [];

      Object.keys(payload).forEach((module) => {
        const moduleRelations = payload[module]?.relations;
        if (!moduleRelations || moduleRelations.length === 0) return;

        moduleRelations.forEach((relation) => {
          integrations.push({
            name: relation.name,
            active: !!relation.is_active,
          });
        });
      });

      state.integrations = integrations;
    },
    TOGGLE_INTEGRATION(state, { name, active }) {
      state.integrations = state.integrations
        .map((integration) => {
          if (integration.name !== name) return integration;
          return { ...integration, active };
        });
    },
    TOGGLE_MODULE_CHECK(state) {
      state.disableModuleCheck = !state.disableModuleCheck;
    },
    TOGGLE_THROTTLING_SIMULATION(state) {
      state.simulateThrottling = !state.simulateThrottling;
    },
    SET_TABLE_PER_PAGE(state, perPage) {
      state.tablePerPage = perPage;
    },
  },
  actions: {
    fetchModulesStatus({ state, commit }) {
      if (state.fetchPromise) return state.fetchPromise;

      const promise = ApiClient.get(urls.moduleStatus)
        .then((payload) => {
          commit('SET_MODULES', payload.data.data);
          commit('SET_INTEGRATIONS', payload.data.data);
        })
        .catch(() => showToast(ModuleFetchError));

      commit('SET_PROMISE', promise);
      return promise;
    },
  },
};
