var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vs-wysiwyg"
  }, [_vm.editor ? _c('div', {
    staticClass: "editor-button-pane"
  }, [_c('div', {
    staticClass: "button-group"
  }, [_c('pane-button', {
    class: {
      'is-active': _vm.editor.isActive('bold')
    },
    attrs: {
      "tooltip": _vm.$t('component.editor.button.bold')
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleBold().run();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "BoldIcon"
    }
  })], 1), _c('pane-button', {
    class: {
      'is-active': _vm.editor.isActive('italic')
    },
    attrs: {
      "tooltip": _vm.$t('component.editor.button.italic')
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleItalic().run();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ItalicIcon"
    }
  })], 1), _c('pane-button', {
    class: {
      'is-active': _vm.editor.isActive('strike')
    },
    attrs: {
      "tooltip": _vm.$t('component.editor.button.strike')
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleStrike().run();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MinusCircleIcon"
    }
  })], 1), _c('pane-button', {
    class: {
      'is-active': _vm.editor.isActive('code')
    },
    attrs: {
      "tooltip": _vm.$t('component.editor.button.code')
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleCode().run();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CodeIcon"
    }
  })], 1)], 1), _c('div', {
    staticClass: "button-group"
  }, [_c('pane-button', {
    class: {
      'is-active': _vm.editor.isActive('paragraph')
    },
    attrs: {
      "tooltip": _vm.$t('component.editor.button.paragraph')
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().setParagraph().run();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "TypeIcon"
    }
  })], 1), _c('pane-button', {
    class: {
      'is-active': _vm.editor.isActive('heading', {
        level: 1
      })
    },
    attrs: {
      "tooltip": _vm.$t('component.editor.button.heading1')
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleHeading({
          level: 1
        }).run();
      }
    }
  }, [_vm._v(" h1 ")]), _c('pane-button', {
    class: {
      'is-active': _vm.editor.isActive('heading', {
        level: 2
      })
    },
    attrs: {
      "tooltip": _vm.$t('component.editor.button.heading2')
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleHeading({
          level: 2
        }).run();
      }
    }
  }, [_vm._v(" h2 ")]), _c('pane-button', {
    class: {
      'is-active': _vm.editor.isActive('bulletList')
    },
    attrs: {
      "tooltip": _vm.$t('component.editor.button.bulletList')
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleBulletList().run();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ListIcon"
    }
  })], 1), _c('pane-button', {
    class: {
      'is-active': _vm.editor.isActive('orderedList')
    },
    attrs: {
      "tooltip": _vm.$t('component.editor.button.orderedList')
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleOrderedList().run();
      }
    }
  }, [_vm._v(" ol ")])], 1), _c('div', {
    staticClass: "button-group"
  }, [_c('pane-button', {
    class: {
      'is-active': _vm.editor.isActive('blockquote')
    },
    attrs: {
      "tooltip": _vm.$t('component.editor.button.blockquote')
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().toggleBlockquote().run();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "Edit3Icon"
    }
  })], 1), _c('pane-button', {
    attrs: {
      "tooltip": _vm.$t('component.editor.button.horizontalRule')
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().setHorizontalRule().run();
      }
    }
  }, [_vm._v(" hr ")]), _c('pane-button', {
    attrs: {
      "tooltip": _vm.$t('component.editor.button.hardBreak')
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().setHardBreak().run();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CornerDownLeftIcon"
    }
  })], 1)], 1), _c('div', {
    staticClass: "button-group right-aligned"
  }, [_c('pane-button', {
    attrs: {
      "tooltip": _vm.$t('component.editor.button.undo')
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().undo().run();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CornerUpLeftIcon"
    }
  })], 1), _c('pane-button', {
    attrs: {
      "tooltip": _vm.$t('component.editor.button.redo')
    },
    on: {
      "click": function ($event) {
        _vm.editor.chain().focus().redo().run();
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CornerUpRightIcon"
    }
  })], 1)], 1)]) : _vm._e(), _c('editor-content', {
    attrs: {
      "editor": _vm.editor
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }