var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    attrs: {
      "title": _vm.$t('home.title.dashboard')
    }
  }, [_c('b-card-text', [_vm._v(_vm._s(_vm.$t('home.greeting.heading', {
    user: _vm.user.forename
  })))]), _c('b-card-text', [_vm._v(" " + _vm._s(_vm.$t('home.greeting.body', {
    appName: _vm.$store.getters['themes/data'].shortAppName
  })) + " ")])], 1), _vm.developmentMode ? _c('div', {
    staticClass: "development-widgets"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" Widżety trybu deweloperskiego ")]), _vm.developmentMode ? _c('b-link', {
    staticClass: "doc-link",
    attrs: {
      "href": "https://pixinvent.com/demo/vuexy-vuejs-admin-dashboard-template/documentation/",
      "target": "_blank"
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "LinkIcon"
    }
  }), _vm._v(" Template Documentation ")], 1) : _vm._e()], 1), _c('hr'), _vm.developmentMode ? _c('b-card', {
    staticClass: "development-widgets",
    attrs: {
      "title": "Lista integracji"
    }
  }, [_c('remote-resource-manager-simulation'), _vm._l(_vm.moduleIntegrations, function (integration) {
    return _c('div', {
      key: integration.name
    }, [_c('state-badge', {
      staticClass: "mt-1",
      attrs: {
        "state": integration.active,
        "text": integration.name
      }
    })], 1);
  })], 2) : _vm._e(), _c('websocket-widget')], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }