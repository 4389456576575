<script>
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import VsStatusBadge from '../VsStatusBadge.vue';

export default {
  name: 'VsStatusBadgeInvestment',
  template: `
    <VsStatusBadge
      :fetchFunction="fetchFunction"
      :statusListRef="statusListRef"
      v-bind="$props"
    />`,
  props: ['id', 'status'],
  setup: () => ({
    fetchFunction: () => {},
    statusListRef: useInvestmentsStore().statuses,
  }),
  components: { VsStatusBadge },
};
</script>
