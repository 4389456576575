<template>
  <div class="add-modal">
    <b-modal
      id="modal-cadre-employment-form-add"
      :title="$t('settings.cadre.employment.add.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('settings.cadre.employment.button.cancel')"
      :ok-title="$t('settings.cadre.employment.button.save')"
      size="lg"
      @ok="createEmploymentForm"
      >
      <b-card-text>
        <employment-form v-model="employment"/>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCardText } from 'bootstrap-vue';
import EmploymentForm from '@/components/views/settings/content-tabs/cadre/forms/employment-forms/Form.vue';
import {
  AddEmploymentFormSuccess,
  ServerError,
  BadInputError,
} from '@feedback/module/cadre';

export default {
  components: {
    BModal,
    BCardText,

    EmploymentForm,
  },
  data() {
    return {
      employment: {},
    };
  },
  methods: {
    createEmploymentForm(event) {
      event.preventDefault();
      this.loading = true;

      this.$store.dispatch('cadre/employmentForms/create', {
        ...this.employment,
      })
        .then(() => {
          this.$showFeedback(AddEmploymentFormSuccess);
          this.$bvModal.hide(event.componentId);
          this.loading = false;
        })
        .catch((err) => {
          let Feedback = ServerError;
          if (err?.response?.data?.errors) {
            Feedback = BadInputError;
          }

          this.$showFeedback(Feedback);
          this.loading = false;
          this.errors = err?.response?.data?.errors || {};
        });
    },
  },
};
</script>
