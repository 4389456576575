import i18n from '@/libs/i18n';

/**
 * Temporary route, services should
 * be defined inside each type
 */
export default [
  {
    path: '/base/examination/:resourceId?',
    name: 'base.examination',
    component: () => import('@/views/base/cars/BaseCarsExamination.vue'),
    meta: {
      pageTitle: i18n.t('base.service.list.pageTitle'),
      moduleRoute: 'Base',
      activeTab: 'examination',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.service.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.service.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/examination/details/:id',
    name: 'base.examination.details',
    component: () => import('@/views/base/cars/BaseCarsExamination.vue'),
    meta: {
      pageTitle: i18n.t('base.service.details.pageTitle'),
      navActiveLink: 'base.examination',
      moduleRoute: 'Base',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource.show',
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.service.list.pageTitle'),
          to: { name: 'base.examination' },
          active: false,
        },
        {
          text: i18n.t('base.service.details.pageTitle'),
          active: true,
        },
      ],
    },
  },
];
