<template>
  <div class="crm-provider-branch-person-picker-option">
    <feather-icon
      icon="UserIcon"
      class="crm-provider-branch-person-picker-option__icon"
      size="14"
    />
    {{ provider.forename }}
    {{ provider.surname }}

    <div class="crm-provider-branch-person-picker-option__position">
      ({{ provider.position }})
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'CrmProviderBranchPersonPickerOption',
  props: {
    provider: Object,
  },
  setup(props) {
    const name = computed(() => props.provider.name);

    return { name };
  },
};
</script>

<style lang="scss" scoped>
.crm-provider-branch-person-picker-option {
  display: flex;
  align-items: center;
  gap: 5px;

  &__position {
    opacity: 0.7;
  }

  &__option-icon {
    opacity: 0.4;
  }
}
</style>
