<template>
  <b-button
    :disabled="disabled || loading"
    :variant="variant"
    :style="buttonStyling(color)"
    @click="(e) => $emit('click', e)"
    :size="size"
    :class="`
      vs-button
      vs-button--${variant}
      ${vsVariant ? 'vs-button--custom-style' : ''}
      vs-button--vs-${vsVariant}
      ${block ? 'vs-button--block' : ''}
      `"
  >
    <div class="vs-button-content">
      <b-spinner small
        v-if="loading"
        label="Spinning"
        />
        <slot></slot>
    </div>
  </b-button>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue';

export default {
  name: 'VsButton',
  props: {
    color: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: 'link',
    },
    vsVariant: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: (size) => ['sm', 'lg'].includes(size),
    },
  },
  data() {
    return {
      isVariant: this.variant,
    };
  },
  methods: {
    buttonStyling(color) {
      const variant = this.isVariant;

      if (variant === 'outline') {
        return {
          color: `${color}`,
          borderColor: `${color}`,
          backgroundColor: 'transparent',
        };
      }
      return {
        backgroundColor: `${color}`,
        borderColor: `${color}`,
        color: 'white',
      };
    },
  },
  components: {
    BButton,
    BSpinner,
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/scss/variables/_variables'

.vs-button
  &--block
    width: 100%
  &--custom-style
    background: var(--background, none)
    color: var(--foreground, none) !important

  &--faded
    background: rgba(128, 128, 128, 0.1)
    color: rgba(92, 92, 92, 1) !important

  &-content
    display: flex
    align-items: center
    justify-content: center

    .spinner-border
      margin-right: 9px

  &:hover
    box-shadow: 0px 5px 9px 0px var(--hover-shadow)

  /* TODO: consider moving styles below as external mixins */
  &--vs
    &-light
      --background: rgba(230, 230, 230)
      --foreground: rgb(100, 100, 100) !important
      --hover-shadow: rgba(230, 230, 230, 0.5)

      --dark-background: #{lighten($theme-dark-card-bg, 5%)}
      --dark-foreground: #{lighten($theme-dark-card-bg, 45%)}
      --dark-hover-shadow: #{lighten($theme-dark-card-bg, 3%)}

    &-secondary
      --background: rgba(170, 170, 170)
      --foreground: rgb(255, 255, 255) !important
      --hover-shadow: rgba(170, 170, 170, 0.5)

      --dark-background: #{lighten($theme-dark-card-bg, 20%)}
      --dark-foreground: #{lighten($theme-dark-card-bg, 60%)}
      --dark-hover-shadow: #{lighten($theme-dark-card-bg, 10%)}

    &-primary
      --background: #{$primary}
      --foreground: #{lighten($primary, 50%)}
      --hover-shadow: #{rgba($primary, 0.5)}

      --dark-background: #{$primary}
      --dark-foreground: #{darken($primary, 30%)}
      --dark-hover-shadow: #{rgba($primary, 0.2)}

.dark-layout
  .vs-button--custom-style
    background: var(--dark-background, none)
    color: var(--dark-foreground, auto) !important

    &:hover
      box-shadow: 0px 5px 9px 0px var(--dark-hover-shadow)
</style>
