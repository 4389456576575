import ApiClient from '@/services/api';

const urls = {
  get: 'api/crm/company/index',
};

export default {
  namespaced: true,

  state: {
    providers: [],
  },

  getters: {
    all: (state) => state.providers,
  },

  mutations: {
    SET_PROVIDERS(state, providers) {
      state.providers = providers;
    },

    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
  },

  actions: {
    /**
     * Fetch providers list
     * @action fetch=providers
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if providers exist
     * @returns {Promise}
     */
    fetch({ state, commit }, params, force = true) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(urls.get, {
        ...params,
        company_type: 'providers',
      });

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_PROVIDERS', res.data.data.companies);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },
  },
};
