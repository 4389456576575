var render = function render(){
  var _vm$selectedStatus;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-dropdown', {
    style: _vm.currentStyle,
    attrs: {
      "id": "dropdown-1",
      "text": (_vm$selectedStatus = _vm.selectedStatus) === null || _vm$selectedStatus === void 0 ? void 0 : _vm$selectedStatus.name,
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        var _vm$selectedStatus2;
        return [_vm._v(" " + _vm._s((_vm$selectedStatus2 = _vm.selectedStatus) === null || _vm$selectedStatus2 === void 0 ? void 0 : _vm$selectedStatus2.name) + " "), _c('feather-icon', {
          attrs: {
            "icon": "ChevronDownIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }])
  }, _vm._l(_vm.statuses, function (status) {
    return _c('b-dropdown-item', {
      key: status.id,
      style: _vm.getStyling(status),
      on: {
        "click": function ($event) {
          return _vm.$emit('pick', status.id);
        }
      }
    }, [_vm._v(" " + _vm._s(status.name) + " ")]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }