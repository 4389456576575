var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_vm._v(" Ustawienia aplikacji... ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }