<template>
  <div class="edit-modal">
    <b-modal
      id="modal-settings-crm-services-edit"
      :title="$t('crm.service.modal.edit.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('crm.service.modal.edit.button.cancel')"
      :ok-title="$t('crm.service.modal.edit.button.ok')"
      size="lg"

      @ok.prevent="editService"
      v-model="modalEditVisible"
      no-close-on-backdrop
      >
      <vs-loader full compact
        text="Aktualizowanie usługi..."
        v-if="isLoading"
      />

      <b-card-text>
        <settings-tab-crm-services-form />
      </b-card-text>

      <template #modal-footer="{ ok, cancel }">
        <vs-modal-footer
          disable-on-loading
          :is-loading="isLoading"

          :text-cancel="$t('crm.service.modal.add.button.cancel')"
          :text-ok="$t('crm.service.modal.add.button.ok')"

          @cancel="cancel"
          @ok="ok"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCardText } from 'bootstrap-vue';
import { inject } from 'vue';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import SettingsTabCrmServicesForm from './SettingsTabCrmServicesForm.vue';

export default {
  setup() {
    const { editService, isLoading, modalEditVisible } = inject('servicesInstance');

    return { editService, isLoading, modalEditVisible };
  },
  components: {
    BModal,
    BCardText,

    SettingsTabCrmServicesForm,
    VsModalFooter,
    VsLoader,
  },
};
</script>
