var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header-section"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._t("title", function () {
    return [_c('h4', {
      staticClass: "title"
    }, [_vm._v(" " + _vm._s(_vm.title) + " ")])];
  }), _vm.action && _vm.canAction ? _c('div', {
    staticClass: "action"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('actionClick');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.action) + " ")])], 1) : _vm._e()], 2), _c('div', {
    staticClass: "section-content"
  }, [_vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }