var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "user-label",
    class: {
      'deleted': !!_vm.user.deleted_at,
      'inline': _vm.inline
    }
  }, [_vm.withAvatar ? _c('avatar', {
    staticClass: "avatar",
    attrs: {
      "user": _vm.user,
      "displayUserIcon": !_vm.isEmployee,
      "deleted": !!_vm.user.deleted_at
    }
  }) : _vm._e(), _vm.showLink ? _c('router-link', {
    attrs: {
      "to": {
        name: 'cadre.employee.details',
        params: {
          id: _vm.user.id
        }
      }
    }
  }, [_vm.linkIconVisible ? _c('feather-icon', {
    attrs: {
      "icon": "UserIcon"
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.user.forename || '') + " " + _vm._s(_vm.user.surname || '') + " ")], 1) : _c('span', {
    staticClass: "username"
  }, [_vm._v(" " + _vm._s(_vm.user.forename || '') + " " + _vm._s(_vm.user.surname || '') + " ")]), this.$slots['extra-info'] ? _c('span', {
    staticClass: "extra-info"
  }, [_vm._t("extra-info")], 2) : _vm._e(), !_vm.isEmployee && _vm.showRelativeText && _vm.me.id === _vm.user.id ? _c('span', {
    staticClass: "me"
  }, [_vm._v(" (" + _vm._s(_vm.$t('component.userLabel.you')) + ") ")]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }