<template>
  <div class="employee-form">
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">

          <b-form-group
            :label="$t('settings.cadre.position.field.group')"
            label-cols-md="3"
            :invalid-feedback="errors.group_id"
            :state="errors.group_id === null || !!objectModel.group_id"
            >
            <cadre-group-picker v-model="objectModel.group_id"/>
          </b-form-group>

          <b-form-group
            :label="$t('settings.cadre.position.field.cost')"
            label-cols-md="3"
            :invalid-feedback="errors.cadre_position_daily_cost"
            :state="errors.cadre_position_daily_cost === null"
            >
            <b-input-group
              append="PLN"
            >
              <b-form-input
                :placeholder="$t('settings.cadre.position.field.cost')"
                v-model="objectModel.cadre_position_daily_cost"/>
            </b-input-group>
          </b-form-group>

          <b-form-group
            :label="$t('settings.cadre.skills.field.name')"
            label-cols-md="3"
            :invalid-feedback="errors.name"
            :state="errors.name === null"
            >
            <b-input-group>
              <b-form-input
                :placeholder="$t('settings.cadre.skills.field.name')"
                v-model="objectModel.name"/>
            </b-input-group>
          </b-form-group>

          <vs-form-header
            withButton
            text="Uprawnienia stanowiska"
            :buttonText="permissionsVisible ? 'Zwiń' : 'Rozwiń'"
            preserveMargin
            @action="permissionsVisible = !permissionsVisible"
          />
          <b-collapse v-model="permissionsVisible">
            <div class="permission-explanation">
              Ustawienie poniższych uprawnień pozwoli na automatyczne
              nadawanie przywilejów osobom, które są przypisane
              do tego stanowiska. W celu nadania/odebrania uprawnień
              konkretnemu pracownikowi, skorzystaj z Ustawień konta danego pracownika
            </div>
            <div class="permission-settings">
              <vs-permission-editor
                v-if="!preparingPermissions"
                stickTop
                noInherit
                :fetched="permissions"
                @update:granted="(granted) => $emit('update:granted', granted)"
                @update:denied="(denied) => $emit('update:denied', denied)"
              />
            </div>
          </b-collapse>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BCollapse,

  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
} from 'bootstrap-vue';
import { computed, ref } from 'vue';
import VsFormHeader from '@/components/ui/vs-form/VsFormHeader.vue';
import VsPermissionEditor from '@/components/ui/vs-permission-editor/VsPermissionEditor.vue';
import CadreGroupPicker from '@/components/module/cadre/CadreGroupPicker.vue';

export default {
  emits: ['input', 'update:granted', 'update:denied'],
  props: {
    model: {
      type: Object,
      default: () => ({
        name: null,
      }),
    },
    permissions: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
    },
    feedback: {
      type: Object,
    },
    preparingPermissions: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const objectModel = computed({
      get: () => props.model,
      set: (val) => emit('input', val),
    });

    emit('input', objectModel.value);

    const permissionsVisible = ref(true);

    return {
      objectModel, permissionsVisible,
    };
  },
  watch: {
    feedback() {
      const errors = this.feedback;
      if (!errors) return;

      Object.keys(errors).forEach((key) => {
        if (errors[key].length > 0) {
          [errors[key]] = errors[key];
        }
      });

      this.errors = errors;
    },
    objectModel: {
      handler() {
        this.$emit('input', this.objectModel);
      },
      deep: true,
    },
  },
  data() {
    return {
      errors: {},
    };
  },
  components: {
    BCol,
    BRow,
    BCollapse,

    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,

    VsFormHeader,
    VsPermissionEditor,
    CadreGroupPicker,
  },
};
</script>

<style lang="sass" scoped>
.permission-settings
  margin-top: 15px

.permission-explanation
  padding: 10px
  line-height: 1.6
  opacity: 0.7

.employee-form
  form
    padding: 30px 45px

    @media screen and (max-width: 991px)
      padding: 0px 0px

.employee-form::v-deep
  .vs-wysiwyg
    margin-top: 15px
</style>
