import store from '@/store';
import Section from '../Section';

class Header extends Section {
  constructor(composer) {
    super('Header', composer);
  }

  addOrderNumber(height) {
    const { layout, content } = this.composer;
    const orderText = 'Zamówienie numer';
    const orderNumber = content.order_number;

    const orderNumberWidth = this.composer.doc.getTextDimensions(orderNumber).w;

    this.composer.doc
      .setFontSize(layout.fontSize.header.order_number)
      .text(
        orderText,
        layout.pageSize.width - layout.gutters.x - orderNumberWidth + 2,
        layout.gutters.y + height - this.composer.doc.getTextDimensions(orderText).h / 2,
        { align: 'right' },
      );

    this.composer.doc
      .setFontSize(layout.fontSize.header.order_number)
      .setFont('OpenSans-Medium', 'normal')
      .text(
        orderNumber,
        layout.pageSize.width - layout.gutters.x,
        layout.gutters.y + height - this.composer.doc.getTextDimensions(orderText).h / 2,
        { align: 'right' },
      );

    this.composer.restoreDefaultFont();
  }

  generate() {
    const logo = store.getters['themes/branding'].logoInvoice;

    const { layout, content } = this.composer;
    const { width, height } = this.getScaledImageProperties(logo, 60);

    this.composer.doc.addImage(logo, layout.gutters.x, layout.gutters.y, width, height);

    this.addOrderNumber(height);

    const orderLocation = `${content.city || 'Elbląg'}, ${content.ordered_at}`;

    this.composer.doc
      .setFontSize(layout.fontSize.header.desc)
      .setFont('OpenSans-Light', 'normal')
      .text(
        orderLocation,
        layout.pageSize.width - layout.gutters.x,
        layout.gutters.y + layout.fontSize.header.order_number / 2 + 8,
        { align: 'right' },
      );

    this.composer.layout.currentHeight += layout.gutters.y + height * 3;

    this.composer.doc
      .setDrawColor('#999')
      .setLineWidth(0.1)
      .line(
        layout.gutters.x,
        layout.currentHeight,
        layout.pageSize.width - layout.gutters.x,
        layout.currentHeight,
        'S',
      );

    this.composer.layout.currentHeight += 4;
  }
}

export default Header;
