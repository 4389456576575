var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-create-work-type-modal"
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-investments-create-work-type",
      "cancel-variant": "outline-secondary",
      "size": "lg",
      "title": _vm.$t('investments.modal.workType.create.title'),
      "no-close-on-backdrop": ""
    },
    on: {
      "ok": function ($event) {
        $event.preventDefault();
        return function () {}.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function () {
        return [_c('div', {
          staticClass: "investment-modal-footer"
        }, [_c('div', {
          staticClass: "buttons"
        }, [_c('vs-button', {
          attrs: {
            "variant": "outline"
          },
          on: {
            "click": function ($event) {
              _vm.addWorkTypeModal.visible = false;
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('investments.modal.workType.create.cancel')) + " ")]), _c('vs-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.addWorkType
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('investments.modal.workType.create.confirm')) + " ")])], 1)])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.addWorkTypeModal.visible,
      callback: function ($$v) {
        _vm.$set(_vm.addWorkTypeModal, "visible", $$v);
      },
      expression: "addWorkTypeModal.visible"
    }
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": _vm.$t('investments.modal.workType.create.loading')
    }
  }) : _vm._e(), _c('investment-work-type-form', {
    attrs: {
      "feedback": _vm.feedback
    },
    model: {
      value: _vm.fields,
      callback: function ($$v) {
        _vm.fields = $$v;
      },
      expression: "fields"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }