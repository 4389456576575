export default {
  examination: {
    medical_exam_type: 'Typ badania',
    employee_id: 'Pracownik',
    exam_date: 'Data badania',
    expiration_date: 'Data ważności',
    file: 'Załącznik',
  },
  training: {
    employee_id: 'Pracownik',
    name: 'Nazwa',
    training_date: 'Data szkolenia',
    expiration_date: 'Data ważności',
    file: 'Załącznik',
  },
};
