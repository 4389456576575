var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-tabs', {
    attrs: {
      "nav-class": "nav-left"
    }
  }, [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "ClipboardIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Materiały")])];
      },
      proxy: true
    }])
  }, [_c('settings-tab-investment-materials')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "ClipboardIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Zbrojenia")])];
      },
      proxy: true
    }])
  }, [_c('settings-tab-investment-reinforcement')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "ClipboardIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Typy robót")])];
      },
      proxy: true
    }])
  }, [_c('settings-tab-investment-work-type')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "ClipboardIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Grupy")])];
      },
      proxy: true
    }])
  }, [_c('settings-tab-investment-work-group')], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "ClipboardIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Statusy")])];
      },
      proxy: true
    }])
  }, [_c('settings-tab-investment-status')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }