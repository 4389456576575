import i18n from '@/libs/i18n';

export default [
  {
    path: '/base/cars',
    name: 'base.cars',
    component: () => import('@/views/base/cars/BaseCars.vue'),
    meta: {
      pageTitle: i18n.t('base.cars.list.pageTitle'),
      moduleRoute: 'Base',
      activeTab: 'cars',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.cars.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.cars.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/cars/details/:id',
    name: 'base.cars.details',
    component: () => import('@/views/base/cars/CarsDetails.vue'),
    meta: {
      pageTitle: i18n.t('base.cars.details.pageTitle'),
      navActiveLink: 'base.cars',
      moduleRoute: 'Base',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource.show',
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.cars.list.pageTitle'),
          to: { name: 'base.cars' },
          active: false,
        },
        {
          text: i18n.t('base.cars.details.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/cars/details/:id/settings',
    name: 'base.cars.details.settings',
    component: () => import('@/components/views/base/machines/settings/MachineSettings.vue'),
    meta: {
      pageTitle: i18n.t('base.cars.settings.pageTitle'),
      navActiveLink: 'base.cars',
      moduleRoute: 'Base',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource.update',
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.cars.list.pageTitle'),
          to: { name: 'base.cars' },
          active: false,
        },
        {
          text: i18n.t('base.cars.details.pageTitle'),
          to: { name: 'base.cars.details' },
          active: false,
        },
        {
          text: i18n.t('base.cars.settings.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/cars/service/:machineId?/:reportType?',
    name: 'base.cars.service',
    component: () => import('@/views/base/cars/BaseCars.vue'),
    meta: {
      pageTitle: i18n.t('base.cars.service.fullname'),
      navActiveLink: 'base.cars',
      moduleRoute: 'Base',
      activeTab: 'service',
      requiresAuth: true,
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.cars.pageTitle'),
          to: { name: 'base.cars' },
          active: false,
        },
        {
          text: i18n.t('base.cars.service.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/cars/mileage/:machineId?/:reportType?',
    name: 'base.cars.mileage',
    component: () => import('@/views/base/cars/BaseCars.vue'),
    meta: {
      pageTitle: i18n.t('base.cars.mileage.fullname'),
      navActiveLink: 'base.cars',
      moduleRoute: 'Base',
      activeTab: 'mileage',
      requiresAuth: true,
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.cars.pageTitle'),
          to: { name: 'base.cars' },
          active: false,
        },
        {
          text: i18n.t('base.cars.mileage.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/cars/insurance/:carId?/:reportType?',
    name: 'base.cars.insurance',
    component: () => import('@/views/base/cars/BaseCars.vue'),
    meta: {
      pageTitle: i18n.t('base.cars.insurance.fullname'),
      navActiveLink: 'base.cars',
      moduleRoute: 'Base',
      activeTab: 'insurance',
      requiresAuth: true,
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.cars.pageTitle'),
          to: { name: 'base.cars' },
          active: false,
        },
        {
          text: i18n.t('base.cars.insurance.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/cars/examinations/:resourceId?',
    name: 'base.cars.examinations',
    component: () => import('@/views/base/cars/BaseCars.vue'),
    meta: {
      pageTitle: i18n.t('base.cars.examination.list.pageTitle'),
      moduleRoute: 'Base',
      navActiveLink: 'base.cars',
      activeTab: 'examinations',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.cars.pageTitle'),
          to: { name: 'base.cars' },
          active: false,
        },
        {
          text: i18n.t('base.cars.examination.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/cars/schedule',
    name: 'base.cars.schedule',
    component: () => import('@/views/base/cars/BaseCars.vue'),
    meta: {
      pageTitle: i18n.t('base.cars.examination.list.pageTitle'),
      moduleRoute: 'Base',
      navActiveLink: 'base.cars',
      activeTab: 'schedule',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.cars.pageTitle'),
          to: { name: 'base.cars' },
          active: false,
        },
        {
          text: i18n.t('base.machines.schedule.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/cars/examinations/details/:id',
    name: 'base.cars.examinations.details',
    component: () => import('@/views/base/cars/BaseCars.vue'),
    meta: {
      pageTitle: i18n.t('base.cars.examination.details.pageTitle'),
      navActiveLink: 'base.cars',
      activeTab: 'examinations',
      moduleRoute: 'Base',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource.show',
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.cars.pageTitle'),
          to: { name: 'base.cars' },
          active: false,
        },
        {
          text: i18n.t('base.cars.examination.details.pageTitle'),
          to: { name: 'base.cars.examinations' },
          active: false,
        },
      ],
    },
  },
  {
    path: '/base/cars/service/:resourceId?',
    name: 'base.cars.service',
    component: () => import('@/views/base/cars/BaseCars.vue'),
    meta: {
      pageTitle: i18n.t('base.service.list.pageTitle'),
      moduleRoute: 'Base',
      navActiveLink: 'base.cars',
      activeTab: 'service',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource',
      showFiltration: true,
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.cars.pageTitle'),
          to: { name: 'base.cars' },
          active: true,
        },
        {
          text: i18n.t('base.service.list.pageTitle'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/base/cars/service/details/:id',
    name: 'base.cars.service.details',
    component: () => import('@/views/base/cars/BaseCars.vue'),
    meta: {
      pageTitle: i18n.t('base.service.details.pageTitle'),
      navActiveLink: 'base.cars',
      moduleRoute: 'Base',
      activeTab: 'service',
      requiresAuth: true,
      requiresAnyPermission: 'base.resource.show',
      breadcrumb: [
        {
          text: i18n.t('base.pageTitle'),
          active: true,
        },
        {
          text: i18n.t('base.cars.pageTitle'),
          to: { name: 'base.cars' },
          active: true,
        },
        {
          text: i18n.t('base.service.details.pageTitle'),
          active: true,
        },
      ],
    },
  },
];
