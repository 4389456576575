var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vs-datepicker"
  }, [_c('b-form-datepicker', _vm._b({
    staticClass: "date",
    attrs: {
      "locale": _vm.$i18n.locale,
      "placeholder": _vm.placeholder,
      "reset-button": _vm.resetButton,
      "label-reset-button": _vm.labelResetButton,
      "start-weekday": "1"
    },
    model: {
      value: _vm.datepickerValue,
      callback: function ($$v) {
        _vm.datepickerValue = $$v;
      },
      expression: "datepickerValue"
    }
  }, 'b-form-datepicker', _vm.$attrs, false)), _vm.withTime ? _c('b-form-timepicker', {
    staticClass: "time",
    attrs: {
      "hour12": false,
      "placeholder": ""
    },
    model: {
      value: _vm.time,
      callback: function ($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }