var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "unit-picker vs-picker"
  }, [_c('v-select', {
    attrs: {
      "multiple": _vm.multiple,
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.units,
      "clearable": _vm.clearable,
      "label": "code"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (_ref) {
        var code = _ref.code;
        return [_vm._v(" " + _vm._s(code) + " ")];
      }
    }, {
      key: "selected-option",
      fn: function (_ref2) {
        var code = _ref2.code;
        return [_vm._v(" " + _vm._s(code) + " ")];
      }
    }, {
      key: "no-options",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.$t('component.picker.unit.noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }