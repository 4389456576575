<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vs-logo />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('login.header', { appName: $store.getters['themes/data'].shortAppName }) }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('login.description') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                :label="$t('global.field.email')"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">{{ $t('global.field.password') }}</label>
                  <!--
                  <b-link :to="{name:'auth-forgot-password-v2'}">
                    <small>Forgot Password?</small>
                  </b-link>
                  -->
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <!--
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox>
              </b-form-group>
              -->

              <!-- submit buttons -->
              <vs-button
                type="submit"
                vs-variant="primary"
                block
                @click="validationForm"
              >
                {{ $t('login.button.signIn') }}
              </vs-button>

              <router-link
                class="d-flex mt-2"
                :to="{ name: 'auth.password.forgot', params: { email: userEmail } }">
                Nie pamiętam hasła
              </router-link>
            </b-form>
          </validation-observer>

          <!--
          <b-card-text class="text-center mt-2">
            <span>New on our platform? </span>
            <b-link :to="{name:'page-auth-register-v2'}">
              <span>&nbsp;Create an account</span>
            </b-link>
          </b-card-text>
          -->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import VsLogo from '@/components/layout/Logo.vue';
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend,
  BInputGroup, BCardText, BCardTitle, BImg, BForm,
} from 'bootstrap-vue';
import { required, email } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import store from '@/store/index';
import { mapActions, mapGetters } from 'vuex';
import i18n from '@/libs/i18n';
import VsButton from '@/components/ui/vs-button/VsButton.vue';

extend('email', {
  message: i18n.t('global.validation.badEmailFormat'),
});

extend('confirmed', {
  message: (field, { target }) => i18n.t('global.validation.wrongRepeat', {
    field: i18n.t(`global.field.${field}`),
    target: i18n.t(`global.field.${target}`),
  }),
});

extend('min', {
  message: (field) => i18n.t('global.validation.minLength', {
    field: i18n.t(`global.field.${field}`),
  }),
});

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    VsLogo,
    ValidationProvider,
    ValidationObserver,
    VsButton,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: this.$route.params?.email ?? '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    };
  },
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line no-undef, vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    ...mapActions({
      signIn: 'auth/signIn',
    }),
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.signIn({
            email: this.userEmail,
            password: this.password,
          }).then(() => {
            this.$router.replace({ name: this.$route?.params?.requested ?? 'home' });
          }).catch(() => {
            this.$refs.loginValidation.setErrors({ email: 'Logowanie nie powiodło się. Upewnij się, że używasz prawidłowych danych.' });
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
