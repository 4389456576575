<template>
  <vs-modal
    class="profile-settings-devices-edit-modal"
    :modalInstance="modalConnectAuthorizedDevice"
    @show="setData"
    @hide="clearData"
  >
    <div
      class="confirmation"
      v-if="!token"
    >
      <h3>{{ device.name }}</h3>
      <p>
        Ponowne wyświetlenie kodu uwierzytelniającego
        spowoduje <b>wylogowanie przypisanego urządzenia</b>.
        Czy na pewno chcesz kontynuować?
      </p>

      <vs-button
        vs-variant="primary"
        @click="reauthToken"
        :loading="modalConnectAuthorizedDevice.isLoading"
      >
        Tak, pokaż kod
      </vs-button>

      <vs-button
        vs-variant="link"
        @click="modalConnectAuthorizedDevice.visible = false"
      >
        Zamknij okno
      </vs-button>
    </div>

    <div
      class="qr-code-wrapper"
      :class="token && 'visible'"
    >
      <vue-qrcode
        class="qr-code"
        :value="qrLink"
        :options="qrOptions"
        ref="qrCode"
      />

      <div class="qr-info">
        Nikomu nie udostępniaj powyższego kodu.
      </div>
    </div>

  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useCoreModals from '@/hooks/useCoreModals';
import { computed, ref } from 'vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import useCoreApi from '@/hooks/useCoreApi';
import showToast from '@/libs/toasts';
import { AuthorizedDevicesReauthError } from '@/libs/toasts/feedback/module/core';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import useQrGenerator from '@/hooks/useQrGenerator';

export default {
  name: 'ProfileSettingsDevicesConnectModal',
  emits: ['success'],
  setup(_, { emit, refs }) {
    const { modalConnectAuthorizedDevice } = useCoreModals();
    const device = ref({});

    const token = ref(null);
    const qrLink = computed(() => `budoerp://auth/${token.value}`);
    const qrOptions = {
      width: 800,
      errorCorrectionLevel: 'Q',
    };

    const retryUntilSucceed = () => new Promise((resolve) => {
      if (refs?.qrCode?.$el?.getContext) {
        useQrGenerator()
          .generate(refs.qrCode.$el);

        resolve();
      } else {
        setTimeout(() => {
          retryUntilSucceed();
        }, 100);
      }
    });

    const setData = (data) => {
      device.value = { ...data };

      if (device.value.plainTextToken) {
        token.value = device.value.plainTextToken;
        retryUntilSucceed();
      }
    };
    const clearData = () => {
      device.value = {};
      token.value = null;
    };

    const reauthToken = async () => {
      modalConnectAuthorizedDevice.value.isLoading = true;

      try {
        const { data } = await useCoreApi()
          .reauthAuthorizedDevice(device.value.id);

        token.value = data.data.plainTextToken;

        retryUntilSucceed();

        emit('success', {
          ...device.value,
          updated_at: data.data.updated_at,
        });
      } catch (error) {
        showToast(AuthorizedDevicesReauthError);
      } finally {
        modalConnectAuthorizedDevice.value.isLoading = false;
      }
    };

    return {
      modalConnectAuthorizedDevice,
      setData,
      clearData,
      device,
      reauthToken,
      token,
      qrLink,
      qrOptions,
    };
  },
  components: {
    VsModal,
    VsButton,
    VueQrcode,
  },
};
</script>

<style lang="sass" scoped>
.qr-code-wrapper
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  display: none
  animation: qrFadeIn .4s ease forwards

  @keyframes qrFadeIn
    from
      opacity: 0
      transform: translateY(15px)
    to
      opacity: 1
      transform: translateY(0)

  &.visible
    display: block

  .qr-code
    pointer-events: none
    width: 100% !important
    height: auto !important
    position: relative
    aspect-ratio: 1/1
    border-radius: 10px
    margin: 25px 0

  .qr-info
    margin: 25px 0
    text-align: center
    opacity: 0.5

.confirmation
  display: flex
  flex-direction: column
  text-align: center
  padding: 20px

  p
    padding: 20px 0
</style>
