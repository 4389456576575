<template>
  <div class="toastification">
    <div class="d-flex align-items-center">
      <notification
        :notification="notification"
        appearance="toast"
        @action="makeAction"
        />
      <b-button
        variant="link"
        class="close-notification"
        @click="$emit('close-toast')"
        >
        <feather-icon icon="XIcon" size="24" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import store from '@/store';
import { formatNotification } from '@/libs/notifications';
import Notification from '@/components/ui/notification/Notification.vue';

export default {
  components: {
    BButton,
    Notification,
  },
  props: {
    notification: {
      type: Object,
      default: null,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    markAsRead() {
      store.dispatch('auth/notifications/markAsRead', this.notification);
    },
    makeAction() {
      const formatted = formatNotification(this.notification);

      if (formatted.action) {
        formatted.action();
      }

      this.markAsRead();
      this.$emit('close-toast');
    },
  },
};
</script>

<style lang="sass" scoped>
.close-notification
  aspect-ratio: 1/1
  padding: 8px
  margin: 0 10px 0 20px
  opacity: 0.3

</style>
