var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settings-tab-investments-status"
  }, [_c('div', {
    staticClass: "buttons"
  }, [_c('div', {
    staticClass: "group"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "primary",
      "disabled": ""
    },
    on: {
      "click": function () {}
    }
  }, [_vm._v(" + Status ")])], 1)]), _vm._l(_vm.statuses, function (status) {
    return _c('div', {
      key: status.id,
      staticClass: "status-wrapper"
    }, [_c('vs-status-badge-investment', {
      attrs: {
        "id": status.id
      }
    })], 1);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }