var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-material-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.errors,
      "i18nNamespace": "investments.form.material.field"
    },
    model: {
      value: _vm.material,
      callback: function ($$v) {
        _vm.material = $$v;
      },
      expression: "material"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "name"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.material.field.name')
    },
    model: {
      value: _vm.material.name,
      callback: function ($$v) {
        _vm.$set(_vm.material, "name", $$v);
      },
      expression: "material.name"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "core_unit_id"
    }
  }, [_c('core-unit-picker', {
    model: {
      value: _vm.material.core_unit_id,
      callback: function ($$v) {
        _vm.$set(_vm.material, "core_unit_id", $$v);
      },
      expression: "material.core_unit_id"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }