<template>
  <div class="order-label">
    <router-link :to="{ name: 'orders-details', params: { id } }" class="prefix">
      {{ orderPrefix }}
    </router-link>
    {{ title }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['id', 'title'],
  computed: {
    ...mapGetters({
      orderDefaultPrefix: 'orders/prefix',
    }),
    orderPrefix() {
      return this.orderDefaultPrefix.replace(':id', this.id);
    },
  },
};
</script>

<style lang="sass" scoped>
.prefix
  font-weight: 600
</style>
