var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vs-list"
  }, [_vm.showHeaders ? _c('div', {
    staticClass: "headers"
  }, _vm._l([].concat(_vm.headers, ['']), function (header) {
    return _c('div', {
      key: header,
      staticClass: "header"
    }, [_vm._v(" " + _vm._s(header) + " ")]);
  }), 0) : _vm._e(), _vm._l(_vm.items, function (item) {
    return _c('vs-list-item', {
      key: item.id,
      attrs: {
        "title": item[_vm.title],
        "item": item,
        "disableActions": _vm.disableActions
      },
      on: {
        "edit": function ($event) {
          return _vm.$emit('edit', item);
        },
        "delete": function ($event) {
          return _vm.$emit('delete', item);
        }
      },
      scopedSlots: _vm._u([{
        key: "cells",
        fn: function (_ref) {
          var row = _ref.row;
          return [_vm._t("cells", null, {
            "row": row
          })];
        }
      }], null, true)
    });
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }