import { jsPDF } from 'jspdf';

/* jsPDF Plugins */
import './fonts/OpenSans-Light-normal';
import './fonts/OpenSans-Regular-normal';
import './fonts/OpenSans-Medium-normal';
import './fonts/OpenSans-Bold-normal';
import 'jspdf-autotable';

/* Invoice Sections */
import Header from './sections/Header';
import Details from './sections/Details';
import Order from './sections/Order';
import PaymentTerms from './sections/PaymentTerms';
import Notes from './sections/Notes';
import Body from './sections/Body';
import Footer from './sections/Footer';

/**
 * Composer is responsible for creating an invoice
 * structure with the corresponding data.
 *
 * FIXME: Refactor the whole thing, especially
 *        when it comes to fixed values
 *        (heights in particular)
 */
class Composer {
  constructor(settings, content = null) {
    // eslint-disable-next-line new-cap
    this.doc = new jsPDF('p', 'mm', 'a4');
    this.opts = settings;
    this.sections = [
      Header,
      Details,
      Order,
      PaymentTerms,
      Notes,
      Body,
      Footer,
    ];

    this.layout = {
      pageSize: this.doc.internal.pageSize,
      gutters: { x: 12, y: 12 },
      fontSize: {
        header: { order_number: 14, desc: 11 },
        details: { contact: 9 },
        order: { table: 9 },
      },
      currentHeight: 0,
    };

    /**
     * FIXME: Section below is not necessary IF we won't allow
     *        the parser to continue without sufficient data
     */
    this.content = {
      order_number: '034/GDA/2021',
      city: 'city',
      date_ordered: '05.10.2021 r.',

      details: {
        ordering: {
        },
        provider: {
          subject: 'Nazwa dostawcy',
          street: 'ul. Kwiatowa 9',
          city: '81-001 Gdańsk',
          nip: 'NIP: 23-234234-3434-34',
          regon: null,
          phone: 'tel. 501 234 567',
          email: 'biuro@example.com',
        },
      },

      order: {
        offer_number: 'OF-02/GD/2021',
        date: '23.10.2021 r.',
        total: '91 250 PLN',

        terms: {
          payment_terms: 'Przelew w terminie 30 dni od daty wpływu do siedziby firmy prawidłowo wystawionej faktury',
          realization_at: '12.11.2021 r.',
          delivery_place: 'budowa w m. Wrocław ul. Strzegomska',
          transport: 'Własny',
          contact_person: 'Maciej Witkowski 507-123-456',
        },

        comment: '<p>Dodatkowe wymagania:<ul><li>gwarancja ceny do 30.04.2020 r.</li><li>gwarantowana wtrzymałość po 28 dniach dojrzewania</li><li>czas rozładunku betonowozu 9 minut/1 m3 - powyżej dopłata 40 zł/każde rozpoczęte 15 minut</li><li>minimalny załadunek betonowozu - 8 m3 (poniżej dopłata do minimum logistycznego 40 zł/każdy 1 m3 brakujący do ilości ustalonej)</li></ul> <b>Zamawiający zastrzega sobie prawo do zmniejszenia zakresu zamówienia. Zastrzeżenie to dotyczy w szczególności sytuacji, w której na skutek działania siły wyższej Zamawiający nie będzie w stanie wykonywać robót budowlanych. W przypadku ograniczenia zakresu zamówienia Zamawiający zapłaci jedynie za faktycznie odebrany materiał.</b></p>',

        objects: [
          {
            id: 0,
            name: 'Beton konstrukcyjny C20/25 S3 XC2 Dmax= 16 mm (CEM III/A 42,5 N)',
            unit: 'm3',
            unit_price: '205 PLN',
            amount: '250',
            total: '51 250 PLN',
          },
          {
            id: 1,
            name: 'Piasek mierzony na tony',
            unit: 'kg',
            unit_price: '200 PLN',
            amount: '200',
            total: '40 000 PLN',
          },
        ],
      },
    };

    if (content) {
      this.content = {
        // eslint-disable-next-line max-len
        details: {
          contact: this.content.details.contact,
          ordering: this.content.details.ordering,
          provider: {
            name: content.provider ? content.provider.name : '' || '',
          },
        },
        ...content,
      };
    }

    this.restoreDefaultFont();
  }

  restoreDefaultFont() {
    this.doc.setFont('OpenSans-Light', 'normal');
  }

  composeSections() {
    const composer = this;

    this.sections.map((InvoiceSection) => {
      const sect = new InvoiceSection(composer);
      return sect.generate();
    });
  }

  createInvoice() {
    this.composeSections();
    return this.doc;
  }
}

export default Composer;
