<template>
  <div class="widget-card-controls">
    <b-button-group>

      <vs-button
        vs-variant="light"
        @click.prevent="$emit('edit')"
        class="edit-button"
        v-if="can(editPermission) && !hideEdit"
      >
        <feather-icon class="icon" icon="Edit2Icon" />
        Edytuj
      </vs-button>

      <vs-button
        vs-variant="light"
        @click.prevent="$emit('delete')"
        v-if="can(deletePermission)"
      >
        <feather-icon icon="Trash2Icon" />
      </vs-button>

    </b-button-group>
  </div>
</template>

<script>
import { BButtonGroup } from 'bootstrap-vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import store from '@/store';

export default {
  name: 'WidgetCardControls',
  emits: ['edit', 'delete'],
  props: {
    editPermission: {
      type: String,
    },
    hideEdit: {
      type: Boolean,
      default: false,
    },
    deletePermission: {
      type: String,
    },
  },
  setup() {
    const can = (permission) => {
      if (!permission) return true;
      const split = permission.split('.');
      const parent = `${split[0]}.${split[1]}`;
      const action = split[2];

      return store
        .getters['auth/userHasPermission'](action, parent);
    };

    return { can };
  },
  components: {
    BButtonGroup,
    VsButton,
  },
};
</script>

<style lang="sass" scoped>
.widget-card-controls
  .edit-button
    .icon
      margin-right: 10px
</style>
