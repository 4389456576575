var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.appearance === 'bell' ? _c('b-link', {
    staticClass: "notification bell",
    on: {
      "click": _vm.makeAction
    }
  }, [_c('b-media', {
    scopedSlots: _vm._u([{
      key: "aside",
      fn: function () {
        return [_c(_vm.moduleIcon(_vm.notification).component, _vm._b({
          tag: "component"
        }, 'component', _vm.moduleIcon(_vm.notification).props, false))];
      },
      proxy: true
    }], null, false, 3985976220)
  }, [_c('p', {
    staticClass: "media-heading"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.formatNotification(_vm.notification).title) + " ")])]), _c('small', {
    staticClass: "notification-text"
  }, [_vm._v(" " + _vm._s(_vm.formatNotification(_vm.notification).description) + " ")])])], 1) : _vm.appearance === 'history' ? _c('div', {
    staticClass: "notification history",
    class: _vm.notification.read ? 'read' : 'unread'
  }, [_c('span'), _c('div', {
    staticClass: "module-icon"
  }, [_c(_vm.moduleIcon(_vm.notification).component, _vm._b({
    tag: "component"
  }, 'component', _vm.moduleIcon(_vm.notification).props, false))], 1), _c('div', {
    staticClass: "details"
  }, [_c('div', {
    staticClass: "title",
    on: {
      "click": _vm.makeAction
    }
  }, [!_vm.notification.read ? _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('home.notifications.badge.unread')) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.formatNotification(_vm.notification).title) + " ")], 1), _c('div', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.formatNotification(_vm.notification).description) + " ")])]), _c('div', {
    staticClass: "right-side"
  }, [_c('div', {
    staticClass: "action-buttons"
  }, [!_vm.notification.read ? _c('b-button', {
    staticClass: "action",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": _vm.markAsRead
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CheckIcon",
      "size": _vm.actionSize
    }
  }), _vm._v(" " + _vm._s(_vm.i18n.t('home.notifications.button.markAsRead')) + " ")], 1) : _vm._e()], 1), _c('div', {
    staticClass: "date"
  }, [_vm._v(" " + _vm._s(_vm.formatDate.notification(_vm.notification.created_at)) + " ")])])]) : _vm.appearance === 'toast' ? _c('div', {
    staticClass: "notification history toast",
    class: _vm.notification.read ? 'read' : 'unread',
    on: {
      "click": _vm.makeAction
    }
  }, [_c('span'), _c('div', {
    staticClass: "module-icon"
  }, [_c(_vm.moduleIcon(_vm.notification).component, _vm._b({
    tag: "component"
  }, 'component', _vm.moduleIcon(_vm.notification).props, false))], 1), _c('div', {
    staticClass: "details"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.formatNotification(_vm.notification).title) + " ")]), _c('div', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.formatNotification(_vm.notification).description) + " ")])])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }