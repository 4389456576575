import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';

const { fetchPickerBrigades } = useInvestmentsApi();

export default {
  namespaced: true,
  state: {
    brigades: [],
  },

  getters: {
    byInvestmentId: (state) => (investmentId) => state.brigades
      .filter((brigade) => brigade.investment_id === investmentId),
  },

  mutations: {
    SET_BRIGADES(state, brigades) {
      state.brigades = brigades;
    },

    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
  },

  actions: {
    /**
     * Fetch brigades list
     * @action fetch=brigades
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if vehicles exist
     * @returns {Promise}
     */
    fetch({ state, commit }, force = true) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = fetchPickerBrigades();

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_BRIGADES', res.data.data);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },
  },
};
