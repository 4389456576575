var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-button', {
    class: `
    vs-button
    vs-button--${_vm.variant}
    ${_vm.vsVariant ? 'vs-button--custom-style' : ''}
    vs-button--vs-${_vm.vsVariant}
    ${_vm.block ? 'vs-button--block' : ''}
    `,
    style: _vm.buttonStyling(_vm.color),
    attrs: {
      "disabled": _vm.disabled || _vm.loading,
      "variant": _vm.variant,
      "size": _vm.size
    },
    on: {
      "click": function (e) {
        return _vm.$emit('click', e);
      }
    }
  }, [_c('div', {
    staticClass: "vs-button-content"
  }, [_vm.loading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "label": "Spinning"
    }
  }) : _vm._e(), _vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }