export default {
  namespaced: true,
  state: {
    statuses: [
      { name: 'Nowy', slug: 'new', color: '#ffe224' },
      { name: 'W drodze', slug: 'onTheWay', color: '#ff8d29' },
      { name: 'Dostarczono', slug: 'delivered', color: '#31c458' },
    ],
  },

  getters: {
    all: (state) => state.statuses,
    getStatusBySlug: (state) => (slug) => state.statuses
      .find((status) => status.slug === slug),
  },
};
