<template>
  <div class="settings-tab-base-technology-form">
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('base.technology.field.name')"
            label-cols-md="3"
            :invalid-feedback="errors.name"
            :state="errors.name === null"
            >
            <b-input-group>
              <b-form-input
                :placeholder="$t('base.technology.field.name')"
                v-model="form.name"/>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="$t('base.category.field.type')"
            label-cols-md="3"
            :invalid-feedback="errors.type"
            :state="errors.type !== null"
          >
            <color-picker
              :value="form.color"
              @input="pickColor"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue';
import { inject, ref } from 'vue';
import { Swatches } from 'vue-color';

export default {
  name: 'SettingsTabBaseTechnologyForm',
  setup() {
    const { form } = inject('technologyInstance');

    const pickColor = (val) => { form.value.color = val.hex; };

    const errors = ref({});

    return { form, errors, pickColor };
  },
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    'color-picker': Swatches,
  },
};
</script>

<style lang="sass" scoped>
.settings-tab-base-technology-form::v-deep
  .vc-swatches
    width: auto
    height: auto
    box-shadow: none
</style>
