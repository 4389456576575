import Section from '../Section';

class Order extends Section {
  constructor(composer) {
    super('Invoice Order', composer);
  }

  generate() {
    const { layout, content } = this.composer;
    this.composer.layout.currentHeight += 10;

    this.appendText(
      `W nawiązaniu do oferty nr ${content.offer_number} z dnia ${content.date || ''} zamawiamy:`,
      layout.gutters.x,
      layout.currentHeight,
    );

    const rows = content.objects.map((row) => [
      row.name, row.unit, row.unit_price, row.amount, row.total,
    ]);

    rows.push([
      '', '', '', 'SUMA:', content.total,
    ]);

    const { composer } = this;

    this.composer.doc.autoTable({
      head: [['Nazwa', 'J.m.', 'Cena jedn. netto', 'Ilość', 'Wartość netto']],
      body: rows,
      headStyles: {
        font: 'OpenSans-Medium',
      },
      styles: {
        font: 'OpenSans-Light',
        fontSize: layout.fontSize.order.table,
      },
      theme: 'plain',
      margin: layout.gutters.x - 2,
      startY: layout.currentHeight,
      willDrawCell(HookData) {
        if (HookData.row.index !== rows.length - 1) return;
        composer.doc
          .setFont('OpenSans-Medium', 'normal');
      },
      didDrawCell(HookData) {
        layout.currentHeight = HookData.cursor.y;
        composer.doc
          .setDrawColor('#ddd')
          .setLineWidth(0.1)
          .line(
            layout.gutters.x,
            layout.currentHeight,
            layout.pageSize.width - layout.gutters.x,
            layout.currentHeight,
            'S',
          );
      },
    });

    layout.currentHeight += 10;
  }
}

export default Order;
