import Vue from 'vue';
import Vuex from 'vuex';

import ApiClient from '@/services/api';

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    branches: [],
    fetchPromise: null,
  },

  getters: {
    all: (state) => state.branches,
    // eslint-disable-next-line arrow-body-style
    byId: (state) => (searchedId) => state.branches.find((branch) => {
      return branch.id === searchedId;
    }) || null,
  },

  mutations: {
    SET_BRANCHES(state, payload) {
      state.branches = payload;
    },
    ADD_BRANCH(state, payload) {
      state.branches = [...state.branches, ...payload];
    },
    SET_PROMISE(state, payload) {
      state.fetchPromise = payload;
    },
  },

  actions: {
    fetch({ state, commit }) {
      if (state.branches.length) return state.branches;
      if (state.fetchPromise) return state.fetchPromise;

      const request = ApiClient.get('api/core/branch/get-list');

      request.then((res) => {
        if (res.status !== 200) return null;
        return commit('SET_BRANCHES', res.data.data);
      });

      commit('SET_PROMISE', request);
      return request;
    },
  },
};
