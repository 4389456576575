<template>
  <router-link
    :to="to"
    :class="`abstract-module-label ${short ? 'abstract-module-label--short' : ''}`"
    :style="labelStylesheet"
  >
    <feather-icon
      class="abstract-module-label__icon"
      :icon="icon"
      :size="style.iconSize"
    />
    <div class="abstract-module-label__content">
      <slot>
        {{ name }}
      </slot>
    </div>
  </router-link>
</template>
<script>
import { computed } from 'vue';

export default {
  name: 'AbstractModuleLabel',
  props: {
    to: {
      type: [String, Object],
    },
    name: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      validator: (val) => ['sm', 'md', 'lg'].includes(val),
      default: 'md',
    },
    short: Boolean,
  },
  setup(props) {
    const sizeStylings = {
      sm: {
        fontSize: '12px',
        iconSize: '14',
      },
      md: {
        fontSize: '14px',
        iconSize: '20',
      },
      lg: {
        fontSize: '16px',
        iconSize: '20',
      },
    };

    const style = computed(() => sizeStylings[props.size]);

    const labelStylesheet = computed(() => ({
      '--font-size': style.value.fontSize,
    }));

    return {
      style,
      labelStylesheet,
    };
  },
};
</script>

<style lang="scss" scoped>
.abstract-module-label {
  --font-size: 14px;
  font-size: var(--font-size);
  display: inline-flex;
  align-items: center;
  gap: 5px;

  &__icon {
    flex-shrink: 0;
  }

  &__content {
    white-space: nowrap;
    display: inline-flex;
    gap: 5px;
  }

  &--short {
    .abstract-module-label__content {
      max-width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
