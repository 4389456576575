<template>
  <div class="add-modal">
    <b-modal
      id="modal-cadre-employment-form-edit"
      :title="$t('settings.cadre.employment.edit.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('settings.cadre.employment.button.cancel')"
      :ok-title="$t('settings.cadre.employment.button.save')"
      size="lg"
      @ok="createSkill"
      >
      <b-card-text>
        <employment-form v-model="skill"/>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCardText } from 'bootstrap-vue';
import EmploymentForm from '@/components/views/settings/content-tabs/cadre/forms/employment-forms/Form.vue';

export default {
  components: {
    BModal,
    BCardText,

    EmploymentForm,
  },
  data() {
    return {
      skill: {},
    };
  },
  methods: {
    createSkill(e) {
      e.preventDefault();
    },
  },
};
</script>
