import ApiClient from '@/services/api';
import store from '@/store';
import { computed } from 'vue';

export default function useIntegrations(name = null) {
  const toggleIntegration = () => {
    ApiClient
      .put('api/orders/settings/relation/crm/switch-relation-order-to-crm-branch')
      .then(({ data }) => store.commit('app/TOGGLE_INTEGRATION', {
        name,
        active: data.data.is_active,
      }));
  };

  const enabledIntegration = computed(() => store.getters['app/moduleIntegrations']);

  const isEnabled = computed(() => {
    if (!name) return null;

    const fn = store.getters['app/isModuleIntegrationActive'];
    return fn(name);
  });

  return {
    toggleIntegration,
    enabledIntegration,
    isIntegrationEnabled: isEnabled,
  };
}
