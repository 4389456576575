import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';

const { fetchPickerInvestments } = useInvestmentsApi();

export default defineStore('investmentsInvestment', () => {
  const pickerList = useRemoteResource('investments.investment', fetchPickerInvestments);

  return {
    pickerList,
  };
});
