var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settings-tab-crm-industries-form"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.producer.field.name'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.name,
      "state": _vm.errors.name === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('base.producer.field.name')
    },
    model: {
      value: _vm.producerModel.name,
      callback: function ($$v) {
        _vm.$set(_vm.producerModel, "name", $$v);
      },
      expression: "producerModel.name"
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.producer.field.producer'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.producer,
      "state": !_vm.errors.hasOwnProperty('producer')
    }
  }, [_c('base-producer-picker', {
    model: {
      value: _vm.producerModel.base_producer_id,
      callback: function ($$v) {
        _vm.$set(_vm.producerModel, "base_producer_id", $$v);
      },
      expression: "producerModel.base_producer_id"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.producer.field.type'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.base_resource_type_id,
      "state": !_vm.errors.hasOwnProperty('base_resource_type_id')
    }
  }, [_c('base-resource-type-picker', {
    model: {
      value: _vm.producerModel.base_resource_type_id,
      callback: function ($$v) {
        _vm.$set(_vm.producerModel, "base_resource_type_id", $$v);
      },
      expression: "producerModel.base_resource_type_id"
    }
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }