var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-provider-branch-picker-option"
  }, [_vm.provider.branch ? [_c('feather-icon', {
    staticClass: "crm-provider-branch-picker-option__icon",
    attrs: {
      "icon": "MapPinIcon",
      "size": "14"
    }
  }), _vm._v(" " + _vm._s(_vm.name) + " "), _c('feather-icon', {
    staticClass: "crm-provider-branch-picker-option__icon crm-provider-branch-picker-option__icon--branch",
    attrs: {
      "icon": "MailIcon",
      "size": "14"
    }
  }), _vm._v(" " + _vm._s(_vm.provider.branch.name) + " ")] : [_c('feather-icon', {
    staticClass: "crm-provider-branch-picker-option__icon",
    attrs: {
      "icon": "MapPinIcon",
      "size": "14"
    }
  }), _c('div', {
    staticClass: "crm-provider-branch-picker-option__unselectable"
  }, [_vm._v(" " + _vm._s(_vm.name) + " (uzupełnij oddział) ")])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }