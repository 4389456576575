<template>
  <table>
    <thead>
      <th>Nazwa</th>
      <th>Aktywny</th>
    </thead>
    <tbody>
      <tr v-for="status in statuses" :key="status.id">
        <td><status-badge :status_id="status.id"/></td>
        <td><activity-badge :state="true"/></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { mapGetters } from 'vuex';
import StatusBadge from '@/components/ui/status-badge/Cadre.vue';
import ActivityBadge from '@/components/views/cadre/ui/ExaminationBadge.vue';

export default {
  name: 'StatusManagement',
  components: {
    StatusBadge,
    ActivityBadge,
  },
  computed: {
    ...mapGetters({
      statuses: 'cadre/statuses/all',
    }),
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch('cadre/statuses/fetch');
  },
};
</script>

<style lang="sass" scoped>
table
  width: 100%
  margin-top: 25px

  td
    padding: 10px 0

    &.expiry-date
      display: flex
      align-items: center
      color: #65b259

      &.expired
        color: #b2595a

      .icon
        margin-right: 10px

  tr:not(:last-child)
    td
      border-bottom: 1px solid rgba(58, 59, 61, 0.3)

.action-buttons
  position: absolute
  bottom: 15px

.action-button
  margin: 10px 0

.box-color
  height: 30px
  width: 100px
</style>
