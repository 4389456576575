var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "websocket-widget"
  }, [_c('b-card', {
    attrs: {
      "title": "Websocket log"
    }
  }, [_c('b-button', {
    on: {
      "click": function () {
        return _vm.$store.dispatch('auth/leaveEchoChannels');
      }
    }
  }, [_vm._v("Disconnect")]), _c('ul', _vm._l(_vm.publicMessages.slice().reverse(), function (msg) {
    return _c('li', {
      key: msg.timestamp
    }, [_c('span', {
      staticClass: "time"
    }, [_vm._v(_vm._s(msg.timestamp))]), _c('code', [_vm._v(_vm._s(msg.channel))]), _c('span', {
      staticClass: "message"
    }, [_vm._v(_vm._s(msg.message))])]);
  }), 0)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }