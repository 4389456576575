import Feedback from '@feedback/Feedback';

/**
 * Feedback extension for Tasks
 */

class TaskFeedback extends Feedback {
  constructor(namespace, isPositiveFeedback) {
    super(isPositiveFeedback);

    this.extendI18nNamespace('tasks');
    this.extendI18nNamespace(namespace);
  }
}

export default TaskFeedback;
