var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settings-tab-crm-industries-form"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.category.field.name'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.name,
      "state": _vm.errors.name === null
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('base.category.field.name')
    },
    model: {
      value: _vm.category.name,
      callback: function ($$v) {
        _vm.$set(_vm.category, "name", $$v);
      },
      expression: "category.name"
    }
  })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.category.field.type'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.base_resource_type_id,
      "state": _vm.errors.base_resource_type_id !== null
    }
  }, [_c('base-resource-type-picker', {
    model: {
      value: _vm.category.base_resource_type_id,
      callback: function ($$v) {
        _vm.$set(_vm.category, "base_resource_type_id", $$v);
      },
      expression: "category.base_resource_type_id"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Raporty",
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.type,
      "state": _vm.errors.type !== null
    }
  }, [_c('b-form-checkbox', {
    staticClass: "reportable-checkbox",
    model: {
      value: _vm.category.be_reported,
      callback: function ($$v) {
        _vm.$set(_vm.category, "be_reported", $$v);
      },
      expression: "category.be_reported"
    }
  }, [_vm._v(" Posiada raporty ")])], 1)], 1)], 1), _vm.category.be_reported ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.category.field.unit'),
      "label-cols-md": "3",
      "invalid-feedback": _vm.errors.type,
      "state": _vm.errors.type !== null
    }
  }, [_c('unit-picker', {
    model: {
      value: _vm.category.unit_id,
      callback: function ($$v) {
        _vm.$set(_vm.category, "unit_id", $$v);
      },
      expression: "category.unit_id"
    }
  })], 1)], 1)], 1) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }