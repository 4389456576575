/**
 * Store Module for managing statuses inside Cadre
 */

import ApiClient from '@/services/api';

const urls = {
  get: 'api/base/statuses/index',
  getTypes: 'api/base/statuses/index-by-type',
};

export default {
  namespaced: true,

  state: {
    statuses: [],
    fetchPromise: null,
  },

  getters: {
    all: (state) => state.statuses,
    byId: (state) => (id) => state.statuses.find((s) => s.id === id),
  },

  mutations: {
    SET_STATUSES(state, payload) {
      state.statuses = payload;
    },
    SET_PROMISE(state, payload) {
      state.fetchPromise = payload;
    },
    UPDATE_STATUS(state, payload) {
      const newStatuses = state.statuses.map((s) => {
        if (s.id !== payload.id) return s;
        return payload;
      });

      state.statuses = [...newStatuses];
    },
  },

  actions: {
    fetchTypedStatuses({ state, commit }) {
      if (state.statuses.length) return state.statuses;
      if (state.fetchPromise) return state.fetchPromise;

      const request = ApiClient.get(urls.getTypes);

      request.then((res) => {
        if (res.status !== 200) return;
        commit('SET_STATUSES', res.data.data);
      });

      commit('SET_PROMISE', request);

      return request;
    },

    fetch({ state, commit }) {
      if (state.statuses.length) return state.statuses;
      if (state.fetchPromise) return state.fetchPromise;

      const request = ApiClient.get(urls.get);

      request.then((res) => {
        if (res.status !== 200) return;
        commit('SET_STATUSES', res.data.data);
      });

      commit('SET_PROMISE', request);

      return request;
    },
  },
};
