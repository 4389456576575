<template>
  <div class="base-resource-type-picker vs-picker">
    <v-select
      v-model="selected"
      :multiple="multiple"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="options"
      :clearable="clearable"
      label="name"
    >
      <template #option="{ name }">
        {{ name }}
      </template>

      <template #selected-option="{ name }">
        {{ name }}
      </template>

      <template #no-options>
        {{ $t('component.picker.branch.noOptions') }}
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { computed, ref } from 'vue';

export default {
  name: 'BaseResourceTypePicker',
  props: {
    multiple: {
      type: Boolean,
      default:
        false,
    },
    customSet: {
      type: Array,
      default:
        () => [],
    },
    clearable: {
      type: Boolean,
      default:
        false,
    },
    value: {
      default:
        null,
    },
  },
  setup(props, { emit }) {
    const options = ref([
      {
        name: 'Maszyna',
        type: 'vehicle',
      },
      {
        name: 'Sprzęt',
        type: 'tool',
      },
      {
        name: 'Samochód',
        type: 'car',
      },
    ]);

    const selected = computed({
      get() {
        if (!props.value) return null;
        if (!props.multiple) {
          return options.value
            .filter(({ type }) => type === props.value)[0];
        }

        return options.value.filter(({ type }) => props.value.includes(type));
      },
      set(value) {
        if (!value) return;
        let newValue = null;

        if (!props.multiple) newValue = value.type;
        if (props.multiple) newValue = value.map(({ type }) => type);

        emit('input', newValue);
      },
    });

    return {
      selected,
      options,
    };
  },
  components: {
    vSelect,
  },
};
</script>
