<template>
  <b-card>
    <tabs />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';

import Tabs from '@/components/views/settings/content-tabs/cadre/Tabs.vue';

export default {
  name: 'Index',
  components: {
    BCard,
    Tabs,
  },
};
</script>

<style scoped>

</style>
