<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <template
      v-slot:vertical-menu-header
    >
      <vertical-menu-header
        :is-vertical-menu-active="isVerticalMenuActive"
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
      />
    </template>

    <template
      v-slot:breadcrumb
      >
      <app-breadcrumb />
    </template>

    <template
      v-slot:footer
    >
     {{ $t('global.app.footer') }}
     <a href="https://vs.com.pl">Virtual Services</a>
    </template>

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import useAppConfig from '@core/app-config/useAppConfig';
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout';
import LayoutVertical from '../components/_LayoutVertical.vue';
import Navbar from '../components/Navbar.vue';
import VerticalMenuHeader from '../components/VerticalMenuHeader.vue';
import AppBreadcrumb from '../components/AppBreadcrumb.vue';

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    VerticalMenuHeader,
    AppBreadcrumb,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    };
  },
  setup() {
    const {
      navbarType,
      footerType,
    } = useAppConfig();

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
    } = useVerticalLayout(navbarType, footerType);

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
    };
  },
};
</script>
