import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueContentPlaceholders from 'vue-content-placeholders';
import { createPinia, PiniaVuePlugin } from 'pinia';
import i18n from '@/libs/i18n';

import store from './store';

import router from './router';
import App from './App.vue';

import '@/libs/permission';

// Global Components
import './global-components';

// 3rd party plugins
import '@/libs/portal-vue';
import '@/libs/toastification';
import '@/libs/sweet-alerts';
import '@/libs/toasts';

// Module Telescope
import '@/libs/module-telescope';

const pinia = createPinia();

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueContentPlaceholders);
Vue.use(PiniaVuePlugin);

// Websocket Listeners
require('@/services/echo');

// Custom vee validators
require('@/libs/validators');

// import core styles
// eslint-disable-next-line no-undef
require('@core/scss/core.scss');

// import assets styles
// eslint-disable-next-line no-undef
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

store.dispatch('auth/attempt').then(() => {
  new Vue({
    pinia,
    store,
    router,
    i18n,
    render: (h) => h(App),
  }).$mount('#app');
});
