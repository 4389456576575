import { buildRequest } from '@/services/api';

const prefix = 'api/core';

const useCoreApi = () => ({
  /* Authorized Devices */
  fetchAuthorizedDevices: () => buildRequest('get',
    `${prefix}/mobile`),
  addAuthorizedDevice: (device) => buildRequest('post',
    `${prefix}/mobile`, device),
  updateAuthorizedDevice: (deviceId, device) => buildRequest('put',
    `${prefix}/mobile/${deviceId}`, device),
  reauthAuthorizedDevice: (deviceId) => buildRequest('put',
    `${prefix}/mobile/${deviceId}/re-login`),
  deleteAuthorizedDevice: (deviceId) => buildRequest('delete',
    `${prefix}/mobile/${deviceId}`),

  /* Priorities */
  fetchPriorities: () => buildRequest('get',
    `${prefix}/priorities`),

  /* Branches */
  fetchBranches: () => buildRequest('get',
    `${prefix}/branch/get-list`),

  /* Units */
  unit: {
    fetch: () => buildRequest('get',
      `${prefix}/unit/get-list`),
  },

  /* Users */
  user: {
    fetch: () => buildRequest('get',
      `${prefix}/user/list`),
  },

  currency: {
    fetch: () => buildRequest('get',
      `${prefix}/currency/get-list`),
  },

  /* Permissions */
  fetchPermissionList: () => buildRequest('get',
    `${prefix}/permissions`),
  assignToUser: (userId, data) => buildRequest('put',
    `${prefix}/user/${userId}/permissions/sync`, data),
  fetchUserPermissions: (userId) => buildRequest('get',
    `${prefix}/user/${userId}/get-permissions`),
});

export default useCoreApi;
