<template>
  <b-img
    :src="$store.getters['themes/branding'].logoFull"
    :alt="$store.getters['themes/data'].appName"
  />
</template>

<script>
import { BImg } from 'bootstrap-vue';
import useAppConfig from '@core/app-config/useAppConfig';
import { $themeConfig } from '@themeConfig';

export default {
  components: {
    BImg,
  },
  computed: {
    isDark() {
      const { skin } = useAppConfig();
      if (skin.value === 'dark') {
        return this.appLogoImageSVGDark;
      }
      return this.appLogoImageSVG;
    },
  },
  setup() {
    // App Name
    const {
      appLogoImageSVG,
      appLogoImageSVGDark,
    } = $themeConfig.app;

    return {
      // App Name
      appLogoImageSVG,
      appLogoImageSVGDark,
    };
  },
};
</script>
