var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "password-reset-successful"
  }, [_c('div', {
    staticClass: "icon"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "CheckCircleIcon",
      "size": "72"
    }
  })], 1), _c('h3', {
    staticClass: "headline"
  }, [_vm._v(" " + _vm._s(_vm.$t('passwordReset.success.header')) + " ")]), _c('p', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.$t('passwordReset.success.description')) + " ")]), _c('b-button', {
    staticClass: "redirect-button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('redirect');
      }
    }
  }, [_c('feather-icon', {
    staticClass: "button-icon",
    attrs: {
      "icon": "LockIcon",
      "size": "14"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('passwordReset.success.button.redirectLogin')) + " ")], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }