/* eslint-disable camelcase */
import i18n from '@/libs/i18n';
import router from '@/router';
import store from '@/store';

import ModuleIcon from '@/components/ui/notification/ModuleIcon.vue';

const taskId = (id) => store.state.tasks.prefix.replace(':id', id);

/**
 * Configuration table for notifications
 * configuration.MODULE.EVENT
 *
 * Title and description could probably be refactored
 * as they have the same structure in i18n file.
 *
 * Actions doesn't need to include 'read' logic, as it's
 * already been covered in specific views.
 *
 * TODO This module logically does the same thing as Timeline
 *      except here we are returning translation strings
 *      instead of components. We should come up with an
 *      idea to connect this logic and avoid redundancy
 */
const configuration = {
  Orders: {
    appearance: {
      icon: 'ArrowRightCircleIcon',
      color: '#2dc1fc',
    },
    events: {},
  },
  Tasks: {
    appearance: {
      icon: 'CheckSquareIcon',
      color: '#2dc1fc',
    },
    defaultAction: (object_id) => router.push({
      name: 'tasks-details',
      params: { id: object_id },
    }).catch((err) => {
      if (err.name !== 'NavigationDuplicated') return;
      store.dispatch('tasks/fetchTaskById', object_id);
    }),
    events: {
      create: ({ object_id, creator }) => ({
        title: i18n.t('notification.tasks.store.title', { id: taskId(object_id) }),
        description: i18n.t('notification.tasks.store.description', { name: creator.full_name }),
        read: 0,
        action: () => configuration.Tasks.defaultAction(object_id),
      }),
      update: ({ object_id, creator }) => ({
        title: i18n.t('notification.tasks.update.title', { id: taskId(object_id) }),
        description: i18n.t('notification.tasks.update.description', { name: creator.full_name }),
        action: () => configuration.Tasks.defaultAction(object_id),
      }),
      updateColumn: ({ object_id, creator }) => ({
        title: i18n.t('notification.tasks.update.title', { id: taskId(object_id) }),
        description: i18n.t('notification.tasks.update.description', { name: creator.full_name }),
        action: () => configuration.Tasks.defaultAction(object_id),
      }),
      changeStatus: ({ object_id, creator }) => ({
        title: i18n.t('notification.tasks.changeStatus.title', { id: taskId(object_id) }),
        description: i18n.t('notification.tasks.changeStatus.description', { name: creator.full_name }),
        action: () => configuration.Tasks.defaultAction(object_id),
      }),
      commentAdded: ({ object_id, creator }) => ({
        title: i18n.t('notification.tasks.commentAdded.title', { id: taskId(object_id) }),
        description: i18n.t('notification.tasks.commentAdded.description', { name: creator.full_name }),
        action: () => configuration.Tasks.defaultAction(object_id),
      }),
      destroy: ({ object_id, creator }) => ({
        title: i18n.t('notification.tasks.destroy.title', { id: taskId(object_id) }),
        description: i18n.t('notification.tasks.destroy.description', { name: creator.full_name }),
        action: () => {},
      }),
    },
  },
};

/**
 * Return a formatted notification object with filled translations
 * and defined callback action
 */
export const formatNotification = function getFormattedNotification(notification) {
  const { module_name, event } = notification;

  if (!configuration[module_name] || !configuration[module_name].events[event]) return notification;

  return configuration[module_name].events[event](notification);
};

/**
 * Return a Notification Icon component with module appearance settings
 */
export const moduleIcon = function getModuleIconComponent({ module_name }) {
  const defaultIcon = {
    component: ModuleIcon,
    props: {
      icon: 'BellIcon',
      color: '#fcba2d',
    },
  };

  if (!configuration?.[module_name]?.appearance) return defaultIcon;
  const { appearance } = configuration[module_name];

  return {
    component: ModuleIcon,
    props: appearance,
  };
};
