var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isAuthorized ? _c('span', [_vm._t("default")], 2) : !_vm.silent ? _c('span', [_vm._t("unauthorized", function () {
    return [_c('unauthorized-pane', {
      attrs: {
        "permission": _vm.permission
      }
    })];
  })], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }