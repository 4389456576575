<template>
  <div class="vs-permission-manager">

    <vs-loader
      text="Przygotowywanie uprawnień"
      v-if="Object.keys(permissionTree).length === 0"
    />

    <div
      v-for="(module, idx) in Object.keys(permissionTree)"
      :key="idx"
    >
      <permission-entry-module
        :name="module"
        :resources="permissionTree[module]"
        :fetched="fetchedByModule[module]"
        @update="(data) => updateModulePermissions(module, data)"
        @ready="onModuleReady"
        :stickTop="stickTop"
        :hideCount="hideCount"
        :noInherit="noInherit"
      />

    </div>

  </div>
</template>

<script>
import usePermissions from '@/hooks/usePermissions';
import { computed, ref } from 'vue';
import PermissionEntryModule from './PermissionEntryModule.vue';
import VsLoader from '../vs-loader/VsLoader.vue';

export default {
  name: 'VsPermissionManager',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    fetched: {
      // FIXME temporary workaround
      type: Array,
      default: () => [],
    },
    stickTop: {
      type: Boolean,
      default: false,
    },
    hideCount: {
      type: Boolean,
      default: false,
    },
    noInherit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    /** TODO!
     * od razu powinienem wszystkie funkcjonalnosci zawrzec w usePermissions
     * oraz trzymac tam stan permisji - pozwoliloby to na wydzielenie funkcji
     * na toggle, sprawdzanie rodzica, dziecka itd.
     */
    const { permissionTree } = usePermissions();
    const state = ref({});

    const updateValue = () => {
      const acc = { enabled: [], disabled: [] };

      Object.keys(state.value).forEach((m) => {
        const mod = state.value[m];
        acc.enabled = [...acc.enabled, ...mod.enabled];
        acc.disabled = [...acc.disabled, ...mod.disabled];
      });

      emit('input', acc);
    };

    const updateModulePermissions = (module, data) => {
      const newObj = { ...state.value };
      newObj[module] = data;
      state.value = newObj;
      updateValue();
    };

    const fetchedByModule = computed(() => {
      const obj = {};
      props.fetched.forEach((f) => {
        const [module] = f.name.split('.');
        if (!module) return;
        obj[module] = [...obj[module] ?? [], f];
      });

      return obj;
    });

    const readyCount = ref(0);
    const onModuleReady = () => {
      readyCount.value += 1;
    };

    return {
      permissionTree,
      updateModulePermissions,
      state,
      fetchedByModule,
      onModuleReady,
      readyCount,
    };
  },
  components: { PermissionEntryModule, VsLoader },
};
</script>

<style lang="sass" scoped>
label
  margin-left: 5px
  font-size: 12pt !important
</style>
