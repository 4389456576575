import Vue from 'vue';
import getComponent from '@feedback';

/**
 * A mixin for throwing feedback toasts.
 */

const defaultToastOptions = {
  position: 'bottom-right',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false,
};

const showToast = function showToast(
  FeedbackObject,
  i18nParams = null,
  callback = null,
  textOverrides = {},
) {
  const component = getComponent(FeedbackObject, i18nParams, callback, textOverrides);

  if (FeedbackObject.isPositiveFeedback) return Vue.$toast.success(component, defaultToastOptions);
  return Vue.$toast.error(component, defaultToastOptions);
};

Vue.mixin({
  methods: {
    $showFeedback: showToast,
  },
});

export default showToast;
