<template>
  <div
    class="vs-picker"
    :class="{ 'vs-picker--loading': isLoading }"
    v-bind="$attrs"
  >

    <vs-loader
      v-if="isLoading"
      inline
      text="Wczytywanie listy..."
    />

    <v-select
      v-model="selected"
      :multiple="multiple"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="filteredOptions"
      :clearable="clearable"
      :disabled="disabled || isLoading"
      :placeholder="!isLoading ? placeholder : ''"
      :style="isLoading ? {} : currentStyle"
      :label="label"
      :reduce="reduce"
      :selectable="selectable"
      :filter="fuseSearch"
      :closeOnSelect="closeOnSelect"
      :deselectFromDropdown="true"
    >
      <template #option="item">
        <div
          class="item"
          v-show="!isLoading"
          style="display: flex; align-items: center; gap: 10px;"
        >
          <feather-icon
            icon="CheckIcon"
            v-if="multiple && value?.includes(reduce(item))"
          />

          <slot
            name="option"
            v-bind="{ option: item, multiple, props: $props }"
          >
            <vs-status-badge
              v-if="statusPicker"
              :data="item"
            />
            <span v-else>
              {{ item.name }}
            </span>
          </slot>
        </div>
      </template>

      <template
        v-if="!multiple || !statusPicker"
        #selected-option="item"
      >
        <div
          class="selected-option"
          :class="`${statusPicker ? 'selected-option-status' : ''}`"
          v-show="!isLoading"
        >
          <slot
            name="selected-option"
            v-bind="{ option: item, props: $props }"
          >
            <template v-if="statusPicker">
              <vs-status-badge :data="item" />
            </template>

            <template v-else>
              {{ item.name }}
            </template>
          </slot>
        </div>
      </template>

      <template #selected-option-container="{ option, deselect }" v-else>
        <div
          class="vs__selected selected-status-container"
          :style="getStyleForStatus(option)"
          v-show="!isLoading"
        >
          <vs-status-badge
            :data="option"
          />

          <feather-icon
            icon="XIcon"
            class="close-icon"
            @mousedown.stop="() => deselect(option)"
          />
        </div>
      </template>

        <template #no-options>
          <slot name="no-options">
            {{ $t('component.picker.default.noOptions') }}
          </slot>
        </template>
    </v-select>

  </div>
</template>

<script>
import vSelect from 'vue-select';
import { computed, ref } from 'vue';
import getContrasting from '@/libs/contrast';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import VsStatusBadge from '@/components/ui/vs-status-badge/VsStatusBadge.vue';
import Fuse from 'fuse.js';
import { emits } from '.';

export default {
  name: 'VsPicker',
  emits,
  props: {
    value: [Number, Array, String, Object],
    options: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    statusPicker: {
      type: Boolean,
      default: false,
    },
    autopick: {
      type: Boolean,
      default: false,
    },
    reduce: {
      type: Function,
      default: (option) => option,
    },
    placeholder: {
      type: String,
      default: 'Wybierz pozycję z listy',
    },
    label: {
      type: String,
      default: 'name',
    },
    selectable: {
      type: Function,
      default: () => true,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    filter: {
      // array of labels for FuseSearch
      type: [Function, Array],
    },
    optionFilter: {
      type: Function,
      default: () => true,
    },
    labelProps: {
      // can be used to pass props to a custom label defined in picker
      type: Object,
    },
    withDeleted: {
      type: Boolean,
      default: false,
    },
    excluded: {
      // return true to remove option from picker list
      type: Function,
      default: () => false,
    },
    whitelist: {
      // return false to remove option from picker list
      type: Array,
    },
  },
  setup(props, { emit }) {
    const lastPickEmit = ref(null);
    const emitPickEvent = () => {
      if (lastPickEmit.value === props.value) return;

      const selectedOption = props.options.find((o) => props.reduce(o) === props.value);
      if (!selectedOption) return;

      lastPickEmit.value = props.value;
      emit('pick', selectedOption);
    };

    const selected = computed({
      get: () => {
        emitPickEvent();
        return props.value;
      },
      set: (val) => {
        emit('input', val);
      },
    });

    const getStyleForStatus = (status) => {
      const { color } = status;
      if (!color) return {};

      return {
        '--status-color': color,
        '--status-color-indicator': getContrasting(color),
        '--status-opacity-indicator': 0.5,
      };
    };

    const selectedStatus = computed(() => {
      if (!selected.value) return null;
      return props.options?.find((s) => props.reduce(s) === selected.value);
    });

    const currentStyle = computed(() => {
      if (!props.statusPicker || !selectedStatus.value?.color) return null;
      return getStyleForStatus(selectedStatus.value);
    });

    const filteredOptions = computed(() => props.options
      .filter((o) => (props.withDeleted
        ? true
        : (!o.deleted_at || props.reduce(o) === props.value)))
      .filter(props.optionFilter)
      .filter((o) => !props.excluded(o))
      .filter((o) => !props.whitelist || props.whitelist.includes(props.reduce(o))));

    const fuseSearch = (fuseOptions, search) => {
      if (!Array.isArray(props.filter)) return fuseOptions;
      const fuse = new Fuse(fuseOptions, {
        keys: props.filter ?? [props.label ?? 'name'],
        threshold: 0.2,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    };

    return {
      selected,
      getStyleForStatus,
      currentStyle,
      filteredOptions,
      fuseSearch,
    };
  },
  components: {
    vSelect,
    VsLoader,
    VsStatusBadge,
  },
};
</script>

<style lang="sass" scoped>
.vs-picker
  position: relative

  &--loading
    ::v-deep
      .vs__selected-options
        opacity: 0 !important

  .vs-loader
    position: absolute
    top: 0
    padding-bottom: 0
    padding: 0 10px
    z-index: 2
    border-radius: 5px

  &::v-deep
    .vs__selected-options
      max-width: calc(100% - 30px)

      .vs__selected
        overflow: hidden
        max-width: calc(100% - 20px)
        text-overflow: ellipsis

.selected-status-container
  background: var(--status-color) !important
  border: none
  display: flex
  align-items: center
  flex-shrink: 0
  /* padding: 6px 5px */

  .close-icon
    color: var(--status-color-indicator)
    cursor: pointer
    border-radius: 50%

    &:hover
      background: var(--status-color-indicator)
      opacity: 0.3

::v-deep
  .vs__dropdown-toggle
    background: var(--status-color)
    display: flex

    .vs__selected-options
      > *
        padding: 6px 5px

  .vs__open-indicator
    path
      fill: var(--status-color-indicator, inherit) !important
      opacity: var(--status-opacity-indicator)

.vs-status-badge
  border-radius: 5px
  padding: 0 5px

  & &
    background: red !important

.status-picker
  max-width: 100%
  border-radius: 5px

  .status-option
    display: flex

    .details
      margin-left: 10px
</style>
