<template>
  <b-card>
    <authorization-guard permission="tasks.task.index" silent>
      <div
        class="card shadow-none bg-transparent border-secondary"
        v-if="filtrationEnabled"
      >
        <div class="card-body">
          <h4 class="card-title">
            {{ $t('global.title.filtering') }}
          </h4>
          <b-row>
            <b-col cols="4">
              <b-form-group :label="$t('tasks.edit.field.assignedTo')">
                <core-user-picker
                  withDeleted
                  multiple
                  v-model="userFilter"
                  :filtration="filteredUsers"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group label="Status">
                <tasks-status-picker multiple v-model="statusFilter" />
              </b-form-group>
            </b-col>
            <b-col cols="4">
              <b-form-group :label="$t('tasks.edit.field.priority')">
                <core-priority-picker multiple v-model="priorityFilter" />
              </b-form-group>
            </b-col>
          </b-row>
          <a href="#" @click="clearAllFilters">
            {{ $t('global.table.clearAllFilters') }}
          </a>
        </div>
      </div>
    </authorization-guard>

    <div class="buttons">
      <div class="group d-flex justify-content-between">
        <vs-button
          @click="openTaskCreateModal"
          vs-variant="primary"
          v-can:store="'tasks.task'"
        >
          + {{ $t('global.button.task') }}
        </vs-button>

        <vs-button vs-variant="light" @click="handleExport">
          <feather-icon size="14" icon="DownloadIcon" />
        </vs-button>

        <a class="d-none" ref="exportAnchorRef" />
      </div>
    </div>
    <br />

    <authorization-guard permission="tasks.task.index">
      <vs-table
        mode="remote"
        :columns="displayedColumns"
        :rows="tasks"
        :totalRecords="totalRecords"
        :checkSort="columnSortOption"
        :perPage="serverParams.perPage"
        :isLoading="isLoading"
        @searchTermChange="(term) => (searchTerm = term)"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange"
        @on-row-click="openDetails"
        @on-sort-change="changeSorting"
      >
        <template v-slot:row="props">
          <task-label
            :id="props.row.id"
            :title="props.row.title"
            v-if="props.column.field === 'title'"
          />

          <!-- Replace status with component -->
          <status-badge
            v-else-if="props.column.field === 'status_id'"
            :status_id="props.row.status_id"
          />

          <span v-else-if="props.column.field === 'assigned_id'">
            <!-- {{ user(props.row.assigned_id) ? user(props.row.assigned_id).name : '' }} -->
            <user-label :data="props.row.assigned" />
          </span>

          <span v-else-if="props.column.field === 'priority_id'">
            <priority-label :id="props.row.priority_id" />
          </span>
          <span v-else-if="props.column.field === 'termination_date'">
            {{ formatDate.taskDeadline(props.row.termination_date) }}
          </span>
        </template>
      </vs-table>
    </authorization-guard>
  </b-card>
</template>

<script>
import formatDate from '@/libs/date-formatter';
import { mapGetters } from 'vuex';
import {
  BCol, BRow, BCard, BFormGroup,
} from 'bootstrap-vue';
import ApiClient from '@/services/api';
import PriorityLabel from '@/components/ui/priority-label/PriorityLabel.vue';
import UserLabel from '@/components/ui/UserLabel.vue';
import StatusBadge from '@/components/ui/status-badge/StatusBadge.vue';
import TaskLabel from '@/components/views/tasks/Label.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import useTasksModals from '@/hooks/tasks/useTasksModals';
import TasksStatusPicker from '@/components/module/tasks/TasksStatusPicker.vue';
import CorePriorityPicker from '@/components/module/core/CorePriorityPicker.vue';
import CoreUserPicker from '@/components/module/core/CoreUserPicker.vue';
import showToast from '@/libs/toasts';
import {
  ExportDataSuccess,
  ExportDataError,
} from '@/libs/toasts/feedback/module/tasks';

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BFormGroup,

    PriorityLabel,
    UserLabel,
    StatusBadge,
    TaskLabel,
    VsButton,
    VsTable,
    AuthorizationGuard,
    TasksStatusPicker,
    CorePriorityPicker,
    CoreUserPicker,
  },
  setup() {
    const { modalCreateTask } = useTasksModals();

    const openTaskCreateModal = () => {
      modalCreateTask.value.open();
    };

    return { openTaskCreateModal };
  },
  data() {
    return {
      formatDate,
      searchTerm: '',
      searchTermTimeout: null,
      exportAnchorRef: null,
      tableOptions: {
        columns: [
          {
            label: this.$t('tasks.list.field.task'),
            field: 'title',
            showInDetails: true,
          },
          {
            label: this.$t('tasks.list.field.assignedTo'),
            field: 'assigned_id',
          },
          {
            label: this.$t('tasks.list.field.status'),
            field: 'status_id',
            showInDetails: true,
          },
          {
            label: this.$t('tasks.list.field.priority'),
            field: 'priority_id',
          },
          {
            label: this.$t('tasks.list.field.date'),
            field: 'termination_date',
          },
        ],
      },
      filterParams: [],
      filteredUsers: [],
    };
  },
  created() {
    this.$store.dispatch('tasks/fetch');
    const self = this;
    ApiClient.get('api/tasks/get-params-for-filtrating').then(({ data }) => {
      self.filterParams = data.data;
      self.filteredUsers = data.data.users.map((user) => user.id);
    });
  },
  computed: {
    ...mapGetters({
      tasks: 'tasks/all',
      isLoading: 'tasks/isLoading',
      serverParams: 'tasks/serverParams',
      totalRecords: 'tasks/totalRecords',
      columnSortOption: 'tasks/columnSort',
      user: 'users/byId',
      getSortableColumns: 'tasks/sortableColumns',
    }),
    displayedColumns() {
      if (this.$route.name !== 'tasks-details') return this.tableOptions.columns;

      return this.tableOptions.columns.filter((column) => column.showInDetails);
    },
    filtrationEnabled() {
      return this.$route.meta.showFiltration;
    },
    userFilter: {
      get() {
        return this.$store.state.tasks.fetchParams.columnFilters.assigned_to;
      },

      set(value) {
        this.$store.dispatch('tasks/updateColumnFilter', {
          assigned_to: value,
        });
      },
    },
    statusFilter: {
      get() {
        return this.$store.state.tasks.fetchParams.columnFilters.statuses_id;
      },

      set(value) {
        this.$store.dispatch('tasks/updateColumnFilter', {
          statuses_id: value,
        });
      },
    },
    priorityFilter: {
      get() {
        return this.$store.state.tasks.fetchParams.columnFilters.priorities_id;
      },

      set(value) {
        this.$store.dispatch('tasks/updateColumnFilter', {
          priorities_id: value,
        });
      },
    },
  },
  methods: {
    changeSorting(data) {
      const { field, type } = data[0];
      this.$store.dispatch('tasks/updateColumnSorting', { field, type });
    },
    clearAllFilters(e) {
      e.preventDefault();
      this.updateFilter({
        assigned_to: null,
        priorities_id: null,
        statuses_id: null,
      });
    },
    openDetails(data) {
      this.$router.push({ name: 'tasks-details', params: { id: data.row.id } });
    },
    onPageChange(param) {
      this.$store.dispatch('tasks/updatePageSettings', param);
      this.$store.dispatch('tasks/fetch');
    },
    updateFilter(param) {
      this.$store.dispatch('tasks/updateColumnFilter', param);
    },
    updateSearchTerm(param) {
      const self = this;
      clearTimeout(this.searchTermTimeout);
      this.searchTermTimeout = setTimeout(() => {
        self.$store.dispatch('tasks/updateSearchTerm', param);
        self.$store.dispatch('tasks/fetch');
      }, 500);
    },
    async handleExport() {
      const anchorRef = this.$refs.exportAnchorRef;
      if (!anchorRef) return;

      ApiClient.get('api/tasks/export', this.$store.state.tasks.fetchParams)
        .then(({ data }) => {
          const timestamp = formatDate.standardTimed(Date.now());
          anchorRef.href = `data:application/xlsx;base64,${data.data}`;
          anchorRef.download = `Budokop - Zadania - ${timestamp}.xlsx`;
          anchorRef.click();
          showToast(ExportDataSuccess);
        })
        .catch((err) => {
          showToast(ExportDataError);
          console.log('error ', err);
        });
    },
  },
  watch: {
    searchTerm() {
      this.updateSearchTerm(this.searchTerm);
    },
  },
};
</script>
<style lang="sass" scoped>
.border-secondary
  border: 1px solid #ebe9f1 !important
</style>
