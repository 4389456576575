import { buildRequest } from '@/services/api';

const prefix = 'api/crm';

const useCRMApi = () => ({
  /**
   * Company
   */
  createCompany: (company) => buildRequest('post',
    `${prefix}/company`, company),

  updateCompany: (company, form) => buildRequest('put',
    `${prefix}/company/${company.id}`, form),

  deleteCompany: (companyId) => buildRequest('delete',
    `${prefix}/company/${companyId}`),

  fetchPickerProviders: () => buildRequest('get',
    `${prefix}/company/get-providers`),

  fetchPickerClients: () => buildRequest('get',
    `${prefix}/company/get-clients`),

  /**
   * Company Contact
   */

  createCompanyContact: (contact) => buildRequest('post',
    `${prefix}/company/address`, contact),

  updateCompanyContact: (contact) => buildRequest('put',
    `${prefix}/company/address/${contact.id}`, contact),

  deleteCompanyContact: (addressId) => buildRequest('delete',
    `${prefix}/company/address/${addressId}`),

  deleteCompanyContactPerson: (contactId) => buildRequest('delete',
    `${prefix}/company/address-contact/${contactId}`),

  fetchCompanyContact: (id) => buildRequest('get',
    `${prefix}/company/address/index/${id}`),

  setPrimaryCompanyContact: (locationId) => buildRequest('put',
    `${prefix}/company/address/set-primary/${locationId}`),

  createManyCompanyContactPersons: (persons) => buildRequest('put',
    `${prefix}/company/address-contact/store-many`, persons),

  /**
   * Services
   */
  fetchServices: () => buildRequest('get',
    `${prefix}/provider/service/index`),

  fetchCompanyServices: () => buildRequest('get',
    `${prefix}/provider/service/index`),

  createService: (service) => buildRequest('post',
    `${prefix}/provider/service`, service),

  updateService: (service) => buildRequest('put',
    `${prefix}/provider/service/${service.id}`, service),

  deleteService: (id) => buildRequest('delete',
    `${prefix}/provider/service/${id}`),

  /**
   * Financing
   */
  updateFinancing: (providerId, financing) => buildRequest('put',
    `${prefix}/provider/${providerId}`, financing),

  assignServices: (providerId, services) => buildRequest('put',
    `${prefix}/provider/service/${providerId}/assign`, services),

  /**
   * Statuses
   */
  fetchStatuses: () => buildRequest('get',
    `${prefix}/company/status/index`),

  /**
   * Industries
   */
  fetchIndustries: () => buildRequest('get',
    'api/crm/company/industry/index'),

  createIndustry: (industry) => buildRequest('post',
    'api/crm/company/industry', industry),

  updateIndustry: (industry) => buildRequest('put',
    `api/crm/company/industry/${industry.id}`, industry),

  deleteIndustry: (id) => buildRequest('delete',
    `api/crm/company/industry/${id}`),
});

export default useCRMApi;
