<template>
  <div class="comment">
    <div class="comment-content" v-html="comment.content"></div>
    <div class="comment-footer">
      <user-label :id="comment.author_id" showLink />
      <div class="date">({{ date }})</div>
      <div class="buttons" v-if="comment.author_id === user.id">
        <b-button
          class="btn-icon"
          variant="flat-secondary"
          v-if="canUpdate"
          @click="edit"
          >
          <feather-icon icon="Edit2Icon" size="18"></feather-icon>
        </b-button>
        <b-button
          class="btn-icon"
          variant="flat-secondary"
          v-if="canDelete"
          @click="remove"
          >
          <feather-icon icon="Trash2Icon" size="18"></feather-icon>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BButton } from 'bootstrap-vue';
import UserLabel from '@/components/ui/UserLabel.vue';

export default {
  components: {
    BButton,
    UserLabel,
  },
  props: {
    comment: Object,
    canUpdate: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    remove() {
      this.$emit('remove', this.comment.id);
    },
    edit() {
      this.$emit('edit', this.comment);
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    date() {
      const date = new Date(this.comment.created_at);
      return date.toLocaleString();
    },
  },
};
</script>

<style lang="sass" scoped>

.comment
  &:not(:first-child)
    border-top: 1px solid rgba(black, 0.1)
    padding: 15px 0

.comment-footer
  display: flex
  align-items: center

  .date
    margin-left: 5px
    opacity: 0.8

  .buttons
    margin-left: auto

</style>
