import VsStatusBadge from '@/components/ui/vs-status-badge/VsStatusBadge.vue';
import UserLabel from '@/components/ui/UserLabel.vue';
import InvestmentsInvestmentLabel from '@/components/views/investments/InvestmentsInvestmentLabel.vue';
import store from '@/store';
import DefaultEventComponent from './default';

const BaseTransportUpdatedStatus = {
  props: ['translation', 'event'],
  setup(props) {
    const status = store
      .getters['base/transport/status/getStatusBySlug'](props.event.new_values.status);

    return { status };
  },
  template: `
    <span>
      {{ $t(translation) }}
      <vs-status-badge :data="status"/>
    </span>
  `,
  components: { VsStatusBadge },
};

const BaseTransportUpdatedInvestment = (key) => ({
  props: ['translation', 'event'],
  template: `
    <span>
      {{ $t(translation) }}
      <investments-investment-label :id="event.new_values.${key}" />
    </span>
  `,
  components: { InvestmentsInvestmentLabel },
});

const BaseTransportUpdatedUser = (key) => ({
  props: ['translation', 'event'],
  template: `
    <span>
      {{ $t(translation) }}
      <user-label inline :withAvatar="false" :id="event.old_values.${key}"/>
      na
      <user-label inline :withAvatar="false" :id="event.new_values.${key}"/>
    </span>
  `,
  components: { UserLabel },
});

export default {
  updated: {
    default: DefaultEventComponent,
    status: BaseTransportUpdatedStatus,
    to_investment_id: BaseTransportUpdatedInvestment('to_investment_id'),
    from_investment_id: BaseTransportUpdatedInvestment('from_investment_id'),
    receiver_id: BaseTransportUpdatedUser('receiver_id'),
    release_id: BaseTransportUpdatedUser('release_id'),
  },
};
