<template>
  <vs-form
    class="profile-settings-device-form"
    i18nNamespace="profile.settings.device.field"
    v-model="device"
    :errors="feedback"
  >
    <vs-form-group
      field="name"
    >
      <b-form-input
        v-model="device.name"
      />
    </vs-form-group>
  </vs-form>
</template>

<script>
import {
  BFormInput,
} from 'bootstrap-vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import { computed } from 'vue';

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const device = computed(() => props.value);

    return { device };
  },
  components: {
    BFormInput,
    VsForm,
    VsFormGroup,
  },
};
</script>
