import global from './global';

// views
import login from './views/login';
import home from './views/home';
import profile from './views/profile';
import passwordReset from './views/password-reset';
import passwordForgot from './views/password-forgot';
import error from './error';
import settings from './views/settings';

import tasks from './views/tasks';
import orders from './views/orders';
import cadre from './views/cadre';
import crm from './views/crm';
import base from './views/base';
import investments from './views/investments';

import events from './events';
import feedback from './feedback';
import component from './component';
import notification from './notification';
import integration from './integration';
import permission from './permission';
import form from './form';

export default {
  global,
  login,
  passwordReset,
  passwordForgot,
  home,
  profile,
  error,
  settings,

  tasks,
  orders,
  cadre,
  crm,
  base,
  investments,

  events,
  feedback,
  component,
  notification,
  integration,

  permission,
  form,
};
