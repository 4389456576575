<template>
  <b-card>
    <b-tabs
      nav-class="nav-left"
    >
      <b-tab active>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Kategorie</span>
        </template>

        <settings-tab-base-categories />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Technologie</span>
        </template>

        <settings-tab-base-technologies />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Statusy</span>
        </template>

        <settings-tab-base-statuses/>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Grupy</span>
        </template>

        <settings-tab-base-groups />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Producenci</span>
        </template>

        <settings-tab-base-producers />
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon
            icon="ClipboardIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Modele</span>
        </template>

        <settings-tab-base-models />
      </b-tab>

    </b-tabs>
  </b-card>
</template>

<script>
import { BCard, BTabs, BTab } from 'bootstrap-vue';
import SettingsTabBaseStatuses
  from '@/components/views/settings/content-tabs/base/SettingsTabBaseStatuses.vue';
import SettingsTabBaseGroups from '@/components/views/settings/content-tabs/base/SettingsTabBaseGroups.vue';
import SettingsTabBaseProducers from '@/components/views/settings/content-tabs/base/SettingsTabBaseProducers.vue';
import SettingsTabBaseModels from '@/components/views/settings/content-tabs/base/SettingsTabBaseModels.vue';
import SettingsTabBaseCategories from './SettingsTabBaseCategories.vue';
import SettingsTabBaseTechnologies from './SettingsTabBaseTechnologies.vue';

export default {
  name: 'SettingsTabBase',
  components: {
    SettingsTabBaseProducers,
    SettingsTabBaseModels,
    SettingsTabBaseGroups,
    SettingsTabBaseStatuses,
    SettingsTabBaseCategories,

    BCard,
    BTabs,
    BTab,
    SettingsTabBaseTechnologies,
  },
};
</script>
