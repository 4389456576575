var render = function render(){
  var _vm$branchById;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [this.showLoader ? _c('vs-loader', {
    attrs: {
      "text": _vm.$t('orders.details.message.loading')
    }
  }) : !_vm.order ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('orders.error.orderNotFound')) + " "), _c('br'), _c('br'), _c('router-link', {
    staticClass: "close-details",
    attrs: {
      "to": {
        name: 'orders'
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('orders.details.button.back')) + " ")])], 1) : _vm.order ? _c('div', {
    staticClass: "order-details"
  }, [_c('div', {
    staticClass: "order-subject"
  }, [_vm._v(" " + _vm._s(_vm.order.title) + " ")]), _c('h3', {
    staticClass: "title"
  }, [_c('order-label', {
    attrs: {
      "id": _vm.order.id,
      "title": _vm.order.order_number
    }
  }), _c('router-link', {
    staticClass: "close-details",
    attrs: {
      "to": {
        name: 'orders'
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "XIcon",
      "size": "24"
    }
  })], 1)], 1), _vm.totalPrice ? _c('div', {
    staticClass: "total-price"
  }, [_vm._v(" " + _vm._s(_vm.totalPrice.toLocaleString('ru-RU')) + " " + _vm._s(_vm.currencyById(_vm.order.currency_id).code) + " ")]) : _vm._e(), _c('div', {
    staticClass: "buttons"
  }, [_c('div', {
    staticClass: "group"
  }, [_c('widget-card-controls', {
    attrs: {
      "editPermission": "orders.order.update",
      "deletePermission": "orders.order.destroy"
    },
    on: {
      "edit": _vm.openEditModal,
      "delete": _vm.deleteOrder
    }
  })], 1), _c('div', {
    staticClass: "group"
  }, [_c('status-dropdown', {
    directives: [{
      name: "can",
      rawName: "v-can:change_status",
      value: 'orders.order',
      expression: "'orders.order'",
      arg: "change_status"
    }],
    attrs: {
      "source": "orders",
      "currentStatusId": _vm.order.status_id
    },
    on: {
      "pick": _vm.changeStatus
    }
  })], 1), _c('div', {
    staticClass: "group"
  }, [_vm.order.crm_provider_address_contact && _vm.order.crm_provider_address_contact.company_address ? _c('vs-button', {
    staticClass: "vs-pdf-button",
    attrs: {
      "variant": "outline",
      "color": "#aaaaaa"
    },
    on: {
      "click": _vm.downloadPDF
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.button.pdf')) + " ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "details"
  }, [_c('table', [_c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('orders.details.field.priority')))]), _c('td', [_c('priority-label', {
    attrs: {
      "id": _vm.order.priority_id
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('orders.details.field.offerNumber')))]), _c('td', [_vm._v(_vm._s(_vm.order.offer_number))])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('orders.details.field.status')))]), _c('td', [_c('div', {
    staticClass: "status"
  }, [_c('status-badge', {
    attrs: {
      "orders": "",
      "status_id": _vm.order.status_id
    }
  })], 1)])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('orders.details.field.author')))]), _c('td', [_c('user-label', {
    attrs: {
      "withAvatar": "",
      "data": _vm.order.author
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('orders.details.field.assigned')))]), _c('td', [_c('user-label', {
    attrs: {
      "withAvatar": "",
      "data": _vm.order.assigned
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('orders.details.field.accepting')))]), _c('td', [_c('user-label', {
    attrs: {
      "withAvatar": "",
      "data": _vm.order.accepting
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('orders.details.field.date.created')))]), _c('td', [_vm._v(_vm._s(_vm.formatDate.taskDeadline(_vm.order.ordered_at)))])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('orders.details.field.date.accepted')))]), _c('td', [_vm._v(_vm._s(_vm.formatDate.taskDeadline(_vm.order.acceptance_at) || '-'))])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('orders.details.field.date.settled')))]), _c('td', [_vm._v(_vm._s(_vm.formatDate.taskDeadline(_vm.order.settlement_at) || '-'))])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('orders.details.field.provider')))]), _c('td', [_c('crm-provider-label', {
    attrs: {
      "integrated": _vm.order.crm_provider_address_contact,
      "short": ""
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('orders.details.field.investor')))]), _c('td', [_c('crm-investor-label', {
    attrs: {
      "integrated": _vm.order.investor
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('orders.details.field.investment')))]), _vm.order.investment && _vm.order.investment.id ? _c('td', [_c('investments-investment-label', {
    attrs: {
      "investment": _vm.order.investment
    }
  })], 1) : _c('td', [_vm._v(_vm._s(_vm.order.investment ? _vm.order.investment.name : ''))])]), _c('tr', [_c('td', [_vm._v(_vm._s(_vm.$t('orders.details.field.region')))]), _c('td', [_vm._v(" " + _vm._s((_vm$branchById = _vm.branchById(_vm.order.branch_id)) === null || _vm$branchById === void 0 ? void 0 : _vm$branchById.shortcut) + " ")])])])]), _c('div', {
    directives: [{
      name: "can",
      rawName: "v-can:show",
      value: 'orders.subject',
      expression: "'orders.subject'",
      arg: "show"
    }]
  }, [_vm.subject ? _c('hr') : _vm._e(), _c('order-subject', {
    attrs: {
      "subject": _vm.subject
    },
    on: {
      "add": _vm.createSubject,
      "edit": _vm.editSubject,
      "remove": _vm.removeSubject
    }
  })], 1), _c('hr'), _c('header-section', {
    attrs: {
      "title": _vm.$t('orders.details.field.paymentTerms')
    }
  }, [_c('div', {
    staticClass: "details"
  }, [_c('table', {
    staticClass: "payment-terms"
  }, [_c('tr', [_c('td', [_vm._v("Warunki płatności")]), _c('td', {
    staticClass: "payment-terms-test",
    domProps: {
      "innerHTML": _vm._s(_vm.order.payment_terms)
    }
  })]), _c('tr', [_c('td', [_vm._v("Termin realizacji")]), _c('td', [_vm._v(_vm._s(_vm.formatDate.taskDeadline(_vm.order.realization_at) || '-'))])]), _c('tr', [_c('td', [_vm._v("Miejsce dostawy")]), _c('td', [_vm._v(_vm._s(_vm.order.delivery_place))])]), _c('tr', [_c('td', [_vm._v("Transport")]), _c('td', [_vm._v(_vm._s(_vm.order.transport))])]), _c('tr', [_c('td', [_vm._v("Osoba do kontaktu")]), _c('td', [_vm._v(_vm._s(_vm.order.contact_person))])])])])]), _c('header-section', {
    attrs: {
      "title": _vm.$t('orders.details.field.description')
    }
  }, [_vm.order.comment ? _c('p', {
    staticClass: "description",
    domProps: {
      "innerHTML": _vm._s(_vm.order.comment)
    }
  }) : _c('span', {
    staticClass: "noDescriptionInfo"
  }, [_vm._v(" " + _vm._s(_vm.$t('orders.details.message.noDescription')) + " ")])]), _c('hr'), _c('attachments', {
    attrs: {
      "orders": "",
      "items": _vm.order.files,
      "uploading": _vm.uploading,
      "noAccessText": _vm.$t('orders.details.message.noAttachmentAccess')
    },
    on: {
      "add": _vm.createAttachment,
      "remove": _vm.removeAttachment
    }
  }), _c('div', {
    directives: [{
      name: "can",
      rawName: "v-can:index",
      value: 'orders.comment',
      expression: "'orders.comment'",
      arg: "index"
    }]
  }, [_c('hr'), _c('comments', {
    attrs: {
      "items": _vm.comments,
      "canAdd": _vm.$store.getters['auth/userHasPermission']('store', 'orders.comment'),
      "canDelete": _vm.$store.getters['auth/userHasPermission']('destroy', 'orders.comment'),
      "canUpdate": _vm.$store.getters['auth/userHasPermission']('update', 'orders.comment')
    },
    on: {
      "add": _vm.createComment,
      "edit": _vm.editComment,
      "remove": _vm.removeComment
    }
  })], 1), _c('authorization-guard', {
    attrs: {
      "silent": "",
      "permission": "orders.order.get_logs"
    }
  }, [_c('hr'), _vm.timeline ? _c('timeline', {
    attrs: {
      "source": "orders",
      "items": _vm.timeline
    }
  }) : _vm._e()], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }