import Feedback from '@feedback/Feedback';

/**
 * Feedback extension for Cores
 */

class CRMFeedback extends Feedback {
  constructor(namespace, isPositiveFeedback) {
    super(isPositiveFeedback);

    this.extendI18nNamespace('crm');
    this.extendI18nNamespace(namespace);
  }
}

export default CRMFeedback;
