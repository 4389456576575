<template>
  <span
    class="vs-status-badge"
    :style="styleVars"
    >
    {{ status.name }}
  </span>
</template>

<script>
import getContrasting from '@/libs/contrast';
import { computed } from 'vue';

export default {
  name: 'VsStatusBadge',
  props: {
    id: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: () => ({
        default: true,
        name: 'Brak statusu',
        color: '#e0e0e0',
      }),
    },
    fetchFunction: {
      type: Function,
      default: () => {},
    },
    statusListRef: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const status = computed(() => {
      if (props.id !== null) {
        return props.statusListRef
          .find((item) => item.id === props.id)
          ?? props.data;
      }
      return props.data;
    });

    const styleVars = computed(() => ({
      '--primary-bg': status.value.color,
      '--primary-fg': getContrasting(status.value.color),
    }));

    if (props.id && !props.data.default) {
      props.fetchFunction();
    }

    return { status, styleVars };
  },
};
</script>

<style lang="sass" scoped>
.vs-status-badge
  display: inline-block
  background-color: var(--primary-bg)
  color: var(--primary-fg)
  padding: 3px 10px
  border-radius: 6px
  font-weight: 600
  white-space: nowrap
</style>
