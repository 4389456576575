export default {
  /* Errors */
  CreateInvestmentError: {
    title: 'Nie udało się utworzyć inwestycji',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  UpdateInvestmentError: {
    title: 'Nie udało się zaktualizować inwestycji',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteInvestmentError: {
    title: 'Nie udało się usunąć inwestycji',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  FetchInvestmentError: {
    title: 'Nie udało się pobrać danych inwestycji',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  UploadInvestmentOfferError: {
    title: 'Nie udało się zapisać oferty inwestycji',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateFuelingError: {
    title: 'Nie udało się utworzyć dostawy paliwa',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  UpdateFuelingError: {
    title: 'Nie udało się zaktualizować dostawy paliwa',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteFuelingError: {
    title: 'Nie udało się usunąć dostawy paliwa',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  FetchWorkSummaryError: {
    title: 'Nie udało się pobrać podsumowania przerobów',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  AssignSubjectError: {
    title: 'Nie udało się dodać przedmiotu zamówienia',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  EditSubjectError: {
    title: 'Nie udało się zaktualizować przedmiotu zamówienia',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteSubjectError: {
    title: 'Nie udało się usunąć przedmiotu zamówienia',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateDemandError: {
    title: 'Nie udało się utworzyć przedmiotu zapotrzebowania',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  EditDemandError: {
    title: 'Nie udało się zaktualizować przedmiotu zapotrzebowania',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteDemandError: {
    title: 'Nie udało się usunąć przedmiotu zapotrzebowania',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateReinforcementError: {
    title: 'Nie udało się utworzyć zbrojenia',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  EditReinforcementError: {
    title: 'Nie udało się zaktualizować zbrojenia',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteReinforcementError: {
    title: 'Nie udało się usunąć zbrojenia',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateWorkTypeError: {
    title: 'Nie udało się utworzyć typu robót',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  EditWorkTypeError: {
    title: 'Nie udało się zaktualizować typu robót',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteWorkTypeError: {
    title: 'Nie udało się usunąć typu robót',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateWorkGroupError: {
    title: 'Nie udało się utworzyć grupy',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  EditWorkGroupError: {
    title: 'Nie udało się zaktualizować grupy',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteWorkGroupError: {
    title: 'Nie udało się usunąć grupy',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateMaterialError: {
    title: 'Nie udało się utworzyć materiału',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  EditMaterialError: {
    title: 'Nie udało się zaktualizować materiału',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteMaterialError: {
    title: 'Nie udało się usunąć materiału',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateOfferError: {
    title: 'Nie udało się utworzyć oferty',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  EditOfferError: {
    title: 'Nie udało się zaktualizować oferty',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteOfferError: {
    title: 'Nie udało się usunąć oferty',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  AcceptOfferError: {
    title: 'Nie udało się dokonać wyboru oferty',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  AssignResourceError: {
    title: 'Nie udało się przypisać zasobu',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  EditResourceError: {
    title: 'Nie udało się zaktualizować zasobu',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteResourceError: {
    title: 'Nie udało się usunąć zasobu',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  UpdateManyResourceError: {
    title: 'Nie udało się zaktualizować dat dla zasobów',
  },

  AssignEmployeeError: {
    title: 'Nie udało się przypisać pracownika',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  EditEmployeeError: {
    title: 'Nie udało się zaktualizować pracownika',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteEmployeeError: {
    title: 'Nie udało się usunąć pracownika',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateBrigadeError: {
    title: 'Nie udało się utworzyć brygady',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  EditBrigadeError: {
    title: 'Nie udało się zaktualizować brygady',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteBrigadeError: {
    title: 'Nie udało się usunąć brygady',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateInvestmentReportError: {
    title: 'Nie udało się dodać raportu dziennego',
  },

  UpdateInvestmentReportError: {
    title: 'Nie udało się zaktualizować raportu dziennego',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  DeleteInvestmentReportError: {
    title: 'Nie udało się usunąć raportu dziennego',
    description: 'Wystąpił nieoczekiwany błąd',
  },

  CreateInvestmentReportInvalid: {
    title: 'Nie udało się dodać raportu dziennego',
    description: 'Przesłane dane są błędne, sprawdź formularz',
  },

  AttachmentSizeError: {
    title: 'Załącznik jest zbyt duży',
    description: 'Wielkość załącznika nie może przekraczać 60 MB',
  },

  /* Success */
  CreateInvestmentSuccess: {
    title: 'Pomyślnie utworzono inwestycję',
    description: '{name}',
  },

  UpdateInvestmentSuccess: {
    title: 'Pomyślnie zaktualizowano dane inwestycji',
    description: '{name}',
  },

  DeleteInvestmentSuccess: {
    title: 'Pomyślnie usunięto inwestycję',
    description: '{name}',
  },

  CreateFuelingSuccess: {
    title: 'Pomyślnie utworzono dostawę paliwa',
  },

  UpdateFuelingSuccess: {
    title: 'Pomyślnie zaktualizowano dane dostawy',
  },

  DeleteFuelingSuccess: {
    title: 'Pomyślnie usunięto dostawę paliwa',
  },

  AssignSubjectSuccess: {
    title: 'Pomyślnie dodano przedmiot zamówienia',
  },

  EditSubjectSuccess: {
    title: 'Pomyślnie zaktualizowano przedmiot zamówienia',
  },

  DeleteSubjectSuccess: {
    title: 'Pomyślnie usunięto przedmiot zamówienia',
  },

  CreateDemandSuccess: {
    title: 'Pomyślnie utworzono przedmiot zapotrzebowania',
    description: '{name}',
  },

  EditDemandSuccess: {
    title: 'Pomyślnie zaktualizowano przedmiot zapotrzebowania',
    description: '{name}',
  },

  DeleteDemandSuccess: {
    title: 'Pomyślnie usunięto zapotrzebowanie',
    description: '{name}',
  },

  CreateReinforcementSuccess: {
    title: 'Pomyślnie utworzono zbrojenie',
    description: '{name}',
  },

  EditReinforcementSuccess: {
    title: 'Pomyślnie zaktualizowano zbrojenie',
    description: '{name}',
  },

  DeleteReinforcementSuccess: {
    title: 'Pomyślnie usunięto zbrojenie',
    description: '{name}',
  },

  CreateWorkTypeSuccess: {
    title: 'Pomyślnie utworzono typ robót',
    description: '{name}',
  },

  EditWorkTypeSuccess: {
    title: 'Pomyślnie zaktualizowano typ robót',
    description: '{name}',
  },

  DeleteWorkTypeSuccess: {
    title: 'Pomyślnie usunięto typ robót',
    description: '{name}',
  },

  CreateWorkGroupSuccess: {
    title: 'Pomyślnie utworzono grupę',
    description: '{name}',
  },

  EditWorkGroupSuccess: {
    title: 'Pomyślnie zaktualizowano grupę',
    description: '{name}',
  },

  DeleteWorkGroupSuccess: {
    title: 'Pomyślnie usunięto grupę',
    description: '{name}',
  },

  CreateMaterialSuccess: {
    title: 'Pomyślnie utworzono materiał',
    description: '{name}',
  },

  EditMaterialSuccess: {
    title: 'Pomyślnie zaktualizowano materiał',
    description: '{name}',
  },

  DeleteMaterialSuccess: {
    title: 'Pomyślnie usunięto materiał',
    description: '{name}',
  },

  CreateOfferSuccess: {
    title: 'Pomyślnie utworzono ofertę',
  },

  EditOfferSuccess: {
    title: 'Pomyślnie zaktualizowano ofertę',
  },

  DeleteOfferSuccess: {
    title: 'Pomyślnie usunięto ofertę',
  },

  AcceptOfferSuccess: {
    title: 'Pomyślnie zmieniono wybór oferty',
  },

  AssignResourceSuccess: {
    title: 'Pomyślnie przypisano zasób',
    description: '{name}',
  },

  EditResourceSuccess: {
    title: 'Pomyślnie zaktualizowano zasób',
    description: '{name}',
  },

  DeleteResourceSuccess: {
    title: 'Pomyślnie usunięto zasób',
    description: '{name}',
  },

  UpdateManyResourceSuccess: {
    title: 'Pomyślnie zaktualizowano daty zasobów',
  },

  AssignEmployeeSuccess: {
    title: 'Pomyślnie przypisano pracownika',
    description: '{name}',
  },

  EditEmployeeSuccess: {
    title: 'Pomyślnie zaktualizowano pracownika',
    description: '{name}',
  },

  DeleteEmployeeSuccess: {
    title: 'Pomyślnie usunięto pracownika',
    description: '{name}',
  },

  CreateBrigadeSuccess: {
    title: 'Pomyślnie utworzono brygadę',
    description: '{name}',
  },

  EditBrigadeSuccess: {
    title: 'Pomyślnie zaktualizowano brygadę',
    description: '{name}',
  },

  DeleteBrigadeSuccess: {
    title: 'Pomyślnie usunięto brygadę',
    description: '{name}',
  },

  CreateInvestmentReportSuccess: {
    title: 'Pomyślnie dodano raport dzienny',
  },

  UpdateInvestmentReportSuccess: {
    title: 'Pomyślnie zaktualizowano raport dzienny',
  },

  DeleteInvestmentReportSuccess: {
    title: 'Pomyślnie usunięto raport dzienny',
  },
};
