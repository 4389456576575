import Vue from 'vue';
import Vuex from 'vuex';

import ApiClient from '@/services/api';

const endpoint = 'api/core/priorities';

Vue.use(Vuex);

export default {
  namespaced: true,

  state: {
    priorities: [],
    fetchPromise: null,
  },

  getters: {
    all: (state) => state.priorities,
    byId: (state) => (id) => state.priorities.filter((priority) => priority.id === id)[0],
  },

  mutations: {
    SET_PRIORITIES(state, payload) {
      state.priorities = payload;
    },
    SET_PROMISE(state, payload) {
      state.fetchPromise = payload;
    },
  },

  actions: {
    fetch({ state, commit }) {
      if (state.priorities.length) return state.priorities;
      if (state.fetchPromise) return state.fetchPromise;

      const promise = ApiClient.get(endpoint).then((payload) => {
        if (payload.status !== 200) return null;
        return commit('SET_PRIORITIES', payload.data.data);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },
  },
};
