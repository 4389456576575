export default {
  /* Errors */
  AddError: {
    title: 'Nie udało się dodać notatki',
  },
  EditError: {
    title: 'Nie udało się edytować notatki',
  },
  DeleteError: {
    title: 'Nie udało się usunąć notatki',
  },

  /* Success */
  AddSuccess: {
    title: 'Pomyślnie utworzono notatkę',
  },
  EditSuccess: {
    title: 'Pomyślnie zaktualizowano notatkę',
  },
  DeleteSuccess: {
    title: 'Pomyślnie usunięto notatkę',
  },
};
