import ApiClient from '@/services/api';

/**
 * TODO: Separate modules for specific resource types
 * are no longer needed. They need to be moved to an
 * universal solution
 */
const urls = {
  get: 'api/base/resource',
};

export default {
  namespaced: true,

  state: {
    equipment: [],
  },

  getters: {
  },

  mutations: {
    SET_EQUIPMENT(state, equipment) {
      state.equipment = equipment;
    },

    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
  },

  actions: {
    /**
     * Fetch equipment list
     * @action fetch=equipment
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if equipment exist
     * @returns {Promise}
     */
    fetch({ state, commit }, params, force = true) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(urls.get, {
        ...params,
        columnFilters: {
          ...params.columnFilters ?? {},
          type_ids: ['tool'],
        },
      });

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_EQUIPMENT', res.data.data.resources);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },
  },
};
