<template>
  <vs-modal
    class="profile-settings-devices-add-modal"
    :modalInstance="modalAddAuthorizedDevice"
    @ok="addAuthorizedDevice"

    @hide="resetFields"
  >

    <profile-settings-device-form
      v-model="fields"
      :feedback="feedback"
    />

  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useCoreModals from '@/hooks/useCoreModals';
import useCoreApi from '@/hooks/useCoreApi';
import useValidatedForm from '@/hooks/useValidatedForm';
import {
  AuthorizedDevicesCreateSuccess,
  AuthorizedDevicesCreateError,
} from '@/libs/toasts/feedback/module/core';
import showToast from '@/libs/toasts';
import ProfileSettingsDeviceForm from '../form/ProfileSettingsDeviceForm.vue';

export default {
  name: 'ProfileSettingsDevicesAddModal',
  emits: ['success'],
  setup(_, { emit }) {
    const {
      modalAddAuthorizedDevice,
      modalConnectAuthorizedDevice,
    } = useCoreModals();

    const { fields, resetFields, feedback } = useValidatedForm({
      name: 'required',
    });

    const addAuthorizedDevice = async () => {
      modalAddAuthorizedDevice.value.isLoading = true;

      try {
        const { data } = await useCoreApi()
          .addAuthorizedDevice(fields.value);

        showToast(AuthorizedDevicesCreateSuccess, { name: data.data.name });
        modalAddAuthorizedDevice.value.visible = false;

        modalConnectAuthorizedDevice.value.data = { ...data.data };
        modalConnectAuthorizedDevice.value.visible = true;
        emit('success');
      } catch (error) {
        showToast(AuthorizedDevicesCreateError);
      } finally {
        modalAddAuthorizedDevice.value.isLoading = false;
      }
    };

    return {
      modalAddAuthorizedDevice,
      addAuthorizedDevice,
      fields,
      resetFields,
      feedback,
    };
  },
  components: { VsModal, ProfileSettingsDeviceForm },
};
</script>
