<template>
  <div class="permission-entry-module">

    <div
      class="header"
      @click.self="collapsed = !collapsed"
    >
      <permission-switcher
        :value="permissionState"
        @change="handleResourceStateChange"
        :noInherit="noInherit"
      />

      <div class="header-text" @click="collapsed = !collapsed">
        <div class="name">
          {{ moduleTranslation }}
        </div>

        {{ `${module}.${name}.*` }}
      </div>

      <div v-if="!hideCount">
        Przyznano {{ enabledActionCount }} z {{ actions.length }} uprawnień
      </div>

      <div class="collapse-indicator" @click="collapsed = !collapsed">
        <feather-icon
          :icon="collapsed ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          size="22"
        />
      </div>
    </div>

    <hr>

    <b-collapse v-model="collapsed">
      <permission-entry-action
        v-for="action in actions"
        :key="`${module}.${name}.${action}`"
        :module="module"
        :resource="name"
        :name="action"
        :state="getActionState(action)"
        @change="(state) => handleActionStateChange(action, state)"
        :noInherit="noInherit"
      />
      <hr>
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse } from 'bootstrap-vue';
import { computed, ref } from 'vue';
import i18n from '@/libs/i18n';
import PermissionSwitcher from './PermissionSwitcher.vue';
import PermissionEntryAction from './PermissionEntryAction.vue';

export default {
  name: 'PermissionEntryModule',
  props: {
    module: String,
    name: String,
    actions: Array,
    state: Array,
    hideCount: {
      type: Boolean,
      default: false,
    },
    noInherit: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const collapsed = ref(false);
    const moduleTranslation = computed(() => i18n.t(`permission.${props.module}.resource.${props.name}.name`));

    const getPermByKey = (key) => props.state.find((p) => p.name === key);

    const permissionState = computed(() => {
      const permission = [
        getPermByKey(`${props.module}.*`),
        getPermByKey(`${props.module}.${props.name}.*`),
      ];

      if (permission.some((perm) => perm?.state === true)) return 'grant';
      if (permission.some((perm) => perm?.state === false)) return 'deny';
      return 'inherit';
    });

    const getActionState = (action) => {
      const permission = [
        getPermByKey(`${props.module}.*`),
        getPermByKey(`${props.module}.${props.name}.*`),
        getPermByKey(`${props.module}.${props.name}.${action}`),
      ];

      if (permission.some((perm) => perm?.state === true)) return 'grant';
      if (permission.some((perm) => perm?.state === false)) return 'deny';
      return 'inherit';
    };

    const handleActionStateChange = (action, state) => {
      // const resourcePerm = getPermByKey(`${props.module}.${props.name}.*`);
      const states = [];

      let queue = props.actions.map((a) => {
        const newState = a === action ? state : getActionState(a);
        states.push(newState);
        return { permission: `${props.module}.${props.name}.${a}`, state: newState };
      });

      if (states.every((s) => s === 'grant') || states.every((s) => s === 'deny')) {
        emit('change', `${props.module}.${props.name}.*`, states[0]);
        queue = queue.map((q) => ({ ...q, state: 'inherit' }));
      } else {
        emit('change', `${props.module}.${props.name}.*`, 'inherit');
      }

      queue.forEach((q) => emit('change', q.permission, q.state));
    };

    const handleResourceStateChange = (state) => {
      props.actions.forEach((a) => {
        if (getPermByKey(`${props.module}.${props.name}.${a}`).state !== null) {
          emit('change', `${props.module}.${props.name}.${a}`, 'inherit');
        }
      });

      emit('change', `${props.module}.${props.name}.*`, state);
    };

    const enabledActionCount = computed(() => {
      if (getPermByKey(`${props.module}.${props.name}.*`).state === true) {
        return props.actions.length;
      }
      return props.actions.reduce((total, action) => {
        if (getPermByKey(`${props.module}.${props.name}.${action}`).state === true
        || getPermByKey(`${props.module}.*`).state === true) {
          return total + 1;
        }
        return total;
      }, 0);
    });

    return {
      moduleTranslation,
      collapsed,
      permissionState,
      getActionState,
      handleActionStateChange,
      handleResourceStateChange,
      enabledActionCount,
    };
  },
  components: {
    BCollapse,
    PermissionSwitcher,
    PermissionEntryAction,
  },
};
</script>

<style lang="sass" scoped>
.header
  display: flex
  align-items: center
  gap: 20px
  font-size: 9pt
  line-height: 1.2
  padding: 0 10px
  padding-left: 45px

  .name
    font-size: 12pt
    font-weight: 500

  .collapse-indicator
    margin-left: auto
</style>
