<template>
  <tr>
    <td class="name">
      <a :href="attachment.file_link" target="_blank">
        {{ attachment.original_name }}
      </a>

      <!-- FIXME: move to a slot to expose it to parent components -->
      <span v-if="attachment.isOfferCurrent">(aktualna oferta)</span>
      <!-- -->
    </td>
    <td class="access" v-if="!hideAccessInfo">
      <b-button
        :title="$t('component.attachments.button.protected.header')"
        v-b-popover.hover.top="noAccessText"
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="flat"
        class="btn-icon rounded-circle"
        >
        <feather-icon v-if="!attachment.userHasAccess" size="18" icon="LockIcon"></feather-icon>
      </b-button>
    </td>
    <td :class="!canDelete && 'last-column'" v-if="!hideDate">
      {{ formatDate.taskDeadline(attachment.created_at) }}
    </td>
    <td class="edit">
      <b-button
        @click="removeAttachment"
        v-if="canDelete"
        class="btn-icon"
        variant="flat-secondary">
        <feather-icon
          icon="Trash2Icon"
          size="20"
        />
      </b-button>
    </td>
  </tr>
</template>

<script>
import formatDate from '@/libs/date-formatter';
import { BButton, VBPopover } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';

export default {
  props: {
    attachment: Object,
    noAccessText: String,
    canDelete: {
      type: Boolean,
      default: true,
    },
    hideAccessInfo: {
      type: Boolean,
      default: false,
    },
    hideDate: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    formatDate,
  }),
  methods: {
    removeAttachment() {
      const id = this.attachment?.id;
      this.$emit('remove', id);
    },
  },
  components: {
    BButton,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
};
</script>

<style lang="sass" scoped>
td
  font-size: 11pt
  padding: 10px 0

  &.name
    width: 50%

  &.edit
    text-align: center
    width: 8%

  &.last-column
    text-align: right

  &.access
    text-align: center
</style>
