var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-work-group-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.errors,
      "i18nNamespace": "investments.form.workGroup.field"
    },
    model: {
      value: _vm.workGroup,
      callback: function ($$v) {
        _vm.workGroup = $$v;
      },
      expression: "workGroup"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "name"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.workGroup.field.name')
    },
    model: {
      value: _vm.workGroup.name,
      callback: function ($$v) {
        _vm.$set(_vm.workGroup, "name", $$v);
      },
      expression: "workGroup.name"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }