<template>
  <table>
    <thead>
      <th>Nazwa</th>
      <th>Aktywny</th>
    </thead>
    <tbody>
    <tr v-for="status in companyStatuses" :key="status.id">
      <td><status-badge :status_id="status.id"/></td>
      <td><activity-badge state="upToDate" :hide-text="true"/></td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import useStatuses from '@/hooks/settings/crm/useStatuses';
import { provide } from 'vue';
import StatusBadge from '@/components/ui/status-badge/Company.vue';
import ActivityBadge from '@/components/views/cadre/ui/ExaminationBadge.vue';

export default {
  name: 'SettingsTabCrmStatuses',
  components: {
    StatusBadge,
    ActivityBadge,
  },
  setup() {
    const statusesInstance = useStatuses();
    const { companyStatuses, fetch } = statusesInstance;

    provide('statusesInstance', statusesInstance);

    fetch();

    return {
      companyStatuses,
    };
  },
};
</script>

<style lang="sass" scoped>
table
  width: 100%
  margin-top: 25px

  td
    padding: 10px 0

    &.expiry-date
      display: flex
      align-items: center
      color: #65b259

      &.expired
        color: #b2595a

      .icon
        margin-right: 10px

  tr:not(:last-child)
    td
      border-bottom: 1px solid rgba(58, 59, 61, 0.3)

.action-buttons
  position: absolute
  bottom: 15px

.action-button
  margin: 10px 0

.box-color
  height: 30px
  width: 100px
</style>
