var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "state-badge",
    class: `state-badge--${_vm.modifier}`
  }, [_vm.showIcon ? _c('div', {
    staticClass: "icon"
  }, [_c('feather-icon', {
    attrs: {
      "size": "21",
      "icon": `${_vm.icon}Icon`
    }
  })], 1) : _vm._e(), _vm.showText ? _c('div', {
    staticClass: "text"
  }, [_vm._v(_vm._s(_vm.text ? _vm.text : _vm.$t(_vm.translation)))]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }