import ApiClient from '@/services/api';

const urls = {
  get: 'api/cadre/skill/index',
  delete: 'api/cadre/skill/:id',
  update: 'api/cadre/skill/:id',
  create: 'api/cadre/skill',
};

export default {
  namespaced: true,

  state: {
    skills: [],
  },

  getters: {
    all: (state) => state.skills,
    byId: (state) => (id) => state.skills.find((skill) => skill.id === id),
  },

  mutations: {
    SET_SKILLS(state, skills) {
      state.skills = skills;
    },
    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
    ADD_SKILL(state, skill) {
      state.skills = [...state.skills, skill];
    },
    UPDATE_SKILL(state, skill) {
      const newSkills = state.skills
        .map((current) => {
          if (current.id !== skill.id) return current;
          return skill;
        });

      state.skills = newSkills;
    },
    REMOVE_SKILL(state, skill) {
      const newSkills = state.skills.filter((current) => current.id !== skill.id);
      state.skills = [...newSkills];
    },
  },

  actions: {
    /**
     * Fetch skills list
     * @action fetch=skills
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if skills exist
     * @returns {Promise}
     */
    fetch({ state, commit }, force = true) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(urls.get);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_SKILLS', res.data.data);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },

    create({ commit }, data) {
      const request = ApiClient.post(urls.create, data);

      request.then((res) => {
        if (res.status !== 200) return;
        commit('ADD_SKILL', res.data.data);
      });

      return request;
    },

    /**
     * Remove skill
     * @action delete=skills
     * @param {ActionContext} [vuexContext]
     * @param {Object} skill - skill object
     * @returns {Promise}
     */
    delete({ commit }, skill) {
      const url = urls.delete.replace(':id', skill.id);
      const promise = ApiClient.delete(url);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('REMOVE_SKILL', skill);
      });
      return promise;
    },

    /**
     * Update skill
     * @action update=skills
     * @param {ActionContext} [vuexContext]
     * @param {Object} skill - skill object
     * @returns {Promise}
     */
    update({ commit }, skill) {
      const url = urls.update.replace(':id', skill.id);
      const promise = ApiClient.put(url, skill);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('UPDATE_SKILL', skill);
      });
      return promise;
    },
  },
};
