/* eslint-disable import/prefer-default-export */
import store from '@/store';
import Vue from 'vue';

/**
 * Check whether `name` exists in
 * a list of enabled integrations
 */
export const integrationEnabled = (name) => {
  const integrations = store.getters['app/enabledModuleIntegrations'];
  return integrations.some((integration) => integration.name === name);
};

Vue.mixin({
  methods: {
    $integrated: integrationEnabled,
  },
});
