<template>

  <div
    class="state-badge"
    :class="`state-badge--${modifier}`"
    >

    <div class="icon" v-if="showIcon">
      <feather-icon size="21" :icon="`${icon}Icon`" />
    </div>

    <div class="text" v-if="showText">{{ text ? text : $t(translation) }}</div>
  </div>

</template>

<script>
import { computed } from 'vue';

/**
 * Simple Component displaying yes/no values
 */
export default {
  props: {
    state: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const icon = computed(() => (props.state ? 'CheckCircle' : 'XCircle'));
    const translation = computed(() => (props.state ? 'global.text.yes' : 'global.text.no'));
    const modifier = computed(() => (props.state ? 'positive' : 'negative'));

    return {
      icon,
      translation,
      modifier,
    };
  },
};
</script>

<style lang="sass" scoped>

.state-badge
  display: flex
  align-items: center

  &--positive
    color: #31bf5e

  &--negative
    color: #ed3131

  .icon
    margin-right: 7px

</style>
