var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vs-permission-editor"
  }, [Object.entries(_vm.categorized).length === 0 ? _c('vs-loader', {
    attrs: {
      "text": "Przygotowywanie uprawnień"
    }
  }) : _c('b-form-checkbox', {
    staticClass: "toggle-settings",
    attrs: {
      "switch": "",
      "size": "md"
    },
    model: {
      value: _vm.showSettingsOnly,
      callback: function ($$v) {
        _vm.showSettingsOnly = $$v;
      },
      expression: "showSettingsOnly"
    }
  }, [_vm._v(" Pokaż tylko dotyczące ustawień aplikacji ")]), _vm._l(Object.entries(_vm.categorized).map(function (_ref) {
    var a = _ref[1];
    return a;
  }).sort(function (a, b) {
    var aIdx = _vm.moduleOrder.indexOf(b.name);
    var bIdx = _vm.moduleOrder.indexOf(a.name);
    if (aIdx < 0) return -1;
    if (bIdx < 0) return 1;
    return aIdx < bIdx;
  }), function (module) {
    return _c('vs-permission-editor-permission', {
      key: module.id,
      attrs: {
        "id": module.id,
        "value": module.state,
        "name": module.name,
        "children": Object.entries(module.children).map(function (_ref2) {
          var child = _ref2[1];
          return child;
        }),
        "noInherit": _vm.noInherit,
        "stickTop": _vm.stickTop,
        "forSettings": module.forSettings,
        "hideSwitchForSettings": _vm.showSettingsOnly
      },
      on: {
        "state:change": function (state, permissionId) {
          return _vm.changeState(state, permissionId, true);
        }
      }
    });
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }