/**
 * Store Module for managing examinations inside Cadre
 * examinations are fictional users
 */

import ApiClient from '@/services/api';

const urls = {
  get: 'api/cadre/medical-exam',
  getById: 'api/cadre/employee/:id/edit',
  create: 'api/cadre/employee',
  delete: 'api/cadre/employee/:id',
  update: 'api/cadre/employee/:id',
  getTypes: 'api/cadre/medical-exam/get-medical-exam-types',
};

export default {
  namespaced: true,

  state: {
    /** Examinations list */
    examinations: [],
    types: [],

    fetchParams: {
      columnFilters: {
        branches_id: null,
      },
    },

    /** Current promise returned by fetch action */
    fetchExaminationsPromise: null,
    fetchTypesPromise: null,
  },

  getters: {
    all: (state) => state.examinations,
    byId: (state) => (id) => state.examinations.find((e) => e.id === id),
  },

  mutations: {
    SET_EXAMINATIONS(state, examinations) {
      state.examinations = examinations;
    },
    SET_TYPES(state, types) {
      state.types = types;
    },
    SET_EXAMINATIONS_PROMISE(state, promise) {
      state.fetchExaminationsPromise = promise;
    },
    SET_TYPES_PROMISE(state, promise) {
      state.fetchTypesPromise = promise;
    },
    UPDATE_EXAMINATION(state, newExamination) {
      const newExaminations = state.examinations
        .map((examination) => {
          if (examination.id !== newExamination.id) return examination;
          return newExamination;
        });

      if (newExamination.id === state.current.id) {
        state.current = newExamination;
      }

      state.examinations = newExaminations;
    },
    REMOVE_EXAMINATION(state, examination) {
      const newExaminations = state.examinations.filter((current) => current.id !== examination.id);
      state.examinations = [...newExaminations];
    },
  },

  actions: {
    /**
     * Fetch examinations list
     * @action fetch=examinations
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if examinations exist
     * @returns {Promise}
     */
    fetch({ state, commit }, params, force = true) {
      if (state.examinations.length && !force) return state.examinations;
      if (state.fetchExaminationsPromise && !force) return state.fetchExaminationsPromise;

      const promise = ApiClient.get(urls.get, params);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_EXAMINATIONS', res.data.data.medicalExams);
      });

      commit('SET_EXAMINATIONS_PROMISE', promise);
      return promise;
    },

    /**
     * Fetch examination by id
     * @action fetch=examinations
     * @param {ActionContext} [vuexContext]
     * @param {Number} employeeId id of the examination
     * @param {Boolean} force Forces the fetch even if examinations exist
     * @returns {Promise}
     */
    fetchOne({ state, commit }, { employeeId = state.current?.id, force = false } = {}) {
      const id = Number(employeeId);

      if (state.current?.id === id && !force) return state.current;
      if (state.current?.id !== id) commit('UNSET_CURRENT');

      const url = urls.getById.replace(':id', id);
      const promise = ApiClient.get(url);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_CURRENT', res.data.data);
      });

      return promise;
    },

    /**
     * Create new examination
     * @action create=examinations
     * @param {ActionContext} [vuexContext]
     * @param {Object} examination - Examination object
     * @returns {Promise}
     */
    create({ commit }, examination) {
      const url = urls.create;

      // FIXME change it later
      const changeMeExamination = {
        ...examination,
      };

      const promise = ApiClient.post(url, changeMeExamination);

      promise.then(({ data }) => {
        commit('ADD_EXAMINATION', data.data);
      });
      return promise;
    },

    /**
     * Remove examination
     * @action delete=examinations
     * @param {ActionContext} [vuexContext]
     * @param {Object} examination - Examination object
     * @returns {Promise}
     */
    delete({ commit }, examination) {
      const url = urls.delete.replace(':id', examination.id);
      const promise = ApiClient.delete(url);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('REMOVE_EXAMINATION', examination);
      });
      return promise;
    },

    /**
     * Update examination
     * @action update=examinations
     * @param {ActionContext} [vuexContext]
     * @param {Object} examination - Examination object
     * @returns {Promise}
     */
    update({ commit }, examination) {
      const url = urls.update.replace(':id', examination.id);

      // FIXME change it later
      const changeMeExamination = {
        ...examination,
      };

      const promise = ApiClient.put(url, changeMeExamination);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('UPDATE_EXAMINATION', res?.data?.data);
      });
      return promise;
    },

    /**
     * Fetch types list
     * @action fetch=types
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if types exist
     * @returns {Promise}
     */
    fetchTypes({ state, commit }, params, force = true) {
      if (state.types.length && !force) return state.types;
      if (state.fetchTypesPromise && !force) return state.fetchTypesPromise;

      const promise = ApiClient.get(urls.getTypes, params);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_TYPES', Object.values(res.data.data));
      });

      commit('SET_TYPES_PROMISE', promise);
      return promise;
    },
  },
};
