<template>
  <header-section :title="`${hideHeader ? '' : 'Oś czasu'}`">
    <div class="timeline-wrapper">
      <app-timeline>
        <app-timeline-item
          v-for="item in items"
          :key="item.id"
          :user_id="item.user_id"
          :title="item.event"
          :time="item.created_at"
          >

          <div
            class="timeline-item-header d-flex flex-row justify-content-between mb-1 mb-sm-0"
            >
            <h6>
              <component :is="translation(item)" />
            </h6>
            <small
              class="timeline-item-time text-nowrap text-muted"
              v-text="date(item.created_at)"
              />
          </div>

          <user-label :id="item.user_id" />

        </app-timeline-item>
      </app-timeline>
    </div>
  </header-section>
</template>

<script>
import { mapGetters } from 'vuex';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import HeaderSection from '@/components/ui/HeaderSection.vue';
import UserLabel from '@/components/ui/UserLabel.vue';

import getEventComponent from '@/libs/events';

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    HeaderSection,
    UserLabel,
  },
  props: {
    items: Array,
    source: {
      type: String,
      default: 'tasks',
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    translation(item) {
      return getEventComponent(item);
    },
    taskEventTranslation(item) {
      if (item.event === 'created' && item.auditable_type === 'taskcomment') {
        return this.$t(`tasks.timeline.event.${item.event}.comment`);
      }

      if (item.event === 'updated' && item.new_values.status_id) {
        const statusObject = this.taskStatusById(item.new_values.status_id);
        const status = `<font color="${statusObject.color}">${statusObject.name}</font>`;
        return this.$t(`tasks.timeline.event.${item.event}.status_change`, { status });
      }

      if (item.event === 'updated' && item.new_values.assigned_id) {
        const oldUser = this.userById(item.old_values.assigned_id).name;
        const newUser = this.userById(item.new_values.assigned_id).name;
        return this.$t(`tasks.timeline.event.${item.event}.assignee_change`, { newUser, oldUser });
      }

      if (item.event === 'updated' && item.new_values.termination_date) {
        const oldDate = item.old_values.termination_date;
        const newDate = item.new_values.termination_date;
        return this.$t(`tasks.timeline.event.${item.event}.deadline_change`, { newDate, oldDate });
      }

      return this.$t(`tasks.timeline.event.${item.event}.default`);
    },

    orderEventTranslation(item) {
      if (item.event === 'created' && item.auditable_type === 'taskcomment') {
        return this.$t(`orders.timeline.event.${item.event}.comment`);
      }

      if (item.event === 'updated' && item.new_values.status_id) {
        const statusObject = this.orderStatusById(item.new_values.status_id);
        const status = `<font color="${statusObject.color}">${statusObject.name}</font>`;
        return this.$t(`orders.timeline.event.${item.event}.status_change`, { status });
      }

      if (item.event === 'updated' && item.new_values.assigned_id) {
        const oldUser = this.userById(item.old_values.assigned_id).name;
        const newUser = this.userById(item.new_values.assigned_id).name;
        return this.$t(`orders.timeline.event.${item.event}.assignee_change`, { newUser, oldUser });
      }

      if (item.event === 'updated' && item.new_values.termination_date) {
        const oldDate = item.old_values.termination_date;
        const newDate = item.new_values.termination_date;
        return this.$t(`orders.timeline.event.${item.event}.deadline_change`, { newDate, oldDate });
      }

      return this.$t(`orders.timeline.event.${item.event}.default`);
    },
    date(time) {
      const date = new Date(time);
      return date.toLocaleString();
    },
  },
  computed: {
    ...mapGetters({
      taskStatusById: 'tasks/statuses/byId',
      orderStatusById: 'orders/statuses/byId',
      userById: 'users/get',
    }),
  },
};
</script>

<style lang="sass" scoped>
.timeline-wrapper
  margin-top: 35px

  .timeline-item-header
    gap: 10px
</style>
