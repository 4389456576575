<template>
  <div class="edit-modal">
    <b-modal
      id="modal-settings-base-category-edit"
      :title="$t('base.category.modal.edit.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('base.category.modal.edit.button.cancel')"
      :ok-title="$t('base.category.modal.edit.button.ok')"
      size="lg"

      @ok.prevent="editProducer"
      v-model="modalEditVisible"

      no-close-on-backdrop
      >
      <vs-loader full compact
        text="Aktualizowanie producenta..."
        v-if="isLoading"
      />

      <b-card-text>
        <settings-tab-base-producer-form/>
      </b-card-text>

      <template #modal-footer="{ ok, cancel }">
        <vs-modal-footer
          disable-on-loading
          :is-loading="isLoading"

          :text-cancel="$t('base.producer.modal.edit.button.cancel')"
          :text-ok="$t('base.producer.modal.edit.button.ok')"

          @cancel="cancel"
          @ok="ok"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCardText } from 'bootstrap-vue';
import { inject } from 'vue';
import SettingsTabBaseProducerForm
  from '@/components/views/settings/content-tabs/base/forms/producer/SettingsTabBaseProducerForm.vue';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';

export default {
  name: 'SettingsTabBaseProducerModalEdit',
  setup() {
    const { editProducer, isLoading, modalEditVisible } = inject('producersInstance');

    return { editProducer, isLoading, modalEditVisible };
  },
  components: {
    SettingsTabBaseProducerForm,
    BModal,
    BCardText,
    VsModalFooter,
    VsLoader,
  },
};
</script>
