import { defineStore } from 'pinia';
import useRemoteResource from '@/hooks/useRemoteResource';
import useCoreApi from '@/hooks/useCoreApi';

const { unit } = useCoreApi();

export default defineStore('coreUnit', () => {
  const { state, fetch, fetched } = useRemoteResource('core.unit', unit.fetch);

  const getShortcode = (unitId) => {
    const foundUnit = state.value.find((u) => u.id === unitId);
    return foundUnit?.code ?? '-';
  };

  return {
    state,
    fetch,
    fetched,
    getShortcode,
  };
});
