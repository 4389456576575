var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-modal"
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-cadre-employment-form-add",
      "title": _vm.$t('settings.cadre.employment.add.title'),
      "cancel-variant": "outline-secondary",
      "cancel-title": _vm.$t('settings.cadre.employment.button.cancel'),
      "ok-title": _vm.$t('settings.cadre.employment.button.save'),
      "size": "lg"
    },
    on: {
      "ok": _vm.createEmploymentForm
    }
  }, [_c('b-card-text', [_c('employment-form', {
    model: {
      value: _vm.employment,
      callback: function ($$v) {
        _vm.employment = $$v;
      },
      expression: "employment"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }