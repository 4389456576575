import ApiClient from '@/services/api';

const urls = {
  get: 'api/cadre/position/index',
  create: 'api/cadre/position',
  delete: 'api/cadre/position/:id',
  update: 'api/cadre/position/:id',
};

export default {
  namespaced: true,

  state: {
    positions: [],
    fetchPromise: null,
  },

  getters: {
    all: (state) => state.positions,
    byId: (state) => (id) => state.positions.find((g) => g.id === id),
  },

  mutations: {
    SET_POSITIONS(state, positions) {
      state.positions = positions;
    },
    SET_PROMISE(state, promise) {
      state.fetchPromise = promise;
    },
    ADD_POSITION(state, position) {
      state.positions = [...state.positions, position];
    },
    UPDATE_POSITION(state, position) {
      const newPositions = state.positions
        .map((current) => {
          if (current.id !== position.id) return current;
          return position;
        });

      state.positions = newPositions;
    },
    REMOVE_POSITION(state, position) {
      const newPositions = state.positions.filter((current) => current.id !== position.id);
      state.positions = [...newPositions];
    },
  },

  actions: {
    /**
     * Fetch positions list
     * @action fetch=positions
     * @param {ActionContext} [vuexContext]
     * @param {Boolean} force Forces the fetch even if positions exist
     * @returns {Promise}
     */
    fetch({ state, commit }, force = false) {
      if (state.fetchPromise && !force) return state.fetchPromise;

      const promise = ApiClient.get(urls.get);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('SET_POSITIONS', res.data.data);
      });

      commit('SET_PROMISE', promise);
      return promise;
    },

    /**
     * Create position
     * @param commit
     * @param data
     * @returns {Promise<*>}
     */
    create({ commit }, data) {
      const request = ApiClient.post(urls.create, data);

      request.then((res) => {
        if (res.status !== 200) return;
        commit('ADD_POSITION', res.data.data);
      });

      return request;
    },

    /**
     * Remove position
     * @action delete=positions
     * @param {ActionContext} [vuexContext]
     * @param {Object} position - Position object
     * @returns {Promise}
     */
    delete({ commit }, position) {
      const url = urls.delete.replace(':id', position.id);
      const promise = ApiClient.delete(url);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('REMOVE_POSITION', position);
      });
      return promise;
    },

    /**
     * Update position
     * @action update=positions
     * @param {ActionContext} [vuexContext]
     * @param {Object} position - Position object
     * @returns {Promise}
     */
    update({ commit }, position) {
      const url = urls.update.replace(':id', position.id);
      const promise = ApiClient.put(url, position);

      promise.then((res) => {
        if (res.status !== 200) return;
        commit('UPDATE_POSITION', {
          ...res.data.data,
          actual_daily_cost: position.cadre_position_daily_cost,
        });
      });
      return promise;
    },
  },
};
