<template>
  <div class="profile-settings-devices">

    <vs-form-header
      withButton
      text="Powiązane urządzenia"
      button-text="+ Dodaj powiązane urządzenie"
      @action="modalAddAuthorizedDevice.visible = true"
    />

    <div class="devices-list">
      <div
        class="device"
        v-for="device in devices"
        :key="device.id"
      >
        <div class="device-category">
          <feather-icon
            icon="SmartphoneIcon"
          />
          Urządzenie mobilne
        </div>

        <div class="device-name">
          {{ device.name }}
        </div>

        <div class="device-description">

          <div class="device-description-parameter">
            <div class="device-description-parameter-header">
              Utworzono
            </div>
            <div class="device-description-parameter-content">
              {{ formatDate.deviceDate(device.created_at) }}
            </div>
          </div>

          <div
            class="device-description-parameter"
            v-if="device.updated_at !== device.created_at"
            >
            <div class="device-description-parameter-header">
              Zmieniono
            </div>
            <div class="device-description-parameter-content">
              {{ formatDate.deviceDate(device.updated_at) }}
            </div>
          </div>

        </div>

        <div class="device-buttons">

          <vs-button
            vs-variant="link"
            @click="() => openConnectModal(device)"
          >
            <feather-icon icon="SidebarIcon" />
            Kod autoryzacyjny
          </vs-button>

          <vs-button
            vs-variant="link"
            @click="() => openEditModal(device)"
          >
            <feather-icon icon="Edit2Icon" />
            Zmień nazwę
          </vs-button>

          <vs-button
            vs-variant="link"
            @click="() => deleteDevice(device)"
          >
            <feather-icon icon="Trash2Icon" />
            Usuń urządzenie
          </vs-button>

        </div>

      </div>
    </div>

    <profile-settings-devices-add-modal
      @success="fetchDevices"
    />
    <profile-settings-devices-edit-modal
      @success="updateDevice"
    />
    <profile-settings-devices-connect-modal
      @success="updateDevice"
    />

  </div>
</template>

<script>
import useCoreApi from '@/hooks/useCoreApi';
import useCoreModals from '@/hooks/useCoreModals';
import VsFormHeader from '@/components/ui/vs-form/VsFormHeader.vue';
import { ref } from 'vue';
import showToast from '@/libs/toasts';
import { AuthorizedDevicesDeleteError, AuthorizedDevicesDeleteSuccess, AuthorizedDevicesFetchError } from '@/libs/toasts/feedback/module/core';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import formatDate from '@/libs/date-formatter';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import ProfileSettingsDevicesAddModal from './modal/ProfileSettingsDevicesAddModal.vue';
import ProfileSettingsDevicesEditModal from './modal/ProfileSettingsDevicesEditModal.vue';
import ProfileSettingsDevicesConnectModal from './modal/ProfileSettingsDevicesConnectModal.vue';

export default {
  name: 'ProfileSettingsDevices',
  setup() {
    const devices = ref([]);

    const fetchDevices = async () => {
      try {
        const { data } = await useCoreApi()
          .fetchAuthorizedDevices();

        devices.value = data.data;
      } catch (err) {
        showToast(AuthorizedDevicesFetchError);
      }
    };

    fetchDevices();

    const {
      modalAddAuthorizedDevice,
      modalConnectAuthorizedDevice,
      modalEditAuthorizedDevice,
    } = useCoreModals();

    const openEditModal = (device) => {
      modalEditAuthorizedDevice.value.data = { ...device };
      modalEditAuthorizedDevice.value.visible = true;
    };

    const openConnectModal = (device) => {
      modalConnectAuthorizedDevice.value.data = { ...device };
      modalConnectAuthorizedDevice.value.visible = true;
    };

    const updateDevice = (updatedDevice) => {
      devices.value = devices.value
        .map((device) => ((device.id !== updatedDevice.id) ? device : updatedDevice));
    };

    const deleteDevice = async (device) => {
      const confirmation = await deleteModal({
        title: i18n.t('profile.settings.device.modal.delete.title'),
        text: i18n.t('profile.settings.device.modal.delete.text', {
          name: device.name,
        }),
      });

      if (!confirmation) return false;

      const req = useCoreApi()
        .deleteAuthorizedDevice(device.id)
        .then(() => {
          showToast(AuthorizedDevicesDeleteSuccess, { name: device.name });

          devices.value = devices.value
            .filter((d) => d.id !== device.id);
        })
        .catch(() => {
          showToast(AuthorizedDevicesDeleteError);
        });

      return req;
    };

    return {
      devices,
      modalAddAuthorizedDevice,
      openEditModal,
      openConnectModal,
      formatDate,
      updateDevice,
      fetchDevices,
      deleteDevice,
    };
  },
  components: {
    ProfileSettingsDevicesAddModal,
    VsFormHeader,
    VsButton,
    ProfileSettingsDevicesEditModal,
    ProfileSettingsDevicesConnectModal,
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/scss/variables/_variables'

.devices-list
  display: grid
  grid-template-columns: repeat(4, 1fr)
  column-gap: 10px
  row-gap: 10px

  .device
    border: 1px solid var(--vs-card-border)
    border-radius: 5px
    padding: 20px
    display: flex
    flex-direction: column
    gap: 5px

    &-category
      display: flex
      align-items: center
      gap: 5px
      font-size: 10pt

    &-name
      font-size: 14pt
      font-weight: 500

    &-description
      padding-top: 10px
      display: flex
      flex-direction: column
      gap: 10px

      &-parameter
        &-header
          opacity: 0.7
          font-size: 10pt

        &-content
          font-size: 12pt

    &-buttons
      padding-top: 20px
      margin-top: auto
      display: flex
      flex-direction: column
      align-items: flex-start
      gap: 0px
      color: var(--vs-primary)

      .vs-button::v-deep
        padding: 5px 0 5px 0

        &:hover
          opacity: 0.7

        .vs-button-content
          display: flex
          align-items: center
          gap: 10px
</style>
