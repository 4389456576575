<template>
  <!--
    Custom wysiwyg component.
    TODO fix responsiveness for buttons
    TODO add editor styling
  -->
  <div class="vs-wysiwyg">
    <div v-if="editor" class="editor-button-pane">
      <div class="button-group">
        <pane-button
          :tooltip="$t('component.editor.button.bold')"
          @click="editor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }">
          <feather-icon icon="BoldIcon" />
        </pane-button>
        <pane-button
          :tooltip="$t('component.editor.button.italic')"
          @click="editor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }">
          <feather-icon icon="ItalicIcon" />
        </pane-button>
        <pane-button
          :tooltip="$t('component.editor.button.strike')"
          @click="editor.chain().focus().toggleStrike().run()"
          :class="{ 'is-active': editor.isActive('strike') }">
          <feather-icon icon="MinusCircleIcon" />
        </pane-button>
        <pane-button
          :tooltip="$t('component.editor.button.code')"
          @click="editor.chain().focus().toggleCode().run()"
          :class="{ 'is-active': editor.isActive('code') }">
          <feather-icon icon="CodeIcon" />
        </pane-button>
      </div>
      <div class="button-group">
        <pane-button
          :tooltip="$t('component.editor.button.paragraph')"
          @click="editor.chain().focus().setParagraph().run()"
          :class="{ 'is-active': editor.isActive('paragraph') }">
          <feather-icon icon="TypeIcon" />
        </pane-button>
        <pane-button
          :tooltip="$t('component.editor.button.heading1')"
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
          h1
        </pane-button>
        <pane-button
          :tooltip="$t('component.editor.button.heading2')"
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
          h2
        </pane-button>
        <pane-button
          :tooltip="$t('component.editor.button.bulletList')"
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }">
          <feather-icon icon="ListIcon" />
        </pane-button>
        <pane-button
          :tooltip="$t('component.editor.button.orderedList')"
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }">
          ol
        </pane-button>
      </div>
      <div class="button-group">
        <pane-button
          :tooltip="$t('component.editor.button.blockquote')"
          @click="editor.chain().focus().toggleBlockquote().run()"
          :class="{ 'is-active': editor.isActive('blockquote') }">
          <feather-icon icon="Edit3Icon" />
        </pane-button>
        <pane-button
          :tooltip="$t('component.editor.button.horizontalRule')"
          @click="editor.chain().focus().setHorizontalRule().run()">
          hr
        </pane-button>
        <pane-button
          :tooltip="$t('component.editor.button.hardBreak')"
          @click="editor.chain().focus().setHardBreak().run()">
          <feather-icon icon="CornerDownLeftIcon" />
        </pane-button>
      </div>
      <div class="button-group right-aligned">
        <pane-button
          :tooltip="$t('component.editor.button.undo')"
          @click="editor.chain().focus().undo().run()">
          <feather-icon icon="CornerUpLeftIcon" />
        </pane-button>
        <pane-button
          :tooltip="$t('component.editor.button.redo')"
          @click="editor.chain().focus().redo().run()">
          <feather-icon icon="CornerUpRightIcon" />
        </pane-button>
      </div>
    </div>
    <editor-content :editor="editor"/>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import PaneButton from './PaneButton.vue';

export default {
  components: {
    EditorContent,
    PaneButton,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  watch: {
    value(newValue) {
      const isSame = this.editor.getHTML() === newValue;

      if (isSame) return;

      this.editor.commands.setContent(newValue, false);
    },
  },
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      parseOptions: {
        preserveWhitespace: 'full',
      },
      extensions: [
        StarterKit,
      ],
      onUpdate: () => {
        let contents = this.editor.getHTML();
        if (contents === '<p></p>') {
          contents = '';
        }
        this.$emit('input', contents);
      },
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style lang="sass" scoped>
.vs-wysiwyg::v-deep
  width: 100%
  border: 1px solid rgba(0, 0, 0, 10%)
  border-radius: 5px

  .ProseMirror
    padding: 10px 10px 0 10px
    overflow: hidden
    white-space: pre-wrap
    word-wrap: break-word

    &-focused
      outline: none

    > * + *
      margin-top: 0.75rem

.editor-button-pane
  padding: 4px 3px
  border-bottom: 1px solid rgba(0, 0, 0, 10%)
  display: flex
  align-items: center
  background: rgba(0, 0, 0, 3%)

  .button-group
    display: flex
    align-items: center

    &:not(:last-child):after
      content: '|'
      margin: 0 10px
      opacity: 0.3

    &.right-aligned
      margin-left: auto
</style>
