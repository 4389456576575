import { parse } from 'node-html-parser';
import Section from '../Section';

// to be replaced with i18n
const translations = {
  payment_terms: 'Warunki płatności',
  realization_at: 'Termin realizacji',
  delivery_place: 'Miejsce dostawy',
  transport: 'Transport',
  contact_person: 'Osoba do kontaktu',
};

class PaymentTerms extends Section {
  constructor(composer) {
    super('Invoice Payment Terms', composer);
  }

  generate() {
    const { layout, content } = this.composer;
    this.appendSectionHeader(
      'Warunki zamówienia',
      layout.gutters.x,
      layout.currentHeight,
    );

    const rows = Object.keys(translations)
      .map((term) => {
        let text = content[term];
        if (text) {
          const stripped = parse(content[term]);
          text = stripped.rawText;
        }
        return [`${translations[term]}:`, text];
      });

    this.composer.doc.autoTable({
      body: rows,
      headStyles: {
        font: 'OpenSans-Medium',
      },
      styles: {
        font: 'OpenSans-Light',
        fontSize: layout.fontSize.order.table,
        minCellWidth: 32,
        cellPadding: 1,
      },
      theme: 'plain',
      margin: layout.gutters.x + 2,
      startY: layout.currentHeight,
      didDrawCell(HookData) {
        layout.currentHeight = HookData.cursor.y + 3;
      },
    });
  }
}

export default PaymentTerms;
