<template>
  <b-card>
    <vs-loader
      v-if="this.showLoader"
      :text="$t('tasks.details.message.loading')"
    />

    <span v-else-if="!task">
      {{ $t('tasks.error.taskNotFound') }}
      <br><br>
      <router-link :to="{ name: 'tasks' }" class="close-details">
        {{ $t('tasks.details.button.back') }}
      </router-link>
    </span>

    <div class="task-details" v-else-if="task">

      <router-link :to="{ name: 'tasks' }" class="back-link">
        {{ $t('tasks.details.button.back') }}
      </router-link>

      <h3 class="title">
        <task-label :id="task.id" :title="task.title" />
        <router-link :to="{ name: 'tasks' }" class="close-details">
          <feather-icon icon="XIcon" size="24"/>
        </router-link>
      </h3>

      <div class="buttons">
        <div class="group" v-if="task.created_by_id === user.id">
          <widget-card-controls
            editPermission="tasks.task.update"
            deletePermission="tasks.task.destroy"
            @edit="openEditModal"
            @delete="deleteTask"
          />
        </div>

        <div class="group">
          <status-dropdown
            :currentStatusId="task.status_id"
            @pick="changeStatus"
            v-can:change_status="'tasks.task'"
            />
        </div>
      </div>

      <div class="details">
        <table>
          <tr>
            <td>{{ $t('tasks.details.field.priority') }}</td>
            <td>
              <priority-label :id="task.priority_id" />
            </td>
          </tr>
          <tr>
            <td>{{ $t('tasks.details.field.status') }}</td>
            <td>
              <div class="status">
                <status-badge v-if="task.status_id" :status_id="task.status_id" />
              </div>
            </td>
          </tr>
          <tr>
            <td>{{ $t('tasks.details.field.assignedTo') }}</td>
            <td>
              <user-label withAvatar :id="task.assigned_id"/>
            </td>
          </tr>
          <tr>
            <td>{{ $t('tasks.details.field.reporter') }}</td>
            <td>
              <user-label withAvatar :id="task.created_by_id"/>
            </td>
          </tr>
          <tr>
            <td>{{ $t('tasks.details.field.deadline') }}</td>
            <td>{{ formatDate.taskDeadline(task.termination_date) }}</td>
          </tr>
        </table>
    </div>
      <header-section :title="$t('tasks.details.field.description')">
        <p class="description"
          v-if="task.description"
          v-html="task.description"
        >
        </p>
        <span class="noDescriptionInfo" v-else>{{ $t('tasks.edit.noDescription') }}</span>
      </header-section>

      <hr>

      <attachments
        :items="task.files"
        :uploading="uploading"
        @add="createAttachment"
        @remove="removeAttachment"
        :noAccessText="$t('tasks.details.message.noAttachmentAccess')"

        :canUpload="$store.getters['auth/userHasPermission']('upload', 'tasks.media')"
        :canDelete="$store.getters['auth/userHasPermission']('delete', 'tasks.media')"
      />

      <hr>

      <comments
        :items="comments"
        @add="createComment"
        @edit="editComment"
        @remove="removeComment"

        :canAdd="$store.getters['auth/userHasPermission']('store', 'tasks.comment')"
        :canDelete="$store.getters['auth/userHasPermission']('destroy', 'tasks.comment')"
        :canUpdate="$store.getters['auth/userHasPermission']('update', 'tasks.comment')"
      />

      <hr>

      <timeline v-if="timeline" :items="timeline"/>
    </div>
  </b-card>
</template>

<script>
import formatDate from '@/libs/date-formatter';
import { mapGetters } from 'vuex';
import { deleteModal } from '@/libs/modals';
import { BCard } from 'bootstrap-vue';
import PseudoApi from '@/services/pseudoapi';
import Timeline from '@/components/ui/timeline/Timeline.vue';
import StatusBadge from '@/components/ui/status-badge/StatusBadge.vue';
import Attachments from '@/components/ui/attachments/Attachments.vue';
import Comments from '@/components/ui/comments/Comments.vue';
import HeaderSection from '@/components/ui/HeaderSection.vue';
import PriorityLabel from '@/components/ui/priority-label/PriorityLabel.vue';
import TaskLabel from '@/components/views/tasks/Label.vue';
import UserLabel from '@/components/ui/UserLabel.vue';
import StatusDropdown from '@/components/ui/StatusDropdown.vue';

import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';

import {
  AttachmentCreateSuccess,
  AttachmentCreateError,
  AttachmentSizeError,
  AttachmentDeleteSuccess,
  AttachmentDeleteError,
  CommentCreateSuccess,
  CommentEditSuccess,
  CommentCreateError,
  CommentEditError,
  CommentDeleteSuccess,
  CommentDeleteError,
  StatusChangeSuccess,
  StatusChangeError,
  FetchTaskError,
} from '@feedback/module/tasks';
import WidgetCardControls from '@/components/ui/WidgetCardControls.vue';
import useTasksModals from '@/hooks/tasks/useTasksModals';
import store from '@/store';

export default {
  components: {
    BCard,
    Attachments,
    StatusBadge,
    Comments,
    HeaderSection,
    Timeline,
    PriorityLabel,
    UserLabel,
    VsLoader,
    TaskLabel,
    StatusDropdown,
    WidgetCardControls,
  },
  setup() {
    const { modalEditTask } = useTasksModals();

    const openEditModal = () => {
      modalEditTask.value.open(store.getters['tasks/current']);
    };

    return { openEditModal };
  },
  data() {
    return {
      formatDate,
      uploading: false,
      showLoader: true,
      priority: PseudoApi.get('priorityLabel').priorityLabel,
      statusBadge: PseudoApi.get('statusBadge').statusBadge,
    };
  },
  computed: {
    ...mapGetters({
      tasks: 'tasks/all',
      task: 'tasks/current',
      users: 'users/all',
      user: 'auth/user',
      timeline: 'tasks/logs/current',
      comments: 'tasks/comments/current',
    }),
  },
  mounted() {
    this.updateTask();
  },
  methods: {
    changeStatus(id) {
      this.$store.dispatch('tasks/updateStatus', {
        status_id: id,
        task_id: this.task.id,
      }).then(() => {
        this.$showFeedback(StatusChangeSuccess);
        this.$store.dispatch('tasks/logs/fetch', this.task.id);
      }).catch(() => {
        this.$showFeedback(StatusChangeError);
      });
    },
    // timeout for presentation purposes
    updateTask() {
      if (!this.$route.params.id) return;
      this.$store.dispatch('tasks/fetchById', this.$route.params.id)
        .then(() => {
          this.showLoader = false;
        })
        .catch(() => {
          this.showLoader = false;
          this.$router.push({ name: 'tasks' });
          this.$showFeedback(FetchTaskError);
        });
    },
    createComment(comment) {
      this.$store.dispatch('tasks/comments/create', {
        ...comment,
        task_id: this.task.id,
      }).then(() => {
        this.$showFeedback(CommentCreateSuccess);
        this.$store.dispatch('tasks/logs/fetch', this.task.id);
      }).catch(() => {
        this.$showFeedback(CommentCreateError);
      });
    },
    editComment(comment) {
      return this.$store.dispatch('tasks/comments/edit', {
        ...comment,
        task_id: this.task.id,
      })
        .then(() => {
          this.$showFeedback(CommentEditSuccess);
        })
        .catch(() => {
          this.$showFeedback(CommentEditError);
        });
    },
    createAttachment(attachment) {
      this.uploading = true;

      this.$store.dispatch('tasks/attachments/create', {
        ...attachment,
        task_id: this.task.id,
      }).then(() => {
        this.$showFeedback(AttachmentCreateSuccess);
        this.uploading = false;
      }).catch(({ response }) => {
        if (response.status === 413 || response.status === 422) {
          this.$showFeedback(AttachmentSizeError);
        } else {
          this.$showFeedback(AttachmentCreateError);
        }
        this.uploading = false;
      });
    },
    removeAttachment(id) {
      return this.$store.dispatch('tasks/attachments/remove', id)
        .then(() => {
          this.$showFeedback(AttachmentDeleteSuccess);
        })
        .catch(() => {
          this.$showFeedback(AttachmentDeleteError);
        });
    },
    removeComment(id) {
      this.$store.dispatch('tasks/comments/remove', id)
        .then(() => {
          this.$showFeedback(CommentDeleteSuccess);
        })
        .catch(() => {
          this.$showFeedback(CommentDeleteError);
        });
    },
    async deleteTask() {
      const confirmation = await deleteModal({
        title: this.$t('tasks.details.modal.delete.title'),
        text: this.$t('tasks.details.modal.delete.text',
          { task: this.task.title }),
      });

      if (!confirmation) return false;

      const response = this.$store.dispatch('tasks/remove', this.$route.params.id);

      response.then((res) => {
        if (!res.status === 200) return;

        this.$router.replace({ name: 'tasks' });
      });

      return true;
    },
    getUsername(id) {
      const [{ firstname, lastname }] = this.users.filter((usr) => usr.id === id);
      return `${firstname} ${lastname}`;
    },
  },
  watch: {
    task(oldTask, newTask) {
      if (!this.task || newTask.id === oldTask.id) return;
      this.$store.dispatch('tasks/logs/fetch', this.task.id);
    },
    $route() {
      this.updateTask();
    },
  },
};
</script>

<style lang="sass" scoped>

.back-link
  display: none

  @media screen and (max-width: 648px)
    display: block

.title
  font-weight: 600
  display: flex
  align-items: center

  .close-details
    margin-left: auto

    .icon
      font-size: 24pt

.buttons
  margin-top: 15px

  .group
    display: inline-block

    @media screen and (max-width: 450px)
      display: block

    &:not(:first-child):before
      content: '|'
      margin: 0 10px
      opacity: 0.2

      @media screen and (max-width: 450px)
        display: block
        content: ''
        width: 100%
        height: 1px
        background: black
        margin: 10px 0

    .btn
      font-size: 10pt

      &:not(:last-child)
        margin-right: 5px

.details
  margin-top: 15px

  table
    tr
      height: 30px

      td
        &:not(:first-child)
          padding-left: 20px
          font-weight: 500

.description
  margin-top: 15px

.header-section
  margin-top: 15px

.noDescriptionInfo
  font-style: italic
</style>
