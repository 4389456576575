export default {
  pageTitle: 'Zadania',
  feedback: {
    add: {
      success: {
        title: 'Zadanie zostało utworzone',
        description: 'Zadanie {prefix} zostało pomyślnie utworzone.',
      },
      error: {
        title: 'Zadanie nie zostało utworzone',
        description: 'Popraw oznakowane pola w formularzu i spróbuj ponownie.',
      },
    },
  },
  list: {
    pageTitle: 'Lista zadań',
    field: {
      task: 'Zadanie',
      assignedTo: 'Przypisane do',
      status: 'Status',
      priority: 'Priorytet',
      date: 'Termin',
      reporter: 'Zlecający',
      edit: 'Edycja',
    },
  },
  edit: {
    title: 'Edycja zadania',
    noDescription: 'Brak opisu',
    button: {
      cancel: 'Anuluj',
      save: 'Zapisz zmiany',
    },
    field: {
      name: 'Nazwa zadania',
      priority: 'Priorytet',
      assignedTo: 'Przypisane do',
      deadline: 'Termin wykonania',
      description: 'Opis zadania',
    },
  },
  add: {
    title: 'Dodaj zadanie',
    button: {
      cancel: 'Anuluj',
      save: 'Zapisz zmiany',
    },
    field: {
      name: 'Nazwa zadania',
      priority: 'Priorytet',
      assignedTo: 'Przypisane do',
      deadline: 'Termin wykonania',
      description: 'Opis zadania',
    },
  },
  timeline: {
    event: {
      created: {
        default: 'Utworzono nowe zadanie',
        comment: 'Dodano nowy komentarz do zadania',
      },
      updated: {
        default: 'Zaktualizowano treść zadania',
        status_change: 'Zmieniono status zadania na {status}',
        assignee_change: 'Zmieniono przypisanego użytkownika z {oldUser} na {newUser}',
        deadline_change: 'Zaktualizowano termin wykonania zadania',
      },
    },
  },
  details: {
    pageTitle: 'Szczegóły zadania',
    field: {
      priority: 'priorytet',
      status: 'status',
      assignedTo: 'przypisane do',
      reporter: 'zlecający',
      deadline: 'termin',

      description: 'Opis zadania',
      comments: 'Komentarze',
      timeline: 'Oś czasu',
    },
    status: {
      todo: 'Do zrobienia',
      inProgress: 'W toku',
      done: 'Zrobione',
    },
    modal: {
      delete: {
        title: 'Potwierdzanie usuwania zadania',
        text: 'Czy na pewno chcesz usunąć zadanie {task}?',
      },
    },
    message: {
      noDescription: 'Zadanie nie ma opisu.',
      noAttachmentAccess: 'Do tego pliku dostęp mają jedynie: przypisany do zadania oraz twórca zadania',
      loading: 'Wczytywanie szczegółów zadania',
    },
    button: {
      add: 'Dodaj',
      back: 'Powrót do listy zadań',
    },
  },
  error: {
    taskNotFound: 'Nie znaleziono takiego zadania.',
  },
};
