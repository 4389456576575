import { reactive, toRefs } from 'vue';

const baseStore = reactive({
  statuses: [],
  categories: [],
  producers: [],
  producersModels: [],
  groups: [],
  workTypes: [],
});

export default function useBaseStore() {
  return { ...toRefs(baseStore) };
}
