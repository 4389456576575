<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    ref="notificationBell"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="totalUnreadCount"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <vs-loader
      full
      v-if="isLoading"
    />

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('component.notificationBell.title.header') }}
        </h4>
        <span class="mark-read">
          <b-badge
            pill
            variant="light-primary"
            v-if="notifications.length > 0"
            @click="markBellAsRead"
          >
          {{ $t('component.notificationBell.button.markAllAsRead') }}
          </b-badge>
        </span>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >

      <div class="no-unread-notifications" v-if="notifications.length === 0">
        <feather-icon icon="CheckCircleIcon" class="icon" size="42" />
        {{ $t('component.notificationBell.title.noUnreadNotifications') }}
      </div>

      <!-- Account Notification -->
      <notification
        v-for="(notification, index) in notifications"
        :notification="notification"
        :key="index"
        @action="() => notificationClicked(notification)"
        />

    </vue-perfect-scrollbar>

    <!-- Bell Footer -->
    <li class="dropdown-menu-footer">
      <p v-if="notifications.length < totalUnreadCount">
        {{ $t('component.notificationBell.title.itemCount', {
          count: notifications.length,
          maxCount: totalUnreadCount,
        }) }}
      </p>
      <vs-button
        class="read-history-button"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        vs-variant="primary"
        @click="openNotificationHistory"
        >
        {{ $t('component.notificationBell.button.readHistory') }}
      </vs-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import { formatNotification } from '@/libs/notifications';
import Notification from '@/components/ui/notification/Notification.vue';
import {
  BNavItemDropdown, BBadge,
} from 'bootstrap-vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import { mapGetters } from 'vuex';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Ripple from 'vue-ripple-directive';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    VuePerfectScrollbar,

    Notification,
    VsButton,
    VsLoader,
  },
  data: () => ({
    dropdownOpened: false,
    isLoading: false,
  }),
  methods: {
    formatNotification,
    markBellAsRead() {
      this.isLoading = true;
      this.$store.dispatch('auth/notifications/markBellAsRead')
        .then(() => {
          this.isLoading = false;
        });
    },
    hideDropdown() { this.$refs.notificationBell.hide(); },
    notificationClicked(notification) {
      if (this.formatNotification(notification).action) {
        this.formatNotification(notification).action();
      }

      this.$store.dispatch('auth/notifications/markAsRead', notification);
      this.hideDropdown();
      return false;
    },
    openNotificationHistory() {
      this.hideDropdown();
      this.$router.push({ name: 'notifications' }).catch(() => {});
    },
  },
  computed: {
    ...mapGetters({
      notifications: 'auth/notifications/bell',
      totalUnreadCount: 'auth/notifications/unreadCount',
    }),
  },
  created() {
    this.$store.dispatch('auth/notifications/fetchUnread');
  },
  directives: {
    Ripple,
  },
  setup() {
    const perfectScrollbarSettings = {
      wheelPropagation: false,
    };

    return {
      perfectScrollbarSettings,
    };
  },
};
</script>

<style lang="sass" scoped>
.read-history-button
  width: 100%

.no-unread-notifications
  text-align: center
  padding: 10% 10%

  .icon
    display: block
    margin: 0 auto 20px auto
    opacity: 0.4

.mark-read
  cursor: pointer
</style>
