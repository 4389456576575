<template>
  <span
    class="user-label"
    :class="{ 'deleted': !!user.deleted_at, 'inline': inline }"
  >

    <avatar
      class="avatar"
      :user="user"
      v-if="withAvatar"
      :displayUserIcon="!isEmployee"
      :deleted="!!user.deleted_at"
    />

    <!-- Add correct routing when possible -->
    <router-link :to="{ name: 'cadre.employee.details', params: { id: user.id } }" v-if="showLink">
      <feather-icon icon="UserIcon" v-if="linkIconVisible" />
      {{ user.forename || '' }} {{ user.surname || '' }}
    </router-link>

    <span class="username" v-else>
      {{ user.forename || '' }} {{ user.surname || '' }}
    </span>

    <span class="extra-info" v-if="this.$slots['extra-info']">
      <slot name="extra-info" />
    </span>

    <span class="me" v-if="!isEmployee && showRelativeText && me.id === user.id">
      ({{ $t('component.userLabel.you') }})
    </span>

  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import Avatar from '@/components/ui/Avatar.vue';

export default {
  props: {
    id: Number,
    withAvatar: {
      type: Boolean,
      default: true,
    },
    showLink: {
      type: Boolean,
      default: () => false,
    },
    linkIconVisible: {
      type: Boolean,
      default: false,
    },
    /**
     * FIXME: component is being used as a label
     *        for both users and employees, this prop name
     *        and component name is misleading
     */
    isEmployee: {
      type: Boolean,
      default: () => false,
    },
    inline: {
      type: Boolean,
      default: () => false,
    },
    showRelativeText: {
      type: Boolean,
      default: () => true,
    },
    data: {
      type: Object,
      default: () => ({
        default: true,
        id: 0,
        name: 'username',
      }),
    },
  },
  components: {
    Avatar,
  },
  created() {
    if (this.id !== null && this.data.default) {
      this.$store.dispatch('users/fetch');
    }
  },
  computed: {
    ...mapGetters({
      userById: 'users/byId',
      me: 'auth/user',
    }),
    user() {
      if (!this.data.default) return this.data;

      return this.userById(this.id) || this.me || this.data;
    },
  },
};
</script>

<style lang="sass" scoped>
.user-label
  display: flex
  align-items: center

  &.inline
    display: inline-flex

  &.deleted
    .username
      color: #fa4141
      transition: 0.2s color ease-in-out

  .avatar
    margin-right: 10px

  .extra-info
    opacity: 0.5
    margin-left: 5px
.me
  margin-left: 3px
  opacity: 0.6
</style>
