import Section from '../Section';

class Body extends Section {
  constructor(composer) {
    super('Invoice Body', composer);
  }

  generate() {
    const { layout, content } = this.composer;

    const { email } = content.details.ordering;
    this.appendText(
      `Oczekujemy potwierdzenia przyjęcia naszego zamówienia na e‐mail ${email}`,
      layout.gutters.x,
      layout.currentHeight + 10,
      false,
    );

    layout.currentHeight += 10;
    const approvalMargin = layout.pageSize.width / 2;

    this.composer.doc.setFont('OpenSans-Medium', 'normal');
    this.appendText(
      'Zamówienie zatwierdził',
      approvalMargin,
      layout.currentHeight,
      false,
    );
    this.composer.restoreDefaultFont();
    const { forename, surname, role } = content.accepting;
    this.appendText(
      [
        `${forename || ''} ${surname || ''}`,
        role || '',
      ],
      approvalMargin,
      layout.currentHeight + 1,
      false,
    );
  }
}

export default Body;
