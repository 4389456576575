<template>

  <header-section
    :title="$t('component.comments.name')"
    :action="$t('component.comments.button.add')"
    @actionClick="openCommentEditor"
    :canAction="canAdd"
    >
    <b-collapse
      id="comment-collapse"
      class="mt-2"
      v-model="commentCollapseVisible"
      >
      <b-card
        :title="editorTranslations.title"
        class="m-0 p-0 border"
        >

        <b-form-group
          :label="$t('component.comments.field.content')"
          class="mt-1"
          >
          <editor
            v-model="newComment.content"
          />
        </b-form-group>

        <div class="comment-buttons">
          <vs-button
            vs-variant="light"
            @click="cancelEditing"
            >
            {{ editorTranslations.cancel }}
          </vs-button>
          <vs-button
            vs-variant="primary"
            class="vs-edit-button"
            @click="publishComment"
            >
            {{ editorTranslations.confirm }}
          </vs-button>
        </div>
      </b-card>
    </b-collapse>

    <app-collapse>
      <comment-item
        v-for="item in items"
        :key="item.id"
        :comment="item"

        @remove="removeComment"
        @edit="editComment"
        :canUpdate="canUpdate"
        :canDelete="canDelete"
        />
    </app-collapse>

  </header-section>

</template>

<script>
import { mapGetters } from 'vuex';
import { deleteModal } from '@/libs/modals';
import {
  BCollapse, BCard, BFormGroup,
} from 'bootstrap-vue';
import Editor from '@/components/ui/vs-wysiwyg/Editor.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import HeaderSection from '@/components/ui/HeaderSection.vue';
import CommentItem from '@/components/ui/comments/CommentItem.vue';

export default {
  props: {
    items: Array,
    canAdd: {
      type: Boolean,
      default: true,
    },
    canUpdate: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters({
      byId: 'tasks/comments/byId',
    }),
    editorTranslations() {
      if (this.newComment.id) {
        return {
          title: this.$t('component.comments.title.edit'),
          confirm: this.$t('component.comments.button.confirmEdit'),
          cancel: this.$t('component.comments.button.cancelEdit'),
        };
      }

      return {
        title: this.$t('component.comments.title.add'),
        confirm: this.$t('component.comments.button.confirmAdd'),
        cancel: this.$t('component.comments.button.cancelAdd'),
      };
    },
  },
  methods: {
    async removeComment(id) {
      const confirmation = await deleteModal({
        title: this.$t('component.comments.modal.delete.title'),
        text: this.$t('component.comments.modal.delete.text'),
      });

      if (!confirmation) return false;

      return this.$emit('remove', id);
    },
    openCommentEditor() {
      this.commentCollapseVisible = true;
    },
    cancelEditing() {
      this.commentCollapseVisible = false;
      this.newComment = {
        id: null,
        title: null,
        content: null,
      };
    },
    editComment(editing) {
      this.newComment.id = editing.id;
      this.newComment.content = editing.content;
      this.openCommentEditor();
    },
    publishComment() {
      const event = this.newComment.id ? 'edit' : 'add';
      this.$emit(event, this.newComment);
      this.cancelEditing();
    },
  },
  data() {
    return {
      commentCollapseVisible: false,
      newComment: {
        id: null,
        title: null,
        content: null,
      },
    };
  },
  components: {
    BCollapse,
    BCard,
    BFormGroup,
    Editor,
    VsButton,
    HeaderSection,
    CommentItem,
    AppCollapse,
  },
};
</script>

<style lang="sass" scoped>
.comment-buttons
  display: flex
  justify-content: end
  margin-top: 15px
  gap: 10px
</style>
