import i18n from '@/libs/i18n';
import { deleteModal } from '@/libs/modals';
import RemoteResourceManager from '@/libs/RemoteResourceManager';
import showToast from '@/libs/toasts';
import {
  CreateTechnologyError,
  CreateTechnologySuccess,
  DeleteTechnologyError,
  DeleteTechnologySuccess,
  FetchTechnologiesError,
  UpdateTechnologyError,
  UpdateTechnologySuccess,
} from '@/libs/toasts/feedback/module/base';
import { ref } from 'vue';
import useBaseApi from '../useBaseApi';

const technologies = ref([]);

export default function useBaseTechnology() {
  const modalAddVisible = ref(false);
  const modalEditVisible = ref(false);

  const form = ref({ name: '', color: '#F57C00' });

  const isLoading = ref(false);

  const fetchTechnologies = (force = false) => {
    if (technologies.value.length > 0 && !force) return technologies;

    const req = useBaseApi()
      .fetchTechnologies()
      .then(({ data }) => {
        technologies.value = data.data;
      })
      .catch(() => showToast(FetchTechnologiesError));

    return req;
  };

  const createTechnology = () => {
    isLoading.value = true;

    const req = useBaseApi()
      .createTechnology({ ...form.value })
      .then(({ data }) => {
        isLoading.value = false;
        fetchTechnologies(true);
        form.value = { name: '', color: '#F57C00' };

        showToast(CreateTechnologySuccess, { name: data.data.name });
        RemoteResourceManager.getInstance().notify('base.technology');
        modalAddVisible.value = false;
      })
      .catch(() => {
        isLoading.value = false;
        showToast(CreateTechnologyError);
      });

    return req;
  };

  const updateTechnology = () => {
    isLoading.value = true;

    const req = useBaseApi()
      .updateTechnology({ ...form.value })
      .then(({ data }) => {
        isLoading.value = false;
        fetchTechnologies(true);
        form.value = { name: '', color: '#F57C00' };

        showToast(UpdateTechnologySuccess, { name: data.data.name });
        RemoteResourceManager.getInstance().notify('base.technology');
        modalEditVisible.value = false;
      })
      .catch(() => {
        isLoading.value = false;
        showToast(UpdateTechnologyError);
      });

    return req;
  };

  const deleteTechnology = async (technology) => {
    const confirmation = await deleteModal({
      title: i18n.t('base.technology.modal.delete.title'),
      text: i18n.t('base.technology.modal.delete.text', {
        name: technology.name,
      }),
    });

    if (!confirmation) return false;

    const req = useBaseApi()
      .deleteTechnology(technology.id)
      .then(() => {
        fetchTechnologies(true);

        showToast(DeleteTechnologySuccess, { name: technology.name });
        RemoteResourceManager.getInstance().notify('base.technology');
        modalEditVisible.value = false;
      })
      .catch(() => showToast(DeleteTechnologyError));

    return req;
  };

  return {
    technologies,
    fetchTechnologies,
    isLoading,
    modalAddVisible,
    modalEditVisible,
    form,

    createTechnology,
    updateTechnology,
    deleteTechnology,
  };
}
