var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "attachments"
  }, [_vm.uploading ? _c('vs-loader', {
    attrs: {
      "full": ""
    }
  }) : _vm._e(), _c('header-section', {
    attrs: {
      "title": _vm.$t('component.attachments.name'),
      "action": _vm.$t('component.attachments.button.add'),
      "canAction": _vm.canUpload
    },
    on: {
      "actionClick": _vm.toggleAttachment
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('div', {
          staticClass: "attachments-header"
        }, [_c('h4', [_vm._v("Załączniki")]), _vm.additionalInfo ? _c('div', {
          staticClass: "additional-info"
        }, [_vm._v(" " + _vm._s(_vm.additionalInfo) + " ")]) : _vm._e()])];
      },
      proxy: true
    }])
  }, [_c('b-collapse', {
    staticClass: "mt-2",
    attrs: {
      "id": "attachment-collapse"
    },
    model: {
      value: _vm.attachmentCollapseVisible,
      callback: function ($$v) {
        _vm.attachmentCollapseVisible = $$v;
      },
      expression: "attachmentCollapseVisible"
    }
  }, [_c('b-card', {
    staticClass: "m-0 p-0 border",
    attrs: {
      "title": _vm.$t('component.attachments.title.add')
    }
  }, [_c('vs-file', {
    ref: "attachmentInput",
    model: {
      value: _vm.newAttachment.file,
      callback: function ($$v) {
        _vm.$set(_vm.newAttachment, "file", $$v);
      },
      expression: "newAttachment.file"
    }
  }), _c('div', {
    staticClass: "attachment-buttons"
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "light"
    },
    on: {
      "click": _vm.cancelEditing
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('component.attachments.button.cancel')) + " ")]), _c('vs-button', {
    staticClass: "vs-edit-button",
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.saveAttachment
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('component.attachments.button.addNew')) + " ")])], 1)], 1)], 1), _vm.useTabs ? _c('b-tabs', {
    model: {
      value: _vm.currentTab,
      callback: function ($$v) {
        _vm.currentTab = $$v;
      },
      expression: "currentTab"
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Bez kategorii",
      "active": ""
    }
  }), _c('b-tab', {
    attrs: {
      "title": "Oferta"
    }
  })], 1) : _vm._e(), _vm.attachments.length > 0 ? _c('table', [_c('tr', [_c('th', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.$t('component.attachments.field.name')) + " ")]), !_vm.hideAccessInfo ? _c('th', {
    staticClass: "access"
  }, [_vm._v(" " + _vm._s(_vm.$t('component.attachments.field.access')) + " ")]) : _vm._e(), !_vm.hideDate ? _c('th', {
    class: !_vm.canDelete && 'last-column'
  }, [_vm._v(" " + _vm._s(_vm.$t('component.attachments.field.date')) + " ")]) : _vm._e(), _vm.canDelete ? _c('th', {
    staticClass: "edit"
  }, [_vm._v(" " + _vm._s(_vm.$t('component.attachments.field.edit')) + " ")]) : _vm._e()]), _vm._l(_vm.attachments, function (item) {
    return _c('attachment-item', {
      key: item.id,
      attrs: {
        "attachment": item,
        "noAccessText": _vm.noAccessText,
        "canDelete": _vm.canDelete,
        "hideDate": _vm.hideDate,
        "hideAccessInfo": _vm.hideAccessInfo
      },
      on: {
        "remove": _vm.removeAttachment
      }
    });
  })], 2) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }