<template>
  <vs-modal
    @ok="createOrder"
    :modalInstance="modalCreateOrder"
  >
    <orders-order-form
      v-model="fields"
      :feedback="feedback"
    />
  </vs-modal>
</template>

<script>
import OrdersOrderForm from '@/components/views/orders/OrdersOrderForm.vue';
import {
  AddSuccess,
  ServerError,
  BadInputError,
} from '@feedback/module/orders';
import useOrdersModals from '@/hooks/orders/useOrdersModals';
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import store from '@/store';
import showToast from '@/libs/toasts';
import router from '@/router';
import { watch } from 'vue';
import formatDate from '@/libs/date-formatter';

export default {
  name: 'OrdersCreateModal',
  setup() {
    const {
      fields,
      feedback,
      areAllFieldsValid,
      assignServerSideErrors,
      nonEmptyFields,
      setFieldDefaults,
      resetFields,
      assignFields,
    } = useValidatedForm({
      _providerBranch: '',
      offer_number: 'required',
      title: 'required',
      priority_id: 'required',
      currency_id: 'required',
      provider: '',
      provider_id: 'required_if:_providerBranch',
      investor: '',
      investor_id: '',
      investment: '',
      investment_id: '',
      branch_id: 'required',
      ordered_at: 'required',
      assigned_id: 'required',
      accepting_id: 'required',
      comment: '',
      payment_terms: 'required',
      realization_at: '',
      delivery_place: '',
      transport: '',
      contact_person: 'required',
    });
    setFieldDefaults({
      assigned_id: store.getters['auth/user']?.id,
      priority_id: 2,
      ordered_at: formatDate.standard(Date.now()),
      realization_at: formatDate.standard(Date.now()),
    });

    const { modalCreateOrder } = useOrdersModals();

    watch(() => modalCreateOrder.value.visible, () => {
      resetFields();
      assignFields(modalCreateOrder.value.data);
    });

    const navigateToOrder = (id) => {
      router.push({ name: 'orders-details', params: { id } });
    };

    const createOrder = async () => {
      if (!(await areAllFieldsValid())) return;
      modalCreateOrder.value.isLoading = true;

      store.dispatch('orders/create', {
        ...nonEmptyFields.value,
        status_id: 1,
        created_by_id: store.getters['auth/user']?.id,
      })
        .then(({ data }) => {
          showToast(AddSuccess, {
            label: store.getters['orders/label'](data.data.id),
            name: data.data.title,
          }, () => navigateToOrder(data.data.id));
          modalCreateOrder.value.close();
        })
        .catch(({ response }) => {
          let Feedback = ServerError;

          if (response.data.errors) {
            Feedback = BadInputError;
          }

          showToast(Feedback, { id: null });
          assignServerSideErrors(response?.data?.errors);
        })
        .finally(() => {
          modalCreateOrder.value.isLoading = false;
        });
    };

    return {
      modalCreateOrder,
      fields,
      feedback,
      createOrder,
    };
  },
  components: {
    OrdersOrderForm,
    VsModal,
  },
};
</script>

<style lang="sass" scoped>
</style>
