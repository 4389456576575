import i18n from '@/libs/i18n';
import { reactive, toRefs } from 'vue';
import { vsModal } from '../investments/useInvestmentsModals';

const modals = reactive({
  modalCreateOrder: vsModal({
    text: {
      title: i18n.t('orders.add.title'),
      ok: i18n.t('orders.add.button.save'),
      cancel: i18n.t('orders.add.button.cancel'),
    },
  }),
  modalEditOrder: vsModal({
    text: {
      title: i18n.t('orders.edit.title'),
      ok: i18n.t('orders.edit.button.save'),
      cancel: i18n.t('orders.edit.button.cancel'),
    },
  }),
});

export default function useOrdersModals() {
  return { ...toRefs(modals) };
}
