import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import themes from './themes';

import auth from './auth';
import echo from './echo';

import users from './resources/users';
import currencies from './resources/core/currencies';
import units from './resources/core/units';
import branches from './resources/core/branches';
import priorities from './resources/core/priorities';

import tasks from './resources/tasks';
import orders from './resources/orders';
import cadre from './resources/cadre';
import crm from './resources/crm';
import base from './resources/base';
import investments from './resources/investments';

Vue.use(Vuex);

export default new Vuex.Store({
  actions: {
    reset({ dispatch }) {
      dispatch('users/reset');
      dispatch('auth/notifications/reset');
      dispatch('tasks/reset');
      dispatch('orders/reset');
      dispatch('auth/leaveEchoChannels');
    },
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
    themes,

    auth,
    echo,

    users,
    priorities,
    currencies,
    units,
    branches,

    tasks,
    orders,
    cadre,
    crm,
    base,
    investments,
  },
  // eslint-disable-next-line no-undef
  strict: process.env.DEV,
});
