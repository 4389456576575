import { ref } from 'vue';
import useBaseApi from '@/hooks/base/useBaseApi';
import { deleteModal } from '@/libs/modals';
import i18n from '@/libs/i18n';
import showToast from '@/libs/toasts';
import {
  CreateCategoryError,
  CreateCategorySuccess,
  DeleteCategoryError,
  DeleteCategorySuccess,
  UpdateCategoryError,
  UpdateCategorySuccess,
} from '@/libs/toasts/feedback/module/base';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

const useCategory = () => {
  const categories = ref([]);
  const category = ref({});

  const modalAddVisible = ref(false);
  const modalEditVisible = ref(false);

  const isLoading = ref(false);

  const fetch = () => {
    useBaseApi()
      .fetchIndexCategories()
      .then(({ data }) => {
        categories.value = data.data;
      })
      .catch(() => {});
  };

  const createCategory = () => {
    isLoading.value = true;

    const sendData = { ...category.value };
    if (!sendData.be_reported) {
      delete sendData.unit_id;
    }

    const req = useBaseApi()
      .createCategory(sendData)
      .then(({ data }) => {
        isLoading.value = false;
        modalAddVisible.value = false;

        showToast(CreateCategorySuccess, { name: data.data.name });
        RemoteResourceManager.getInstance().notify('base.category');
        category.value = {};
      })
      .catch(() => {
        isLoading.value = false;
        showToast(CreateCategoryError, { name: category.value.name });
      });

    return req;
  };

  const editCategory = () => {
    isLoading.value = true;

    const sendData = { ...category.value };
    if (!sendData.be_reported) {
      delete sendData.unit_id;
    }

    const req = useBaseApi()
      .updateCategory(sendData)
      .then(({ data }) => {
        isLoading.value = false;

        modalEditVisible.value = false;

        showToast(UpdateCategorySuccess, { name: data.data.name });
        RemoteResourceManager.getInstance().notify('base.category');
        category.value = {};
      })
      .catch(() => {
        isLoading.value = false;
        showToast(UpdateCategoryError, { name: category.value.name });
      });

    return req;
  };

  const deleteCategory = async (requested) => {
    const confirmation = await deleteModal({
      title: i18n.t('base.category.modal.delete.title'),
      text: i18n.t('base.category.modal.delete.text', {
        name: requested.name,
      }),
    });

    if (!confirmation) return false;

    return useBaseApi()
      .deleteCategory(requested.id)
      .then(() => {
        categories.value = categories.value.filter((s) => s.id !== requested.id);
        showToast(DeleteCategorySuccess, { name: requested.name });
        RemoteResourceManager.getInstance().notify('base.category');
      })
      .catch(() => showToast(DeleteCategoryError, { name: requested.name }));
  };

  const openAddModal = () => {
    category.value = {};
    modalAddVisible.value = true;
  };

  const openEditModal = (edited) => {
    category.value = { ...edited, base_resource_type_id: edited.type.slug };
    modalEditVisible.value = true;
  };

  return {
    categories,
    category,

    isLoading,
    modalAddVisible,
    modalEditVisible,

    fetch,
    openEditModal,
    openAddModal,
    createCategory,
    editCategory,
    deleteCategory,
  };
};

export default useCategory;
