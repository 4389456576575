<template>
  <abstract-module-label
    v-if="company && companyRoute"
    icon="PackageIcon"
    :to="{ name: companyRoute, params: { id: company.id } }"
    :name="company.name"
    :short="short"
  >
    <div v-if="!noBranch">
      {{ company.name }}, {{ branch }}
    </div>

    <div v-else>
      {{ company.name }}
    </div>

    <div v-if="!noPerson" class="crm-provider-label-person">
      ({{ person }})
    </div>
  </abstract-module-label>
</template>

<script>
import { computed } from 'vue';
import AbstractModuleLabel from '@/components/abstract/AbstractModuleLabel.vue';

export default {
  name: 'CrmProviderLabel',
  props: {
    // provider | client
    companyType: {
      type: String,
      default: 'provider',
    },

    // object taken from integrated field
    integrated: {
      type: Object,
      default: () => ({}),
    },
    noBranch: {
      type: Boolean,
      default: false,
    },
    noPerson: {
      type: Boolean,
      default: false,
    },
    short: Boolean,
  },
  setup(props) {
    const company = computed(() => props.integrated?.company_address?.company);
    const branch = computed(() => props.integrated?.company_address?.name);
    const person = computed(() => {
      if (!props.integrated.forename) return '';
      const { forename, surname } = props.integrated;

      return `${forename ?? ''} ${surname ?? ''}`;
    });

    const companyRoute = computed(() => {
      let namespace = null;

      switch (props.companyType) {
        case 'provider':
          namespace = 'providers';
          break;
        case 'client':
          namespace = 'clients';
          break;
        default:
          break;
      }

      if (!namespace) return null;
      return `crm.${namespace}.details`;
    });

    return {
      company, person, companyRoute, branch,
    };
  },
  components: {
    AbstractModuleLabel,
  },
};
</script>

<style lang="scss" scoped>
.crm-provider-label-person {
  color: var(--vs-color);
  font-weight: 400;
  white-space: nowrap;
}
</style>
