var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tasks"
  }, [_c('div', {
    staticClass: "listWrapper",
    class: {
      fullWidth: !_vm.detailsVisible()
    }
  }, [_c('tasks-list'), _c('tasks-edit-modal'), _c('tasks-create-modal')], 1), _c('div', {
    staticClass: "detailsColumn",
    class: {
      visible: _vm.detailsVisible()
    }
  }, [_c('tasks-details')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }