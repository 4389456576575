import StatusBadge from '@/components/ui/status-badge/StatusBadge.vue';
import UserLabel from '@/components/ui/UserLabel.vue';
import DefaultEventComponent from './default';

const TaskUpdatedStatus = {
  props: ['translation', 'event'],
  template: `
    <span>
      {{ $t(translation) }}
      <status-badge :status_id="event.new_values.status_id"/>
    </span>
  `,
  components: { StatusBadge },
};

const TaskUpdatedAssignee = {
  props: ['translation', 'event'],
  template: `
    <span>
      {{ $t(translation) }}
      <user-label inline :withAvatar="false" :id="event.old_values.assigned_id"/>
      na
      <user-label inline :withAvatar="false" :id="event.new_values.assigned_id"/>
    </span>
  `,
  components: { UserLabel },
};

export default {
  updated: {
    default: DefaultEventComponent,
    status_id: TaskUpdatedStatus,
    termination_date: DefaultEventComponent,
    assigned_id: TaskUpdatedAssignee,
  },
};
