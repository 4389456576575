<template>
  <div class="powertools-dropdown">
    <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
      <template #button-content>
        <div class="powertools-dropdown-button">
          <feather-icon
            size="21"
            icon="ZapIcon"
            />
            <div class="text">
              Powertools
            </div>
        </div>
      </template>
      <powertools-item
        :enabled="$store.state.app['disableModuleCheck']"
        @click="() => $store.commit('app/TOGGLE_MODULE_CHECK')"
        >
        Ignoruj status modułów
      </powertools-item>
      <powertools-item
        :enabled="$store.state.app['simulateThrottling']"
        @click="() => $store.commit('app/TOGGLE_THROTTLING_SIMULATION')"
        >
        Symuluj słabe łącze
      </powertools-item>
    </b-dropdown>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig';
import { computed } from 'vue';
import { BDropdown } from 'bootstrap-vue';
import PowertoolsItem from './PowertoolsItem.vue';

export default {
  components: {
    BDropdown,
    PowertoolsItem,
  },
  setup() {
    const { skin } = useAppConfig();

    const isDark = computed(() => skin.value === 'dark');

    return { skin, isDark };
  },
};
</script>

<style lang="sass" scoped>
.powertools-dropdown-button
  display: flex
  align-items: center

  .text
    margin-left: 10px
</style>
