import Section from '../Section';

class Footer extends Section {
  constructor(composer) {
    super('Invoice Footer', composer);
  }

  generate() {
    const { layout, content } = this.composer;

    const rows = [content.footer];

    this.composer.doc.autoTable({
      body: rows,
      headStyles: {
        font: 'OpenSans-Medium',
      },
      styles: {
        font: 'OpenSans-Light',
        fontSize: layout.fontSize.order.table,
        minCellWidth: 32,
        cellPadding: 1,
        halign: 'center',
      },
      theme: 'plain',
      margin: layout.gutters.x + 2,
      startY: layout.pageSize.height - layout.gutters.y - 8,
      didDrawCell(HookData) {
        layout.currentHeight = HookData.cursor.y + 3;
      },
    });
  }
}

export default Footer;
